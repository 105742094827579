import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const FieldSettingsQuestionMapping = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  let variant = props.variant;
  let step = props.step;
  let element = props.element;
  let field = props.field;

  let field_options = Object.values(contextData.objects.variant.field_options)
    .filter(
      (field_option) =>
        field_option.field_id == field.id && field_option.toBeDeleted !== true
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  useEffect(() => {});

  const parsedLabelLength = (label) => {
    if (!props.label_max_length) {
      return label;
    }

    return label && label.length > props.label_max_length
      ? label.substr(0, props.label_max_length).trim() + "..."
      : label;
  };

  return (
    <React.Fragment>
      {field_options.map((field_option) => {
        return (
          <React.Fragment key={`field-option-mapping-${field_option.id}`}>
            <div className="row hard-center" style={{ marginBottom: "10px" }}>
              <div
                className="col-xs-6 no-padding-sides"
                style={{ width: "230px" }}
              >
                <div className="col-xs-12 bg-slate-100 dark:bg-slate-800 text-black dark:text-white text-xl p-3 rounded-md">
                  {field_option.label
                    ? $("<div/>")
                        .html(parsedLabelLength(field_option.label))
                        .text()
                    : parsedLabelLength(field_option.value)}
                </div>
              </div>
              <div
                className="col-xs-1 center no-padding-sides"
                style={{ paddingTop: "5px", width: "50px" }}
              >
                <p className="light smooth semibold">→</p>
              </div>
              <div
                className="col-xs-5"
                style={{ paddingLeft: "0px" }}
              >
                <div
                  className="row"
                  style={{
                    marginLeft: "-18px",
                    marginRight: "-30px",
                    marginTop: "-11px",
                  }}
                >
                  <VariantBuilderSetting
                    object={field_option}
                    setting_name="[jump_field_id]"
                    setting_type="dropdown"
                    object_type="field_options"
                    object_id={field_option.id}
                    value={
                      field_option.jump_field_id
                        ? field_option.jump_field_id + ""
                        : field.options["jump-field-id"]
                        ? field.options["jump-field-id"]
                        : ""
                    }
                    options={props.fieldsDropdown}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default FieldSettingsQuestionMapping;
