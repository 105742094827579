import React, { useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderToolbarDevices = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const cta = props.cta;

  const desktopButton = useRef(null);
  const mobileButton = useRef(null);

  return (
    <React.Fragment>
      <div className="grid grid-flow-col auto-cols-auto pt-2 justify-center">
        <div className="pt-1 mr-1">
          <button
            ref={desktopButton}
            className={`${
              contextData.device !== "mobile"
                ? "bg-blue-100 dark:bg-slate-800 dark:text-blue-600"
                : "bg-white dark:bg-slate-950 dark:text-white"
            } pt-3 pb-3 px-5 rounded-lg text-xl mr-0 cursor-pointer border-none`}
            onClick={builder.desktop}
            style={{
              height: "36px",
            }}
            data-name="desktop"
            onMouseOver={() => {
              const svgPath = desktopButton.current.querySelector("svg path");
              if (contextData.device == "mobile" && !contextData.dark_mode) {
                svgPath.setAttribute("fill", "#003FFF");
              }
            }}
            onMouseLeave={() => {
              const svgPath = desktopButton.current.querySelector("svg path");
              if (contextData.device == "mobile" && !contextData.dark_mode) {
                svgPath.setAttribute("fill", "black");
              }
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.999 12.85C15.999 13.2642 16.3348 13.6 16.749 13.6C17.1632 13.6 17.499 13.2642 17.499 12.85H15.999ZM2.69902 12.85C2.69902 13.2642 3.03481 13.6 3.44902 13.6C3.86324 13.6 4.19902 13.2642 4.19902 12.85H2.69902ZM5.11152 4.575H15.0865V3.075H5.11152V4.575ZM15.999 5.43269V12.85H17.499V5.43269H15.999ZM4.19902 12.85V5.43269H2.69902V12.85H4.19902ZM15.0865 4.575C15.6144 4.575 15.999 4.9825 15.999 5.43269H17.499C17.499 4.10707 16.395 3.075 15.0865 3.075V4.575ZM5.11152 3.075C3.80303 3.075 2.69902 4.10707 2.69902 5.43269H4.19902C4.19902 4.9825 4.58367 4.575 5.11152 4.575V3.075ZM3.44902 14.075H16.749V12.575H3.44902V14.075ZM16.949 14.275V15.225H18.449V14.275H16.949ZM16.749 15.425H3.44902V16.925H16.749V15.425ZM3.24902 15.225V14.275H1.74902V15.225H3.24902ZM3.44902 15.425C3.33857 15.425 3.24902 15.3355 3.24902 15.225H1.74902C1.74902 16.1639 2.51014 16.925 3.44902 16.925V15.425ZM16.949 15.225C16.949 15.3355 16.8595 15.425 16.749 15.425V16.925C17.6879 16.925 18.449 16.1639 18.449 15.225H16.949ZM16.749 14.075C16.8595 14.075 16.949 14.1645 16.949 14.275H18.449C18.449 13.3361 17.6879 12.575 16.749 12.575V14.075ZM3.44902 12.575C2.51014 12.575 1.74902 13.3361 1.74902 14.275H3.24902C3.24902 14.1645 3.33857 14.075 3.44902 14.075V12.575Z"
                fill={
                  contextData.device !== "mobile"
                    ? contextData.dark_mode
                      ? "white"
                      : "#003FFF"
                    : contextData.dark_mode
                    ? "#9DA3AF"
                    : "black"
                }
              />
            </svg>
          </button>
        </div>
        <div className="pt-1">
          <button
            ref={mobileButton}
            className={`${
              contextData.device == "mobile"
                ? "bg-blue-100 dark:bg-slate-800 dark:text-blue-600"
                : "bg-white dark:bg-slate-950 dark:text-white"
            } pt-3 pb-3 px-5 rounded-lg text-xl mr-0 cursor-pointer border-none `}
            onClick={builder.mobile}
            style={{
              height: "36px",
            }}
            data-name="mobile"
            onMouseOver={() => {
              const svgPath = mobileButton.current.querySelector("svg path");
              if (contextData.device == "desktop" && !contextData.dark_mode) {
                svgPath.setAttribute("stroke", "#003FFF");
              }
            }}
            onMouseLeave={() => {
              const svgPath = mobileButton.current.querySelector("svg path");
              if (contextData.device == "desktop" && !contextData.dark_mode) {
                svgPath.setAttribute("stroke", "black");
              }
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.22363 4.25H9.97363M3.59863 3.49999V12.5C3.59863 13.3284 4.27021 14 5.09863 14H11.0986C11.9271 14 12.5986 13.3284 12.5986 12.5V3.5C12.5986 2.67157 11.9271 2 11.0986 2L5.09863 1.99999C4.27021 1.99999 3.59863 2.67156 3.59863 3.49999ZM8.09863 11H8.15176V11.0481H8.09863V11Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke={
                  contextData.device == "mobile"
                    ? contextData.dark_mode
                      ? "white"
                      : "#003FFF"
                    : contextData.dark_mode
                    ? "#9DA3AF"
                    : "black"
                }
              />
            </svg>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderToolbarDevices;
