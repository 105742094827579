import React, { useState, useEffect, useRef } from "react";

const UsersNavSearch = (props) => {
  const [query, setQuery] = useState(props.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const form = useRef();
  const collapse = useRef();

  return (
    <React.Fragment>
      <form
        node={form}
        onSubmit={(e) => {
          e.preventDefault();

          if (window.location.href.indexOf("cta") > -1) {
            window.location.href = `/websites/${props.website_id}/cta?name=${query}`;
          } else if (window.location.href.indexOf("broadcasts") > -1) {
            window.location.href = `/websites/${props.website_id}/broadcasts?name=${query}`;
          } else if (window.location.href.indexOf("contacts") > -1) {
            window.location.href = `/websites/${props.website_id}/contacts?email=${query}`;
          } else if (window.location.href.indexOf("accounts") > -1 && window.location.href.indexOf("websites") > -1) {
            window.location.href = `/accounts/${props.account_id}/websites?name=${query}`;
          }
        }}
      >
        <input
          value={query}
          type="text"
          name="name"
          className="form-control !text-xl !text-slate-200 !border-none !bg-slate-700 !shadow-none !outline-none !pl-16"
          placeholder="Search..."
          onChange={(e) => {
            setIsOpen(true);
            setQuery(e.target.value);
          }}
        />
        <span
          className="glyphicon glyphicon-search cursor-pointer text-slate-400 !text-xl !absolute !top-3 !left-5"
          onClick={(e) => {
            form.current.submit();
          }}
        ></span>
      </form>

      {isOpen && query && (
        <div
          className="absolute z-20 w-72 shadow-lg bg-white dark:bg-gray-800"
          style={{ width: "500px", top: "37px", left: "0", right: "0", borderRadius: '5px' }}
          ref={collapse}
        >
          <a href={`/websites/${props.website_id}/cta?name=${query}`}>
            <div className="w-full px-5 py-2 text-lg hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 hover:text-black dark:text-gray-300 dark:hover:text-white" style={{borderRadius: '5px 5px 0px 0px'}}>
              Search campaigns: "{query}"
            </div>
          </a>
          <a href={`/websites/${props.website_id}/broadcasts?name=${query}`}>
            <div className="w-full px-5 py-2 text-lg hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 hover:text-black dark:text-gray-300 dark:hover:text-white">
              Search broadcasts: "{query}"
            </div>
          </a>
          <a href={`/websites/${props.website_id}/contacts?email=${query}`}>
            <div className="w-full px-5 py-2 text-lg hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 hover:text-black dark:text-gray-300 dark:hover:text-white">
              Search contacts: "{query}"
            </div>
          </a>
          <a href={`/accounts/${props.account_id}/websites?name=${query}`}>
            <div className="w-full px-5 py-2 text-lg hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 hover:text-black dark:text-gray-300 dark:hover:text-white" style={{borderRadius: '0px 0px 5px 5px'}}>
              Search websites: "{query}"
            </div>
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default UsersNavSearch;
