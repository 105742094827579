import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useProductVariants from "../product_variants/useProductVariants";

const useProducts = () => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  const { ProductVariants } = useProductVariants();

  const Products = {
    create: async function (element, product_recommendation, callback) {
      builder.create(
        "products",
        {
          element_id: element ? element.id : null,
          product_recommendation_id: product_recommendation
            ? product_recommendation.id
            : null,
        },
        { callback: callback }
      );
    },

    duplicate: async function (og_product, payload, skip_history) {
      let attributes = {
        title: og_product.title,
        price: og_product.price,
        image: og_product.image,
        redirect_url: og_product.redirect_url,
        discounted_price: og_product.discounted_price,
        position: og_product.position,
        description: og_product.description,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      return new Promise((resolve, reject) => {
        builder.create("products", attributes, {
          callback: async function (product) {
            try {
              let product_variants = Object.values(
                variant.product_variants
              ).filter(
                (product_variant) =>
                  product_variant.product_id == og_product.id &&
                  product_variant.toBeDeleted !== true
              );

              await Promise.all(
                product_variants.map((product_variant) =>
                  ProductVariants.duplicate(
                    product_variant,
                    {
                      product_id: product.id,
                    },
                    true
                  )
                )
              );

              resolve(product);
            } catch (error) {
              reject(error);
            }
          },
          skip_history: skip_history,
          skip_select: true,
        });
      });
    },

    remove: (product) => {
      let message =
        "Are you sure you want to remove this product? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("products", product.id, message);
    },

    sortableContentStart: (element) => {
      const containerSelector = `.products-content[data-object_type="elements"][data-object_id='${element.id}']`;
      let container = document.querySelector(containerSelector);
      let item_selector =
        ".setting[data-setting_type='item_v2'][data-object_type='elements'][data-draggable='true']";

      let sortable_options = {
        sort: true,
        group: { name: containerSelector },
        animation: 0,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle: ".cf-item-v2[data-object_type='elements'][data-object-id='" + element.id + "']",
        scroll: true,
        forceFallback: true,
        fallbackTolerance: 5,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        setData: function (dataTransfer, dragEl) {
          dataTransfer.setData("Text", dragEl.textContent);
          let drag_image = document.getElementById("drag-image");
          dataTransfer.setDragImage(drag_image, 0, 0);
        },
        onUpdate: (event) => {
          let updates = [];

          Array.from(event.to.children).forEach((product_div, i) => {
            let new_position = i + 1;
            let setting_name = `[options]${product_div.getAttribute("data-setting_name")}`

            if (product_div.getAttribute("data-setting_type") == "item_v2") {
              updates.push({
                object_type: "elements",
                object_id: parseInt(product_div.getAttribute("data-object_id")),
                setting_name: setting_name,
                value: new_position,
              });
            }
          });

          builder.update(updates);
        },
      };
      new Sortable(container, sortable_options);
    },

    sortableStart: (element, product_recommendation) => {
      const containerSelector = `.products-list[data-object_type='${
        product_recommendation ? "product_recommendations" : "elements"
      }'][data-object_id='${
        product_recommendation ? product_recommendation.id : element.id
      }']`;
      let container = document.querySelector(containerSelector);

      let item_selector =
        ".setting[data-setting_type='item_v2'][data-object_type='products']";

      $(container)
        .find(item_selector)
        .sort(function (a, b) {
          return $(a).attr("data-position") - $(b).attr("data-position");
        })
        .prependTo(container);

      let sortable_options = {
        sort: true,
        group: { name: containerSelector },
        animation: 0,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle: ".cf-item-v2[data-object_type='products']",
        scroll: true,
        forceFallback: true,
        fallbackTolerance: 5,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        onUpdate: (event) => {
          Products.sortableUpdate(event, element, product_recommendation);
        },
      };
      new Sortable(container, sortable_options);
    },

    sortableUpdate: (event, element, product_recommendation) => {
      let updates = [];

      Array.from(event.to.children).forEach((product_div, i) => {
        let new_position = i + 1;
        let setting_name =
          "[options][" + product_div.getAttribute("data-name") + "-position]";

        if (product_div.getAttribute("data-setting_type") == "item_v2") {
          updates.push({
            object_type: "products",
            object_id: parseInt(product_div.getAttribute("data-object_id")),
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },
  };

  let currentHook = Products;
  return { Products, currentHook };
};

export default useProducts;
