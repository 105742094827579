import React, { useState, useEffect, useRef } from "react";
import { ColumnChart } from "react-chartkick";
import "chartkick/chart.js";

const ReportFunnelChart = (props) => {
  const [chartData, setData] = useState([]);

  async function getResponseData(reports) {
    // Create an array of promises
    const promises = reports.map(async (report) => {
      if (report.count || report.count == 0) {
        return { ...report, data: report.count };
        
      } else {
        // Perform the fetch request
        const response = await fetch(`${report.path}?` + $.param(report.payload));

        // Get the response data
        const data = await response.json();

        // Return the data and the original fetch params
        return { ...report, data };
      }
    });

    // Wait for all the promises to resolve
    const responseData = await Promise.all(promises);

    // Return the array of response data objects
    return responseData;
  }

  useEffect(() => {
    getResponseData(props.reports).then((data) => {
      let chartData = {};
      data.forEach((report, i) => {
        chartData[`${report.data.toLocaleString()} ${report.label}`] =
          i == 0 && report.data !== 0
            ? 100
            : ((report.data / data[0].data) * 100).toFixed(2);
      });
      setData(chartData);
    });
  }, [props.reports]);

  return (
    <React.Fragment>
      <ColumnChart
        loading="Loading..."
        data={chartData}
        stacked={true}
        thousands=","
        min={0}
        max={100}
        height={props.height}
        library={{
          scales: {
            y: {
              ticks: {
                min: 0,
                max: 100,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.formattedValue}%`;
                },
              },
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export default ReportFunnelChart;
