import React from "react";
import PropTypes from "prop-types";
import CtasListItemOptions from "../ctas/CtasListItemOptions";
import CtasListItemStatus from "../ctas/CtasListItemStatus";
import CtaGroupsSmallList from "../cta_groups/CtaGroupsSmallList";

const CtasListItemRow = (props) => {
  const websiteLink = "/websites/" + props.cta.website_id;
  const ctaLink = websiteLink + "/cta/" + props.cta.id;
  return (
    <React.Fragment>
      <div className="CtasListItemRow row">
        <div className="row hard-center">
          <div className="col-xs-12">
            <div className="col-xs-3 no-padding-right">
              <a href={ctaLink}>
                <div className="col-xs-5 no-padding-sides left">
                  <div
                    className="ctas-list-row-item-preview"
                    style={{
                      backgroundImage:
                        "url(" +
                        (props.variant && props.variant.screenshot
                          ? props.variant.screenshot
                          : "") +
                        ")",
                    }}
                  ></div>
                </div>
              </a>
              <div className="col-xs-7 no-padding-sides">
                <a href={ctaLink}>
                  <div className="row hard-center">
                    <p
                      className="light ellipsis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={props.cta.name}
                      style={{
                        textTransform: "capitalize",
                        margin: "0",
                        fontSize: "14px",
                      }}
                    >
                      {props.cta.cta_type !== "inline"
                        ? props.cta.cta_type
                        : "Embedded"}{" "}
                      - {props.cta.name}
                    </p>
                  </div>
                </a>
                <div className="row hard-center">
                  {props.hide_manage_groups ? (
                    ""
                  ) : (
                    <CtaGroupsSmallList
                      groups={props.cta.groups}
                      trim={true}
                      {...props}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-9">
              <a href={ctaLink}>
                <div
                  className="col-xs-2 ctas-list-row-column"
                  style={{ minWidth: "100px" }}
                >
                  <p className="light ellipsis">
                    {numberWithCommas(props.cta.views)}
                  </p>
                </div>
                <div
                  className="col-xs-2 no-padding-sides ctas-list-row-column"
                  style={{ minWidth: "100px" }}
                >
                  <p className="light ellipsis">
                    {numberWithCommas(props.cta.conversions)} (
                    {parseFloat(props.cta.conversion_rate).toFixed(0)}%)
                  </p>
                </div>
                <div
                  className="col-xs-2 no-padding-sides ctas-list-row-column"
                  style={{ minWidth: "100px" }}
                >
                  <p className="light ellipsis">
                    {numberWithCommas(props.cta.completions)} (
                    {parseFloat(props.cta.completion_rate).toFixed(0)}%)
                  </p>
                </div>
                <div
                  className="col-xs-1 no-padding-sides ctas-list-row-column"
                  style={{ minWidth: "75px" }}
                >
                  <p className="light ellipsis">
                    {props.currency}
                    {numberWithCommas(props.cta.revenue)}
                  </p>
                </div>
                <div
                  className="col-xs-1 no-padding-sides ctas-list-row-column"
                  style={{ minWidth: "75px" }}
                >
                  <p className="light ellipsis">
                    {props.currency}
                    {numberWithCommas(props.cta.aov)}
                  </p>
                </div>
              </a>
              <div className="col-xs-2 no-padding-sides ctas-list-row-column">
                {props.hide_status == true ? (
                  ""
                ) : (
                  <CtasListItemStatus {...props} />
                )}
              </div>
              <div
                className="col-xs-1 no-padding-sides ctas-list-row-column right"
                style={{ float: "right" }}
              >
                {props.hide_options == true ? (
                  ""
                ) : (
                  <CtasListItemOptions {...props} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CtasListItemRow;
