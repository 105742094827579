import React, { useContext } from "react";
import useFieldOptions from "../field_options/useFieldOptions";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewFieldOption = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { FieldOptions } = useFieldOptions();
  const optionsLength = Object.values(
    contextData.objects.variant.field_options
  ).filter(
    (field_option) =>
      field_option.field_id == props.field.id && !field_option.toBeDeleted
  ).length;

  const createFieldOption = () => {
    let position_letter = String.fromCharCode(65 + optionsLength);
    const default_value =
      props.element_type == "quiz"
        ? `Answer ${position_letter}`
        : `Option ${optionsLength + 1}`;

    FieldOptions.create(
      props.field,
      {
        label: props.default_value || default_value,
        value: props.default_value || default_value,
        emoji: "😎",
        letter: position_letter,
      },
      {
        callback: (field_option) => {
          dispatchCustomEvent("focusItem", {
            parent_id: field_option.field_id,
            item_type: "field_options",
            item_id: field_option.id
          });
        }
      }
    );
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 my-5">
        <button
          onClick={createFieldOption}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
          title="Add Answer"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add
          Answer
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewFieldOption;
