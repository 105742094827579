import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeIcon = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Icons" open={false}>
        <VariantBuilderSetting
          object={variant}
          label="Icon size"
          setting_name="[options][icon-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["icon-font-size"]
              ? variant.options["icon-font-size"]
              : undefined
          }
          placeholder={
            variant.options["p-font-size"] ? variant.options["p-font-size"] : 16
          }
          min={10}
          max={200}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Icon size"
          setting_name="[options][icon-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["icon-font-size-mobile"]
              ? variant.options["icon-font-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["p-font-size-mobile"]
              ? variant.options["p-font-size-mobile"]
              : variant.options["p-font-size"]) || 16
          }
          min={10}
          max={200}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Icon line height"
          setting_name="[options][icon-font-line-height]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["icon-font-line-height"]
              ? variant.options["icon-font-line-height"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={500}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Icon line height"
          setting_name="[options][icon-font-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["icon-font-line-height-mobile"]
              ? variant.options["icon-font-line-height-mobile"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={500}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Font color"
          setting_name="[options][icon-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["icon-font-color"]
              ? variant.options["icon-font-color"]
              : undefined
          }
          placeholder={
            variant.options["p-font-color"]
              ? variant.options["p-font-color"]
              : "#000"
          }
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Font color"
          setting_name="[options][icon-font-color-mobile]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["icon-font-color-mobile"]
              ? variant.options["icon-font-color-mobile"]
              : undefined
          }
          placeholder={
            variant.options["p-font-color"]
              ? variant.options["p-font-color"]
              : "#000"
          }
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Max Width"
          setting_name="[options][icon-max-width-style]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["icon-max-width-style"]
              ? variant.options["icon-max-width-style"]
              : "none"
          }
          options={[
            { value: "none", text: "Auto" },
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][icon-max-width-value]"
          setting_type="range"
          object_type="variants"
          label={"Icon Maximum Width"}
          value={
            variant.options["icon-max-width-value"]
              ? variant.options["icon-max-width-value"]
              : undefined
          }
          placeholder={300}
          condition={variant.options["icon-max-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Max Width"
          setting_name="[options][icon-max-width-style-mobile]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["icon-max-width-style-mobile"]
              ? variant.options["icon-max-width-style-mobile"]
              : undefined
          }
          options={[
            { value: "none", text: "Auto" },
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][icon-max-width-value-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Icon Maximum Width"}
          value={
            variant.options["icon-max-width-value-mobile"]
              ? variant.options["icon-max-width-value-mobile"]
              : undefined
          }
          placeholder={300}
          condition={variant.options["icon-max-width-style-mobile"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Border Style"
          setting_name="[options][icon-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["icon-border-style"]
              ? variant.options["icon-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Border color"
            setting_name="[options][icon-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["icon-border-color"]
                ? variant.options["icon-border-color"]
                : undefined
            }
            placeholder={variant.options["icon-border-color"]}
            condition={
              variant.options["icon-border-style"] &&
              variant.options["icon-border-style"] !== "" &&
              variant.options["icon-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][icon-hover-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["icon-hover-border-color"]
                ? variant.options["icon-hover-border-color"]
                : undefined
            }
            placeholder={
              variant.options["icon-border-color"]
                ? variant.options["icon-border-color"]
                : undefined
            }
            condition={
              variant.options["icon-border-style"] &&
              variant.options["icon-border-style"] !== "" &&
              variant.options["icon-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {variant.options["icon-border-style"] &&
          variant.options["icon-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][icon-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[`icon-border-width-${side}`]
                          ) == false
                            ? variant.options[`icon-border-width-${side}`]
                            : variant.options["icon-border-width"]
                            ? variant.options["icon-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Border Corner Radius"
          setting_name="[options][icon-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="variants"
          value={
            variant.options["icon-border-radius"]
              ? variant.options["icon-border-radius"]
              : undefined
          }
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][icon-border-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(variant.options[`icon-border-radius-${side}`]) ==
                    false
                      ? variant.options[`icon-border-radius-${side}`]
                      : undefined
                  }
                  condition={variant.options["icon-border-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Border Shadow"
          setting_name="[options][icon-border-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["icon-border-shadow"]
              ? variant.options["icon-border-shadow"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][icon-border-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["icon-border-shadow-horizontal-distance"]
              ? variant.options["icon-border-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["icon-border-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][icon-border-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["icon-border-shadow-horizontal-distance"]
              ? variant.options["icon-border-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["icon-border-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][icon-border-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["icon-border-shadow-blur-radius"]
              ? variant.options["icon-border-shadow-blur-radius"]
              : undefined
          }
          condition={variant.options["icon-border-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][icon-border-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["icon-border-shadow-spread-radius"]
              ? variant.options["icon-border-shadow-spread-radius"]
              : undefined
          }
          condition={variant.options["icon-border-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Shadow Color"
            setting_name="[options][icon-border-shadow-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["icon-border-shadow-color"]
                ? variant.options["icon-border-shadow-color"]
                : undefined
            }
            condition={variant.options["icon-border-shadow"] == "custom"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][icon-hover-shadow-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["icon-hover-shadow-color"]
                ? variant.options["icon-hover-shadow-color"]
                : undefined
            }
            placeholder={
              variant.options["icon-border-shadow-color"]
                ? variant.options["icon-border-shadow-color"]
                : undefined
            }
            condition={variant.options["icon-border-shadow"] == "custom"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Inset shadow position"
          setting_name="[options][icon-border-shadow-inset]"
          setting_type="checkbox"
          object_type="variants"
          value={
            variant.options["icon-border-shadow-inset"]
              ? variant.options["icon-border-shadow-inset"]
              : "false"
          }
          condition={variant.options["icon-border-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeIcon;
