import React from "react";
import PropTypes from "prop-types";
import Field from "../fields/Field";

const Survey = (props) => {
  let element = props.element;
  let options = element.options;
  const classes = style.classes(element, props.builder, props.device);
  let fields = Object.values(element.fields).filter(
    (field) => field.element_id == element.id && field.field_type == "survey"
  );
  let sorted_fields = fields.sort(function (a, b) {
    return a.position - b.position;
  });

  return (
    <React.Fragment>
      <div id={"element" + element.id + "-content"} className={classes}>
        {sorted_fields.map((field) => {
          return (
            <Field
              key={"field-" + field.id}
              field={field}
              element={element}
              {...props}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-survey";

    classes +=
      " " + (options["cf-alignment"] ? options["cf-alignment"] : "cf-center");
    return classes;
  },
};

export default Survey;
