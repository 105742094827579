import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PreviewTour from "../ctas/tooltips/PreviewTour";
import UnpublishedTour from "./tooltips/UnpublishedTour";

const CtaPreview = (props) => {
  const [device, setDevice] = useState("desktop");
  let iframeSrc = `/websites/${props.website.id}/cta/${props.cta.id}/page/?iframe=true&variant=${props.variant.variation}&device_type=${device}`;
  let width = "100%";

  if (device == "mobile") {
    width = "420px";
  }

  function PreviewTourTooltip() {
    if (getCookie("cta_preview_onboarded") !== "true" && tooltips_ran != true) {
      setTimeout(function () {
        if ($("body").attr("data-action") == "preview") {
          PreviewTour();
        }
      }, 3000);
    } else {
      $(".shepherd-step").hide();
    }
  }

  function UnpublishedTooltip() {
    if (props.variant.options.unpublished == "true") {
      UnpublishedTour();
    }
  }

  useEffect(() => {
    $("#cta-preview-iframe").on("load", function () {
      this.height = $(window).height() - $(this).offset().top;
    });

    // Start Tooltips
    window.tooltips_ran = false;
    PreviewTourTooltip();
    UnpublishedTooltip();
  }, [device]);

  return (
    <React.Fragment>
      <div>
        <iframe
          id="cta-preview-iframe"
          width={width}
          frameBorder="0"
          src={iframeSrc}
          device-type={device}
        ></iframe>
        <div className="cta-preview-devices light smooth" device-type={device}>
          <div
            className="col-xs-6 cta-preview-device-toggle"
            device-type="desktop"
            data-toggle="tooltip"
            data-placement="bottom"
            title=""
            data-original-title="Desktop"
            onClick={() => {
              setDevice("desktop");
            }}
          >
            <i className="fa fa-laptop" aria-hidden="true"></i>
          </div>
          <div
            className="col-xs-6 cta-preview-device-toggle"
            device-type="mobile"
            data-toggle="tooltip"
            data-placement="bottom"
            title=""
            data-original-title="Mobile"
            onClick={() => {
              setDevice("mobile");
            }}
          >
            <i className="fa fa-mobile" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CtaPreview;
