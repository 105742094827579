import React, { useContext } from "react";

const ElementTemplateItemSmall = (props) => {
  const elementTemplate = props.item;

  return (
    <div
      data-template-id={elementTemplate.id}
      className="cf-draggable-element-template center"
    >
      <div
        className={`w-[150px] inline-block float-left mr-[7px] h-[96px] p-0 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-slate-700 text-black dark:text-slate-300 dark:hover:text-white rounded-lg cursor-pointer`}
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.10) 0px 0px 0px 1px'
        }}
        onClick={() => props.selectTemplate(elementTemplate.id)}
      >
        <div
          className="w-full pt-6 h-[70px] bg-white dark:bg-slate-950 text-center text-4xl whitespace-no-wrap"
          style={{
            backgroundImage: `url(${
              elementTemplate.screenshot ? elementTemplate.screenshot : ""
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            borderRadius: '4px 4px 0px 0px'
          }}
        ></div>
        <div className="w-full mt-[4px] px-2 text-lg whitespace-nowrap text-ellipsis overflow-hidden">
          {elementTemplate.name}
        </div>
      </div>
    </div>
  );
};

export default ElementTemplateItemSmall;
