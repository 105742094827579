import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import NewContentToggleItem from "../content_toggles/NewContentToggleItem";
import ContentToggleTabSettings from "../content_toggles/ContentToggleTabSettings";

const ElementSettingsTabs = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  let sorted_list_items = Object.values(variant.content_toggle_items)
    .filter(
      (list_item) =>
        list_item.element_id == element.id && list_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <React.Fragment>
      <div className="col-xs-12">
        <div className="w-full relative text-black dark:text-white">
          <span className="font-semibold text-xl">Tabs:</span>
        </div>
      </div>
      <div
        className="sortable-container col-xs-12"
        data-sortable-name="content-toggle-list-items"
        data-object_type="content_toggle_items"
      >
        {sorted_list_items.map((list_item, i) => {
          return (
            <ContentToggleTabSettings
              key={"content_toggle_item-" + list_item.id}
              toggle_item={list_item}
              {...props}
            />
          );
        })}
      </div>

      <NewContentToggleItem element={element} />
    </React.Fragment>
  );
};

export default ElementSettingsTabs;
