import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeHeadings = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;

  const headings = [
    { name: "Heading 1", value: "h1" },
    { name: "Heading 2", value: "h2" },
    { name: "Heading 3", value: "h3" },
    { name: "Heading 4", value: "h4" },
    { name: "Paragraph", value: "p" },
  ];

  return (
    <React.Fragment>
      {headings.map((heading) => {
        return (
          <React.Fragment key={heading.name}>
            <VariantBuilderPanelCollapse name={heading.name} open={false}>
              <VariantBuilderSetting
                object={variant}
                label={`${heading.name} Font`}
                setting_name={`[options][${heading.value}-font-family]`}
                setting_type="select"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-family`]
                    ? variant.options[`${heading.value}-font-family`]
                    : undefined
                }
                option_groups={["System fonts", "Google fonts"]}
                options={[
                  { value: "", text: "Default" },
                  ...window.builder_fonts_dropdown,
                ]}
                col="8"
                callback={(new_value, old_value) => {
                  ctas.builder.fonts.load_font_family(new_value);
                }}
                show_reset={true}
              />

              <VariantBuilderSetting
                object={variant}
                setting_name={`[options][${heading.value}-font-weight]`}
                setting_type="font_weight"
                label="Weight"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-weight`]
                    ? variant.options[`${heading.value}-font-weight`]
                    : undefined
                }
                col="4"
                classNames="no-padding-left"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={variant}
                label={`${heading.name} Font size`}
                setting_name={`[options][${heading.value}-font-size]`}
                setting_type="range"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-size`]
                    ? variant.options[`${heading.value}-font-size`]
                    : undefined
                }
                placeholder="16"
                min={0}
                max={200}
                range_step={1}
                device="desktop"
                allow_empty={true}
                show_reset={true}
              />

              <VariantBuilderSetting
                object={variant}
                label={`${heading.name} Font size`}
                setting_name={`[options][${heading.value}-font-size-mobile]`}
                setting_type="range"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-size-mobile`]
                    ? variant.options[`${heading.value}-font-size-mobile`]
                    : variant.options[`${heading.value}-font-size`]
                    ? variant.options[`${heading.value}-font-size`]
                    : undefined
                }
                placeholder={
                  variant.options[`${heading.value}-font-size`]
                    ? variant.options[`${heading.value}-font-size`]
                    : "16"
                }
                min={0}
                max={200}
                range_step={1}
                device="mobile"
                allow_empty={true}
                show_reset={true}
              />

              <VariantBuilderSetting
                object={variant}
                label={`${heading.name} Font line height`}
                setting_name={`[options][${heading.value}-font-line-height]`}
                setting_type="range"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-line-height`]
                    ? variant.options[`${heading.value}-font-line-height`]
                    : undefined
                }
                placeholder="20"
                min={0}
                max={200}
                range_step={1}
                device="desktop"
                allow_empty={true}
                show_reset={true}
              />

              <VariantBuilderSetting
                object={variant}
                label={`${heading.name} Font line height`}
                setting_name={`[options][${heading.value}-font-line-height-mobile]`}
                setting_type="range"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-line-height-mobile`]
                    ? variant.options[
                        `${heading.value}-font-line-height-mobile`
                      ]
                    : variant.options[`${heading.value}-font-line-height`]
                    ? variant.options[`${heading.value}-font-line-height`]
                    : undefined
                }
                placeholder={
                  variant.options[`${heading.value}-font-line-height`]
                    ? variant.options[`${heading.value}-font-line-height`]
                    : 20
                }
                min={0}
                max={200}
                range_step={1}
                device="mobile"
                allow_empty={true}
                show_reset={true}
              />

              <VariantBuilderSetting
                object={variant}
                label={`${heading.name} Font color`}
                setting_name={`[options][${heading.value}-font-color]`}
                setting_type="color_v2"
                object_type="variants"
                value={
                  variant.options[`${heading.value}-font-color`]
                    ? variant.options[`${heading.value}-font-color`]
                    : undefined
                }
                placeholder="#000"
                show_reset={true}
              />
            </VariantBuilderPanelCollapse>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default VariantSettingsThemeHeadings;
