import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeSurveys = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Surveys" open={false}>
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][visual-type-placement]"
          setting_type="alignment"
          object_type="variants"
          label={"Options Visual Placement"}
          value={
            variant.options["visual-type-placement"]
              ? variant.options["visual-type-placement"]
              : "centered"
          }
          valueMappings={{
            left: "left",
            center: "centered",
          }}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][visual-type-placement-mobile]"
          setting_type="alignment"
          object_type="variants"
          label={"Options Visual Placement"}
          value={
            variant.options["visual-type-placement-mobile"]
              ? variant.options["visual-type-placement-mobile"]
              : undefined
          }
          valueMappings={{
            left: "left",
            center: "centered",
          }}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Options Visual Size"
          setting_name="[options][visual-type-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["visual-type-font-size"]
              ? variant.options["visual-type-font-size"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-size"]
              ? variant.options["visual-type-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Options Visual Size"
          setting_name="[options][visual-type-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["visual-type-font-size-mobile"]
              ? variant.options["visual-type-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-size-mobile"]
              ? variant.options["visual-type-font-size-mobile"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={"Visual Type Font Color"}
          setting_name="[options][visual-type-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["visual-type-font-color"]
              ? variant.options["visual-type-font-color"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-color"]
              ? variant.options["visual-type-font-color"]
              : "#fff"
          }
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={"Visual Type Font Color"}
          setting_name="[options][visual-type-font-color-mobile]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["visual-type-font-color-mobile"]
              ? variant.options["visual-type-font-color-mobile"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-color-mobile"]
              ? variant.options["visual-type-font-color-mobile"]
              : "#fff"
          }
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Options Image Width"
          setting_name="[options][options-image-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["options-image-width"]
              ? variant.options["options-image-width"]
              : undefined
          }
          min={0}
          max={750}
          range_step={1}
          condition={variant.options["visual-type-placement"] != "left"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Options Image Width"
          setting_name="[options][options-image-width-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["options-image-width-mobile"]
              ? variant.options["options-image-width-mobile"]
              : undefined
          }
          min={0}
          max={750}
          range_step={1}
          condition={variant.options["visual-type-placement"] != "left"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Options Image Height"
          setting_name="[options][options-image-height]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["options-image-height"]
              ? variant.options["options-image-height"]
              : undefined
          }
          min={0}
          max={750}
          allow_empty={true}
          range_step={1}
          condition={variant.options["visual-type-placement"] != "left"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Options Image Height"
          setting_name="[options][options-image-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["options-image-height-mobile"]
              ? variant.options["options-image-height-mobile"]
              : undefined
          }
          min={0}
          max={750}
          allow_empty={true}
          range_step={1}
          condition={variant.options["visual-type-placement"] != "left"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Next Button Padding"
          setting_name="[options][next-button-padding]"
          setting_type="box_height"
          object_type="variants"
          value={
            variant.options["next-button-padding"]
              ? variant.options["next-button-padding"]
              : "cf-button-regular"
          }
          valueMappings={{
            small: "cf-button-small",
            regular: "cf-button-regular",
            large: "cf-button-large",
            custom: "custom",
          }}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][next-button-padding-value]"
          setting_type="range"
          object_type="variants"
          label="Next Button Padding"
          value={
            variant.options["next-button-padding-value"]
              ? variant.options["next-button-padding-value"]
              : undefined
          }
          condition={variant.options["next-button-padding"] == "custom"}
          min={0}
          max={200}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Next Button Font"
          setting_name="[options][next-button-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["next-button-font-family"]
              ? variant.options["next-button-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          callback={function (new_value, old_value) {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          skip_history={true}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][next-button-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["next-button-font-weight"]
              ? variant.options["next-button-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Next Button font size"
          setting_name="[options][next-button-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["next-button-font-size"]
              ? variant.options["next-button-font-size"]
              : undefined
          }
          placeholder={
            variant.options["next-button-font-size"]
              ? variant.options["next-button-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Next Button Font size"
          setting_name="[options][next-button-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["next-button-font-size-mobile"]
              ? variant.options["next-button-font-size-mobile"]
              : undefined
          }
          placeholder={variant.options["next-button-font-size"] || 16}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Next text color"
            setting_name="[options][next-button-text-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button-text-color"]
                ? variant.options["next-button-text-color"]
                : undefined
            }
            placeholder={"#000"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][next-button–hover-text-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button–hover-text-color"]
                ? variant.options["next-button–hover-text-color"]
                : undefined
            }
            placeholder={variant.options["next-button-text-color"] || "#000"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Next But. color"
            setting_name="[options][next-button-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button-color"]
                ? variant.options["next-button-color"]
                : undefined
            }
            placeholder={"#fff"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][next-button–hover-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button–hover-color"]
                ? variant.options["next-button–hover-color"]
                : undefined
            }
            placeholder={variant.options["next-button-color"] || "#fff"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Next Button Border Style"
          setting_name="[options][next-button-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["next-button-border-style"]
              ? variant.options["next-button-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Border color"
            setting_name="[options][next-button-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button-border-color"]
                ? variant.options["next-button-border-color"]
                : undefined
            }
            placeholder={variant.options["next-button-border-color"] ? variant.options["next-button-border-color"] : variant.options["next-button-color"]}
            condition={
              variant.options["next-button-border-style"] &&
              variant.options["next-button-border-style"] !== "" &&
              variant.options["next-button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][next-button-hover-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button-hover-border-color"]
                ? variant.options["next-button-hover-border-color"]
                : undefined
            }
            placeholder={variant.options["next-button-hover-border-color"] ? variant.options["next-button-hover-border-color"] : variant.options["next-button-border-color"]}
            condition={
              variant.options["next-button-border-style"] &&
              variant.options["next-button-border-style"] !== "" &&
              variant.options["next-button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {variant.options["next-button-border-style"] &&
          variant.options["next-button-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Next Button Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][next-button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[`next-button-border-width-${side}`]
                          ) == false
                            ? variant.options[
                                `next-button-border-width-${side}`
                              ]
                            : variant.options["next-button-border-width"]
                            ? variant.options[
                                "next-button-border-width"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Next Button Corner Radius"
          setting_name="[options][next-button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["next-button-radius"]
              ? variant.options["next-button-radius"]
              : "cf-slightly-rounded"
          }
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][next-button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(variant.options[`next-button-radius-${side}`]) ==
                    false
                      ? variant.options[`next-button-radius-${side}`]
                      : undefined
                  }
                  condition={variant.options["next-button-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Next Button Shadow"
          setting_name="[options][next-button-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["next-button-shadow"]
              ? variant.options["next-button-shadow"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][next-button-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Next Shadow Horizontal Distance"}
          value={
            variant.options["next-button-shadow-horizontal-distance"]
              ? variant.options["next-button-shadow-horizontal-distance"]
              : "0"
          }
          condition={variant.options["next-button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][next-button-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Next Shadow Vertical Distance"}
          value={
            variant.options["next-button-shadow-vertical-distance"]
              ? variant.options["next-button-shadow-vertical-distance"]
              : "0"
          }
          condition={variant.options["next-button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][next-button-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Next Shadow Blur Radius"}
          value={
            variant.options["next-button-shadow-blur-radius"]
              ? variant.options["next-button-shadow-blur-radius"]
              : "0"
          }
          condition={variant.options["next-button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][next-button-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Next Shadow Blur Radius"}
          value={
            variant.options["next-button-shadow-spread-radius"]
              ? variant.options["next-button-shadow-spread-radius"]
              : "0"
          }
          condition={variant.options["next-button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Shadow Color"
            setting_name="[options][next-button-shadow-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button-shadow-color"]
                ? variant.options["next-button-shadow-color"]
                : "0"
            }
            condition={variant.options["next-button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-right"
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][next-button-hover-shadow-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["next-button-hover-shadow-color"]
                ? variant.options["next-button-hover-shadow-color"]
                : undefined
            }
            placeholder={variant.options["next-button-shadow-color"]}
            show_reset={true}
            condition={variant.options["next-button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-left"
            offset_left={true}
          />
        </div>
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeSurveys;
