var broadcasts_groups = {};

import initialize from "packs/broadcasts_groups/initialize.js";
broadcasts_groups.initialize = initialize;

document.addEventListener("turbolinks:load", function () {
  $(".broadcast-group").each(function (i, broadcast_group_div) {
    broadcasts_groups.initialize(broadcast_group_div);
  });
});

export default broadcasts_groups;
