import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsDivider = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        label="Divider Orientation"
        setting_name="[options][divider-orientation]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["divider-orientation"]
            ? element.options["divider-orientation"]
            : "horizontal"
        }
        options={[
          { value: "horizontal", text: "Horizontal" },
          { value: "vertical", text: "Vertical" },
        ]}
        condition={["divider"].includes(element.element_type)}
      />

      <VariantBuilderSetting
        object={element}
        label="Divider Length"
        setting_name="[options][divider-length]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["divider-length"]
            ? element.options["divider-length"]
            : "none"
        }
        options={[
          { value: "none", text: "100%" },
          { value: "custom", text: "Custom" },
        ]}
        condition={
          ["divider"].includes(element.element_type) &&
          element.options["divider-orientation"] !== "vertical"
        }
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][divider-length-value]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Divider Length Value"}
        value={
          element.options["divider-length-value"]
            ? element.options["divider-length-value"]
            : "300"
        }
        condition={
          ["divider"].includes(element.element_type) &&
          (element.options["divider-length"] == "custom" ||
            element.options["divider-orientation"] == "vertical")
        }
        min={0}
        max={2560}
        range_step={"1"}
      />
    </React.Fragment>
  );
};

export default ElementSettingsDivider;
