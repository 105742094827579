import React, { forwardRef } from "react";
import Select from "react-select";

const selectedDot = (isSelected) =>
  isSelected && {
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: "#000",
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 8,
      width: 8,
    },
  };

const CFSelect = forwardRef((props, ref) => {

  const formatOptionLabel = ({ value, label }) => {
    let icon;
    props.options.forEach((option) => {
      if (option.options) {
        const selectedOption = option.options.filter(
          (option) => option.value == value
        )[0];

        if (selectedOption && selectedOption.icon) {
          icon = selectedOption.icon;
        }
      }
    });

    return (
      <div>
        {icon && (
          <img className="w-[13px] h-auto mr-5" src={icon} />
        )}
        {label}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Select
        ref={ref}
        className="w-full smart-select"
        {...props}
        classNames={{
          control: () =>
            "w-full !min-h-[34px] px-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white !rounded-xl border-solid border-[1px] !border-slate-200 dark:!border-slate-700 focus:!border-slate-200 !outline-none !shadow-none",
          dropdownIndicator: () => "!p-[0px]",
          indicatorSeparator: () => "!w-[0px]",
          input: () =>
            "text-black dark:text-white outline-none focus:outline-none focus:ring-0 appearance-none shadow-none",
          menu: () =>
            "w-full bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700",
          option: ({ isFocused, isSelected }) =>
            `!cursor-pointer !text-black dark:!text-white ${
              isSelected ? "!bg-blue-600 !text-white" : isFocused ? "!bg-slate-100 dark:!bg-slate-800" : ""
            } hover:!text-black active:!text-black focus:!text-black hover:!bg-slate-100 active:!bg-slate-100 focus:!bg-slate-800 dark:hover:!text-white dark:active:!text-white dark:focus:!text-white dark:hover:!bg-slate-800 dark:active:!bg-slate-800 dark:focus:!bg-slate-800`,
          singleValue: () => "text-black dark:text-white",
          valueContainer: () => "!p-[0px]",
        }}
        formatOptionLabel={formatOptionLabel}
        value={props.value}
      />

      <style dangerouslySetInnerHTML={{ __html: style.stylesheet() }} />
    </React.Fragment>
  );
});

const style = {
  stylesheet: () => {
    // prettier-ignore
    const desktopStyles = ``;

    return desktopStyles;
  },
};

export default CFSelect;
