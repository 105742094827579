import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const FieldOptionReportIcon = (props) => {
  const field_option = props.field_option;
  const field_type = props.field_type;

  return (
    <React.Fragment>
      {field_type == "emoji" && field_type ? (
        <div className="element-selection-report-icon">
          {field_option.emoji}
        </div>
      ) : field_type == "icon" && field_option.icon ? (
        <div
          className="element-selection-report-icon"
          style={{
            color: "blue",
          }}
        >
          <FontAwesomeIcon
            icon={[
              field_option.icon.split(" ")[0],
              field_option.icon.split(" ")[1],
            ]}
          />
        </div>
      ) : field_type == "letter" && field_option.letter ? (
        <div
          className="element-selection-report-icon"
          style={{
            background: "blue",
            color: "#fff",
          }}
        >
          {field_option.letter}
        </div>
      ) : field_type == "image" ? (
        <div
          className="element-selection-report-icon"
          style={{
            background: "blue",
            color: "#fff",
            backgroundImage: `url(${field_option.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "0px",
          }}
        ></div>
      ) : field_type == "color" ? (
        <div
          className="element-selection-report-icon"
          style={{
            background: field_option.color ? field_option.color : "black",
          }}
        ></div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default FieldOptionReportIcon;
