const templateableElementTypesArray = [
  "headline",
  "text",
  "form",
  "button",
  "survey",
  "row",
  "products",
  "product_hero",
  "timer",
  "progress",
  "icon",
  "collapse",
  "tabs",
  "list",
  "carousel",
  "quiz",
];

export default templateableElementTypesArray;
