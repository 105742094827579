class ShopifyAjaxAdapter {
  constructor(source_product) {
    this.source = "Shopify";
    this.source_product = source_product;

    this.id = this.productId();
    this.handle = this.productHandle();
    this.available = this.productAvailable();
    this.title = this.productTitle();
    this.description = this.productDescription();
    this.image = this.productImage();
    this.price = this.productPrice();
    this.options = this.productOptions();
    this.variants = this.productVariants() || [];
    this.compare_at_price = this.productCompareAtPrice();
    this.redirect_url = this.productRedirectUrl();
    this.subscriptions = this.productSubscriptions();
    this.imageList = this.productImageList();
  }

  productId() {
    return this.source_product?.id;
  }

  productHandle() {
    return this.source_product?.handle;
  }

  productAvailable() {
    return this.source_product?.available;
  }

  productTitle() {
    return this.source_product?.title;
  }

  productDescription() {
    return this.source_product?.description;
  }

  productImage() {
    let image = this.source_product?.featured_image;

    return image.includes('http') ? image : image = `https:${image}`;
  }

  productImageList() {
    return this.source_product?.images;
  }

  productPrice() {
    return (this.source_product?.price / 100).toFixed(2);
  }

  productCompareAtPrice() {
    return this.source_product?.compare_at_price;
  }

  productOptions() {
    return this.source_product?.options
  }

  productStockQuantity() {
    return undefined
  }

  productRedirectUrl() {
    return `/products/${this.productHandle()}`;
  }

  productSubscriptions() {
    return undefined;
  }

  productVariants() {
    return this.source_product?.variants?.map((f) => {
      return {
        id: f.id,
        available: f.available,
        price: (f.price / 100).toFixed(2),
        title: f.title,
        description: f.description,
        image: f.featured_image.src,
        imageList: undefined,
        stockQuantity: undefined,
      };
    });
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ShopifyAjaxAdapter = ShopifyAjaxAdapter;
}
