import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsTimer = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        setting_name="[options][timer-type]"
        setting_type="dropdown"
        label="Timer type"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-type"]
            ? element.options["timer-type"]
            : "daily"
        }
        options={[
          { value: "static", text: "Static date" },
          { value: "daily", text: "Daily" },
          { value: "relative", text: "Relative" },
        ]}
        condition={["timer"].includes(element.element_type)}
      />

      {["timer"].includes(element.element_type) &&
        ["relative", "daily"].includes(element.options["timer-type"]) && (
          <div className="col-xs-12 some-vertical-space">
            <VariantBuilderSetting
              object={element}
              setting_name="[options][timer-days]"
              setting_type="number"
              label="Day"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["timer-days"]
                  ? element.options["timer-days"]
                  : 0
              }
              col={3}
              classNames="spacing-inputs"
              min={0}
              condition={["relative"].includes(element.options["timer-type"])}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][timer-hours]"
              setting_type="number"
              label="Hour"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["timer-hours"]
                  ? element.options["timer-hours"]
                  : 23
              }
              col={3}
              classNames="spacing-inputs"
              min={0}
              max={23}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][timer-minutes]"
              setting_type="number"
              label="Minute"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["timer-minutes"]
                  ? element.options["timer-minutes"]
                  : 59
              }
              col={3}
              classNames="spacing-inputs"
              min={0}
              max={59}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][timer-seconds]"
              setting_type="number"
              label="Second"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["timer-seconds"]
                  ? element.options["timer-seconds"]
                  : 59
              }
              col={3}
              classNames="spacing-inputs"
              min={0}
              max={59}
            />
          </div>
        )}

      <VariantBuilderSetting
        object={element}
        setting_name="[options][timer-date]"
        setting_type="date"
        label="Timer date"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-date"]
            ? element.options["timer-date"]
            : moment().endOf("day").format("YYYY-MM-DD h:mm:ss a")
        }
        condition={
          ["timer"].includes(element.element_type) &&
          element.options["timer-type"] == "static"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Timer timezone"
        setting_name="[options][timer-date-timezone]"
        setting_type="select"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-date-timezone"]
            ? element.options["timer-date-timezone"]
            : "America/New_York"
        }
        options={window.timezones}
        condition={
          ["timer"].includes(element.element_type) &&
          element.options["timer-type"] == "static"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Use broadcast end date"
        setting_name="[options][timer-broadcast-date]"
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-broadcast-date"]
            ? element.options["timer-broadcast-date"]
            : "false"
        }
        condition={
          ["timer"].includes(element.element_type) &&
          element.options["timer-type"] == "static"
        }
        tooltip="If this CTA is a part of a broadcast campaign, the countdown timer will use the broadcast's end date. If not, it will fallback to the end time set above."
      />

      <VariantBuilderSetting
        object={element}
        label="Days Translation"
        setting_name="[options][timer-days-translation]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-days-translation"]
            ? element.options["timer-days-translation"]
            : "Days"
        }
        condition={["timer"].includes(element.element_type)}
        placeholder="Days"
      />

      <VariantBuilderSetting
        object={element}
        label="Hours Translation"
        setting_name="[options][timer-hours-translation]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-hours-translation"]
            ? element.options["timer-hours-translation"]
            : "Hours"
        }
        condition={["timer"].includes(element.element_type)}
        placeholder="Hours"
      />

      <VariantBuilderSetting
        object={element}
        label="Minutes Translation"
        setting_name="[options][timer-minutes-translation]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-minutes-translation"]
            ? element.options["timer-minutes-translation"]
            : "Minutes"
        }
        condition={["timer"].includes(element.element_type)}
        placeholder="Minutes"
      />

      <VariantBuilderSetting
        object={element}
        label="Seconds Translation"
        setting_name="[options][timer-seconds-translation]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-seconds-translation"]
            ? element.options["timer-seconds-translation"]
            : "Seconds"
        }
        condition={["timer"].includes(element.element_type)}
        placeholder="Seconds"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][timer-end-action]"
        setting_type="dropdown"
        label="Timer end action"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-end-action"]
            ? element.options["timer-end-action"]
            : "timer"
        }
        options={[
          { value: "timer", text: "Show timer" },
          { value: "hide", text: "Hide timer" },
          { value: "message", text: "Show message" },
          { value: "step", text: "Jump to a step" },
        ]}
        condition={["timer"].includes(element.element_type)}
      />

      <VariantBuilderSetting
        object={element}
        label="Jump to funnel step"
        setting_name="[options][timer-end-step]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["timer-end-step"]
            ? element.options["timer-end-step"]
            : ""
        }
        options={[
          {
            value: "",
            text: "Select a funnel step",
            disabled: true,
          },
          ...Object.values(variant.steps)
            .filter((s) => s.id !== step.id && s.toBeDeleted !== true)
            .sort((a, b) => {
              return a.position - b.position;
            })
            .map((s) => {
              return {
                value: s.id + "",
                text:
                  "Step " +
                  s.position +
                  (s.options["name"] ? " - " + s.options["name"] : ""),
              };
            }),
        ]}
        condition={
          ["timer"].includes(element.element_type) &&
          element.options["timer-end-action"] == "step"
        }
        warning={
          !element.options["timer-end-step"]
            ? "Step to jump to not yet set"
            : undefined
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Timer end message"
        setting_name="[html]"
        setting_type="editor"
        object_type="elements"
        object_id={element.id}
        value={element.html}
        condition={
          ["timer"].includes(element.element_type) &&
          element.options["timer-end-action"] == "message"
        }
      />
    </React.Fragment>
  );
};

export default ElementSettingsTimer;
