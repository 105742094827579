import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingShadow = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">
        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == "None" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("None");
          }}
          title={`No Shadow`}
        >
          <div className=" ml-auto mr-auto w-[15px] h-[11px] bg-slate-500 dark:bg-slate-300 rounded"></div>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == "soft" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("soft");
          }}
          title={`Soft Shadow`}
        >
          <div
            className=" ml-auto mr-auto w-[25px] h-[11px] bg-slate-500 dark:bg-slate-300 rounded"
            style={{ boxShadow: "0 5px 15px 0 rgb(179 179 179 / 78%)" }}
          ></div>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == "hard" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("hard");
          }}
          title={`Hard Shadow`}
        >
          <div
            className=" ml-auto mr-auto w-[100%] h-[11px] bg-slate-500 dark:bg-slate-300 rounded"
            style={{ borderLeft: `3px solid ${contextData.dark_mode ? '#696969' : '#ccc'}`, borderBottom: `3px solid ${contextData.dark_mode ? '#696969' : '#ccc'}` }}
          ></div>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == "custom" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("custom");
          }}
          title={"Custom Shadow"}
        >
          <div className=" ml-auto mr-auto w-[25px] h-[11px] border-dashed border-[1px] border-black dark:border-white rounded"></div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingShadow;
