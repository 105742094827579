import React from "react";
import PropTypes from "prop-types";
import FieldOption from "../field_options/FieldOption";

const FieldDropdown = (props) => {
  let field = props.field;
  let element = props.element;
  let field_options = Object.values(field.field_options).filter(
    (field_option) =>
      field_option.field_id == field.id && field_option.toBeDeleted !== true
  );
  let sorted_field_options = Object.values(field_options).sort(function (a, b) {
    return a.position - b.position;
  });

  return (
    <React.Fragment>
      <div
        data-id={field.id}
        data-field={field.slug}
        data-pos={field.position ? field.position : ""}
        className="cf-form-field cf-custom-field"
        data-visible="true"
        data-multi-select={field.multi_select == true ? "true" : "false"}
        style={{
          order: field.position,
        }}
      >
        <div
          className={
            "contact-custom-field cf-" + field.field_type + " cf-col-xs-12"
          }
          data-required={field.required == true ? "true" : "false"}
          id={"customfield" + field.id}
        >
          <div
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              color: element.options["label-color"]
                ? element.options["label-color"]
                : "",
              fontFamily: "inherit",
            }}
          >
            {field.label && props.hide_label !== true && (
              <p
                className="cf-label"
                style={{
                  marginBottom: "4px",
                  color: element.options["label-color"]
                    ? element.options["label-color"]
                    : "",
                }}
              >
                {field.label}
              </p>
            )}
            <select
              name={"contact[extra][" + field.slug + "]"}
              id={"contact_extra_" + field.slug}
              required={field.required == true ? true : false}
              defaultValue=""
            >
              {field.placeholder && (
                <option disabled={true} value="">
                  {field.placeholder}
                </option>
              )}
              {sorted_field_options.map((field_option) => {
                return (
                  <FieldOption
                    key={"field-option-" + field_option.id}
                    field_option={field_option}
                    {...props}
                  />
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldDropdown;
