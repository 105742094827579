import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CtasListToolbar from "../ctas/CtasListToolbar";
import CtasListItem from "../ctas/CtasListItem";

const CtasList = (props) => {
  const ctas = props.ctas.map((cta, i) => {
    return (
      <CtasListItem
        key={"cta-" + cta.id}
        website={props.website}
        cta={cta}
        list_type={props.list_type}
        role_type={props.role_type}
        plan_type={props.plan_type}
        premimum={props.premium}
        {...props}
      />
    );
  });

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <React.Fragment>
      <div className="row hard-center CtasList smooth">
        <div className="col-xs-12 no-padding-sides">
          <div className="row hard-center">
            <div className="col-xs-12 no-padding-left">
              <CtasListToolbar {...props} />
            </div>
          </div>
          {props.list_type == "card" && (
            <div className="row hard-center ctas-list-card-container">
              {ctas}
              <a className="new-cta" href={`/websites/${props.website.id}/cta/new`}>
                <div className="CtasListItemCard card-new ctas-list-card-new">
                  <h3 className="some-vertical-space smooth center">
                    Create Campaign +
                  </h3>
                </div>
              </a>
            </div>
          )}
          {props.list_type == "row" && (
            <div className="col-xs-12">
              <div className="row hard-center ctas-list-row-container some-vertical-space border-solid border-[1px] border-slate-200 rounded-lg">
                <div className="col-xs-12 ctas-list-row-header">
                  <div className="col-xs-3">
                    <div className="col-xs-5 no-padding-sides">
                      <p className="ctas-list-row-heading">Preview</p>
                    </div>
                    <div className="col-xs-7 no-padding-right !pl-[6px]">
                      <p className="ctas-list-row-heading">Name</p>
                    </div>
                  </div>
                  <div className="col-xs-9">
                    <div className="col-xs-2" style={{minWidth: '100px'}}>
                      <p className="ctas-list-row-heading">Viewers</p>
                    </div>
                    <div className="col-xs-2 no-padding-sides" style={{minWidth: '100px'}}>
                      <p className="ctas-list-row-heading">Conversions</p>
                    </div>
                    <div className="col-xs-2 no-padding-sides" style={{minWidth: '100px'}}>
                      <p className="ctas-list-row-heading">Completions</p>
                    </div>
                    <div className="col-xs-1 no-padding-sides" style={{minWidth: '75px'}}>
                      <p className="ctas-list-row-heading">Revenue</p>
                    </div>
                    <div className="col-xs-1 no-padding-sides" style={{minWidth: '75px'}}>
                      <p className="ctas-list-row-heading">AOV</p>
                    </div>
                    <div className="col-xs-2 no-padding-sides">
                      <p className="ctas-list-row-heading">Campaign</p>
                    </div>
                    <div className="col-xs-1 no-padding-sides"></div>
                  </div>
                </div>
                <div className="col-xs-12">{ctas}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CtasList;
