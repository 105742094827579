import React, { useContext, useEffect, useState, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import useFieldOptions from "../field_options/useFieldOptions";
import NewFieldModal from "../fields/NewFieldModal";
import NewQuizField from "../fields/NewQuizField";
import useFields from "../fields/useFields";

const QuizSettings = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const [showQuizSubQuestions, setShowQuizSubQuestions] = useState(true);
  const [openedOptionSettingsId, setOpenedOptionSettingsId] = useState(null);
  const [activeTab, setActiveTab] = useState("content");

  const [newFieldModalVisibility, setNewFieldModalVisibility] = useState(false);

  const { FieldOptions } = useFieldOptions();
  const { Fields } = useFields(false);

  const field_item = props.field_item;
  const element = contextData.objects.variant.elements[field_item.element_id];

  let sorted_nested_fields = Object.values(contextData.objects.variant.fields)
    .filter(
      (field) =>
        field.element_id == element.id &&
        field.toBeDeleted !== true &&
        field_item.toBeDeleted !== true &&
        field.field_id == field_item.id
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  useEffect(() => {
    FieldOptions.sortableStart(field_item);

    sorted_nested_fields.forEach((nested_field) => {
      FieldOptions.sortableStart(nested_field);
    });

    const fieldClickSelector = `.cf-item-box[data-object-id="${field_item.id}"]`;
    document.querySelectorAll(fieldClickSelector).forEach((item) => {
      item.addEventListener("click", handleFieldClick);
    });

    return function cleanup() {
      document.querySelectorAll(fieldClickSelector).forEach((item) => {
        item.removeEventListener("click", handleFieldClick);
      });
    };
  }, [activeTab]);

  useEffect(() => {
    if (sorted_nested_fields.length == 0) {
      setShowQuizSubQuestions(false);
    } else {
      setShowQuizSubQuestions(true);
    }

    sorted_nested_fields.forEach((sub_field) => {
      setTimeout(function () {
        const subFieldClickSelector = `.cf-item-box[data-object-id="${sub_field.id}"]`;

        document.querySelectorAll(subFieldClickSelector).forEach((item) => {
          item.addEventListener("click", () =>
            handleSubFieldClick(sub_field.id)
          );
        });
      }, 150);
    });
  }, [sorted_nested_fields.length]);

  useEffect(() => {
    if (showQuizSubQuestions) {
      document.querySelectorAll(".field-position").forEach((sub) => {
        Fields.sortableStart(element, sub);
      });
    }
  }, [showQuizSubQuestions]);

  const getFieldSubFieldsLength = () => {
    return Object.values(contextData.objects.variant.fields).filter(
      (sub_field) =>
        sub_field.element_id == element.id &&
        sub_field.toBeDeleted !== true &&
        sub_field.field_id == field_item.id
    ).length;
  };

  const handleSubFieldClick = (sub_field_id) => {
    let target_field = Object.values(contextData.objects.variant.fields).find(
      (s) => sub_field_id == s.id
    );

    let newContextData = { ...contextData };
    dispatchCustomEvent("focusItem", {
      parent_id: target_field.element_id,
      item_type: "fields",
      item_id: target_field.id,
    });
  };

  const handleFieldClick = (event) => {
    event.preventDefault();


    dispatchCustomEvent("focusItem", {
      parent_id: field_item.element_id,
      item_type: "fields",
      item_id: field_item.id,
    });
  };

  const quizLabelIcon = (field_type) => {
    switch (field_type) {
      case "image":
        return "images";
      case "color":
        return "palette";
      case "emoji":
        return "smile";
      case "icon":
        return "icons";
      case "letter":
        return "a";
      case "text":
        return "t";
      case "dropdown":
        return "square-caret-down";
      case "paragraph":
        return "t";
      case "number":
        return "1";
      case "range":
        return "sliders";
      case "empty":
        return "eye-slash";
      default:
        return "font";
    }
  };

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={field_item}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="fields"
        object_id={field_item.id}
        title={field_item.label || field_item.slug}
        condition={element.element_type == "quiz"}
        style={{ margin: "4px 0px" }}
        position={field_item.position}
        label_prepend={props.field_item_position}
        label_icon={quizLabelIcon(field_item.field_type)}
        duplicate_payload={{
          label: `${field_item.label} (Copy)`,
          element_id: field_item.element_id,
        }}
        nested_items_length={sorted_nested_fields.length}
        label_max_length={16}
        label_hover_max_length={10}
        nested_tooltip={"Add Sub-Question"}
        onEdit={() => {
          builder.select("fields", field_item.id);
        }}
        callbackOnRemove={() => {
          setTimeout(function () {
            const items = document.querySelectorAll(
              `.tab-row[data-name="Quiz"] .setting[data-setting_type='item_v2'][data-object_type='fields']`
            );
            Fields.sortableReorderUpdates(items);
          }, 100);
        }}
        create_nested_item_callback={() => {
          dispatchCustomEvent("newObject", {
            object_type: "Fields",
            element_id: field_item.element_id,
            field_id: field_item.id,
            position: 1,
          });
        }}
        showNestedItems={showQuizSubQuestions}
        setShowNestedItems={setShowQuizSubQuestions}
        highlight={true}
        classNames={''}
      />

      <div
        className="field-position"
        data-field-item-id={field_item.id}
        style={{ marginLeft: "10px", position: "relative" }}
      >
        {showQuizSubQuestions && props.nested_fields == undefined ? (
          <React.Fragment>
            {sorted_nested_fields.map((nested_field, sub_index) => {
              return (
                <React.Fragment key={"quiz-field-item-" + nested_field.id}>
                  <VariantBuilderSetting
                    object={nested_field}
                    setting_name="[modal_trigger]"
                    setting_type="item_v2"
                    object_type="fields"
                    object_id={nested_field.id}
                    title={nested_field.label.toLowerCase()}
                    condition={element.element_type == "quiz"}
                    position={nested_field.position}
                    label_prepend={`${props.field_item_position}.${
                      sub_index + 1
                    }`}
                    label_icon={quizLabelIcon(nested_field.field_type)}
                    onEdit={() => {
                      builder.select("fields", nested_field.id);
                    }}
                    duplicate_payload={{
                      label: `${nested_field.label} (Copy)`,
                      element_id: nested_field.element_id,
                    }}
                    callbackOnRemove={() => {
                      setTimeout(function () {
                        let sub_questions_selector = `.field-position[data-field-item-id="${field_item.id}"]`;
                        let item_selector = `.setting[data-setting_type='item_v2'][data-object_type='fields']`;

                        const items = document.querySelectorAll(
                          `.tab-row[data-name="Quiz"] ${sub_questions_selector} ${item_selector}`
                        );

                        Fields.sortableReorderUpdates(items);
                      }, 100);
                    }}
                    label_max_length={14}
                    label_hover_max_length={6}
                    highlight={true}
                    classNames={'my-[4px] !pr-[22px]'}
                  />
                </React.Fragment>
              );
            })}

            <div>
              <NewQuizField
                element={element}
                sub_question={true}
                payload={{
                  field_id: field_item.id,
                  position: getFieldSubFieldsLength() + 1,
                }}
                {...props}
              />
            </div>
          </React.Fragment>
        ) : (
          <div
            className="cf-quiz-sortable-subfield-indicator col-xs-12 field-add-container smooth"
            style={{
              fontSize: "14px",
              color: "rgb(137, 137, 137)",
              display: "none",
              position: "absolute",
              padding: "7px",
            }}
          >
            Convert to sub-question
          </div>
        )}
      </div>

      <NewFieldModal
        show={newFieldModalVisibility}
        setShow={setNewFieldModalVisibility}
        element={element}
        payload={{
          field_id: field_item.id,
          position: getFieldSubFieldsLength() + 1,
        }}
        templates={props.field_templates}
      />
    </React.Fragment>
  );
};

export default QuizSettings;
