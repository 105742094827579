import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "./VariantBuilderContext";

const VariantBuilderCanvasRoundedCorner = (props) => {
  const [contextData] = useContext(VariantContextData);
  const vertical = props.vertical;
  const horizontal = props.horizontal;

  return (
    <React.Fragment>
      {vertical == "top" && horizontal == "left" && (
        <div className="absolute -top-[8px] right-[-10px]">
          <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_448_7778)">
              <path
                d="M0 0V9C0.586422 4.30863 4.31398 0.63178 9.01294 0.109673L10 0H0Z"
                fill={contextData.dark_mode ? "#030718" : "#fff"}
              />
            </g>
            <defs>
              <clipPath id="clip0_448_7778">
                <rect width="10" height="9" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {vertical == "top" && horizontal == "right" && (
        <div className="absolute -top-[8px] -left-[10px]">
          <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_448_7778)">
              <path
                d="M10 0V9C9.41358 4.30863 5.68602 0.63178 0.987057 0.109673L0 0H10Z"
                fill={contextData.dark_mode ? "#030718" : "#fff"}
              />
            </g>
            <defs>
              <clipPath id="clip0_448_7778">
                <rect width="10" height="9" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {vertical == "bottom" && horizontal == "left" && (
        <div className={`absolute right-[-10px] ${props.page == 'preview' ? 'bottom-[43px]' : 'bottom-[53px]'}`}>
          <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_448_7778)">
              <path
                d="M0 9V0C0.586422 4.69137 4.31398 8.36822 9.01294 8.89033L10 9H0Z"
                fill={contextData.dark_mode ? "#030718" : "#fff"}
              />
            </g>
            <defs>
              <clipPath id="clip0_448_7778">
                <rect
                  width="10"
                  height="9"
                  fill={contextData.dark_mode ? "#030718" : "#fff"}
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {vertical == "bottom" && horizontal == "right" && (
        <div className={`absolute -left-[10px] ${props.page == 'preview' ? 'bottom-[43px]' : 'bottom-[53px]'}`}>
          <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_448_7778)">
              <path
                d="M10 9V0C9.41358 4.69137 5.68602 8.36822 0.987057 8.89033L0 9H10Z"
                fill={contextData.dark_mode ? "#030718" : "#fff"}
              />
            </g>
            <defs>
              <clipPath id="clip0_448_7778">
                <rect width="10" height="9" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </React.Fragment>
  );
};

export default VariantBuilderCanvasRoundedCorner;
