import React, { useEffect } from "react";
import arePropsEqual from "../../utils/arePropsEqual";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSlideOutPanel = (props) => {
  const stylesheet = style.stylesheet(props.builder, props.device);

  let slideOutClasses = "cf-slide-out-panel";
  if (props.show) {
    slideOutClasses = "cf-slide-out-panel open";
  }

  let parsedTitle = () => {
    const baseTitle =
      props.title && props.title.length > 40
        ? props.title.substr(0, 40).trim() + "..."
        : props.title;

    let tempTitleDiv = document.createElement("div");
    tempTitleDiv.innerHTML = baseTitle;

    return tempTitleDiv.textContent || tempTitleDiv.innerText || "";
  };

  useEffect(() => {
    if (props.show) {
      if (props.callbackOnOpen) {
        props.callbackOnOpen();
      }

      $(".intercom-lightweight-app-launcher").hide();
      document.addEventListener("click", handleClick);
    } else {
      $(".intercom-lightweight-app-launcher").show();
    }

    return function cleanup() {
      document.removeEventListener("click", handleClick);
    };
  }, [props.show]);

  const handleClick = (event) => {
    let element_div = document.querySelector(`#element${props.element_id}`);

    if (element_div && element_div.contains(event.target)) {
      props.setShow(false);
    }
  };

  return (
    <React.Fragment>
      <div
        id={`cf-slide-out-panel-${props.object_id}`}
        data-setting_name={props.setting_name}
        className={slideOutClasses}
      >
        <div className="row cf-slide-out-header">
          <div className="col-xs-11">
            <div className="cf-title">{parsedTitle()}</div>
            <div className="cf-description">{props.description}</div>
          </div>
          <div
            className="col-xs-1 cf-slide-close-button"
            onClick={() => props.setShow(false)}
          >
            <FontAwesomeIcon icon="circle-xmark" />
          </div>
        </div>

        <div className="row cf-slide-out-content">{props.children}</div>

        <div className="row cf-slide-out-footer">
          <div className="col-xs-offset-9">
            <button
              onClick={() => props.setShow(false)}
              className={`btn blue-button field-title btn-block btn-lg`}
              style={{
                color: "#fff",
                border: "1px solid #00b592",
                background: "#00b592",
              }}
            >
              Done
            </button>
          </div>
        </div>

        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device) => {
    // prettier-ignore
    const desktopStyles =
    `.cf-slide-out-panel {
      height: 100%;
      padding: 20px 0px;
      background-color: #fff;
      position: fixed;
      z-index: 3;
      top: 0;
      right: 0;
      width: 515px;
      transform: translateX(100%);
    }

    .cf-slide-out-panel .row {
      margin-right: unset !important;
      margin-left: unset !important;
    }

    .cf-slide-out-panel .cf-slide-out-header {
      border-bottom: 1px solid #EBF1F9;
      padding: 13px 20px;
    }

    .cf-slide-out-panel .cf-slide-out-header .cf-title {
      font-size: 20px;
      font-weight: 700;
    }

    .cf-slide-out-panel .cf-slide-out-header .cf-description {
      font-size: 14px;
      font-weight: 500;
      color: #6E818F;
    }

    .cf-slide-out-panel .cf-slide-out-content {
      height: 85%;
      overflow-y: auto;
      overflow-x: hidden;
      border-bottom: 1px solid #EBF1F9;
    }

    .cf-slide-out-panel .tab-content {
      padding: 20px 23px;
    }

    .cf-slide-out-panel .cf-slide-out-footer {
      padding: 20px 36px;
    }

    .cf-slide-out-panel .cf-slide-close-button svg {
      font-size: 22px;
      color: #7C90A2;
      cursor: pointer;
    }

    .intercom-lightweight-app {
      z-index: 1;
    }

    .cf-slide-out-panel.open {
      transform: translateX(0);
      box-shadow: 0px 4px 19px 0px #0000001A;
    }
    `;

    // prettier-ignore
    const mobileStyles =
    `/* mobile styles */`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default React.memo(VariantBuilderSlideOutPanel, arePropsEqual);
