document.addEventListener("turbolinks:load", function () {

  $('.wistia_responsive_padding[data-id]').each(function(i, video_container) {
    $(video_container).on('mouseover', function() {
      var video_id = $(video_container).attr('data-id');
      $.getScript('https://fast.wistia.com/assets/external/E-v1.js');
      $.getScript('https://fast.wistia.com/embed/medias/' + video_id + '.jsonp');
    })
  });

  $('#upgrade').on('shown.bs.modal', function () {
    $(this).find('.wistia_responsive_padding').each(function(i, video_container) {
      var video_id = $(video_container).attr('data-id');
      $.getScript('https://fast.wistia.com/assets/external/E-v1.js');
      $.getScript('https://fast.wistia.com/embed/medias/' + video_id + '.jsonp');
    });
  })

});