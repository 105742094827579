import React, { useState, useEffect, useRef } from "react";
import { ColumnChart } from "react-chartkick";
import "chartkick/chart.js";

const ReportChart = (props) => {
  const data = props.count == 0 ? 0 : `${props.path}?${$.param(props.payload)}`;
  
  return (
    <React.Fragment>
      <ColumnChart
        loading="Loading..."
        data={data}
        thousands=","
        height={props.height}
        library={{
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${props.currency ? props.currency : ''}${context.raw.toFixed(0).toLocaleString()}`;
                },
              },
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export default ReportChart;
