import React, { useContext, useEffect, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import useContentToggle from "./useContentToggle";
import htmlToLabel from "../../utils/htmlToLabel";

const ContentToggleTabSettings = (props) => {
  const [contextData] = useContext(VariantContextData);

  const toggle_item = props.toggle_item;
  const element = contextData.objects.variant.elements[toggle_item.element_id];

  const { ContentToggle } = useContentToggle();

  useEffect(() => {
    ContentToggle.sortableStart(toggle_item);

    $(`.cf-item-box[data-object-id="${toggle_item.id}"]`).off('click').on('click', () => {
      dispatchCustomEvent("focusItem", {
        parent_id: element.id,
        item_type: "content_toggle_items",
        item_id: toggle_item.id,
      });
    });
  });

  useEffect(() => {
    if (contextData.selected_item_id == toggle_item.id) {
      const element_div = `#builder .cf-element[data-element-id="${element.id}"]`;
      const collapse_div = $(element_div).find(`.cf-tab-item[data-tab-pos="${toggle_item.position}"]`);

      if ($(collapse_div).hasClass('active') == false) {
        $(element_div).find(`.cf-tab-item[data-tab-pos="${toggle_item.position}"]`).trigger('click');
      }
    }
  }, [contextData.selected_item_id]);

  // Make sure the content has a wrapped tag when calling `text`
  let toggle_item_content = htmlToLabel(toggle_item.content);

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={toggle_item}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="content_toggle_items"
        object_id={toggle_item.id}
        title={toggle_item_content}
        condition={element.element_type == "tabs"}
        label_max_length={25}
        label_hover_max_length={14}
        style={{
          marginBottom: "0px",
        }}
        position={toggle_item.position ? toggle_item.position : ""}
        duplicate_payload={{
          element_id: element.id
        }}
        label_icon={'fas fa-folder'}
      >
        <VariantBuilderSetting
          object={toggle_item}
          label="Content"
          setting_name="[content]"
          setting_type="editor"
          object_type="content_toggle_items"
          object_id={toggle_item.id}
          value={toggle_item.content ? toggle_item.content : ""}
        />
        <VariantBuilderSetting
          object={toggle_item}
          setting_name="[content]"
          setting_type="code"
          label="HTML Editor"
          object_type="content_toggle_items"
          object_id={toggle_item.id}
          value={toggle_item.content ? toggle_item.content : ""}
          feature_gate_type="Custom HTML"
        />
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ContentToggleTabSettings;
