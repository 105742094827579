import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import ElementLayer from "../elements/ElementLayer.js";
import { VariantContextData } from "../variants/VariantBuilderContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionColumnLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const section = props.section;
  const column = props.column;

  let column_elements = Object.values(
    contextData.objects.variant.elements
  ).filter(
    (e) =>
      e.section_id == section.id &&
      e.column == column.position &&
      e.toBeDeleted !== true
  );
  let sorted_elements = column_elements.sort(function (a, b) {
    return a.position - b.position;
  });

  return (
    <VariantBuilderPanelLayersItem
      key={`section-${section.id}-column-${column.name}-layer`}
      object_type="sections"
      object_id={section.id}
      object={section}
      column={column}
      title={`Column ${column.position}`}
      open={true}
      hidden={section.options['structure'][`${column.name}-column-hide${contextData.device == 'mobile' ? '-mobile' : ''}`] == "true"}
      tab={"Columns"}
      canvasSelector={`.cf-column[data-section-id='${section.id}'][data-column-position='${column.name}']`}
      toggleForDevice={contextData.device == 'mobile' && section.options["structure"]["mobile-responsive"] !== "false" ? () => {
        builder.update([
          {
            object_type: 'sections',
            object_id: section.id,
            setting_name: `[options][structure][${column.name}-column-hide-mobile]`,
            value: `${(section.options['structure'][`${column.name}-column-hide-mobile`] == "true") == false}`
          },
        ]);
      } : undefined}
    >
      <div
        className="w-full layers-container"
        data-object-type="sections"
        data-object-id={section.id}
        data-column={column ? column.position : null}
      >
        {sorted_elements.map((element) => {
          return (
            <ElementLayer
              key={`element-${element.id}-layer`}
              element={element}
            />
          );
        })}
      </div>

      <div className="w-full pl-6 mb-3">
        <button
          onClick={() => {
            dispatchCustomEvent("newObject", {
              object_type: "Elements", 
              section_id: section.id,
              column: column.position,
            });
          }}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2" /> Add Element
        </button>
      </div>
    </VariantBuilderPanelLayersItem>
  );
};

export default SectionColumnLayer;
