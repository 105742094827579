import React, { useState, useEffect, useRef } from "react";
import useConditions from "../conditions/useConditions";
import ConditionSettingsInput from "../conditions/ConditionSettingsInput";
import detachedDisplayFeatureGate from "../../utils/detachedDisplayFeatureGate";
import CFSelect from "../shared/forms/CFSelect";
import Tooltip from "../shared/Tooltip";

const ConditionForm = (props) => {
  const condition = props.condition;
  const website = props.website;
  const conditionsList = props.conditionsList;
  const { currentConditionsHook } = useConditions(props);
  const Conditions = currentConditionsHook;
  const form = useRef(null);
  const node = useRef();
  const [formData, setFormData] = useState({
    contactable_type: props.contactable_type,
    contactable_id: props.contactable_id,
    id: condition ? condition.id : null,
    condition_type: condition ? condition.condition_type : null,
    data: condition ? condition.data : {},
    operator: condition ? condition.operator : props.operator ? props.operator : "and",
    cta_id: props.cta_id ? props.cta_id : condition && condition.cta_id ? condition.cta_id : null,
  });

  const [selectedCta, setSelectedCta] = useState({
    value: "",
    label: "All campaigns in broadcast",
  });
  const [selectedCondition, setSelectedCondition] = useState(undefined);
  const [selectedOperatorGroup, setSelectedOperatorGroup] = useState("and");
  const [operatorIsOpen, setOperatorIsOpen] = useState(false);

  useEffect(() => {
    $('[data-toggle="popover"]').popover({ html: true });
    $('[data-toggle="tooltip"]').tooltip();
  });

  useEffect(() => {
    if (condition) {
      if (props.broadcast && props.broadcast.quick != true && condition?.cta_id) {
        setSelectedCta(
          ctasSelectOptions().find((f) => f.value == condition.cta_id)
        );
      } else {
        setSelectedCta({
          value: "",
          label: "All campaigns in broadcast",
        })
      }

      setSelectedCondition({
        value: condition.condition_type,
        label: condition.condition_type,
      });
    }
  }, [condition]);

  const selectedConditionSettings = () => {
    if (selectedCondition) {
      return conditionsList[selectedCondition.value];
    }
  };

  const handleOperatorChange = (operator) => {
    let newFormData = formData;
    newFormData.operator = operator;
    setFormData(newFormData);

    setOperatorIsOpen(false);

    if (props.builder) {
      dispatchCustomEvent("updateBuilder", {
        updates: [
          {
            object_type: "conditions",
            object_id: condition.id,
            setting_name: "[operator]",
            value: operator,
          },
        ],
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = (e, newFormData) => {
    setTimeout(() => {
      let isFormValid = form.current.checkValidity();

      if (!selectedCondition || !isFormValid) {
        return false;
      }

      let payload = { ...(newFormData || formData), website_id: website.id };

      form.current.querySelectorAll('input[data-input-type="hidden"]').forEach((input) => {
        const name = input.getAttribute("name");
        const value = input.getAttribute("value");

        if (name && value) {
          _.set(payload, name.replace('condition', ''), value);
        }
      });

      if (condition) {
        Conditions.update(payload, (data) => {
          props.onUpdate(data);
        });
      } else if (detachedDisplayFeatureGate(e, conditionsList, selectedCondition.value)) {
        Conditions.create(payload, (data) => {
          props.onCreate(data);
        });
      }
    }, 150);
  };

  const ctasSelectOptions = () => {
    let selectOptions = [
      {
        value: "",
        label: "All campaigns in broadcast",
      },
    ];

    const broadcast_ctas_ids =
      (window.broadcast && window.broadcast.ctas) ||
      Object.values(props.broadcast.ctas).map((c) => c.id);

    broadcast_ctas_ids.forEach((cta_id) => {
      let cta = website.ctas[cta_id];

      if (cta) {
        if (
          cta.cta_type == "page" &&
          window.location.href.includes("/broadcasts/")
        ) {
          return;
        }

        selectOptions.push({
          label:
            cta.cta_type.substr(0, 1).toUpperCase() +
            cta.cta_type.substr(1) +
            " – " +
            (cta.name || "Campaign " + cta.id),
          value: cta.id,
        });
      }
    });

    return selectOptions;
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOperatorIsOpen(false);
  };


  useEffect(() => {
    if (operatorIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [operatorIsOpen]);

  return (
    <React.Fragment>
      <div
        className={`w-full relative max-w-[648px] float-left ${!condition ? 'new-condition' : ''}`}
        data-attribute="condition_type"
      >
        <form
          className={`flex gap-x-3 relative ${!condition ? "pb-[10px]" : ""}`}
          data-remote="true"
          ref={form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input name="builder" value="true" type="hidden" />
          <input
            name={"condition[contactable_type]"}
            value={props.contactable_type}
            type="hidden"
          />
          <input
            name={"condition[contactable_id]"}
            value={props.contactable_id}
            type="hidden"
          />

          {condition && (
            <input name="condition[id]" value={condition.id} type="hidden" />
          )}

          <input
            name="condition[operator]"
            value={formData.operator}
            type="hidden"
          />

          {condition && (
            <div className="text-left" ref={node}>
              <button
                className="w-[45px] h-[34px] bg-slate-200 dark:bg-slate-800 text-black dark:text-white py-2 px-4 text-center rounded-lg border-none text-xl"
                onClick={(e) => {
                  e.preventDefault();
                  setOperatorIsOpen(!operatorIsOpen)
                }}
              >
                {condition.operator == "or" ? "OR" : "AND"}
              </button>
              {operatorIsOpen && (
                <div
                  className="absolute w-[50px] rounded-lg shadow-lg overflow-hidden bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-10"
                  style={{ top: "85%" }}
                >
                  <div
                    className={`w-full cursor-pointer px-5 py-2 text-lg overflow-hidden overflow-ellipsis ${condition.operator == "or" ? 'dark:bg-slate-950' : ''} hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 hover:text-black dark:text-gray-300 dark:hover:text-white`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (condition.operator == "or") {
                        handleOperatorChange("and");
                      }
                    }}
                  >
                    AND
                  </div>
                  <div
                    className={`w-full cursor-pointer px-5 py-2 text-lg overflow-hidden overflow-ellipsis ${condition.operator == "and" ? 'dark:bg-slate-950' : ''} hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 hover:text-black dark:text-gray-300 dark:hover:text-white`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (condition.operator == "and") {
                        handleOperatorChange("or");
                      }
                    }}
                  >
                    OR
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="flex-grow min-w-[25%] max-w-[50%]">
            {!condition && (
              <div className="absolute left-[-20px]">
                <div className="relative mt-[5px]">
                  {selectedConditionSettings() &&
                    selectedConditionSettings().description && (
                      <Tooltip
                        tooltip={selectedConditionSettings().description}
                      />
                    )}
                </div>
              </div>
            )}
            <CFSelect
              id="cf-select-condition_type"
              className="smart-select"
              isSearchable={true}
              required={true}
              name="condition[condition_type]"
              options={props.conditionSelectOptions}
              value={selectedCondition}
              onChange={(e) => {
                const conditionType = e.value;

                let newFormData = formData;
                newFormData.condition_type = conditionType;
                setFormData(newFormData);

                setSelectedCondition({
                  value: conditionType,
                  label: conditionType,
                });

                if (
                  conditionsList[e.value] &&
                  conditionsList[e.value]["operator"]
                ) {
                  setSelectedOperatorGroup(conditionsList[e.value]["operator"]);
                }

                if (props.builder && condition && detachedDisplayFeatureGate(undefined, conditionsList, conditionType)) {
                  dispatchCustomEvent("updateBuilder", {
                    updates: [
                      {
                        object_type: "conditions",
                        object_id: condition.id,
                        setting_name: "[condition_type]",
                        value: conditionType,
                      },
                    ],
                  });
                } else if (detachedDisplayFeatureGate(undefined, conditionsList, conditionType)) {
                  handleSubmit(e, newFormData);
                }
              }}
            />
          </div>

          {setSelectedCondition &&
            selectedConditionSettings() &&
            selectedConditionSettings()["settings"] && (
              <React.Fragment>
                {selectedConditionSettings() &&
                  selectedConditionSettings()["settings"].map(
                    (setting, index) => {
                      let integrationType =
                        selectedConditionSettings() &&
                        selectedConditionSettings().group;

                      let integration = $.map(
                        website.integrations,
                        function (integration) {
                          if (integration.integration_type == integrationType)
                            return integration;
                        }
                      )[0];

                      return (
                        <ConditionSettingsInput
                          condition={condition}
                          key={`${setting.name}-${setting.label}-${index}`}
                          setting={setting}
                          integration={integration}
                          handleSubmit={handleSubmit}
                          formData={formData}
                          setFormData={setFormData}
                          onChange={(e) => {
                            let newFormData = formData;
                            _.set(
                              newFormData,
                              `${
                                !integration
                                  ? setting.attribute
                                  : `[data][${setting.attribute}]`
                              }`,
                              e.value || e.target.value
                            );
                            setFormData(newFormData);

                            if (
                              condition &&
                              !props.builder &&
                              setting.input_type == "select"
                            ) {
                              handleSubmit(e, newFormData);
                            }
                          }}
                          onDone={(e) => {
                            if (condition) {
                              if (props.builder) {
                                const value = e.value || e.target.value;
                                dispatchCustomEvent("updateBuilder", {
                                  updates: [
                                    {
                                      object_type: "conditions",
                                      object_id: condition.id,
                                      setting_name: setting.attribute,
                                      value: value,
                                    },
                                  ],
                                });
                              } else if (setting.input_type !== "select") {
                                handleSubmit(e);
                              }
                            }
                          }}
                          {...props}
                        />
                      );
                    }
                  )}
              </React.Fragment>
            )}

          {!condition && (
            <button
              className={`w-[75px] border-0 border-transparent py-3 px-3 rounded-lg text-xl ml-1 mr-1 bg-blue-600 text-white cursor-pointer float-right`}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              Save
            </button>
          )}
        </form>

        {selectedCondition && conditionsList[selectedCondition.value] && conditionsList[selectedCondition.value].note && (
          <div className="row hard-center text-base text-slate-500" dangerouslySetInnerHTML={{__html: conditionsList[selectedCondition.value].note}}>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConditionForm;
