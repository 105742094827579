import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ColorPicker from "react-best-gradient-color-picker";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingColorV2 = (props) => {
  const node = useRef(null);
  const [contextData, builder] = useContext(VariantContextData);
  const [originalValue, setOriginalValue] = useState(
    props.value ? props.value : null
  );
  const [defaultValue, setDefaultValue] = useState(
    props.value ? props.value : null
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const [pickerVisible, setPickerVisible] = useState(false);

  let brand_color_light = "#ffffff";
  if (contextData.objects.website.data["brand_color_light"]) {
    brand_color_light = contextData.objects.website.data["brand_color_light"];
  }

  let brand_color_vibrant = "#0D42E4";
  if (contextData.objects.website.data["brand_color_vibrant"]) {
    brand_color_vibrant =
      contextData.objects.website.data["brand_color_vibrant"];
  }

  let brand_color_dark = "#000000";
  if (contextData.objects.website.data["brand_color_dark"]) {
    brand_color_dark = contextData.objects.website.data["brand_color_dark"];
  }

  function updateColor(value) {
    setDefaultValue(value);

    if (!value || value !== originalValue) {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          old_value: originalValue,
        },
      ]);
      setOriginalValue(value);

      if (props.callback) {
        props.callback(value, originalValue);
      }
    }
  }

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target) || e.target.classList.contains("input-color-picker-toggle")) {
      // inside click
      return;
    }
    // outside click
    setPickerVisible(false);
  };

  useEffect(() => {
    setOriginalValue(props.value ? props.value : "");
    setDefaultValue(props.value ? props.value : "");
  }, [props.object_id, props.last_reset, props.value]);

  useEffect(() => {
    if (pickerVisible) {
      document.addEventListener("mousedown", handleClickOutside);

      var div = node.current;
      var limit = 0;

      var rect = div.getBoundingClientRect();
      console.log(limit, rect.top);
      if (rect.top < limit) {
        div.style.top = '0px';
      }
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerVisible]);

  return (
    <React.Fragment>
      <input
        type="text"
        name={props.setting_name}
        data-object_id={props.object_id}
        value={defaultValue}
        className="w-full p-3 pl-[40px] bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0"
        placeholder={props.placeholder}
        onChange={(e) => {
          let value = e.target.value;
          if (
            [
              "brand_color_dark",
              "brand_color_light",
              "brand_color_vibrant",
              "BLACK",
              "MEDIUMBLUE",
              "WHITE",
            ].indexOf(value) == -1
          ) {
            if (value.indexOf("#") > -1) {
              value = value.replace("#", "");
            }
            if (value && value.indexOf("#") == -1 && value.indexOf("(") == -1) {
              value = "#" + value;
            }
          }
          updateColor(value);
        }}
      />

      <div
        data-setting_name={props.setting_name}
        className="position-relative w-[30px] input-color-picker-toggle !border-slate-200 dark:!border-slate-700"
        data-object_id={props.object_id}
        style={{
          background: props.value
            ? props.value
            : props.placeholder
            ? props.placeholder
            : undefined,
        }}
        onClick={(e) => {
          let colorpickerToggleInput = `.input-color-picker-toggle[data-setting_name="${props.setting_name}"]`;
          if (props.object_id) {
            colorpickerToggleInput += `[data-object_id="${props.object_id}"]`;
          }

          let colorpickerToggle = document.querySelector(
            colorpickerToggleInput
          );

          if (e.target == colorpickerToggle) {
            setPickerVisible(!pickerVisible);
          }
        }}
      ></div>

      {pickerVisible == true && (
        <div
          ref={node}
          className={`absolute z-20 w-[250px] bg-slate-800 rounded-lg p-5`}
          style={{
            left: props.offset_left ? '-125px' : '13px',
            top: '-385px'
          }}
        >
          <ColorPicker
            value={
              props.value
                ? props.value
                : props.placeholder
                ? props.placeholder
                : undefined
            }
            onChange={updateColor}
            width={225}
            height={150}
            hideAdvancedSliders={true}
            hideColorTypeBtns={props.gradient !== true}
            hideColorGuide={true}
            hideInputType={true}
            presets={[
              brand_color_light,
              brand_color_vibrant,
              brand_color_dark,
              "#000000",
              "#808080",
              "#c0c0c0",
              "#ffffff",
              "#000080",
              "#0000ff",
              "#00ffff",
              "#008000",
              "#008080",
              "#00ff00",
              "#800000",
              "#800080",
              "#af33f2",
              "#ff00ff",
              "#ff0000",
              "#f0672e",
              "#ffff00",
            ]}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default VariantBuilderSettingColorV2;
