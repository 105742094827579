var reports = {
  rangeMap: {
    Today: "group_by_hour_of_day",
    "Last 7 Days": "group_by_day",
    "Last 30 Days": "group_by_week",
    "Last 90 Days": "group_by_month",
    "Last 365 Days": "group_by_month",
    "All time": "group_by_month",
    "This Month": "group_by_week",
  },

  treatAsUTC: function (date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  },

  daysBetween: function (startDate, endDate) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (
      (reports.treatAsUTC(endDate) - reports.treatAsUTC(startDate)) /
      millisecondsPerDay
    );
  },

  groupByType: function (range_start, range_end) {
    var difference = reports.daysBetween(range_start, range_end);
    if (difference < 1) {
      var timeframe = "group_by_hour_of_day";
    } else if (difference < 8) {
      var timeframe = "group_by_day";
    } else if (difference < 32) {
      var timeframe = "group_by_week";
    } else if (difference < 93) {
      var timeframe = "group_by_month";
    } else if (difference < 366) {
      var timeframe = "group_by_month";
    } else {
      var timeframe = "group_by_quarter";
    }
    return timeframe;
  },

  calculateConversions: function (viewers, conversions, target) {
    if ($(target).length) {
      $(target).html(
        '<img src="https://s3.amazonaws.com/leadflow/assets/load.gif" style="width: 20px; height: 20px; margin-top: -19px;" />'
      );
      if (
        $(viewers).find("img").length == 0 &&
        $(conversions).find("img").length == 0
      ) {
        var a = parseInt($(viewers).text().replace(/,/g, ""));
        var b = parseInt($(conversions).text().replace(/,/g, ""));
        var percentage = (b / a) * 100;
        if (isNaN(percentage) == false) {
          $(target).text(percentage.toFixed(2) + "%");
        } else {
          $(target).text("0%");
        }
      }
    }
  },

  getReport: function (endpoint, identifier, container, payload) {
    $("#" + identifier).html(
      '<img src="https://s3.amazonaws.com/leadflow/assets/load.gif" style="width: 20px; height: 20px; margin-top: -19px;" />'
    );
    $.ajax({
      type: "GET",
      url: endpoint,
      data: payload,
      success: function (data) {
        $("#" + identifier).html(data.toLocaleString("en-US"));
        if (container !== undefined) {
          var viewers = $("#" + container).find('.stat[data-name="views"]')[0];
          if (
            payload["query"] == undefined ||
            payload["query"]["event_type"] !== "Completion"
          ) {
            var conversions = $("#" + container).find(
              '.stat[data-name="conversions"]'
            )[0];
            var target = $("#" + container).find(
              '.stat[data-name="conversion_rate"]'
            )[0];
          } else if (payload["query"]["event_type"] == "Completion") {
            var conversions = $("#" + container).find(
              '.stat[data-name="completions"]'
            )[0];
            var target = $("#" + container).find(
              '.stat[data-name="completion_rate"]'
            )[0];
          }
          reports.calculateConversions(viewers, conversions, target);
        }
      },
    });
  },

  getList: function (endpoint, identifier, payload, value_type, count_type) {
    $("#" + identifier).html(
      '<div class="col-xs-12 center vertical-space"><img src="https://s3.amazonaws.com/leadflow/assets/load.gif"style="width: 20px; height: 20px;" /></div>'
    );
    $.ajax({
      type: "GET",
      url: endpoint,
      data: payload,
      success: function (data) {
        $("#" + identifier).html(
          '<div class="row hard-center list-stat"><div class="col-xs-10 left"><p><strong>' +
            value_type +
            '</strong></p></div><div class="col-xs-2 left"><p><strong>' +
            count_type +
            "</strong></p></div></div>"
        );
        $.each(data, function (value, count) {
          $("#" + identifier).append(
            '<div class="row hard-center list-stat"><div class="col-xs-10 left"><p class="light">' +
              value +
              '</p></div><div class="col-xs-2 left"><p class="light">' +
              count +
              "</p></div></div>"
          );
        });
      },
    });
  },

  getChart: function (endpoint, identifier, chart_type, payload) {
    $.ajax({
      type: "GET",
      url: "https://www.gstatic.com/charts/loader.js",
      dataType: "script",
      cache: true,
      success: function () {
        $("#" + identifier)
          .css(
            "background",
            "url(https://s3.amazonaws.com/leadflow/assets/load.gif) no-repeat center center"
          )
          .css("background-size", "40px");
        $.ajax({
          type: "GET",
          url: endpoint,
          data: payload,
          success: function (data) {
            if (data) {
              if (chart_type == "BarChart") {
                var count_col = "#" + $("#" + identifier).attr("data-col");
                var count_type = $("#" + identifier).attr("data-count");
                var count = Object.keys(data).length;
                $("#" + identifier).css("margin-top", -5 * count);
                $(count_col).html("").css("padding", "40px 0 0");
                if (count == 9) {
                  var style = "margin: 2px 0 0;";
                }
                if (count == 9) {
                  var style = "margin: 4px 0 0;";
                }
                if (count == 8) {
                  var style = "margin: 8px 0 0;";
                }
                if (count == 7) {
                  var style = "margin: 13px 0 0;";
                }
                if (count == 6) {
                  var style = "margin: 19px 0 0;";
                }
                if (count == 6) {
                  var style = "margin: 19px 0 0;";
                }
                if (count == 5) {
                  var style = "margin: 27px 0 0;";
                }
                if (count == 4) {
                  var style = "margin: 33px 0 7px;";
                }
                if (count == 3) {
                  $(count_col).css("padding", "55px 0 0");
                  var style = "margin: 30px 0 30px;";
                }
                if (count == 2) {
                  $(count_col).css("padding", "60px 0 0");
                  var style = "margin: 50px 0 50px;";
                }
                if (count == 1) {
                  $(count_col).css("padding", "160px 0 0");
                  var style = "margin: 15px 0 0;";
                }
                $.each(data, function (k, v) {
                  $(count_col).append(
                    '<div class="col-xs-12" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 13px; ' +
                      style +
                      '">' +
                      v.toLocaleString("en-US") +
                      " " +
                      count_type +
                      "</div>"
                  );
                });
              }
              $("#" + identifier).css("background", "");
              new Chartkick[chart_type](identifier, data, {
                colors: ["#003FFF"],
                fontName:
                  "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif",
              });
            }
          },
        });
      },
    });
  },
};

export default reports;
