async function apiRequest(method, api_endpoint, payload, callback) {
  let status;
  let message;
  let csrf_token;
  let metaTag = document.querySelector('meta[name="csrf-token"]');

  if (metaTag) {
    csrf_token = metaTag.getAttribute("content");
  }
  
  const result = await fetch(api_endpoint, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": csrf_token,
    },
    method: method == "PUT_WITH_RESPONSE" ? "PUT" : method,
    body: JSON.stringify({ ...(payload || {}) }),
  })
    .then((response) => {
      status = response.status;
      message = response.statusText;
      return ["POST", "PUT_WITH_RESPONSE"].includes(method) ? response.json() : {};
    })
    .then((data) => {
      if (data && callback) {
        callback(data);
      }
      return {
        code: status,
        message: message,
        json: data,
      };
    })
    .catch(function (error) {
      return {
        code: 408,
        message: "Could not connect to server",
        json: {},
      };
    });
  return result;
}

export default apiRequest;