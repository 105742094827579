import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import ElementTemplateItem from "./ElementTemplateItem";
import { capitalize } from "lodash";

const NewElementTemplates = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const [searchText, setSearchText] = useState("");

  let templates = props.templates;

  const searchedTemplates = templates.filter(function (template) {
    return (
      template.element_type === props.element_type &&
      template.name.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const createElement = function (template_id = 0) {
    if (template_id > 0) {
      props.createCallback(props.element_type, template_id);
    } else {
      props.createCallback(props.element_type);
    }
  };

  return (
    <React.Fragment>
      <div id="new-element-templates" className="w-full new-element-templates">
        <div className="w-full text-xl font-semibold text-black dark:text-white">
          {capitalizeFirstLetter(props.title)} Elements
        </div>

        <div className="w-full mt-4 relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400">
            <i className="fas fa-search text-2xl"></i>
          </span>
          <input
            type="text"
            name="name"
            className="w-full px-2 py-4 pl-8 text-xl rounded-lg text-slate-500 dark:focus:color-white bg-white dark:bg-slate-950  border-none focus:border-none focus:outline-none"
            placeholder="Search..."
            defaultValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div id="elements-create-from-scratch" className="w-full">
          <div
            className="cursor-pointer w-full h-[50px] mt-4 mb-9 text-center leading-[50px] text-lg rounded-lg text-slate-500 hover:text-blue-600 dark:hover:text-white bg-white hover:bg-white dark:bg-slate-950 dark:hover:bg-slate-700 border-dashed border-[1px] border-slate-300 hover:border-blue-600 dark:border-slate-500 dark:hover:border-white"
            onClick={createElement}
            data-type={props.element_type}
          >
            <div data-type={props.element_type}>Create From Scratch</div>
          </div>
        </div>

        {searchedTemplates.map((item) => (
          <ElementTemplateItem
            key={item.id}
            item={item}
            selectTemplate={(e) => createElement(e)}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default NewElementTemplates;
