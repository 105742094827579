var initialize = function () {
  $(".group-select").each(function (i, group_select_div) {
    $(group_select_div)
      .unbind("click")
      .on("click", function () {
        if ($(this).attr("data-id") !== "nil") {
          $('.object-filter input[name="group_id"]').val(
            $(this).attr("data-id")
          );
          $(".object-filter").submit();
        }
      });
  });

  $(".group .object-update").each(function (i, update_icon) {
    $(update_icon).on("click", function (e) {
      $("#groupsModal").modal("hide");
      var targetSelector = $(update_icon).attr("data-target");
      $(targetSelector).modal("show");
    });
  });
};

export default initialize;
