import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeForms = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Forms" open={false}>
        <VariantBuilderSetting
          object={variant}
          label={"Field Font"}
          setting_name="[options][field-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["field-font"]
              ? variant.options["field-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][field-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["field-font-weight"]
              ? variant.options["field-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Field Font size"
          setting_name="[options][field-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["field-font-size"]
              ? variant.options["field-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Field Font size"
          setting_name="[options][field-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["field-font-size-mobile"]
              ? variant.options["field-font-size-mobile"]
              : variant.options["field-font-size"]
              ? variant.options["field-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />
        <VariantBuilderSetting
          object={variant}
          label={"Field Text Color"}
          setting_name="[options][field-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["field-text-color"]
              ? variant.options["field-text-color"]
              : "#000"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Label Font Family"
          setting_name="[options][field-label-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["field-label-font-family"]
              ? variant.options["field-label-font-family"]
              : undefined
          }
          placeholder={
            variant.options["field-label-font-family"]
              ? variant.options["field-label-font-family"]
              : variant.options["field-font"]
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "font-default",
              text: "Font Default",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][field-label-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["field-label-font-weight"]
              ? variant.options["field-label-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Label Font Size"
          setting_name="[options][field-label-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["field-label-font-size"]
              ? variant.options["field-label-font-size"]
              : 15
          }
          placeholder={variant.options["field-font-size"] || 15}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Label Font Size"
          setting_name="[options][field-label-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["field-label-font-size-mobile"]
              ? variant.options["field-label-font-size-mobile"]
              : variant.options["field-label-font-size"]
              ? variant.options["field-label-font-size"]
              : 15
          }
          placeholder={variant.options["field-label-font-size"] || 15}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />
        
        <VariantBuilderSetting
          object={variant}
          label="Field Label Color"
          setting_name="[options][label-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["label-color"]
              ? variant.options["label-color"]
              : "#232323"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Field Rounding"
          setting_name="[options][field-rounded]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["field-rounded"]
              ? variant.options["field-rounded"]
              : "cf-field-slightly-rounded"
          }
          valueMappings={{
            none: 'cf-field-not-rounded',
            some: 'cf-field-slightly-rounded',
            full: 'cf-field-fully-rounded',
            custom: 'custom',
          }}
        />
        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][field-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(variant.options[`field-radius-${side}`]) == false
                      ? variant.options[`field-radius-${side}`]
                      : "3"
                  }
                  condition={variant.options["field-rounded"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
        <VariantBuilderSetting
          object={variant}
          label={"Field Background Color"}
          setting_name="[options][field-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["field-background-color"]
              ? variant.options["field-background-color"]
              : "#fff"
          }
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Field Border Style"
          setting_name="[options][field-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["field-border-style"]
              ? variant.options["field-border-style"]
              : "solid"
          }
        />
        <VariantBuilderSetting
          object={variant}
          label="Field border color"
          setting_name="[options][field-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["field-border-color"]
              ? variant.options["field-border-color"]
              : "#DBDBDB"
          }
          condition={variant.options["field-border-style"] !== "none"}
        />
        {variant.options["field-border-style"] !== "none" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Field Border Width
                </div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][field-border-width-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={variant}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="variants"
                      value={
                        _.isNil(
                          variant.options[`field-border-width-${side}`]
                        ) == false
                          ? variant.options[`field-border-width-${side}`]
                          : "1"
                      }
                      placeholder="0px"
                      col="3"
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Range field handle"
          setting_name="[options][field-handle-shape]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["field-handle-shape"]
              ? variant.options["field-handle-shape"]
              : "rectangle"
          }
          options={[
            { value: "rectangle", text: "Rectangle" },
            { value: "square", text: "Square" },
            { value: "circle", text: "Circle" },
          ]}
        />

        <VariantBuilderSetting
          object={variant}
          label="Range Handle Color"
          setting_name="[options][field-handle-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["field-handle-color"]
              ? variant.options["field-handle-color"]
              : "#fff"
          }
        />
        <VariantBuilderSetting
          object={variant}
          label="Range Handle Border"
          setting_name="[options][field-handle-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["field-handle-border-color"]
              ? variant.options["field-handle-border-color"]
              : "#D9D9D9"
          }
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeForms;
