import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ProductVariantSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const website = contextData.objects.website;
  const product = props.product;
  const product_variant = props.product_variant;

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={product_variant}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="product_variants"
        object_id={product_variant.id}
        title={
          product_variant.title
            ? product_variant.title
            : product_variant.position
            ? `Product Variant ${product_variant.position}`
            : `Product Variant ${product_variant.id}`
        }
        style={{
          marginBottom: "0px",
        }}
        position={product_variant.position ? product_variant.position : "1"}
        warning={!product_variant.title ? "Required info missing" : ""}
        warningBorder={false}
        label_icon={"fas fa-tshirt"}
        duplicate_payload={{
          product_id: product_variant.product_id
        }}
        label_max_length={100}
        label_hover_max_length={100}
      >
        <VariantBuilderSetting
          object={product_variant}
          setting_name="[title]"
          setting_type="text"
          label="Title"
          object_type="product_variants"
          object_id={product_variant.id}
          value={product_variant.title ? product_variant.title : undefined}
          warning={!product_variant.title ? "Title required" : ""}
        />

        <VariantBuilderSetting
          object={product_variant}
          setting_name="[price]"
          setting_type="number"
          label="Price"
          object_type="product_variants"
          object_id={product_variant.id}
          value={product_variant.price}
          min={0}
          note="Leave blank to use the product's default price"
          col="6"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={product_variant}
          setting_name="[discounted_price]"
          setting_type="number"
          label="Sale Price"
          object_type="product_variants"
          object_id={product_variant.id}
          value={product_variant.discounted_price}
          min={0}
          col="6"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={product}
          setting_name="[currency-message]"
          setting_type="html"
          object_type="products"
          object_id={product.id}
        >
          <div className="row hard-center" style={{ marginTop: "-15px" }}>
            <div
              className="text-lg text-black dark:text-white"
            >
              <strong>Currency:</strong> {website.currency_character}
              <a
                style={{ float: "right" }}
                href={`/websites/${website.id}/edit`}
                target="_blank"
              >
                Edit →
              </a>
            </div>
          </div>
        </VariantBuilderSetting>

        <VariantBuilderSetting
          object={product_variant}
          setting_name="[image]"
          setting_type="image"
          label="Image"
          object_type="product_variants"
          object_id={product_variant.id}
          value={product_variant.image ? product_variant.image : null}
          note="Leave blank to use the product's default image"
        />

        <VariantBuilderSetting
          object={product_variant}
          setting_name="[redirect_url]"
          setting_type="text"
          label="Product Link URL"
          object_type="product_variants"
          object_id={product_variant.id}
          value={
            product_variant.redirect_url ? product_variant.redirect_url : null
          }
          placeholder="https://"
          note="Leave blank to use the product's default image"
        />

        <VariantBuilderSetting
          object={product_variant}
          setting_name="[description]"
          setting_type="text"
          label="Description"
          object_type="product_variants"
          object_id={product_variant.id}
          value={
            product_variant.description
              ? product_variant.description
              : undefined
          }
        />
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ProductVariantSettings;
