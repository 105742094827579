import React, { useContext, useState, useEffect } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import SectionColumnLayer from "../sections/SectionColumnLayer.js";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { isSectionColumnsMobileSorted } from "../../utils/builderUtils";

const SectionLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const section = props.section;

  let columns = [{ name: "first", position: 1 }];

  if (
    section.options["structure"]["column-layout"] &&
    ["100"].includes(section.options["structure"]["column-layout"]) == false
  ) {
    columns.push({ name: "second", position: 2 });
  }

  if (
    ["33-33-33", "50-25-25", "25-25-50", "25-50-25", "25-25-25-25"].includes(
      section.options["structure"]["column-layout"]
    )
  ) {
    columns.push({ name: "third", position: 3 });
  }

  if (["25-25-25-25"].includes(section.options["structure"]["column-layout"])) {
    columns.push({ name: "fourth", position: 4 });
  }

  let sorted_columns = Object.values(columns).sort((a, b) => {
    let a_position = _.get(section, `options.structure.cf-column-${a.name}-position`);
    let b_position = _.get(section, `options.structure.cf-column-${b.name}-position`);
    return a_position - b_position;
  })

  let sorted_columns_mobile = Object.values(columns).sort((a, b) => {
    let a_position = _.get(section, `options.structure.cf-column-${a.name}-mobile-position`);
    let b_position = _.get(section, `options.structure.cf-column-${b.name}-mobile-position`);
    return a_position - b_position;
  })

  // If mobile columns are not sorted, set the desktop columns to the default order
  if (isSectionColumnsMobileSorted(section) == false) {
    sorted_columns_mobile = sorted_columns;
  }

  const columnsList = (
    <div className="layers-container" data-object-id={section.id} data-object-type={'sections-columns'}>
      {contextData.device === 'desktop' && (
        <div
          className="layer-columns-container"
          data-object-type="sections"
          data-object-id={section.id}
          data-device="desktop"
        >
          {sorted_columns.map((column) => {
            return (
              <SectionColumnLayer
                key={`section-${section.id}-column-${column.name}-layer`}
                column={column}
                {...props}
              />
            );
          })}
        </div>
      )}

      {contextData.device === 'mobile' && (
        <div
          className="layer-columns-container"
          data-object-type="sections"
          data-object-id={section.id}
          data-device="mobile"
        >
          {sorted_columns_mobile.map((column) => {
            return (
              <SectionColumnLayer
                key={`section-${section.id}-column-${column.name}-layer`}
                column={column}
                {...props}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  return !section.content_toggle_item_id ? (
    <VariantBuilderPanelLayersItem
      object_type={"sections"}
      wrapper_object_type={section.element_id ? "elements" : null}
      object_id={section.id}
      wrapper_object_id={section.element_id ? section.element_id : null}
      title={props.title ? props.title : `Section ${section.position}`}
      open={!section.element_id ? true : false}
      object={section}
      element={props.element ? props.element : null}
      element_type={props.element ? props.element.element_type : null}
      canvasSelector={`.cf-section[data-section-id="${section.id}"] .cf-section-overlay`}
      toggleForDevice={() => {
        if (props.element) {
          builder.update([
            {
              object_type: 'elements',
              object_id: props.element.id,
              setting_name: `[options][hide-${contextData.device}]`,
              value: `${(props.element.options[`hide-${contextData.device}`] == "true") == false}`
            },
          ]);
        } else {
          builder.update([
            {
              object_type: 'sections',
              object_id: section.id,
              setting_name: `[options][structure][hide-${contextData.device}]`,
              value: `${(section.options['structure'][`hide-${contextData.device}`] == "true") == false}`
            },
          ]);
        }
      }}
    >
      {columnsList}
    </VariantBuilderPanelLayersItem>
  ) : (
    columnsList
  );
};

export default SectionLayer;
