import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsVideo = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      {element.element_type == "video" && (
        <React.Fragment>
          <VariantBuilderSetting
            object={element}
            label="Video URL"
            setting_name={"[options][video-embed-url]"}
            setting_type="text"
            object_type="elements"
            object_id={element.id}
            placeholder="https://www.youtube.com/watch?v=..."
            value={
              element.options["video-embed-url"]
                ? element.options["video-embed-url"]
                : ""
            }
            condition={element.element_type == "video"}
            warning={
              !element.options["video-embed-url"]
                ? "Video URL required"
                : undefined
            }
            note="YouTube, Vimeo, Wistia URL or iframe source"
          />

          <VariantBuilderSetting
            object={element}
            label={"Controls"}
            setting_name={"[options][video-embed-controls]"}
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["video-embed-controls"]
                ? element.options["video-embed-controls"]
                : "true"
            }
            condition={
              element.element_type == "video" &&
              element.options["video-embed-url"] &&
              element.options["video-embed-url"].indexOf("youtube") > -1
            }
            col="4"
          />

          <VariantBuilderSetting
            object={element}
            label={"Autoplay"}
            setting_name={"[options][video-embed-autoplay]"}
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["video-embed-autoplay"]
                ? element.options["video-embed-autoplay"]
                : "false"
            }
            condition={element.element_type == "video"}
            col="4"
          />

          <VariantBuilderSetting
            object={element}
            label={"Loop"}
            setting_name={"[options][video-embed-loop]"}
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["video-embed-loop"]
                ? element.options["video-embed-loop"]
                : "false"
            }
            condition={
              element.element_type == "video" &&
              element.options["video-embed-url"] &&
              (element.options["video-embed-url"].indexOf("youtube") > -1 ||
                element.options["video-embed-url"].indexOf("vimeo") > -1)
            }
            col="4"
          />

          <VariantBuilderSetting
            object={element}
            label={"Show Related From YouTube"}
            setting_name={"[options][video-embed-related]"}
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["video-embed-related"]
                ? element.options["video-embed-related"]
                : "false"
            }
            condition={
              element.element_type == "video" &&
              element.options["video-embed-url"] &&
              element.options["video-embed-url"].indexOf("youtube") > -1
            }
            tooltip="Defaults to related videos being limited to the video's channel. Toggle to allow other channels videos to be recommended."
            col={12}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ElementSettingsVideo;
