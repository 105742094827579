import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import ElementSettingsImage from "../elements/ElementSettingsImage";

const ElementSettingsImageDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Image" open={true}>
        <ElementSettingsImage {...props} />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsImageDesign;
