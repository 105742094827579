const ActionsArray = (website, integrations, existing_actions) => {
  let services = {};
  let fields = {};

  if (website && website.fields) {
    Object.values(website.fields).forEach((field) => {
      if (field.slug) {
        fields[field.slug] = { id: field.slug, name: field.slug };
      }
    });
  }

  services["ConvertFlow"] = {
    integration_type: "ConvertFlow",
    icon: 'convertflow-icon-no-bg.svg',
    api_data: {
      tags: website && website.tags,
      fields: fields,
      ctas: website && website.ctas,
    },
    action_types: [
      {
        action_type: "Send alert",
        settings: [
          {
            input_type: "text",
            attribute: "[recipients]",
            label: "Email recipients",
            placeholder: "Comma separated emails",
          },
        ],
      },
      {
        action_type: "Add a tag",
        settings: [
          {
            input_type: "select",
            attribute: "[tag_id]",
            label: "ConvertFlow tag",
            placeholder: "Select a tag",
            dropdown: "tags",
          },
        ],
      },
      {
        action_type: "Remove a tag",
        settings: [
          {
            input_type: "select",
            attribute: "[tag_id]",
            label: "ConvertFlow tag",
            placeholder: "Select a tag",
            dropdown: "tags",
          },
        ],
      },
      {
        action_type: "Update custom field",
        settings: [
          {
            input_type: "select",
            attribute: "[data][field]",
            label: "Custom field",
            placeholder: "Select a custom field",
            dropdown: "fields",
          },
          {
            input_type: "text",
            attribute: "[data][value]",
            label: "Field value",
            placeholder: "Value",
          },
        ],
      },
      {
        action_type: "Update score",
        settings: [
          {
            input_type: "select",
            attribute: "[data][field]",
            label: "Update score",
            placeholder: "Select a field",
            dropdown: "fields",
          },
          {
            input_type: "number",
            attribute: "[data][number]",
            label: "Score number",
            placeholder: "0",
          },
        ],
      },
      {
        action_type: "Add to score",
        settings: [
          {
            input_type: "select",
            attribute: "[data][field]",
            label: "Add to score",
            placeholder: "Select a field",
            dropdown: "fields",
          },
          {
            input_type: "number",
            attribute: "[data][number]",
            label: "Number to add",
            placeholder: "0",
          },
        ],
      },
      {
        action_type: "Subtract from score",
        settings: [
          {
            input_type: "select",
            attribute: "[data][field]",
            label: "Subtract from score",
            placeholder: "Select a field",
            dropdown: "fields",
          },
          {
            input_type: "number",
            attribute: "[data][number]",
            label: "Number to subtract",
            placeholder: "0",
          },
        ],
      },
    ],
  };

  // add integrations to services map

  Object.values(integrations).forEach((integration) => {
    if (integration.integration_type !== "Wordpress") {
      services[integration.integration_type] = integration;
    }
  });

  // add existing actions to container

  if (typeof window !== "undefined") {
    // if editing a cta, give ability to add track CTA completion automtation

    if (
      $("body").attr("data-controller") == "variants" &&
      $("body").attr("data-action") == "edit"
    ) {
      services["ConvertFlow"].action_types.unshift({
        action_type: "Track completion of campaign",
        settings: [
          {
            input_type: "select",
            attribute: "[data][cta_id]",
            label: "Campaign",
            placeholder: "Select a campaign",
            dropdown: "ctas",
          },
        ],
      });
    }

    if ($(existing_actions).length > 0) {
      Object.values(existing_actions).forEach((action) => {
        if (action !== undefined) {
          if (
            $.map(Object.keys(services), function (service) {
              if (
                action.action_type &&
                action.action_type.indexOf(service) > -1
              )
                return service;
            }).length > 0 &&
            (action.data_name || action.data)
          ) {
            var integrationType = action.action_type.split(" -")[0];
            var integration = $.map(website.integrations, function (
              integration
            ) {
              if (integration.integration_type == integrationType)
                return integration;
            })[0];
            var action_type = services[integrationType].action_types.filter(
              (actionType) => actionType.action_type == action.action_type
            )[0];
            if (action_type && integration && action.data && action_type.settings) {
              action_type.settings.forEach((setting) => {
                let data_id = action.data[setting.attribute];
                if (
                  data_id &&
                  setting.dropdown &&
                  integration.api_data[setting.dropdown]
                ) {
                  let list_item;
                  if (
                    setting.dropdown &&
                    integration.api_data[setting.dropdown]
                  ) {
                    list_item = integration.api_data[setting.dropdown].filter(
                      (item) => item.id + "" == data_id + ""
                    )[0];
                  }
                }
              });
            }
          }
        }
      });
    }
  }

  return services;
};

export default ActionsArray;
