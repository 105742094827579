import React from "react";
import PropTypes from "prop-types";
import FieldOption from "../field_options/FieldOption";

const FieldRadio = (props) => {
  let field = props.field;
  let element = props.element;
  let field_options = Object.values(
    props.variant.field_options || field.field_options
  ).filter(
    (field_option) =>
      field_option.field_id == field.id && field_option.toBeDeleted !== true
  );
  let sorted_field_options = Object.values(field_options).sort(function (a, b) {
    return a.position - b.position;
  });

  return (
    <React.Fragment>
      <div
        data-id={field.id}
        data-field={field.slug}
        data-pos={field.position ? field.position : ""}
        className="cf-form-field cf-custom-field"
        data-visible="true"
        data-multi-select={field.multi_select == true ? "true" : "false"}
        style={{
          order: field.position,
        }}
      >
        <div
          className={
            "contact-custom-field cf-" + field.field_type + " cf-col-xs-12"
          }
          data-required={field.required == true ? "true" : "false"}
          id={"customfield" + field.id}
        >
          <div
            className="cf-selection-options"
            style={{ marginBottom: "10px" }}
          >
            {field.label && props.hide_label !== true && (
              <p
                className="cf-label"
                style={{
                  marginBottom: "4px",
                  color: element.options["label-color"]
                    ? element.options["label-color"]
                    : "",
                }}
              >
                {field.label}
              </p>
            )}
            {field.multi_select == true && (
              <input
                name={"contact[extra][" + field.slug + "]"}
                id={"contact_extra_" + field.slug}
                type="hidden"
                defaultValue={field.value ? field.value : ""}
              ></input>
            )}
            {sorted_field_options.map((field_option) => {
              return (
                <FieldOption
                  key={"field-option-" + field_option.id}
                  field_option={field_option}
                  {...props}
                />
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldRadio;
