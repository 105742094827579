import React, { useEffect } from "react";
import PropTypes from "prop-types";
import BroadcastsListToolbar from "../broadcasts/BroadcastsListToolbar";
import BroadcastsListItem from "../broadcasts/BroadcastsListItem";

const BroadcastsList = (props) => {
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);
  return (
    <React.Fragment>
      <div className="row hard-center BroadcastsList smooth">
        <div className="col-xs-12 no-padding-sides">
          <BroadcastsListToolbar {...props} />
        </div>
        <div className="col-xs-12 smooth">
          <div className="row hard-center broadcasts-list-row-container some-vertical-space border-solid border-[1px] border-slate-200 rounded-xl shadow-sm">
            <div className="col-xs-12 broadcasts-list-row-header">
              <div className="col-xs-3">
                <p className="broadcasts-list-row-heading">Name</p>
              </div>
              <div className="col-xs-9">
                <div className="col-xs-1 no-padding-sides" style={{minWidth: '85px'}}>
                  <p className="broadcasts-list-row-heading">Campaigns</p>
                </div>
                <div className="col-xs-2">
                  <p className="broadcasts-list-row-heading">Views</p>
                </div>
                <div className="col-xs-2 no-padding-sides">
                  <p className="broadcasts-list-row-heading">
                    Conversions
                  </p>
                </div>
                <div className="col-xs-2 no-padding-sides">
                  <p className="broadcasts-list-row-heading">
                    Revenue
                  </p>
                </div>
                <div className="col-xs-2 no-padding-sides">
                  <p className="broadcasts-list-row-heading">Status</p>
                </div>
                <div className="col-xs-2 no-padding-sides"></div>
              </div>
            </div>
            <div className="col-xs-12">
              {props.broadcasts.map((broadcast) => {
                return (
                  <BroadcastsListItem
                    key={"broadcast-" + broadcast.id}
                    broadcast={broadcast}
                    {...props}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BroadcastsList;
