import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";

const FieldSettingsSlugMapping = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  const field = props.field;
  const element = contextData.objects.variant.elements[field.element_id];
  const website = contextData.objects.website;
  const cta = contextData.objects.cta;
  const variant = contextData.objects.variant;

  let field_options = Object.values(contextData.objects.variant.field_options)
    .filter(
      (field_option) =>
        field_option.field_id == field.id && field_option.toBeDeleted !== true
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  let field_slugs = [];

  let customFields = [];

  let cfFields = {
    label: "Saved Custom Fields",
    options: [],
  };

  const allFields = [
    ...Object.values(website.fields),
    ...Object.values(variant.fields),
  ]

  Object.values(allFields).forEach(function (field) {
    if (
      field.slug !== "New Field" &&
      field.slug !== "" &&
      cfFields.options.filter((option) => option.value == field.slug)[0] ==
        undefined &&
      (!field.service ||
        field.service == "ConvertFlow" ||
        !website.integrations ||
        Object.values(website.integrations).map(function (integration) {
          if (integration.integration_type == field.service) return integration;
        }).length == 0)
    ) {
      cfFields.options.push({
        value: field.slug,
        label: field.slug,
        icon: contextData.logo,
      });

      field_slugs.push({
        value: field.slug,
        label: field.slug,
        group: "ConvertFlow Custom Fields",
      });
    }
  });

  if (
    field.slug &&
    (!field.service || field.service == "ConvertFlow") &&
    cfFields.options.filter((field_slug) => field_slug.value == field.slug)
      .length == 0
  ) {
    cfFields.options.push({
      value: field.slug,
      label: field.slug,
    });
  }

  cfFields.options.push({
    label: "New custom field",
    value: "New Field",
    icon: contextData.dark_mode
      ? `/images/add-icon-white.svg`
      : `/images/add-icon.svg`,
  });

  customFields.push(cfFields);

  Object.values(website.integrations)
    .filter((integration) => integration.features.includes("contact"))
    .sort((a, b) => a.integration_type.localeCompare(b.integration_type))
    .forEach(function (integration) {
      let serviceFields = {
        label: integration.integration_type,
        options: [],
      };

      if (integration.api_data["properties"]) {
        Object.values(integration.api_data["properties"]).forEach(function (
          property
        ) {
          let property_option = {
            value: property.id,
            label: property.name,
            plan_tier: integration.plan_tier,
            icon: `/images/integrations/${
              integration.icon ? integration.icon.split("/").pop() : null
            }`,
          };

          property_option.integration_id = integration.id;
          property_option.property_id = property.id;
          if (property.options !== undefined) {
            property_option.field_type = "select";
          } else {
            property_option.field_type = "text";
          }

          serviceFields.options.push(property_option);
          field_slugs.push(property_option);
        });
      } else {
        serviceFields.options.push({
          label: `${integration.integration_type} automatically receives ConvertFlow's saved custom fields. Save fields above!`,
          isDisabled: true,
          icon: `/images/integrations/${
            integration.icon ? integration.icon.split("/").pop() : null
          }`,
        });
      }
      customFields.push(serviceFields);
    });

  function slugInputCallback(new_value, old_value) {
    if (new_value !== "New Field") {
      let formatted_value = new_value;

      let changesToRegister = getFieldConditionChanges(
        field.id + "",
        formatted_value
      );

      changesToRegister.push({
        object_type: "fields",
        object_id: field.id,
        setting_name: "[slug]",
        value: formatted_value,
        old_value:
          old_value && new_value !== old_value ? old_value : "New Field",
      });

      builder.update(changesToRegister);
    }
  }

  function slugDropdownCallback(new_value, old_value) {
    if (new_value == "New Field") {
      builder.update([
        {
          object_type: "fields",
          object_id: field.id,
          setting_name: "[slug]",
          value: "New Field",
          skip_history: true,
        },
        {
          object_type: "fields",
          object_id: field.id,
          setting_name: "[service]",
          value: "",
          skip_history: true,
        },
      ]);
    } else {
      let service = "";

      let fields = field_slugs.filter(
        (field_slug) => field_slug.value == new_value
      );

      let field_slug = fields
        ? fields.filter((field) => field.integration_id)[0] || fields[0]
        : undefined;

      if (field_slug && field_slug.integration_id) {
        let integration = website.integrations[field_slug.integration_id];
        if (integration) {
          service = integration.integration_type;
        }
      }

      let changesToRegister = getFieldConditionChanges(
        field.id + "",
        new_value
      );
      changesToRegister.push(
        {
          object_type: "fields",
          object_id: field.id,
          setting_name: "[slug]",
          value: new_value,
          old_value:
            old_value && new_value !== old_value ? old_value : "New Field",
        },
        {
          object_type: "fields",
          object_id: field.id,
          setting_name: "[service]",
          value: service,
        }
      );

      builder.update(changesToRegister);
    }
  }

  function getFieldConditionChanges(field_id, slug) {
    let changes = [];

    Object.values(contextData.objects.variant.conditions)
      .filter((c) => c.data && c.data["field_id"] == field_id)
      .forEach((condition) => {
        changes.push({
          object_type: "conditions",
          object_id: condition.id,
          setting_name: "[data][slug]",
          value: slug,
        });
      });
    return changes;
  }

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={field}
        label="Map to custom field"
        setting_name="[slug]"
        setting_type="select"
        object_type="fields"
        object_id={field.id}
        value={field.slug ? field.slug : ""}
        options={customFields}
        skip_history={true}
        callback={slugDropdownCallback}
        warning={!field.slug ? "Custom field mapping required" : undefined}
      />
      <VariantBuilderSetting
        object={field}
        label="New field data name"
        setting_name="[slug]"
        setting_type="text"
        object_type="fields"
        object_id={field.id}
        value=""
        placeholder="field_data_name"
        condition={field.slug == "New Field"}
        skip_history={true}
        callback={slugInputCallback}
        snakecase={true}
        warning={
          field.slug == "New Field" ? "Field data name is required" : undefined
        }
      />
    </React.Fragment>
  );
};

export default FieldSettingsSlugMapping;
