const CountriesArray = [
  {
    name: "Afghanistan",
    dial_code: 93,
    country_code: "AF",
    flag: "🇦🇫",
    currency: "AFN",
    currency_symbol: "Af",
  },
  {
    name: "Albania",
    dial_code: 355,
    country_code: "AL",
    flag: "🇦🇱",
    currency: "ALL",
    currency_symbol: "L",
  },
  {
    name: "Algeria",
    dial_code: 213,
    country_code: "DZ",
    flag: "🇩🇿",
    currency: "DZD",
    currency_symbol: "د.ج",
  },
  {
    name: "AmericanSamoa",
    dial_code: 1684,
    country_code: "AS",
    flag: "🇦🇸",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Andorra",
    dial_code: 376,
    country_code: "AD",
    flag: "🇦🇩",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Angola",
    dial_code: 244,
    country_code: "AO",
    flag: "🇦🇴",
    currency: "AOA",
    currency_symbol: "Kz",
  },
  {
    name: "Anguilla",
    dial_code: 1264,
    country_code: "AI",
    flag: "🇦🇮",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: 1268,
    country_code: "AG",
    flag: "🇦🇬",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Argentina",
    dial_code: 54,
    country_code: "AR",
    flag: "🇦🇷",
    currency: "ARS",
    currency_symbol: "$",
  },
  {
    name: "Armenia",
    dial_code: 374,
    country_code: "AM",
    flag: "🇦🇲",
    currency: "AMD",
    currency_symbol: "Դ",
  },
  {
    name: "Aruba",
    dial_code: 297,
    country_code: "AW",
    flag: "🇦🇼",
    currency: "AWG",
    currency_symbol: "ƒ",
  },
  {
    name: "Australia",
    dial_code: 61,
    country_code: "AU",
    flag: "🇦🇺",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Austria",
    dial_code: 43,
    country_code: "AT",
    flag: "🇦🇹",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Azerbaijan",
    dial_code: 994,
    country_code: "AZ",
    flag: "🇦🇿",
    currency: "AZN",
    currency_symbol: "ман",
  },
  {
    name: "Bahamas",
    dial_code: 1242,
    country_code: "BS",
    flag: "🇧🇸",
    currency: "BSD",
    currency_symbol: "$",
  },
  {
    name: "Bahrain",
    dial_code: 973,
    country_code: "BH",
    flag: "🇧🇭",
    currency: "BHD",
    currency_symbol: "ب.د",
  },
  {
    name: "Bangladesh",
    dial_code: 880,
    country_code: "BD",
    flag: "🇧🇩",
    currency: "BDT",
    currency_symbol: "৳",
  },
  {
    name: "Barbados",
    dial_code: 1246,
    country_code: "BB",
    flag: "🇧🇧",
    currency: "BBD",
    currency_symbol: "$",
  },
  {
    name: "Belarus",
    dial_code: 375,
    country_code: "BY",
    flag: "🇧🇾",
    currency: "BYN",
    currency_symbol: "Br",
  },
  {
    name: "Belgium",
    dial_code: 32,
    country_code: "BE",
    flag: "🇧🇪",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Belize",
    dial_code: 501,
    country_code: "BZ",
    flag: "🇧🇿",
    currency: "BZD",
    currency_symbol: "$",
  },
  {
    name: "Benin",
    dial_code: 229,
    country_code: "BJ",
    flag: "🇧🇯",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Bermuda",
    dial_code: 1441,
    country_code: "BM",
    flag: "🇧🇲",
    currency: "BMD",
    currency_symbol: "$",
  },
  {
    name: "Bhutan",
    dial_code: 975,
    country_code: "BT",
    flag: "🇧🇹",
    currency: "BTN",
    currency_symbol: "Nu",
  },
  {
    name: "Plurinational State of Bolivia",
    dial_code: 591,
    country_code: "BO",
    flag: "🇧🇴",
    currency: "BOB",
    currency_symbol: "Bs",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: 387,
    country_code: "BA",
    flag: "🇧🇦",
    currency: "BAM",
    currency_symbol: "КМ",
  },
  {
    name: "Botswana",
    dial_code: 267,
    country_code: "BW",
    flag: "🇧🇼",
    currency: "BWP",
    currency_symbol: "P",
  },
  {
    name: "Brazil",
    dial_code: 55,
    country_code: "BR",
    flag: "🇧🇷",
    currency: "BRL",
    currency_symbol: "R$",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: 246,
    country_code: "IO",
    flag: "🇮🇴",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    name: "Brunei Darussalam",
    dial_code: 673,
    country_code: "BN",
    flag: "🇧🇳",
    currency: "BND",
    currency_symbol: "$",
  },
  {
    name: "Bulgaria",
    dial_code: 359,
    country_code: "BG",
    flag: "🇧🇬",
    currency: "BGN",
    currency_symbol: "лв",
  },
  {
    name: "Burkina Faso",
    dial_code: 226,
    country_code: "BF",
    flag: "🇧🇫",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Burundi",
    dial_code: 257,
    country_code: "BI",
    flag: "🇧🇮",
    currency: "BIF",
    currency_symbol: "₣",
  },
  {
    name: "Cambodia",
    dial_code: 855,
    country_code: "KH",
    flag: "🇰🇭",
    currency: "KHR",
    currency_symbol: "៛",
  },
  {
    name: "Cameroon",
    dial_code: 237,
    country_code: "CM",
    flag: "🇨🇲",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Canada",
    dial_code: 1,
    country_code: "CA",
    flag: "🇨🇦",
    currency: "CAD",
    currency_symbol: "$",
  },
  {
    name: "Cape Verde",
    dial_code: 238,
    country_code: "CV",
    flag: "🇨🇻",
    currency: "CVE",
    currency_symbol: "$",
  },
  {
    name: "Cayman Islands",
    dial_code: 345,
    country_code: "KY",
    flag: "🇰🇾",
    currency: "KYD",
    currency_symbol: "$",
  },
  {
    name: "Central African Republic",
    dial_code: 236,
    country_code: "CF",
    flag: "🇨🇫",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Chad",
    dial_code: 235,
    country_code: "TD",
    flag: "🇹🇩",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Chile",
    dial_code: 56,
    country_code: "CL",
    flag: "🇨🇱",
    currency: "CLP",
    currency_symbol: "$",
  },
  {
    name: "China",
    dial_code: 86,
    country_code: "CN",
    flag: "🇨🇳",
    currency: "CNY",
    currency_symbol: "¥",
  },
  {
    name: "Christmas Island",
    dial_code: 61,
    country_code: "CX",
    flag: "🇨🇽",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: 61,
    country_code: "CC",
    flag: "🇨🇨",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Colombia",
    dial_code: 57,
    country_code: "CO",
    flag: "🇨🇴",
    currency: "COP",
    currency_symbol: "$",
  },
  {
    name: "Comoros",
    dial_code: 269,
    country_code: "KM",
    flag: "🇰🇲",
    currency: "KMF",
    currency_symbol: "CF",
  },
  {
    name: "Congo",
    dial_code: 242,
    country_code: "CG",
    flag: "🇨🇬",
    currency: "CDF",
    currency_symbol: "₣",
  },
  {
    name: "Democratic Republic of the Congo",
    dial_code: 243,
    country_code: "CD",
    flag: "🇨🇩",
    currency: "CDF",
    currency_symbol: "FC",
  },
  {
    name: "Cook Islands",
    dial_code: 682,
    country_code: "CK",
    flag: "🇨🇰",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    name: "Costa Rica",
    dial_code: 506,
    country_code: "CR",
    flag: "🇨🇷",
    currency: "CRC",
    currency_symbol: "₡",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: 225,
    country_code: "CI",
    flag: "🇨🇮",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    name: "Croatia",
    dial_code: 385,
    country_code: "HR",
    flag: "🇭🇷",
    currency: "HRK",
    currency_symbol: "Kn",
  },
  {
    name: "Cuba",
    dial_code: 53,
    country_code: "CU",
    flag: "🇨🇺",
    currency: "CUP",
    currency_symbol: "$",
  },
  {
    name: "Cyprus",
    dial_code: 537,
    country_code: "CY",
    flag: "🇨🇾",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Czech Republic",
    dial_code: 420,
    country_code: "CZ",
    flag: "🇨🇿",
    currency: "CZK",
    currency_symbol: "Kč",
  },
  {
    name: "Denmark",
    dial_code: 45,
    country_code: "DK",
    flag: "🇩🇰",
    currency: "DKK",
    currency_symbol: "kr",
  },
  {
    name: "Djibouti",
    dial_code: 253,
    country_code: "DJ",
    flag: "🇩🇯",
    currency: "DJF",
    currency_symbol: "₣",
  },
  {
    name: "Dominica",
    dial_code: 1767,
    country_code: "DM",
    flag: "🇩🇲",
    currency: "DOP",
    currency_symbol: "$",
  },
  {
    name: "Dominican Republic",
    dial_code: 1849,
    country_code: "DO",
    flag: "🇩🇴",
    currency: "DOP",
    currency_symbol: "$",
  },
  {
    name: "Ecuador",
    dial_code: 593,
    country_code: "EC",
    flag: "🇪🇨",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Egypt",
    dial_code: 20,
    country_code: "EG",
    flag: "🇪🇬",
    currency: "EGP",
    currency_symbol: "£",
  },
  {
    name: "El Salvador",
    dial_code: 503,
    country_code: "SV",
    flag: "🇸🇻",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Equatorial Guinea",
    dial_code: 240,
    country_code: "GQ",
    flag: "🇬🇶",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Eritrea",
    dial_code: 291,
    country_code: "ER",
    flag: "🇪🇷",
    currency: "ERN",
    currency_symbol: "Nfk",
  },
  {
    name: "Estonia",
    dial_code: 372,
    country_code: "EE",
    flag: "🇪🇪",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Ethiopia",
    dial_code: 251,
    country_code: "ET",
    flag: "🇪🇹",
    currency: "ETB",
    currency_symbol: "ብር",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: 500,
    country_code: "FK",
    flag: "🇫🇰",
    currency: "FKP",
    currency_symbol: "£",
  },
  {
    name: "Faroe Islands",
    dial_code: 298,
    country_code: "FO",
    flag: "🇫🇴",
    currency: "FOK",
    currency_symbol: "kr",
  },
  {
    name: "Fiji",
    dial_code: 679,
    country_code: "FJ",
    flag: "🇫🇯",
    currency: "FJD",
    currency_symbol: "$",
  },
  {
    name: "Finland",
    dial_code: 358,
    country_code: "FI",
    flag: "🇫🇮",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "France",
    dial_code: 33,
    country_code: "FR",
    flag: "🇫🇷",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "French Guiana",
    dial_code: 594,
    country_code: "GF",
    flag: "🇬🇫",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "French Polynesia",
    dial_code: 689,
    country_code: "PF",
    flag: "🇵🇫",
    currency: "XPF",
    currency_symbol: "₣",
  },
  {
    name: "Gabon",
    dial_code: 241,
    country_code: "GA",
    flag: "🇬🇦",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Gambia",
    dial_code: 220,
    country_code: "GM",
    flag: "🇬🇲",
    currency: "GMD",
    currency_symbol: "D",
  },
  {
    name: "Georgia",
    dial_code: 995,
    country_code: "GE",
    flag: "🇬🇪",
    currency: "GEL",
    currency_symbol: "ლ",
  },
  {
    name: "Germany",
    dial_code: 49,
    country_code: "DE",
    flag: "🇩🇪",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Ghana",
    dial_code: 233,
    country_code: "GH",
    flag: "🇬🇭",
    currency: "GHS",
    currency_symbol: "₵",
  },
  {
    name: "Gibraltar",
    dial_code: 350,
    country_code: "GI",
    flag: "🇬🇮",
    currency: "GIP",
    currency_symbol: "£",
  },
  {
    name: "Greece",
    dial_code: 30,
    country_code: "GR",
    flag: "🇬🇷",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Greenland",
    dial_code: 299,
    country_code: "GL",
    flag: "🇬🇱",
    currency: "DKK",
    currency_symbol: "kr.",
  },
  {
    name: "Grenada",
    dial_code: 1473,
    country_code: "GD",
    flag: "🇬🇩",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Guadeloupe",
    dial_code: 590,
    country_code: "GP",
    flag: "🇬🇵",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Guam",
    dial_code: 1671,
    country_code: "GU",
    flag: "🇬🇺",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Guatemala",
    dial_code: 502,
    country_code: "GT",
    flag: "🇬🇹",
    currency: "GTQ",
    currency_symbol: "Q",
  },
  {
    name: "Guernsey",
    dial_code: 44,
    country_code: "GG",
    flag: "🇬🇬",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    name: "Guinea",
    dial_code: 224,
    country_code: "GN",
    flag: "🇬🇳",
    currency: "GNF",
    currency_symbol: "₣",
  },
  {
    name: "Guinea-Bissau",
    dial_code: 245,
    country_code: "GW",
    flag: "🇬🇼",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Guyana",
    dial_code: 595,
    country_code: "GY",
    flag: "🇬🇾",
    currency: "GYD",
    currency_symbol: "$",
  },
  {
    name: "Haiti",
    dial_code: 509,
    country_code: "HT",
    flag: "🇭🇹",
    currency: "HTG",
    currency_symbol: "G",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: 379,
    country_code: "VA",
    flag: "🇻🇦",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Honduras",
    dial_code: 504,
    country_code: "HN",
    flag: "🇭🇳",
    currency: "HNL",
    currency_symbol: "L",
  },
  {
    name: "Hong Kong",
    dial_code: 852,
    country_code: "HK",
    flag: "🇭🇰",
    currency: "HKD",
    currency_symbol: "$",
  },
  {
    name: "Hungary",
    dial_code: 36,
    country_code: "HU",
    flag: "🇭🇺",
    currency: "HUF",
    currency_symbol: "Ft",
  },
  {
    name: "Iceland",
    dial_code: 354,
    country_code: "IS",
    flag: "🇮🇸",
    currency: "ISK",
    currency_symbol: "Kr",
  },
  {
    name: "India",
    dial_code: 91,
    country_code: "IN",
    flag: "🇮🇳",
    currency: "INR",
    currency_symbol: "₹  ",
  },
  {
    name: "Indonesia",
    dial_code: 62,
    country_code: "ID",
    flag: "🇮🇩",
    currency: "IDR",
    currency_symbol: "Rp",
  },
  {
    name: "Iran",
    dial_code: 98,
    country_code: "IR",
    flag: "🇮🇷",
    currency: "IRR",
    currency_symbol: "﷼",
  },
  {
    name: "Iraq",
    dial_code: 964,
    country_code: "IQ",
    flag: "🇮🇶",
    currency: "IQD",
    currency_symbol: "ع.د",
  },
  {
    name: "Ireland",
    dial_code: 353,
    country_code: "IE",
    flag: "🇮🇪",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Isle of Man",
    dial_code: 44,
    country_code: "IM",
    flag: "🇮🇲",
    currency: "IMP",
    currency_symbol: "£",
  },
  {
    name: "Israel",
    dial_code: 972,
    country_code: "IL",
    flag: "🇮🇱",
    currency: "ILS",
    currency_symbol: "₪",
  },
  {
    name: "Italy",
    dial_code: 39,
    country_code: "IT",
    flag: "🇮🇹",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Jamaica",
    dial_code: 1876,
    country_code: "JM",
    flag: "🇯🇲",
    currency: "JMD",
    currency_symbol: "$",
  },
  {
    name: "Japan",
    dial_code: 81,
    country_code: "JP",
    flag: "🇯🇵",
    currency: "JPY",
    currency_symbol: "¥",
  },
  {
    name: "Jersey",
    dial_code: 44,
    country_code: "JE",
    flag: "🇯🇪",
    currency: "JEP",
    currency_symbol: "£",
  },
  {
    name: "Jordan",
    dial_code: 962,
    country_code: "JO",
    flag: "🇯🇴",
    currency: "JOD",
    currency_symbol: "د.ا",
  },
  {
    name: "Kazakhstan",
    dial_code: 77,
    country_code: "KZ",
    flag: "🇰🇿",
    currency: "KZT",
    currency_symbol: "〒",
  },
  {
    name: "Kenya",
    dial_code: 254,
    country_code: "KE",
    flag: "🇰🇪",
    currency: "KES",
    currency_symbol: "Sh",
  },
  {
    name: "Kiribati",
    dial_code: 686,
    country_code: "KI",
    flag: "🇰🇮",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Democratic People's Republic of Korea",
    dial_code: 850,
    country_code: "KP",
    flag: "🇰🇵",
    currency: "KPW",
    currency_symbol: "₩",
  },
  {
    name: "Republic of Korea",
    dial_code: 82,
    country_code: "KR",
    flag: "🇰🇷",
    currency: "KRW",
    currency_symbol: "₩",
  },
  {
    name: "Kuwait",
    dial_code: 965,
    country_code: "KW",
    flag: "🇰🇼",
    currency: "KWD",
    currency_symbol: "د.ك",
  },
  {
    name: "Kyrgyzstan",
    dial_code: 996,
    country_code: "KG",
    flag: "🇰🇬",
    currency: "KGS",
    currency_symbol: "Лв",
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: 856,
    country_code: "LA",
    flag: "🇱🇦",
    currency: "LAK",
    currency_symbol: "₭",
  },
  {
    name: "Latvia",
    dial_code: 371,
    country_code: "LV",
    flag: "🇱🇻",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Lebanon",
    dial_code: 961,
    country_code: "LB",
    flag: "🇱🇧",
    currency: "LBP",
    currency_symbol: "ل.ل",
  },
  {
    name: "Lesotho",
    dial_code: 266,
    country_code: "LS",
    flag: "🇱🇸",
    currency: "LSL",
    currency_symbol: "L",
  },
  {
    name: "Liberia",
    dial_code: 231,
    country_code: "LR",
    flag: "🇱🇷",
    currency: "LRD",
    currency_symbol: "$",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: 218,
    country_code: "LY",
    flag: "🇱🇾",
    currency: "LYD",
    currency_symbol: "ل.د",
  },
  {
    name: "Liechtenstein",
    dial_code: 423,
    country_code: "LI",
    flag: "🇱🇮",
    currency: "CHF",
    currency_symbol: "Fr",
  },
  {
    name: "Lithuania",
    dial_code: 370,
    country_code: "LT",
    flag: "🇱🇹",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Luxembourg",
    dial_code: 352,
    country_code: "LU",
    flag: "🇱🇺",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Macao",
    dial_code: 853,
    country_code: "MO",
    flag: "🇲🇴",
    currency: "MOP",
    currency_symbol: "P",
  },
  {
    name: "Republic of North Macedonia",
    dial_code: 389,
    country_code: "MK",
    flag: "🇲🇰",
    currency: "MKD",
    currency_symbol: "ден",
  },
  {
    name: "Madagascar",
    dial_code: 261,
    country_code: "MG",
    flag: "🇲🇬",
    currency: "MGA",
    currency_symbol: "Ar",
  },
  {
    name: "Malawi",
    dial_code: 265,
    country_code: "MW",
    flag: "🇲🇼",
    currency: "MWK",
    currency_symbol: "MK",
  },
  {
    name: "Malaysia",
    dial_code: 60,
    country_code: "MY",
    flag: "🇲🇾",
    currency: "MYR",
    currency_symbol: "RM",
  },
  {
    name: "Maldives",
    dial_code: 960,
    country_code: "MV",
    flag: "🇲🇻",
    currency: "MVR",
    currency_symbol: "ރ.",
  },
  {
    name: "Mali",
    dial_code: 223,
    country_code: "ML",
    flag: "🇲🇱",
    currency: "SOS",
    currency_symbol: "Sh",
  },
  {
    name: "Malta",
    dial_code: 356,
    country_code: "MT",
    flag: "🇲🇹",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Marshall Islands",
    dial_code: 692,
    country_code: "MH",
    flag: "🇲🇭",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Martinique",
    dial_code: 596,
    country_code: "MQ",
    flag: "🇲🇶",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Mauritania",
    dial_code: 222,
    country_code: "MR",
    flag: "🇲🇷",
    currency: "MRU",
    currency_symbol: "UM",
  },
  {
    name: "Mauritius",
    dial_code: 230,
    country_code: "MU",
    flag: "🇲🇺",
    currency: "MUR",
    currency_symbol: "₨",
  },
  {
    name: "Mayotte",
    dial_code: 262,
    country_code: "YT",
    flag: "🇾🇹",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Mexico",
    dial_code: 52,
    country_code: "MX",
    flag: "🇲🇽",
    currency: "MXN",
    currency_symbol: "$",
  },
  {
    name: "Federated States of Micronesia",
    dial_code: 691,
    country_code: "FM",
    flag: "🇫🇲",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Republic of Moldova ",
    dial_code: 373,
    country_code: "MD",
    flag: "🇲🇩",
    currency: "MDL",
    currency_symbol: "L",
  },
  {
    name: "Monaco",
    dial_code: 377,
    country_code: "MC",
    flag: "🇲🇨",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Mongolia",
    dial_code: 976,
    country_code: "MN",
    flag: "🇲🇳",
    currency: "MNT",
    currency_symbol: "₮",
  },
  {
    name: "Montenegro",
    dial_code: 382,
    country_code: "ME",
    flag: "🇲🇪",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Montserrat",
    dial_code: 1664,
    country_code: "MS",
    flag: "🇲🇸",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Morocco",
    dial_code: 212,
    country_code: "MA",
    flag: "🇲🇦",
    currency: "MAD",
    currency_symbol: "د.م.",
  },
  {
    name: "Mozambique",
    dial_code: 258,
    country_code: "MZ",
    flag: "🇲🇿",
    currency: "MZN",
    currency_symbol: "MTn",
  },
  {
    name: "Myanmar",
    dial_code: 95,
    country_code: "MM",
    flag: "🇲🇲",
    currency: "MMK",
    currency_symbol: "K",
  },
  {
    name: "Namibia",
    dial_code: 264,
    country_code: "NA",
    flag: "🇳🇦",
    currency: "NAD",
    currency_symbol: "$",
  },
  {
    name: "Nauru",
    dial_code: 674,
    country_code: "NR",
    flag: "🇳🇷",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Nepal",
    dial_code: 977,
    country_code: "NP",
    flag: "🇳🇵",
    currency: "NPR",
    currency_symbol: "₨",
  },
  {
    name: "Netherlands",
    dial_code: 31,
    country_code: "NL",
    flag: "🇳🇱",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Netherlands Antilles",
    dial_code: 599,
    country_code: "AN",
    flag: "🇦🇳",
    currency: "ANG",
    currency_symbol: "ƒ",
  },
  {
    name: "New Caledonia",
    dial_code: 687,
    country_code: "NC",
    flag: "🇳🇨",
    currency: "XPF",
    currency_symbol: "₣",
  },
  {
    name: "New Zealand",
    dial_code: 64,
    country_code: "NZ",
    flag: "🇳🇿",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    name: "Nicaragua",
    dial_code: 505,
    country_code: "NI",
    flag: "🇳🇮",
    currency: "NIO",
    currency_symbol: "C$",
  },
  {
    name: "Niger",
    dial_code: 227,
    country_code: "NE",
    flag: "🇳🇪",
    currency: "NGN",
    currency_symbol: "₦",
  },
  {
    name: "Nigeria",
    dial_code: 234,
    country_code: "NG",
    flag: "🇳🇬",
    currency: "NGN",
    currency_symbol: "₦",
  },
  {
    name: "Niue",
    dial_code: 683,
    country_code: "NU",
    flag: "🇳🇺",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    name: "Norfolk Island",
    dial_code: 672,
    country_code: "NF",
    flag: "🇳🇫",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: 1670,
    country_code: "MP",
    flag: "🇲🇵",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Norway",
    dial_code: 47,
    country_code: "NO",
    flag: "🇳🇴",
    currency: "NOK",
    currency_symbol: "kr",
  },
  {
    name: "Oman",
    dial_code: 968,
    country_code: "OM",
    flag: "🇴🇲",
    currency: "OMR",
    currency_symbol: "ر.ع.",
  },
  {
    name: "Pakistan",
    dial_code: 92,
    country_code: "PK",
    flag: "🇵🇰",
    currency: "PKR",
    currency_symbol: "₨",
  },
  {
    name: "Palau",
    dial_code: 680,
    country_code: "PW",
    flag: "🇵🇼",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Palestine",
    dial_code: 970,
    country_code: "PS",
    flag: "🇵🇸",
    currency: "ILS",
    currency_symbol: "₪",
  },
  {
    name: "Panama",
    dial_code: 507,
    country_code: "PA",
    flag: "🇵🇦",
    currency: "PAB",
    currency_symbol: "B/.",
  },
  {
    name: "Papua New Guinea",
    dial_code: 675,
    country_code: "PG",
    flag: "🇵🇬",
    currency: "PGK",
    currency_symbol: "K",
  },
  {
    name: "Paraguay",
    dial_code: 595,
    country_code: "PY",
    flag: "🇵🇾",
    currency: "PYG",
    currency_symbol: "₲",
  },
  {
    name: "Peru",
    dial_code: 51,
    country_code: "PE",
    flag: "🇵🇪",
    currency: "PEN",
    currency_symbol: "S/.",
  },
  {
    name: "Philippines",
    dial_code: 63,
    country_code: "PH",
    flag: "🇵🇭",
    currency: "PHP",
    currency_symbol: "₱",
  },
  {
    name: "Pitcairn",
    dial_code: 872,
    country_code: "PN",
    flag: "🇵🇳",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    name: "Poland",
    dial_code: 48,
    country_code: "PL",
    flag: "🇵🇱",
    currency: "PLN",
    currency_symbol: "zł",
  },
  {
    name: "Portugal",
    dial_code: 351,
    country_code: "PT",
    flag: "🇵🇹",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Puerto Rico",
    dial_code: 1939,
    country_code: "PR",
    flag: "🇵🇷",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Qatar",
    dial_code: 974,
    country_code: "QA",
    flag: "🇶🇦",
    currency: "QAR",
    currency_symbol: "ر.ق",
  },
  {
    name: "Romania",
    dial_code: 40,
    country_code: "RO",
    flag: "🇷🇴",
    currency: "RON",
    currency_symbol: "L",
  },
  {
    name: "Russia",
    dial_code: 7,
    country_code: "RU",
    flag: "🇷🇺",
    currency: "RUB",
    currency_symbol: "р.",
  },
  {
    name: "Rwanda",
    dial_code: 250,
    country_code: "RW",
    flag: "🇷🇼",
    currency: "RWF",
    currency_symbol: "₣",
  },
  {
    name: "Réunion",
    dial_code: 262,
    country_code: "RE",
    flag: "🇷🇪",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Saint Barthélemy",
    dial_code: 590,
    country_code: "BL",
    flag: "🇧🇱",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: 290,
    country_code: "SH",
    flag: "🇸🇭",
    currency: "SHP",
    currency_symbol: "£",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: 1869,
    country_code: "KN",
    flag: "🇰🇳",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Saint Lucia",
    dial_code: 1758,
    country_code: "LC",
    flag: "🇱🇨",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Saint Martin",
    dial_code: 590,
    country_code: "MF",
    flag: "🇲🇫",
    currency: "ANG",
    currency_symbol: "ƒ",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: 508,
    country_code: "PM",
    flag: "🇵🇲",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: 1784,
    country_code: "VC",
    flag: "🇻🇨",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    name: "Samoa",
    dial_code: 685,
    country_code: "WS",
    flag: "🇼🇸",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "San Marino",
    dial_code: 378,
    country_code: "SM",
    flag: "🇸🇲",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: 239,
    country_code: "ST",
    flag: "🇸🇹",
    currency: "STN",
    currency_symbol: "Db",
  },
  {
    name: "Saudi Arabia",
    dial_code: 966,
    country_code: "SA",
    flag: "🇸🇦",
    currency: "SAR",
    currency_symbol: "ر.س",
  },
  {
    name: "Senegal",
    dial_code: 221,
    country_code: "SN",
    flag: "🇸🇳",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Serbia",
    dial_code: 381,
    country_code: "RS",
    flag: "🇷🇸",
    currency: "RSD",
    currency_symbol: "din",
  },
  {
    name: "Seychelles",
    dial_code: 248,
    country_code: "SC",
    flag: "🇸🇨",
    currency: "SCR",
    currency_symbol: "₨",
  },
  {
    name: "Sierra Leone",
    dial_code: 232,
    country_code: "SL",
    flag: "🇸🇱",
    currency: "SLL",
    currency_symbol: "Le",
  },
  {
    name: "Singapore",
    dial_code: 65,
    country_code: "SG",
    flag: "🇸🇬",
    currency: "BND",
    currency_symbol: "$",
  },
  {
    name: "Slovakia",
    dial_code: 421,
    country_code: "SK",
    flag: "🇸🇰",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Slovenia",
    dial_code: 386,
    country_code: "SI",
    flag: "🇸🇮",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Solomon Islands",
    dial_code: 677,
    country_code: "SB",
    flag: "🇸🇧",
    currency: "SBD",
    currency_symbol: "$",
  },
  {
    name: "Somalia",
    dial_code: 252,
    country_code: "SO",
    flag: "🇸🇴",
    currency: "SOS",
    currency_symbol: "Sh",
  },
  {
    name: "South Africa",
    dial_code: 27,
    country_code: "ZA",
    flag: "🇿🇦",
    currency: "ZAR",
    currency_symbol: "R",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: 500,
    country_code: "GS",
    flag: "🇬🇸",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    name: "Spain",
    dial_code: 34,
    country_code: "ES",
    flag: "🇪🇸",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    name: "Sri Lanka",
    dial_code: 94,
    country_code: "LK",
    flag: "🇱🇰",
    currency: "LKR",
    currency_symbol: "Rs",
  },
  {
    name: "Sudan",
    dial_code: 249,
    country_code: "SD",
    flag: "🇸🇩",
    currency: "SDG",
    currency_symbol: "£",
  },
  {
    name: "Suriname",
    dial_code: 597,
    country_code: "SR",
    flag: "🇸🇷",
    currency: "SRD",
    currency_symbol: "$",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: 47,
    country_code: "SJ",
    flag: "🇸🇯",
    currency: "NOK",
    currency_symbol: "kr",
  },
  {
    name: "Swaziland",
    dial_code: 268,
    country_code: "SZ",
    flag: "🇸🇿",
    currency: "SZL",
    currency_symbol: "L",
  },
  {
    name: "Sweden",
    dial_code: 46,
    country_code: "SE",
    flag: "🇸🇪",
    currency: "SEK",
    currency_symbol: "kr",
  },
  {
    name: "Switzerland",
    dial_code: 41,
    country_code: "CH",
    flag: "🇨🇭",
    currency: "CHF",
    currency_symbol: "₣",
  },
  {
    name: "Arab Republic of Syria",
    dial_code: 963,
    country_code: "SY",
    flag: "🇸🇾",
    currency: "SYP",
    currency_symbol: "£S",
  },
  {
    name: "Taiwan",
    dial_code: 886,
    country_code: "TW",
    flag: "🇹🇼",
    currency: "TWD",
    currency_symbol: " 圓",
  },
  {
    name: "Tajikistan",
    dial_code: 992,
    country_code: "TJ",
    flag: "🇹🇯",
    currency: "TJS",
    currency_symbol: "ЅМ",
  },
  {
    name: "Tanzania",
    dial_code: 255,
    country_code: "TZ",
    flag: "🇹🇿",
    currency: "TZS",
    currency_symbol: "Tsh",
  },
  {
    name: "Thailand",
    dial_code: 66,
    country_code: "TH",
    flag: "🇹🇭",
    currency: "THB",
    currency_symbol: "฿",
  },
  {
    name: "Timor-Leste",
    dial_code: 670,
    country_code: "TL",
    flag: "🇹🇱",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Togo",
    dial_code: 228,
    country_code: "TG",
    flag: "🇹🇬",
    currency: "XAF",
    currency_symbol: "₣",
  },
  {
    name: "Tokelau",
    dial_code: 690,
    country_code: "TK",
    flag: "🇹🇰",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    name: "Tonga",
    dial_code: 676,
    country_code: "TO",
    flag: "🇹🇴",
    currency: "TOP",
    currency_symbol: "T$",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: 1868,
    country_code: "TT",
    flag: "🇹🇹",
    currency: "TTD",
    currency_symbol: "$",
  },
  {
    name: "Tunisia",
    dial_code: 216,
    country_code: "TN",
    flag: "🇹🇳",
    currency: "TND",
    currency_symbol: "د.ت",
  },
  {
    name: "Turkey",
    dial_code: 90,
    country_code: "TR",
    flag: "🇹🇷",
    currency: "TRY",
    currency_symbol: "₺",
  },
  {
    name: "Turkmenistan",
    dial_code: 993,
    country_code: "TM",
    flag: "🇹🇲",
    currency: "TMT",
    currency_symbol: "m",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: 1649,
    country_code: "TC",
    flag: "🇹🇨",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Tuvalu",
    dial_code: 688,
    country_code: "TV",
    flag: "🇹🇻",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    name: "Uganda",
    dial_code: 256,
    country_code: "UG",
    flag: "🇺🇬",
    currency: "UGX",
    currency_symbol: "Sh",
  },
  {
    name: "Ukraine",
    dial_code: 380,
    country_code: "UA",
    flag: "🇺🇦",
    currency: "UAH",
    currency_symbol: "₴",
  },
  {
    name: "United Arab Emirates",
    dial_code: 971,
    country_code: "AE",
    flag: "🇦🇪",
    currency: "AED",
    currency_symbol: "د.إ",
  },
  {
    name: "United Kingdom",
    dial_code: 44,
    country_code: "GB",
    flag: "🇬🇧",
    currency: "GDP",
    currency_symbol: "£",
  },
  {
    name: "United States",
    dial_code: 1,
    country_code: "US",
    flag: "🇺🇸",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Uruguay",
    dial_code: 598,
    country_code: "UY",
    flag: "🇺🇾",
    currency: "UYU",
    currency_symbol: "$",
  },
  {
    name: "Uzbekistan",
    dial_code: 998,
    country_code: "UZ",
    flag: "🇺🇿",
    currency: "UZS",
    currency_symbol: "сум",
  },
  {
    name: "Vanuatu",
    dial_code: 678,
    country_code: "VU",
    flag: "🇻🇺",
    currency: "VUV",
    currency_symbol: "Vt",
  },
  {
    name: "Bolivarian Republic of Venezuela",
    dial_code: 58,
    country_code: "VE",
    flag: "🇻🇪",
    currency: "VES",
    currency_symbol: "Bs",
  },
  {
    name: "Vietnam",
    dial_code: 84,
    country_code: "VN",
    flag: "🇻🇳",
    currency: "VND",
    currency_symbol: "₫",
  },
  {
    name: "Virgin Islands, British",
    dial_code: 1284,
    country_code: "VG",
    flag: "🇻🇬",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: 1340,
    country_code: "VI",
    flag: "🇻🇮",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    name: "Wallis and Futuna",
    dial_code: 681,
    country_code: "WF",
    flag: "🇼🇫",
    currency: "XPF",
    currency_symbol: "₣",
  },
  {
    name: "Yemen",
    dial_code: 967,
    country_code: "YE",
    flag: "🇾🇪",
    currency: "YER",
    currency_symbol: "﷼",
  },
  {
    name: "Zambia",
    dial_code: 260,
    country_code: "ZM",
    flag: "🇿🇲",
    currency: "ZMW",
    currency_symbol: "ZK",
  },
  {
    name: "Zimbabwe",
    dial_code: 263,
    country_code: "ZW",
    flag: "🇿🇼",
    currency: "ZWL",
    currency_symbol: "$",
  },
  {
    name: "Åland Islands",
    dial_code: 358,
    country_code: "AX",
    flag: "🇦🇽",
    currency: "EUR",
    currency_symbol: "€",
  },
];

export default CountriesArray
