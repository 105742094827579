import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderCanvasSelect from "../variants/VariantBuilderCanvasSelect";
import VariantProfile from "../variants/VariantProfile";
import Step from "./Step";
import VariantTheme from "../variants/VariantTheme";
import VariantClose from "../variants/VariantClose";

const StepBuilder = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let selected = builder.selected();
  let cta = props.cta;
  let variant = props.variant;
  let step = props.step || props.variant.steps[props.step.id];
  let max_width = null;

  let classes = "cf-container cf-row cf-outer-center";

  if (
    ["hook", "overlay"].includes(cta.cta_type) &&
    variant.options["show-shadow"] !== "false"
  ) {
    classes += " cf-container-shadow";
  }

  if (props.cta.cta_type !== "page") {
    if (
      props.variant.options["container-width"] == "310px" ||
      (!props.variant.options["container-width"] &&
        props.cta.cta_type == "hook" &&
        props.cta.hook_type == "vertical")
    ) {
      max_width = "310px";
    } else if (
      ["small", "500px"].includes(props.variant.options["container-width"])
    ) {
      max_width = "500px";
    } else if (
      ["regular", "700px"].includes(props.variant.options["container-width"])
    ) {
      max_width = "700px";
    } else if (
      ["large", "900px"].includes(props.variant.options["container-width"])
    ) {
      max_width = "900px";
    } else if (props.variant.options["container-width"] == "1200px") {
      max_width = "1200px";
    } else if (props.variant.options["container-width"] == "100%") {
      max_width = "100%";
    } else if (props.variant.options["container-width"]) {
      max_width = props.variant.options["container-width"];
    }
  }

  if (props.builder == true) {
    const [contextData, builder] = useContext(VariantContextData);
    useEffect(() => {
      let canvas = document.querySelector(
        "#builder .canvas[data-step-id='" + step.id + "']"
      );
      let selectLabel = canvas.querySelector(
        '.select-label[data-object_type="steps"][data-object_id="' +
          step.id +
          '"]'
      );
      let selectLabelPopover = canvas.querySelector(
        '.select-label-popover[data-object_type="steps"][data-object_id="' +
          step.id +
          '"]'
      );

      canvas.onclick = function (e) {
        if (e.target == canvas) {
          dispatchCustomEvent("selectObject", {
            object_type: "steps",
            object_id: step.id,
            panel_closed: false
          });
        }
      };

      document.querySelector(
        ".step-toggle[data-step-id='" + step.id + "']"
      ).onclick = function (e) {
        dispatchCustomEvent("selectObject", {
          object_type: "steps",
          object_id: step.id,
          panel_closed: false
        });
      };

      canvas.onmouseover = function (e) {
        if (
          e.target.classList.contains("canvas") ||
          e.target.classList.contains("step-toggle") ||
          e.target.classList.contains("step-headline") ||
          e.target.classList.contains("glyphicon") ||
          e.target.classList.contains("select-label") ||
          e.target.classList.contains("select-label-span") ||
          e.target.classList.contains("select-label-popover") ||
          selectLabelPopover.contains(e.target)
        ) {
          canvas.classList.add("editor-select");
          selectLabel.style.display = "block";
          selectLabelPopover.style.display = "block";
        } else if (canvas.classList.contains("editor-select")) {
          canvas.classList.remove("editor-select");
          selectLabel.style.display = "none";
          selectLabelPopover.style.display = "none";
        }
      };

      canvas.onmouseleave = function (e) {
        canvas.classList.remove("editor-select");
        selectLabel.style.display = "none";
        selectLabelPopover.style.display = "none";
      };
    });
  }

  return (
    <React.Fragment>
      <div
        id={"step-" + step.id + "-canvas"}
        data-step-id={step.id}
        step-id={step.id}
        data-position={step.position}
        className="step step-builder-wrapper"
      >
        <div
          className="row hard-center some-vertical-space"
          style={{ padding: "0 15px" }}
        >
          <div
            id={"cta_" + props.cta.id}
            className={
              "cta cf-" +
              props.cta.cta_type +
              "-" +
              props.cta[props.cta_type] +
              "_type"
            }
          >
            <div className="cf-container cf-row cf-outer-center">
              <div
                className={
                  "row hard-center canvas preview-" +
                  contextData.device +
                  (props.builder == true &&
                  selected.object_type == "steps" &&
                  selected.object_id == step.id
                    ? " editor-select"
                    : "")
                }
                data-step-id={step.id}
              >
                <div
                  className="row some-vertical-space step-toggle"
                  data-step-id={step.id}
                >
                  <div className="col-xs-12 center">
                    <h4 className={`step-headline text-xl font-semibold ${cta.cta_type == 'overlay' && variant.options["container-background"] == 'dark' ? 'text-white' : 'text-black'} dark:text-white smooth`}>
                      Step {step.position}
                      {step.options && step.options["name"]
                        ? " - " + step.options["name"]
                        : ""}
                    </h4>
                  </div>
                </div>
                <div
                  className="row hard-center cta-preview"
                  style={{
                    width: contextData.device == "desktop" ? max_width : null,
                    maxWidth: contextData.device == "mobile" ? max_width : '100%',
                  }}
                >
                  {["hook", "overlay", "inline"].includes(
                    props.cta.cta_type
                  ) && (
                    <VariantProfile
                      variant={props.variant}
                      cta={props.cta}
                      website={props.website}
                      user={props.user}
                      builder={props.builder}
                    />
                  )}
                  <Step key={"step-" + step.id} step={step} {...props} />
                  {props.builder == true &&
                    ["overlay", "hook", "bar"].includes(cta.cta_type) && (
                      <VariantClose
                        variant={variant}
                        cta={cta}
                        website={props.website}
                        builder={props.builder}
                        device={props.device}
                      />
                    )}
                </div>
                {props.builder == true && (
                  <VariantBuilderCanvasSelect
                    label={"Funnel Step"}
                    object_type="steps"
                    object_id={step.id}
                    object={step}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <VariantTheme {...props} />
      </div>
    </React.Fragment>
  );
};

export default StepBuilder;
