import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tooltip = (props) => {
  const node = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const dark = document.querySelector('body').getAttribute('data-mode') == 'dark';
  
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  return (
    <React.Fragment>
      <div
        className={`${props.inline ? "inline ml-3" : "float-right relative"} ${props.className ? props.className : ""}`}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        <span className="text-xl text-black dark:text-white cursor-pointer">
          {props.label}
          <svg className={`-mb-1 ${props.label ? "ml-2" : ""}`}xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00078 0.800079C4.02433 0.800078 0.800782 4.02363 0.800781 8.00008C0.80078 11.9765 4.02433 15.2001 8.00078 15.2001C11.9772 15.2001 15.2008 11.9765 15.2008 8.00008C15.2008 4.02363 11.9772 0.80008 8.00078 0.800079ZM7.22935 11.9001C7.22935 12.3262 7.57473 12.6716 8.00078 12.6716C8.42683 12.6716 8.77221 12.3262 8.77221 11.9001C8.77221 11.4741 8.42683 11.1287 8.00078 11.1287C7.57473 11.1287 7.22935 11.4741 7.22935 11.9001ZM6.84364 6.65008C6.84364 6.01101 7.36171 5.49294 8.00078 5.49294C8.63985 5.49294 9.15792 6.01101 9.15792 6.65008C9.15792 7.28915 8.63985 7.80722 8.00078 7.80722C7.57473 7.80722 7.22935 8.1526 7.22935 8.57865V9.58588C7.22935 10.0119 7.57473 10.3573 8.00078 10.3573C8.42683 10.3573 8.77221 10.0119 8.77221 9.58588V9.23827C9.8875 8.90634 10.7008 7.87319 10.7008 6.65008C10.7008 5.15891 9.49195 3.95008 8.00078 3.95008C6.50961 3.95008 5.30078 5.15891 5.30078 6.65008C5.30078 7.07613 5.64616 7.42151 6.07221 7.42151C6.49826 7.42151 6.84364 7.07613 6.84364 6.65008Z" fill={dark ? 'white' : 'black'} />
          </svg>
        </span>

        {isPopoverOpen && (
          <div
            className={`absolute w-72 z-10 ${
              props.col || props.inline ? "-left-50" : "right-0"
            } rounded-lg shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5`}
            style={{ top: "75%" }}
            ref={node}
          >
            <div
              className="w-full relative p-3 text-lg text-black dark:text-white normal-case"
              dangerouslySetInnerHTML={{ __html: props.tooltip }}
            />
          </div>
        )}
      </div>

    </React.Fragment>
  );
};

export default Tooltip;
