import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingBorderRadius = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const noneValue = props.valueMappings && props.valueMappings.none ? props.valueMappings.none : "none";
  const someValue = props.valueMappings && props.valueMappings.some ? props.valueMappings.some : "some";
  const fullValue = props.valueMappings && props.valueMappings.full ? props.valueMappings.full : "full";
  const customValue = props.valueMappings && props.valueMappings.custom ? props.valueMappings.custom : "custom";

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">
        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == noneValue ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue(noneValue);
          }}
          title={`Not Rounded`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect
              x="1.75"
              y="1.75"
              width="12.5"
              height="12.5"
              stroke={contextData.dark_mode ? `white` : "black"}
              strokeWidth="1.5"
            />
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == someValue
              ? "bg-white dark:bg-slate-950"
              : ""
          }`}
          onClick={() => {
            updateValue(someValue);
          }}
          title={`Slightly Rounded`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M3.25 13C2.00736 13 1 11.9926 1 10.75L1 3.25C1 2.00736 2.00736 1 3.25 1L10.75 1C11.9926 1 13 2.00736 13 3.25V10.75C13 11.9926 11.9926 13 10.75 13H3.25Z"
              stroke={contextData.dark_mode ? `white` : "black"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == fullValue
              ? "bg-white dark:bg-slate-950"
              : ""
          }`}
          onClick={() => {
            updateValue(fullValue);
          }}
          title={`Fully Rounded`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="14"
            viewBox="0 0 26 16"
            fill="none"
          >
            <rect
              x="2.375"
              y="1.75"
              width="21.25"
              height="12.5"
              rx="6.25"
              stroke={contextData.dark_mode ? `white` : "black"}
              strokeWidth="1.5"
            />
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == customValue ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue(customValue);
          }}
          title={"Custom"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 15 16"
            fill="none"
          >
            <path
              d="M5.36628 1.59998H2.52183C1.73636 1.59998 1.09961 2.23673 1.09961 3.0222V5.86664M5.36628 14.4H2.52183C1.73636 14.4 1.09961 13.7632 1.09961 12.9778V10.1333M9.63294 1.59998H12.4774C13.2629 1.59998 13.8996 2.23673 13.8996 3.0222V5.86664M13.8996 10.1333V12.9778C13.8996 13.7632 13.2629 14.4 12.4774 14.4H9.63294"
              stroke={contextData.dark_mode ? `white` : "black"}
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingBorderRadius;
