import React, { useRef, useState } from "react";
import actionsArray from "../../utils/actionsArray";
import conditionsArray from "../../utils/conditionsArray";
import ActionListItem from "./ActionListItem";
import ActionForm from "./ActionForm";
import useActions from "./useActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActionsBuilder = (props) => {
  const { currentActionsHook } = useActions(props);
  const Actions = currentActionsHook;

  const [showAutomationsForm, setShowAutomationsForm] = useState(false);

  const cta = props.cta;
  const variant = props.variant;
  const website = props.website;
  const broadcast = props.broadcast;
  const element = props.element;

  let actions = [];

  if (element && props.contactable_type == "Element") {
    actions = Object.values(variant.actions).filter(
      (action) => action.element_id == element.id && action.toBeDeleted !== true
    );
  } else if (broadcast) {
    actions = broadcast.actions;
  } else if (variant) {
    actions = variant.actions;
  }

  const [existingActions, setExistingActions] = useState(
    Object.values(actions).filter(
      (action) =>
        action.contactable_type == props.contactable_type &&
        action.contactable_id == props.contactable_id &&
        action.toBeDeleted !== true
    )
  );
  

  const actionsToUse =
    props.builder == false
      ? existingActions
      : Object.values(actions).filter(
          (action) =>
            action.contactable_type == props.contactable_type &&
            action.contactable_id == props.contactable_id &&
            action.toBeDeleted !== true
        );

  const actionsList = actionsArray(
    website,
    website.integrations,
    existingActions
  );

  const conditionsList = conditionsArray(
    variant,
    cta,
    website,
    props.contactable_id,
    props.contactable_type
  );

  const actionsModalRef = useRef(null);

  return (
    <React.Fragment>
      <div className="col-xs-12 some-vertical-space">
        <p className="text-xl text-black dark:text-white">
          {props.title ? props.title : "Run Automations"}
        </p>

        {actionsToUse.length == 0 && (
          <div className="row hard-center p-5 text-black dark:text-white text-xl border-solid border-[1px] borderb-black dark:border-slate-800 mb-5">
            No automations added yet. Add an automation below!
          </div>
        )}

        <div className="automations" style={{ maxWidth: "600px" }}>
          {actionsToUse.map((action, i) => {
            return (
              <div
                key={`action-${action.id}-${i}`}
                className="some-vertical-space"
                style={{ display: "-webkit-box" }}
              >
                <ActionListItem
                  object={action}
                  actionsList={actionsList}
                  website={website}
                  onEdit={(object) => {
                    setModalObject(object);
                    setModalVisibility(true);
                    setTimeout(function () {
                      actionsModalRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }, 100);
                  }}
                  onUpdate={(data) => {
                    if (props.builder == false) {
                      let items = [...existingActions];
                      let targetIndex = items.findIndex(
                        (c) => c.id == data.id
                      );
                      let item = { ...items[targetIndex] };
                      item = data;
                      items[targetIndex] = item;
                      setExistingActions(items);
                    }
                  }}
                  onDuplicate={(object) => {
                    Actions.duplicate(object, {}, false, (data) => {
                      if (props.builder == false) {
                        setExistingActions((actions) => [...actions, data]);
                      }
                    });
                  }}
                  onRemove={(object_id) => {
                    if (props.builder == false) {
                      setExistingActions(
                        existingActions.filter((c) => c.id !== object_id)
                      );
                    }
                  }}
                  {...props}
                />
              </div>
            );
          })}
        </div>

        {showAutomationsForm ? (
          <ActionForm
            actionsList={actionsList}
            conditionsList={conditionsList}
            website={website}
            onCreate={(data) => {
              if (props.builder == false) {
                setExistingActions((actions) => [...actions, data]);
              }
              setShowAutomationsForm(false);
            }}
            {...props}
          />
        ) : (
          <button
            onClick={() => setShowAutomationsForm(true)}
            className="w-full p-2 pl-0 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
          >
            <FontAwesomeIcon icon="plus-square" className="mr-2" /> Add Automation
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionsBuilder;
