import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsListDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let elementVisualType = element.options["list_visual_type"];

  const [modalIsOpen, setModalIsOpen] = useState(
    elementVisualType == "image" ? true : false
  );

  const lazyLoadVisualType = () => {
    setModalIsOpen(true);

    setTimeout(function () {
      if (elementVisualType === "icon") {
        document
          .querySelector(
            "#options-panel div.input-group.iconpicker-container > span"
          )
          .click();
      } else if (elementVisualType === "emoji") {
        document
          .querySelector(
            "#options-panel input[setting_name='[options][list_emoji]'"
          )
          .click();
      }
    }, 100);
  };

  let visualTypeValue = () => {
    if (elementVisualType === "image") {
      return element.options["list_image"];
    } else if (elementVisualType === "icon") {
      return <FontAwesomeIcon icon={element.options["list_icon"]} />;
    } else if (elementVisualType === "emoji") {
      return element.options["list_emoji"];
    }
  };

  let visualTypeSelectPreview = () => {
    if (elementVisualType && ["icon", "emoji"].includes(elementVisualType)) {
      let label =
        elementVisualType.charAt(0).toUpperCase() + elementVisualType.slice(1);

      return (
        <React.Fragment>
          <div
            className="col-xs-3 some-spacing-right"
            onClick={() => lazyLoadVisualType()}
          >
            <button className={`btn btn-clean field-title btn-block btn-lg`}>
              {visualTypeValue()}
            </button>
          </div>

          <div className="col-xs-9 some-spacing-left">
            <button
              onClick={() => lazyLoadVisualType()}
              className={`btn btn-clean-flex field-title btn-block btn-lg`}
              style={{ width: "100% !important" }}
            >
              <span
                style={{
                  fontSize: "12px",
                  marginRight: "10px",
                }}
                className="glyphicon glyphicon-share"
                aria-hidden="true"
              ></span>
              Edit
            </button>
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Content List" open={true}>

      <VariantBuilderSetting
          object={element}
          label="Container Width"
          setting_name="[options][list-width]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-width"] ? element.options["list-width"] : ""
          }
          valueMappings={{
            automatic: 'cf-list-automatic',
            full: 'cf-list-full',
            custom: 'custom',
          }}
          device="desktop"
        />

        <VariantBuilderSetting
          object={element}
          label="Container Width"
          setting_name="[options][list-width-mobile]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-width-mobile"]
              ? element.options["list-width-mobile"]
              : ""
          }
          valueMappings={{
            automatic: 'cf-list-automatic',
            regular: null,
            full: 'cf-list-full',
            custom: 'custom',
          }}
          device="mobile"
        />

        <VariantBuilderSetting
          object={section}
          label={"Container Width Value"}
          setting_name="[options][list-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-width-value"]
              ? element.options["list-width-value"]
              : "0"
          }
          condition={
            element.options["list-width"] == "custom" &&
            ["list"].includes(element.element_type)
          }
          min={0}
          max={2000}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={section}
          label={"Container Width Value"}
          setting_name="[options][list-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-width-value-mobile"]
              ? element.options["list-width-value-mobile"]
              : element.options["list-width-value"]
          }
          condition={
            element.options["list-width-mobile"] == "custom" &&
            ["list"].includes(element.element_type)
          }
          min={0}
          max={2000}
          range_step={"1"}
          device="mobile"
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Font Size"
          setting_name="[options][visual-type-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-size"]
              ? element.options["visual-type-font-size"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-size"]
              ? variant.options["visual-type-font-size"]
              : 24
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Font Size"
          setting_name="[options][visual-type-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-size-mobile"]
              ? element.options["visual-type-font-size-mobile"]
              : element.options["visual-type-font-size"]
              ? element.options["visual-type-font-size"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-size-mobile"]
              ? variant.options["visual-type-font-size-mobile"]
              : variant.options["visual-type-font-size"]
              ? variant.options["visual-type-font-size"]
              : 24
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Font Color"
          setting_name="[options][visual-type-font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-color"]
              ? element.options["visual-type-font-color"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-font-color"]
              ? variant.options["list-visual-type-font-color"]
              : "#000"
          }
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="color"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("color");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Image Width"
          setting_name="[options][visual-type-image-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-image-width"]
              ? element.options["visual-type-image-width"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-image-width"]
              ? variant.options["list-visual-type-image-width"]
              : "200"
          }
          min={0}
          max={750}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Image Width"
          setting_name="[options][visual-type-image-width-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-image-width-mobile"]
              ? element.options["visual-type-image-width-mobile"]
              : element.options["visual-type-image-width"]
              ? element.options["visual-type-image-width"]
              : undefined
          }
          placeholder={
            element.options["visual-type-image-width"]
              ? element.options["visual-type-image-width"]
              : "200"
          }
          min={0}
          max={750}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Spacing"
          setting_name="[options][list-visual-type-spacing]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-visual-type-spacing"]
              ? element.options["list-visual-type-spacing"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-spacing"]
              ? variant.options["list-visual-type-spacing"]
              : "Auto"
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Item Icon Spacing"
          setting_name="[options][list-visual-type-spacing-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-visual-type-spacing-mobile"]
              ? element.options["list-visual-type-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-spacing-mobile"]
              ? variant.options["list-visual-type-spacing-mobile"]
              : "Auto"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Items Divider Border Style"
          setting_name="[options][list-items-divider-style]"
          setting_type="border_style"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-items-divider-style"]
              ? element.options["list-items-divider-style"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Items Divider Size"
          setting_name="[options][list-items-divider-border-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-items-divider-border-width"]
              ? element.options["list-items-divider-border-width"]
              : undefined
          }
          condition={["solid", "dashed", "dotted"].includes(
            element.options["list-items-divider-style"]
          )}
          placeholder="1"
          min={0}
          max={50}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Items Divider Color"
          setting_name="[options][list-items-divider-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-items-divider-color"]
              ? element.options["list-items-divider-color"]
              : undefined
          }
          placeholder={"#000"}
          condition={["solid", "dashed", "dotted"].includes(
            element.options["list-items-divider-style"]
          )}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Text Alignment"
          setting_name="[options][content-alignment]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-alignment"]
              ? element.options["content-alignment"]
              : null
          }
          valueMappings={{
            left: 'left',
            center: 'center',
            right: 'right',
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Font family"
          setting_name="[options][content-font-family]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-family"]
              ? element.options["content-font-family"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-family"]
              ? variant.options["list-content-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);

            // remove top level inline font so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="font-family"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("font-family");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][content-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-weight"]
              ? element.options["content-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Font size"
          setting_name="[options][content-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-size"]
              ? element.options["content-font-size"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-size"]
              ? variant.options["list-content-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Font size"
          setting_name="[options][content-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-size-mobile"]
              ? element.options["content-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-size-mobile"]
              ? variant.options["list-content-font-size-mobile"]
              : variant.options["list-content-font-size"]
              ? variant.options["list-content-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Font Line Height"
          setting_name="[options][content-font-line-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-line-height"]
              ? element.options["content-font-line-height"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-line-height"]
              ? variant.options["list-content-font-line-height"]
              : 20
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $("<div />");
              $(elementHtml).append(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="line-height"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("line-height");
                const new_value = $(elementHtml).html();
                console.log("new_value", new_value);

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Font Line Height"
          setting_name="[options][content-font-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-line-height-mobile"]
              ? element.options["content-font-line-height-mobile"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-line-height-mobile"]
              ? variant.options["list-content-font-line-height-mobile"]
              : variant.options["list-content-font-line-height"]
              ? variant.options["list-content-font-line-height"]
              : 20
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Font color"
          setting_name="[options][content-font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-font-color"]
              ? element.options["content-font-color"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-color"]
              ? variant.options["list-content-font-color"]
              : "#000"
          }
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="color"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("color");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Content Drop Shadow"
          setting_name="[options][content-box-shadow]"
          setting_type="shadow"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-box-shadow"]
              ? element.options["content-box-shadow"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][content-box-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["content-box-shadow-horizontal-distance"]
              ? element.options["content-box-shadow-horizontal-distance"]
              : "0"
          }
          condition={element.options["content-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][content-box-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["content-box-shadow-vertical-distance"]
              ? element.options["content-box-shadow-vertical-distance"]
              : "0"
          }
          condition={element.options["content-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][content-box-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["content-box-shadow-blur-radius"]
              ? element.options["content-box-shadow-blur-radius"]
              : "0"
          }
          condition={element.options["content-box-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Shadow Color"
          setting_name="[options][content-box-shadow-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["content-box-shadow-color"]
              ? element.options["content-box-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={element.options["content-box-shadow"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Container Background color"
          setting_name="[options][list-background]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-background"]
              ? element.options["list-background"]
              : undefined
          }
          placeholder={
            variant.options["list-background"]
              ? variant.options["list-background"]
              : "#000"
          }
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="color"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("color");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Container Border Style"
          setting_name="[options][list-border-style]"
          setting_type="border_style"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-border-style"]
              ? element.options["list-border-style"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Border color"
          setting_name="[options][list-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-border-color"]
              ? element.options["list-border-color"]
              : undefined
          }
          placeholder={"#000"}
          condition={
            element.options["list-border-style"] &&
            element.options["list-border-style"] !== "" &&
            element.options["list-border-style"] !== "none"
          }
          show_reset={true}
        />

        {element.options["list-border-style"] &&
          element.options["list-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][list-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`list-border-width-${side}`]
                          ) == false
                            ? element.options[`list-border-width-${side}`]
                            : element.options["list-border-width"]
                            ? element.options["list-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder="1px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Container Border Corner Radius"
          setting_name="[options][list-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-border-radius"]
              ? element.options["list-border-radius"]
              : undefined
          }
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][list-border-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`list-border-radius-${side}`]) ==
                    false
                      ? element.options[`list-border-radius-${side}`]
                      : undefined
                  }
                  condition={element.options["list-border-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label="Container Shadow"
          setting_name="[options][list-container-shadow]"
          setting_type="shadow"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-container-shadow"]
              ? element.options["list-container-shadow"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][list-container-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["list-container-shadow-horizontal-distance"]
              ? element.options["list-container-shadow-horizontal-distance"]
              : undefined
          }
          condition={element.options["list-container-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][list-container-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["list-container-shadow-horizontal-distance"]
              ? element.options["list-container-shadow-horizontal-distance"]
              : undefined
          }
          condition={element.options["list-container-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][list-container-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["list-container-shadow-blur-radius"]
              ? element.options["list-container-shadow-blur-radius"]
              : undefined
          }
          condition={element.options["list-container-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][list-container-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Spread Radius"}
          value={
            element.options["list-container-shadow-spread-radius"]
              ? element.options["list-container-shadow-spread-radius"]
              : undefined
          }
          condition={element.options["list-container-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Shadow Color"
          setting_name="[options][list-container-shadow-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-container-shadow-color"]
              ? element.options["list-container-shadow-color"]
              : undefined
          }
          placeholder="#000"
          condition={element.options["list-container-shadow"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Inset shadow position"
          setting_name="[options][list-container-shadow-inset]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["list-container-shadow-inset"]
              ? element.options["list-container-shadow-inset"]
              : "false"
          }
          condition={element.options["list-container-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsListDesign;
