import React from "react";
import PropTypes from "prop-types";

const CtasListItemOptions = (props) => {
  const ctaLink = "/websites/" + props.cta.website_id + "/cta/" + props.cta.id;
  const builderLink =
    ctaLink + (props.variant ? "/variants/" + props.variant.id + "/edit" : "");
  const previewLink = ctaLink + "/preview";
  const launchLink = ctaLink + "/launch";
  const duplicateLink = ctaLink + "/duplicate";
  const templateLink =
    window.location.origin +
    "/accounts/" +
    props.website.account_id +
    "/templates/new/?object_type=Cta&object_id=" +
    props.cta.id;
  const archiveLink = ctaLink + "/archive";
  const unarchiveLink = ctaLink + "/unarchive";

  return (
    <React.Fragment>
      <div
        className="btn-group smooth CtasListItemOptions"
        title="Options"
      >
        {props.list_type == "card" && (
          <div
            type="button"
            className="bg-transparent border-none cursor-pointer"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M11.1984 7.9999C11.1984 7.11625 11.9148 6.3999 12.7984 6.3999C13.6821 6.3999 14.3984 7.11625 14.3984 7.9999C14.3984 8.88356 13.6821 9.5999 12.7984 9.5999C11.9148 9.5999 11.1984 8.88356 11.1984 7.9999Z" fill="black"/>
              <path d="M6.39844 7.9999C6.39844 7.11625 7.11478 6.3999 7.99844 6.3999C8.88209 6.3999 9.59844 7.11625 9.59844 7.9999C9.59844 8.88356 8.88209 9.5999 7.99844 9.5999C7.11478 9.5999 6.39844 8.88356 6.39844 7.9999Z" fill="black"/>
              <path d="M1.59844 7.9999C1.59844 7.11625 2.31478 6.3999 3.19844 6.3999C4.08209 6.3999 4.79844 7.11625 4.79844 7.9999C4.79844 8.88356 4.08209 9.5999 3.19844 9.5999C2.31478 9.5999 1.59844 8.88356 1.59844 7.9999Z" fill="black"/>
            </svg>
          </div>
        )}
        {props.list_type == "row" && (
          <button
            type="button"
            className="bg-white border-solid border-[1px] py-3 px-2 w-[85px] mr-4 rounded-md border-slate-200 text-black text-xl hover:bg-blue-100 hover:border-blue-100 hover:text-blue-600"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{
              marginTop: "-5px",
            }}
          >
            <span>Options</span>{" "}
            <span className="caret"></span>
          </button>
        )}
        <ul className="!rounded-lg !border-none !shadow-lg dropdown-menu">
          <li>
            <a className="" href={ctaLink}>
              Campaign
            </a>
          </li>
          <li>
            <a className="" href={builderLink} data-turbolinks="false">
              Builder
            </a>
          </li>
          <li>
            <a className="" href={previewLink}>
              Preview
            </a>
          </li>
          <li>
            <a className="" href={launchLink}>
              Launch
            </a>
          </li>
          <li>
            <a
              className=""
              data-toggle="modal"
              data-target={"#Cta" + props.cta.id + "GroupsModal"}
            >
              Groups
            </a>
          </li>
          <li>
            <a
              className="new-cta"
              href={duplicateLink}
              target="_blank"
              data-confirm="Are you sure you want to duplicate this campaign and its variants?"
            >
              Duplicate
            </a>
          </li>
          {props.role_type !== "client" && (
            <li>
              <a
                className="template-create"
                target="_blank"
                href={templateLink}
              >
                Save as template
              </a>
            </li>
          )}
          {props.cta.archived !== true && (
            <li>
              <a
                className=""
                href={archiveLink}
                data-confirm="Are you sure you want to archive this campaign? This will deactivate it on your website."
              >
                Archive
              </a>
            </li>
          )}
          {props.cta.archived == true && (
            <React.Fragment>
              <li>
                <a
                  className=""
                  href={unarchiveLink}
                  data-confirm="Are you sure you want to unarchive this campaign? This will make it possible to display on your website again."
                >
                  Unarchive
                </a>
              </li>
              <li>
                <a
                  className=""
                  href={ctaLink}
                  data-confirm="Are you sure you want to delete this call-to-action?"
                  data-method="delete"
                  rel="nofollow"
                >
                  Delete
                </a>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default CtasListItemOptions;
