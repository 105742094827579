import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useProductVariants from "./useProductVariants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewProductVariant = (props) => {
  const { ProductVariants } = useProductVariants();

  const createProductVariant = () => {
    ProductVariants.create(props.product, (product_variant) => {
      dispatchCustomEvent("focusItem", {
        parent_id: props.product.id,
        item_type: "product_variants",
        item_id: product_variant.id
      });
    });
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 my-5">
        <button
          onClick={createProductVariant}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
          title="Add Product Variant"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add Product Variant
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewProductVariant;
