var groups = {};

import initialize from "packs/groups/initialize.js";
groups.initialize = initialize;

document.addEventListener("turbolinks:load", function () {
  groups.initialize();
});

export default groups;
