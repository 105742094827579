var tooltips = function (step) {
  if (window.cta && Object.keys(window.cta.steps).length > 0) {
    if (window.tour !== undefined) {
      tour.hide();
    }

    var completeTooltip = function () {
      $('.checklist-step[data-step="builder"]').addClass("active");
      setCookie("cta_builder_onboarded", "true", 360);
      onboard.tooltips.current = undefined;
    };

    window.tooltips_ran = true;
    window.tour = new Shepherd.Tour({
      defaults: {
        classes: "shepherd-theme-arrows shepherd-theme-square smooth",
      },
    });
    $(".tutorial-tab-tooltip-trigger").hide();

    if (getCookie("cta_builder_onboarded") !== "true") {
      var start_attach =
        '.checklist-step[data-step="builder"] .wistia_responsive_padding right';
    } else {
      var start_attach = ".canvas top";
    }

    $('.tutorial-tab-tooltip-trigger[data-tooltip="start"]').show();

    if ($(start_attach)[0]) {
      tour.addStep("start", {
        title: "The ConvertFlow builder tour",
        text:
          "Learn how to use the builder. Watch the video<br> to the left, or click to follow a guided tour 👈",
        attachTo: start_attach,
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "Guided tour →",
            action: function () {
              $("#onboard-popup").hide();
              $("#onboard").show();
              return tour.next();
            },
          },
        ],
        when: {
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }

    tour.addStep("builder", {
      title: "The call-to-action (CTA) builder",
      text:
        "From one builder, you can craft any type of CTA.<br>Effortlessly design buttons, forms, popups,<br> sticky bars, landing pages, funnels, etc. <br><br>No more coding or waiting on developers 🙌",
      attachTo: ".canvas bottom",
      advanceOn: ".docs-link click",
      showCancelLink: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: "Next →",
          action: function () {
            $(".section-add-element").show().trigger("click");
            return tour.next();
          },
        },
      ],
      when: {
        complete: function () {},
        hide: function () {
          completeTooltip();
        },
      },
    });

    $('.tutorial-tab-tooltip-trigger[data-tooltip="add-elements"]').show();

    tour.addStep("add-elements", {
      title: "Adding elements to the builder",
      text:
        "Click the add icon inside a column to add elements.<br> Add headlines, text, images, forms, buttons and more.",
      attachTo: ".section-add-element top",
      advanceOn: ".docs-link click",
      showCancelLink: true,
      scrollTo: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: "Next →",
          action: function () {
            return tour.next();
          },
        },
      ],
      when: {
        show: function () {
          $(".section-add-element").first().show().trigger("click");
          setTimeout(function () {
            window.scrollTo(0, window.scrollY - 100);
          }, 50);
        },
        complete: function () {},
        hide: function () {
          completeTooltip();
        },
      },
    });

    if (
      $.map(cta.elements, function (element) {
        if (
          element.element_type == "headline" ||
          element.element_type == "text"
        )
          return element;
      }).length > 0
    ) {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="text-elements"]').show();

      tour.addStep("text-elements", {
        title: "Headline & text elements",
        text:
          "Double click on headlines, text, buttons,<br> etc. to edit text and style content",
        attachTo: ".cf-text-element top",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $(".cf-text-element").first().parent().trigger("click");
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      $(
        '.tutorial-tab-tooltip-trigger[data-tooltip="text-elements-drag"]'
      ).show();

      tour.addStep("text-elements-drag", {
        title: "Drag-and-drop to position",
        text:
          "Grab any element in the builder and<br> drop it where you'd like it to display.",
        attachTo: ".cf-text-element top",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $(".cf-text-element").first().parent().trigger("click");
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      $(
        '.tutorial-tab-tooltip-trigger[data-tooltip="text-elements-toolbar"]'
      ).show();

      tour.addStep("text-elements-toolbar", {
        title: "Control elements with the toolbar",
        text:
          "Select an element, and you can use<br> the toolbar to move elements<br> up/down, duplicate it or delete it",
        attachTo: '.toolbar-button[data-type="duplicate"] bottom',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $(".cf-text-element").first().parent().trigger("click");
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      $(
        '.tutorial-tab-tooltip-trigger[data-tooltip="text-elements-design"]'
      ).show();

      tour.addStep("text-elements-design", {
        title: "Design controls for elements",
        text:
          "Click on any element and its design settings<br> will appear here in the builder panel",
        attachTo: ".options-container left",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }

    if (
      $.map(cta.elements, function (element) {
        if (element.element_type == "form") return element;
      }).length > 0
    ) {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="form-elements"]').show();

      tour.addStep("form-elements", {
        title: "Form elements",
        text:
          "Click on form elements to access<br> the form builder in the panel",
        attachTo: ".cf-form left",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        scrollTo: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $(".cf-form").first().parent().trigger("click");
            setTimeout(function () {
              window.scrollTo(0, window.scrollY - 100);
            }, 50);
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      if (
        window.website &&
        (!website.integrations ||
          $.map(website.integrations, function (integration) {
            if (integration.api_data.actions_map) return integration;
          }).length == 0)
      ) {
        tour.addStep("form-elements-integration", {
          title: "Let's get integrated",
          text:
            "Have an email service provider to send form<br> submissions to? Click here to connect it now.",
          attachTo: ".integration-first-message left",
          advanceOn: ".docs-link click",
          showCancelLink: true,
          buttons: [
            {
              text: "← Back",
              classes: "shepherd-back",
              action: function () {
                return tour.back();
              },
            },
            {
              text: "Next →",
              action: function () {
                return tour.next();
              },
            },
          ],
          when: {
            show: function () {
              onboard.tooltips.current = $(".integration-first-message").attr(
                "data-element-id"
              );
            },
            complete: function () {},
            hide: function () {
              completeTooltip();
            },
          },
        });
      }

      tour.addStep("form-elements-actions", {
        title: "Form submission actions",
        text:
          'Once you\'ve integrated your ESP/CRM, use<br> "confirmation actions" to control what<br> happens after someone submits your form',
        attachTo: 'button[data-modal-title="On form submission"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      tour.addStep("form-elements-builder", {
        title: "Enabling contact fields",
        text: "Toggle fields here to display them in your form",
        attachTo: '.setting[data-contact-field="city"] left',
        scrollTo: true,
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("form-elements-fields", {
        title: "Adding custom fields to your form",
        text:
          "Click here to add custom fields to your forms.<br><br> Ask visitors for info using text, dropdown,<br> radio and paragraph fields, and map each<br> custom field to your email marketing tools",
        attachTo: ".field-add-container .glyphicon-plus-sign left",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.show("sections");
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      tour.addStep("form-elements-actions-modal", {
        title: "Form submission actions",
        text:
          "Control what happens when people submit this form.<br><br> You can show a thank you message, jump to another<br> step in this CTA, redirect them to page or even trigger<br> another ConvertFlow CTA. The possibilities are endless!",
        attachTo: '.setting[data-attribute*="confirmation-type"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              $("#settingsModal").modal("hide");
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("form-elements-actions-automations", {
        title: "Form automation triggers",
        text:
          "Add automations to your form to segment your leads in<br> your connected email marketing tools (tags, lists, etc).",
        attachTo: '.setting[data-setting-type="automations"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("form-elements-actions-scripts", {
        title: "Form conversion pixels and scripts",
        text:
          "Add custom scripts to fire conversion pixels <br> or run your own custom actions",
        attachTo: '.setting[data-attribute*="script"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("form-elements-actions-conditional", {
        title: "Conditional form actions",
        text:
          "Only want ConvertFlow to perform actions when<br> a person meets certain conditions? Add<br> conditional actions to send people down paths<br> based on their field selections and more",
        attachTo: '.add-container[data-type="action-groups"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {
            $("#settingsModal").modal("hide");
          },
          hide: function () {
            completeTooltip();
            $("#settingsModal").modal("hide");
          },
        },
      });
    }

    if (
      $.map(cta.elements, function (element) {
        if (element.element_type == "survey") return element;
      }).length > 0
    ) {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="survey-elements"]').show();

      tour.addStep("survey-elements", {
        title: "Survey elements",
        text:
          "Click on survey elements to access<br> the survey builder in the panel",
        attachTo: ".cf-survey left",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        scrollTo: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $(".cf-survey").first().parent().trigger("click");
            setTimeout(function () {
              window.scrollTo(0, window.scrollY - 100);
            }, 50);
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      if (
        window.website &&
        (!website.integrations ||
          $.map(website.integrations, function (integration) {
            if (integration.api_data.actions_map) return integration;
          }).length == 0)
      ) {
        tour.addStep("survey-elements-integration", {
          title: "Let's get integrated",
          text:
            "Have an email service provider to send survey<br> responses to? Click here to connect it now.",
          attachTo: ".integration-first-message left",
          advanceOn: ".docs-link click",
          showCancelLink: true,
          buttons: [
            {
              text: "← Back",
              classes: "shepherd-back",
              action: function () {
                return tour.back();
              },
            },
            {
              text: "Next →",
              action: function () {
                return tour.next();
              },
            },
          ],
          when: {
            show: function () {
              onboard.tooltips.current = $(".integration-first-message").attr(
                "data-element-id"
              );
            },
            complete: function () {},
            hide: function () {
              completeTooltip();
            },
          },
        });
      }

      tour.addStep("survey-elements-actions", {
        title: "Survey selection actions",
        text:
          'Once you\'ve integrated your ESP/CRM, use<br> "confirmation actions" to control what happens<br> after someone selects an option',
        attachTo: 'button[data-modal-title="On survey selection"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("survey-elements-builder", {
        title: "Adjusting survey options",
        text:
          "Manage your survey option values,<br> images and positioning here",
        attachTo: ".field-options-container left",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("survey-elements-fields", {
        title: "Adding survey options",
        text: "Click here to add new options to your surveys.",
        attachTo: ".field-option-input left",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("survey-elements-fields", {
        title: "Map to a custom field",
        text:
          "Send survey selections to custom fields<br> in your integrated email marketing tool",
        attachTo: '.setting[data-attribute*="slug"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.show("sections");
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("survey-elements-actions-modal", {
        title: "Survey selection actions",
        text:
          "Control what happens when people select an option.<br><br> You can show a thank you message, jump to another<br> step in this CTA, redirect them to page or even trigger<br> another ConvertFlow CTA. The possibilities are endless!",
        attachTo: '.setting[data-attribute*="confirmation-type"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              $("#settingsModal").modal("hide");
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            setCookie("cta_builder_onboarded", "true", 360);
          },
        },
      });
      tour.addStep("survey-elements-actions-automations", {
        title: "Survey automation triggers",
        text:
          "Add automations to your survey to segment your leads in<br> your connected email marketing tools (tags, lists, etc).",
        attachTo: '.setting[data-setting-type="automations"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("survey-elements-actions-scripts", {
        title: "Survey conversion pixels and scripts",
        text:
          "Add custom scripts to fire conversion pixels <br> or run your own custom actions",
        attachTo: '.setting[data-attribute*="script"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("survey-elements-actions-conditional", {
        title: "Conditional survey actions",
        text:
          "Only want ConvertFlow to perform actions when<br> a person meets certain conditions? Add<br> conditional actions to send people down paths<br> based on their survey selections and more",
        attachTo: '.add-container[data-type="action-groups"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {
            $("#settingsModal").modal("hide");
          },
          hide: function () {
            completeTooltip();
            $("#settingsModal").modal("hide");
          },
        },
      });
    }

    if (
      $.map(cta.elements, function (element) {
        if (element.element_type == "button") return element;
      }).length > 0
    ) {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="button-elements"]').show();

      tour.addStep("button-elements", {
        title: "Button elements",
        text:
          "Click on button elements to control<br> redirect settings, design styles and more",
        attachTo: '.cf-element[data-type="button"] .cf-button top',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        scrollTo: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $('.cf-element[data-type="button"] .cf-button')
              .first()
              .parent()
              .trigger("click");
            setTimeout(function () {
              window.scrollTo(0, window.scrollY - 200);
            }, 50);
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      tour.addStep("button-elements-actions", {
        title: "Button click actions",
        text:
          "Click here to control what happens<br> after someone clicks your button",
        attachTo: 'button[data-modal-title="On button click"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.show("sections");
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      tour.addStep("button-elements-actions-modal", {
        title: "Button click actions",
        text:
          "Control what happens when people click this button.<br><br> You can jump to another step in this CTA, redirect<br> them to page or even trigger another ConvertFlow CTA.",
        attachTo: '.setting[data-attribute*="confirmation-type"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              $("#settingsModal").modal("hide");
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("button-elements-actions-automations", {
        title: "Button automation triggers",
        text:
          "Add automations to your button to segment your leads in<br> your connected email marketing tools (tags, lists, etc).",
        attachTo: '.setting[data-setting-type="automations"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
      tour.addStep("button-elements-actions-scripts", {
        title: "Button conversion pixels and scripts",
        text:
          "Add custom scripts to fire conversion pixels <br> or run your own custom actions",
        attachTo: '.setting[data-attribute*="script"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $("#settingsModal")
              .unbind("hidden.bs.modal")
              .on("hidden.bs.modal", function () {
                tour.hide();
              });
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }

    if ($.isEmptyObject(cta.sections) == false) {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="sections"]').show();

      var section_id = $(".cf-column-first").first().attr("section-id");

      if (step == "sections") {
        $('.cf-column-first[section-id="' + section_id + '"]')
          .addClass("editor-select")
          .find(".section-options-toggle")
          .show();
        $('.cf-background-overlay[section-id="' + section_id + '"]').click();
        $('.tab-row[data-tab="Column Layout"] .tab-toggle').click();
      }

      tour.addStep("sections", {
        title: "Sections & columns",
        text:
          "Click on section columns to toggle the<br> settings panel. Control column layouts,<br> background colors & images and column styles.",
        attachTo: '.cf-column-first[section-id="' + section_id + '"] right',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        scrollTo: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              tour.show("text-elements");
            },
          },
          {
            text: "Next →",
            action: function () {
              $('.panel-tab[data-tab="steps"]').click();
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            var section_id = $(".cf-column-first").first().attr("section-id");
            $("#settingsModal").unbind("hidden.bs.modal");
            $("#settingsModal").modal("hide");
            $('.cf-column-first[section-id="' + section_id + '"]')
              .addClass("editor-select")
              .find(".section-options-toggle")
              .show();
            $(
              '.cf-background-overlay[section-id="' + section_id + '"]'
            ).click();
            $('.tab-row[data-tab="Column Layout"] .tab-toggle').click();
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }

    if ($.isEmptyObject(cta.steps) == false) {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="steps"]').show();

      tour.addStep("steps", {
        title: "Add steps for more conversions 📈 ",
        text:
          "Ditch long forms and low conversion rates.<br> Convert higher by adding multiple steps and<br> guiding your visitors each step of the way",
        attachTo: ".step-add-container top",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        scrollTo: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      tour.addStep("steps-manage", {
        title: "Manage your steps",
        text:
          "Easily manage your CTA steps. Steps can be named,<br> ordered, duplicated and you can also add skip logic<br> to skip returning visitors to right where they left off",
        attachTo: '.panel-tab[data-tab="steps"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        scrollTo: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $('#options-panel .panel-tab[data-tab="steps"]').click();
            $(".step-thumbnail")
              .first()
              .find(".step-thumbnail-overlay")
              .css("opacity", "1");
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }
    if (cta.cta_type == "inline") {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="inline-settings"]').show();

      if (step == "inline-settings") {
        $('.panel-tab[data-tab="cta"]').click();
      }

      tour.addStep("inline-settings", {
        title: "Embedded CTA settings",
        text:
          "Control the size of your embedded CTA and<br> more by clicking to edit CTA settings",
        attachTo: '.panel-tab[data-tab="cta"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              $('.panel-tab[data-tab="steps"]').click();
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $('.panel-tab[data-tab="cta"]').click();
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }
    if (cta.cta_type == "hook" || cta.cta_type == "overlay") {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="popup-settings"]').show();

      if (step == "popup-settings") {
        $('.panel-tab[data-tab="cta"]').click();
      }

      tour.addStep("popup-settings", {
        title: "Popup CTA settings",
        text:
          "Control the size of your popup CTA<br> container, trigger settings and more<br> by clicking to edit CTA settings",
        attachTo: '.panel-tab[data-tab="cta"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              $('.panel-tab[data-tab="steps"]').click();
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $('.panel-tab[data-tab="cta"]').click();
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });

      $(
        '.tutorial-tab-tooltip-trigger[data-tooltip="profile-settings"]'
      ).show();

      if (step == "profile-settings") {
        $('.panel-tab[data-tab="cta"]').click();
      }

      tour.addStep("profile-settings", {
        title: "Adding a user profile",
        text:
          "Toggle displaying one of your team member's<br> user profile images with your CTA",
        attachTo: '.tab-row[data-tab="User Profile"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {},
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }
    if (cta.cta_type == "bar") {
      $('.tutorial-tab-tooltip-trigger[data-tooltip="bar-settings"]').show();

      if (step == "bar-settings") {
        $('.panel-tab[data-tab="cta"]').click();
      }

      tour.addStep("bar-settings", {
        title: "Sticky Bar CTA settings",
        text:
          "Control where on the screen your sticky bar<br> displays and how it triggers by<br> clicking to edit CTA settings",
        attachTo: '.panel-tab[data-tab="cta"] left',
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              $('.panel-tab[data-tab="steps"]').click();
              return tour.back();
            },
          },
          {
            text: "Next →",
            action: function () {
              return tour.next();
            },
          },
        ],
        when: {
          show: function () {
            $('.panel-tab[data-tab="cta"]').click();
          },
          complete: function () {},
          hide: function () {
            completeTooltip();
          },
        },
      });
    }

    $('.tutorial-tab-tooltip-trigger[data-tooltip="save"]').show();

    tour.addStep("save", {
      title: "Saving your changes",
      text:
        'Click the blue "Save" button (or command+S keys)<br> at any time while editing to save your work',
      attachTo: '.btn-save[data-type="save"] bottom',
      advanceOn: ".docs-link click",
      showCancelLink: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: "Next →",
          action: function () {
            return tour.next();
          },
        },
      ],
      when: {
        show: function () {},
        complete: function () {},
        hide: function () {
          completeTooltip();
        },
      },
    });

    tour.addStep("publish", {
      title: "Publish your changes",
      text:
        "Once you've saved your changes, you<br> can stage them to go live on your<br> site by clicking the publish button",
      attachTo: '.btn-save[data-type="publish"] bottom',
      advanceOn: ".docs-link click",
      showCancelLink: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: "Next →",
          action: function () {
            return tour.next();
          },
        },
      ],
      when: {
        show: function () {},
        complete: function () {},
        hide: function () {},
      },
    });

    tour.addStep("preview", {
      title: "Preview before launching",
      text:
        "When you're all done making edits to your CTA, click to<br> the preview page to test it out before launching",
      attachTo: ".cta-nav-preview left",
      advanceOn: ".docs-link click",
      showCancelLink: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: "Next →",
          action: function () {
            $("#onboard").show();
            $("#onboard-popup").hide();
            return tour.next();
          },
        },
      ],
      when: {
        show: function () {},
        complete: function () {},
        hide: function () {},
      },
    });

    tour.addStep("tutorials", {
      title: "Find helpful tutorials",
      text:
        "For each CTA you create, we'll recommend<br> tutorials to help you get it launched.<br> Click the widget below to access them.",
      attachTo: "#onboard top",
      advanceOn: ".docs-link click",
      showCancelLink: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: "Next →",
          action: function () {
            $("#onboard").hide();
            $("#onboard-popup").show();
            return tour.next();
          },
        },
      ],
      when: {
        show: function () {},
        complete: function () {},
        hide: function () {
          completeTooltip();
        },
      },
    });

    var cta_type;
    if (cta.cta_type == "inline") {
      var cta_type = "embedded";
    } else if (cta.cta_type == "page") {
      var cta_type = "landing page";
    } else if (cta.cta_type == "bar") {
      var cta_type = "sticky bar";
    } else {
      var cta_type = cta.cta_type;
    }

    if (!template || !template.tutorial) {
      var next_button = "Finish";
    } else {
      var next_button = "Next →";
    }

    tour.addStep("video", {
      title: "Watch a video on " + cta_type + " CTAs",
      text:
        "Learn how to build " +
        cta_type +
        " CTAs<br> and launch them on your website",
      attachTo: "#onboard-popup right",
      advanceOn: ".docs-link click",
      showCancelLink: true,
      buttons: [
        {
          text: "← Back",
          classes: "shepherd-back",
          action: function () {
            return tour.back();
          },
        },
        {
          text: next_button,
          action: function () {
            if (!template || !template.tutorial) {
              return tour.hide();
            } else {
              return tour.next();
            }
          },
        },
      ],
      when: {
        show: function () {
          // if ($('.checklist-step[data-step="builder"] .tutorial-tab-content').is(':visible')) {
          //   $('.checklist-step[data-step="builder"] .tutorial-tab-header').trigger('click')
          // }

          if (
            $('.checklist-step[data-step="101"] .tutorial-tab-content').is(
              ":visible"
            ) == false
          ) {
            $('.checklist-step[data-step="101"] .tutorial-tab-header').trigger(
              "click"
            );
            var topPos = $('.onboard-checklist-box[data-step="launch"]')[0]
              .offsetTop;
            $(".checklist-scroll")[0].scrollTop = topPos + 50;
          }

          if (!template || !template.tutorial) {
            completeTooltip();
          }
        },
        complete: function () {},
        hide: function () {
          completeTooltip();
        },
      },
    });

    if (cta.template) {
      tour.addStep("template", {
        title: "Follow a tutorial on this template",
        text:
          "Learn how to use this CTA template with<br> this tutorial from the creator",
        attachTo: "#onboard-popup right",
        advanceOn: ".docs-link click",
        showCancelLink: true,
        buttons: [
          {
            text: "← Back",
            classes: "shepherd-back",
            action: function () {
              return tour.back();
            },
          },
          {
            text: "Finish",
            action: function () {
              tour.hide();
            },
          },
        ],
        when: {
          show: function () {
            completeTooltip();

            if (
              $(
                '.checklist-step[data-step="builder"] .tutorial-tab-content'
              ).is(":visible")
            ) {
              $(
                '.checklist-step[data-step="builder"] .tutorial-tab-header'
              ).trigger("click");
            }

            if (
              $(
                '.checklist-step[data-step="' +
                  cta.cta_type +
                  '"] .tutorial-tab-content'
              ).is(":visible")
            ) {
              $(
                '.checklist-step[data-step="' +
                  cta.cta_type +
                  '"] .tutorial-tab-header'
              ).trigger("click");
            }

            if (
              $(
                '.checklist-step[data-step="template"] .tutorial-tab-content'
              ).is(":visible") == false
            ) {
              $(
                '.checklist-step[data-step="template"] .tutorial-tab-header'
              ).trigger("click");
            }
          },
          complete: function () {
            completeTooltip();
          },
          hide: function () {
            completeTooltip();
          },
        },
      });
    }

    if (step !== undefined) {
      $("#options-panel")
        .removeClass("options-panel-closed")
        .addClass("options-panel-open");
      $("#options-panel-toggle .glyphicon")
        .removeClass("glyphicon-cog")
        .addClass("glyphicon-chevron-right");
      $("#builder")
        .css("min-width", "0")
        .css("max-width", "calc(100% - 275px)");

      if (step == "steps") {
        $('.panel-tab[data-tab="steps"]').click();
      }

      $.each(["form", "survey", "button"], function (i, element_type) {
        if (step == element_type + "-elements")
          setCookie("cta_" + element_type + "_onboarded", "false", 360);
      });

      tour.show(step);
      // $('#onboard').show()
    }

    $("#settingsModal")
      .unbind("shown.bs.modal")
      .on("shown.bs.modal", function () {
        setTimeout(function () {
          if (
            $("#settingsModal .modal-title").text() == "On form submission" &&
            getCookie("cta_form_onboarded") !== "true"
          ) {
            setCookie("cta_form_onboarded", "true", 360);
            tour.show("form-elements-actions-modal");
          }

          if (
            $("#settingsModal .modal-title").text() == "On survey selection" &&
            getCookie("cta_survey_onboarded") !== "true"
          ) {
            setCookie("cta_survey_onboarded", "true", 360);
            tour.show("survey-elements-actions-modal");
          }

          if (
            $("#settingsModal .modal-title").text() == "On button click" &&
            getCookie("cta_button_onboarded") !== "true"
          ) {
            setCookie("cta_button_onboarded", "true", 360);
            tour.show("button-elements-actions-modal");
          }
        }, 500);
      });
  }
};

export default tooltips;
