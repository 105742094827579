import React, { useState, useEffect, useRef } from "react";

const VariantBuilderToolbarVariants = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  const showPreview = window.location.search.includes("?preview=true");

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const links = props.cta.variants.map((variant) => {
    return {
      label: `Variant ${variant.variation.toUpperCase()}${
        variant.name && variant.name !== "Champion" ? ` - ${variant.name}` : ""
      }`,
      url: `/websites/${props.website.id}/cta/${props.cta.id}/variants/${variant.id}/edit`,
      id: variant.id,
    };
  });

  return (
    <div className="inline-flex flex-col pt-2 relative" ref={node}>
      <button
        className="w-[90px] bg-blue-100 dark:bg-slate-800 text-blue-600 dark:text-white dark:hover:text-white py-2 px-4 rounded-lg border-none text-xl ml-4"
        onClick={toggleCollapse}
        style={{
          height: "32px",
        }}
      >
        Variant {props.variant.variation.toUpperCase()}{" "}
        <i className={`fas fa-chevron-down w-4`}></i>
      </button>
      {isOpen && (
        <div
          className="absolute w-[150px] rounded-lg shadow-lg overflow-hidden bg-blue-100 dark:bg-gray-900 ring-1 ring-black ring-opacity-5"
          style={{ top: "100%", left: "10px" }}
        >
          {links.map((link, index) => (
            <a key={index} href={`${link.url}${showPreview ? '?preview=true' : ''}`}>
              <div
                className={`w-full px-5 py-2 text-lg overflow-hidden overflow-ellipsis ${
                  props.variant.id == link.id
                    ? "bg-blue-600 text-white dark:text-white"
                    : "hover:bg-slate-100 dark:hover:bg-gray-950 text-blue-600 dark:text-gray-300"
                } whitespace-nowrap`}
              >
                {link.label}
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default VariantBuilderToolbarVariants;
