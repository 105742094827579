import React from "react";
import PropTypes from "prop-types";
import Section from "../sections/Section";

const ElementRow = (props) => {
  let element = props.element;

  let row_section;

  if (!element.row_section && element.sections) {
    row_section = Object.values(element.sections).filter(
      (section) =>
        section.element_id == element.id &&
        section.step_id == props.section.step_id
    )[0];
  } else {
    row_section = element.row_section;
  }

  return (
    <React.Fragment>
      {row_section && (
        <Section
          key={"element-" + element.id + "-section-" + row_section.id}
          website={props.website}
          cta={props.cta}
          variant={props.variant}
          builder={props.builder}
          preview={props.preview}
          step={props.step}
          section={row_section}
          device={props.device}
        ></Section>
      )}
    </React.Fragment>
  );
};

export default ElementRow;
