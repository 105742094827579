import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingBoxHeight = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const smallHeightValue = props.valueMappings && props.valueMappings.small ? props.valueMappings.small : "small";
  const regularHeightValue = props.valueMappings && props.valueMappings.regular ? props.valueMappings.regular : "regular";
  const largeHeightValue = props.valueMappings && props.valueMappings.large ? props.valueMappings.large : "large";
  const customHeightValue = props.valueMappings && props.valueMappings.custom ? props.valueMappings.custom : "custom";

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">

        {props.valueMappings && props.valueMappings.small && (
          <button
            className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
              props.value == smallHeightValue ? "bg-white dark:bg-slate-950" : ""
            }`}
            onClick={() => {
              updateValue(smallHeightValue);
            }}
            title={`Small Height`}
          >
            <div className=" ml-auto mr-auto w-[25px] h-[8px] bg-slate-500 dark:bg-slate-300 rounded">
            </div>
          </button>
        )}

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == regularHeightValue
              ? "bg-white dark:bg-slate-950"
              : ""
          }`}
          onClick={() => {
            updateValue(regularHeightValue);
          }}
          title={`Regular Height`}
        >
          <div className=" ml-auto mr-auto w-[25px] h-[11px] bg-slate-500 dark:bg-slate-300 rounded">
          </div>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == largeHeightValue
              ? "bg-white dark:bg-slate-950"
              : ""
          }`}
          onClick={() => {
            updateValue(largeHeightValue);
          }}
          title={`Large Height`}
        >
          <div className=" ml-auto mr-auto w-[25px] h-[15px] bg-slate-500 dark:bg-slate-300 rounded">
          </div>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == customHeightValue ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue(customHeightValue);
          }}
          title={"Custom Button Height"}
        >
          <div className=" ml-auto mr-auto w-[25px] h-[12px] border-dashed border-[1px] border-slate-800 dark:border-white rounded">
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingBoxHeight;
