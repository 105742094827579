import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeTimer = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Timers" open={false}>
        <VariantBuilderSetting
          object={variant}
          label="Timer number font"
          setting_name="[options][timer-number-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["timer-number-font"]
              ? variant.options["timer-number-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-number-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["timer-number-font-weight"]
              ? variant.options["timer-number-font-weight"]
              : "700"
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer number size"
          setting_name="[options][timer-number-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["timer-number-size"]
              ? variant.options["timer-number-size"]
              : undefined
          }
          placeholder={35}
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer number size"
          setting_name="[options][timer-number-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["timer-number-size-mobile"]
              ? variant.options["timer-number-size-mobile"]
              : undefined
          }
          placeholder={variant.options["timer-number-size"]}
          min={0}
          max={100}
          range_step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer number text color"
          setting_name="[options][timer-number-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["timer-number-color"]
              ? variant.options["timer-number-color"]
              : undefined
          }
          placeholder="#fff"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-padding]"
          setting_type="range"
          object_type="variants"
          label={"Timer number background padding"}
          value={
            variant.options["timer-padding"]
              ? variant.options["timer-padding"]
              : undefined
          }
          placeholder="20"
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-padding-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Timer number background padding"}
          value={
            variant.options["timer-padding-mobile"]
              ? variant.options["timer-padding-mobile"]
              : undefined
          }
          placeholder="20"
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer number background color"
          setting_name="[options][timer-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["timer-background-color"]
              ? variant.options["timer-background-color"]
              : undefined
          }
          placeholder="#000"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer number border style"
          setting_name="[options][timer-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["timer-border-style"]
              ? variant.options["timer-border-style"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer number border color"
          setting_name="[options][timer-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["timer-border-color"]
              ? variant.options["timer-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={["dotted", "dashed", "solid"].includes(
            variant.options["timer-border-style"]
          )}
          show_reset={true}
        />

        {variant.options["timer-border-style"] &&
          variant.options["timer-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Timer Number Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][timer-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[`timer-border-width-${side}`]
                          ) == false
                            ? variant.options[`timer-border-width-${side}`]
                            : "1"
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Timer Number Corner Radius"
          setting_name="[options][timer-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["timer-radius"]
              ? variant.options["timer-radius"]
              : "cf-slightly-rounded"
          }
        />

        {variant.options["timer-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][timer-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(variant.options[`timer-radius-${side}`]) == false
                        ? variant.options[`timer-radius-${side}`]
                        : "3"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Timer Number Shadow"
          setting_name="[options][timer-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["timer-shadow"]
              ? variant.options["timer-shadow"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["timer-shadow-horizontal-distance"]
              ? variant.options["timer-shadow-horizontal-distance"]
              : undefined
          }
          placeholder="0"
          condition={variant.options["timer-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["timer-shadow-vertical-distance"]
              ? variant.options["timer-shadow-vertical-distance"]
              : undefined
          }
          placeholder="0"
          condition={variant.options["timer-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["timer-shadow-blur-radius"]
              ? variant.options["timer-shadow-blur-radius"]
              : undefined
          }
          placeholder="0"
          condition={variant.options["timer-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["timer-shadow-spread-radius"]
              ? variant.options["timer-shadow-spread-radius"]
              : undefined
          }
          condition={variant.options["timer-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][timer-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["timer-shadow-color"]
              ? variant.options["timer-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={variant.options["timer-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Inset shadow position"
          setting_name="[options][timer-shadow-inset]"
          setting_type="checkbox"
          object_type="variants"
          value={
            variant.options["timer-shadow-inset"]
              ? variant.options["timer-shadow-inset"]
              : "false"
          }
          condition={variant.options["timer-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer unit text font"
          setting_name="[options][timer-unit-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["timer-unit-font"]
              ? variant.options["timer-unit-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-unit-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["timer-unit-font-weight"]
              ? variant.options["timer-unit-font-weight"]
              : "700"
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer unit text color"
          setting_name="[options][timer-unit-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["timer-unit-color"]
              ? variant.options["timer-unit-color"]
              : undefined
          }
          placeholder="#000"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer unit text size"
          setting_name="[options][timer-unit-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["timer-unit-size"]
              ? variant.options["timer-unit-size"]
              : undefined
          }
          placeholder="15"
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Timer unit text size"
          setting_name="[options][timer-unit-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["timer-unit-size-mobile"]
              ? variant.options["timer-unit-size-mobile"]
              : undefined
          }
          placeholder="13"
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Expired message text color"
          setting_name="[options][timer-message-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["timer-message-color"]
              ? variant.options["timer-message-color"]
              : undefined
          }
          placeholder="#000"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Expired text font"
          setting_name="[options][timer-message-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["timer-message-font"]
              ? variant.options["timer-message-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][timer-message-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["timer-message-font-weight"]
              ? variant.options["timer-message-font-weight"]
              : "700"
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Expired message text size"
          setting_name="[options][timer-message-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["timer-message-size"]
              ? variant.options["timer-message-size"]
              : undefined
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          placeholder="15"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Expired message text size"
          setting_name="[options][timer-message-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["timer-message-size-mobile"]
              ? variant.options["timer-message-size-mobile"]
              : undefined
          }
          placeholder="20"
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeTimer;
