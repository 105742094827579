import React, { useState, useEffect } from "react";
import arePropsEqual from "../../utils/arePropsEqual";

const ReportList = (props) => {
  const [list, setList] = useState([]);

  const getReport = async (payload) => {
    console.log("fetch list", payload);
    try {
      const response = await fetch(`${props.path}?` + $.param(payload));
      const json = await response.json();
      console.log("json", json);

      setList(json);

      if (props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    getReport(props.payload);
  }, [props.payload]);

  return (
    <React.Fragment>
      <div className="row hard-center list-stat">
        <div className="col-xs-10 left no-padding-sides cta-insight-column">
          <p>
            <strong>{props.label}</strong>
          </p>
        </div>
        <div className="col-xs-2 left  no-padding-sides cta-insight-column">
          <p>
            <strong>Count</strong>
          </p>
        </div>
      </div>
      {Object.keys(list).length > 0 && (
        <React.Fragment>
          {Object.keys(list).map((value) => {
            return (
              <React.Fragment key={`value-${list[value]}`}>
                <div className="row hard-center list-stat">
                  <div className="col-xs-10 left no-padding-left cta-insight-column">
                    <p className="light">{value}</p>
                  </div>
                  <div className="col-xs-2 left no-padding-left cta-insight-column">
                    <p className="light">{list[value]}</p>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
      {Object.keys(list).length == 0 && (
        <React.Fragment>
          <div className="col-xs-12 vertical-space dark alert-blue">
            <p className="smooth">Nothing to report yet</p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(ReportList, arePropsEqual);
