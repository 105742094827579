var form_site_wide = function () {
  $('#form_site_wide select[name="condition[condition_type]"]').on(
    "change",
    function () {
      $(".website-condition-setting").hide();
      var selected = $(this).val();

      if (selected == "If page is" || selected == "If page is NOT") {
        $('.website-condition-setting[data-type="url"]').show();
      }

      if (
        selected == "If URL contains" ||
        selected == "If URL does NOT contain"
      ) {
        $('.website-condition-setting[data-type="keyword"]').show();
      }

      if (selected == "If device type is") {
        $('.website-condition-setting[data-type="device"]').show();
      }

      if (
        selected == "If visiting from region" ||
        selected == "If NOT visiting from region"
      ) {
        $('.website-condition-setting[data-type="keyword"]').show();
      }

      if (
        selected == "If visiting from country" ||
        selected == "If NOT visiting from country"
      ) {
        $('.website-condition-setting[data-type="country"]').show();
      }
    }
  );
};

export default form_site_wide;
