class ProductsAdapterService {
  constructor(client, source) {
    this.client = client;
    this.source = source
  }

  adaptProduct(product) {
    switch (this.source) {
      case 'ShopifyGraphql':
        return new ShopifyAdapter(product);
      case 'Shopify':
        return new ShopifyAjaxAdapter(product);
      case 'Convertflow':
        return new ConvertflowAdapter(product);
      default:
        break;
    }
  }

  createCart() {
    return new Promise((resolve, reject) => {
      this.client.cartCreate().then((cart) => {
        resolve(cart)
      }).catch(reject)
    })
  }

  addToCart(cartId, products) {
    return new Promise((resolve, reject) => {
      if (window.convertflow?.session?.cart?.Shopify?.adding_to_cart !== true) {
        const productsIntegrationService = new ProductsIntegrationsService("Shopify");

        let cart_session = {
          adding_to_cart: true
        }

        this.client.cartAddProduct(cartId, products).then((cart) => {
          $cf.ajax({
            type: "GET",
            url: `/?cf_action=cf_shopify_cart&cart_id=${cart?.id}`,
            dataType: "json",
            success: function (data) {},
          });

          if (cart?.lines?.length > 0) {
            cart_session = { ...cart_session, ...cart };

            let cartSessionProductIDS = convertflow.session.cart.Shopify.cf_products_quantities || [];
            cart.lines.forEach((line) => {
              cartSessionProductIDS.push(`${line.id.replace('gid://shopify/ProductVariant/', '')}:${line.quantity}`)
            });
            cart_session = { ...cart_session, cf_products_quantities: cartSessionProductIDS };
          }

          cart_session.adding_to_cart = false;
          productsIntegrationService.saveOnCartSession("Shopify", cart_session);

          resolve(cart)
        }).catch(reject)
      } else {
        let interval = setInterval(() => {
          if (window.convertflow?.session?.cart?.Shopify?.adding_to_cart !== true) {
            clearInterval(interval);
            this.addToCart(cartId, products).then(resolve).catch(reject);
          }
        }, 100);
      }
    })
  }

  removeFromCart(cartId, products) {
    return new Promise((resolve, reject) => {
      this.client.cartRemoveProduct(cartId, products).then((cart) => {
        resolve(cart)
      }).catch(reject)
    })
  }

  applyDiscountCode = async (cartId, discountCode) => {
    return new Promise((resolve, reject) => {
      this.client.cartApplyDiscountCode(cartId, discountCode).then((cart) => {
        resolve(cart)
      }).catch(reject)
    })
  }

  getProduct(id) {
    return new Promise((resolve, reject) => {
      this.client.getProduct(id).then((product) => {
        if (product) {
          resolve(this.adaptProduct(product))
        } else {
          reject("Product not found")
        }
      }).catch(reject)
    })
  }

  getProducts(args) {
    return new Promise((resolve, reject) => {
      this.client.getProducts(args).then((products) => {
        products = products.map((product) => {
          return this.adaptProduct(product);
        })

        resolve(products)
      }).catch(reject)
    })
  }

  getCollectionProducts(id, args) {
    return new Promise((resolve, reject) => {
      this.client.getCollectionProducts(id, args).then((products) => {
        products = products.map((product) => {
          return this.adaptProduct(product);
        })

        resolve(products)
      }).catch(reject)
    })
  }

  getProductRecommendations(productId, args) {
    return new Promise((resolve, reject) => {
      this.client.getProductRecommendations(productId, args).then((products) => {
        products = products.map((product) => {
          return this.adaptProduct(product);
        })

        resolve(products)
      }).catch(reject)
    })
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ProductsAdapterService = ProductsAdapterService;
}
