import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsSurveyDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  let custom_field = Object.values(variant.fields).find(
    (field) => field.element_id == element.id && field.toBeDeleted !== true
  );

  let confirmation_button_or_multi_select =
    custom_field &&
    ([true, "true"].includes(custom_field.options["confirmation_button"]) ==
      true ||
      [true, "true"].includes(custom_field.multi_select) == true);

  function previewButtonHover(updated_element, new_value, styleToPreview) {
    let current_button_color = updated_element.options["button-color"];
    let current_border_color = updated_element.options["button-border-color"]
      ? updated_element.options["button-border-color"]
      : updated_element.options["button-color"];
    let current_text_color = updated_element.options["button-text-color"]
      ? updated_element.options["button-text-color"]
      : "#fff";

    let new_button_color =
      styleToPreview == "button–hover-color"
        ? new_value
        : updated_element.options["button–hover-color"]
        ? updated_element.options["button–hover-color"]
        : current_button_color;

    let new_border_color =
      styleToPreview == "button-hover-border-color"
        ? new_value
        : updated_element.options["button-hover-border-color"]
        ? updated_element.options["button-hover-border-color"]
        : current_border_color;

    let new_text_color =
      styleToPreview == "button–hover-text-color"
        ? new_value
        : updated_element.options["button–hover-text-color"]
        ? updated_element.options["button–hover-text-color"]
        : current_text_color;

    let button_div = document.querySelector(
      '#builder .cf-element[data-element-id="' +
        updated_element.id +
        '"] .cf-button, #builder .cf-element[data-element-id="' +
        updated_element.id +
        '"] .cf-survey-choice'
    );

    button_div.style.setProperty("background", new_button_color, "important");
    button_div.style.setProperty("border-color", new_border_color, "important");
    button_div.style.setProperty("color", new_text_color, "important");

    setTimeout(function () {
      button_div.style.removeProperty("background");
      button_div.style.removeProperty("border-color");
      button_div.style.removeProperty("color");
    }, 1500);
  }

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Options`} open={true}>
        <VariantBuilderSetting
          object={element}
          label="Options Width"
          setting_name="[options][options-width]"
          setting_type="box_width"
          valueMappings={{
            auto: "cf-survey-responsive",
            regular: "cf-survey-regular",
            full: "cf-survey-full",
            custom: "custom",
          }}
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["options-width"]
              ? element.options["options-width"]
              : "cf-survey-regular"
          }
          condition={element.element_type == "survey"}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][options-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Options Width"}
          value={
            element.options["options-width-value"]
              ? element.options["options-width-value"]
              : undefined
          }
          condition={
            element.options["options-width"] == "custom" &&
            ["survey"].includes(element.element_type)
          }
          min={0}
          max={2000}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][options-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Options Width"}
          value={
            element.options["options-width-value-mobile"]
              ? element.options["options-width-value-mobile"]
              : undefined
          }
          condition={
            element.options["options-width"] == "custom" &&
            ["survey"].includes(element.element_type)
          }
          min={0}
          max={2000}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][options-display]"
          setting_type="layout"
          object_type="elements"
          object_id={element.id}
          label={"Options Format"}
          valueMappings={{
            responsive: "inline-block",
            stacked: "table",
          }}
          value={
            element.options["options-display"]
              ? element.options["options-display"]
              : "table"
          }
          condition={
            ["custom", "cf-survey-responsive", "cf-survey-regular"].includes(
              element.options["options-width"]
            ) && ["survey"].includes(element.element_type)
          }
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][options-margin-bottom]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Options Vertical Spacing"}
          value={
            element.options["options-margin-bottom"]
              ? element.options["options-margin-bottom"]
              : undefined
          }
          condition={["survey"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][options-margin-left]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Options Horizontal Spacing"}
          value={
            element.options["options-margin-left"]
              ? element.options["options-margin-left"]
              : undefined
          }
          condition={
            element.options["options-width"] !== "cf-survey-full" &&
            element.options["options-display"] !== "table" &&
            ["survey"].includes(element.element_type)
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Text Alignment"
          setting_name="[options][options-alignment]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["options-alignment"]
              ? element.options["options-alignment"]
              : "center"
          }
          valueMappings={{
            left: "left",
            center: "center",
            right: "right",
          }}
          condition={element.element_type == "survey"}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][visual-type-placement]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          label={"Options Visual Placement"}
          value={
            element.options["visual-type-placement"]
              ? element.options["visual-type-placement"]
              : undefined
          }
          valueMappings={{
            left: "left",
            center: "center",
          }}
          condition={["survey"].includes(element.element_type)}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={section}
          setting_name="[options][visual-type-placement-mobile]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          label={"Options Visual Placement"}
          value={
            element.options["visual-type-placement-mobile"]
              ? element.options["visual-type-placement-mobile"]
              : undefined
          }
          valueMappings={{
            left: "left",
            center: "center",
          }}
          condition={["survey"].includes(element.element_type)}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Option Font"}
          setting_name="[options][field-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font"]
              ? element.options["field-font"]
              : undefined
          }
          placeholder={
            variant.options["field-font"]
              ? variant.options["field-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={["survey"].includes(element.element_type)}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][field-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font-weight"]
              ? element.options["field-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          condition={["survey"].includes(element.element_type)}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Option Font Size"}
          setting_name="[options][field-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font-size"]
              ? element.options["field-font-size"]
              : undefined
          }
          placeholder={
            variant.options["field-font-size"]
              ? variant.options["field-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Option Font size"}
          setting_name="[options][field-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["field-font-size-mobile"]
              ? element.options["field-font-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["field-font-size-mobile"]
              ? variant.options["field-font-size-mobile"]
              : variant.options["field-font-size"]) || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Visual Size"
          setting_name="[options][visual-type-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-size"]
              ? element.options["visual-type-font-size"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-size"]
              ? variant.options["visual-type-font-size"]
              : 16
          }
          condition={["survey"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Visual Size"
          setting_name="[options][visual-type-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-size-mobile"]
              ? element.options["visual-type-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-size-mobile"]
              ? variant.options["visual-type-font-size-mobile"]
              : element.options["visual-type-font-size"]
              ? element.options["visual-type-font-size"]
              : 16
          }
          condition={["survey"].includes(element.element_type)}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Options Visual Font Color"}
          setting_name="[options][visual-type-font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-color"]
              ? element.options["visual-type-font-color"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-color"]
              ? variant.options["visual-type-font-color"]
              : "#fff"
          }
          condition={["survey"].includes(element.element_type)}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Options Visual Font Color"}
          setting_name="[options][visual-type-font-color-mobile]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["visual-type-font-color-mobile"]
              ? element.options["visual-type-font-color-mobile"]
              : undefined
          }
          placeholder={
            variant.options["visual-type-font-color-mobile"]
              ? variant.options["visual-type-font-color-mobile"]
              : element.options["visual-type-font-color"]
          }
          condition={["survey"].includes(element.element_type)}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Image Width"
          setting_name="[options][options-image-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["options-image-width"]
              ? element.options["options-image-width"]
              : undefined
          }
          placeholder={
            element.options["options-image-width"]
              ? element.options["options-image-width"]
              : "200"
          }
          min={0}
          max={750}
          range_step={1}
          condition={
            element.element_type == "survey" &&
            element.options["visual-type-placement"] != "left"
          }
          device="desktop"
          callback={(new_value, old_value) => {
            console.log("new_value", new_value);
            console.log("old_value", old_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Image Width"
          setting_name="[options][options-image-width-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["options-image-width-mobile"]
              ? element.options["options-image-width-mobile"]
              : undefined
          }
          placeholder={
            element.options["options-image-width"]
              ? element.options["options-image-width"]
              : "200"
          }
          min={0}
          max={750}
          range_step={1}
          condition={
            element.element_type == "survey" &&
            element.options["visual-type-placement"] != "left"
          }
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Image Height"
          setting_name="[options][options-image-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["options-image-height"]
              ? element.options["options-image-height"]
              : undefined
          }
          placeholder={
            element.options["options-image-height"]
              ? element.options["options-image-height"]
              : variant.options["options-image-height"]
              ? variant.options["options-image-height"]
              : "Auto"
          }
          min={0}
          max={750}
          allow_empty={true}
          range_step={1}
          condition={
            element.element_type == "survey" &&
            element.options["visual-type-placement"] != "left"
          }
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Options Image Height"
          setting_name="[options][options-image-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["options-image-height-mobile"]
              ? element.options["options-image-height-mobile"]
              : undefined
          }
          placeholder={
            element.options["options-image-height-mobile"]
              ? element.options["options-image-height-mobile"]
              : variant.options["options-image-height-mobile"]
              ? variant.options["options-image-height-mobile"]
              : "Auto"
          }
          min={0}
          max={750}
          allow_empty={true}
          range_step={1}
          condition={
            element.element_type == "survey" &&
            element.options["visual-type-placement"] != "left"
          }
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label={"Option Height"}
          setting_name="[options][button-size]"
          setting_type="box_height"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-size"]
              ? element.options["button-size"]
              : "cf-button-regular"
          }
          valueMappings={{
            small: "cf-button-small",
            regular: "cf-button-regular",
            large: "cf-button-large",
            custom: "custom",
          }}
          condition={["survey"].includes(element.element_type)}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-size-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Option Height"}
          value={
            element.options["button-size-value"]
              ? element.options["button-size-value"]
              : undefined
          }
          condition={
            element.options["button-size"] == "custom" &&
            ["survey"].includes(element.element_type)
          }
          min={0}
          max={200}
          range_step={"1"}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label={"Opt. text color"}
            setting_name="[options][button-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-text-color"]
                ? element.options["button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button-text-color"]
                ? variant.options["button-text-color"]
                : "#fff"
            }
            condition={["survey"].includes(element.element_type)}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button–hover-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button–hover-text-color"]
                ? element.options["button–hover-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button–hover-text-color"]
                ? variant.options["button–hover-text-color"]
                : variant.options["button-text-color"]
                ? variant.options["button-text-color"]
                : element.options["button-text-color"]
                ? element.options["button-text-color"]
                : undefined
            }
            condition={["survey"].includes(element.element_type)}
            callback={(new_value) => {
              previewButtonHover(
                builder.selected().object,
                new_value,
                "button–hover-text-color"
              );
            }}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label={"Option color"}
            setting_name="[options][button-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-color"]
                ? element.options["button-color"]
                : undefined
            }
            placeholder={
              variant.options["button-color"]
                ? variant.options["button-color"]
                : "#0072f7"
            }
            condition={["survey"].includes(element.element_type)}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button–hover-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button–hover-color"]
                ? element.options["button–hover-color"]
                : undefined
            }
            placeholder={
              variant.options["button–hover-color"]
                ? variant.options["button–hover-color"]
                : variant.options["button-color"]
                ? variant.options["button-color"]
                : element.options["button-color"]
            }
            condition={["survey"].includes(element.element_type)}
            callback={(new_value) => {
              previewButtonHover(
                builder.selected().object,
                new_value,
                "button–hover-color"
              );
            }}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label={"Option Border Style"}
          setting_name="[options][button-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-border-style"]
              ? element.options["button-border-style"]
              : undefined
          }
          condition={["survey"].includes(element.element_type)}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Border Color"
            setting_name="[options][button-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-border-color"]
                ? element.options["button-border-color"]
                : undefined
            }
            placeholder={
              variant.options["button-border-color"]
                ? variant.options["button-border-color"]
                : element.options["button-color"]
            }
            condition={
              ["survey"].includes(element.element_type) &&
              element.options["button-border-style"] &&
              element.options["button-border-style"] !== "" &&
              element.options["button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button-hover-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-hover-border-color"]
                ? element.options["button-hover-border-color"]
                : undefined
            }
            placeholder={
              variant.options["button-hover-border-color"]
                ? variant.options["button-hover-border-color"]
                : element.options["button-border-color"]
            }
            condition={
              ["survey"].includes(element.element_type) &&
              element.options["button-border-style"] &&
              element.options["button-border-style"] !== "" &&
              element.options["button-border-style"] !== "none"
            }
            callback={(new_value) => {
              previewButtonHover(
                builder.selected().object,
                new_value,
                "button-hover-border-color"
              );
            }}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {element.options["button-border-style"] &&
          element.options["button-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Button Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`button-border-width-${side}`]
                          ) == false
                            ? element.options[`button-border-width-${side}`]
                            : element.options["button-border-width"]
                            ? element.options["button-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label={"Option Corner Radius"}
          setting_name="[options][button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-radius"]
              ? element.options["button-radius"]
              : undefined
          }
          condition={["survey"].includes(element.element_type)}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`button-radius-${side}`]) == false
                      ? element.options[`button-radius-${side}`]
                      : undefined
                  }
                  condition={
                    ["survey"].includes(element.element_type) &&
                    element.options["button-radius"] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label={"Option Button Shadow"}
          setting_name="[options][button-shadow]"
          setting_type="shadow"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-shadow"]
              ? element.options["button-shadow"]
              : undefined
          }
          condition={["survey"].includes(element.element_type)}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["button-shadow-horizontal-distance"]
              ? element.options["button-shadow-horizontal-distance"]
              : undefined
          }
          placeholder={variant.options["button-shadow-horizontal-distance"]}
          condition={element.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["button-shadow-vertical-distance"]
              ? element.options["button-shadow-vertical-distance"]
              : undefined
          }
          placeholder={variant.options["button-shadow-vertical-distance"]}
          condition={element.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["button-shadow-blur-radius"]
              ? element.options["button-shadow-blur-radius"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["button-shadow-spread-radius"]
              ? element.options["button-shadow-spread-radius"]
              : undefined
          }
          placeholder={variant.options["button-shadow-spread-radius"]}
          condition={element.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Shadow Color"
            setting_name="[options][button-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-shadow-color"]
                ? element.options["button-shadow-color"]
                : undefined
            }
            placeholder={variant.options["button-shadow-color"]}
            condition={element.options["button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button-hover-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-hover-shadow-color"]
                ? element.options["button-hover-shadow-color"]
                : undefined
            }
            placeholder={
              element.options["button-shadow-color"]
                ? element.options["button-shadow-color"]
                : variant.options["button-hover-shadow-color"]
                ? variant.options["button-hover-shadow-color"]
                : variant.options["button-shadow-color"]
            }
            condition={element.options["button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label="Inset shadow position"
          setting_name="[options][button-shadow-inset]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-shadow-inset"]
              ? element.options["button-shadow-inset"]
              : "false"
          }
          condition={element.options["button-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>

      {confirmation_button_or_multi_select ? (
        <React.Fragment>
          <VariantBuilderPanelCollapse name="Next Button">
            <VariantBuilderSetting
              object={element}
              label="Next Button Width"
              setting_name="[options][next-button-width]"
              setting_type="box_width"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-width"]
                  ? element.options["next-button-width"]
                  : "cf-full-width"
              }
              valueMappings={{
                auto: "cf-button-slim",
                regular: "cf-button-mid",
                full: "cf-full-width",
                custom: "custom",
              }}
              show_reset={true}
              device="desktop"
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button Width"
              setting_name="[options][next-button-width-value]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-width-value"]
                  ? element.options["next-button-width-value"]
                  : undefined
              }
              placeholder={variant.options["next-button-width-value"]}
              condition={element.options["next-button-width"] == "custom"}
              min={0}
              max={2000}
              range_step={"1"}
              device="desktop"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button Width"
              setting_name="[options][next-button-width-mobile]"
              setting_type="box_width"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-width-mobile"]
                  ? element.options["next-button-width-mobile"]
                  : undefined
              }
              valueMappings={{
                auto: "cf-button-slim",
                regular: "cf-button-mid",
                full: "cf-full-width",
                custom: "custom",
              }}
              show_reset={true}
              device="mobile"
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button Width"
              setting_name="[options][next-button-width-value-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-width-value-mobile"]
                  ? element.options["next-button-width-value-mobile"]
                  : undefined
              }
              placeholder={
                variant.options["next-button-width-value-mobile"]
                  ? variant.options["next-button-width-value-mobile"]
                  : element.options["next-button-width-mobile"]
              }
              condition={
                element.options["next-button-width-mobile"] == "custom"
              }
              min={0}
              max={2000}
              range_step={"1"}
              device="mobile"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button Padding"
              setting_name="[options][next-button-padding]"
              setting_type="box_height"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-padding"]
                  ? element.options["next-button-padding"]
                  : undefined
              }
              valueMappings={{
                small: "cf-button-small",
                regular: "cf-button-regular",
                large: "cf-button-large",
                custom: "custom",
              }}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][next-button-padding-value]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label="Next Button Padding"
              value={
                element.options["next-button-padding-value"]
                  ? element.options["next-button-padding-value"]
                  : undefined
              }
              placeholder={variant.options["next-button-padding-value"]}
              condition={element.options["next-button-padding"] == "custom"}
              min={0}
              max={200}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button Font"
              setting_name="[options][next-button-font-family]"
              setting_type="select"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-font-family"]
                  ? element.options["next-button-font-family"]
                  : undefined
              }
              option_groups={["System fonts", "Google fonts"]}
              options={[
                { value: "", text: "Default" },
                ...window.builder_fonts_dropdown,
              ]}
              skip_history={true}
              callback={function (new_value, old_value) {
                ctas.builder.fonts.load_font_family(new_value);
              }}
              col="8"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][next-button-font-weight]"
              setting_type="font_weight"
              label="Weight"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-font-weight"]
                  ? element.options["next-button-font-weight"]
                  : undefined
              }
              col="4"
              classNames="no-padding-left"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button font size"
              setting_name="[options][next-button-font-size]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-font-size"]
                  ? element.options["next-button-font-size"]
                  : undefined
              }
              placeholder={
                variant.options["next-button-font-size"]
                  ? variant.options["next-button-font-size"]
                  : 16
              }
              min={0}
              max={100}
              range_step={1}
              device="desktop"
              allow_empty={true}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Next Button Font size"
              setting_name="[options][next-button-font-size-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-font-size-mobile"]
                  ? element.options["next-button-font-size-mobile"]
                  : undefined
              }
              placeholder={
                variant.options["next-button-font-size-mobile"] || 16
              }
              min={0}
              max={100}
              range_step={1}
              device="mobile"
              allow_empty={true}
              show_reset={true}
            />

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label="Next text color"
                setting_name="[options][next-button-text-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button-text-color"]
                    ? element.options["next-button-text-color"]
                    : undefined
                }
                placeholder={
                  variant.options["next-button-text-color"] || "#000"
                }
                col="6"
                classNames="some-padding-right"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][next-button–hover-text-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button–hover-text-color"]
                    ? element.options["next-button–hover-text-color"]
                    : undefined
                }
                placeholder={
                  variant.options["next-button–hover-text-color"] || "#000"
                }
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                offset_left={true}
              />
            </div>

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label="Next But. color"
                setting_name="[options][next-button-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button-color"]
                    ? element.options["next-button-color"]
                    : undefined
                }
                placeholder={variant.options["next-button-color"] || "#fff"}
                col="6"
                classNames="some-padding-right"
                show_reset={true}
                gradient={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][next-button–hover-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button–hover-color"]
                    ? element.options["next-button–hover-color"]
                    : undefined
                }
                placeholder={
                  variant.options["next-button–hover-color"] || "#fff"
                }
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                gradient={true}
                offset_left={true}
              />
            </div>

            <VariantBuilderSetting
              object={element}
              label="Next Button Border Style"
              setting_name="[options][next-button-border-style]"
              setting_type="border_style"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-border-style"]
                  ? element.options["next-button-border-style"]
                  : undefined
              }
              show_reset={true}
            />

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label="Border color"
                setting_name="[options][next-button-border-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button-border-color"]
                    ? element.options["next-button-border-color"]
                    : undefined
                }
                placeholder={variant.options["next-button-border-color"]}
                condition={
                  element.options["next-button-border-style"] &&
                  element.options["next-button-border-style"] !== "" &&
                  element.options["next-button-border-style"] !== "none"
                }
                col="6"
                classNames="some-padding-right"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][next-button-hover-border-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button-hover-border-color"]
                    ? element.options["next-button-hover-border-color"]
                    : undefined
                }
                placeholder={
                  variant.options["next-button-hover-border-color"]
                    ? variant.options["next-button-hover-border-color"]
                    : element.options["next-button-border-color"]
                }
                condition={
                  element.options["next-button-border-style"] &&
                  element.options["next-button-border-style"] !== "" &&
                  element.options["next-button-border-style"] !== "none"
                }
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                offset_left={true}
              />
            </div>

            {element.options["next-button-border-style"] &&
              element.options["next-button-border-style"] !== "none" && (
                <div
                  className="col-xs-12 some-vertical-space setting"
                  style={{ marginBottom: "5px" }}
                >
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Next Button Border Width
                      </div>

                      {["left", "top", "bottom", "right"].map((side) => {
                        const settingName = `[options][next-button-border-width-${side}]`;
                        return (
                          <VariantBuilderSetting
                            key={settingName}
                            object={element}
                            setting_name={settingName}
                            setting_type="number"
                            object_type="elements"
                            object_id={element.id}
                            value={
                              _.isNil(
                                element.options[
                                  `next-button-border-width-${side}`
                                ]
                              ) == false
                                ? element.options[
                                    `next-button-border-width-${side}`
                                  ]
                                : element.options["next-button-border-width"]
                                ? element.options[
                                    "next-button-border-width"
                                  ].replace("px", "")
                                : undefined
                            }
                            placeholder="0px"
                            col="3"
                            note={window.capitalizeFirstLetter(side)}
                            classNames="spacing-inputs"
                            min={0}
                            allow_empty={true}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

            <VariantBuilderSetting
              object={element}
              label="Next Button Corner Radius"
              setting_name="[options][next-button-radius]"
              setting_type="border_radius"
              valueMappings={{
                none: "cf-not-rounded",
                some: "cf-slightly-rounded",
                full: "cf-fully-rounded",
                custom: "custom",
              }}
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-radius"]
                  ? element.options["next-button-radius"]
                  : undefined
              }
              show_reset={true}
            />

            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][next-button-radius-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[`next-button-radius-${side}`]
                        ) == false
                          ? element.options[`next-button-radius-${side}`]
                          : undefined
                      }
                      condition={
                        element.options["next-button-radius"] == "custom"
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder="0px"
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>

            <VariantBuilderSetting
              object={element}
              label="Next Button Shadow"
              setting_name="[options][next-button-shadow]"
              setting_type="shadow"
              show_reset={true}
              object_type="elements"
              object_id={element.id}
              value={
                element.options["next-button-shadow"]
                  ? element.options["next-button-shadow"]
                  : undefined
              }
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][next-button-shadow-horizontal-distance]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Next Shadow Horizontal Distance"}
              value={
                element.options["next-button-shadow-horizontal-distance"]
                  ? element.options["next-button-shadow-horizontal-distance"]
                  : undefined
              }
              placeholder={
                variant.options["next-button-shadow-horizontal-distance"]
              }
              condition={element.options["next-button-shadow"] == "custom"}
              min={-100}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][next-button-shadow-vertical-distance]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Next Shadow Vertical Distance"}
              value={
                element.options["next-button-shadow-vertical-distance"]
                  ? element.options["next-button-shadow-vertical-distance"]
                  : undefined
              }
              placeholder={
                variant.options["next-button-shadow-vertical-distance"]
              }
              condition={element.options["next-button-shadow"] == "custom"}
              min={-100}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][next-button-shadow-blur-radius]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Next Shadow Blur Radius"}
              value={
                element.options["next-button-shadow-blur-radius"]
                  ? element.options["next-button-shadow-blur-radius"]
                  : undefined
              }
              placeholder={variant.options["next-button-shadow-blur-radius"]}
              condition={element.options["next-button-shadow"] == "custom"}
              min={0}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][next-button-shadow-spread-radius]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Next Shadow Blur Radius"}
              value={
                element.options["next-button-shadow-spread-radius"]
                  ? element.options["next-button-shadow-spread-radius"]
                  : undefined
              }
              placeholder={variant.options["next-button-shadow-spread-radius"]}
              condition={element.options["next-button-shadow"] == "custom"}
              min={0}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label="Shadow Color"
                setting_name="[options][next-button-shadow-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button-shadow-color"]
                    ? element.options["next-button-shadow-color"]
                    : undefined
                }
                placeholder={element.options["next-button-shadow-color"]}
                condition={element.options["next-button-shadow"] == "custom"}
                col="6"
                classNames="some-padding-right"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][next-button-hover-shadow-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["next-button-hover-shadow-color"]
                    ? element.options["next-button-hover-shadow-color"]
                    : undefined
                }
                placeholder={
                  variant.options["next-button-hover-shadow-color"]
                    ? variant.options["next-button-hover-shadow-color"]
                    : element.options["next-button-shadow-color"]
                    ? element.options["next-button-shadow-color"]
                    : undefined
                }
                condition={element.options["next-button-shadow"] == "custom"}
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                offset_left={true}
              />
            </div>
          </VariantBuilderPanelCollapse>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ElementSettingsSurveyDesign;
