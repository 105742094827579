var duplicate = function (payload, original) {
  var action = payload;

  $.each(original, function (key, value) {
    if (
      $.inArray(key, ["contactable_type", "contactable_id", "element_id"]) == -1
    ) {
      action[key] = value;
    }
  });

  if (action.data == null) action.data = {};
  action.duplicated = true;

  $.ajax({
    type: "POST",
    url: actions_post,
    data: payload,
    async: true,
    success: function (data) {
      var action = data;
      data.toBeCreated = true;
      cta.actions[data.id] = data;

      if (data.contactable_type == "Element") {
        var element = cta.elements[data.element_id];
        element.actions = element.actions || {};
        element.actions[data.id] = data;
      }
    },
  });
};

export default duplicate;
