var PreviewTour = () => {
  if (window.tour !== undefined) {
    window.tour.hide();
  }
  setCookie("cta_preview_onboarded", "true", 360);
  window.tooltips_ran = true;

  window.tour = new Shepherd.Tour({
    defaults: {
      classes: "shepherd-theme-arrows shepherd-theme-square smooth",
    },
  });
  tour.addStep("editor", {
    title: "Done previewing?",
    text: 'Click "Launch" to continue to the next step!',
    attachTo: "#launch-button left",
    advanceOn: ".docs-link click",
    showCancelLink: true,
    buttons: [
      {
        action() {
          setCookie("cta_preview_onboarded", "true", 360);
          return tour.hide();
        },
        text: "Got it",
      },
    ],
    when: {
      complete: function () {
        setCookie("cta_preview_onboarded", "true", 360);
      },
      hide: function () {
        setCookie("cta_preview_onboarded", "true", 360);
      },
    },
  });
  tour.start();
  $("#launch-button").click(function () {
    tour.hide();
  });
  $(".shepherd-cancel-link, #launch-button").click(function () {
    setCookie("cta_preview_onboarded", "true", 360);
  });
};

export default PreviewTour;
