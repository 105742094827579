import React, { useContext, useEffect, useState } from "react";
import { VariantBuilderContext } from "../variants/VariantBuilderContext";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderPanel from "../variants/VariantBuilderPanel";
import VariantBuilderToolbar from "../variants/VariantBuilderToolbar";
import VariantBuilderCanvas from "./VariantBuilderCanvas";
import VariantBuilderPreview from "./VariantBuilderPreview";

const VariantBuilderInner = (props) => {
  const showPreview = window.location.search.includes("?preview=true");
  const [contextData] = useContext(VariantContextData);
  const [page, setPage] = useState(showPreview ? "preview" : "builder");
  const cta = props.cta;
  const variant = contextData.objects.variant;
  let background;

  if (cta.cta_type == "overlay") {
    background =
      variant.options["container-background"] == "custom"
        ? variant.options["container-background-color"]
        : variant.options["container-background"] == "dark"
        ? "rgba(0, 0, 0, 0.8)"
        : undefined;
  }

  useEffect(() => {
    if (page == "builder") {
      // Clear the preview URL parameter
      const url = new URL(window.location.href);
      url.searchParams.delete("preview");
      window.history.replaceState({}, "", url.toString());
    } else if (page == "preview") {
      // Set the preview URL parameter
      const url = new URL(window.location.href);
      url.searchParams.set("preview", "true");
      window.history.replaceState({}, "", url.toString());
    }
  }, [page]);

  return (
    <React.Fragment>
      <div
        className="flex"
        style={{
          background: background,
        }}
      >
        <VariantBuilderCanvas page={page} {...props} />
        <VariantBuilderPanel page={page} {...props} />
        <VariantBuilderPreview page={page} {...props} />
      </div>

      <VariantBuilderToolbar page={page} setPage={setPage} {...props} />
    </React.Fragment>
  );
};

const VariantBuilder = (props) => {
  const website = props.website;

  useEffect(() => {
    const website = props.website;
    ctas.builder.froalaExtend(website);

    const darkMode = getCookie("darkMode")
      ? getCookie("darkMode") == "true"
      : false;
    document.body.setAttribute("data-mode", darkMode ? "dark" : "light");
  }, [website]);

  return (
    <VariantBuilderContext {...props}>
      <VariantBuilderInner {...props} />
    </VariantBuilderContext>
  );
};

export default VariantBuilder;
