import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _, { xor } from "lodash";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingPadding = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  useEffect(() => {
    let padding_buttons = document.querySelectorAll(
      '.section-spacing-container[setting_name="' +
        props.setting_name +
        '"] .spacing-select'
    );

    padding_buttons.forEach((padding_button) => {
      padding_button.onclick = function () {
        let side = padding_button.getAttribute("data-side");
        let action = padding_button.getAttribute("data-value");
        let setting_name = props.setting_name + side + "]";
        let side_class = (props.device == "mobile" ? "mobile-" : "") + side;

        let current = _.get(
          props.object,
          setting_name,
          "cf-" + side_class + "-padding_0"
        );

        let base_class =
          action == "add"
            ? "cf-" + side_class + "-padding_5"
            : "cf-" + side_class + "-padding_0";
        let padding_level =
          action == "add"
            ? parseInt(current.split("_")[1]) + 1
            : parseInt(current.split("_")[1]) - 1;

        if (current !== base_class) {
          let new_value = "cf-" + side_class + "-padding_" + padding_level;
          builder.update([
            {
              object_type: props.object_type,
              object_id: props.object_id,
              setting_name: setting_name,
              value: new_value,
              skip_history: props.skip_history,
            },
          ]);
        }
      };
    });
  });
  return (
    <React.Fragment>
      <div
        className="row section-spacing-container"
        setting_name={props.setting_name}
        style={{
          display: "block",
        }}
      >
        <div className="row hard-center section-spacing-outer">
          <div
            data-side="top"
            data-value="add"
            className="spacing-select section-spacing-top-add"
          >
            <div className="section-spacing-icon">+</div>
          </div>
          <div
            data-side="top"
            data-value="subtract"
            className="spacing-select section-spacing-top-minus"
          >
            <div className="section-spacing-icon">-</div>
          </div>

          <div
            data-side="left"
            data-value="add"
            className="spacing-select section-spacing-left-add"
          >
            <div className="section-spacing-icon">+</div>
          </div>
          <div
            data-side="left"
            data-value="subtract"
            className="spacing-select section-spacing-left-minus"
          >
            <div className="section-spacing-icon">-</div>
          </div>

          <div
            data-side="right"
            data-value="add"
            className="spacing-select section-spacing-right-add"
          >
            <div className="section-spacing-icon">+</div>
          </div>
          <div
            data-side="right"
            data-value="subtract"
            className="spacing-select section-spacing-right-minus"
          >
            <div className="section-spacing-icon">-</div>
          </div>

          <div
            data-side="bottom"
            data-value="add"
            className="spacing-select section-spacing-bottom-add"
            style={{
              bottom: "29px",
            }}
          >
            <div className="section-spacing-icon">+</div>
          </div>
          <div
            data-side="bottom"
            data-value="subtract"
            className="spacing-select section-spacing-bottom-minus"
            style={{
              bottom: "62px",
            }}
          >
            <div className="section-spacing-icon">-</div>
          </div>

          <div className="row hard-center section-spacing-inner">
            <div className="row hard-center section-spacing-box"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingPadding;
