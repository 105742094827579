import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FieldTemplateItem = (props) => {
  const fieldTemplate = props.item;

  return (
    <React.Fragment>
      <div
        data-template-id={fieldTemplate.id}
        className="cf-draggable-field-template center"
      >
        <div
          className="w-full relative h-[152px] p-4 mt-7 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-slate-700 text-black dark:text-slate-300 dark:hover:text-white rounded-lg cursor-pointer"
          onClick={() => props.selectTemplate(fieldTemplate.id)}
        >
          <div
            className="w-full pt-6 h-[110px] bg-white dark:bg-slate-950 text-blue-600 dark:text-white rounded-lg text-center text-4xl whitespace-no-wrap"
            style={{
              backgroundImage: `url(${
                fieldTemplate.screenshot ? fieldTemplate.screenshot : ""
              })`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="w-full mt-3 mb-3 text-lg  whitespace-nowrap text-ellipsis overflow-hidden">
            {fieldTemplate.name}
          </div>
          <div className="thumbnail-overlay absolute h-[125px] leading-110px inset-0 bg-transparent hover:bg-white dark:hover:bg-slate-800 hover:bg-opacity-50 dark:hover:bg-opacity-50 cursor-pointer rounded-lg">
            <button
              style={{ display: "none" }}
              className="thumbnail-button mt-[50px] hard-center border-[1px] border-solid border-black dark:border-white py-2 px-5 rounded-lg text-lg cursor-pointer semibold bg-black dark:bg-slate-800 dark:bg-opacity-80 text-white"
            >
              + Create
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldTemplateItem;
