import React from "react";
import useContentLists from "./useContentLists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewContentListItem = (props) => {
  const { ContentLists } = useContentLists();

  const createListItem = () => {
    ContentLists.create(props.element, (list_item) => {
      dispatchCustomEvent("focusItem", {
        parent_id: props.element.id,
        item_type: "content_list_items",
        item_id: list_item.id,
      });
    });
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 my-5">
        <button
          onClick={createListItem}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add Item
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewContentListItem;
