import React from "react";
import PropTypes from "prop-types";
import FieldDropdown from "../fields/FieldDropdown";
import FieldParagraph from "../fields/FieldParagraph";
import FieldRadio from "../fields/FieldRadio";
import FieldSurvey from "../fields/FieldSurvey";
import FieldText from "../fields/FieldText";
import FieldRange from "../fields/FieldRange";

const Field = (props) => {
  let field = props.field;
  let element = props.element;
  let options = element.options;
  return (
    <React.Fragment>
      {field.field_type == "text" && <FieldText {...props} />}
      {field.field_type == "dropdown" && <FieldDropdown {...props} />}
      {(field.field_type == "select" ||
        field.field_type == "radio" ||
        field.field_type == "checkbox") && <FieldRadio {...props} />}
      {field.field_type == "paragraph" && <FieldParagraph {...props} />}
      {field.field_type == "survey" && <FieldSurvey {...props} />}
      {field.field_type == "hidden" && <FieldText {...props} />}
      {field.field_type == "number" && <FieldText {...props} />}
      {field.field_type == "range" && <FieldRange {...props} />}
    </React.Fragment>
  );
};

export default Field;
