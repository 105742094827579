var checklist = {
  close: function () {
    $("#onboard-popup").addClass("collapsed");
    $("#onboard").show();
  },
  initialize: function () {
    $("#onboard").on("click", function (e) {
      if (
        e.target !== $(".checklist-steps")[0] &&
        $(e.target).parents(".checklist-steps").length == 0
      ) {
        $(this).hide();
        $("#onboard-popup").show().toggleClass("collapsed");
      }
    });

    $("#onboard-popup-close")
      .unbind("click")
      .on("click", function () {
        checklist.close();
        setCookie("onboard_closed", "true", 360);
      });

    $("#onboard, .checklist-steps, .checklist-steps span")
      .unbind("mouseover")
      .on("mouseover", function () {
        $(".checklist-steps").show();
        $(".checklist-steps span")
          .addClass("glyphicon glyphicon-remove")
          .attr("aria-hidden", "true")
          .text("");
      });

    $("#onboard, .checklist-steps")
      .unbind("mouseleave")
      .on("mouseleave", function () {
        $(".checklist-steps").hide();
        onboard.checklist.steps();
      });

    if ($("body").attr("data-account-id")) {
      $("#onboard_refresh")
        .unbind("click")
        .on("click", function () {
          $(this).addClass("spinning");
          $.ajax({
            url:
              window.location.origin +
              "/accounts/" +
              $("body").attr("data-account-id") +
              "/usage",
          }).done(function () {
            $(this).removeClass("spinning");
            location.reload();
          });
        });
    }

    $('a[data-toggle="onboard"]').on("click", function () {
      $("#onboard-popup").removeClass("collapsed");
      var step = $(this).attr("data-target");
      var stepDiv = $(`.checklist-step[data-step='${step}']`);
      var tab = $(stepDiv).find(".tutorial-tab-header");
      var content = $(stepDiv).find(".tutorial-tab-content");

      if ($(content).is(":visible") == false) {
        $(tab).trigger("click");
      }
    });

    onboard.checklist.steps();

    var cta_type = $('.onboard-checklist-box[data-step="launch"]').attr(
      "data-cta-type"
    );

    window._wq = window._wq || [];
    _wq.push({
      id: "_all",
      onReady: function (video) {
        // if CTA type video, check to do list item
        if (
          $.inArray(video._hashedId, [
            "7g6n14u3ob",
            "xxtqhj3eps",
            "0hatosofmb",
            "0sbcb0fv1v",
            "1gfhcwy8j6",
          ]) > -1
        ) {
          video.bind("play", function () {
            if (
              cta_type &&
              getCookie("cta_builder_" + cta_type + "_video") !== "true"
            ) {
              setCookie("cta_builder_" + cta_type + "_video", "true", 360);
              $(
                '.checklist-step[data-step="' +
                  cta_type +
                  '"] .checklist-box-outer'
              ).trigger("click");
            }

            if (window.tour && (!window.template || !template.tutorial)) {
              tour.hide();
            }
          });
        } else if (video._hashedId == "9nzgguvnc9") {
          video.bind("play", function () {
            setCookie("welcome_video", "true", 360);
            $(
              '.onboard-checklist-box[data-step="welcome"] .checklist-box-outer'
            ).addClass("active");
          });
        } else if (video._hashedId == "k45c55l2df") {
          video.bind("play", function () {
            setCookie("cta_builder_onboarded", "true", 360);
          });
        } else if (window.template && template.tutorial) {
          video.bind("play", function () {
            tour.hide();

            if (
              window.cta &&
              getCookie("cta_builder_template_" + cta.template) !== "true"
            ) {
              setCookie("cta_builder_template_" + cta.template, "true", 360);
              $(
                '.checklist-step[data-step="template"] .checklist-box-outer'
              ).trigger("click");
            }
          });
        }
      },
    });

    const showWelcome =
      $("body").attr("data-controller") == "cta" &&
      $("body").attr("data-action") == "new" &&
      getCookie("welcome_video") !== "true" &&
      getCookie("onboard_closed") !== "true" &&
      (!window.account ||
        !account.usage ||
        !account.usage.onboard ||
        account.usage.onboard["launch"] !== true) &&
      $('.checklist-step[data-step="101"] .tutorial-tab-content').is(
        ":visible"
      ) == false;

    const panelHasPlaybook =
      $(".checklist-step[data-step='playbook']").length > 0;

      const panelHasPlaybookVideo =
        $(".checklist-step[data-step='playbook'] .tutorial-tab-video").length >
        0;

      const panelHasTemplateTutorial =
        $(".checklist-step[data-step='template']").length > 0;

      const needsBuilderOnboarding =
        $("body").attr("data-controller") == "variants" &&
        $("body").attr("data-action") == "edit" &&
        $("#onboard-popup").attr("data-launched") !== "true" &&
        getCookie("cta_builder_onboarded") !== "true";

      if (
        window.variantPublished !== true &&
        (showWelcome || needsBuilderOnboarding)
      ) {
        console.log("showWelcome", showWelcome);
        console.log("needsBuilderOnboarding", needsBuilderOnboarding);

        if (showWelcome) {
          $("#onboard").trigger("click");
          $('.checklist-step[data-step="101"] .tutorial-tab-header').trigger(
            "click"
          );
        } else if (needsBuilderOnboarding && !panelHasPlaybookVideo) {
          $("#onboard").trigger("click");
          $('.checklist-step[data-step="101"] .tutorial-tab-header').trigger(
            "click"
          );

          let element = $('.onboard-checklist-box[data-step="launch"]');

          if (element) {
            $(".campaign-playbook-panel").animate(
              { scrollTop: element.offset().top },
              500
            );
          }
        } else if (needsBuilderOnboarding && panelHasPlaybook) {
          $("#onboard").trigger("click");
          $(
            '.onboard-checklist-box[data-step="playbook"] .tutorial-tab-header'
          ).trigger("click");
        } else if (panelHasTemplateTutorial) {
          $("#onboard").trigger("click");
          $(
            '.onboard-checklist-box[data-step="template"] .tutorial-tab-header'
          ).trigger("click");
        }
      }
  },

  steps: function (step, active) {
    // builder tooltips

    var steps = 5;
    var completed = 0;
    var cta_type = $('.onboard-checklist-box[data-step="launch"]').attr(
      "data-cta-type"
    );

    if (window.account && account.usage && account.usage.onboard) {
      if ($.inArray(account.current_user, ["owner", "admin"]) == -1) {
        $('.onboard-checklist-box[data-step="welcome"] .checklist-title').text(
          "Welcome to ConvertFlow! 🎉"
        );
        $('.onboard-checklist-box[data-step="launch"]').css(
          "padding-bottom",
          "20px"
        );
        $('.onboard-checklist-box[data-step="install"]')
          .css("border-left", "3px solid #fff")
          .css("padding-top", "0");
        $('.onboard-checklist-box[data-step="team"]').hide();
        $('.onboard-checklist-box[data-step="customer"]').hide();
        var steps = 3;
      }

      if (getCookie("welcome_video") == "true") {
        var completed = completed + 1;
        $(
          '.onboard-checklist-box[data-step="welcome"] .checklist-box-outer'
        ).addClass("active");
      }

      if (account.usage.onboard["launch"] == true) {
        var completed = completed + 1;
        $(
          '.onboard-checklist-box[data-step="launch"] .checklist-box-outer'
        ).addClass("active");
      }

      if (
        (account.usage.onboard["script"] == true ||
          $('.onboard-checklist-box[data-step="install"]').attr(
            "data-installed"
          ) == "true") &&
        account.usage.onboard["integration"] == true
      ) {
        var completed = completed + 1;
        $(
          '.onboard-checklist-box[data-step="install"] .checklist-box-outer'
        ).addClass("active");
      }

      if (
        account.usage.onboard["team"] == true &&
        $.inArray(account.current_user, ["owner", "admin"]) > -1
      ) {
        var completed = completed + 1;
        $(
          '.onboard-checklist-box[data-step="team"] .checklist-box-outer'
        ).addClass("active");
      }

      if (
        account.usage.onboard["customer"] == true &&
        $.inArray(account.current_user, ["owner", "admin"]) > -1
      ) {
        var completed = completed + 1;
        $(
          '.onboard-checklist-box[data-step="customer"] .checklist-box-outer'
        ).addClass("active");
      }

      if (account.usage.onboard["shirt"] == true) {
        $('.onboard-checklist-box[data-step="customer"] .shirt-button')
          .removeAttr("href")
          .removeAttr("target");
        $('.onboard-checklist-box[data-step="customer"] .shirt-button button')
          .addClass("disabled")
          .text("T-Shirt Requested");
      }
    }

    var remaining = steps - completed;
    var width = 100 - (remaining * 100) / steps;
    $(".checklist-steps span")
      .removeClass("glyphicon glyphicon-remove")
      .removeAttr("aria-hidden")
      .text(remaining);
    $(".checklist-overlay").css("width", width + "%");
    $(".checklist-overlay, .checklist-steps").show();
    $("#onboard").css("background", "#0042b3");

    if (width == 100) {
      $(".checklist-overlay, .checklist-steps").hide();
      $(".checklist-steps span")
        .addClass("glyphicon glyphicon-remove")
        .attr("aria-hidden", "true")
        .text("");
    }
  },
};

export default checklist;
