const NewStepDraggable = {
  start: (contextData, Steps) => {
    let forceFallback = environment == "test" ? false : true;

    let createFromScratchContainer = document.querySelector(
      "#steps-create-from-scratch"
    );
    if (createFromScratchContainer) {
      let scratchSortable = new Sortable(createFromScratchContainer, {
        sort: false,
        group: { name: "steps-position", pull: "clone", put: false },
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewStepDraggable.handleDragging(event);
        },
        onEnd: function (event) {
          NewStepDraggable.handleCreation(event, contextData, Steps);
        },
      });

      window.sortables.indexOf(scratchSortable) === -1
        ? window.sortables.push(scratchSortable)
        : "";
    }

    let templatesContainer = document.querySelectorAll(
      ".new-step-templates"
    );

    templatesContainer.forEach((templatesContainer) => {
      let templatesSortable = new Sortable(templatesContainer, {
        sort: false,
        group: { name: "steps-position", pull: "clone", put: false },
        draggable: ".cf-draggable-step-template",
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewStepDraggable.handleDragging(event);
        },
        onEnd: function (event) {
          NewStepDraggable.handleCreation(event, contextData, Steps);
        },
      });

      window.sortables.indexOf(templatesSortable) === -1
        ? window.sortables.push(templatesSortable)
        : "";
    });
  },

  handleDragging: (event) => {
    var stepContainer = $(".steps-container").filter(function () {
      return !$(this).find(".step").length;
    });

    $(stepContainer).prepend(
      `<p class='cf-draggable-creation-hover' data-object-type="Steps">Drag here to create</p>`
    );
    $(stepContainer).find(`.cf-draggable-creation-hover[data-object-type="Steps"]`).show();
  },

  handleCreation: (event, contextData, Steps) => {
    $(`.cf-draggable-creation-hover[data-object-type="Steps"]`).remove();

    if (event.from !== event.to) event.item.remove();
    if (event.from == event.to) return;

    const position = $('.step').length == 0 ? 1 : event.newIndex + 1;
    const template_id = parseInt(event.clone.dataset.templateId) || 0;
    const positionUpdates = NewStepDraggable.draggableContainerItemsReorderUpdates(
      event.to,
      position
    );

    Steps.create(template_id, position, positionUpdates, true);
  },

  draggableContainerItemsReorderUpdates: (
    container,
    new_dragged_index
  ) => {
    let updates = [];
    let newPositionIndexStart;
    let draggedIndex = parseInt(new_dragged_index);

    Array.from(container.children)
      .filter((step_div) => step_div.classList.contains("step"))
      .forEach((step_div, i) => {
        let step_id = parseInt(step_div.getAttribute("data-step-id"));
        let old_position = parseInt(step_div.getAttribute("data-position"));
        let new_position = i + 1;

        if (new_position < draggedIndex) return;
        if (new_position == draggedIndex) {
          newPositionIndexStart = new_position + 1;
          new_position = newPositionIndexStart;
        } else if (new_position > draggedIndex) {
          new_position = newPositionIndexStart + 1;
          newPositionIndexStart += 1;
        }

        updates.push({
          object_type: "steps",
          object_id: step_id,
          setting_name: "[position]",
          value: new_position,
          old_value: old_position,
        });
      });

      console.log('updates', updates)
    return updates;
  },
};

export default NewStepDraggable;
