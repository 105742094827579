import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import ActionGroupSettings from "../action_groups/ActionGroupSettings";
import NewActionGroup from "../action_groups/NewActionGroup";
import TabGroup from "../shared/TabGroup";
import Tooltip from "../shared/Tooltip";
import ElementSettingsLogic from "../elements/ElementSettingsLogic";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";

const StepSettings = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const [tab, setTab] = useState("Logic");

  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let step = builder.selected().object;
  let action_groups = Object.values(variant.action_groups).filter(
    (action_group) =>
      action_group.step_id == step.id && action_group.toBeDeleted !== true
  );

  const sections = Object.values(variant.sections).filter((section) => {
    return !section.toBeDeleted && section.step_id == step.id;
  });

  const elements = Object.values(variant.elements)
    .filter((element) => {
      return (
        ["button", "form", "quiz", "survey", "products"].includes(element.element_type) &&
        !element.toBeDeleted &&
        sections.filter((section) => section.id == element.section_id).length >
          0
      );
    })
    .sort((a, b) => {
      return a.position - b.position;
    });

  return (
    <React.Fragment>
      <div className="row hard-center">
        <VariantBuilderPanelTitle
          title={`Funnel Step ${step.position}`}
          object_type="steps"
          object_id={step.id}
          object={step}
          tooltip="Funnel steps are shown one step at a time. Use conversion elements progress visitors through your funnel."
          tooltipLabel="Learn"
          parent_object={{
            label: 'Back To Funnel',
            callback: () => {
              builder.view('funnel')
            }
          }}
        />

        <TabGroup
          tabs={["Logic", "Settings"]}
          tab={tab}
          setActiveTab={(tab) => {
            setTab(tab);
          }}
          className="mb-6"
        >
          {tab == "Logic" && (
            <React.Fragment>
              <div className="row">
                <VariantBuilderSetting
                  object={step}
                  setting_name="[options][name]"
                  setting_type="text"
                  object_type="steps"
                  object_id={step.id}
                  label="Funnel step name"
                  value={step.options["name"] ? step.options["name"] : ""}
                  placeholder="Name this step!"
                  update_on_input_end={true}
                />
              </div>

              <VariantBuilderPanelCollapse
                name="When Funnel Step Is Reached:"
                open={true}
              >
                <div className="row px-6">
                  <VariantBuilderSetting
                    object={step}
                    setting_name="[options][script]"
                    setting_type="code"
                    object_type="steps"
                    object_id={step.id}
                    label="Fire custom code"
                    value={step.options["script"] ? step.options["script"] : ""}
                    feature_gate_type="Custom Scripts"
                  />

                  <div className="col-xs-12 my-5">
                    <div className="w-full mb-3 relative text-black dark:text-white">
                      <span className="text-xl">Skip To Funnel Steps:</span>

                      <Tooltip tooltip={`asdf`} />
                    </div>

                    {action_groups.map((action_group) => {
                      return (
                        <ActionGroupSettings
                          key={"action-group-" + action_group.id}
                          step={step}
                          action_group={action_group}
                        />
                      );
                    })}

                    <NewActionGroup step={step} />
                  </div>
                </div>
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse
                name="Conversion Element Actions:"
                open={true}
              >
                <div className="row hard-center px-6">
                  {elements.map((element) => {
                    return (
                      <ElementSettingsLogic
                        key={`element-${element.id}-logic`}
                        element={element}
                      />
                    );
                  })}
                  {elements.length == 0 && (
                    <div className="row hard-center p-5 text-black dark:text-white text-xl border-solid border-[1px] border-black dark:border-slate-800 mb-5">
                      No conversion elements added yet!
                    </div>
                  )}
                </div>
              </VariantBuilderPanelCollapse>
            </React.Fragment>
          )}

          {tab == "Settings" && (
            <React.Fragment>
              <div className="row">
                <VariantBuilderSetting
                  object={step}
                  setting_name="[options][progress-bar-count]"
                  setting_type="checkbox"
                  object_type="steps"
                  object_id={step.id}
                  label="Counts towards progress bars"
                  value={
                    step.options["progress-bar-count"]
                      ? step.options["progress-bar-count"]
                      : true
                  }
                  tooltip="Toggle this off to exclude this step from dynamic progress bar calculations."
                  callback={(new_value, old_value) => {
                    let updates = [];

                    Object.values(variant.elements)
                      .filter((element) => element.element_type == "progress")
                      .forEach((element) => {
                        updates.push({
                          object_type: "elements",
                          object_id: element.id,
                          setting_name: "[lastUpdated]",
                          value: Date.now(),
                        });
                      });

                    builder.update(updates);
                  }}
                  col={12}
                />
              </div>

              <VariantBuilderPanelCollapse name={"Custom Code"} open={false}>
                <VariantBuilderSetting
                  object={step}
                  setting_name="[options][custom-class]"
                  label="Custom Classes"
                  placeholder="Custom class names here"
                  setting_type="text"
                  object_type="steps"
                  object_id={step.id}
                  value={step.options["custom-class"]}
                  alphanumeric={true}
                  update_on_input_end={true}
                />

                <VariantBuilderSetting
                  object={step}
                  setting_type="message"
                  label="HTML/Anchor ID"
                  value={"#step_" + step.id}
                />

                <VariantBuilderSetting
                  object={step}
                  setting_type="message"
                  label="CSS Selector"
                  value={"#step_" + step.id + ".cf-step"}
                  tooltip="Using custom CSS? Make sure to add !important to the end of each of your CSS properties."
                />
              </VariantBuilderPanelCollapse>
            </React.Fragment>
          )}
        </TabGroup>
      </div>
    </React.Fragment>
  );
};

export default StepSettings;
