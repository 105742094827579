import React, { useState, useContext, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSteps from "./useSteps.js";
import StepTemplateItem from "./StepTemplateItem";
import NewStepDraggable from "./NewStepDraggable.js";

const NewStep = (props) => {
  const [contextData] = useContext(VariantContextData);
  const [searchText, setSearchText] = useState("");
  const { Steps } = useSteps();

  let templates = props.step_templates;

  const searchedTemplates = templates.filter(function (template) {
    return template.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const createStep = function (template_id = 0) {
    if (template_id > 0) {
      Steps.create(template_id);
    } else {
      Steps.create();
    }
  };

  useEffect(() => {
    NewStepDraggable.start(contextData, Steps)
  }, []);

  return (
    <React.Fragment>
      <div id="new-step" className="row hard-center my-4">
        <div
          id="new-step-templates"
          className="w-full new-step-templates"
        >
          <div className="w-full text-xl font-semibold text-black dark:text-white">
            Funnel Step Templates
          </div>

          <div className="w-full mt-4 relative">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400">
              <i className="fas fa-search text-2xl"></i>
            </span>
            <input
              type="text"
              name="name"
              className="w-full px-2 py-4 pl-8 text-xl rounded-lg text-slate-500 dark:focus:color-white bg-white dark:bg-slate-950 border-none focus:border-none focus:outline-none"
              placeholder="Search..."
              defaultValue={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div id="steps-create-from-scratch" className="w-full">
            <div
              className="cursor-pointer w-full h-[50px] mt-4 mb-9 text-center leading-[50px] text-lg rounded-lg text-slate-500 hover:text-blue-600 dark:hover:text-white bg-white hover:bg-white dark:bg-slate-950 dark:hover:bg-slate-700 border-dashed border-[1px] border-slate-300 hover:border-blue-600 dark:border-slate-500 dark:hover:border-white"
              onClick={createStep}
            >
              Create From Scratch
            </div>
          </div>

          {searchedTemplates.map((item) => (
            <StepTemplateItem
              key={item.id}
              item={item}
              selectTemplate={(e) => createStep(e)}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewStep;
