import React, { useEffect, useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSettingAdvanced from "../variants/VariantBuilderSettingAdvanced";
import VariantBuilderSettingDropdown from "../variants/VariantBuilderSettingDropdown";
import VariantBuilderSettingCheckbox from "../variants/VariantBuilderSettingCheckbox";
import VariantBuilderSettingNumber from "../variants/VariantBuilderSettingNumber";
import VariantBuilderSettingRange from "../variants/VariantBuilderSettingRange";
import VariantBuilderSettingEditor from "../variants/VariantBuilderSettingEditor";
import VariantBuilderSettingColor from "../variants/VariantBuilderSettingColor";
import VariantBuilderSettingColorV2 from "../variants/VariantBuilderSettingColorV2";
import VariantBuilderSettingText from "../variants/VariantBuilderSettingText";
import VariantBuilderSettingCode from "../variants/VariantBuilderSettingCode";
import VariantBuilderSettingModal from "../variants/VariantBuilderSettingModal";
import VariantBuilderSettingModalTrigger from "../variants/VariantBuilderSettingModalTrigger";
import VariantBuilderSettingSlideOut from "../variants/VariantBuilderSettingSlideOut";
import VariantBuilderSettingMessage from "../variants/VariantBuilderSettingMessage";
import VariantBuilderSettingImage from "../variants/VariantBuilderSettingImage";
import VariantBuilderSettingColumn from "../variants/VariantBuilderSettingColumn";
import VariantBuilderSettingPadding from "../variants/VariantBuilderSettingPadding";
import VariantBuilderSettingSpacing from "../variants/VariantBuilderSettingSpacing";
import VariantBuilderSettingSelect from "../variants/VariantBuilderSettingSelect";
import VariantBuilderSettingPresetField from "../variants/VariantBuilderSettingPresetField";
import VariantBuilderSettingHtml from "../variants/VariantBuilderSettingHtml";
import VariantBuilderSettingAlignment from "../variants/VariantBuilderSettingAlignment";
import VariantBuilderSettingDate from "../variants/VariantBuilderSettingDate";
import VariantBuilderSettingItem from "./VariantBuilderSettingItem";
import VariantBuilderSettingItemV2 from "./VariantBuilderSettingItemV2";
import VariantBuilderSettingFontAwesome from "../variants/VariantBuilderSettingFontAwesome";
import VariantBuilderSettingEmoji from "../variants/VariantBuilderSettingEmoji";
import VariantBuilderSettingBorderStyle from "../variants/VariantBuilderSettingBorderStyle";
import VariantBuilderSettingBorderRadius from "../variants/VariantBuilderSettingBorderRadius";
import VariantBuilderSettingBoxHeight from "../variants/VariantBuilderSettingBoxHeight";
import VariantBuilderSettingBoxWidth from "../variants/VariantBuilderSettingBoxWidth";
import VariantBuilderSettingShadow from "../variants/VariantBuilderSettingShadow";
import VariantBuilderSettingFontWeight from "../variants/VariantBuilderSettingFontWeight";
import VariantBuilderSettingLayout from "../variants/VariantBuilderSettingLayout";
import VariantBuilderSettingOptionsImages from "../variants/VariantBuilderSettingOptionsImages";
import VariantBuilderSettingOptionsButtons from "../variants/VariantBuilderSettingOptionsButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../shared/Tooltip";

const VariantBuilderSetting = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [lastReset, setLastReset] = useState(null);
  const settingKey = `${props.object_type}-${props.object_id}-${props.setting_name}`;
  const node = useRef();

  let settingClasses = `my-[10px] ${
    props.col ? `col-xs-${props.col}` : `col-xs-12`
  } setting ${
    props.warning && props.warningBorder !== false ? "setting-warning" : ""
  } ${props.classNames ? ` ${props.classNames}` : ""}`;

  let tooltip_tag;
  if (props.tooltip) {
    tooltip_tag = (
      <Tooltip
        tooltip={props.tooltip}
        inline={props.col !== undefined || props.setting_type == "checkbox"}
      />
    );
  }

  let device_tag;
  if (props.device == "mobile") {
    device_tag = (
      <span
        className="glyphicon glyphicon-phone tooltip-icon"
        data-toggle="tooltip"
        data-placement="top"
        title="Mobile style"
        aria-hidden="true"
        style={{
          color: "#00b593",
          fontSize: "14px",
        }}
      ></span>
    );
  }

  let advancedToggle;
  if (
    ["modal", "item"].includes(props.setting_type) == false &&
    props.children
  ) {
    advancedToggle = (
      <VariantBuilderSettingAdvanced>
        {props.children}
      </VariantBuilderSettingAdvanced>
    );
  }

  const reset = () => {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: null,
      },
    ]);
    setLastReset(Date.now());
  };

  let reset_tag;
  if (props.show_reset && props.value) {
    reset_tag = (
      <div
        className="float-right cursor-pointer mx-1"
        title="Reset To Default"
        onClick={reset}
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.79489 4.00001L2 7.84625M2 7.84625L5.79489 11.6925M2 7.84625L14 7.84625C16.2091 7.84625 18 9.63711 18 11.8463L18 12C18 14.2092 16.2091 16 14 16L10 16"
            stroke={contextData.dark_mode ? `white` : "black"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  useEffect(() => {
    $('[data-toggle="popover"]').popover({ html: true });
    $('[data-toggle="tooltip"]').tooltip();

    if (props.onload) {
      props.onload();
    }
  });

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  return (
    <React.Fragment>
      {props.loading && <div className={settingClasses} style={{ padding: '0px' }}>
        <div className="col-xs-12">
          <div style={{ backgroundColor: '#90A6C4', borderRadius: '5px', maxWidth: '130px', height: '20px' }}></div>
        </div>

        <div className="col-xs-12">
          <div style={{ backgroundColor: '#90A6C4', marginTop: '10px', height: '35px', borderRadius: '5px' }}></div>
        </div>
      </div>}

      {!props.loading && (props.condition == undefined || props.condition == true) &&
        (!props.device || props.device == contextData.device) && (
          <div
            className={settingClasses}
            style={props.style}
            data-position={props.position ? props.position : undefined}
            data-setting_type={props.setting_type}
            data-setting_name={props.setting_name}
            data-object_type={props.object_type}
            data-object_id={props.object_id}
            data-draggable={props.draggable || true}
            {...props.data_attributes}
            ref={node}
          >
            {props.warning && (
              <div className={`row ${props.warningBorder == undefined || props.warningBorder == true ? 'hard-center' : ''} setting-warning-label text-xl text-red-600 dark:text-red-400 mb-3`}>
                <FontAwesomeIcon
                  icon="fas fa-exclamation-triangle"
                  className="mr-5"
                />
                {props.warning}
              </div>
            )}
            {props.label && (
              <div
                className={`mb-[5px] ${
                  props.setting_type == "checkbox" && !props.col
                    ? "col-xs-9 no-padding-sides"
                    : ""
                }`}
              >
                <span className="setting-label text-xl text-black dark:text-white capitalize whitespace-nowrap">
                  {props.label}
                </span>
                {tooltip_tag}
                {device_tag}
                {advancedToggle}
                {reset_tag}
              </div>
            )}
            {props.headline && (
              <h4 className="semibold">
                {props.headline}
                {tooltip_tag}
                {device_tag}
              </h4>
            )}
            {props.setting_type == "text" && (
              <VariantBuilderSettingText key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "icon" && (
              <VariantBuilderSettingFontAwesome key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "emoji" && (
              <VariantBuilderSettingEmoji key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "dropdown" && (
              <VariantBuilderSettingDropdown key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "checkbox" && (
              <React.Fragment>
                {props.col ? (
                  <VariantBuilderSettingCheckbox key={settingKey} last_reset={lastReset} {...props} />
                ) : (
                  <div
                    className={`text-right -mt-[5px] ${
                      props.setting_type == "checkbox" && !props.col
                        ? "col-xs-3 no-padding-sides"
                        : ""
                    }`}
                  >
                    <VariantBuilderSettingCheckbox
                      key={settingKey}
                      {...props}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            {props.setting_type == "number" && (
              <VariantBuilderSettingNumber key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "range" && (
              <VariantBuilderSettingRange key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "editor" && (
              <VariantBuilderSettingEditor key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "color" && (
              <VariantBuilderSettingColor key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "color_v2" && (
              <VariantBuilderSettingColorV2 key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "code" && (
              <VariantBuilderSettingCode key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "modal" && (
              <VariantBuilderSettingModal {...props}>
                {props.children}
              </VariantBuilderSettingModal>
            )}
            {props.setting_type == "slideout" && (
              <VariantBuilderSettingSlideOut {...props}>
                {props.children}
              </VariantBuilderSettingSlideOut>
            )}
            {props.setting_type == "modal_trigger" && (
              <VariantBuilderSettingModalTrigger key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "message" && (
              <VariantBuilderSettingMessage key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "image" && (
              <VariantBuilderSettingImage key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "column" && (
              <VariantBuilderSettingColumn key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "padding" && (
              <VariantBuilderSettingPadding key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "spacing" && (
              <VariantBuilderSettingSpacing key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "select" && (
              <VariantBuilderSettingSelect key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "preset_field" && (
              <VariantBuilderSettingPresetField key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "html" && (
              <VariantBuilderSettingHtml key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "alignment" && (
              <VariantBuilderSettingAlignment key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "options_buttons" && (
              <VariantBuilderSettingOptionsButtons key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "options_images" && (
              <VariantBuilderSettingOptionsImages key={settingKey} {...props} />
            )}
            {props.setting_type == "date" && (
              <VariantBuilderSettingDate key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "item" && (
              <VariantBuilderSettingItem key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "item_v2" && (
              <VariantBuilderSettingItemV2 key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "border_style" && (
              <VariantBuilderSettingBorderStyle key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "border_radius" && (
              <VariantBuilderSettingBorderRadius key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "box_height" && (
              <VariantBuilderSettingBoxHeight key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "box_width" && (
              <VariantBuilderSettingBoxWidth key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "shadow" && (
              <VariantBuilderSettingShadow key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "font_weight" && (
              <VariantBuilderSettingFontWeight key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.setting_type == "layout" && (
              <VariantBuilderSettingLayout key={settingKey} last_reset={lastReset} {...props} />
            )}
            {props.note && (
              <div className="text-sm text-black dark:text-white mt-[5px]" style={{ width: props.note_full_width ? 'max-content' : 'initial' }}>
                {props.note}
              </div>
            )}
          </div>
        )}
    </React.Fragment>
  );
};

export default VariantBuilderSetting;
