import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";

const ElementSettingsQuizLayout = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        label="Max Width"
        setting_name="[options][quiz-max-width-style]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["quiz-max-width-style"]
            ? element.options["quiz-max-width-style"]
            : ""
        }
        options={[
          { value: "full", text: "100%" },
          { value: "custom", text: "Custom" },
        ]}
        device="desktop"
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][quiz-max-width-value]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Quiz Maximum Width"}
        value={
          element.options["quiz-max-width-value"]
            ? element.options["quiz-max-width-value"]
            : ""
        }
        condition={element.options["quiz-max-width-style"] == "custom"}
        min={0}
        max={2560}
        range_step={"1"}
        device="desktop"
        placeholder={"100%"}
        allow_empty={true}
      />

      <VariantBuilderSetting
        object={element}
        label="Max Width"
        setting_name="[options][quiz-max-width-style-mobile]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["quiz-max-width-style-mobile"]
            ? element.options["quiz-max-width-style-mobile"]
            : "none"
        }
        options={[
          { value: "full", text: "100%" },
          { value: "custom", text: "Custom" },
        ]}
        device="mobile"
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][quiz-max-width-value-mobile]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Quiz Maximum Width"}
        value={
          element.options["quiz-max-width-value-mobile"]
            ? element.options["quiz-max-width-value-mobile"]
            : ""
        }
        condition={element.options["quiz-max-width-style-mobile"] == "custom"}
        min={0}
        max={280}
        range_step={"1"}
        allow_empty={true}
        placeholder={"100%"}
        device="mobile"
      />

      <VariantBuilderSetting
      object={element}
      label="Outer Alignment"
      setting_name="[options][cf-alignment]"
      setting_type="alignment"
      object_type="elements"
      object_id={element.id}
      value={
        element.options["cf-alignment"]
          ? element.options["cf-alignment"]
          : "cf-center"
      }
      valueMappings={{
        left: "cf-outer-left",
        center: "cf-outer-center",
        right: "cf-outer-right",
      }}
      device="desktop"
      condition={element.options["quiz-max-width-style"] == 'custom'}
    />

    <VariantBuilderSetting
      object={element}
      label="Outer Alignment"
      setting_name="[options][cf-alignment-mobile]"
      setting_type="alignment"
      object_type="elements"
      object_id={element.id}
      value={
        element.options["cf-alignment-mobile"]
          ? element.options["cf-alignment-mobile"]
          : ""
      }
      valueMappings={{
        left: "cf-mobile-outer-left",
        center: "cf-mobile-outer-center",
        right: "cf-mobile-outer-right",
      }}
      device="mobile"
      condition={element.options["quiz-max-width-style"] == 'custom' || element.options["quiz-max-width-style-mobile"] == 'custom'}
    />

      <VariantBuilderSetting
        object={element}
        label="Content Alignment"
        setting_name="[options][cf-content-alignment]"
        setting_type="alignment"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["cf-content-alignment"]
            ? element.options["cf-content-alignment"]
            : undefined
        }
        valueMappings={{
          left: "cf-left",
          center: "cf-center",
          right: "cf-right",
        }}
        condition={
          contextData.device == "desktop" && element.element_type == "quiz"
        }
        device="desktop"
      />

      <VariantBuilderSetting
        object={element}
        label="Content Alignment"
        setting_name="[options][cf-content-alignment-mobile]"
        setting_type="alignment"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["cf-content-alignment-mobile"]
            ? element.options["cf-content-alignment-mobile"]
            : undefined
        }
        valueMappings={{
          left: "cf-left",
          center: "cf-center",
          right: "cf-right",
        }}
        condition={
          contextData.device == "mobile" && element.element_type == "quiz"
        }
        device="mobile"
      />

    </React.Fragment>
  );
};

export default ElementSettingsQuizLayout;
