import React, { useState, useContext, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSections from "./useSections.js";
import SectionTemplateItem from "./SectionTemplateItem";
import NewSectionDraggable from "./NewSectionDraggable.js";

const NewSection = (props) => {
  const [contextData] = useContext(VariantContextData);
  const [searchText, setSearchText] = useState("");
  const { Sections } = useSections();

  let templates = props.section_templates;
  
  const searchedTemplates = templates.filter(function (template) {
    return template.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const createSection = function (template_id = 0) {
    const stepToUse = contextData.new_object.step_id
      ? Object.values(contextData.objects.variant.steps).filter(
          (step) => step.id == contextData.new_object.step_id
        )[0]
      : Object.values(contextData.objects.variant.steps).filter(
          (step) => step.toBeDeleted !== true && step.position == 1
        )[0];

    if (stepToUse) {
      if (template_id > 0) {
        Sections.create(stepToUse, null, template_id);
      } else {
        Sections.create(stepToUse);
      }
    }
  };

  useEffect(() => {
    NewSectionDraggable.start(contextData, Sections);
  }, []);

  return (
    <React.Fragment>
      <div id="new-section" className="row hard-center my-4">
        <div
          id="new-section-templates"
          className="w-full new-section-templates"
        >
          <div className="w-full text-xl font-semibold text-black dark:text-white">
            Section Templates
          </div>

          <div className="w-full mt-4 relative">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400">
              <i className="fas fa-search text-2xl"></i>
            </span>
            <input
              type="text"
              name="name"
              className="w-full px-2 py-4 pl-8 text-xl rounded-lg text-slate-500 dark:focus:color-white bg-white dark:bg-slate-950  border-none focus:border-none focus:outline-none"
              placeholder="Search..."
              defaultValue={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="w-full" id="sections-create-from-scratch">
            <div
              className="cursor-pointer w-full h-[50px] mt-4 mb-9 text-center leading-[50px] text-lg rounded-lg text-slate-500 hover:text-blue-600 dark:hover:text-white bg-white hover:bg-white dark:bg-slate-950 dark:hover:bg-slate-700 border-dashed border-[1px] border-slate-300 hover:border-blue-600 dark:border-slate-500 dark:hover:border-white"
              onClick={createSection}
            >
              Create From Scratch
            </div>
          </div>

          {searchedTemplates.map((item) => (
            <SectionTemplateItem
              key={item.id}
              item={item}
              selectTemplate={(e) => createSection(e)}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewSection;
