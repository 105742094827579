import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

const ElementText = (props) => {
  let element = props.element;
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    props.variant,
    element
  );

  return (
    <React.Fragment>
      <div
        id={"element" + element.id + "-content"}
        className="element-content cf-text-element cf-margin-alignment cf-merge-tag-allowed"
        data-font={`${element.options["font-family"] || ""} ${
          element.options["font-family-mobile"] || ""
        }`}
        dangerouslySetInnerHTML={{
          __html:
            props.builder == true
              ? "<div class='froala-editor'>" + element.html + "</div>"
              : element.html,
        }}
      ></div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, variant, element) => {
    let options = { ...element.options };

    let maxWidth;
    if (options["max-width"]) {
      maxWidth = options["max-width"];
    }

    let fontSize;
    if (options["font-size"] && options["font-size"] !== "theme") {
      fontSize = options["font-size"];
    }

    let lineHeight;
    if (options["font-line-height"]) {
      lineHeight = options["font-line-height"];
    }

    let fontFamily;
    if (options["font-family"] && options["font-family"] !== "theme") {
      fontFamily = options["font-family"];
    }

    let fontWeight;
    if (options["font-weight"] && options["font-weight"] !== "theme") {
      fontWeight = options["font-weight"];
    }

    let fontColor;
    if (options["font-color"] && options["font-color"] !== "theme") {
      fontColor = options["font-color"];
    }

    let textShadow;
    if (options["box-shadow"] == "soft") {
      textShadow = "0 3px 5px rgb(179 179 179 / 78%)";
    }
    if (options["box-shadow"] == "hard") {
      textShadow = "-3px 3px 0 rgb(0 0 0 / 10%)";
    }
    if (options["box-shadow"] == "custom") {
      textShadow = "";
      textShadow +=
        (element.options["box-shadow-horizontal-distance"]
          ? `${element.options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      textShadow +=
        (element.options["box-shadow-vertical-distance"]
          ? `${element.options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      textShadow +=
        (element.options["box-shadow-blur-radius"]
          ? `${element.options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      textShadow +=
        (element.options["box-shadow-color"]
          ? element.options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    let desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element {
  ${textShadow ? 'text-shadow: ' + textShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h1,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h1,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element p:not(.cf-label), 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element p:not(.cf-label), 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element span,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element span,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element {
  ${fontSize ? `font-size: ${fontSize}px;` : ''}
  ${fontWeight ? `font-weight: ${fontWeight};` : ''}
  ${fontColor ? `color: ${fontColor};` : ''}
  ${fontFamily && fontFamily !== 'inherit' ? `font-family: ${fontFamily}, sans-serif;` : ''}
  ${lineHeight ? `line-height: ${lineHeight}px;` : ''}
  ${maxWidth ? `max-width: ${maxWidth}px;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element h1 {
  ${fontSize ? `font-size: ${fontSize}px;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element h2 {
  ${fontSize ? `font-size: ${fontSize}px;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element h3 {
  ${fontSize ? `font-size: ${fontSize}px;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element h4 {
  ${fontSize ? `font-size: ${fontSize}px;` : ''}
}
`;

    if (fontFamily == "inherit") {
      // prettier-ignore
      desktopStyles += ` 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element span {
  font-family: inherit;
}`
    }

    let maxWidthMobile;
    if (options["max-width-mobile"]) {
      maxWidthMobile = options["max-width-mobile"];
    }

    let fontSizeMobile;
    if (
      options["font-size-mobile"] &&
      options["font-size-mobile"] !== "theme"
    ) {
      fontSizeMobile = options["font-size-mobile"];
    }

    let lineHeightMobile;
    if (options["font-line-height-mobile"]) {
      lineHeightMobile = options["font-line-height-mobile"];
    }

    let fontFamilyMobile;
    if (
      options["font-family-mobile"] &&
      options["font-family-mobile"] !== "theme"
    ) {
      fontFamilyMobile = options["font-family-mobile"];
    }

    let fontWeightMobile;
    if (
      options["font-weight-mobile"] &&
      options["font-weight-mobile"] !== "theme"
    ) {
      fontWeightMobile = options["font-weight-mobile"];
    }

    let fontColorMobile;
    if (
      options["font-color-mobile"] &&
      options["font-color-mobile"] !== "theme"
    ) {
      fontColorMobile = options["font-color-mobile"];
    }

    // prettier-ignore
    let mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] {
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h1,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h1,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element p:not(.cf-label), 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element p:not(.cf-label), 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element span,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element span,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="headline"] .cf-text-element,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"][data-type="text"] .cf-text-element {
  ${fontSizeMobile ? `font-size: ${fontSizeMobile}px;` : ''}
  ${fontFamilyMobile && fontFamilyMobile !== 'inherit' ? `font-family: ${fontFamilyMobile};` : ''}
  ${fontWeightMobile ? `font-weight: ${fontWeightMobile};` : ''}
  ${fontColorMobile ? `color: ${fontColorMobile};` : ''}
  ${lineHeightMobile ? `line-height: ${lineHeightMobile}px;` : ''}
  ${maxWidthMobile ? `max-width: ${maxWidthMobile}px;` : ''}
}`;

    if (fontFamilyMobile == "inherit") {
      // prettier-ignore
      mobileStyles += ` 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-text-element span {
  font-family: inherit;
}`
    }

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementText;
