import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSteps from "../steps/useSteps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StepThumbnail = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { Steps } = useSteps();
  const node = useRef();
  const [optionsIsOpen, setOptionsIsOpen] = useState(false);

  let step = props.step;
  const cta = contextData.objects.cta;
  const variant = contextData.objects.variant;
  let builder_background = variant.options["container-background"];
  if (cta.cta_type !== "overlay") {
    builder_background = "light";
  }

  const elementWarnings = Object.values(variant.elements).filter((e) => e && !e.toBeDeleted && e.options).filter((element) => {
    const isConversionElement = [
      "button",
      "form",
      "survey",
      "quiz",
      "products",
      "product_hero",
    ].includes(element.element_type);

    const belongsToFunnelStep = Object.values(variant.sections)
      .filter((section) => section.step_id == step.id && section.toBeDeleted !== true)
      .map((section) => section.id)
      .includes(element.section_id);

    const missingFunnelStep =
      element.options["confirmation-type"] == "step" &&
      (!element.options["redirect-step"] ||
        (element.options["redirect-step"] !== "previous" &&
          Object.values(variant.steps).filter(
            (s) =>
              s.id + "" == element.options["redirect-step"] &&
              s.toBeDeleted !== true
          ).length == 0));

    const missingRedirectURL =
      element.options["confirmation-type"] == "redirect" &&
      !element.options["redirect-url"] &&
      element.options["confirmation-close"] !== "true";

    const missingTriggeredCampaign =
      element.options["confirmation-type"] == "cta" &&
      !element.options["confirmation-cta"];

    const missingSMSNumber =
      element.options["confirmation-type"] == "sms" &&
      !element.options["sms-number"];

    if (
      !element.toBeDeleted && 
      isConversionElement &&
      belongsToFunnelStep &&
      (missingFunnelStep ||
        missingRedirectURL ||
        missingTriggeredCampaign ||
        missingSMSNumber)
    ) {
      return true;
    }
  }).length;

  let max_width = null;
  if (cta.cta_type !== "page") {
    if (
      variant.options["container-width"] == "310px" ||
      (!variant.options["container-width"] &&
        cta.cta_type == "hook" &&
        cta.hook_type == "vertical")
    ) {
      max_width = "310px";
    } else if (
      ["small", "500px"].includes(variant.options["container-width"])
    ) {
      max_width = "500px";
    } else if (
      ["regular", "700px"].includes(variant.options["container-width"])
    ) {
      max_width = "700px";
    } else if (
      ["large", "900px"].includes(variant.options["container-width"])
    ) {
      max_width = "900px";
    } else if (variant.options["container-width"] == "1200px") {
      max_width = "1200px";
    } else if (variant.options["container-width"] == "100%") {
      max_width = "100%";
    } else if (variant.options["container-width"]) {
      max_width = variant.options["container-width"];
    }
  }

  const toggleOptions = () => {
    setOptionsIsOpen(!optionsIsOpen);
  };

  const handleSelect = () => {
    dispatchCustomEvent("selectObject", {
      object_type: "steps",
      object_id: step.id,
    });
  };

  const handleMoveUp = () => {
    dispatchCustomEvent("moveObjectUp", {
      object_type: "steps",
      object_id: step.id,
    });
  };

  const handleMoveDown = () => {
    dispatchCustomEvent("moveObjectDown", {
      object_type: "steps",
      object_id: step.id,
    });
  };

  const handleDuplicate = () => {
    document.dispatchEvent(
      new CustomEvent("duplicateObject", {
        detail: {
          object_type: "steps",
          object_id: step.id,
        },
      })
    );
  };

  const handleRemove = () => {
    document.dispatchEvent(
      new CustomEvent("removeObject", {
        detail: {
          object_type: "steps",
          object_id: step.id,
        },
      })
    );
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOptionsIsOpen(false);
  };

  useEffect(() => {
    Steps.generateThumbnail(step, props.cta);

    let thumbnail = document.querySelector(
      '.step-thumbnail[data-step-id="' + step.id + '"]'
    );

    thumbnail.onclick = function (e) {
      Steps.scrollTo(step);
    };
  });

  useEffect(() => {
    if (optionsIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsIsOpen]);

  return (
    <React.Fragment>
      <div
        className="w-full step-thumbnail mt-10 relative cursor-pointer text-black dark:text-slate-300 dark:hover:text-white"
        data-step-id={step.id}
        data-position={step.position}
        ref={node}
      >
        <div className="w-full flex">
          <div className="w-[33px] -ml-1 absolute -left-[18px]">
            <div className="step-handle w-[25px] h-[25px] leading-[24px] text-lg mt-[70px] center text-slate-500 dark:text-white">
              <div className="step-handle-grab" style={{ display: "none" }}>
                <FontAwesomeIcon icon="fas fa-grip-vertical" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full flex mb-3">
              <div className="step-handle flex-grow text-xl overflow-hidden whitespace-nowrap text-ellipsis">
                Step {step.position}
                {step.options["name"] ? " - " + step.options["name"] : ""}
              </div>
              <div className="w-[75px]">
                <div
                  className="step-thumbnail-options cursor-pointer absolute float-right right-[0px]"
                  onClick={toggleOptions}
                  style={{ display: "none" }}
                >
                  <FontAwesomeIcon icon="fas fa-ellipsis-h" />
                </div>
              </div>
              {optionsIsOpen && (
                <div
                  className={`w-175 h-[38px] cursor-pointer absolute overflow-hidden right-[0px] top-10 p-0 z-10 bg-white dark:bg-black border-solid border-white dark:border-slate-800 shadow-md`}
                  data-object_type={"steps"}
                  data-object_id={step.id}
                  style={{ borderRadius: "10px" }}
                >
                  <div className={`flex items-center`}>
                    <button
                      title="Edit"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={handleSelect}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <button
                      title="Move Up"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={handleMoveUp}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.75 8L9 3M9 3L14.25 8M9 3V15"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <button
                      title="Move Down"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={handleMoveDown}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.75 10L9 15M9 15L14.25 10M9 15V3"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button
                      title="Duplicate"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={handleDuplicate}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.25 9.84375L14.25 4.49994C14.25 3.25729 13.2426 2.24992 12 2.24994L6.65625 2.25M9.75 15.75L4.6875 15.75C3.75552 15.75 3 14.9945 3 14.0625L3 6.75C3 5.81802 3.75552 5.0625 4.6875 5.0625L9.75 5.0625C10.682 5.0625 11.4375 5.81802 11.4375 6.75L11.4375 14.0625C11.4375 14.9945 10.682 15.75 9.75 15.75Z"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <button
                      title="Remove"
                      className={`bg-white hover:bg-slate-100 dark:bg-black dark:hover:bg-slate-800 p-2 pt-3 cursor-pointer border-none`}
                      onClick={handleRemove}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 4.63235H15M6.75 2.25H11.25M7.5 12.5735V7.80882M10.5 12.5735V7.80882M11.625 15.75H6.375C5.54657 15.75 4.875 15.0389 4.875 14.1618L4.53255 5.45953C4.5148 5.00837 4.85544 4.63235 5.2819 4.63235H12.7181C13.1446 4.63235 13.4852 5.00837 13.4674 5.45953L13.125 14.1618C13.125 15.0389 12.4534 15.75 11.625 15.75Z"
                          stroke={contextData.dark_mode ? "white" : "black"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`w-full step-thumbnail-preview ${builder_background} cursor-pointer overflow-hidden p-2 bg-slate-200 dark:bg-slate-800 rounded-lg border-solid border-slate-100 hover:border-slate-300 dark:border-slate-800 dark:hover:border-slate-800`}
            >
              <div className="relative">
                <div className="relative">
                  <div
                    id={"cta_" + props.cta.id}
                    style={{
                      zoom: "17%",
                      maxWidth: max_width,
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "660px",
                      overflow: "hidden",
                      borderRadius: "25px",
                    }}
                  ></div>
                </div>
                <div className="step-thumbnail-overlay absolute h-[125px] leading-110px inset-0 bg-transparent hover:bg-white dark:hover:bg-slate-800 hover:bg-opacity-50 dark:hover:bg-opacity-50 cursor-pointer rounded-lg">
                  <button
                    style={{ display: "none" }}
                    className="step-thumbnail-button z-10 relative mt-[40px] hard-center border-[1px] border-solid border-slate-950 py-2 px-5 rounded-lg text-lg cursor-pointer semibold bg-slate-950 dark:bg-slate-950 text-white"
                    onClick={handleSelect}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-code-branch"
                      className="mr-3 rotate-180"
                    />
                    Manage Logic
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {elementWarnings > 0 && (
          <div
            className="w-full mt-3 bg-red-100 dark:bg-transparent text-red-700 dark:text-red-400 text-base py-2 px-4 rounded-lg hover:bg-red-200 dark:hover:bg-transparent border-solid border-[1px] border-bg-red-200 dark:border-red-800 dark:hover:border-red-500"
            onClick={handleSelect}
          >
            <div className="inline-block w-[11px] h-[11px] -mt-2 leading-[11px] font-semibold rounded-full text-xs bg-red-700 text-white text-center mr-2">
              {elementWarnings}
            </div>
            Element{`${elementWarnings > 1 ? "s" : ""}`} with errors →
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StepThumbnail;
