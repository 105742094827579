import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeCollapses = (props) => {
  const [contextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Collapses" open={false}>
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][collapse-heading-font-family]"
          label={"Title Font Family"}
          setting_type="select"
          object_type="variants"
          value={
            variant.options["collapse-heading-font-family"]
              ? variant.options["collapse-heading-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][collapse-heading-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["collapse-heading-font-weight"]
              ? variant.options["collapse-heading-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Font Size"
          setting_name="[options][collapse-heading-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["collapse-heading-font-size"]
              ? variant.options["collapse-heading-font-size"]
              : undefined
          }
          placeholder={16}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Font Size"
          setting_name="[options][collapse-heading-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["collapse-heading-font-size-mobile"]
              ? variant.options["collapse-heading-font-size-mobile"]
              : undefined
          }
          placeholder={variant.options["collapse-heading-font-size"]
          ? variant.options["collapse-heading-font-size"]
          : 16}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Font Color"
          setting_name="[options][collapse-heading-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["collapse-heading-font-color"]
              ? variant.options["collapse-heading-font-color"]
              : undefined
          }
          placeholder="#000"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Background Color"
          setting_name="[options][collapse-heading-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["collapse-heading-background-color"]
              ? variant.options["collapse-heading-background-color"]
              : undefined
          }
          placeholder="#F5F5F5"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Border Style"
          setting_name="[options][collapse-heading-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["collapse-heading-border-style"]
              ? variant.options["collapse-heading-border-style"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Border Color"
          setting_name="[options][collapse-heading-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["collapse-heading-border-color"]
              ? variant.options["collapse-heading-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={["dotted", "dashed", "solid"].includes(
            variant.options["collapse-heading-border-style"]
          )}
          show_reset={true}
        />

        {variant.options["collapse-heading-border-style"] &&
          variant.options["collapse-heading-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Title Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][collapse-heading-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[
                              `collapse-heading-border-width-${side}`
                            ]
                          ) == false
                            ? variant.options[
                                `collapse-heading-border-width-${side}`
                              ]
                            : variant.options["collapse-heading-border-width"]
                            ? variant.options[
                                "collapse-heading-border-width"
                              ].replace("px", "")
                            : "1"
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Box Corner Radius"
          setting_name="[options][collapse-heading-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["collapse-heading-radius"]
              ? variant.options["collapse-heading-radius"]
              : "cf-not-rounded"
          }
        />

        {variant.options["collapse-heading-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][collapse-heading-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[`collapse-heading-radius-${side}`]
                      ) == false
                        ? variant.options[`collapse-heading-radius-${side}`]
                        : "3"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Items Distance"
          setting_name="[options][collapse-items-distance]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["collapse-items-distance"]
              ? variant.options["collapse-items-distance"]
              : undefined
          }
          placeholder={4}
          min={4}
          max={100}
          range_step={1}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Background Color"
          setting_name="[options][collapse-content-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["collapse-content-background-color"]
              ? variant.options["collapse-content-background-color"]
              : undefined
          }
          placeholder="#F5F5F5"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Box Shadow"
          setting_name="[options][collapse-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["collapse-shadow"]
              ? variant.options["collapse-shadow"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][collapse-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["collapse-shadow-horizontal-distance"]
              ? variant.options["collapse-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["collapse-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][collapse-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["collapse-shadow-vertical-distance"]
              ? variant.options["collapse-shadow-vertical-distance"]
              : undefined
          }
          condition={variant.options["collapse-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][collapse-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["collapse-shadow-blur-radius"]
              ? variant.options["collapse-shadow-blur-radius"]
              : undefined
          }
          placeholder={0}
          condition={variant.options["collapse-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][collapse-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["collapse-shadow-spread-radius"]
              ? variant.options["collapse-shadow-spread-radius"]
              : undefined
          }
          condition={variant.options["collapse-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][collapse-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["collapse-shadow-color"]
              ? variant.options["collapse-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          placeholder
          condition={variant.options["collapse-shadow"] == "custom"}
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeCollapses;
