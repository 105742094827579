import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSteps from "../steps/useSteps.js";
import StepThumbnail from "../steps/StepThumbnail";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";

const StepsManager = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { Steps } = useSteps();
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let steps = Object.values(variant.steps)
    .filter((step) => step.toBeDeleted !== true)
    .sort((a, b) => a.position - b.position);

  useEffect(() => {
    Steps.managerSortable();
  });

  return (
    <React.Fragment>
      <VariantBuilderPanelTitle
        title={`Funnel Steps`}
        tooltip="Funnel steps are shown one step at a time. Use conversion elements progress visitors through your funnel."
        tooltipLabel="Learn"
      />
      <div className="w-full">
        <div id="steps-list" className="w-full mt-5">
          {steps.map((step) => {
            return (
              <StepThumbnail
                key={"step-thumbnail-" + step.id}
                cta={cta}
                variant={variant}
                step={step}
              />
            );
          })}
        </div>
        <div id="steps-list-add" className="w-full">
          <div
            className="cursor-pointer w-[240px] ml-auto mr-auto h-[125px] mt-10 mb-9 text-center leading-[125px] text-xl rounded-lg text-slate-500 hover:text-blue-600 dark:hover:text-white bg-white hover:bg-white dark:bg-slate-950 dark:hover:bg-slate-700 border-dashed border-[1px] border-slate-300 hover:border-blue-600 dark:border-slate-500 dark:hover:border-white"
            onClick={() => {
              dispatchCustomEvent("newObject", {
                object_type: "Steps",
              });
            }}
          >
            + Add Funnel Step
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StepsManager;
