import React, { useState, useEffect, useRef } from "react";
import ReportCount from "../reports/ReportCount";
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";
import FieldOptionReportIcon from "./FieldOptionReportIcon";
import wNumb from "wnumb";

const FieldOptionValueReport = (props) => {
  const element = props.element;
  const field = props.field;
  const field_option = props.field_option;

  let field_option_formatted_value = field_option.value;

  if (field.field_type == "range" && !isNaN(field_option.value)) {
    const formatPercentage = wNumb({
      decimals: 0,
      suffix: "%",
    });

    const formatCurrency = wNumb({
      decimals: 2,
      prefix: props.currency,
    });

    var numberType = field.options["range-value-type"]
      ? field.options["range-value-type"]
      : "integer";
    if (numberType == "integer") {
      field_option_formatted_value = parseInt(field_option.value);
    } else if (numberType == "currency") {
      field_option_formatted_value = formatCurrency.to(
        parseInt(field_option.value)
      );
    } else if (numberType == "percentage") {
      field_option_formatted_value = formatPercentage.to(
        parseInt(field_option.value)
      );
    }
  }

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report field-option-report"
        data-field-id={field.id}
      >
        <div
          className="element-selection-report-bar"
          style={{
            width: undefined,
            display: "none",
          }}
        ></div>
        <div className="col-xs-4">
          <p className="ellipsis" style={{ marginBottom: "5px" }}>
            <FieldOptionReportIcon
              field_type={field.field_type}
              field_option={field_option}
            />

            {field_option.label
              ? field_option.label
              : field_option_formatted_value
              ? field_option_formatted_value
              : `Field Option ${field_option.position}`}
          </p>
        </div>
        <div className="col-xs-8 right">
          <p className="light text-xl" style={{ marginBottom: "10px" }}>
            <strong>
              <ReportCount
                inline={true}
                id={`field-option-report-${field_option.id}`}
                path={`/websites/${props.cta.website_id}/events_reports`}
                payload={{
                  report_type: "distinct_count",
                  report: {
                    website_id: props.cta.website_id,
                    event_type: ["Complete", "Selection"],
                    contactable_type: "Cta",
                    contactable_id: props.cta.id,
                    variant_id: props.step.variant_id,
                    step_id: props.step.id,
                    element_id: element.id,
                    field: field.slug,
                    value: field_option_formatted_value,
                    range_start: props.range_start,
                    range_end: props.range_end,
                  },
                }}
                callback={() => {
                  props.calculateSelectionRates();
                }}
              />
            </strong>{" "}
            selected (<span className="conversion-rate">0.00</span>%)
            {!props.gateReports && (
              <React.Fragment>
                {" - "}
                <strong>
                  <ReportCount
                    id={`field_option-${field_option.id}-orders`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "count",
                      report: {
                        website_id: props.cta.website_id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        field: field.slug,
                        value: field_option_formatted_value,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `#field_option-${field_option.id}-orders`,
                        `#field_option-${field_option.id}-revenue`,
                        `#field_option-${field_option.id}-aov`
                      );
                      updateConversionRate(
                        `#field-option-${field_option.id}-selections`,
                        `#field_option-${field_option.id}-orders`,
                        `#field_option-${field_option.id}-order-rate`
                      );
                    }}
                  />
                </strong>{" "}
                orders (
                <span id={`field_option-${field_option.id}-order-rate`}>
                  0.00
                </span>
                ){" - "}
                <strong>
                  <ReportCount
                    id={`field_option-${field_option.id}-revenue`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "revenue",
                      report: {
                        website_id: props.cta.website_id,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        field: field.slug,
                        value: field_option_formatted_value,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `#field_option-${field_option.id}-orders`,
                        `#field_option-${field_option.id}-revenue`,
                        `#field_option-${field_option.id}-aov`
                      );
                    }}
                    currency={props.currency}
                  />
                </strong>{" "}
                revenue
                {" - "}
                <strong>
                  {props.currency}
                  <span id={`field_option-${field_option.id}-aov`}>0.00</span>
                </strong>{" "}
                AOV
              </React.Fragment>
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldOptionValueReport;
