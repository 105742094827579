import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeProducts = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Products" open={false}>
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-width]"
          setting_type="range"
          object_type="variants"
          label={"Container maximum width"}
          value={
            variant.options["products-item-width"]
              ? variant.options["products-item-width"]
              : undefined
          }
          placeholder={2560}
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-width-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Container maximum width"}
          value={
            variant.options["products-item-width-mobile"]
              ? variant.options["products-item-width-mobile"]
              : undefined
          }
          placeholder={variant.options["products-item-width"] || 2560}
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-padding]"
          setting_type="range"
          object_type="variants"
          label={"Products outer padding"}
          value={
            variant.options["products-padding"]
              ? variant.options["products-padding"]
              : undefined
          }
          placeholder={0}
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-padding-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products outer padding"}
          value={
            variant.options["products-padding-mobile"]
              ? variant.options["products-padding-mobile"]
              : undefined
          }
          placeholder={0}
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-padding]"
          setting_type="range"
          object_type="variants"
          label={"Products inner padding"}
          value={
            variant.options["products-item-padding"]
              ? variant.options["products-item-padding"]
              : undefined
          }
          placeholder={15}
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-padding-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products inner padding"}
          value={
            variant.options["products-item-padding-mobile"]
              ? variant.options["products-item-padding-mobile"]
              : undefined
          }
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-margin-vertical]"
          setting_type="range"
          object_type="variants"
          label={"Products vertical spacing"}
          value={
            variant.options["products-margin-vertical"]
              ? variant.options["products-margin-vertical"]
              : undefined
          }
          placeholder={0}
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-margin-vertical-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products vertical spacing"}
          value={
            variant.options["products-margin-vertical-mobile"]
              ? variant.options["products-margin-vertical-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-margin-vertical"]
              ? variant.options["products-margin-vertical"]
              : 0
          }
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-content-type-spacing]"
          setting_type="range"
          object_type="variants"
          label={"Products Content Spacing"}
          value={
            variant.options["products-content-type-spacing"]
              ? variant.options["products-content-type-spacing"]
              : undefined
          }
          placeholder={
            variant.options["products-content-type-spacing"]
              ? variant.options["products-content-type-spacing"]
              : 10
          }
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-content-type-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products Content Spacing"}
          value={
            variant.options["products-content-type-spacing-mobile"]
              ? variant.options["products-content-type-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-content-type-spacing-mobile"]
              ? variant.options["products-content-type-spacing-mobile"]
              : variant.options["products-content-type-spacing"]
              ? variant.options["products-content-type-spacing"]
              : 10
          }
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Product background color"
          setting_name="[options][products-item-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-background-color"]
              ? variant.options["products-item-background-color"]
              : undefined
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Product border style"
          setting_name="[options][products-item-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-item-border-style"]
              ? variant.options["products-item-border-style"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Product border color"
          setting_name="[options][products-item-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-border-color"]
              ? variant.options["products-item-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={["dotted", "dashed", "solid"].includes(
            variant.options["products-item-border-style"]
          )}
          show_reset={true}
        />

        <div
          className="col-xs-12 some-vertical-space setting"
          style={{ marginBottom: "5px" }}
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="mb-[5px] text-xl text-black dark:text-white">
                Product Border Width
              </div>

              {["left", "top", "bottom", "right"].map((side) => {
                const settingName = `[options][products-item-border-width-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[`products-item-border-width-${side}`]
                      ) == false
                        ? variant.options[`products-item-border-width-${side}`]
                        : variant.options["products-item-border-width"]
                        ? variant.options["products-item-border-width"].replace(
                            "px",
                            ""
                          )
                        : "1"
                    }
                    placeholder="0px"
                    col="3"
                    note={window.capitalizeFirstLetter(side)}
                    classNames="spacing-inputs"
                    min={0}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Product Corner Radius"
          setting_name="[options][products-item-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="variants"
          value={
            variant.options["products-item-radius"]
              ? variant.options["products-item-radius"]
              : "cf-not-rounded"
          }
        />

        {variant.options["products-item-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][products-item-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[`products-item-radius-${side}`]
                      ) == false
                        ? variant.options[`products-item-radius-${side}`]
                        : "3"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Products Shadow"
          setting_name="[options][products-item-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["products-item-shadow"]
              ? variant.options["products-item-shadow"]
              : "None"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["products-item-shadow-horizontal-distance"]
              ? variant.options["products-item-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["products-item-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["products-item-shadow-vertical-distance"]
              ? variant.options["products-item-shadow-vertical-distance"]
              : undefined
          }
          condition={variant.options["products-item-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["products-item-shadow-blur-radius"]
              ? variant.options["products-item-shadow-blur-radius"]
              : undefined
          }
          condition={variant.options["products-item-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["products-item-shadow-spread-radius"]
              ? variant.options["products-item-shadow-spread-radius"]
              : undefined
          }
          condition={variant.options["products-item-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][products-item-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-shadow-color"]
              ? variant.options["products-item-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={variant.options["products-item-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Inset shadow position"
          setting_name="[options][products-item-shadow-inset]"
          setting_type="checkbox"
          object_type="variants"
          value={
            variant.options["products-item-shadow-inset"]
              ? variant.options["products-item-shadow-inset"]
              : "false"
          }
          condition={variant.options["products-item-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-image-height]"
          setting_type="range"
          object_type="variants"
          label={"Products image height"}
          value={
            variant.options["products-item-image-height"]
              ? variant.options["products-item-image-height"]
              : undefined
          }
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-carousel-image-height]"
          setting_type="range"
          object_type="variants"
          label={"Carousel image height"}
          value={
            variant.options["products-carousel-image-height"]
              ? variant.options["products-carousel-image-height"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-carousel-image-height-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Carousel image height"}
          value={
            variant.options["products-carousel-image-height-mobile"]
              ? variant.options["products-carousel-image-height-mobile"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-image-height-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products image height"}
          value={
            variant.options["products-item-image-height-mobile"]
              ? variant.options["products-item-image-height-mobile"]
              : undefined
          }
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-image-width]"
          setting_type="range"
          object_type="variants"
          label={"Products image width"}
          value={
            variant.options["products-item-image-width"]
              ? variant.options["products-item-image-width"]
              : undefined
          }
          min={0}
          max={1200}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-image-width-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products image width"}
          value={
            variant.options["products-item-image-width-mobile"]
              ? variant.options["products-item-image-width-mobile"]
              : undefined
          }
          min={0}
          max={1200}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products title font"
          setting_name="[options][products-item-title-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-item-title-font"]
              ? variant.options["products-item-title-font"]
              : undefined
          }
          placeholder={
            variant.options["products-item-title-font"]
              ? variant.options["products-item-title-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-title-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-item-title-font-weight"]
              ? variant.options["products-item-title-font-weight"]
              : "700"
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Products title color"
          setting_name="[options][products-item-title-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-title-color"]
              ? variant.options["products-item-title-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-title-color"]
              ? variant.options["products-item-title-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products title size"
          setting_name="[options][products-item-title-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-item-title-size"]
              ? variant.options["products-item-title-size"]
              : undefined
          }
          placeholder={
            variant.options["products-item-title-size"]
              ? variant.options["products-item-title-size"]
              : "15"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products title size"
          setting_name="[options][products-item-title-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-item-title-size-mobile"]
              ? variant.options["products-item-title-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-item-title-size-mobile"]
              ? variant.options["products-item-title-size-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products price font"
          setting_name="[options][products-item-price-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-item-price-font"]
              ? variant.options["products-item-price-font"]
              : undefined
          }
          placeholder={
            variant.options["products-item-price-font"]
              ? variant.options["products-item-price-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-price-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-item-price-font-weight"]
              ? variant.options["products-item-price-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Price color"
          setting_name="[options][products-item-price-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-price-color"]
              ? variant.options["products-item-price-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-price-color"]
              ? variant.options["products-item-price-color"]
              : "#000"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Discount color"
          setting_name="[options][products-item-discount-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-discount-color"]
              ? variant.options["products-item-discount-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-discount-color"]
              ? variant.options["products-item-discount-color"]
              : "#000"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products price size"
          setting_name="[options][products-item-price-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-item-price-size"]
              ? variant.options["products-item-price-size"]
              : undefined
          }
          placeholder={
            variant.options["products-item-price-size"]
              ? variant.options["products-item-price-size"]
              : "15"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products price size"
          setting_name="[options][products-item-price-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-item-price-size-mobile"]
              ? variant.options["products-item-price-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-item-price-size-mobile"]
              ? variant.options["products-item-price-size-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Description font"
          setting_name="[options][products-item-description-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-item-description-font"]
              ? variant.options["products-item-description-font"]
              : undefined
          }
          placeholder={
            variant.options["products-item-description-font"]
              ? variant.options["products-item-description-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-item-description-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-item-description-font-weight"]
              ? variant.options["products-item-description-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Description color"
          setting_name="[options][products-item-description-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-item-description-color"]
              ? variant.options["products-item-description-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-description-color"]
              ? variant.options["products-item-description-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products description size"
          setting_name="[options][products-item-description-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-item-description-size"]
              ? variant.options["products-item-description-size"]
              : undefined
          }
          placeholder={
            variant.options["products-item-description-size"]
              ? variant.options["products-item-description-size"]
              : "15"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products description size"
          setting_name="[options][products-item-description-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-item-description-size-mobile"]
              ? variant.options["products-item-description-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-item-description-size-mobile"]
              ? variant.options["products-item-description-size-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font`}
          setting_name="[options][products-content-label-font-family]"
          setting_type="select"
          object_type="variants"
          value={variant.options['products-content-label-font-family']}
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-content-label-font-weight]"
          setting_type="dropdown"
          label="Weight"
          object_type="variants"
          value={variant.options['products-content-label-font-weight']}
          options={[
            { value: "400", text: "Normal" },
            { value: "700", text: "Bold" },
          ]}
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font Size`}
          setting_name="[options][products-content-label-font-size]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-content-label-font-size']}
          placeholder="13"
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font Size`}
          setting_name="[options][products-content-label-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-content-label-font-size-mobile']}
          placeholder={variant.options['products-content-label-font-size']}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font line height`}
          setting_name="[options][products-content-label-line-height]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-content-label-line-height']}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font line height`}
          setting_name="[options][products-content-label-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-content-label-line-height-mobile']}
          placeholder={variant.options['products-content-label-line-height']}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font color`}
          setting_name="[options][products-content-label-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={variant.options['products-content-label-font-color']}
          placeholder={"#000"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Min Width"
          setting_name="[options][products-variant-picker-button-minimum-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-variant-picker-button-minimum-width"]
              ? variant.options["products-variant-picker-button-minimum-width"]
              : undefined
          }
          placeholder={
            variant.options["products-variant-picker-button-minimum-width"]
              ? variant.options["products-variant-picker-button-minimum-width"]
              : "auto"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Min Width"
          setting_name="[options][products-variant-picker-button-minimum-width-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options[
              "products-variant-picker-button-minimum-width-mobile"
            ]
              ? variant.options[
                  "products-variant-picker-button-minimum-width-mobile"
                ]
              : undefined
          }
          placeholder={
            variant.options[
              "products-variant-picker-button-minimum-width-mobile"
            ]
              ? variant.options[
                  "products-variant-picker-button-minimum-width-mobile"
                ]
              : "auto"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Font"
          setting_name="[options][products-variant-picker-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-variant-picker-font-family"]
              ? variant.options["products-variant-picker-font-family"]
              : undefined
          }
          placeholder={
            variant.options["products-variant-picker-font-family"]
              ? variant.options["products-variant-picker-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-variant-picker-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-variant-picker-font-weight"]
              ? variant.options["products-variant-picker-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Font Size"
          setting_name="[options][products-variant-picker-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-variant-picker-font-size"]
              ? variant.options["products-variant-picker-font-size"]
              : undefined
          }
          placeholder={
            variant.options["products-variant-picker-font-size"]
              ? variant.options["products-variant-picker-font-size"]
              : "14"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Font Size"
          setting_name="[options][products-variant-picker-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-variant-picker-font-size-mobile"]
              ? variant.options["products-variant-picker-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-variant-picker-font-size-mobile"]
              ? variant.options["products-variant-picker-font-size-mobile"]
              : "14"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Text"
          setting_name="[options][products-variant-picker-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-variant-picker-text-color"]
              ? variant.options["products-variant-picker-text-color"]
              : undefined
          }
          placeholder={
            variant.options["products-variant-picker-text-color"]
              ? variant.options["products-variant-picker-text-color"]
              : "#000"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-variant-picker-text-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-variant-picker-text-hover-color"]
              ? variant.options["products-variant-picker-text-hover-color"]
              : undefined
          }
          placeholder={
            variant.options["products-variant-picker-text-hover-color"]
              ? variant.options["products-variant-picker-text-hover-color"]
              : variant.options["products-variant-picker-text-color"] || "#fff"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Color"
          setting_name="[options][products-variant-picker-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-variant-picker-background-color"]
              ? variant.options["products-variant-picker-background-color"]
              : undefined
          }
          placeholder={"#fff"}
          col="6"
          classNames="some-padding-right"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-variant-picker-background-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-variant-picker-background-hover-color"]
              ? variant.options[
                  "products-variant-picker-background-hover-color"
                ]
              : undefined
          }
          placeholder={"#000"}
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Border Style"
          setting_name="[options][products-variant-picker-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-variant-picker-border-style"]
              ? variant.options["products-variant-picker-border-style"]
              : "solid"
          }
          show_reset={true}
        />

        {variant.options["products-variant-picker-border-style"] &&
          variant.options["products-variant-picker-border-style"] !==
            "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <p className="field-title">Picker Buttons Border</p>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][products-variant-picker-border-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[
                              `products-variant-picker-border-${side}`
                            ]
                          ) == false
                            ? variant.options[
                                `products-variant-picker-border-${side}`
                              ]
                            : variant.options["products-variant-picker-border"]
                            ? variant.options[
                                "products-variant-picker-border"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder="1px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Picker Border"
          setting_name="[options][products-variant-picker-border-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-variant-picker-border-color"]
              ? variant.options["products-variant-picker-border-color"]
              : undefined
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-variant-picker-border-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-variant-picker-border-hover-color"]
              ? variant.options["products-variant-picker-border-hover-color"]
              : undefined
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Border Rounding"
          setting_name="[options][products-variant-picker-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-variant-picker-corner-rounding"]
              ? variant.options["products-variant-picker-corner-rounding"]
              : undefined
          }
          condition={
            variant.options["products-variant-picker-border-style"] !== "none"
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-variant-picker-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`products-variant-picker-corner-${side}`]
                    ) == false
                      ? variant.options[
                          `products-variant-picker-corner-${side}`
                        ]
                      : undefined
                  }
                  condition={
                    variant.options[
                      "products-variant-picker-corner-rounding"
                    ] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Quantity Minimum Width"
          setting_name="[options][products-quantity-picker-minimum-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-minimum-width"]
              ? variant.options["products-quantity-picker-minimum-width"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-minimum-width"]
              ? variant.options["products-quantity-picker-minimum-width"]
              : "100"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Minimum Width"
          setting_name="[options][products-quantity-picker-minimum-width-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-minimum-width-mobile"]
              ? variant.options["products-quantity-picker-minimum-width-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-minimum-width-mobile"]
              ? variant.options["products-quantity-picker-minimum-width-mobile"]
              : "100"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Font family"
          setting_name="[options][products-quantity-picker-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-font-family"]
              ? variant.options["products-quantity-picker-font-family"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-font-family"]
              ? variant.options["products-quantity-picker-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-quantity-picker-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-font-weight"]
              ? variant.options["products-quantity-picker-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Font Size"
          setting_name="[options][products-quantity-picker-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-font-size"]
              ? variant.options["products-quantity-picker-font-size"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-font-size"]
              ? variant.options["products-quantity-picker-font-size"]
              : "14"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Font Size"
          setting_name="[options][products-quantity-picker-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-font-size-mobile"]
              ? variant.options["products-quantity-picker-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-font-size-mobile"]
              ? variant.options["products-quantity-picker-font-size-mobile"]
              : "14"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Background"
          setting_name="[options][products-quantity-picker-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-background-color"]
              ? variant.options["products-quantity-picker-background-color"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-background-color"]
              ? variant.options["products-quantity-picker-background-color"]
              : "#FFFFFF"
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Text"
          setting_name="[options][products-quantity-picker-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-text-color"]
              ? variant.options["products-quantity-picker-text-color"]
              : undefined
          }
          placeholder={
            variant.options["products-quantity-picker-text-color"]
              ? variant.options["products-quantity-picker-text-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Border Style"
          setting_name="[options][products-quantity-picker-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-border-style"]
              ? variant.options["products-quantity-picker-border-style"]
              : "solid"
          }
        />

        {variant.options["products-quantity-picker-border-style"] !==
          "none" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
              <div className="mb-[5px] text-xl text-black dark:text-white">Picker Border Width</div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][products-quantity-picker-border-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={variant}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="variants"
                      value={
                        _.isNil(
                          variant.options[
                            `products-quantity-picker-border-${side}`
                          ]
                        ) == false
                          ? variant.options[
                              `products-quantity-picker-border-${side}`
                            ]
                          : "1"
                      }
                      allow_empty={true}
                      placeholder="1px"
                      col="3"
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Border Color"
          setting_name="[options][products-quantity-picker-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-border-color"]
              ? variant.options["products-quantity-picker-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={
            variant.options["products-quantity-picker-border-style"] !== "none"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover Color"
          setting_name="[options][products-quantity-picker-border-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-quantity-picker-border-hover-color"]
              ? variant.options["products-quantity-picker-border-hover-color"]
              : undefined
          }
          condition={
            variant.options["products-quantity-picker-border-style"] !== "none"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Border Corner Rounding"
          setting_name="[options][products-quantity-picker-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-quantity-picker-corner-rounding"]
              ? variant.options["products-quantity-picker-corner-rounding"]
              : undefined
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-quantity-picker-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`products-quantity-picker-corner-${side}`]
                    ) == false
                      ? variant.options[
                          `products-quantity-picker-corner-${side}`
                        ]
                      : undefined
                  }
                  condition={
                    variant.options[
                      "products-quantity-picker-corner-rounding"
                    ] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Plan Font Family"
          setting_name="[options][products-subscription-choices-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-font-family"]
              ? variant.options["products-subscription-choices-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-subscription-choices-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-font-weight"]
              ? variant.options["products-subscription-choices-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Font Size"
          setting_name="[options][products-subscription-choices-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-font-size"]
              ? variant.options["products-subscription-choices-font-size"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Choices Font Size"
          setting_name="[options][products-subscription-choices-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-font-size-mobile"]
              ? variant.options[
                  "products-subscription-choices-font-size-mobile"
                ]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Text"
          setting_name="[options][products-subscription-choices-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-text-color"]
              ? variant.options["products-subscription-choices-text-color"]
              : undefined
          }
          placeholder="#000"
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover Color"
          setting_name="[options][products-subscription-choices-text-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-text-hover-color"]
              ? variant.options[
                  "products-subscription-choices-text-hover-color"
                ]
              : undefined
          }
          placeholder="#000"
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Choices Color"
          setting_name="[options][products-subscription-choices-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-subscription-choices-background-color"]
              ? variant.options[
                  "products-subscription-choices-background-color"
                ]
              : undefined
          }
          placeholder="#fff"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Choices Border Style"
          setting_name="[options][products-subscription-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-subscription-border-style"]
              ? variant.options["products-subscription-border-style"]
              : "solid"
          }
        />

        <div
          className="col-xs-12 some-vertical-space setting"
          style={{ marginBottom: "5px" }}
        >
          <div className="row">
            <div className="col-xs-12">
            <div className="mb-[5px] text-xl text-black dark:text-white">Border Width</div>

              {["left", "top", "bottom", "right"].map((side) => {
                const settingName = `[options][products-subscription-border-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        options[`products-subscription-border-${side}`]
                      ) == false
                        ? options[`products-subscription-border-${side}`]
                        : "1"
                    }
                    allow_empty={true}
                    placeholder="0px"
                    col="3"
                    note={window.capitalizeFirstLetter(side)}
                    classNames="spacing-inputs"
                    min={0}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Border Color"
          setting_name="[options][products-subscription-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-subscription-border-color"]
              ? variant.options["products-subscription-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={
            variant.options["products-subscription-border-style"] !== "none"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover Color"
          setting_name="[options][products-subscription-border-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-subscription-border-hover-color"]
              ? variant.options["products-subscription-border-hover-color"]
              : undefined
          }
          condition={
            variant.options["products-subscription-border-style"] !== "none"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Radio"
          setting_name="[options][products-subscription-radio-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-subscription-radio-color"]
              ? variant.options["products-subscription-radio-color"]
              : undefined
          }
          placeholder="#D4DDEA"
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Selected Color"
          setting_name="[options][products-subscription-radio-selected-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-subscription-radio-selected-color"]
              ? variant.options["products-subscription-radio-selected-color"]
              : undefined
          }
          placeholder="#003FFF"
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Border Corner Rounding"
          setting_name="[options][products-subscription-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-subscription-corner-rounding"]
              ? variant.options["products-subscription-corner-rounding"]
              : undefined
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-subscription-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`products-subscription-corner-${side}`]
                    ) == false
                      ? variant.options[`products-subscription-corner-${side}`]
                      : undefined
                  }
                  condition={
                    variant.options["products-subscription-corner-rounding"] ==
                    "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Reviews Star Color"
          setting_name="[options][products-reviews-star-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-reviews-star-color"]
              ? variant.options["products-reviews-star-color"]
              : undefined
          }
          placeholder="#EFAB40"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Star Size"
          setting_name="[options][products-reviews-star-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-reviews-star-size"]
              ? variant.options["products-reviews-star-size"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Star Size"
          setting_name="[options][products-reviews-star-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-reviews-star-size-mobile"]
              ? variant.options["products-reviews-star-size-mobile"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Font Family"
          setting_name="[options][products-reviews-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-reviews-font-family"]
              ? variant.options["products-reviews-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-reviews-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-reviews-font-weight"]
              ? variant.options["products-reviews-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Font Size"
          setting_name="[options][products-reviews-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-reviews-font-size"]
              ? variant.options["products-reviews-font-size"]
              : undefined
          }
          placeholder="11"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Font Size"
          setting_name="[options][products-reviews-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-reviews-font-size-mobile"]
              ? variant.options["products-reviews-font-size-mobile"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Text Color"
          setting_name="[options][products-reviews-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-reviews-text-color"]
              ? variant.options["products-reviews-text-color"]
              : undefined
          }
          placeholder="#000"
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeProducts;
