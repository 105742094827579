import React, { useContext, useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingColumn = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  useEffect(() => {
    let setting_tag = document.querySelector(
      '.column-select-outer[setting_name="' + props.setting_name + '"]'
    );

    let buttons = setting_tag.querySelectorAll("button");

    buttons.forEach(function (button_tag) {
      button_tag.onclick = function () {
        let new_value = button_tag.getAttribute("data-value");
        let updates = [
          {
            object_type: props.object_type,
            object_id: props.object_id,
            setting_name: props.setting_name,
            value: new_value,
            skip_history: props.skip_history,
          },
        ];

        button_tag.querySelectorAll(".column-value").forEach((value_tag) => {
          let column = value_tag.getAttribute("data-column");
          let column_value =
            "cf-col-md-" +
            (props.device == "mobile" ? "mobile-" : "") +
            value_tag.getAttribute("data-value");
          let setting_name =
            "[options][structure][cf-column-" +
            column +
            (props.device == "mobile" ? "-mobile" : "") +
            "]";
          updates.push({
            object_type: props.object_type,
            object_id: props.object_id,
            setting_name: setting_name,
            value: column_value,
            skip_history: props.skip_history,
          });
        });

        builder.update(updates);
      };
    });

    let previously_selected = setting_tag.querySelector(
      ".column-select-button-active"
    );
    if (previously_selected) {
      previously_selected.classList.remove("column-select-button-active");
    }

    let active_button = setting_tag.querySelector(
      'button[data-value="' + props.value + '"]'
    );

    if (active_button) {
      active_button.classList.add("column-select-button-active");
    }
  });
  
  useEffect(() => {
    const columnsSelector = `#builder .cf-column[data-section-id='${props.object_id}']`;
    const selectLabelSelector = `.select-label[data-object_type="sections"][data-object_id="${props.object_id}"]`;

    const columnTags = document.querySelectorAll(columnsSelector);
    columnTags.forEach((columnTag) => {
      columnTag.classList.add("editor-select");
      columnTag.querySelector(selectLabelSelector).style.display = "block";
    });

    setTimeout(() => {
      const columnTags = document.querySelectorAll(columnsSelector);
      columnTags.forEach((columnTag) => {
        columnTag.classList.remove("editor-select");
        columnTag.querySelector(selectLabelSelector).style.display = "none";
      });
    }, 2000);
  }, [props.value]);

  return (
    <React.Fragment>
      <div className="column-select-outer" setting_name={props.setting_name}>
        <div
          className="col-xs-12"
          style={{
            margin: 0,
            padding: "0px",
          }}
        >
          <div
            className="col-xs-6"
            style={{
              padding: 0,
            }}
          >
            <button
              data-value="100"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-12"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                data-column="first"
                data-value="12"
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                100%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="50-50"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-6 column-value"
                data-column="first"
                data-value="6"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-6 column-value"
                data-column="second"
                data-value="6"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                50/50%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0",
            }}
          >
            <button
              data-value="60-40"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-7 column-value"
                data-column="first"
                data-value="7"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-5 column-value"
                data-column="second"
                data-value="5"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                60/40%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="40-60"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-5 column-value"
                data-column="first"
                data-value="5"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-7 column-value"
                data-column="second"
                data-value="7"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                40/60%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="70-30"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-8 column-value"
                data-column="first"
                data-value="8"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-4 column-value"
                data-column="second"
                data-value="4"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                70/30%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="30-70"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-4 column-value"
                data-column="first"
                data-value="4"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-8 column-value"
                data-column="second"
                data-value="8"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                30/70%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="80-20"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-9 column-value"
                data-column="first"
                data-value="9"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="second"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                80/20%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="20-80"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-3 column-value"
                data-column="first"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-9 column-value"
                data-column="second"
                data-value="9"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                20/80%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="90-10"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-10 column-value"
                data-column="first"
                data-value="10"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-2 column-value"
                data-column="second"
                data-value="2"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                90/10%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="10-90"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-2 column-value"
                data-column="first"
                data-value="2"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-10 column-value"
                data-column="second"
                data-value="10"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                10/90%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="33-33-33"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-4 column-value"
                data-column="first"
                data-value="4"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-4 column-value"
                data-column="second"
                data-value="4"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-4 column-value"
                data-column="third"
                data-value="4"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                33/33/33%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="50-25-25"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-6 column-value"
                data-column="first"
                data-value="6"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="second"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="third"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                50/25/25%
              </div>
            </button>
          </div>
          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="25-25-50"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-3 column-value"
                data-column="first"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="second"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-6 column-value"
                data-column="third"
                data-value="6"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                25/25/50%
              </div>
            </button>
          </div>

          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="25-50-25"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-3 column-value"
                data-column="first"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-6 column-value"
                data-column="second"
                data-value="6"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="third"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                }}
              >
                25/50/25%
              </div>
            </button>
          </div>

          <div
            className="col-xs-6"
            style={{
              padding: "0px",
            }}
          >
            <button
              data-value="25-25-25-25"
              type="button"
              className="btn btn-default column-select-button"
            >
              <div
                className="col-xs-3 column-value"
                data-column="first"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="second"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="third"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className="col-xs-3 column-value"
                data-column="fourth"
                data-value="3"
                style={{
                  padding: "1px",
                }}
              >
                <div className="row hard-center column-select-option"></div>
              </div>
              <div
                className={`col-xs-12 column-value text-center text-lg`}
                style={{
                  marginTop: "5px",
                  height: "15px",
                  padding: "0px 2px",
                }}
              >
                25/25/25/25%
              </div>
            </button>
          </div>

          
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingColumn;
