import React from "react";
import PropTypes from "prop-types";

const BroadcastsListItemOptions = (props) => {
  const broadcastLink =
    "/websites/" +
    props.broadcast.website_id +
    "/broadcasts/" +
    props.broadcast.id;

  return (
    <React.Fragment>
      <div className="btn-group smooth">
        <button
          type="button"
          className="btn dropdown-toggle toolbar-btn field-title btn-clean"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{
            border: "1px solid #eaf1fa !important",
            marginTop: "-5px",
            background: "#fff",
          }}
        >
          <span className="field-title">Options</span>{" "}
          <span className="caret"></span>
        </button>
        <ul className="!rounded-lg !border-none !shadow-lg dropdown-menu">
          <li>
            <a className="" href={broadcastLink + "/edit"}>
              Edit
            </a>
          </li>
          <li>
            <a className="" href={broadcastLink}>
              Reports
            </a>
          </li>
          <li>
            <a
              className=""
              data-toggle="modal"
              data-target={"#Broadcast" + props.broadcast.id + "GroupsModal"}
              style={{ cursor: "pointer" }}
            >
              Groups
            </a>
          </li>
          <li>
            <a
              className=""
              href={broadcastLink + "/reset"}
              data-confirm="Are you sure you want to set broadcast stat calculations to 0?

              IMPORTANT: After resetting to 0, any visitor engagements that had been previously tracked before the reset, will NOT count towards the new calculations."
            >
              Reset calculations
            </a>
          </li>
          <li>
            <a
              href="#"
              className=""
              data-toggle="modal"
              data-target={"#duplicateBroadcast" + props.broadcast.id + "Modal"}
            >
              Duplicate
            </a>
          </li>
          <li>
            <a
              className=""
              href={broadcastLink}
              data-confirm="Are you sure you want to
              delete this broadcast campaign? All stats will be lost and it will
              stop displaying"
              data-method="delete"
              rel="nofollow"
            >
              Delete
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default BroadcastsListItemOptions;
