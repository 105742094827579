import React, { useState, useEffect, useRef } from "react";
import useAreas from "./useAreas";

const NewArea = (props) => {
  const { Areas } = useAreas();
  const areaName = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    Areas.create(props.website, {
      name: areaName.current.value,
    }, (area) => {
      let newWebsiteAreas = { ...props.websiteAreas };
      newWebsiteAreas[area.id] = area;
      props.setWebsiteAreas(newWebsiteAreas);
      areaName.current.value = '';
      $("#areaModal").modal("hide");
    })
  };

  useEffect(() => {
    $("#areaModal").off("shown.bs.modal").on("shown.bs.modal", function () {
      areaName.current.focus();
    });
  }, []);

  return (
    <React.Fragment>
      <div
        className={`${
          props.broadcast?.quick == false ? "col-xs-4" : "col-xs-6"
        } center area mt-[22px]`}
      >
        <div
          className="row hard-center card-new area-new"
          data-toggle="modal"
          data-target="#areaModal"
        >
          New Area +
        </div>
      </div>
      <div className="modal" id="areaModal" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                dataDismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title font-bold px-5">
                New Area
              </h4>
            </div>
            <div className="modal-body">
              <div className="row hard-center some-vertical-space smooth mt-0">
                <form onSubmit={handleSubmit} remote="true">
                  <input type="hidden" name="page" value="cta"></input>

                  <div className="col-xs-12 some-vertical-space" style={{marginTop: '0px'}}>
                    <input
                      ref={areaName}
                      type="text"
                      name="area[name]"
                      id="area_name"
                      placeholder="Area name"
                      required="required"
                      className="w-full text-2xl border-solid border-[1px] border-slate-200 rounded-md text-black p-4"
                    />
                  </div>

                  <div className="col-xs-12">
                    <button
                      type="submit"
                      className="new-cta w-full bg-blue-600 border-none text-white text-2xl font-semibold rounded-md p-4"
                    >
                      Create Area +
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewArea;
