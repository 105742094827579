import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const ElementIcon = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );

  const iconName = () => {
    let selectedIcon = element.options["fa-icon"];

    return selectedIcon
      .replace("fas ", "")
      .replace("fab ", "")
      .replace("far ", "");
  };

  const iconPrefix = () => {
    let selectedIcon = element.options["fa-icon"];

    return selectedIcon.replace(` ${iconName()}`, "");
  };

  return (
    <React.Fragment>
      <div className={classes}>
        <FontAwesomeIcon icon={[iconPrefix(), iconName()]} />
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-icon-element ";

    let alignmentClass = "cf-outer-center";

    if (options["cf-alignment"]) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        alignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentClass = "cf-outer-right";
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element) => {
    let options = { ...element.options };

    let alignmentMargin;
    if (
      options["divider-orientation"] == "vertical" &&
      options["cf-alignment"]
    ) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentMargin = "margin-left: 0px !important;";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentMargin = "margin-right: 0px !important;";
      }
    }

    let fontSize;
    if (options["icon-font-size"] && options["icon-font-size"] !== "theme") {
      fontSize = options["icon-font-size"];
    }

    let lineHeight;
    if (options["icon-font-line-height"]) {
      lineHeight = options["icon-font-line-height"];
    }

    let fontColor;
    if (options["icon-font-color"] && options["icon-font-color"] !== "theme") {
      fontColor = options["icon-font-color"];
    }

    let borderRadius = "";
    if (options["icon-border-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["icon-border-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["icon-border-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["icon-border-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["icon-border-radius-" + side]) == false
              ? options["icon-border-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let boxShadow;
    let boxShadowHover;

    if (options["icon-border-shadow"] == "None") {
      boxShadow = "none";
    }

    if (options["icon-border-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["icon-border-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["icon-border-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["icon-border-shadow-horizontal-distance"]
          ? `${element.options["icon-border-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["icon-border-shadow-vertical-distance"]
          ? `${element.options["icon-border-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["icon-border-shadow-blur-radius"]
          ? `${element.options["icon-border-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["icon-border-shadow-spread-radius"]
          ? `${element.options["icon-border-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["icon-border-shadow-color"]
          ? element.options["icon-border-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      boxShadowHover =
        boxShadow +
        (element.options["icon-border-hover-shadow-color"]
          ? element.options["icon-border-hover-shadow-color"]
          : element.options["icon-border-shadow-color"]
          ? element.options["icon-border-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      boxShadow +=
        element.options["icon-border-shadow-inset"] == "true" ? "inset" : "";
      boxShadowHover +=
        element.options["icon-border-shadow-inset"] == "true" ? "inset" : "";
    }

    let maxWidth;
    if (
      options["icon-max-width-style"] == "custom" &&
      options["icon-max-width-value"]
    ) {
      maxWidth = `${options["icon-max-width-value"]}px !important;`;
    } else if (options["icon-max-width-style"] == "full") {
      maxWidth = "100% !important;";
    }

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-icon-element {
        text-align: center !important;
        ${fontSize ? `font-size: ${fontSize}px;` : ''}
        ${lineHeight ? `line-height: ${lineHeight}px;` : ''}
        ${fontColor ? `color: ${fontColor};` : ''}
        ${maxWidth ? `max-width: ${maxWidth}; display: block;` : 'display: inline-block;'}
        ${options["icon-border-style"] ? 'border-style: ' + options["icon-border-style"] + ' !important;' : ''}
        ${options["icon-border-style"] !== "" && _.isNil(options["icon-border-width"]) == false ? 'border-width: ' + options["icon-border-width"] + 'px !important;' : ''}
        ${options["icon-border-style"] !== "" && _.isNil(options["icon-border-width-left"]) == false ? 'border-left-width: ' + options["icon-border-width-left"] + 'px !important;' : _.isNil(options["icon-border-width"]) == false ? 'border-left-width: ' + options["icon-border-width"] + 'px !important;' : ''}
        ${options["icon-border-style"] !== "" && _.isNil(options["icon-border-width-right"]) == false ? 'border-right-width: ' + options["icon-border-width-right"] + 'px !important;' : _.isNil(options["icon-border-width"]) == false ? 'border-right-width: ' + options["icon-border-width"] + 'px !important;' : ''}
        ${options["icon-border-style"] !== "" && _.isNil(options["icon-border-width-top"]) == false ? 'border-top-width: ' + options["icon-border-width-top"] + 'px !important;' : _.isNil(options["icon-border-width"]) == false ? 'border-top-width: ' + options["icon-border-width"] + 'px !important;' : ''}
        ${options["icon-border-style"] !== "" && _.isNil(options["icon-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["icon-border-width-bottom"] + 'px !important;' : _.isNil(options["icon-border-width"]) == false ? 'border-bottom-width: ' + options["icon-border-width"] + 'px !important;' : ''}
        ${options["icon-border-style"] !== "" && options["icon-border-color"] ? 'border-color: ' + options["icon-border-color"] + ' !important;' : options["icon-border-color"] ? 'border-color: ' + options["icon-border-color"] + ' !important;' : ''}
        ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
        ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-icon-element:hover {
        ${options["icon-border-hover-border-color"] ? 'border-color: ' + options["icon-border-hover-border-color"] + ' !important;' : options["icon-border-color"] ? 'border-color: ' +  options["icon-border-color"] + ' !important;' : ''}
        ${boxShadowHover ? 'box-shadow: ' + boxShadowHover + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-icon-element .svg-inline--fa {
        display: var(--fa-display, inline-block);
        height: 1em;
        overflow: visible;
        ${fontSize ? `font-size: ${fontSize}px;` : ""}
      }
`;

    let fontSizeMobile;
    if (
      options["icon-font-size-mobile"] &&
      options["icon-font-size-mobile"] !== "theme"
    ) {
      fontSizeMobile = options["icon-font-size-mobile"];
    }

    let lineHeightMobile;
    if (options["icon-font-line-height-mobile"]) {
      lineHeightMobile = options["icon-font-line-height-mobile"];
    }

    let fontColorMobile;
    if (
      options["icon-font-color-mobile"] &&
      options["icon-font-color-mobile"] !== "theme"
    ) {
      fontColorMobile = options["icon-font-color-mobile"];
    }

    let maxWidthMobile;
    if (
      options["icon-max-width-style-mobile"] == "custom" &&
      options["icon-max-width-value-mobile"]
    ) {
      maxWidthMobile = `${options["icon-max-width-value-mobile"]}px !important;`;
    } else if (options["icon-max-width-style-mobile"] == "full") {
      maxWidthMobile = "100% !important;";
    }

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-icon-element {
        ${fontSizeMobile ? `font-size: ${fontSizeMobile}px;` : ''}
        ${lineHeightMobile ? `line-height: ${lineHeightMobile}px;` : ''}
        ${fontColorMobile ? `color: ${fontColorMobile};` : ''}
        ${maxWidthMobile ? `max-width: ${maxWidthMobile}; display: block;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-icon-element .svg-inline--fa {
        ${fontSizeMobile ? `font-size: ${fontSizeMobile}px;` : ''}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementIcon;
