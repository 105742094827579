var initialize = function (broadcast, standalone_cta) {
  $(".broadcast-name-form").submit(function (e) {
    var url =
      window.location.origin +
      "/websites/" +
      broadcast.website_id +
      "/broadcasts/" +
      broadcast.id;

    $.ajax({
      type: "PUT",
      url: url,
      data: {
        broadcast: {
          name: $(this).find("input").first().val(),
        },
      },
      success: function (data) {
        var name = $(".broadcast-name-form").find("input").first().val();
        $(".broadcast-name").html(name);
        $(".broadcast-name-form-container").hide();
        $(".broadcast-name-container").show();
      },
    });
    return false;
  });

  $(".broadcast-name-container span").click(function () {
    $(".broadcast-name-form-container").show();
    $(".broadcast-name-container").hide();
  });

  var broadcasts_cta_select = ".new_broadcasts_cta select";
  $(broadcasts_cta_select)
    .find('option[value=""]')
    .attr("disabled", "disabled");

  $(".broadcasts-cta").each(function (i, broadcasts_cta_div) {
    $(broadcasts_cta_select)
      .find('option[value="' + $(broadcasts_cta_div).attr("data-cta-id") + '"]')
      .hide();
  });

  var container = $(
    '.campaign[data-type="Broadcast"][data-id="' + broadcast.id + '"]'
  );

  broadcasts.builder({
    container: container,
    contactable_type: "Broadcast",
    contactable_id: broadcast.id,
    broadcast: broadcast,
    existing_conditions: $.map(website.conditions, function (condition) {
      if (
        condition.contactable_type == "Broadcast" &&
        condition.contactable_id == broadcast.id
      )
        return condition;
    }),
    campaign_type: "broadcast",
    cta: standalone_cta,
  });
};

export default initialize;
