export const isValuePresent = (value) => {
  return _.isNil(value) === false && value !== "";
};

export const parsedSpacingValue = (value) => {
  if (!isValuePresent(value)) return;
  if (String(value).includes('%')) return value;
  return value + 'px';
};

export const formatSpacingValueInput = (value) => {
  let formattedValue = value.replace(/[^0-9+%-]+/gi, "");

  if (typeof formattedValue === 'string' && isValuePresent(formattedValue)) {
    formattedValue = String(parseInt(formattedValue));
  }

  // Ensure only one percentage sign at the end
  const percentageIndex = value.indexOf('%');
  if (percentageIndex !== -1 && percentageIndex !== formattedValue.length - 1) {
    formattedValue = formattedValue.replace(/%/g, '') + '%';
  }

  // Ensure only one negative sign at the beginning
  const negativeIndex = formattedValue.indexOf('-');
  if (negativeIndex > 0) {
    formattedValue = formattedValue.replace(/-/g, '');
    formattedValue = '-' + formattedValue;
  }

  return formattedValue;
};

export const isSectionColumnsMobileSorted = (section) => {
  // sortableJS sets all values even if the section only has 2 columns
  // check if every value is present and return true if so

  let column_positions = ['first', 'second', 'third', 'fourth'];

  let is_mobile_sorted = column_positions.map(a => {
    let position = _.get(section, `options.structure.cf-column-${a}-mobile-position`);
    return isValuePresent(position)
  }).every(Boolean);

  return is_mobile_sorted;
};

export const getWebsiteIntegration = (website, integration_name) => {
  return Object.values(website?.integrations).filter(
    (i) => i.integration_type == integration_name && i.status == true
  )[0]
};
