import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeProgress = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Progress Bars" open={false}>
        <VariantBuilderSetting
          object={variant}
          label="Message font"
          setting_name="[options][progress-text-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["progress-text-font"]
              ? variant.options["progress-text-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][progress-text-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["progress-text-font-weight"]
              ? variant.options["progress-text-font-weight"]
              : "400"
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Message font size"
          setting_name="[options][progress-text-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["progress-text-size"]
              ? variant.options["progress-text-size"]
              : undefined
          }
          placeholder="16"
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Message font size"
          setting_name="[options][progress-text-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["progress-text-size-mobile"]
              ? variant.options["progress-text-size-mobile"]
              : undefined
          }
          placeholder="16"
          min={0}
          max={100}
          range_step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          object_type="variants"
          setting_name="[options][progress-text-color]"
          setting_type="color_v2"
          value={
            variant.options["progress-text-color"]
              ? variant.options["progress-text-color"]
              : undefined
          }
          placeholder="#fff"
          label="Message text color"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          object_type="variants"
          setting_name="[options][progress-outer-background-color]"
          setting_type="color_v2"
          value={
            variant.options["progress-outer-background-color"]
              ? variant.options["progress-outer-background-color"]
              : undefined
          }
          placeholder={"#ecf0f6"}
          label="Bar background color"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          object_type="variants"
          setting_name="[options][progress-inner-background-color]"
          setting_type="color_v2"
          value={
            variant.options["progress-inner-background-color"]
              ? variant.options["progress-inner-background-color"]
              : undefined
          }
          placeholder="#0D42E4"
          label="Bar meter color"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Border Style"
          setting_name="[options][progress-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["progress-border-style"]
              ? variant.options["progress-border-style"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Border color"
          setting_name="[options][progress-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["progress-border-color"]
              ? variant.options["progress-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={variant.options["progress-border-style"] !== "none"}
          show_reset={true}
        />

        {variant.options["progress-border-style"] !== "none" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Border Width
                </div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][progress-border-width-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={variant}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="variants"
                      value={
                        _.isNil(
                          variant.options[`progress-border-width-${side}`]
                        ) == false
                          ? variant.options[`progress-border-width-${side}`]
                          : "2"
                      }
                      placeholder="0px"
                      col="3"
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Corner Radius"
          setting_name="[options][progress-outer-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="variants"
          value={
            variant.options["progress-outer-radius"]
              ? variant.options["progress-outer-radius"]
              : "cf-slightly-rounded"
          }
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][progress-outer-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(variant.options[`progress-outer-radius-${side}`]) ==
                    false
                      ? variant.options[`progress-outer-radius-${side}`]
                      : undefined
                  }
                  condition={
                    variant.options["progress-outer-radius"] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeProgress;
