import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "./VariantBuilderContext";
import useProducts from "../products/useProducts";
import useProductVariants from "../product_variants/useProductVariants";
import useFields from "../fields/useFields";
import useContentToggle from "../content_toggles/useContentToggle";
import useContentLists from "../content_lists/useContentLists";
import useFieldOptions from "../field_options/useFieldOptions";
import htmlToLabel from "../../utils/htmlToLabel";

const VariantBuilderSettingItem = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [modalVisibility, setModalVisibility] = useState(false);
  let hookToUse;

  if (props.object_type == "fields") {
    const { Fields } = useFields();
    hookToUse = Fields;
  }

  if (props.object_type == "content_list_items") {
    const { ContentLists } = useContentLists();
    hookToUse = ContentLists;
  }

  if (props.object_type == "field_options") {
    const { FieldOptions } = useFieldOptions();
    hookToUse = FieldOptions;
  }

  if (props.object_type == "content_toggle_items") {
    const { ContentToggle } = useContentToggle();
    hookToUse = ContentToggle;
  }

  if (props.object_type == "products") {
    const { Products } = useProducts();
    hookToUse = Products;
  }

  if (props.object_type == "product_variants") {
    const { ProductVariants } = useProductVariants();
    hookToUse = ProductVariants;
  }

  const modalId = `${props.object_type}-${props.object_id}-modal-${props.setting_name}`;

  useEffect(() => {
    let modal = document.querySelector(
      '.modal[data-name="' +
        props.setting_name +
        '"][object_type="' +
        props.object_type +
        '"][object_id="' +
        props.object_id +
        '"]'
    );
    if (modalVisibility == true) {
      $(modal).modal("show");
      $(modal).addClass("in").css("display", "block");
      $("body").addClass("modal-open");

      if (
        ($(modal).attr("object_type") == "field_options" ||
          $(modal).attr("object_type") == "content_list_items") &&
        typeof props.callback === "function"
      ) {
        props.callback();
      }

      $(modal)
        .unbind("hidden.bs.modal")
        .bind("hidden.bs.modal", function (e) {
          setModalVisibility(false);
        });

      modal.onclick = function (e) {
        if (e.target == modal) {
          if (
            $(modal).is(":visible") &&
            $(modal).find(".modal:visible").length == 0
          ) {
            setModalVisibility(false);
          }
        }
      };
    } else {
      $(modal).removeClass("in").css("display", "none");

      if ($(".modal.in").size() < 1) {
        $("body").removeClass("modal-open");
      }
    }
  });

  return (
    <React.Fragment>
      <div className="row hard-center">
        <div
          className={`col-xs-9 item-trigger`}
          data-object_type={props.object_type}
          data-object_id={props.object.id}
          style={{
            paddingRight: "0px",
          }}
          onClick={() => {
            setModalVisibility(true);
          }}
        >
          <p className="light">
            <span className="preset-label">{props.title}</span>
            <span
              className="glyphicon glyphicon-pencil"
              aria-hidden="true"
            ></span>
          </p>
        </div>
        <div className="col-xs-3 item-container left">
          <div
            onClick={(e) => {
              hookToUse.remove(props.object);

              if (props.callback) {
                props.callback(props.object);
              }
            }}
            className="item-delete"
            style={{
              float: "left",
              right: "0",
              left: "0",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <span
              className="glyphicon glyphicon-remove"
              aria-hidden="true"
            ></span>
          </div>
        </div>
        <div
          className="modal"
          id={modalId}
          data-name={props.setting_name}
          object_type={props.object_type}
          object_id={props.object_id}
          role="dialog"
          aria-labelledby="myModalLabel"
          data-backdrop="false"
          style={{
            background: "rgb(0, 0, 0, 0.21)",
          }}
        >
          <div className="modal-dialog !mt-44 !w-[690px]" role="document">
            <div className="modal-content bg-white dark:bg-slate-950">
              <div className="modal-header border-slate-200 dark:border-slate-800">
                <button
                  type="button"
                  className="close dark:text-white"
                  onClick={() => {
                    setModalVisibility(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4
                  className="text-2xl semibold text-black dark:text-white"
                >
                  {htmlToLabel(props.title)}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row hard-center">{props.children}</div>
              </div>
              <div className="modal-footer !border-slate-200 dark:!border-slate-800">
                <button
                  type="button"
                  className="text-xl semibold py-2 px-4 bg-white dark:bg-slate-950 text-black dark:text-white border-solid border-[1px] border-black dark:border-white rounded-lg"
                  onClick={() => {
                    setModalVisibility(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingItem;
