import React from "react";
import PropTypes from "prop-types";
import CtaGroupsSmallListItem from "../cta_groups/CtaGroupsSmallListItem";

const CtaGroupsSmallList = (props) => {
  let groups = props.cta.groups.slice(0, props.trim ? 3 : 6);

  return (
    <React.Fragment>
      <div
        data-toggle="modal"
        data-target={"#Cta" + props.cta.id + "GroupsModal"}
        data-cta-id={props.cta.id}
        className={
          "CtaGroupsSmallList row hard-center flex " +
          (props.light == true ? "light" : "")
        }
      >
        {groups.map((group) => {
          return (
            <CtaGroupsSmallListItem
              key={"cta-" + props.cta.id + "-group-" + group.id}
              group={group}
              {...props}
            />
          );
        })}
        <div
          className={
            "cta-group-small-add smooth bold " +
            (props.light == true ? "light" : "")
          }
          title="Manage Groups"
        >
          +
        </div>
      </div>
    </React.Fragment>
  );
};

export default CtaGroupsSmallList;
