var UnpublishedTour = () => {
  if (window.tour !== undefined) {
    window.tour.hide();
  }
  window.tooltips_ran = true;

  window.tour = new Shepherd.Tour({
    defaults: {
      classes: "shepherd-theme-arrows shepherd-theme-square smooth",
    },
  });
  tour.addStep("editor", {
    title: "Don't forget to publish!",
    text:
      "Click back to the builder to publish <br> the saved changes you're previewing.",
    attachTo: "#builder-button bottom",
    advanceOn: { selector: "#builder-button", event: "click" },
    showCancelLink: true,
    buttons: [
      {
        action() {
          return document.querySelector("#builder-button").click();
        },
        text: "← Back to builder",
      },
    ],
  });
  tour.start();
};

export default UnpublishedTour;
