import React, { useEffect } from "react";
import PropTypes from "prop-types";
import noUiSlider from "nouislider";
import wNumb from "wnumb";
import _, { isEqual, xor } from "lodash";

const FieldRange = (props) => {
  let variant = props.variant;
  let field = props.field;
  let element = props.element;
  let options = element.options;
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    field
  );

  let field_options = Object.values(field.field_options).filter(
    (field_option) =>
      field_option.field_id == field.id && field_option.toBeDeleted !== true
  );

  useEffect(() => {
    let range = document.querySelector(
      `#builder .cf-field-range-slider[data-id="${field.id}"]`
    );

    if (range.noUiSlider) {
      range.noUiSlider.destroy();
    }

    var rangeStartValue = 0;
    var rangeValues = { min: 0, max: 1000 };
    var rangeStep = field.options["increment"]
      ? parseInt(field.options["increment"])
      : 1;
    var rangePips = false;
    var rangeSnap = false;
    var numberType = field.options["range-value-type"]
      ? field.options["range-value-type"]
      : "integer";

    // range input using min/max and step
    if (field.options["range-type"] !== "increments") {
      var rangeSliderMinimum =
        _.isNil(field.options["minimum"]) == false
          ? parseInt(field.options["minimum"])
          : 0;
      var rangeSliderMaximum =
        _.isNil(field.options["maximum"]) == false
          ? parseInt(field.options["maximum"])
          : 1000;
      rangeStartValue = rangeSliderMaximum * 0.25;

      rangeStartValue = [rangeStartValue];
      if (field.options["multiple-handles"] == "true") {
        rangeStartValue.push(rangeSliderMaximum * 0.75);
      }

      rangeValues = {
        min: rangeSliderMinimum,
        max: rangeSliderMaximum,
      };

      rangePips = false;

      if (field.options["range-labels"] == "true") {
        rangePips = {
          mode: "range",
          values: [rangeSliderMinimum, rangeSliderMaximum],
          density: 100,
        };

        var pipFormats = {};

        if (numberType == "currency") {
          rangePips["format"] = wNumb({
            decimals: 2,
            prefix: props.website["currency_character"],
          });
        }

        if (numberType == "percentage") {
          rangePips["format"] = wNumb({
            decimals: 0,
            suffix: "%",
          });
        }

        if (field.options["range-label-minimum"]) {
          pipFormats[rangeSliderMinimum] = field.options["range-label-minimum"];
        }

        if (field.options["range-label-maximum"]) {
          pipFormats[rangeSliderMaximum] = field.options["range-label-maximum"];
        }

        if (Object.keys(pipFormats).length > 0) {
          rangePips["format"] = {
            to: function (a) {
              return pipFormats[a];
            },
          };
        }
      }
    }

    // range input using field options
    if (field.options["range-type"] == "increments") {
      rangeSnap =
        field.options["limit-to-increments"] !== "false" ? true : false;

      if (rangeSnap == true) {
        rangeStep = undefined;
      }

      var values = [];
      if (field_options) {
        Object.values(field_options).forEach(function (field_option) {
          if (window.isNumeric(field_option.value)) {
            values.push(parseInt(field_option.value));
          }
        });
      }

      if (values) {
        var sortedValues = values.sort(function (a, b) {
          return a - b;
        });
        rangeStartValue = sortedValues[0] || rangeStartValue;
        rangeSliderMaximum = sortedValues[sortedValues.length - 1] || 1000;

        rangeValues = {
          min: rangeStartValue,
          max: sortedValues[sortedValues.length - 1] || 1000,
        };

        var numberOfPipsDividedBy100 = 100 / sortedValues.length;
        sortedValues
          .filter(function (option_value) {
            if (
              option_value !== rangeStartValue &&
              option_value !== rangeSliderMaximum
            ) {
              return option_value;
            }
          })
          .forEach(function (option_value, i) {
            var percentage = numberOfPipsDividedBy100 * (i + 1);
            rangeValues[percentage + "%"] = option_value;
          });

        var density = 100;
        if (sortedValues.length > 0) {
          density = sortedValues.length;
        }

        rangePips = {
          mode: "values",
          values: values,
          density: density,
        };

        if (numberType == "currency") {
          rangePips["format"] = wNumb({
            decimals: 2,
            prefix: props.website["currency_character"],
          });
        }

        if (numberType == "percentage") {
          rangePips["format"] = wNumb({
            decimals: 0,
            suffix: "%",
          });
        }
      }
    }

    noUiSlider.create(range, {
      tooltips: true,
      start: rangeStartValue,
      connect: true,
      range: rangeValues,
      step: rangeStep,
      pips: rangePips,
      snap: rangeSnap,
      format: {
        to: function (value) {
          return parseInt(value).toFixed();
        },
        from: function (value) {
          return parseInt(value).toFixed();
        },
      },
    });
  }, [element, field]);
  return (
    <React.Fragment>
      <div
        data-id={field.id}
        data-field={field.slug}
        data-pos={field.position ? field.position : ""}
        className="cf-form-field cf-custom-field"
        data-visible={field.field_type !== "hidden" ? "true" : ""}
        data-multi-select=""
        style={{
          order: field.position,
        }}
      >
        <div
          data-id={field.id}
          className={"contact-custom-field cf-field-range cf-col-xs-12"}
          data-labels-visible={
            field.options["range-type"] == "increments" ||
            field.options["range-labels"] == "true"
              ? "true"
              : "false"
          }
          data-handle-shape={
            options["field-handle-shape"]
              ? options["field-handle-shape"]
              : variant.options["field-handle-shape"]
              ? variant.options["field-handle-shape"]
              : undefined
          }
        >
          <input
            name={"contact[extra][" + field.slug + "]"}
            id={"contact_extra_" + field.slug}
            type="hidden"
            defaultValue={field.value ? field.value : ""}
            placeholder={field.placeholder ? field.placeholder : ""}
            style={{ marginBottom: "0px" }}
          ></input>
          <div
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              color: element.options["label-color"]
                ? element.options["label-color"]
                : "",
              fontFamily: "inherit",
            }}
          >
            {field.label && props.hide_label !== true && (
              <p
                className="cf-label"
                style={{
                  marginBottom: "4px",
                  color: element.options["label-color"]
                    ? element.options["label-color"]
                    : "",
                }}
              >
                {field.label}
              </p>
            )}
            <div data-id={field.id} className="cf-field-range-slider"></div>
          </div>
        </div>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, element, field) => {
    let options = element.options;

    // prettier-ignore
    const desktopStyles = 
    
`#cta_${cta.id} .cf-field-range-slider[data-id="${field.id}"] {
  
}`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-field-range-slider[data-id="${field.id}"] {
  
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default FieldRange;
