var cta_groups = {};

import initialize, {} from 'packs/cta_groups/initialize.js';
cta_groups.initialize = initialize;

document.addEventListener('turbolinks:load', function() {
	$('.cta-group').each(function(i, cta_group_div) {
		cta_groups.initialize(cta_group_div)
	})
});

export default cta_groups;