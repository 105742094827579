var pricing_table = function () {
  $('.feature-popover[data-toggle="popover"]').popover({
    html: true,
    trigger: "hover",
  });

  window.contactUsButton = function () {
    function loadMeetingPopup() {
      convertflow.load({
        cta_id: 123204,
        callback: function(cta, variant, embed) {
          convertflow.trigger({
            cta_id: 123204
          });
        }
      });
    }

    if (!window.convertflow) {
      $.getScript('https://js.convertflow.co/production/websites/3.js', function() {
        window.addEventListener("cfReady", function(event) {
          loadMeetingPopup();
        });
      });
    } else {
      convertflow.trigger({
        cta_id: 123204
      });
    }
  }

  // 2024 pricing plans

  $(["2024_pages", "2024_pages_annual", "2024_shopify_pages", "2024_core", "2024_core_annual", "2024_shopify_core", "2024_funnels", "2024_funnels_annual", "2024_shopify_funnels", "2024_pro", "2024_pro_annual", "2024_shopify_pro", "2024_plus", "2024_plus_annual", "2024_shopify_plus", "2024_advanced", "2024_advanced_annual", "2024_shopify_advanced", "2024_scale", "2024_scale_annual", "2024_shopify_scale"]).each(function (
    i,
    plan_type
  ) {
    let planTypeSelector = '.pricing-box-outer[data-plan="' + plan_type + '"]';

    $(planTypeSelector).each(function (i, plan_div) {
      const usageOuterDiv = $(plan_div).find(".plan-usage-select-outer");
      const usageOuterDivContent =
        'For <select class="field-control text-field plan-usage-select"></select> monthly views';
      const planOuterDiv = $(plan_div).find(".plan-button");
      const priceLabel = $(plan_div).find(".plan-price");
      const buttonWrapper = $(plan_div).find(".plan-button");
      const plan = plans[plan_type];
      const viewsPlan = viewsPlans[plan.views_plan];
      const isAnnualPlan = plan_type.indexOf('annual') > -1;

      // build views volume dropdown
      $(usageOuterDiv).html(usageOuterDivContent);

      const usageSelect = $(usageOuterDiv).find("select");
      const maxQuantity =
        plan_type.includes("pages") && views_plan_quantity < 10
          ? 10 
          : plan_type.includes("core") && views_plan_quantity < 20
          ? 10
          : plan_type.includes("pro") && views_plan_quantity < 30
          ? 10
          : plan_type.includes("plus") && views_plan_quantity < 30
          ? 25 : 101;

      [...Array(maxQuantity).keys()]
        .filter(
          (quantity) =>
          quantity >= plan.views / 10000
        )
        .forEach((quantity) => {
          const quantityOffset = plan.views / 10000;
          const quantityLabel = (quantity * 10000).toLocaleString();

          let quantityValue = quantity - quantityOffset;
          if (quantityValue < 0) {
            quantityValue = "";
          }

          // select the current plan
          const optionSelected = current_plan.stripe_plan == plan.stripe_plan && plan.views / 10000 + (views_plan_quantity - quantityOffset) == quantityValue;

          const optionTag = `<option value="${quantityValue}" ${
            optionSelected ? "selected" : ""
          }>${quantityLabel}</option>`;

          $(usageSelect).append(optionTag);
        });

      $(usageSelect).on("change", function () {

        const selectedValue = $(usageSelect).val();
        const quantity =
          selectedValue && selectedValue !== "contact"
            ? parseInt(selectedValue)
            : 0;

        // visualize price
        let price = plan.price;

        if (plan_type.includes('annual')) {
          price = Math.ceil(price / 12);
        }

        const annual_commit_discount =
          on_annual_paid_monthly == true;

        if (annual_commit_discount == true) {
          price = Math.ceil(price - price * 0.0833);
        }

        price = price + quantity * viewsPlan.price;

        const downgrade_inaccessible_on_contract =
          (annual_commit_discount && price < current_plan.price + views_plan_quantity * 20) || ['2024_scale', '2024_enterprise'].includes(current_plan.plan_type);

        $(priceLabel).html(price.toLocaleString());

        // build button
        let buttonLinkTag = document.createElement("a");
        let buttonLink = `${root_url}subscriptions/new?stripe_plan=${plan_type}&account_id=${subscription.account_id}`;

        if (
          ((free_plan && !trial) || (trial && !free_plan)) &&
          on_annual_paid_monthly !== true && !isAnnualPlan
        ) {
          buttonLink += "&trial=true";
        }

        if (quantity >= 0) {
          buttonLink += `&views_plan_quantity=${quantity}`;
        }

        if (on_annual_paid_monthly == true) {
          buttonLink += "&annual_paid_monthly=true";
        }

        buttonLinkTag.href = buttonLink;

        let buttonTag = document.createElement("button");
        buttonTag.className = "btn btn-block pricing-button";

        const buttonText =
          free_plan && !trial && !isAnnualPlan
            ? "Start 14 day free trial"
            : plan_type == subscription.stripe_plan &&
              quantity == views_plan_quantity &&
              !downgrade_inaccessible_on_contract
            ? "Current Plan"
            : downgrade_inaccessible_on_contract
            ? "Account ineligible"
            : "Switch to this plan";

        buttonTag.innerHTML = buttonText;

        const backgroundColor =
          (free_plan && plan_type == "2024_pages") ||
          (current_plan.stripe_plan == "2024_pages" && plan_type == "2024_core") ||
          (current_plan.stripe_plan == "2024_core" && plan_type == "2024_pro") ||
          (current_plan.stripe_plan == "2024_pro" && plan_type == "2024_plus") ||
          (current_plan.stripe_plan == "2024_pro" && plan_type == "2024_advanced") ||
          (current_plan.stripe_plan == "2024_plus" && plan_type == "2024_scale") ||
          (current_plan.stripe_plan == "2024_advanced" && plan_type == "2024_scale") ||
            (current_plan.stripe_plan == "2024_scale" && plan_type == "2024_enterprise")
            ? "#003fff"
            : downgrade_inaccessible_on_contract
            ? "rgb(126, 147, 165)"
            : "";
        $(buttonTag).css("background", backgroundColor);

        // disable button if on annual contract and plan would be downgrade
        if (downgrade_inaccessible_on_contract || buttonText == "Current Plan") {
          buttonLinkTag.href = "#";
          $(buttonTag).addClass("disabled");
        }

        // show contact us button if interested in high traffic
        if (selectedValue == "contact" || (plan_type.includes('scale') && ['free', '2024_pages', '2024_funnels', '2024_core', '2024_pages'].includes(current_plan.plan_type))) {
          buttonTag.innerHTML = "Contact Sales";
          buttonLinkTag.href = "#";

          $(buttonLinkTag)
            .off("click")
            .on("click", function () {
              contactUsButton();
            });
        }

        $(buttonLinkTag).append(buttonTag);
        $(planOuterDiv).html(buttonLinkTag);

        if ((plan.annual_plan || isAnnualPlan) && !on_annual_paid_monthly && !downgrade_inaccessible_on_contract) {
          let annualLink = `${root_url}subscriptions/new?stripe_plan=${plan.annual_plan}&account_id=${subscription.account_id}`;

          if (quantity >= 0) {
            annualLink += `&views_plan_quantity=${quantity}`;
          }

          if (current_plan.stripe_plan == plan.annual_plan) {
            $(planOuterDiv).append(
              `<p class="light center smooth" style="margin-bottom: 8px; font-size: 13px !important; margin-top: 15px !important; color: #2bc3a6 !important;">
                You're saving 25% by paying yearly 🙌
              </p>`
            )
          } else if (isAnnualPlan) {
            const monthlyPlan = plans[plan_type.replace('_annual', '')];

            if (monthlyPlan) {
              $(planOuterDiv).append(
                `<p class="light center smooth" style="margin-bottom: 8px; font-size: 13px !important; margin-top: 15px !important; color: #2bc3a6 !important;">
                  Saving 25% with annual subscription
                </p>`
              )
            }
          } else {
            $(planOuterDiv).append(
              `<p class="light center smooth" style="margin-bottom: 8px; font-size: 13px !important; margin-top: 15px !important;">
                <a style="color: #2bc3a6 !important;" href="#">
                  25% discount with annual →
                </a>
              </p>`
            )
            $(planOuterDiv).find('a').on('click', function (e) {
              $('a[href="#monthly"]').trigger('click');
            });
          }
        }
      });

      $(usageSelect).trigger("change");
    });
  });

  // 2023 pricing plans

  $(["2023_build", "2023_launch", "2023_pro", "2023_scale"]).each(function (
    i,
    plan_type
  ) {
    let planTypeSelector = '.pricing-box-outer[data-plan="' + plan_type + '"]';

    $(planTypeSelector).each(function (i, plan_div) {
      const usageOuterDiv = $(plan_div).find(".plan-usage-select-outer");
      const usageOuterDivContent =
        'For <select class="field-control text-field plan-usage-select"></select> monthly views';
      const planOuterDiv = $(plan_div).find(".plan-button");
      const priceLabel = $(plan_div).find(".plan-price");
      const buttonWrapper = $(plan_div).find(".plan-button");
      const plan = plans[plan_type];
      const viewsPlan = viewsPlans[plan.views_plan];

      // build views volume dropdown
      $(usageOuterDiv).html(usageOuterDivContent);
      const usageSelect = $(usageOuterDiv).find("select");

      [...Array(60).keys()]
        .filter(
          (quantity) =>
          quantity >= plan.views / 10000
        )
        .forEach((quantity) => {
          const quantityOffset = plan.views / 10000;
          const quantityLabel = (quantity * 10000).toLocaleString();

          let quantityValue = quantity - quantityOffset;
          if (quantityValue < 0) {
            quantityValue = "";
          }

          // select the current plan
          const optionSelected = current_plan.stripe_plan == plan.stripe_plan && plan.views / 10000 + (views_plan_quantity - quantityOffset) == quantityValue;

          const optionTag = `<option value="${quantityValue}" ${
            optionSelected ? "selected" : ""
          }>${quantityLabel}</option>`;

          $(usageSelect).append(optionTag);
        });

      const contactOptionTag = '<option value="contact">600,000+</option>';
      $(usageSelect).append(contactOptionTag);

      $(usageSelect).on("change", function () {

        const selectedValue = $(usageSelect).val();
        const quantity =
          selectedValue && selectedValue !== "contact"
            ? parseInt(selectedValue)
            : 0;

        // visualize price
        let price = plan.price;

        const annual_commit_discount =
          on_annual_paid_monthly == true;

        if (annual_commit_discount == true) {
          price = Math.ceil(price - price * 0.0833);
        }

        price = price + quantity * viewsPlan.price;

        const downgrade_inaccessible_on_contract =
          (annual_commit_discount && price < current_plan.price + views_plan_quantity * 20) || (['2023_build', '2023_launch', '2023_pro'].includes(plan_type) && ['2023_scale', '2023_autopilot', '2023_enterprise'].includes(current_plan.plan_type) && !trial);

        $(priceLabel).html(price.toLocaleString());

        // build button
        let buttonLinkTag = document.createElement("a");
        let buttonLink = `${root_url}subscriptions/new?stripe_plan=${plan_type}&account_id=${subscription.account_id}`;

        if (
          ((free_plan && !trial) || (trial && !free_plan)) &&
          on_annual_paid_monthly !== true
        ) {
          buttonLink += "&trial=true";
        }

        if (quantity >= 0) {
          buttonLink += `&views_plan_quantity=${quantity}`;
        }

        if (on_annual_paid_monthly == true) {
          buttonLink += "&annual_paid_monthly=true";
        }

        buttonLinkTag.href = buttonLink;

        let buttonTag = document.createElement("button");
        buttonTag.className = "btn btn-block pricing-button";

        const buttonText =
          free_plan && !trial
            ? "Start 14 day free trial"
            : plan_type == subscription.stripe_plan &&
              quantity == views_plan_quantity &&
              !downgrade_inaccessible_on_contract
            ? "Change plan term"
            : downgrade_inaccessible_on_contract
            ? "Account ineligible"
            : "Switch to this plan";

        buttonTag.innerHTML = buttonText;

        const backgroundColor =
          (free_plan && plan_type == "2023_build") ||
          (current_plan.stripe_plan == "2023_build" &&
          plan_type == "2023_launch") ||
          (current_plan.stripe_plan == "2023_launch" &&
            plan_type == "2023_pro") ||
          (current_plan.stripe_plan == "2023_pro" &&
            plan_type == "2023_scale")  ||
            (current_plan.stripe_plan == "2023_scale" &&
              plan_type == "2023_autopilot")
            ? "#003fff"
            : downgrade_inaccessible_on_contract
            ? "rgb(126, 147, 165)"
            : "";
        $(buttonTag).css("background", backgroundColor);

        // disable button if on annual contract and plan would be downgrade
        if (downgrade_inaccessible_on_contract) {
          buttonLinkTag.href = "#";
          $(buttonTag).addClass("disabled");
        }

        // show contact us button if interested in high traffic
        if (selectedValue == "contact") {
          buttonTag.innerHTML = "Contact Sales";
          buttonLinkTag.href = "#";

          $(buttonLinkTag)
            .off("click")
            .on("click", function () {
              const planTypePretty = plan_type.replace("2023_", "");
              const intercomMessage = `I'm interested in a high-traffic pricing for the ${planTypePretty} plan`;
              Intercom("showNewMessage", intercomMessage);
            });
        }

        $(buttonLinkTag).append(buttonTag);
        $(planOuterDiv).html(buttonLinkTag);

        if (plan.annual_plan && !on_annual_paid_monthly && !downgrade_inaccessible_on_contract) {
          let annualLink = `${root_url}subscriptions/new?stripe_plan=${plan.annual_plan}&account_id=${subscription.account_id}`;

          if (quantity >= 0) {
            annualLink += `&views_plan_quantity=${quantity}`;
          }

          if (current_plan.stripe_plan == plan.annual_plan) {
            $(planOuterDiv).append(
              `<p class="light center smooth" style="margin-bottom: 8px; font-size: 13px !important; margin-top: 15px !important; color: #2bc3a6 !important;">
                You're saving $${(plan.price * 12) - (plan.price * 10)}/y by paying yearly 🙌
              </p>`
            )
          } else {
            $(planOuterDiv).append(
              `<p class="light center smooth" style="margin-bottom: 8px; font-size: 13px !important; margin-top: 15px !important;">
                <a style="color: #2bc3a6 !important;" href="${annualLink}" target="_blank">
                  Save $${(plan.price * 12) - (plan.price * 10)}/y + ${plan_type == '2023_build' ? 't-shirt' : plan_type == '2023_launch' ? 'hoodie' : plan_type == '2023_pro' ? 'swag box' : 'swag box'} with annual →
                </a>
              </p>`
            )
          }
        }
      });

      $(usageSelect).trigger("change");
    });
  });

  // 2022 pricing plans

  $(["2022_pro", "2022_team", "2022_business"]).each(function (i, plan_type) {
    let planTypeSelector = '.pricing-box-outer[data-plan="' + plan_type + '"]';

    $(planTypeSelector).each(function (i, plan_div) {
      const usageOuterDiv = $(plan_div).find(".plan-usage-select-outer");
      const usageOuterDivContent =
        'For <select class="field-control text-field plan-usage-select"></select> monthly visitors';
      const planOuterDiv = $(plan_div).find(".plan-button");
      const priceLabel = $(plan_div).find(".plan-price");
      const buttonWrapper = $(plan_div).find(".plan-button");

      // build visitors volume dropdown
      $(usageOuterDiv).html(usageOuterDivContent);
      const usageSelect = $(usageOuterDiv).find("select");

      [...Array(40).keys()]
        .filter(
          (quantity) =>
            (plan_type !== "2022_business" && quantity > 0) ||
            (plan_type == "2022_business" && quantity > 9)
        )
        .forEach((quantity) => {
          const quantityOffset = plan_type == "2022_business" ? 10 : 1;
          const quantityLabel = (quantity * 10000).toLocaleString();

          let quantityValue = quantity - quantityOffset;
          if (quantityValue < 0) {
            quantityValue = "";
          }

          // select the current plan
          const optionSelected =
            (plan_type !== "2022_business" &&
              visitors_plan_quantity == quantityValue) ||
            (plan_type == "2022_business" &&
              current_plan.stripe_plan !== "2022_business" &&
              visitors_plan_quantity - 9 == quantityValue);

          const optionTag = `<option value="${quantityValue}" ${
            optionSelected ? "selected" : ""
          }>${quantityLabel}</option>`;

          $(usageSelect).append(optionTag);
        });

      const contactOptionTag = '<option value="contact">400,000+</option>';
      $(usageSelect).append(contactOptionTag);

      $(usageSelect).on("change", function () {
        const plan = plans[plan_type];
        const selectedValue = $(usageSelect).val();
        const quantity =
          selectedValue && selectedValue !== "contact"
            ? parseInt(selectedValue)
            : 0;

        // visualize price
        let price = plan.price;

        const annual_commit_discount =
          on_annual_paid_monthly == true && plan_type !== "2022_business";

        if (annual_commit_discount == true) {
          price = Math.ceil(price - price * 0.0833);
        }

        price = price + quantity * 10;

        if (selectedValue == "contact") {
          if (plan_type !== "2022_business") {
            price = price + 40 * 10 + "+";
          } else {
            price = price + 30 * 10 + "+";
          }
        }

        const downgrade_inaccessible_on_contract =
          annual_commit_discount &&
          price < current_plan.price + visitors_plan_quantity * 10;

        $(priceLabel).html(price);

        // build button
        let buttonLinkTag = document.createElement("a");
        let buttonLink = `${root_url}subscriptions/new?stripe_plan=${plan_type}&account_id=${subscription.account_id}`;

        if (
          ((free_plan && !trial) || (trial && !free_plan)) &&
          on_annual_paid_monthly !== true &&
          plan_type !== "2022_business"
        ) {
          buttonLink += "&trial=true";
        }

        if (quantity >= 0) {
          buttonLink += `&visitors_plan_quantity=${quantity}`;
        }

        if (on_annual_paid_monthly == true && plan_type !== "2022_business") {
          buttonLink += "&annual_paid_monthly=true";
        }

        buttonLinkTag.href = buttonLink;

        let buttonTag = document.createElement("button");
        buttonTag.className = "btn btn-block pricing-button";

        const buttonText =
          free_plan && !trial
            ? "Start 14 day free trial"
            : plan_type == subscription.stripe_plan &&
              quantity == visitors_plan_quantity &&
              !downgrade_inaccessible_on_contract
            ? "Change plan term"
            : downgrade_inaccessible_on_contract
            ? "Account ineligible"
            : "Switch to this plan";

        buttonTag.innerHTML = buttonText;

        const backgroundColor =
          (free_plan && plan_type == "2022_pro") ||
          (current_plan.stripe_plan == "2022_pro" &&
            plan_type == "2022_team") ||
          (current_plan.stripe_plan == "2022_team" &&
            plan_type == "2022_business")
            ? "#003fff"
            : downgrade_inaccessible_on_contract
            ? "rgb(126, 147, 165)"
            : "";
        $(buttonTag).css("background", backgroundColor);

        // disable button if on annual contract and plan would be downgrade
        if (downgrade_inaccessible_on_contract) {
          buttonLinkTag.href = "#";
          $(buttonTag).addClass("disabled");
        }

        // show contact us button if interested in high traffic
        if (selectedValue == "contact") {
          buttonTag.innerHTML = "Contact Sales";
          buttonLinkTag.href = "#";

          $(buttonLinkTag)
            .off("click")
            .on("click", function () {
              const planTypePretty = plan_type.replace("2022_", "");
              const intercomMessage = `I'm interested in a high-traffic pricing for the ${planTypePretty} plan`;
              Intercom("showNewMessage", intercomMessage);
            });
        }

        $(buttonLinkTag).append(buttonTag);
        $(planOuterDiv).html(buttonLinkTag);
      });

      $(usageSelect).trigger("change");
    });
  });

  // 2021 pricing plans
  $.each(["month", "year"], function (i, interval) {
    $.each(
      ["2021_pro", "2021_business", "marketer", "pro", "business"],
      function (i, plan_type) {
        $(
          '.pricing-box-outer[data-plan="' +
            plan_type +
            '"][data-plan-interval="' +
            interval +
            '"]'
        ).each(function (i, plan_div) {
          var usage_outer = $(plan_div).find(".plan-usage-select-outer");
          $(usage_outer).html(
            'For <select class="field-control text-field plan-usage-select"></select> monthly visitors'
          );

          var scalable_plans = $.map(plans, function (i, plan_id) {
            if (
              plan_id.indexOf(plan_type) > -1 &&
              (["pro", "business"].indexOf(plan_type) == -1 ||
                plan_id.indexOf("2021") == -1) &&
              ((interval == "month" && plan_id.indexOf("annual") == -1) ||
                (interval == "year" && plan_id.indexOf("annual") > -1))
            )
              return plan_id;
          });

          $.each(scalable_plans, function (id, plan_id) {
            var plan = plans[plan_id];
            if (plan.visitors && plan.next_plan && plan.hidden !== true) {
              $(usage_outer)
                .find("select")
                .append(
                  '<option value="' +
                    plan_id +
                    '">' +
                    plan.visitors.toLocaleString() +
                    "</option>"
                );
            }
          });

          if (
            ["2021_pro", "2021_business", "business"].indexOf(plan_type) > -1
          ) {
            $(usage_outer)
              .find("select")
              .append('<option value="contact">2,000,000+</option>');
          }

          $(usage_outer)
            .find("select")
            .unbind("change")
            .on("change", function () {
              var plan_id = $(this).val();
              var plan = plans[plan_id];

              if (plan) {
                if (interval == "month") {
                  var price = plan.price;

                  if (on_annual_paid_monthly == true) {
                    price = Math.ceil(price - price * 0.0833);
                  }
                } else {
                  var price = Math.ceil(plan.price / 12);
                  $(plan_div)
                    .find(".annual-savings")
                    .html(
                      "<strike>$" +
                        (plan.price * 1.2).toLocaleString() +
                        "</strike> $" +
                        plan.price.toLocaleString() +
                        " annually (Save $" +
                        (plan.price * 1.2 - plan.price).toLocaleString() +
                        ")"
                    );
                }
                $(plan_div).find(".plan-price").html(price);

                var annual_upgrade =
                  current_usage_tier &&
                  interval == "year" &&
                  plans[subscription.stripe_plan].price * 10 == plan.price;

                var downgrade_inaccessible_on_contract =
                  on_annual_paid_monthly == true &&
                  ((interval == "month" && price < current_plan.price) ||
                    (interval !== "month" && price < current_plan.price));

                $(plan_div)
                  .find(".plan-button")
                  .html(
                    '<a href="' +
                      root_url +
                      "subscriptions/new?stripe_plan=" +
                      plan_id +
                      "&account_id=" +
                      subscription.account_id +
                      (((free_plan && !trial) || (trial && !free_plan)) &&
                      interval == "month" &&
                      on_annual_paid_monthly !== true
                        ? "&trial=true"
                        : "") +
                      (on_annual_paid_monthly == true && interval == "month"
                        ? "&annual_paid_monthly=true"
                        : "") +
                      '">' +
                      '<button class="btn btn-block pricing-button" style="background: ' +
                      (free_plan ||
                      (annual_upgrade &&
                        downgrade_inaccessible_on_contract !== true)
                        ? "#00b591 !important"
                        : plan_id == subscription.stripe_plan ||
                          downgrade_inaccessible_on_contract
                        ? "rgb(126, 147, 165)"
                        : "") +
                      '">' +
                      (free_plan && !trial && interval == "month"
                        ? "Start 14 day free trial"
                        : plan_id == subscription.stripe_plan
                        ? "Your current plan"
                        : downgrade_inaccessible_on_contract
                        ? "Account ineligible"
                        : (trial && !free_plan) ||
                          subscription.stripe_plan.indexOf("k") == -1
                        ? "Switch to this plan"
                        : current_usage_tier &&
                          current_usage_tier <=
                            parseInt(plan_id.split("_").pop().replace("k", ""))
                        ? "Upgrade to this plan"
                        : annual_upgrade
                        ? "Switch to annual"
                        : "Switch to this plan") +
                      "</button>" +
                      "</a>"
                  );

                if (
                  plan_id == subscription.stripe_plan ||
                  downgrade_inaccessible_on_contract
                ) {
                  $(plan_div).find(".plan-button a").removeAttr("href");
                  $(plan_div).find(".plan-button button").addClass("disabled");
                }
              }

              if (
                plan_id == "contact" ||
                (((plan.interval == "month" && plan.price > 300) ||
                  (plan.interval == "year" && plan.price > 3000)) &&
                  (subscription.stripe_plan == "free" ||
                    (current_plan.plan_type == "2021_pro" &&
                      current_plan.interval == "month" &&
                      current_plan.price < 300) ||
                    (current_plan.plan_type == "2021_pro" &&
                      current_plan.interval == "year" &&
                      current_plan.price < 3000) ||
                    current_plan.plan_type == "2021_business"))
              ) {
                var prepopulatedMessage;
                var priceToShow;

                if (plan && plan.price > 300) {
                  prepopulatedMessage = "I'm interested in a high-traffic plan";
                  priceToShow = "300+";
                }

                if (plan_id == "contact") {
                  prepopulatedMessage = "I'm interested in an enterprise plan";
                  priceToShow = "2,000+";
                  $(plan_div).find(".plan-name").html("Enterprise");
                }

                $(plan_div).find(".plan-price").html(priceToShow);

                $(plan_div)
                  .find(".plan-button")
                  .html(
                    '<button class="btn btn-block pricing-button" style="background: #00b591 !important">Contact Us</button>'
                  );

                $(plan_div)
                  .find(".pricing-button")
                  .on("click", function () {
                    Intercom("showNewMessage", prepopulatedMessage);
                  });

                if (interval == "year") {
                  $(plan_div)
                    .find(".annual-savings")
                    .html("2 months free with annual");
                }
              } else {
                var original = $(plan_div)
                  .find(".plan-name")
                  .attr("data-original-name");
                $(plan_div).find(".plan-name").html(original);
              }
            });

          if (current_usage_tier) {
            var plan_option = $(usage_outer).find(
              'select option[value="' + subscription.stripe_plan + '"]'
            );

            if ($(plan_option)[0]) {
              $(plan_option).attr("selected", "true");
            }

            if (
              interval == "year" &&
              subscription.stripe_plan.indexOf("annual") == -1
            ) {
              var annual_plan_option = $(usage_outer).find(
                'select option[value="' + subscription.stripe_plan + '_annual"]'
              );

              if ($(annual_plan_option)[0]) {
                $(annual_plan_option).attr("selected", "true");
              }
            }
          }

          $(usage_outer).find("select").trigger("change");
        });
      }
    );
  });
};

export default pricing_table;
