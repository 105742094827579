var color_picker = {
  show: function (picker, input_selector) {
    var setting = $(picker).parent();

    if ($(picker).attr("data-initialized") !== "true") {
      $(".popover").hide();
      $(picker).attr("data-initialized", "true");
      $(setting).find(".popover").show();
      websites.color_picker.start(setting, input_selector);
    } else {
      if ($(setting).find(".popover").css("display") == "none") {
        $(".popover").hide();
        $(setting).find(".popover").show();
      } else {
        $(setting).find(".popover").hide();
      }
    }
  },

  start: function (setting, input_selector) {
    $(setting)
      .find(".color-picker-input")
      .minicolors({
        control: $(this).attr("data-control") || "hue",
        defaultValue: $(this).attr("data-defaultValue") || "",
        inline: $(this).attr("data-inline") === "true",
        letterCase: $(this).attr("data-letterCase") || "lowercase",
        opacity: true,
        position: $(this).attr("data-position") || "bottom left",
        change: function (hex, opacity) {
          var hexToRgb = function (hex, opacity) {
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
              return r + r + g + g + b + b;
            });
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            var r = parseInt(result[1], 16);
            var g = parseInt(result[2], 16);
            var b = parseInt(result[3], 16);
            return "rgba(" + [r, g, b].join() + ", " + opacity + ")";
          };

          var hex = hex || $(window.ColorPickerInput).val();
          var opacity = $(setting)
            .find(".color-picker-input")
            .attr("data-opacity");

          if (hex.indexOf("#") > -1) {
            var value = hexToRgb(hex, opacity || 1);
          } else {
            var value = hex;

            if (value.indexOf("rgba") > -1) {
              var value = value.replace("rgba", "");
            } else if (value.indexOf("rgb") > -1) {
              var value = value.replace("rgb", "");
            }

            var a = value.replace("(", "").replace(")", "").split(",");
            var value =
              "rgba(" +
              a[0] +
              "," +
              parseInt(a[1]) +
              "," +
              parseInt(a[2]) +
              "," +
              opacity +
              ")";
          }

          if (!value) return;
          if (typeof console === "object") {
            $(input_selector).val(value).trigger("change");
            $(input_selector)
              .closest(".input-color-picker-toggle")
              .css("background", value);
          }
        },
        theme: "bootstrap",
      });

    $(setting)
      .find(".fr-select-color")
      .unbind("click")
      .on("click", function (i, color) {
        var hexcode = $(this).attr("data-color");

        $(input_selector).val(hexcode).trigger("change");
        $(input_selector)
          .closest(".input-color-picker-toggle")
          .css("background", hexcode);
      });

    $("#color_input_brand_color_vibrant").on("change", function () {
      var color_code = $(this).val();

      $(".campaign-type .template-choose").each(function () {
        $(this).css("background-color", color_code);
      });
    });

    $(".color-field-input").on("change", function () {
      var data_type = $(this).attr("data-type");
      var color_code = $(this).val();

      $('.fr-select-color[data-type="' + data_type + '"')
        .css("background", color_code)
        .attr("data-color", color_code);
    });
  },
};

export default color_picker;
