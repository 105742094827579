const updateConversionRate = (
  viewsSelector,
  conversionsSelector,
  rateSelector
) => {
  let conversion_check = setInterval(function () {
    const viewsSpan = $(viewsSelector);
    const conversionsSpan = $(conversionsSelector);

    if (viewsSpan.attr("data-loading") == "false" && conversionsSpan.attr("data-loading") == "false") {
      clearInterval(conversion_check);

      const views = parseInt($(viewsSpan).text().replaceAll(",", ""));
      const conversions = parseInt($(conversionsSpan).text().replaceAll(",", ""));

      let conversionRateValue =
        views && conversions && conversions > 0 ? (conversions / views) * 100 : 0;

      if (conversionRateValue > 100) {
        conversionRateValue = 100.0;
      }

      $(rateSelector).text(conversionRateValue.toFixed(2) + "%");
    }
  }, 500);
};

export default updateConversionRate;
