import React, { useState, useEffect } from "react";
import arePropsEqual from "../../utils/arePropsEqual";
import Tooltip from "../shared/Tooltip";

const ReportCount = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState(
    !props.count || props.count == 0 ? 0 : props.count
  );

  const getReport = async (payload) => {
    console.log("fetch count", payload);
    try {
      setIsLoading(true);
      const response = await fetch(`${props.path}?` + $.param(payload));
      const json = await response.json();
      setCount(json);
      setIsLoading(false);

      if (props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    if (!props.count && props.count !== 0) {
      getReport(props.payload);
    }
  }, [props.payload]);

  if (props.selector_to_assign_data_attr) {
    useEffect(() => {
      $(props.selector_to_assign_data_attr).attr('data-count', count);
    }, [count]);
  }

  return (
    <React.Fragment>
      {!props.inline && !props.unstyled && (
        <div className="row hard-center">
          <div className="row hard-center">
            <span className="field-title text-left">{props.label}</span>
            {props.tooltip && (
              <Tooltip tooltip={props.tooltip} inline={true} />
            )}
          </div>
          <div className="row hard-center">
            <h4
              className="report-count bold"
              style={{ margin: "5px 0px 0px" }}
            >
              {props.currency ? props.currency : ""}
              <span id={props.id} data-loading={isLoading}>
                {(props.count || props.count == 0
                  ? props.count
                  : count
                ).toLocaleString()}
              </span>
            </h4>
          </div>
        </div>
      )}

      {props.inline && (
        <span className={props.unstyled ? "" : "bold"}>
          {props.currency ? props.currency : ""}
          <span id={props.id} className={props.unstyled ? "" : "events-report-count"} data-loading={isLoading}>
            {(props.count || props.count == 0
              ? props.count
              : count
            ).toLocaleString()}
          </span>
        </span>
      )}
    </React.Fragment>
  );
};

export default React.memo(ReportCount, arePropsEqual);
