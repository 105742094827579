var form = function (button) {
  $("#integrations").modal("hide");

  $("#integration-form-modal").on("hidden.bs.modal", function () {
    $("#integrations").modal("show");
  });

  var form = $($(button).attr("data-form"));
  var integration_type = $(button).attr("data-service");
  $(form)
    .find('input[name="integration[integration_type]"]')
    .val(integration_type);

  $(form).find(".integration-auth-keys").html("");
  var auth_keys = JSON.parse($(button).attr("data-auth-keys"));

  $.each(auth_keys, function (i, auth_key) {
    var authAttribute = auth_key.attribute || auth_key.key;
    $(form)
      .find(".integration-auth-keys")
      .append(
        '<div class="form-group row hard-center integration-api-data" data-type="' +
          authAttribute +
          '">' +
          '<p class="field-title">' +
          auth_key.name +
          "</p>" +
          '<input type="text" data-auth-key="' +
          authAttribute +
          '" name="integration[api_data][' +
          authAttribute +
          ']" placeholder="' +
          auth_key.placeholder +
          '" required class="form-control input-lg text-field" />' +
          '<span class="semibold smooth light" style="font-size: 12px !important;">' +
          (auth_key.note || "") +
          "</span>" +
          "</div>"
      );
  });
};

export default form;
