import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingRange = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [defaultValue, setDefaultValue] = useState(
    props.value ? props.value : undefined
  );

  const [timeoutId, setTimeoutId] = useState(null);

  function updateSetting(e) {
    let parsedFloat;
    let value = e.target.value;
    let timeoutIdTemp;

    if (e.target.value !== 0) {
      parsedFloat = parseFloat(e.target.value);
    } else {
      parsedFloat = e.target.value;
    }
    if (parsedFloat !== NaN) {
      if (props.min && parsedFloat < props.min) {
        value = props.min;
      } else if (props.max && parsedFloat > props.max) {
        value = props.max;
      }
      e.target.value = value;

      setDefaultValue(value);

      if (
        (!value && props.allow_empty == true) ||
        (value &&
          (!props.min || value >= props.min) &&
          (!props.max || value <= props.max))
      ) {
        clearTimeout(timeoutId);

        timeoutIdTemp = setTimeout(function () {
          if (props.skip_update !== true) {
            builder.update([
              {
                object_type: props.object_type,
                object_id: props.object_id,
                setting_name: props.setting_name,
                value: value,
                skip_history: props.skip_history,
              },
            ]);
          }
          if (props.callback) {
            props.callback(value);
          }
        }, 100);

        setTimeoutId(timeoutIdTemp);
      }
    }
  }

  useEffect(() => {
    setDefaultValue(props.value ? props.value : '');
  }, [props.object_id, props.last_reset, props.value]);

  return (
    <React.Fragment>
      <div className="w-full -mt-[5px]">
        <div
          className="col-xs-9"
          style={{
            paddingLeft: "0px",
            paddingRight: "5px",
          }}
        >
          <input
            type="range"
            setting_name={props.setting_name}
            className="w-full h-2 my-6 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 
          [&::-webkit-slider-thumb]:w-7
          [&::-webkit-slider-thumb]:h-7
          [&::-webkit-slider-thumb]:-mt-[6px]
          [&::-webkit-slider-thumb]:appearance-none
          [&::-webkit-slider-thumb]:bg-blue-600
          [&::-webkit-slider-thumb]:rounded-full
          [&::-webkit-slider-thumb]:transition-all
          [&::-webkit-slider-thumb]:duration-150
          [&::-webkit-slider-thumb]:ease-in-out
          [&::-webkit-slider-thumb]:dark:bg-blue-600

          [&::-moz-range-thumb]:w-7
          [&::-moz-range-thumb]:h-7
          [&::-moz-range-thumb]:appearance-none
          [&::-moz-range-thumb]:bg-white
          [&::-moz-range-thumb]:border-4
          [&::-moz-range-thumb]:border-blue-600
          [&::-moz-range-thumb]:rounded-full
          [&::-moz-range-thumb]:transition-all
          [&::-moz-range-thumb]:duration-150
          [&::-moz-range-thumb]:ease-in-out

          [&::-webkit-slider-runnable-track]:w-full
          [&::-webkit-slider-runnable-track]:h-2
          [&::-webkit-slider-runnable-track]:bg-gray-200
          [&::-webkit-slider-runnable-track]:rounded-full
          [&::-webkit-slider-runnable-track]:dark:bg-gray-700

          [&::-moz-range-track]:w-full
          [&::-moz-range-track]:h-2
          [&::-moz-range-track]:bg-gray-200
          [&::-moz-range-track]:rounded-full"
            defaultValue={defaultValue || props.placeholder}
            onChange={(e) => {
              updateSetting(e);
            }}
            min={props.min}
            max={props.max}
            step={props.range_step}
          />
        </div>
        <div
          className="col-xs-3"
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <input
            type="number"
            setting_name={props.setting_name}
            className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0"
            value={defaultValue}
            onChange={(e) => {
              updateSetting(e);
            }}
            min={props.min}
            max={props.max}
            step={props.range_step}
            placeholder={props.placeholder}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingRange;
