import React from "react";
import PropTypes from "prop-types";

const FieldParagraph = (props) => {
  let field = props.field;
  let element = props.element;
  let options = element.options;
  return (
    <React.Fragment>
      <div
        data-id={field.id}
        data-field={field.slug}
        data-pos={field.position ? field.position : ""}
        className="cf-form-field cf-custom-field"
        data-visible="true"
        data-multi-select=""
        style={{
          order: field.position,
        }}
      >
        <div
          id={"customfield" + field.id}
          data-required={field.required == true ? "true" : "false"}
          className={
            "contact-custom-field cf-" + field.field_type + " cf-col-xs-12"
          }
        >
          {field.label && props.hide_label !== true && (
            <p
              className="cf-label"
              style={{
                marginBottom: "4px",
                color: element.options["label-color"]
                  ? element.options["label-color"]
                  : "",
              }}
            >
              {field.label}
            </p>
          )}
          <textarea
            name={"contact[extra][" + field.slug + "]"}
            id={"contact_extra_" + field.slug}
            className="form-control"
            placeholder={field.placeholder ? field.placeholder : ""}
            required={field.required == true ? true : false}
            rows={field.rows ? field.rows : 3}
            defaultValue={field.value ? field.value : ""}
            minLength={
              field.options["minimum-characters"]
                ? field.options["minimum-characters"]
                : undefined
            }
            maxLength={
              field.options["maximum-characters"]
                ? field.options["maximum-characters"]
                : undefined
            }
          ></textarea>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldParagraph;
