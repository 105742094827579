import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeProducts = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Product Heroes" open={false}>
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-width]"
          setting_type="range"
          object_type="variants"
          label={"Container maximum width"}
          value={
            variant.options["products-hero-item-width"]
              ? variant.options["products-hero-item-width"]
              : undefined
          }
          placeholder={2560}
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-width-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Container maximum width"}
          value={
            variant.options["products-hero-item-width-mobile"]
              ? variant.options["products-hero-item-width-mobile"]
              : undefined
          }
          placeholder={variant.options["products-hero-item-width"] || 2560}
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-content-width]"
          setting_type="range"
          object_type="variants"
          label={"Content maximum width"}
          value={
            variant.options["products-hero-item-content-width"]
              ? variant.options["products-hero-item-content-width"]
              : undefined
          }
          placeholder={2560}
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-content-width-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Content maximum width"}
          value={
            variant.options["products-hero-item-content-width-mobile"]
              ? variant.options["products-hero-item-content-width-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-content-width"] || 2560
          }
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-padding]"
          setting_type="range"
          object_type="variants"
          label={"Products outer padding"}
          value={
            variant.options["products-hero-padding"]
              ? variant.options["products-hero-padding"]
              : 0
          }
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-padding-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products outer padding"}
          value={
            variant.options["products-hero-padding-mobile"]
              ? variant.options["products-hero-padding-mobile"]
              : undefined
          }
          placeholder={variant.options["products-hero-padding"]}
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-content-type-spacing]"
          setting_type="range"
          object_type="variants"
          label={"Products Content Spacing"}
          value={
            variant.options["products-hero-content-type-spacing"]
              ? variant.options["products-hero-content-type-spacing"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-content-type-spacing"]
              ? variant.options["products-hero-content-type-spacing"]
              : 10
          }
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-content-type-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products Content Spacing"}
          value={
            variant.options["products-hero-content-type-spacing-mobile"]
              ? variant.options["products-hero-content-type-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-content-type-spacing-mobile"]
              ? variant.options["products-hero-content-type-spacing-mobile"]
              : variant.options["products-hero-content-type-spacing"]
              ? variant.options["products-hero-content-type-spacing"]
              : 10
          }
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Product background color"
          setting_name="[options][products-hero-item-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-background-color"]
              ? variant.options["products-hero-item-background-color"]
              : undefined
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Product border style"
          setting_name="[options][products-hero-item-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-hero-item-border-style"]
              ? variant.options["products-hero-item-border-style"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Product border color"
          setting_name="[options][products-hero-item-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-border-color"]
              ? variant.options["products-hero-item-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={["dotted", "dashed", "solid"].includes(
            variant.options["products-hero-item-border-style"]
          )}
        />

        <div
          className="col-xs-12 some-vertical-space setting"
          style={{ marginBottom: "5px" }}
        >
          <div className="mb-[5px] text-xl text-black dark:text-white">
            Product Border width
          </div>

          {["left", "top", "bottom", "right"].map((side) => {
            const settingName = `[options][products-hero-item-border-width-${side}]`;
            return (
              <VariantBuilderSetting
                key={settingName}
                object={variant}
                setting_name={settingName}
                setting_type="number"
                object_type="variants"
                value={
                  _.isNil(
                    variant.options[`products-item-border-width-${side}`]
                  ) == false
                    ? variant.options[`products-item-border-width-${side}`]
                    : variant.options["products-hero-item-border-width"]
                    ? variant.options[
                        "products-hero-item-border-width"
                      ].replace("px", "")
                    : "1"
                }
                placeholder="0px"
                col="3"
                note={window.capitalizeFirstLetter(side)}
                classNames="spacing-inputs"
                min={0}
              />
            );
          })}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Product Corner Radius"
          setting_name="[options][products-hero-item-radius]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-hero-item-radius"]
              ? variant.options["products-hero-item-radius"]
              : "cf-not-rounded"
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom"
          }}
        />

        {variant.options["products-hero-item-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][products-hero-item-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[`products-item-radius-${side}`]
                      ) == false
                        ? variant.options[`products-item-radius-${side}`]
                        : "3"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Products Shadow"
          setting_name="[options][products-hero-item-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["products-hero-item-shadow"]
              ? variant.options["products-hero-item-shadow"]
              : "None"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["products-hero-item-shadow-horizontal-distance"]
              ? variant.options["products-hero-item-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["products-hero-item-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["products-hero-item-shadow-vertical-distance"]
              ? variant.options["products-hero-item-shadow-vertical-distance"]
              : undefined
          }
          condition={variant.options["products-hero-item-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["products-hero-item-shadow-blur-radius"]
              ? variant.options["products-hero-item-shadow-blur-radius"]
              : "0"
          }
          condition={variant.options["products-hero-item-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["products-hero-item-shadow-spread-radius"]
              ? variant.options["products-hero-item-shadow-spread-radius"]
              : undefined
          }
          condition={variant.options["products-hero-item-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][products-hero-item-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-shadow-color"]
              ? variant.options["products-hero-item-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={variant.options["products-hero-item-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Inset shadow position"
          setting_name="[options][products-hero-item-shadow-inset]"
          setting_type="checkbox"
          object_type="variants"
          value={
            variant.options["products-hero-item-shadow-inset"]
              ? variant.options["products-hero-item-shadow-inset"]
              : "false"
          }
          condition={variant.options["products-hero-item-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-image-height]"
          setting_type="range"
          object_type="variants"
          label={"Products image height"}
          value={
            variant.options["products-hero-item-image-height"]
              ? variant.options["products-hero-item-image-height"]
              : undefined
          }
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-carousel-image-height]"
          setting_type="range"
          object_type="variants"
          label={"Carousel image height"}
          value={
            variant.options["products-hero-carousel-image-height"]
              ? variant.options["products-hero-carousel-image-height"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-carousel-image-height-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Carousel image height"}
          value={
            variant.options["products-hero-carousel-image-height-mobile"]
              ? variant.options["products-hero-carousel-image-height-mobile"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-image-height-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Products image height"}
          value={
            variant.options["products-hero-item-image-height-mobile"]
              ? variant.options["products-hero-item-image-height-mobile"]
              : undefined
          }
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title font"
          setting_name="[options][products-hero-item-title-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-font"]
              ? variant.options["products-hero-item-title-font"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-title-font"]
              ? variant.options["products-hero-item-title-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-title-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-font-weight"]
              ? variant.options["products-hero-item-title-font-weight"]
              : "700"
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Title color"
          setting_name="[options][products-hero-item-title-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-color"]
              ? variant.options["products-hero-item-title-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-title-color"]
              ? variant.options["products-hero-item-title-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title size"
          setting_name="[options][products-hero-item-title-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-size"]
              ? variant.options["products-hero-item-title-size"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-title-size"]
              ? variant.options["products-hero-item-title-size"]
              : "15"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title size"
          setting_name="[options][products-hero-item-title-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-size-mobile"]
              ? variant.options["products-hero-item-title-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-title-size-mobile"]
              ? variant.options["products-hero-item-title-size-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Line Height"
          setting_name="[options][products-hero-item-title-line-height]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-line-height"]
              ? variant.options["products-hero-item-title-line-height"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-title-line-height"]
              ? variant.options["products-hero-item-title-line-height"]
              : "13"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Title Line Height"
          setting_name="[options][products-hero-item-title-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-title-line-height-mobile"]
              ? variant.options["products-hero-item-title-line-height-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-title-line-height-mobile"]
              ? variant.options["products-hero-item-title-line-height-mobile"]
              : "13"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Price font"
          setting_name="[options][products-hero-item-price-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-item-price-font"]
              ? variant.options["products-hero-item-price-font"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-price-font"]
              ? variant.options["products-hero-item-price-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-price-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-item-price-font-weight"]
              ? variant.options["products-hero-item-price-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Price color"
          setting_name="[options][products-hero-item-price-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-price-color"]
              ? variant.options["products-hero-item-price-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-price-color"]
              ? variant.options["products-hero-item-price-color"]
              : "#000"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Discount color"
          setting_name="[options][products-hero-item-discount-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-discount-color"]
              ? variant.options["products-hero-item-discount-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-discount-color"]
              ? variant.options["products-hero-item-discount-color"]
              : "#000"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products price size"
          setting_name="[options][products-hero-item-price-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-price-size"]
              ? variant.options["products-hero-item-price-size"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-price-size"]
              ? variant.options["products-hero-item-price-size"]
              : "15"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products price size"
          setting_name="[options][products-hero-item-price-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-price-size-mobile"]
              ? variant.options["products-hero-item-price-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-price-size-mobile"]
              ? variant.options["products-hero-item-price-size-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Description font"
          setting_name="[options][products-hero-item-description-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-font"]
              ? variant.options["products-hero-item-description-font"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-description-font"]
              ? variant.options["products-hero-item-description-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-item-description-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-font-weight"]
              ? variant.options["products-hero-item-description-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Description color"
          setting_name="[options][products-hero-item-description-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-color"]
              ? variant.options["products-hero-item-description-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-description-color"]
              ? variant.options["products-hero-item-description-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products description size"
          setting_name="[options][products-hero-item-description-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-size"]
              ? variant.options["products-hero-item-description-size"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-description-size"]
              ? variant.options["products-hero-item-description-size"]
              : "15"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products description size"
          setting_name="[options][products-hero-item-description-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-size-mobile"]
              ? variant.options["products-hero-item-description-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-description-size-mobile"]
              ? variant.options["products-hero-item-description-size-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products description Line Height"
          setting_name="[options][products-hero-item-description-line-height]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-line-height"]
              ? variant.options["products-hero-item-description-line-height"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-description-line-height"]
              ? variant.options["products-hero-item-description-line-height"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Products description Line Height"
          setting_name="[options][products-hero-item-description-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-item-description-line-height-mobile"]
              ? variant.options["products-hero-item-description-line-height-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-item-description-line-height-mobile"]
              ? variant.options["products-hero-item-description-line-height-mobile"]
              : "13"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font`}
          setting_name="[options][products-hero-content-label-font-family]"
          setting_type="select"
          object_type="variants"
          value={variant.options['products-hero-content-label-font-family']}
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-content-label-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={variant.options['products-hero-content-label-font-weight']}
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font Size`}
          setting_name="[options][products-hero-content-label-font-size]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-hero-content-label-font-size']}
          placeholder="13"
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font Size`}
          setting_name="[options][products-hero-content-label-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-hero-content-label-font-size-mobile']}
          placeholder={variant.options['products-hero-content-label-font-size']}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font line height`}
          setting_name="[options][products-hero-content-label-line-height]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-hero-content-label-line-height']}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font line height`}
          setting_name="[options][products-hero-content-label-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={variant.options['products-hero-content-label-line-height-mobile']}
          placeholder={variant.options['products-hero-content-label-line-height']}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label={`Content Label Font color`}
          setting_name="[options][products-hero-content-label-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={variant.options['products-hero-content-label-font-color']}
          placeholder={"#000"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Min Width"
          setting_name="[options][products-hero-variant-picker-button-minimum-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-button-minimum-width"]
              ? variant.options[
                  "products-hero-variant-picker-button-minimum-width"
                ]
              : undefined
          }
          placeholder={
            variant.options["products-hero-variant-picker-button-minimum-width"]
              ? variant.options[
                  "products-hero-variant-picker-button-minimum-width"
                ]
              : "auto"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Min Width"
          setting_name="[options][products-hero-variant-picker-button-minimum-width-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options[
              "products-variant-picker-button-minimum-width-mobile"
            ]
              ? variant.options[
                  "products-variant-picker-button-minimum-width-mobile"
                ]
              : undefined
          }
          placeholder={
            variant.options[
              "products-variant-picker-button-minimum-width-mobile"
            ]
              ? variant.options[
                  "products-variant-picker-button-minimum-width-mobile"
                ]
              : "auto"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Font"
          setting_name="[options][products-hero-variant-picker-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-font-family"]
              ? variant.options["products-hero-variant-picker-font-family"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-variant-picker-font-family"]
              ? variant.options["products-hero-variant-picker-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-variant-picker-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-font-weight"]
              ? variant.options["products-hero-variant-picker-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Font Size"
          setting_name="[options][products-hero-variant-picker-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-font-size"]
              ? variant.options["products-hero-variant-picker-font-size"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-variant-picker-font-size"]
              ? variant.options["products-hero-variant-picker-font-size"]
              : "14"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Font Size"
          setting_name="[options][products-hero-variant-picker-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-font-size-mobile"]
              ? variant.options["products-hero-variant-picker-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-variant-picker-font-size-mobile"]
              ? variant.options["products-hero-variant-picker-font-size-mobile"]
              : "14"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Text"
          setting_name="[options][products-hero-variant-picker-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-text-color"]
              ? variant.options["products-hero-variant-picker-text-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-variant-picker-text-color"]
              ? variant.options["products-hero-variant-picker-text-color"]
              : "#000"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-hero-variant-picker-text-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-text-hover-color"]
              ? variant.options["products-hero-variant-picker-text-hover-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-variant-picker-text-hover-color"]
              ? variant.options["products-hero-variant-picker-text-hover-color"]
              : variant.options["products-hero-variant-picker-text-color"] ||
                "#fff"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Color"
          setting_name="[options][products-hero-variant-picker-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-background-color"]
              ? variant.options["products-hero-variant-picker-background-color"]
              : undefined
          }
          placeholder={"#fff"}
          col="6"
          classNames="some-padding-right"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-hero-variant-picker-background-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          value={variant.options["products-hero-variant-picker-background-hover-color"]}
          placeholder={"#000"}
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          gradient={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Border Style"
          setting_name="[options][products-hero-variant-picker-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-border-style"]
              ? variant.options["products-hero-variant-picker-border-style"]
              : "solid"
          }
        />

        {variant.options["products-hero-variant-picker-border-style"] &&
          variant.options["products-hero-variant-picker-border-style"] !==
            "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Picker Buttons Border
                </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][products-hero-variant-picker-border-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[
                              `products-hero-variant-picker-border-${side}`
                            ]
                          ) == false
                            ? variant.options[
                                `products-hero-variant-picker-border-${side}`
                              ]
                            : variant.options[
                                "products-hero-variant-picker-border"
                              ]
                            ? variant.options[
                                "products-hero-variant-picker-border"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder="1px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Picker Border"
          setting_name="[options][products-hero-variant-picker-border-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-hero-variant-picker-border-color"]
              ? variant.options["products-hero-variant-picker-border-color"]
              : undefined
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-hero-variant-picker-border-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-hero-variant-picker-border-hover-color"]
              ? variant.options["products-hero-variant-picker-border-hover-color"]
              : undefined
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Picker Buttons Border Rounding"
          setting_name="[options][products-hero-variant-picker-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-hero-variant-picker-corner-rounding"]
              ? variant.options["products-hero-variant-picker-corner-rounding"]
              : "cf-fully-rounded"
          }
          condition={
            variant.options["products-hero-variant-picker-border-style"] !==
            "none"
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-hero-variant-picker-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`products-variant-picker-corner-${side}`]
                    ) == false
                      ? variant.options[
                          `products-variant-picker-corner-${side}`
                        ]
                      : undefined
                  }
                  condition={
                    variant.options[
                      "products-hero-variant-picker-corner-rounding"
                    ] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Quantity Minimum Width"
          setting_name="[options][products-hero-quantity-picker-minimum-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-minimum-width"]
              ? variant.options["products-hero-quantity-picker-minimum-width"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-quantity-picker-minimum-width"]
              ? variant.options["products-hero-quantity-picker-minimum-width"]
              : "100"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Minimum Width"
          setting_name="[options][products-hero-quantity-picker-minimum-width-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-minimum-width-mobile"]
              ? variant.options["products-hero-quantity-picker-minimum-width-mobile"]
              : undefined
          }
          placeholder={
            variant.options[
              "products-hero-quantity-picker-minimum-width-mobile"
            ]
              ? variant.options[
                  "products-hero-quantity-picker-minimum-width-mobile"
                ]
              : "100"
          }
          min={0}
          max={1000}
          allow_empty={true}
          range_step={1}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Font"
          setting_name="[options][products-hero-quantity-picker-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-font-family"]
              ? variant.options["products-hero-quantity-picker-font-family"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-quantity-picker-font-family"]
              ? variant.options["products-hero-quantity-picker-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-quantity-picker-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-font-weight"]
              ? variant.options["products-hero-quantity-picker-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Font Size"
          setting_name="[options][products-hero-quantity-picker-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-font-size"]
              ? variant.options["products-hero-quantity-picker-font-size"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-quantity-picker-font-size"]
              ? variant.options["products-hero-quantity-picker-font-size"]
              : "14"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Font Size"
          setting_name="[options][products-hero-quantity-picker-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-font-size-mobile"]
              ? variant.options[
                  "products-hero-quantity-picker-font-size-mobile"
                ]
              : undefined
          }
          placeholder={
            variant.options["products-hero-quantity-picker-font-size-mobile"]
              ? variant.options[
                  "products-hero-quantity-picker-font-size-mobile"
                ]
              : "14"
          }
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Text"
          setting_name="[options][products-hero-quantity-picker-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-text-color"]
              ? variant.options["products-hero-quantity-picker-text-color"]
              : undefined
          }
          placeholder={
            variant.options["products-hero-quantity-picker-text-color"]
              ? variant.options["products-hero-quantity-picker-text-color"]
              : "#000"
          }
          col="12"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Background"
          setting_name="[options][products-hero-quantity-picker-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-background-color"]
              ? variant.options[
                  "products-hero-quantity-picker-background-color"
                ]
              : undefined
          }
          placeholder={
            variant.options["products-hero-quantity-picker-background-color"]
              ? variant.options[
                  "products-hero-quantity-picker-background-color"
                ]
              : "#FFFFFF"
          }
          col="12"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Border Style"
          setting_name="[options][products-hero-quantity-picker-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-border-style"]
              ? variant.options["products-hero-quantity-picker-border-style"]
              : "solid"
          }
        />

        {variant.options["products-hero-quantity-picker-border-style"] !==
          "none" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Quantity Border Width
                </div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][products-hero-quantity-picker-border-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={variant}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="variants"
                      value={
                        _.isNil(
                          variant.options[
                            `products-quantity-picker-border-${side}`
                          ]
                        ) == false
                          ? variant.options[
                              `products-quantity-picker-border-${side}`
                            ]
                          : "1"
                      }
                      allow_empty={true}
                      placeholder="1px"
                      col="3"
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Quantity Border"
          setting_name="[options][products-hero-quantity-picker-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-border-color"]
              ? variant.options["products-hero-quantity-picker-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={
            variant.options["products-hero-quantity-picker-border-style"] !==
            "none"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-hero-quantity-picker-border-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-hero-quantity-picker-border-hover-color"]
              ? variant.options[
                  "products-hero-quantity-picker-border-hover-color"
                ]
              : undefined
          }
          condition={
            variant.options["products-hero-quantity-picker-border-style"] !==
            "none"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Quantity Border Corner Rounding"
          setting_name="[options][products-hero-quantity-picker-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-hero-quantity-picker-corner-rounding"]
              ? variant.options["products-hero-quantity-picker-corner-rounding"]
              : "cf-slightly-rounded"
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-hero-quantity-picker-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`products-quantity-picker-corner-${side}`]
                    ) == false
                      ? variant.options[
                          `products-quantity-picker-corner-${side}`
                        ]
                      : undefined
                  }
                  condition={
                    variant.options[
                      "products-hero-quantity-picker-corner-rounding"
                    ] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Plan Font"
          setting_name="[options][products-hero-subscription-choices-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-choices-font-family"]
              ? variant.options[
                  "products-hero-subscription-choices-font-family"
                ]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-subscription-choices-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-choices-font-weight"]
              ? variant.options[
                  "products-hero-subscription-choices-font-weight"
                ]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Font Size"
          setting_name="[options][products-hero-subscription-choices-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-choices-font-size"]
              ? variant.options["products-hero-subscription-choices-font-size"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Font Size"
          setting_name="[options][products-hero-subscription-choices-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options[
              "products-hero-subscription-choices-font-size-mobile"
            ]
              ? variant.options[
                  "products-subscription-choices-font-size-mobile"
                ]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Text"
          setting_name="[options][products-hero-subscription-choices-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-choices-text-color"]
              ? variant.options["products-hero-subscription-choices-text-color"]
              : undefined
          }
          placeholder="#000"
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-hero-subscription-choices-text-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          value={variant.options["products-hero-subscription-choices-text-hover-color"]}
          placeholder="#000"
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Field"
          setting_name="[options][products-hero-subscription-choices-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={variant.options["products-hero-subscription-choices-background-color"]}
          placeholder="#fff"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Border Style"
          setting_name="[options][products-hero-subscription-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-border-style"]
              ? variant.options["products-hero-subscription-border-style"]
              : "solid"
          }
        />

        <div
          className="col-xs-12 some-vertical-space setting"
          style={{ marginBottom: "5px" }}
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="mb-[5px] text-xl text-black dark:text-white">
                Plan Border Width
              </div>

              {["left", "top", "bottom", "right"].map((side) => {
                const settingName = `[options][products-hero-subscription-border-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        options[`products-subscription-border-${side}`]
                      ) == false
                        ? options[`products-subscription-border-${side}`]
                        : "1"
                    }
                    allow_empty={true}
                    placeholder="0px"
                    col="3"
                    note={window.capitalizeFirstLetter(side)}
                    classNames="spacing-inputs"
                    min={0}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Plan Border"
          setting_name="[options][products-hero-subscription-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-border-color"]
              ? variant.options["products-hero-subscription-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={
            variant.options["products-hero-subscription-border-style"] !==
            "none"
          }
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][products-hero-subscription-border-hover-color]"
          setting_type="color_v2"
          object_type="variants"
          placeholder="#000"
          value={
            variant.options["products-hero-subscription-border-hover-color"]
              ? variant.options["products-hero-subscription-border-hover-color"]
              : undefined
          }
          condition={
            variant.options["products-hero-subscription-border-style"] !==
            "none"
          }
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Radio"
          setting_name="[options][products-hero-subscription-radio-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-radio-color"]
              ? variant.options["products-hero-subscription-radio-color"]
              : undefined
          }
          placeholder="#D4DDEA"
          col="6"
          classNames="some-padding-right"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Selected"
          setting_name="[options][products-hero-subscription-radio-selected-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-radio-selected-color"]
              ? variant.options[
                  "products-hero-subscription-radio-selected-color"
                ]
              : undefined
          }
          placeholder="#003FFF"
          col="6"
          classNames="some-padding-left"
          show_reset={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Plan Border Corner Rounding"
          setting_name="[options][products-hero-subscription-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-hero-subscription-corner-rounding"]
              ? variant.options["products-hero-subscription-corner-rounding"]
              : "cf-slightly-rounded"
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-hero-subscription-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`products-subscription-corner-${side}`]
                    ) == false
                      ? variant.options[`products-subscription-corner-${side}`]
                      : undefined
                  }
                  condition={
                    variant.options[
                      "products-hero-subscription-corner-rounding"
                    ] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Reviews Star"
          setting_name="[options][products-hero-reviews-star-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-star-color"]
              ? variant.options["products-hero-reviews-star-color"]
              : undefined
          }
          placeholder="#EFAB40"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Star Size"
          setting_name="[options][products-hero-reviews-star-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-star-size"]
              ? variant.options["products-hero-reviews-star-size"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Star Size"
          setting_name="[options][products-hero-reviews-star-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-star-size-mobile"]
              ? variant.options["products-hero-reviews-star-size-mobile"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Font"
          setting_name="[options][products-hero-reviews-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-font-family"]
              ? variant.options["products-hero-reviews-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-reviews-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-font-weight"]
              ? variant.options["products-hero-reviews-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Font Size"
          setting_name="[options][products-hero-reviews-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-font-size"]
              ? variant.options["products-hero-reviews-font-size"]
              : undefined
          }
          placeholder="11"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Font Size"
          setting_name="[options][products-hero-reviews-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-font-size-mobile"]
              ? variant.options["products-hero-reviews-font-size-mobile"]
              : undefined
          }
          placeholder="14"
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Reviews Text Color"
          setting_name="[options][products-hero-reviews-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-reviews-text-color"]
              ? variant.options["products-hero-reviews-text-color"]
              : undefined
          }
          placeholder="#000"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Offer Badge Background"
          setting_name="[options][products-hero-offer-badge-background]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-background"]
              ? variant.options["products-hero-offer-badge-background"]
              : undefined
          }
          placeholder={"#003FFF"}
          gradient={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Offer Badge Font"
          setting_name="[options][products-hero-offer-badge-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-font-family"]
              ? variant.options["products-hero-offer-badge-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][products-hero-offer-badge-font-family-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-font-family-weight"]
              ? variant.options["products-hero-offer-badge-font-family-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Offer Badge Font Size"
          setting_name="[options][products-hero-offer-badge-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-font-size"]
              ? variant.options["products-hero-offer-badge-font-size"]
              : undefined
          }
          placeholder={"14"}
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Offer Badge Font Size"
          setting_name="[options][products-hero-offer-badge-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-font-size-mobile"]
              ? variant.options["products-hero-offer-badge-font-size-mobile"]
              : undefined
          }
          placeholder={"14"}
          allow_empty={true}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Offer Badge Text Color"
          setting_name="[options][products-hero-offer-badge-text-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-text-color"]
              ? variant.options["products-hero-offer-badge-text-color"]
              : undefined
          }
          placeholder={"#FFFFFF"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Offer Badge Corner Rounding"
          setting_name="[options][products-hero-offer-badge-corner-rounding]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["products-hero-offer-badge-corner-rounding"]
              ? variant.options["products-hero-offer-badge-corner-rounding"]
              : undefined
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][products-hero-offer-badge-corner-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[
                        `products-hero-offer-badge-corner-${side}`
                      ]
                    ) == false
                      ? variant.options[
                          `products-hero-offer-badge-corner-${side}`
                        ]
                      : undefined
                  }
                  condition={
                    variant.options[
                      "products-hero-offer-badge-corner-rounding"
                    ] == "custom"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeProducts;
