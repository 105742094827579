import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useConditions from "../conditions/useConditions";
import useActions from "../actions/useActions";

const useActionGroups = (props) => {
  const { Conditions } = useConditions(props);
  const { Actions } = useActions(props);

  let contextData;
  let builder;
  let variant;

  if (!props || props.builder !== false) {
    [contextData, builder] = useContext(VariantContextData);
    variant = contextData.objects.variant;
  }

  const ActionGroups = {
    create: async function (element, step, payload, options) {
      builder.create(
        "action_groups",
        {
          ...(payload || {}),
          element_id: element ? element.id : undefined,
          step_id: step ? step.id : undefined,
        },
        {
          transform: function (action_group) {
            action_group.open = true;
            return action_group;
          },
          callback: options ? options.callback : undefined,
        }
      );
    },

    duplicate: async function (og_action_group, payload, skip_history) {
      return new Promise((resolve, reject) => {
        let attributes = {
          data: og_action_group.data,
          element_id: og_action_group.element_id,
          step_id: og_action_group.step_id,
        };

        if (payload) {
          attributes = { ...attributes, ...payload };
        }

        builder.create("action_groups", attributes, {
          callback: function (action_group) {
            // duplicate conditions
            let conditions = Object.values(variant.conditions).filter(
              (condition) =>
                condition.contactable_type == "ActionGroup" &&
                condition.contactable_id == og_action_group.id &&
                condition.toBeDeleted !== true
            );

            let conditionPromises = conditions.map((condition) =>
              Conditions.duplicate(
                condition,
                {
                  contactable_id: action_group.id,
                },
                true
              )
            );

            let actions = Object.values(variant.actions).filter(
              (action) =>
                action.contactable_type == "ActionGroup" &&
                action.contactable_id == og_action_group.id &&
                action.toBeDeleted !== true
            );

            let actionPromises = actions.map((action) =>
              Actions.duplicate(
                action,
                {
                  contactable_id: action_group.id,
                },
                true
              )
            );

            Promise.all([...conditionPromises, ...actionPromises])
              .then(() => resolve(action_group))
              .catch(reject);
          },
          skip_history: skip_history,
        });
      });
    },

    remove: (action_group) => {
      let message =
        "Are you sure you want to remove this action group? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("action_groups", action_group.id, message);
    },
  };

  const DetachedActionGroups = {};

  let currentActionGroupsHook =
    props && props.builder == false ? DetachedActionGroups : ActionGroups;
  return { ActionGroups, currentActionGroupsHook };
};

export default useActionGroups;
