var preview = function (template_id, cta_id, cta_type, brand_style, premium) {

  $(".modal").modal("hide");
  $(".template-preview-container").html("");
  $(".template-modal-share").hide();
  var root_url = window.location.origin;

  $.ajax({
    url: window.location.origin + "/templates/" + template_id,
    dataType: "JSON",
    type: "GET",
    complete: function (data) {
      data = data.responseJSON;

      $("#templatePreview").modal("show");
      $("#newVariant").modal("hide");

      let controller = $("body").attr("data-controller");
      if (
        controller == "step_templates" ||
        controller == "section_templates" ||
        controller == "element_templates" ||
        controller == "field_templates"
      ) {
        $("#templateShare").hide();
      }

      if (
        $("body").attr("data-controller") == "templates" ||
        $("body").attr("data-controller") == "step_templates"
      ) {
        $("#manageTemplate")
          .unbind("click")
          .on("click", function () {
            window.location.href =
              window.location.origin +
              "/accounts/" +
              data.account.id +
              "/templates/" +
              data.template.id +
              "/edit";
          });
      } else {
        // select template for creation
        $("#chooseTemplate")
          .unbind("click")
          .on("click", function () {
            var account_plan_type = $("#upgrade").attr("data-plan-type");
            
            if (premium && premium == true && ['free', "2024_pages", "2024_core", '2024_funnels'].includes(account_plan_type)) {
              subscriptions.upgrade_modal(
                event,
                false,
                "use templates from the premium templates library.",
                "2024_pro",
                "Triggered feature CTA – Premium Templates"
              );
              return;
            } else {
              $("#templatePreview").modal("hide");
              $("#cta_template").val(data.object.id);
              $('#new_cta input[name="template_id"]').val(data.template.id);
              $("#ctaCreate").modal("show");
              $("#ctaCreate").on("shown.bs.modal", function () {
                $('#cta_name').focus();
              });

              $("#variant_template").val(data.object.id);
              $("#new_variant").submit();

              $("#template-partner-consent").hide();
              if (data.template.official && data.template.official !== true) {
                $("#template-partner-consent").show();
              }

              $(
                '.cta-create[data-template-cta="' + data.object.id + '"]'
              ).trigger("click");
            }
          });
      }

      // build iframe
      var frame = document.createElement("iframe");
      $(frame).attr("id", "template-iframe");

      let src = "";
      if (data.template.object_type === "Cta") {
        src =
          root_url +
          "/websites/0/cta/" +
          data.object.id +
          "/page/?iframe=true&variant=&device_type=desktop";

        if (brand_style) {
          Object.keys(brand_style).forEach(function (style_key, i) {
            if (brand_style[style_key]) {
              src =
                src +
                "&" +
                style_key +
                "=" +
                encodeURIComponent(brand_style[style_key]);
            }
          });
        }
      } else if (
        ["Section", "Step", "Element", "Field"].includes(
          data.template.object_type
        )
      ) {
        $("#manageTemplate")
          .removeClass("black-button-clear")
          .addClass("black-button");

        const targetController = () => {
          switch (data.template.object_type) {
            case "Section":
              return "sections";
            case "Step":
              return "steps";
            case "Element":
              return "elements";
            case "Field":
              return "fields";
            default:
          }
        };

        src =
          root_url +
          `/websites/0/${targetController()}/${
            data.template.object_id
          }/preview`;
      }

      $(frame).attr("src", src);
      $(frame).attr("cta-type", cta_type);

      if (cta_type !== "page") {
        $("#templatePreview .template-preview-container").css(
          "height",
          "600px"
        );
        $(frame).attr("width", "1198px");
        $(frame).attr("height", "600px");
      } else {
        $("#templatePreview .template-preview-container").css(
          "height",
          "600px"
        );
        $(frame).attr("width", "1332px");
        $(frame).attr("height", "672px");
        $(frame).css({
          transform: "scale(0.9)",
          "margin-left": "-67px",
          "margin-top": "-38px",
        });
      }
      $(frame).attr("frameborder", "0");
      $(".template-preview-container").html(frame);
      $(".template-modal-name").text(data.template.name);
      $(".template-modal-description").text(data.template.description || "");
      $("#templatePreview .template-creator-avatar").css(
        "background-image",
        "url(" +
          (data.user.avatar ||
            "https://s3.amazonaws.com/convertflow/uploads/4b2e7718-3df3-4f35-8a4f-28657bf36e0a/convertflow-icon-blue.png") +
          ")"
      );
      $(".template-creator-user-name").text(
        (data.user.name || "a template creator") +
          " " +
          (data.user.lastname || "")
      );
      $(".template-creator-user-account").text(data.account.name);

      $(".template-preview-container, .template-preview-devices").attr(
        "device-type",
        "desktop"
      );

      $("#templateShare")
        .unbind("click")
        .on("click", function () {
          var affiliate_code = $("#template-share-link-copy").attr(
            "affiliate-code"
          );
          var template_path = root_url + "/templates/" + template_id;

          if (affiliate_code) {
            var template_path = template_path + "/?fpr=" + affiliate_code;
          }

          var tweet =
            data.template.name +
            " – Get this @convertflow " +
            (data.object.cta_type == "page"
              ? "landing page"
              : data.object.cta_type == "inline"
              ? "embedded CTA"
              : data.object.cta_type == "overlay"
              ? "overlay popup"
              : data.object.cta_type == "hook"
              ? "hook popup"
              : data.object.cta_type == "bar"
              ? "sticky bar"
              : "") +
            " template for free – " +
            template_path;
          var refer_code = affiliate_code
            ? " refer-code=&quot;" + affiliate_code + "&quot;"
            : "";
          var embed_code =
            "&lt;div class=&quot;convertflow-template convertflow-template-" +
            template_id +
            "&quot;" +
            refer_code +
            "&gt;&lt;/div&gt;&lt;script async src=&quot;https://js.convertflow.co/" +
            (root_url == "https://app.convertflow.com"
              ? "production"
              : root_url == "https://testconvertflow.herokuapp.com"
              ? "staging"
              : root_url == "http://localhost:3000"
              ? "development"
              : "") +
            "/templates/" +
            template_id +
            ".js&quot;&gt;&lt;/script&gt;";

          $(".template-modal-share").toggle();
          $("#template-share-link-copy").html(template_path);
          $("#template-share-social-facebook").attr(
            "href",
            "https://www.facebook.com/sharer/sharer.php?u=" + template_path
          );
          $("#template-share-social-twitter").attr(
            "href",
            "https://twitter.com/intent/tweet?text=" +
              encodeURI(tweet) +
              "%20%23CraftedInConvertFlow"
          );
          $("#template-share-social-linked").attr(
            "href",
            "http://www.linkedin.com/shareArticle?mini=true&url=" +
              encodeURI(template_path)
          );
          $("#template-share-embed-code").html(embed_code);
        });

      $(".template-modal-share").on("click", function (e) {
        if ($(e.target).hasClass("template-modal-share")) {
          $(".template-modal-share").hide();
        }
      });

      $(".template-modal-share-close").on("click", function () {
        $(".template-modal-share").hide();
      });
    },
  });

  $("#templatePreview").on("hidden.bs.modal", function (e) {
    $("#newVariant").modal("show");
    $("#template-modal-share").hide();
  });
};

export default preview;
