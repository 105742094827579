import React from "react";

const CtaGroupsSmallListItem = (props) => {
  return (
    <React.Fragment>
      <div
        data-cta-id={props.cta.id}
        data-group-id={props.group.id}
        className="cta-group-item bg-slate-100 pt-1 pb-2 px-2 flex text-xl text-black mr-3 rounded"
        data-toggle="tooltip"
        data-placement="top"
        title={props.group.name}
      >
        <div>
          {props.group.icon && `${props.group.icon} `}
          {!props.group.icon && (
            <svg
              className="inline"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M1.80155 6.31237L1.80149 12.9556C1.80148 14.0602 2.69691 14.9556 3.80148 14.9556L14.2005 14.9556C15.3051 14.9557 16.2005 14.0603 16.2005 12.9557L16.2008 6.2592C16.2008 5.70691 15.753 5.25917 15.2008 5.25917H9.06355L6.98978 3.04395H2.80116C2.24873 3.04395 1.80095 3.49135 1.80111 4.04378C1.80133 4.74059 1.80156 5.65872 1.80155 6.31237Z"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        <div className="flex-grow pl-2">{props.group.name}</div>
      </div>
    </React.Fragment>
  );
};

export default CtaGroupsSmallListItem;
