import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsText = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Text Styling`} open={true}>
        <VariantBuilderSetting
          object={element}
          label="Font family"
          setting_name="[options][font-family]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-family"]
              ? element.options["font-family"]
              : undefined
          }
          placeholder={
            element.element_type == "headline"
              ? variant.options["h1-font-family"]
              : element.element_type == "text"
              ? variant.options["p-font-family"]
              : ""
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);

            // remove top level inline font so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="font-family"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("font-family");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-weight"]
              ? element.options["font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={element}
          label="Font size"
          setting_name="[options][font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-size"]
              ? element.options["font-size"]
              : undefined
          }
          placeholder={
            element.element_type == "headline"
              ? variant.options["h1-font-size"]
                ? variant.options["h1-font-size"]
                : 32
              : element.element_type == "text"
              ? variant.options["p-font-size"]
                ? variant.options["p-font-size"]
                : 16
              : undefined
          }
          min={0}
          max={200}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Font size"
          setting_name="[options][font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-size-mobile"]
              ? element.options["font-size-mobile"]
              : undefined
          }
          placeholder={
            (element.element_type == "headline"
              ? variant.options["h1-font-size-mobile"]
                ? variant.options["h1-font-size-mobile"]
                : variant.options["h1-font-size"]
              : element.element_type == "text"
              ? variant.options["p-font-size-mobile"]
                ? variant.options["p-font-size-mobile"]
                : variant.options["p-font-size"]
              : undefined) || "16"
          }
          min={0}
          max={200}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Font line height"
          setting_name="[options][font-line-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-line-height"]
              ? element.options["font-line-height"]
              : undefined
          }
          placeholder={
            element.element_type == "headline"
              ? variant.options["h1-font-line-height"]
                ? variant.options["h1-font-line-height"]
                : 50
              : element.element_type == "text"
              ? variant.options["p-font-line-height"]
                ? variant.options["p-font-line-height"]
                : 25
              : undefined
          }
          min={0}
          max={200}
          range_step={1}
          device="desktop"
          allow_empty={true}
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $("<div />");
              $(elementHtml).append(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="line-height"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("line-height");
                const new_value = $(elementHtml).html();
                console.log("new_value", new_value);

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Font line height"
          setting_name="[options][font-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-line-height-mobile"]
              ? element.options["font-line-height-mobile"]
              : undefined
          }
          placeholder={
            (element.element_type == "headline"
              ? variant.options["h1-font-line-height-mobile"]
                ? variant.options["h1-font-line-height-mobile"]
                : variant.options["h1-font-line-height"]
              : element.element_type == "text"
              ? variant.options["p-font-line-height-mobile"]
                ? variant.options["p-font-line-height-mobile"]
                : variant.options["p-font-line-height"]
              : undefined) || "25"
          }
          min={0}
          max={200}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Font color"
          setting_name="[options][font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["font-color"]
              ? element.options["font-color"]
              : undefined
          }
          placeholder={
            element.element_type == "headline" &&
            variant.options["h1-font-color"]
              ? variant.options["h1-font-color"]
              : element.element_type == "text" &&
                variant.options["p-font-color"]
              ? variant.options["p-font-color"]
              : "#000"
          }
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="color"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("color");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsText;
