import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";

const ElementSettingsImage = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];

  useEffect(() => {
    // Handle legacy image width and height. If there is values already set, do not update
    if (element.options["image-width"] && element.options["image-width"]) {
      return;
    }

    const updateElementSetting = (setting_name, value) => {
      builder.update([
        {
          object_type: "elements",
          object_id: element.id,
          setting_name: setting_name,
          value: value,
        },
      ]);
    }

    if (element.html) {
      var htmlObject = document.createElement("div");
      htmlObject.innerHTML = element.html;

      const imageWidth = htmlObject.querySelector("img")?.style?.width?.replace("px", "");
      const imageHeight = htmlObject.querySelector("img")?.style?.height?.replace("px", "");

      if (imageWidth && !element.options["image-width"]) updateElementSetting("[options][image-width]", imageWidth);
      if (imageHeight && !element.options["image-height"]) updateElementSetting("[options][image-height]", imageHeight);
    }
  }, [element.html]);

  let controlStyleMobile = element.options["control-style-mobile"]
    ? element.options["control-style-mobile"]
    : element.options["control-style"]
      ? element.options["control-style"]
      : "width";

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        label="Control"
        setting_name="[options][control-style]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["control-style"]
            ? element.options["control-style"]
            : undefined
        }
        options={[
          { value: "width", text: "Width" },
          { value: "height", text: "Height" },
        ]}
        device="desktop"
      />

      <VariantBuilderSetting
        object={element}
        label="Control"
        setting_name="[options][control-style-mobile]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={controlStyleMobile}
        options={[
          { value: "width", text: "Width" },
          { value: "height", text: "Height" },
        ]}
        device="mobile"
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][image-width]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Image Max Width"}
        value={element.options["image-width"]}
        device="desktop"
        condition={
          element.options["control-style"] !== "height" &&
          ["image"].includes(element.element_type)
        }
        placeholder={"Auto"}
        min={0}
        max={2560}
        allow_empty={true}
        range_step={"1"}
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][image-width-mobile]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Image Max Width"}
        condition={controlStyleMobile == "width"}
        placeholder={element.options["image-width"] || "Auto"}
        value={
          element.options["image-width-mobile"]
            ? element.options["image-width-mobile"]
            : undefined
        }
        device="mobile"
        min={0}
        max={2560}
        allow_empty={true}
        range_step={"1"}
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][image-height]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Image Max Height"}
        value={element.options["image-height"]}
        device="desktop"
        condition={
          element.options["control-style"] == "height" &&
          ["image"].includes(element.element_type)
        }
        placeholder={"Auto"}
        min={0}
        max={2560}
        allow_empty={true}
        range_step={"1"}
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][image-height-mobile]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Image Max Height"}
        condition={controlStyleMobile == "height"}
        placeholder={element.options["image-height"] || "Auto"}
        value={
          element.options["image-height-mobile"]
            ? element.options["image-height-mobile"]
            : undefined
        }
        device="mobile"
        min={0}
        max={2560}
        allow_empty={true}
        range_step={"1"}
        show_reset={true}
      />
    </React.Fragment>
  );
};

export default ElementSettingsImage;
