import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import ElementSettingsProductFeed from "./ElementSettingsProductFeed";
import ElementSettingsProductContent from "./ElementSettingsProductContent";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import ProductSettings from "../products/ProductSettings";
import NewProduct from "../products/NewProduct";
import useProducts from "../products/useProducts";
import NewActionGroup from "../action_groups/NewActionGroup";
import ActionGroupSettings from "../action_groups/ActionGroupSettings";
import ActionsBuilder from "../actions/ActionsBuilder";
import ProductRecommendationSettings from "../product_recommendations/ProductRecommendationSettings";
import NewProductRecommendation from "../product_recommendations/NewProductRecommendation";
import Tooltip from "../shared/Tooltip";

const ElementSettingsProducts = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let element = builder.selected().object;

  return (
    <React.Fragment>
      <div className="col-xs-12">
        <VariantBuilderPanelCollapse name="Products Feed" open={true}>
          <ElementSettingsProductFeed {...props} />
        </VariantBuilderPanelCollapse>

        <VariantBuilderPanelCollapse name="Products Content" open={true}>
          <ElementSettingsProductContent {...props} />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-button-cart-all]"
            setting_type="checkbox"
            label="Add All To Cart Button"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-button-cart-all"]
                ? element.options["products-button-cart-all"]
                : "false"
            }
            condition={
              ["Shopify", "ShopifyGraphql"].includes(
                element.options["products-source"]
              ) && element.options["confirmation-type"] == "cart"
            }
          />

          <VariantBuilderSetting
            object={element}
            label="Add All To Cart Button Text"
            setting_name="[options][products-button-cart-all-text]"
            setting_type="editor"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-button-cart-all-text"]
                ? element.options["products-button-cart-all-text"]
                : "<p>Add All To Cart</p>"
            }
            condition={
              ["Shopify", "ShopifyGraphql"].includes(
                element.options["products-source"]
              ) &&
              element.options["confirmation-type"] == "cart" &&
              element.options["products-button-cart-all"] == "true"
            }
          />
        </VariantBuilderPanelCollapse>
      </div>
    </React.Fragment>
  );
};

export default ElementSettingsProducts;
