document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "cards" ||
    ($("body").attr("data-controller") == "subscriptions" &&
      $("body").attr("data-action") == "new")
  ) {
    $.ajax({
      type: "GET",
      url: "https://js.stripe.com/v3/",
      dataType: "script",
      cache: true,
      success: function () {
        var stripe = Stripe($('meta[name="stripe-key"]').attr("content"));
        var elements = stripe.elements();

        var style = {
          base: {
            color: "#32325d",
            fontFamily:
              "BlinkMacSystemFont, -apple-system, 'Inter', sans-serif",
            background: "#f2f2f2",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        };

        var card = elements.create("card", {
          style: style,
        });

        if ($("#card-element")[0]) {
          card.mount("#card-element");

          card.addEventListener("change", function (event) {
            $("[type=submit]").attr("disabled", false);

            var displayError = document.getElementById("card-errors");
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = "";
            }
          });

          // Handle form submission.
          var form =
            document.getElementById("new_subscription") ||
            document.getElementById("new_card");

          if (form.getAttribute("data-shopify") !== "true") {
            form.addEventListener("submit", function (event) {
              event.preventDefault();
              $("[type=submit]").attr("disabled", true);
              $("[type=submit]").html(
                '<span style="font-size: 12px !important" class="glyphicon glyphicon-refresh spinning"></span> Processing...'
              );

              var billing_details = {
                name: $("#credit-card-name").val(),
                email: $("#credit-card-email").val(),
                phone: $("#credit-card-phone").val(),
                address: {
                  city: $("#credit-card-city").val(),
                  country: $("#credit-card-country").val(),
                  line1: $("#credit-card-address").val(),
                  state: $("#credit-card-state").val(),
                },
              };

              stripe
                .handleCardSetup($("#card-button").attr("data-secret"), card, {
                  payment_method_data: {
                    billing_details: billing_details,
                  },
                })
                .then(function (result) {
                  if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById("card-errors");
                    errorElement.textContent = result.error.message;
                    $("[type=submit]").attr("disabled", false);
                    $("[type=submit]").html("Submit");
                  } else {
                    form.submit();
                  }
                });
            });
          }
        }
      },
    });
  }
});
