import React, { useState, useContext, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSteps from "../steps/useSteps.js";
import StepLayer from "../steps/StepLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VariantBuilderPanelLayersItem from "./VariantBuilderPanelLayersItem.js";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";

const VariantBuilderPanelLayers = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { Steps } = useSteps();
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let steps = Object.values(variant.steps)
    .filter((step) => step.toBeDeleted !== true)
    .sort((a, b) => a.position - b.position);

  useEffect(() => {
    Steps.sortableLayersStart();
  });

  return (
    <div className="row hard-center">
      <VariantBuilderPanelTitle
        title={`Content`}
        tooltip="Navigate your funnel's content using the manager below."
        tooltipLabel="Learn"
      />
      <div className="w-full mt-5">
        <VariantBuilderPanelLayersItem
          object_type="variants"
          object_id={variant.id}
          title={
            cta.cta_type == "page"
              ? "Landing Page"
              : cta.cta_type == "overlay"
              ? "Overlay Popup"
              : cta.cta_type == "hook"
              ? "Hook Popup"
              : cta.cta_type == "bar"
              ? "Sticky Bar"
              : cta.cta_type == "inline"
              ? "Embed"
              : ""
          }
          selectCallback={() => {
            $('[data-tab="theme"]').trigger("click");

            setTimeout(function () {
              const collapse = $('[data-name="Container"]');

              if ($(collapse).find(".tab-settings").is(":visible") == false) {
                $(collapse).find(".tab-toggle").trigger("click");
              }
            }, 500);
          }}
          open={true}
        >
          <div id="layers" className="row hard-center">
            {steps.map((step) => (
              <StepLayer key={`step-${step.id}-layer`} step={step} {...props} />
            ))}
          </div>
          <div className="row hard-center mt-3 mb-10">
            <button
              onClick={() => {
                dispatchCustomEvent("newObject", {
                  object_type: "Steps"
                });
              }}
              className="w-full p-2 pl-1 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
            >
              <FontAwesomeIcon icon="plus-square" className="mr-2" /> Add Funnel Step
            </button>
          </div>
        </VariantBuilderPanelLayersItem>
      </div>
    </div>
  );
};

export default VariantBuilderPanelLayers;
