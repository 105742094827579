var edit = function (variant) {
  var variant_path =
    window.location.origin +
    "/websites/" +
    variant.website_id +
    "/cta/" +
    variant.cta_id +
    "/variants/" +
    variant.id;

  // auto submit name input
  var nameTimer = null;
  var name_form =
    'form[data-type="variant-name"][data-id="' + variant.id + '"]';
  let changed_forms = 'form[data-type="variant-traffic"][data-changed="true"]';

  $(name_form)
    .find('input[type="text"]')
    .on("keyup", function (e) {
      clearTimeout(nameTimer);

      nameTimer = setTimeout(function () {
        $(name_form).submit();
      }, 1000);
    });

  // on name form submit

  $(name_form).on("submit", function (e) {
    e.preventDefault();
    var name = $(name_form).find('input[type="text"]').val();
    $('.variant-name[variant-id="' + variant.id + '"]').text(name);

    $.ajax({
      type: "PUT",
      url: variant_path,
      data: {
        variant: {
          name: name,
        },
      },
    });
  });

  // auto submit percentage changes
  var trafficTimer = null;
  var percentage_form =
    'form[data-type="variant-traffic"][data-id="' + variant.id + '"]';

  const handle_percentage_change = (input, percentage_form) => {
    $(percentage_form).removeAttr("data-submitting");
    $(percentage_form).attr("data-changed", "true");

    var value = parseInt(input.val());
    if (value > 100) {
      input.val("100");
    } else if (value < 0) {
      input.val("0");
    }

    $(percentage_form).attr("data-changed", "true");

    // hide other buttons to avoid confusion
    if ($(changed_forms).length >= 2) {
      $(`${changed_forms} button`).hide();
    }

    validate_percentage_values(input, percentage_form);
  };

  const validate_percentage_values = (input, percentage_form) => {
    var value = parseInt(input.val());

    let values = $(`${changed_forms} input`)
      .not('[data-id="' + variant.id + '"]')
      .map(function () {
        return $(this).val();
      })
      .get();

    let changed_percentages_total = values.reduce(
      (sum, num) => parseInt(sum) + parseInt(num),
      0
    );

    // check is it's all filled and the sum doesn't match 100
    let changed_forms_length = $(changed_forms).length;

    let zero_forms_length = $(".percentage").filter(function () {
      return (
        $(this).val() == "0" &&
        $(this).parent()[0].dataset["changed"] !== "true"
      );
    }).length;

    let total_forms_length =
      $('form[data-type="variant-traffic"]').length - zero_forms_length;

    let valid_total_sum =
      total_forms_length != changed_forms_length ||
      (total_forms_length == changed_forms_length &&
        value + changed_percentages_total == 100);
    //

    if (value + changed_percentages_total <= 100 && valid_total_sum) {
      $(`${changed_forms} input`).removeClass("error");
      $(`${changed_forms} input`).addClass("is-valid");

      $(percentage_form).find("button").show();
    } else {
      input.removeClass("is-valid");
      input.addClass("error");

      $(percentage_form).find("button").hide();
    }
  };

  $(percentage_form)
    .find(".percentage")
    .unbind("keyup")
    .on("keyup", function (e) {
      if (e.keyCode !== 13) {
        handle_percentage_change($(this), percentage_form);
      } else {
        $(percentage_form).attr("data-submitting", "true");
      }
    });

  $(percentage_form)
    .find(".percentage")
    .unbind("change")
    .on("change", function (e) {
      handle_percentage_change($(this), percentage_form);
    });

    var current_plan = window.plan_type || $("#upgrade").attr("data-plan-type");
    var website_premium = $("#upgrade").attr("data-website-premium");
    var modal = $("#upgrade");
    var after_2024_deprecated = $(modal).attr("data-account-after-2024-deprecation") == 'true';
    var cta_type = $(".cta-view").attr("data-cta-type");

    function limit_page_tests(current_value) {
      return ["page"].includes(cta_type) &&
        current_plan &&  
        ($.inArray(current_plan, ["free", "2024_pages", "2024_core"]) > -1 ||
          website_premium == "false") && 
          current_value !== "0" &&
          current_value !== "100";
    }

    function limit_popup_tests(current_value) {
      return ["page"].includes(cta_type) == false && 
        current_plan &&
        ($.inArray(current_plan, [
          "free",
          "2024_pages",
          "2024_core",
          "2024_funnels",
          "marketer",
          "2021_pro",
          "2022_pro",
          "2023_launch"
        ]) > -1 ||
          (current_plan == "2024_pro" && after_2024_deprecated) ||
          website_premium == "false") &&
        current_value !== "0" &&
        current_value !== "100";
    }

  // tells user to upgrade for a/b split-testing
  var split_test_feature_gate = function (
    event,
    form,
    current_value,
    current_percentages
  ) {
    function fixVariantPercentage() {
      setTimeout(function () {
        $("#newVariant").modal("hide");
      }, 500);
  
      if (current_value !== "100" && current_percentages.length > 1) {
        $(form).find('input[type="number"]').val("0");
      } else if (current_value !== "100" && current_percentages.length == 1) {
        $(form).find('input[type="number"]').val("100");
      } else if (current_value == "0" && current_percentages.length == 0) {
        $(form).find('input[type="number"]').val("100");
      }
    }

    if (limit_page_tests(current_value)) {
      fixVariantPercentage()

      subscriptions.upgrade_modal(
        event,
        false,
        "split-test landing pages",
        "2024_pro",
        "Triggered feature CTA – Page Split-Testing"
      );
  
    } else if (limit_popup_tests(current_value)) {
      fixVariantPercentage()

      subscriptions.upgrade_modal(
        event,
        false,
        "split-test targeted popups & embedded content",
        "2024_plus",
        "Triggered feature CTA – Campaign Split-Testing"
      );
    } else {
      return true;
    }
  };

  // rounds percentages and save them to database
  var update_percentages = function (form) {
    $(form).attr("data-submitting", "true");
    let form_value = $(form).find('input[type="number"]').val();

    let changed_values = $(`${changed_forms} input`)
      .map(function () {
        return $(this).val();
      })
      .get();

    let changed_values_sum = changed_values.reduce(
      (sum, num) => parseInt(sum) + parseInt(num),
      0
    );

    let difference = 100 - changed_values_sum;
    let zeros = [];

    $(".percentage").each(function (i, percentage_input) {
      if ($(percentage_input).val() == "0") {
        zeros.push($(percentage_input));
      }
    });

    let changed_forms_length = $(changed_forms).length;

    if (form_value !== "0") {
      var variants =
        $(".percentage").length - zeros.length - changed_forms_length;
    } else {
      var variants = $(".percentage").length - zeros.length;
    }

    let total_forms_length =
      $('form[data-type="variant-traffic"]').length - zeros.length;

    if (total_forms_length == changed_forms_length && form_value != "0") {
      $(form)
        .find('input[type="number"]')
        .removeClass("error")
        .val(difference + parseInt(form_value));
    } else if (
      total_forms_length != changed_forms_length &&
      variants == 0 &&
      form_value != "0"
    ) {
      $(form).find('input[type="number"]').removeClass("error").val("100");
    }

    if (variants !== 0) {
      var newval = difference / variants;
    }

    var variant_updates = {};

    $(".percentage").each(function (i, percentage_input) {
      var previous_value = $(percentage_input).val();
      var variant_id = $(percentage_input).attr("data-id");

      let form_data_changed = $(percentage_input).parent()[0].dataset[
        "changed"
      ];

      if (
        variant_id !== variant.id + "" &&
        $(percentage_input).val() !== "0" &&
        newval !== undefined &&
        $(zeros).length !== $(".percentage").length &&
        form_data_changed !== "true"
      ) {
        $(percentage_input).attr("data-value", previous_value);
        $(percentage_input).removeClass("error").val(newval);
      }

      var new_percentage = parseInt($(percentage_input).val());
      variant_updates[variant_id] = {
        variant_id: variant_id,
        percentage: new_percentage,
      };
    });

    if (Object.keys(variant_updates).length > 0) {
      console.log("variant_updates", variant_updates);
      $.ajax({
        type: "POST",
        url:
          window.location.origin +
          "/websites/" +
          variant.website_id +
          "/cta/" +
          variant.cta_id +
          "/update_variant_percentages",
        data: {
          variant_updates: variant_updates,
        },
      });
    }

    $('form[data-type="variant-traffic"] button').hide();
    $('form[data-type="variant-traffic"] error').removeClass("error");
  };

  // on variant percentage update, either triggers feature gate or saves percentages
  $('form[data-type="variant-traffic"][data-id="' + variant.id + '"]')
    .unbind("submit")
    .on("submit", function (event) {
      event.preventDefault();
      $(`${changed_forms} input`).removeClass("error");
      $(`${changed_forms} input`).removeClass("is-valid");

      var form = this;
      var newval = undefined;
      var current_value = $(form).find('input[type="number"]').val();
      var current_percentages = [];
      
      $('.variant-list input[name="percentage"]').each(function (i, percentage_input) {
        if ($(percentage_input).val() !== "0") {
          current_percentages.push($(percentage_input).val());
        }
      });

      if (
        split_test_feature_gate(event, form, current_value, current_percentages) && 
        $(form).attr("data-submitting") !== "true"
      ) {
        update_percentages(form);
      }

      $(changed_forms).removeAttr("data-changed");
    });

  $('a[data-type="variant-delete"][data-id="' + variant.id + '"]').on(
    "click",
    function () {
      if (confirm("Are you sure you want to delete this variant?")) {
        $.ajax({
          type: "POST",
          url: variant_path + "/destroy",
          success: function (data) {
            location.reload();
          },
        });
      }
    }
  );
};

export default edit;
