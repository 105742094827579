const safelyRequestIdleCallback = (block) => {
  if ("requestIdleCallback" in window) {
    requestIdleCallback(function () {
      block();
    });
  } else {
    block();
  }
};

export default safelyRequestIdleCallback;
