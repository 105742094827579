import React, { useState } from "react";
import useFields from "./useFields.js";
import FieldTemplateItem from "./FieldTemplateItem";
import VariantBuilderSlideOutPanel from "../variants/VariantBuilderSlideOutPanel";

const NewFieldModal = (props) => {
  const [searchText, setSearchText] = useState("");
  const { Fields } = useFields();

  const createField = function (template_id = 0) {
    const template = template_id > 0 ? template_id : undefined;
    Fields.create(props.element, props.payload, template);

    props.setShow(false);
  };

  const searchedTemplates =
    props.templates &&
    props.templates.filter(function (template) {
      return template.name.toLowerCase().includes(searchText.toLowerCase());
    });

  return (
    <React.Fragment>
      <VariantBuilderSlideOutPanel
        show={props.show}
        setShow={props.setShow}
        title={"Add a question"}
        description={"Create from scratch or use a template"}
      >
        <div className="row hard-center" style={{ padding: "20px" }}>
          <div className="col-xs-12">
            <input
              type="text"
              name="name"
              className="form-control text-field"
              placeholder="Search..."
              defaultValue={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <span className="glyphicon glyphicon-search search"></span>
          </div>

          <div className="col-xs-12" style={{ padding: "0px" }}>
            <div className="d-flex d-flex-wrap">
              <div className="col-xs-6">
                <div
                  className="cf-card-add d-flex add-card gap-5"
                  onClick={createField}
                >
                  <span>Create From Scratch</span>
                </div>
              </div>

              {searchedTemplates &&
                searchedTemplates.map((item) => (
                  <FieldTemplateItem
                    key={item.id}
                    item={item}
                    selectTemplate={(e) => createField(e)}
                  />
                ))}
            </div>
          </div>
        </div>
      </VariantBuilderSlideOutPanel>
    </React.Fragment>
  );
};

export default NewFieldModal;
