const detachedDisplayFeatureGate = (event, conditionsList, condition_type) => {
  var planType = window.plan_type || $("#upgrade").attr("data-plan-type");
  var modal = $("#upgrade");
  var websitePremium = $("#upgrade").attr("data-website-premium");
  var after_2024_deprecated = $(modal).attr("data-account-after-2024-deprecation") == 'true';

  var condition_type = condition_type || $(event.target).find("#cf-select-condition_type").text();

  var conditionGroup = conditionsList[condition_type]
    ? conditionsList[condition_type].group
    : undefined;

  var isCartCondition = [
    "Shopify",
  ].includes(conditionGroup) && (condition_type.includes("cart") || condition_type.includes("order"));

  var isFreePlanCondition =
    $.inArray(conditionGroup, [
      "This campaign",
      "Current page",
      "Wordpress",
    ]) > -1 || (conditionGroup == 'Shopify' && !isCartCondition);

  var isSessionCondition = [
    "This session",
  ].includes(conditionGroup);

  var canCreateSessionCondition =
    websitePremium !== "false" &&
    (["2024_pages", "2024_core", "2024_funnels"].includes(planType) == false || (plan_type == '2024_pro' && !after_2024_deprecated)) && 
    (isFreePlanCondition || isSessionCondition);

  var canCreateCartCondition =
    websitePremium !== "false" &&
    (["2024_pages", "2024_core", "2024_funnels"].includes(planType) == false || (plan_type == '2024_pro' && !after_2024_deprecated)) && 
    (isFreePlanCondition || isCartCondition);

  var canCreateAdvancedCondition =
    websitePremium !== "false" && ["2024_pages", "2024_core", "2024_funnels", "2024_pro", "2023_build"].includes(planType) == false && (planType !== '2023_launch' || ["Contact profile", "Javascript"].includes(conditionGroup));

  var isLegacyPlanWithAccess =
    planType !== "free" &&
    websitePremium !== "false" &&
    ["marketer", "pro", "pro99", "2021_pro"].includes(planType);

  console.log('canCreateSessionCondition', canCreateSessionCondition);
  console.log('canCreateCartCondition', canCreateCartCondition);
  console.log('canCreateAdvancedCondition', canCreateAdvancedCondition);
  console.log('conditionGroup', conditionGroup);

  if (
    isFreePlanCondition ||
    canCreateSessionCondition ||
    canCreateCartCondition || 
    canCreateAdvancedCondition ||
    isLegacyPlanWithAccess
  ) {
    return true;
  } else {
    if (conditionGroup == "Javascript" && canCreateAdvancedCondition == false) {
      subscriptions.upgrade_modal(
        event,
        false,
        "target & personalize by cookies and javascript",
        "2024_plus",
        "Triggered feature CTA – Targeting – Javascript"
      );
    } else if (conditionGroup == "Contact profile" && canCreateAdvancedCondition == false) {
      subscriptions.upgrade_modal(
        event,
        false,
        "target & personalize for contact profiles",
        "2024_plus",
        "Triggered feature CTA – Targeting – Contact profiles"
      );
    } else if (isSessionCondition && canCreateSessionCondition == false) {
      subscriptions.upgrade_modal(
        event,
        false,
        "target & personalize by session activity",
        "2024_plus",
        "Triggered feature CTA – Targeting – Site activity"
      );
    } else if (isCartCondition && canCreateCartCondition == false) {
      subscriptions.upgrade_modal(
        event,
        false,
        "target & personalize by cart activity",
        "2024_plus",
        "Triggered feature CTA – Targeting – Site activity"
      );
    } else {
      subscriptions.upgrade_modal(
        event,
        false,
        "target & personalize for contact segments in " + conditionGroup,
        "2024_plus",
        "Triggered feature CTA – Targeting – " + conditionGroup
      );
    }
  }
};

export default detachedDisplayFeatureGate;
