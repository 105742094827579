var campaign_templates = {
  initialize: function () {
    $(".campaign-template-form").each(function (i, form) {
      $(form)
        .find(".new-category-input")
        .keypress(function (e) {
          var k = e.keyCode || e.which;
          if (k == 13) {
            e.preventDefault();
            $(form).find(".new-category-submit").click();
          }
        });

      $(form)
        .find(".new-category-submit")
        .on("click", function () {
          var category = $(form).find(".new-category-input").val();
          var dropdown = $(form).find(
            'select[name="campaign_template[categories][]"]'
          );

          if (
            $(dropdown).find('option[value="' + category + '"]').length == 0
          ) {
            $(dropdown).append(
              '<option value="' +
                category +
                '" selected>' +
                category +
                "</option>"
            );
          }

          $(form).find(".new-category-input").val("");
        });

      $(form)
        .find(".new-industry-input")
        .keypress(function (e) {
          var k = e.keyCode || e.which;
          if (k == 13) {
            e.preventDefault();
            $(form).find(".new-industry-submit").click();
          }
        });

      $(form)
        .find(".new-industry-submit")
        .on("click", function () {
          var industry = $(form).find(".new-industry-input").val();
          var dropdown = $(form).find(
            'select[name="campaign_template[industries][]"]'
          );

          if (
            $(dropdown).find('option[value="' + industry + '"]').length == 0
          ) {
            $(dropdown).append(
              '<option value="' +
                industry +
                '" selected>' +
                industry +
                "</option>"
            );
          }

          $(form).find(".new-industry-input").val("");
        });

      $(".campaign-template-form").each(function (i, form_div) {
        const froalaOptions = {
          toolbarButtons: [
            "paragraphFormat",
            "fontSize",
            "lineHeight",
            "insertLink",
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "align",
            "formatUL",
            "insertImage",
            "clearFormatting",
          ],
          key: "kKC1KXDF1INBh1KPe2TK==",
        };
        console.log("froalaOptions", froalaOptions);
        $(form_div).find("textarea").froalaEditor(froalaOptions);
      });

      $(".template-header").on("click", function () {
        $(this).find(".glyphicon").toggleClass("glyphicon-chevron-right");
        $(this).find(".glyphicon").toggleClass("glyphicon-chevron-down");
        $(this).parent().find(".template-body").toggle();
      });
    });
  },
};

document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "campaign_templates" ||
    ($("body").attr("data-controller") == "cta" &&
      ["templates", 'new'].includes($("body").attr("data-action")))
  ) {
    campaign_templates.initialize();

    $(".cta-type-select input, .cta-type-select label").on("click", function () {
      var cta_type = $(this).parent().find('input').val();
      $('input[type="hidden"][name="cta_type"]').val(cta_type);
      $("#template-search").submit();
    });

    $(".template-category-select input, .template-category-select label").on("click", function () {
      var category = $(this).parent().find('input').val();
      $('input[type="hidden"][name="category"]').val(category);
      $("#template-search").submit();
    });

    $(".template-industry-select").on("click", function (e) {
      var industryDiv = $(e.target).closest('.template-industry-select');
      $('.template-industry-select input[type="checkbox"]:checked').each(function(i, checkbox) {
        $(checkbox).prop('checked', false);
      });

      var checkbox = $(industryDiv).find('input[type="checkbox"]');
      $(checkbox).prop('checked', true);
      
      var category = $(checkbox).val();
      $('input[type="hidden"][name="industry"]').val(category);
      $("#template-search").submit();
    });

    $(".premium-select input, .premium-select label").on("click", function () {
      var premium = $(this).parent().find('input').val();
      $('input[type="hidden"][name="premium"]').val(premium);
      $("#template-search").submit();
    });

    function togglePanel(playbook_div) {
      $(playbook_div).toggleClass("collapsed");
    }

    $(".campaign-playbook-trigger").on("click", function () {
      if ($(".campaign-playbook-panel").hasClass("collapsed")) {
        $(".campaign-playbook-panel").removeClass("collapsed");
      } else {
        $(".campaign-playbook-panel").addClass("collapsed");
        setTimeout(function () {
          $(".campaign-playbook-panel").removeClass("collapsed");
        }, 200);
      }
    });
  }
});

export default campaign_templates;
