import React, { useEffect } from "react";

const GroupsNavList = (props) => {
  const groupPath = (group) => {
    return `/websites/${props.website_id}/${props.groups_kind}?group_ids=${group.id}`;
  };

  const isGroupActive = (group) => {
    if (
      props.controller === props.groups_kind &&
      props.group_ids && props.group_ids.includes(group.id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  async function updateGroupsPositions(groupsPositions) {
    $.ajax({
      type: "PUT",
      url: `${window.location.origin}/websites/${props.website_id}/groups_list`,
      data: { groups: groupsPositions },
      processData: true,
      async: true,
    });
  }

  useEffect(() => {
    let container = document.querySelector(`#${props.groups_kind}_navlist`);

    let sortableOptions = {
      sort: true,
      forceFallback: true,
      ghostClass: "dropzone-box",
      fallbackTolerance: 5,
      scroll: true,
      scrollSensitivity: 100,
      scrollSpeed: 10,
      preventOnFilter: false,
      onEnd: function (event) {
        let groupsPositions = {};

        Array.from(event.to.childNodes).forEach((node, index) => {
          groupsPositions[node.dataset.groupId] = { position: index };
        });
        updateGroupsPositions(groupsPositions);
      },
    };
    let sortable = new Sortable(container, sortableOptions);
  }, []);

  return (
    <React.Fragment>
      <div
        className="row hard-center"
        id={`${props.groups_kind}_navlist`}
        style={{ marginBottom: props.groups.length > 0 ? "5px" : null }}
      >
        {props.groups.map((group) => {
          if (group.name.length > 13) {
            $("#nav_" + group.id).tooltip();
          }

          return (
            <div
              key={group.id}
              id={`nav_${group.id}`}
              title={group.name}
              data-group-id={group.id}
              className={`row hard-center website-nav-item-child ${
                isGroupActive(group) === true ? "active" : ""
              }`}
            >
              <a href={groupPath(group)} className="website-nav-link">
                <div className={`col-xs-1 no-padding-sides pt-${group.icon ? '2' : '3'}`}>
                  {group.icon && (
                    <span
                      className="website-nav-icon"
                      style={{ paddingRight: group.icon ? "10px" : "23px" }}
                    >
                      {group.icon || ""}
                    </span>
                  )}
                  {!group.icon && (
                    <svg
                      className="website-nav-link-icon stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        d="M1.80155 6.81237L1.80149 13.4556C1.80148 14.5602 2.69691 15.4556 3.80148 15.4556L14.2005 15.4556C15.3051 15.4557 16.2005 14.5603 16.2005 13.4557L16.2008 6.7592C16.2008 6.20691 15.753 5.75917 15.2008 5.75917H9.06355L6.98978 3.54395H2.80116C2.24873 3.54395 1.80095 3.99135 1.80111 4.54378C1.80133 5.24059 1.80156 6.15872 1.80155 6.81237Z"
                        stroke="black"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                <div className="col-xs-11 website-nav-link-label ellipsis">
                  {group.name.length > 13
                    ? group.name.substr(0, 13).trim() + "..."
                    : group.name}
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default GroupsNavList;
