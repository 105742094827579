class YotpoAdapter {
  constructor(source_review) {
    this.source = "Yotpo";
    this.source_review = source_review;

    this.product_id = this.productId();
    this.product_score = this.productScore();
    this.product_total_reviews = this.productTotalReviews();
  }

  productId() {
    return this.source_review?.product_id;
  }

  productScore() {
    if (this.source_review?.average_score === 0) return 5;

    return this.source_review?.average_score;
  }

  productTotalReviews() {
    if (this.source_review?.total_review === 0) return undefined;

    return this.source_review?.total_review;
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.YotpoAdapter = YotpoAdapter;
}
