import React, { useState, useEffect } from "react";
import AreasList from "../areas/AreasList";

const CtaAreasSelector = (props) => {
  const broadcast = props.broadcast;
  const website = props.website;
  const cta = props.cta;

  useEffect(() => {
    $(".areas-container.areas-preview")
      .find(".areas-show-more")
      .on("click", function () {
        $(this).hide();
        $(this).parent().removeClass("areas-preview");
      });

    $("#areaModal form input[type='submit']").on("click", function (e) {
      setTimeout(function () {
        setWebsiteAreas(window.website.areas);
      }, 500);
    });
  }, []);

  return cta && cta.cta_type == "inline" ? (
    <div
      className={`some-vertical-space areas-container ${
        props.action_name == "launch" ? "row" : "areas-preview"
      }`}
    >
      <AreasList {...props} />

      {broadcast?.quick == false && (
        <div
          className="row hard-center center areas-show-more"
          onClick={() => {
            $(this).hide();
            $(this).parent().removeClass("areas-preview");
          }}
        >
          <button className="btn black-button">
            Show All →
          </button>
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default CtaAreasSelector;
