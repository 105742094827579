import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import SectionLayer from "../sections/SectionLayer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StepLayer = (props) => {
  let variant = props.variant;
  const step = props.step;
  const sorted_sections = Object.values({
    ...variant.sections,
    ...step.sections,
  })
    .filter(
      (section) =>
        section.step_id == step.id &&
        !section.element_id &&
        section.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <VariantBuilderPanelLayersItem
      object_type="steps"
      object_id={step.id}
      title={`Funnel Step ${step.position}
      ${step.options["name"] ? " - " + step.options["name"] : ""}`}
      open={true}
      object={step}
      canvasSelector={`.canvas[data-step-id='${step.id}']`}
    >
      <div
        className="w-full layers-container"
        data-object-type="steps"
        data-object-id={step.id}
      >
        {sorted_sections.map((section) => {
          return (
            <SectionLayer
              key={`section-${section.id}-layer`}
              section={section}
              {...props}
            />
          );
        })}
      </div>
      <div className="w-full pl-7 pb-4">
        <button
          onClick={() => {
            dispatchCustomEvent("newObject", {
              object_type: "Sections", 
              step_id: step.id
            });
          }}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2" /> Add Section
        </button>
      </div>
    </VariantBuilderPanelLayersItem>
  );
};

export default StepLayer;
