import React, { useState, useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderCanvasRoundedCorner from "../variants/VariantBuilderCanvasRoundedCorner";

const VariantBuilderPreview = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [page, setPage] = useState(props.preview ? true : "builder");
  const [progress, setProgress] = useState(0);
  const iframeRef = useRef(null);

  let iframeSrc = `/websites/${props.website.id}/cta/${props.cta.id}/page/?iframe=true&variant=${props.variant.variation}&device_type=${contextData.device}`;
  let width = contextData.device == "mobile" ? "375px" : "100%";

  useEffect(() => {
    if (props.page == "preview") {
      const iframe = iframeRef.current;

      setTimeout(() => {
        handleLoadEnd();
      }, 5000);

      const handleLoadStart = () => {
        setProgress(0);
        startLoading();
      };

      const handleLoadEnd = () => {
        setProgress(100);
      };

      const startLoading = () => {
        let interval = setInterval(() => {
          setProgress((prev) => {
            if (prev >= 100) {
              clearInterval(interval);
              return 99;
            }
            return prev + 1;
          });
        }, 20);
      };

      const handleMessage = (event) => {
        if (event.data === 'finishLoading') {
          console.log('parent finishLoading');
          handleLoadEnd();
        }
      };
  
      window.addEventListener('message', handleMessage);
      handleLoadStart();
      
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, [props.page, contextData.device]);

  return (
    <React.Fragment>
      {props.page == "preview" && (
        <React.Fragment>
          <div
            className={`flex items-center row hard-center ${
              contextData.device == "mobile" ? "pt-[49px]" : ""
            }`}
            style={{ width: width, height: "100vh" }}
          >
            <iframe
              ref={iframeRef}
              id="preview-iframe"
              src={iframeSrc}
              className={`relative ${
                contextData.device == "desktop" ? "top-[49px]" : ""
              }`}
              frameBorder={0}
              style={{
                height: `${contextData.device == "mobile" ? "812px" : "100vh"}`,
                top: `${
                  contextData.device == "mobile" && window.innerHeight < 1000
                    ? "100px"
                    : ""
                }`,
              }}
              width={width}
            />
            <div
              className="h-screen fixed top-0 left-0 bg-white dark:bg-slate-950 mt-[49px] pt-4 center smooth z-10"
              style={{
                width: "10px",
              }}
            >
              <VariantBuilderCanvasRoundedCorner
                vertical="top"
                horizontal="left"
                page={"preview"}
              />
              <VariantBuilderCanvasRoundedCorner
                vertical="bottom"
                horizontal="left"
                page={"preview"}
              />
            </div>
            <div
              className="h-screen fixed top-0 right-0 bg-white dark:bg-slate-950 mt-[49px] pt-4 center smooth z-10"
              style={{
                width: "10px",
              }}
            >
              <VariantBuilderCanvasRoundedCorner
                vertical="top"
                horizontal="right"
                page={"preview"}
              />
              <VariantBuilderCanvasRoundedCorner
                vertical="bottom"
                horizontal="right"
                page={"preview"}
              />
            </div>
          </div>
          <div
            className={`loading-wrapper flex items-center justify-center`}
            style={{ display: progress == 100 ? "none" : "flex" }}
          > 
            <img src={contextData.dark_mode ? props.logo_white : props.logo} className="w-[35px] -mt-[80px]" />
            <div className="loading-bar w-[256px]">
              <div
                className="loading-bar-progress"
                style={{ width: `${progress >= 90 ? 90 : progress}%` }}
              ></div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(VariantBuilderPreview, (prevProps, nextProps) => {
  if (prevProps.page == nextProps.page) {
    return true;
  } else {
    return false;
  }
});
