var select = function (upload_type, payload) {
  function triggerSelect(upload_type, payload) {
    var upload_path = "/" + payload.environment + payload.path;
    const client = filestack.init("Ab4UxJ7gSSG4hnZD7BeIHz");
    
    const options = {
      accept: ["image/jpeg", "image/jpg", "image/png", "image/gif"],
      maxSize: 1024 * 1024 * 10,
      maxFiles: 1,
      storeTo: {
        location: "s3",
        path: upload_path,
        access: "public",
      },
      supportEmail: "support@convertflow.com",
      fromSources: ["local_file_system", "url"],
      transformationsUI: false, 
      viewType: "grid",
      onOpen: function () {
        window.filestackOpen = true;
        if (payload.modal) $(payload.modal).modal("hide");
      },
      onClose: function () {
        window.filestackOpen = false;
        if (payload.modal) $(payload.modal).modal("show");
      },
      onFileSelected: function (file) {
        file.filename = file.filename
          .replace(/ /g, "_")
          .replace(/\(/g, "-")
          .replace(/\)/g, "-");
        return file;
      },
      onFileUploadFinished: function (file) {
        if (file && file.source == "local_file_system") {
          uploads.transform(upload_type, payload, file);

          if (payload.modal) $(payload.modal).modal("show");
        }
      },
      onUploadDone: function (upload) {
        const file = upload.filesUploaded[0];

        if (file && file.source !== "local_file_system") {
          uploads.transform(upload_type, payload, file);

          if (payload.modal) $(payload.modal).modal("show");
        }
      },
    };

    // cta builder options

    if (payload.library == true) {
      $.extend(options, {
        customSourceName: "Image Library",
        customSourceContainer: "uploads.convertflow.co",
        customSourcePath:
          payload.environment +
          "/websites/" +
          (payload.website_id || cta.website_id) +
          "/",
      });

      options["fromSources"] = [
        "local_file_system",
        "customsource",
        "unsplash",
        "url",
      ];
    }

    client.picker(options).open();
  }

  if (window.filestack) {
    triggerSelect(upload_type, payload);
  } else {
    $.getScript(
      "//static.filestackapi.com/filestack-js/3.x.x/filestack.min.js",
      function () {
        triggerSelect(upload_type, payload);
      }
    );
  }
};

export default select;
