import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CtasListToolbar from "../ctas/CtasListToolbar";
import CtasListItem from "../ctas/CtasListItem";

const TemplatesCtasList = (props) => {
  const ctas = props.ctas.map((cta, i) => {
    return (
      <CtasListItem
        key={"cta-" + cta.id}
        website={cta.website}
        cta={cta}
        list_type={props.list_type}
        role_type={props.role_type}
        hide_manage_groups={props.hide_manage_groups}
        hide_options={props.hide_options}
        hide_status={props.hide_status}
      />
    );
  });

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <React.Fragment>
      <div className="row hard-center CtasList smooth">
        <div className="col-xs-12 no-padding-sides">
          <CtasListToolbar {...props} />
          {props.list_type == "card" && (
            <div className="row hard-center ctas-list-card-container">
              {ctas}
            </div>
          )}
          {props.list_type == "row" && (
            <div className="col-xs-12">
              <div className="row hard-center flat-box ctas-list-row-container some-vertical-space">
                <div className="col-xs-12 ctas-list-row-header">
                  <div className="col-xs-4">
                    <div className="col-xs-4 no-padding-sides">
                      <p className="bold ctas-list-row-heading">Preview</p>
                    </div>
                    <div className="col-xs-8 no-padding-sides">
                      <p className="bold ctas-list-row-heading">Name</p>
                    </div>
                  </div>
                  <div className="col-xs-8">
                    <div className="col-xs-1 no-padding-sides">
                      <p className="bold ctas-list-row-heading">Variants</p>
                    </div>
                    <div className="col-xs-2">
                      <p className="bold ctas-list-row-heading">Views</p>
                    </div>
                    <div className="col-xs-2 no-padding-sides">
                      <p className="bold ctas-list-row-heading">Conversions</p>
                    </div>
                    <div className="col-xs-2 no-padding-sides">
                      <p className="bold ctas-list-row-heading">Completions</p>
                    </div>
                    <div className="col-xs-3 no-padding-sides">
                      <p className="bold ctas-list-row-heading">Campaign</p>
                    </div>
                    <div className="col-xs-2 no-padding-sides"></div>
                  </div>
                </div>
                <div className="col-xs-12">{ctas}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TemplatesCtasList;
