import React, { useEffect, useRef, useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const TabGroup = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const tabGroupRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(null);

  useEffect(() => {
    if (!offsetTop) {
      setOffsetTop(tabGroupRef.current.offsetTop);
    }
  }, [props, contextData.dark_mode]);

  return (
    <React.Fragment>
      <div
        ref={tabGroupRef}
        className={`row ${
          props.sticky !== false
            ? "sticky z-10 -mt-[2px] border-solid border-0 border-t-[1px] border-t-slate-100 dark:border-t-slate-800"
            : ""
        } bg-white dark:bg-slate-950`}
        style={{
          top: offsetTop ? `${offsetTop}px` : null,
        }}
      >
        <div
          className={`tab-group-tabs w-full grid grid-cols-${props.tabs.length} border-0 border-b-2 border-slate-100 dark:border-slate-800 rounded-xl`}
        >
          {props.tabs.map((tab) => (
            <div
              key={tab}
              className={`tab-group-toggle cursor-pointer create-tab text-center px-4 py-4 text-xl border-solid border-0 ${
                props.tab === tab
                  ? "font-semibold !border-b-[2px] text-black dark:text-white border-black dark:border-white"
                  : "!border-b-[1px] text-slate-500 dark:text-slate-300 border-slate-100 dark:border-slate-800"
              }`}
              data-tab={tab}
              onClick={() => props.setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </div>
          ))}
        </div>
      </div>
      <div className="tab-group col-xs-12 no-padding-sides w-full mt-1">
        <div className={`tab-group-content h-fit row hard-center mt-6`}>
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TabGroup;
