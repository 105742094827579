import React, { useState, useEffect, useRef } from "react";
import { LineChart } from "react-chartkick";
import "chartkick/chart.js";

const ReportConversionRateChart = (props) => {
  const [chartData, setData] = useState([]);

  const getReport = async (path, payload, callback) => {
    console.log("fetch count", payload);
    try {
      const response = await fetch(`${path}?` + $.param(payload));
      const json = await response.json();

      if (callback) {
        callback(json);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  function setChartData(viewReports, conversionReports) {
    let chartData = {};
    viewReports.forEach((viewReport) => {
      let conversionReport = conversionReports.find(
        (conversionReport) => conversionReport[0] === viewReport[0]
      );

      if (conversionReport) {
        chartData[viewReport[0]] = (
          (conversionReport[1] / viewReport[1]) *
          100
        ).toFixed(2);
      } else {
        chartData[viewReport[0]] = 0;
      }
    });
    setData(chartData);
  }

  useEffect(() => {
    getReport(props.viewPath, props.viewPayload, (viewReports) => {
      getReport(
        props.conversionPath,
        props.conversionPayload,
        (conversionReports) => {
          setChartData(viewReports, conversionReports);
        }
      );
    });
  }, [props.viewPayload, props.conversionPayload]);

  return (
    <React.Fragment>
      <LineChart
        loading="Loading..."
        data={props.count == 0 ? [] : chartData}
        thousands=","
        height={props.height}
        min={0}
        max={100}
      />
    </React.Fragment>
  );
};

export default ReportConversionRateChart;
