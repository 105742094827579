import React from "react";
import PropTypes from "prop-types";
import FieldOptionDropdown from "../field_options/FieldOptionDropdown";
import FieldOptionRadio from "../field_options/FieldOptionRadio";
import FieldOptionChoice from "../field_options/FieldOptionChoice";
import FieldOptionVisualChoice from "../field_options/FieldOptionVisualChoice";

const FieldOption = (props) => {
  let field = props.field;

  return (
    <React.Fragment>
      {field.field_type == "dropdown" && <FieldOptionDropdown {...props} />}
      {(field.field_type == "select" ||
        field.field_type == "radio" ||
        field.field_type == "checkbox") && <FieldOptionRadio {...props} />}

      {field.field_type == "survey" ? (
        props.renderVisualComponent ? (
          <FieldOptionVisualChoice {...props} />
        ) : (
          <FieldOptionChoice {...props} />
        )
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default FieldOption;
