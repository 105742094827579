var broadcasts = {};

import initialize, {} from 'packs/broadcasts/initialize.js';
broadcasts.initialize = initialize;

import builder, {} from 'packs/broadcasts/builder.js';
broadcasts.builder = builder;


document.addEventListener('turbolinks:load', function() {

	if ($('body').attr('data-controller') == 'broadcasts' && $('body').attr('data-action') == 'index') {

		$('.broadcast').each(function(i, broadcast_div) {
			$(broadcast_div).find('.campaign-toggle-checkbox').on('click', function() {
				
				$.ajax({
			    type: "PUT",
			    url: '/broadcasts/' + $(broadcast_div).attr('data-id'),
			    data: {
			    	broadcast: {
					    live: $(this).is(':checked')
					  }
			    }
			  });
			})
		});

		
		$('#broadcast-search select').change(function() {
			$('#broadcast-search').submit()
		})

		$('#broadcast-search input[name="name"]').keyup(function() {
			if ($(this).val().length > 0) {
				$(this).parent().find('.search').css('color', '#1892f5');
			} else {
				$(this).parent().find('.search').css('color', '#696969');
			}
		});

		$('#broadcast-search .search').click(function() {
			$('#broadcast-search').submit()
		})
		
	}
	
});


export default broadcasts;