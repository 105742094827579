import React, { useState, useEffect, useRef } from 'react';
import Area from './Area';
import NewArea from './NewArea';

const AreasList = (props) => {
  const [websiteAreas, setWebsiteAreas] = useState(props.website.areas);

  return (
    <React.Fragment>
      {Object.values(websiteAreas).map((area) => (
        <Area key={`area-${area.id}`} {...props} area={area} websiteAreas={websiteAreas} setWebsiteAreas={setWebsiteAreas} />
      ))}

      <NewArea {...props} websiteAreas={websiteAreas} setWebsiteAreas={setWebsiteAreas} />
    </React.Fragment>
  );
};

export default AreasList;