import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderPanelBreadcrumb = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  function selectObject(object_type, object_id, column) {
    let labels = ".select-label[data-object_id!='" + object_id + "']";

    $(labels).hide();
    if (column) $(".select-label[data-column!='" + column + "']").hide();

    builder.select(object_type, object_id, column ? "Design" : null, column);
  }

  let breadcrumbs = [];

  if (props.object_type == "steps") {
    breadcrumbs.push(
      <span
        key="breadcrumb-steps-"
        onClick={() => {
          $('[data-tab="steps"]').trigger("click");
        }}
      >
        Funnel
      </span>
    );

    breadcrumbs.push(
      <span
        key={"breadcrumb-steps-" + props.object.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => {
          selectObject("steps", props.object.id);
        }}
        data-div-selector={'.canvas[data-step-id="' + props.object.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="steps"][data-object_id="' +
          props.object.id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        Step {props.object.position}
      </span>
    );
  }

  if (props.object_type == "sections" && !props.object.element_id) {
    let step = contextData.objects.variant.steps[props.object.step_id];
    if (!step) return;

    breadcrumbs.push(
      <span
        key="breadcrumb-steps-"
        onClick={() => {
          $('[data-tab="steps"]').trigger("click");
        }}
      >
        Funnel
      </span>
    );

    breadcrumbs.push(
      <span
        key={"breadcrumb-steps-" + props.object.step_id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("steps", props.object.step_id)}
        data-div-selector={
          '.canvas[data-step-id="' + props.object.step_id + '"]'
        }
        data-label-selector={
          '.select-label[data-object_type="steps"][data-object_id="' +
          props.object.step_id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        Step {step.position}
      </span>
    );

    if (!props.object.element_id) {
      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + props.object.id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", props.object.id)}
          data-div-selector={
            '.cf-section-overlay[data-section-id="' + props.object.id + '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            props.object.id +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Section
        </span>
      );
    }

    if (props.object.element_id) {
      let section_id =
        contextData.objects.variant.elements[props.object.element_id]
          .section_id;
      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + section_id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", section_id)}
          data-div-selector={
            '.cf-section-overlay[data-section-id="' + section_id + '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            section_id +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Section
        </span>
      );
    }
  }

  if (
    props.object_type == "elements" ||
    (props.object_type == "sections" && props.object.element_id)
  ) {
    let section =
      contextData.objects.variant.sections[
        props.object.section_id || props.object.id
      ];
    if (!section) return;

    if (!section.element_id) {
      breadcrumbs.push(
        <span
          key={"breadcrumb-steps-" + section.step_id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("steps", section.step_id)}
          data-div-selector={'.canvas[data-step-id="' + section.step_id + '"]'}
          data-label-selector={
            '.select-label[data-object_type="steps"][data-object_id="' +
            section.step_id +
            '"]'
          }
        >
          Step {contextData.objects.variant.steps[section.step_id].position}
        </span>
      );

      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + section.id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", section.id)}
          data-div-selector={
            '.cf-section-overlay[data-section-id="' + section.id + '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            section.id +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Section
        </span>
      );
    }

    if (
      section.element_id &&
      contextData.objects.variant.elements[section.element_id]
    ) {
      let row_element =
        contextData.objects.variant.elements[section.element_id];
      if (!row_element) return;
      let parent_section =
        contextData.objects.variant.sections[row_element.section_id];
      if (!parent_section) return;
      let step = contextData.objects.variant.steps[parent_section.step_id];
      if (!step) return;

      breadcrumbs.push(
        <span
          key={"breadcrumb-steps-" + step.id}
          onClick={(e) => selectObject("steps", step.id)}
          data-div-selector={'.canvas[data-step-id="' + step.id + '"]'}
          data-label-selector={
            '.select-label[data-object_type="steps"][data-object_id="' +
            step.id +
            '"]'
          }
        >
          Step {step.position}
        </span>
      );

      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + parent_section.id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", parent_section.id)}
          data-div-selector={
            '.cf-section-overlay[data-section-id="' + parent_section.id + '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            parent_section.id +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          {!parent_section.element_id ? "Section" : "... "}
          {parent_section.element_id && (
            <React.Fragment>
              <span
                className="glyphicon glyphicon-menu-right"
                aria-hidden="true"
              ></span>{" "}
              Row
            </React.Fragment>
          )}
        </span>
      );

      let column =
        row_element.column == 1
          ? "first"
          : row_element.column == 2
          ? "second"
          : row_element.column == 3
          ? "third"
          : row_element.column == 4
          ? "fourth"
          : "";
      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + parent_section.id + "-column-" + column}
          onClick={(e) =>
            selectObject("sections", parent_section.id, column, false)
          }
          data-div-selector={
            '.cf-column[data-section-id="' +
            parent_section.id +
            '"][data-column-position="' +
            column +
            '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            parent_section.id +
            '"][data-column="' +
            column +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Col {row_element.column}
        </span>
      );

      if (section.content_toggle_item_id) {
        let collapse_element_id =
          contextData.objects.variant.content_toggle_items[
            section.content_toggle_item_id
          ].element_id;
        if (!collapse_element_id) return;

        let element_type =
          contextData.objects.variant.elements[collapse_element_id]
            ?.element_type;
        if (!element_type) return;

        let label =
          element_type.charAt(0).toUpperCase() + element_type.slice(1);

        breadcrumbs.push(
          <span
            key={`breadcrumb-elements-${collapse_element_id}`}
            className="options-panel-breadcrumbs-nested"
            onClick={(e) => selectObject("elements", collapse_element_id)}
            data-div-selector={`.cf-element[dat-id="${collapse_element_id}"]`}
            data-label-selector={`.select-label[data-object_type="elements"][data-object_id="${collapse_element_id}"]`}
          >
            <span
              className="glyphicon glyphicon-menu-right"
              aria-hidden="true"
            ></span>{" "}
            {label}
          </span>
        );
      }

      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + section.id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", section.id)}
          data-div-selector={
            '.cf-section-overlay[data-section-id="' + section.id + '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            section.id +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Row
        </span>
      );
    }
  }

  if (props.object_type == "elements") {
    let column =
      props.object.column == 1
        ? "first"
        : props.object.column == 2
        ? "second"
        : props.object.column == 3
        ? "third"
        : props.object.column == 4
        ? "fourth"
        : "";

    let section = contextData.objects.variant.sections[props.object.section_id];
    if (!section) return;

    if (!section.content_toggle_item_id) {
      breadcrumbs.push(
        <span
          key={
            "breadcrumb-sections-" +
            props.object.section_id +
            "-column-" +
            column
          }
          className="options-panel-breadcrumbs-nested"
          onClick={(e) =>
            selectObject("sections", props.object.section_id, column, false)
          }
          data-div-selector={
            '.cf-column[data-section-id="' +
            props.object.section_id +
            '"][data-column-position="' +
            column +
            '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            props.object.section_id +
            '"][data-column="' +
            column +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Col {props.object.column}
        </span>
      );
    }

    breadcrumbs.push(
      <span
        key={"breadcrumb-elements-" + props.object.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("elements", props.object.id)}
        data-div-selector={'.cf-element[data-id="' + props.object.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="elements"][data-object_id="' +
          props.object.id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        {humanizeStr(props.object.element_type)}
      </span>
    );
  }

  if (props.object_type == "fields" && !props.object.field_id) {
    const field = props.object;
    const fieldPosition =
      Object.values(contextData.objects.variant.fields)
        .filter(
          (f) =>
            f.element_id == f.element_id &&
            f.toBeDeleted !== true &&
            !f.field_id
        )
        .sort((a, b) => a.position - b.position)
        .findIndex((f) => f.id == field.id) + 1;
    const element = contextData.objects.variant.elements[field.element_id];
    if (!element) return;

    const column =
      element.column == 1
        ? "first"
        : element.column == 2
        ? "second"
        : element.column == 3
        ? "third"
        : element.column == 4
        ? "fourth"
        : "";

    let section = contextData.objects.variant.sections[element.section_id];
    if (!section) return;

    if (!section.content_toggle_item_id) {
      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + section.id}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", section.id)}
          data-div-selector={
            '.cf-section-overlay[data-section-id="' + section.id + '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            section.id +
            '"]'
          }
        >
          Section
        </span>
      );

      breadcrumbs.push(
        <span
          key={"breadcrumb-sections-" + element.id + "-column-" + column}
          className="options-panel-breadcrumbs-nested"
          onClick={(e) => selectObject("sections", element.id, column, false)}
          data-div-selector={
            '.cf-column[data-section-id="' +
            element.id +
            '"][data-column-position="' +
            column +
            '"]'
          }
          data-label-selector={
            '.select-label[data-object_type="sections"][data-object_id="' +
            element.id +
            '"][data-column="' +
            column +
            '"]'
          }
        >
          <span
            className="glyphicon glyphicon-menu-right"
            aria-hidden="true"
          ></span>{" "}
          Col {element.column}
        </span>
      );
    }

    breadcrumbs.push(
      <span
        key={"breadcrumb-elements-" + element.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("elements", element.id)}
        data-div-selector={'.cf-element[data-id="' + element.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="elements"][data-object_id="' +
          element.id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        {capitalizeFirstLetter(element.element_type)}
      </span>
    );

    breadcrumbs.push(
      <span
        key={"breadcrumb-fields-" + field.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("fields", field.id)}
        data-div-selector={'.cf-element[data-id="' + field.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="fields"][data-object_id="' +
          field.id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        {`Question ${fieldPosition || field.position}`}
      </span>
    );
  }

  if (props.object_type == "fields" && props.object.field_id) {
    const element =
      contextData.objects.variant.elements[props.object.element_id];
    if (!element) return;

    const parent_field =
      contextData.objects.variant.fields[props.object.field_id];
    if (!parent_field) return;

    const field = props.object;
    const fieldPosition =
      Object.values(contextData.objects.variant.fields)
        .filter(
          (f) =>
            f.element_id == element.id && f.toBeDeleted !== true && !f.field_id
        )
        .sort((a, b) => a.position - b.position)
        .findIndex((f) => f.id == parent_field.id) + 1;
    const subFieldPosition =
      Object.values(contextData.objects.variant.fields)
        .filter((f) => f.toBeDeleted !== true && f.field_id == field.field_id)
        .sort((a, b) => a.position - b.position)
        .findIndex((f) => f.id == field.id) + 1;

    breadcrumbs.push(
      <span
        key={"breadcrumb-elements-" + element.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("elements", element.id)}
        data-div-selector={'.cf-element[data-id="' + element.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="elements"][data-object_id="' +
          element.id +
          '"]'
        }
      >
        {capitalizeFirstLetter(element.element_type)}
      </span>
    );

    breadcrumbs.push(
      <span
        key={"breadcrumb-fields-" + parent_field.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("fields", parent_field.id)}
        data-div-selector={'.cf-element[data-id="' + parent_field.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="fields"][data-object_id="' +
          parent_field.id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        {`Question ${fieldPosition || parent_field.position}`}
      </span>
    );

    breadcrumbs.push(
      <span
        key={"breadcrumb-fields-" + field.id}
        className="options-panel-breadcrumbs-nested"
        onClick={(e) => selectObject("fields", field.id)}
        data-div-selector={'.cf-element[data-id="' + field.id + '"]'}
        data-label-selector={
          '.select-label[data-object_type="fields"][data-object_id="' +
          field.id +
          '"]'
        }
      >
        <span
          className="glyphicon glyphicon-menu-right"
          aria-hidden="true"
        ></span>{" "}
        {`Sub-Question ${subFieldPosition || field.position}`}
      </span>
    );
  }

  useEffect(() => {
    document
      .querySelectorAll(".options-panel-breadcrumbs span[data-div-selector]")
      .forEach((span) => {
        let divSelector = span.getAttribute("data-div-selector");
        let div = document.querySelector(divSelector);

        let labelSelector = span.getAttribute("data-label-selector");
        let label = document.querySelector(labelSelector);

        span.onmouseover = function () {
          if (div) div.classList.add("editor-select");
          if (label) label.style.display = "block";
        };

        span.onmouseleave = function () {
          if (div) div.classList.remove("editor-select");
          if (label) label.style.display = "none";
        };
      });
  });

  return (
    <React.Fragment>
      <div className="options-panel-breadcrumbs w-full mt-4 text-lg text-slate-500 dark:text-slate-300">
        {breadcrumbs.map((breadcrumb) => {
          return breadcrumb;
        })}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderPanelBreadcrumb;
