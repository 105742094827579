import React from "react";
import PropTypes from "prop-types";
import CtasListItemCard from "../ctas/CtasListItemCard";
import CtasListItemRow from "../ctas/CtasListItemRow";

const CtasListItem = (props) => {
  const variant =
    props.cta.variants.filter((v) => v.percentage > 0)[0] ||
    props.cta.variants[0];
  return (
    <React.Fragment>
      <div className="CtasListItem" data-id={props.cta.id}>
        {props.list_type == "card" && (
          <CtasListItemCard variant={variant} {...props} />
        )}
        {props.list_type == "row" && (
          <CtasListItemRow variant={variant} {...props} />
        )}
      </div>
    </React.Fragment>
  );
};

export default CtasListItem;
