import React from "react";
import PropTypes from "prop-types";
import VariantProfile from "../variants/VariantProfile";
import Step from "../steps/Step";
import Section from "../sections/Section";
import VariantTheme from "../variants/VariantTheme";
import VariantClose from "../variants/VariantClose";

const Variant = (props) => {
  let cta = props.cta;
  let variant = props.variant;
  let show_profile =
    variant.options["show-profile"] &&
    variant.options["show-profile"] + "" !== "false";

  let classes = "cf-container cf-row cf-outer-center";

  if (
    ["hook", "overlay"].includes(cta.cta_type) &&
    variant.options["show-shadow"] !== "false"
  ) {
    classes += " cf-container-shadow";
  }

  return (
    <React.Fragment>
      <div className={classes}>
        {show_profile == true && cta.cta_type == "inline" && (
          <VariantProfile
            variant={variant}
            cta={cta}
            website={props.website}
            user={props.user}
          />
        )}
        {Object.values(variant.steps).map((step, i) => (
          <Step
            key={"step-" + step.id}
            website={props.website}
            cta={cta}
            variant={variant}
            step={step}
          />
        ))}
        {show_profile == true &&
          (cta.cta_type == "hook" || cta.cta_type == "overlay") && (
            <VariantProfile
              variant={variant}
              cta={cta}
              website={props.website}
              user={props.user}
            />
          )}
        {["overlay", "hook", "bar"].includes(cta.cta_type) && (
          <VariantClose variant={variant} cta={cta} website={props.website} />
        )}
        <VariantTheme {...props} />
      </div>
    </React.Fragment>
  );
};

export default Variant;
