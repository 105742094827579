import React, { useContext, useState, useEffect } from "react";
import EmojiSelectorField from "../shared/EmojiSelectorField";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingEmoji = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  function updateSetting(value) {
    if (props.skip_update !== true) {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          skip_history: props.skip_history,
        },
      ]);
    }

    if (props.callback) {
      let old_value = props.value;
      props.callback(value, old_value);
    }
  }

  return (
    <React.Fragment>
      <EmojiSelectorField
        onSelect={updateSetting}
        selectedIcon={props.value}
        {...props}
      />
    </React.Fragment>
  );
};

export default VariantBuilderSettingEmoji;
