import React from "react";
import PropTypes from "prop-types";
import CtasListItemOptions from "../ctas/CtasListItemOptions";
import CtasListItemStatus from "../ctas/CtasListItemStatus";
import CtaGroupsSmallList from "../cta_groups/CtaGroupsSmallList";

const CtasListItemCard = (props) => {
  const websiteLink = "/websites/" + props.cta.website_id;
  const ctaLink = websiteLink + "/cta/" + props.cta.id;
  const launchLink = ctaLink + "/launch";

  return (
    <React.Fragment>
      <div className="CtasListItemCard p-3 border-solid border-[1px] border-slate-200 !rounded-3xl shadow-sm">
        <div
          className="row hard-center ctas-list-item-card-preview"
          style={{
            backgroundImage:
              "url(" +
              (props.variant && props.variant.screenshot
                ? props.variant.screenshot
                : "") +
              ")",
          }}
        >
          <div className="row hard-center ctas-list-item-card-overlay">
            <a href={ctaLink}>
              <div className="row hard-center ctas-list-item-card-stats text-xl">
                <div className="row hard-center ctas-list-item-card-stats-row">
                  <div className="col-xs-6 no-padding-sides">
                    <p className="font-semibold">Statistics</p>
                  </div>
                  <div className="col-xs-6 no-padding-sides right">
                    <p className="font-semibold">Totals</p>
                  </div>
                </div>
                <div className="row hard-center ctas-list-item-card-stats-row">
                  <div className="col-xs-6 no-padding-sides">
                    <p>Viewers</p>
                  </div>
                  <div className="col-xs-6 no-padding-sides right">
                    <p>{numberWithCommas(props.cta.views)}</p>
                  </div>
                </div>
                <div className="row hard-center ctas-list-item-card-stats-row">
                  <div className="col-xs-6 no-padding-sides">
                    <p>Conversions</p>
                  </div>
                  <div className="col-xs-6 no-padding-sides right">
                    <p>
                      {numberWithCommas(props.cta.conversions)} (
                      {parseFloat(props.cta.conversion_rate).toFixed(2)}%)
                    </p>
                  </div>
                </div>
                <div
                  className="row hard-center ctas-list-item-card-stats-row"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <div className="col-xs-6 no-padding-sides">
                    <p>Completions</p>
                  </div>
                  <div className="col-xs-6 no-padding-sides right">
                    <p>
                      {numberWithCommas(props.cta.completions)} (
                      {parseFloat(props.cta.completion_rate).toFixed(2)}%)
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <div className="row hard-center ctas-list-item-card-info">
              <div className="col-xs-12">
                {props.hide_manage_groups == true ? (
                  ""
                ) : (
                  <CtaGroupsSmallList
                    groups={props.cta.groups}
                    light={true}
                    trim={true}
                    {...props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row hard-center ctas-list-item-card-name">
          <a href={ctaLink}>
            <div className="col-xs-10 no-padding-sides ellipsis">
              <span
                className="cta-name !font-semibold ellipsis"
                title={props.cta.name}
                style={{
                  fontSize: '13px'
                }}
              >
                {props.cta.name}
              </span>
            </div>
          </a>
          <div className="col-xs-2 no-padding-right text-right">
            {props.hide_options == true ? (
              ""
            ) : (
              <CtasListItemOptions {...props} />
            )}
          </div>
          <div className="col-xs-8 no-padding-left">
            <span className="light capitalize" style={{fontSize: '12px'}}>
              {props.cta.cta_type !== "inline"
                ? props.cta.cta_type
                : "Embedded"}
            </span> 
          </div>
          <div className="col-xs-4 right no-padding-sides -mt-3 -mr-3">
            {props.hide_status == true ? (
                ""
              ) : (
                <CtasListItemStatus {...props} />
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CtasListItemCard;
