import React from "react";
import PropTypes from "prop-types";
import CountriesArray from "../../utils/CountriesArray";

const ElementFormPresetFields = (props) => {
  let element = props.element;
  let options = element.options;
  let important = props.builder !== true ? " !important" : "";
  let website = props.website;

  let preset_fields = [
    {
      field: "name",
      attribute: "name",
      type: "text",
      placeholder: "Your first name",
      label: "First name",
    },
    {
      field: "last_name",
      attribute: "lastname",
      type: "text",
      placeholder: "Your last name",
      label: "Last name",
    },
    {
      field: "email",
      attribute: "email",
      type: "email",
      placeholder: "Your email address",
      label: "Email",
    },
    {
      field: "phone",
      attribute: "phone",
      type: "tel",
      placeholder: "Your phone number",
      label: "Phone",
    },
    {
      field: "url",
      attribute: "url",
      type: "text",
      placeholder: "Your website URL",
      label: "Website",
    },
    {
      field: "company_name",
      attribute: "company-name",
      type: "text",
      placeholder: "Your company name",
      label: "Company name",
    },
    {
      field: "company_title",
      attribute: "company-title",
      type: "text",
      placeholder: "Your professional title",
      label: "Company title",
    },
    {
      field: "city",
      attribute: "city",
      type: "text",
      placeholder: "Your city",
      label: "City",
    },
    {
      field: "state",
      attribute: "state",
      type: "text",
      placeholder: "Your state",
      label: "State",
    },
    {
      field: "country",
      attribute: "country",
      type: "text",
      placeholder: "Your country",
      label: "Country",
    },
    {
      field: "address",
      attribute: "address",
      type: "text",
      placeholder: "Your address",
      label: "Address",
    },
    {
      field: "zip_code",
      attribute: "zip-code",
      type: "text",
      placeholder: "Your zip code",
      label: "Zip code",
    },
  ];

  const countries = CountriesArray;

  let countryField = (
    <input
      type="text"
      name={"contact[country]"}
      placeholder={
        options["country-placeholder"] ? options["country-placeholder"] : ""
      }
    ></input>
  );

  let phonePresetField = preset_fields.find((e) => e.field == "phone");
  let phoneField = (
    <React.Fragment>
      {options["phone-country-code-hide"] !== "true" && (
        <div
          className="cf-form-field-wrapper"
          style={{ display: "inline-block", marginRight: "5px" }}
        >
          <p
            className="cf-label"
            style={{
              display: "block",
              color: options["label-color"]
                ? options["label-color"] + important
                : undefined,
            }}
            htmlFor={"contact[" + phonePresetField.field + "]"}
          >
            {options["phone-country-code-label"]
              ? options["phone-country-code-label"]
              : ""}
          </p>
          <select
            className="cf-sms-countries"
            value={
              options["phone-default-country"] ||
              (website.data && website.data["default-country"]) ||
              "US"
            }
            tabIndex="-1"
          >
            <ElementFormPresetFieldsCountryCodes countries={countries} />
          </select>
        </div>
      )}

      <div
        className="cf-form-field-wrapper"
        style={{
          display: "inline-block",
          width: `${
            element.options["phone-country-code-hide"] !== "true"
              ? "calc(100% - 54px)"
              : "100%"
          }`,
        }}
      >
        <p
          className="cf-label"
          style={{
            display: "block",
            color: options["label-color"]
              ? options["label-color"] + important
              : undefined,
            display: !options[phonePresetField.attribute + "-label"]
              ? "none" + important
              : undefined,
          }}
          htmlFor={"contact[" + phonePresetField.field + "]"}
        >
          {options[phonePresetField.attribute + "-label"]
            ? options[phonePresetField.attribute + "-label"]
            : ""}
        </p>
        <input
          type={phonePresetField.type}
          name={"contact[" + phonePresetField.field + "]"}
          placeholder={
            options[phonePresetField.attribute + "-placeholder"]
              ? options[phonePresetField.attribute + "-placeholder"]
              : ""
          }
          required={
            options[phonePresetField.attribute + "-required"] == "true"
              ? true
              : false
          }
          maxLength={
            options[phonePresetField.attribute + "-maximum-characters"]
              ? options[phonePresetField.attribute + "-maximum-characters"]
              : undefined
          }
          minLength={
            options[phonePresetField.attribute + "-minimum-characters"]
              ? options[phonePresetField.attribute + "-minimum-characters"]
              : undefined
          }
        />
      </div>
    </React.Fragment>
  );

  if (element.options["show-country"] == "true") {
    countryField = (
      <select
        name={"contact[country]"}
        placeholder={
          options["country-placeholder"]
            ? options["country-placeholder"]
            : "Select a country"
        }
        required={options["country-required"] == "true" ? true : false}
        defaultValue=""
      >
        <option value="" disabled hidden>
          {options["country-placeholder"]
            ? options["country-placeholder"]
            : "Select a country"}
        </option>
        <ElementFormPresetFieldsCountries countries={countries} />
      </select>
    );
  }
  return (
    <React.Fragment>
      {preset_fields.map((preset_field) => {
        return (
          <div
            key={"element-" + element.id + "-form-" + preset_field.attribute}
            className="cf-form-field"
            data-pos={options[preset_field.attribute + "-position"]}
            data-field={preset_field.attribute}
            data-visible={
              options["show-" + preset_field.attribute]
                ? options["show-" + preset_field.attribute]
                : preset_field.attribute == "email"
                ? "true"
                : ""
            }
            data-adjacent={
              preset_field.attribute == "name" &&
              options["show-lastname"] == "true" &&
              parseInt(options["name-position"]) + 1 + "" ==
                options["lastname-position"]
                ? "lastname"
                : ""
            }
            style={{
              order: options[preset_field.attribute + "-position"],
            }}
          >
            {preset_field.attribute == "phone" &&
            options["show-phone"] == "true" ? (
              phoneField
            ) : (
              <React.Fragment>
                <p
                  className="cf-label"
                  style={{
                    color: options["label-color"]
                      ? options["label-color"] + important
                      : undefined,
                    display: !options[preset_field.attribute + "-label"]
                      ? "none" + important
                      : undefined,
                  }}
                >
                  {options[preset_field.attribute + "-label"]
                    ? options[preset_field.attribute + "-label"]
                    : ""}
                </p>
                {preset_field.attribute !== "country" && (
                  <input
                    type={preset_field.type}
                    name={"contact[" + preset_field.field + "]"}
                    placeholder={
                      options[preset_field.attribute + "-placeholder"]
                        ? options[preset_field.attribute + "-placeholder"]
                        : ""
                    }
                    required={
                      options[preset_field.attribute + "-required"] == "true"
                        ? true
                        : false
                    }
                    maxLength={
                      options[preset_field.attribute + "-maximum-characters"]
                        ? options[
                            preset_field.attribute + "-maximum-characters"
                          ]
                        : undefined
                    }
                    minLength={
                      options[preset_field.attribute + "-minimum-characters"]
                        ? options[
                            preset_field.attribute + "-minimum-characters"
                          ]
                        : undefined
                    }
                  ></input>
                )}
              </React.Fragment>
            )}
            {preset_field.attribute == "country" && countryField}
            {["email", "phone"].includes(preset_field.attribute) && (
              <React.Fragment>
                <img
                  className="cf-email-validation"
                  src="https://img.convertflow.co/static/loader.png"
                />
              </React.Fragment>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

const ElementFormPresetFieldsCountries = (props) => {
  const countries = props.countries;
  return (
    <React.Fragment>
      {countries.map((country, i) => {
        return (
          <option key={`${country.name}-${i}`} value={country.name}>
            {country.name}
          </option>
        );
      })}
    </React.Fragment>
  );
};

const ElementFormPresetFieldsCountryCodes = (props) => {
  const countries = props.countries;
  return (
    <React.Fragment>
      {countries.map((country, i) => {
        return (
          <option
            key={`${country.name}-${i}`}
            value={country.country_code}
            data-dial_code={country.dial_code}
          >
            {country.flag} {country.name} (+{country.dial_code})
          </option>
        );
      })}
    </React.Fragment>
  );
};

export default ElementFormPresetFields;
