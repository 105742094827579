import React, { useContext, useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingImage = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let value = props.value ? props.value : "";
  let website_id = contextData.objects["website"].id;

  if (
    props.value &&
    props.value.indexOf("<img") > -1 &&
    props.value.indexOf("/>") > -1
  ) {
    let div_tag = document.createElement("div");
    div_tag.innerHTML = props.value;
    let image = $(div_tag).find("img")[0];
    value = image ? image.src : value;
  }
  const [defaultValue, setDefaultValue] = useState(value);
  const [timeoutId, setTimeoutId] = useState(null);

  function updateSetting(e) {
    let timeoutIdTemp;
    let value = e.target.value;
    let old_value = props.value;
    setDefaultValue(e.target.value);
    clearTimeout(timeoutId);

    timeoutIdTemp = setTimeout(function () {
      if (props.inline_image == true) {
        let inline_image = document.querySelector(props.inline_image_selector);
        inline_image.src = value;
        value = inline_image.outerHTML;
      }

      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          skip_history: props.skip_history,
        },
      ]);

      if (props.callback) {
        props.callback(value, old_value);
      }
    }, 1000);

    setTimeoutId(timeoutIdTemp);
  }

  useEffect(() => {
    setDefaultValue(value);
  }, [props.object_id]);

  return (
    <React.Fragment>
      <input
        type="text"
        setting_name={props.setting_name}
        object_type={props.object_type}
        object_id={props.object_id}
        className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0"
        value={defaultValue}
        onInput={updateSetting}
        onChange={updateSetting}
        style={{
          pointerEvents:
            contextData.objects["subscription"]["plan_type"] == "free"
              ? "none"
              : undefined,
        }}
      />
      <div className="mt-5">
        <button
          className={`border-solid border-[1px] py-2 px-5 rounded-lg text-lg ml-1 mr-5 bg-black dark:bg-slate-700 border-black dark:border-slate-700 text-white dark:text-white cursor-pointer`}
          onClick={() => {
            let parsed_selector = `input[setting_name="${props.setting_name}"][object_type="${props.object_type}"]`;
            if (props.object_id) {
              parsed_selector += `[object_id="${props.object_id}"]`;
            }

            uploads.select("input", {
              selector: parsed_selector,
              environment: environment,
              path: "/websites/" + website_id + "/",
              website_id: website_id,
              library: true,
            });
          }}
        >
          <FontAwesomeIcon icon="fas fa-upload" className="mr-3" />
          Upload
        </button>
        {value && props.inline_image !== true && (
          <button
            className={`semibold border-solid border-[1px] py-2 px-5 rounded-lg text-lg ml-1 mr-1 bg-transparent border-slate-200 dark:border-slate-700 text-black dark:text-white cursor-pointer`}
            onClick={() => {
              builder.update([
                {
                  object_type: props.object_type,
                  object_id: props.object_id,
                  setting_name: props.setting_name,
                  value: "",
                  skip_history: props.skip_history,
                },
              ]);
              setDefaultValue("");
            }}
          >
            <FontAwesomeIcon icon="fas fa-times" className="mr-3" />
            Remove
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingImage;
