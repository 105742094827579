var transform = function (upload_type, payload, file) {
  function runTransform(upload_type, payload, file) {
    console.log("payload", payload);
    console.log("file", file);
    var transformation_url =
      'https://cdn.filestackcontent.com/resize=w:2000,f:max/quality=v:80/store=l:s3,a:public,r:us-east-1,c:uploads.convertflow.co,p:"' +
      payload.environment +
      payload.path +
      '",f:' +
      file.filename +
      "/" +
      file.handle;

    $.ajax({
      type: "GET",
      url: transformation_url,
      dataType: "json",
      success: function (data) {
        uploads.callback(upload_type, payload, file, data);
      },
    });
  }

  function handleNSFW(upload_type, payload, file, sfw) {
    $.ajax({
      type: "POST",
      url: window.location.origin + "/users/process_image?process_type=nsfw",
      dataType: "json",
      data: {
        file_path: file.key,
        file_type: file.mimetype,
        file_url: "https://uploads.convertflow.co/" + file.key,
        preview_handle: file.handle,
        sfw: sfw,
        user_id: payload.user_id,
        website_id: payload.website_id,
        account_id: payload.account_id,
      },
    });
    alert(
      "This image does not meet our content policies. Please upload another image."
    );
  }

  function sfwCheck(upload_type, payload, file) {
    $.ajax({
      type: "GET",
      url:
        "https://cdn.filestackcontent.com/security=p:eyJleHBpcnkiOjE5Mjg3MjUyMDB9,s:ea4369b4da7099fb01d337d687464e6c5fa50bf4c59afc8fc1f94aad14e023e4/sfw/" +
        file.handle,
      dataType: "json",
      success: function (response) {
        if (response && response["sfw"] == true) {
          runTransform(upload_type, payload, file);
        } else {
          handleNSFW(upload_type, payload, file, response["sfw"]);
        }
      },
    });
  }

  var plan = $("#upgrade").attr("data-plan");
  var plan_type = $("#upgrade").attr("data-plan-type");
  var trial = $("#upgrade").attr("data-trial");
  
  const needsNSFWCheck = plan_type == "free" || (trial == "true" && plan.indexOf('shopify') == -1);
  console.log('needsNSFWCheck', needsNSFWCheck);

  if (needsNSFWCheck) {
    sfwCheck(upload_type, payload, file);
  } else {
    runTransform(upload_type, payload, file);
  };
};

export default transform;
