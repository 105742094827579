var initialize = function () {
  $(".conditions-group").each(function (i, conditions_group_div) {
    $(conditions_group_div)
      .find(".conditions-group-name-container span")
      .click(function () {
        $(conditions_group_div)
          .find(".conditions-group-name-form-container")
          .show();
        $(conditions_group_div).find(".conditions-group-name-container").hide();
      });
  });
};

export default initialize;
