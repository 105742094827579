import React from "react";
import PropTypes from "prop-types";
class HelloWorld extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={"col-xs-12 some-vertical-space " + this.props.class_to_use}
        >
          Greeting: {this.props.greeting}
        </div>
      </React.Fragment>
    );
  }
}

HelloWorld.propTypes = {
  greeting: PropTypes.string,
};
export default HelloWorld;
