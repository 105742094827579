import React, { useContext, useState } from "react";
import apiRequest from "../../utils/apiRequest";

const useAreas = () => {
  const Areas = {
    create: async function (website, payload, callback) {
      const api_endpoint = `/websites/${website.id}/areas`;

      apiRequest("POST", api_endpoint, payload, (data) => {
        if (callback) {
          callback(data);
        }
      });
    },

    update: async function (website, payload, callback) {
      const api_endpoint = `/websites/${website.id}/areas/${payload.area.id}`;

      apiRequest("PUT_WITH_RESPONSE", api_endpoint, payload, (data) => {
        if (callback) {
          callback(data);
        }
      });
    },

    remove: (website, area, callback) => {
      let message =
        "Are you sure you want to remove this area? This will effect all campaigns.";

      if (confirm(message)) {
        apiRequest("DELETE", `/websites/${website.id}/areas/${area.id}`, {}, () => {
          if (callback) {
            callback();
          }
        });
      }
    },
  };

  return { Areas };
};

export default useAreas;
