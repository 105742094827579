import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { compileString } from "sass";

const FieldLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;
  const field = props.field;
  const sub_fields = !field.field_id
    ? Object.values(contextData.objects.variant.fields).filter(
        (field_item) =>
          field_item.id !== field.id &&
          field_item.field_id == field.id &&
          field_item.toBeDeleted !== true
      )
    : [];

  return (
    <React.Fragment>
      <VariantBuilderPanelLayersItem
        object_type="fields"
        object_id={field.id}
        object={field}
        title={props.title}
        element={element}
        hasCaret={field.field_id || sub_fields.length == 0 ? false : true}
        canvasSelector={`.cf-element[data-element-id="${element.id}"]`}
      >
        {!field.field_id && (
          <React.Fragment>
            <div
              className="w-full layers-container"
              data-object-type="fields"
              data-object-id={field.id}
            >
              {sub_fields.map((sub_field, i) => {
                return (
                  <FieldLayer
                    key={`field-${sub_field.id}-layer`}
                    element={element}
                    field={sub_field}
                    title={`Sub-Question ${i + 1}`}
                  />
                );
              })}
            </div>
          </React.Fragment>
        )}
      </VariantBuilderPanelLayersItem>
    </React.Fragment>
  );
};

export default FieldLayer;
