import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeList = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Lists" open={false}>
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list_visual_type]"
          label="Item Icon Type"
          setting_type="dropdown"
          options={[
            { value: "", text: "Empty" },
            { value: "bullet", text: "Bullet" },
            { value: "icon", text: "Icon" },
            { value: "emoji", text: "Emoji" },
            { value: "image", text: "Image" },
            { value: "letter", text: "Letter" },
            { value: "numbered", text: "Numbered" },
          ]}
          object_type="variants"
          value={
            variant.options["list_visual_type"]
              ? variant.options["list_visual_type"]
              : "bullet"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Icon"
          setting_name="[options][list_icon]"
          setting_type="icon"
          object_type="variants"
          default_value="fas fa-check"
          value={
            variant.options["list_icon"] ? variant.options["list_icon"] : ""
          }
          condition={variant.options["list_visual_type"] === "icon"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Emoji"
          setting_name="[options][list_emoji]"
          setting_type="emoji"
          object_type="variants"
          value={
            variant.options["list_emoji"] ? variant.options["list_emoji"] : ""
          }
          condition={variant.options["list_visual_type"] === "emoji"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Icon Image"
          setting_name="[options][list_image]"
          setting_type="image"
          object_type="variants"
          value={
            variant.options["list_image"] ? variant.options["list_image"] : ""
          }
          condition={variant.options["list_visual_type"] === "image"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Font Size"
          setting_name="[options][list-visual-type-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-visual-type-font-size"]
              ? variant.options["list-visual-type-font-size"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-font-size"]
              ? variant.options["list-visual-type-font-size"]
              : 24
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Font Size"
          setting_name="[options][list-visual-type-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-visual-type-font-size-mobile"]
              ? variant.options["list-visual-type-font-size-mobile"]
              : variant.options["list-visual-type-font-size"]
              ? variant.options["list-visual-type-font-size"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-font-size-mobile"]
              ? variant.options["list-visual-type-font-size-mobile"]
              : variant.options["list-visual-type-font-size"]
              ? variant.options["list-visual-type-font-size"]
              : 24
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Font Color"
          setting_name="[options][list-visual-type-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-visual-type-font-color"]
              ? variant.options["list-visual-type-font-color"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-font-color"]
              ? variant.options["list-visual-type-font-color"]
              : "#000"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Image Width"
          setting_name="[options][list-visual-type-image-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-visual-type-image-width"]
              ? variant.options["list-visual-type-image-width"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-image-width"]
              ? variant.options["list-visual-type-image-width"]
              : "200"
          }
          min={0}
          max={750}
          range_step={1}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Image Width"
          setting_name="[options][list-visual-type-image-width-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-visual-type-image-width-mobile"]
              ? variant.options["list-visual-type-image-width-mobile"]
              : variant.options["list-visual-type-image-width"]
              ? variant.options["list-visual-type-image-width"]
              : undefined
          }
          placeholder={
            variant.options["list-visual-type-image-width"]
              ? variant.options["list-visual-type-image-width"]
              : "200"
          }
          min={0}
          max={750}
          range_step={1}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Font family"
          setting_name="[options][list-content-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["list-content-font-family"]
              ? variant.options["list-content-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={ctas.builder.fonts.load_font_family}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-content-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["list-content-font-weight"]
              ? variant.options["list-content-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Font size"
          setting_name="[options][list-content-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-content-font-size"]
              ? variant.options["list-content-font-size"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-size"]
              ? variant.options["list-content-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Font size"
          setting_name="[options][list-content-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-content-font-size-mobile"]
              ? variant.options["list-content-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["list-content-font-size-mobile"]
              ? variant.options["list-content-font-size-mobile"]
              : variant.options["list-content-font-size"]
              ? variant.options["list-content-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Font Line Height"
          setting_name="[options][list-content-font-line-height]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-content-font-line-height"]
              ? variant.options["list-content-font-line-height"]
              : undefined
          }
          placeholder={20}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Font Line Height"
          setting_name="[options][list-content-font-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-content-font-line-height-mobile"]
              ? variant.options["list-content-font-line-height-mobile"]
              : variant.options["list-content-font-line-height"]
              ? variant.options["list-content-font-line-height"]
              : undefined
          }
          placeholder={20}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Font color"
          setting_name="[options][list-content-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-content-font-color"]
              ? variant.options["list-content-font-color"]
              : undefined
          }
          placeholder={"#000"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Content Drop Shadow"
          setting_name="[options][list-content-box-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["list-content-box-shadow"]
              ? variant.options["list-content-box-shadow"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-content-box-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["list-content-box-shadow-horizontal-distance"]
              ? variant.options["list-content-box-shadow-horizontal-distance"]
              : "0"
          }
          condition={variant.options["list-content-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-content-box-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["list-content-box-shadow-vertical-distance"]
              ? variant.options["list-content-box-shadow-vertical-distance"]
              : "0"
          }
          condition={variant.options["list-content-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-content-box-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["list-content-box-shadow-blur-radius"]
              ? variant.options["list-content-box-shadow-blur-radius"]
              : "0"
          }
          condition={variant.options["list-content-box-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][list-content-box-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-content-box-shadow-color"]
              ? variant.options["list-content-box-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          placeholder="#000"
          condition={variant.options["list-content-box-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Spacing"
          setting_name="[options][list-visual-type-spacing]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-visual-type-spacing"]
              ? variant.options["list-visual-type-spacing"]
              : undefined
          }
          placeholder={"Auto"}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Item Icon Spacing"
          setting_name="[options][list-visual-type-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-visual-type-spacing-mobile"]
              ? variant.options["list-visual-type-spacing-mobile"]
              : undefined
          }
          placeholder={"Auto"}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Items Divider Border Style"
          setting_name="[options][list-items-divider-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["list-items-divider-style"]
              ? variant.options["list-items-divider-style"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Items Divider Size"
          setting_name="[options][list-items-divider-border-width]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["list-items-divider-border-width"]
              ? variant.options["list-items-divider-border-width"]
              : undefined
          }
          placeholder="1"
          condition={["solid", "dashed", "dotted"].includes(
            variant.options["list-items-divider-style"]
          )}
          min={0}
          max={50}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Items Divider Color"
          setting_name="[options][list-items-divider-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-items-divider-color"]
              ? variant.options["list-items-divider-color"]
              : undefined
          }
          placeholder={"#000"}
          condition={["solid", "dashed", "dotted"].includes(
            variant.options["list-items-divider-style"]
          )}
        />

        <VariantBuilderSetting
          object={variant}
          label="Container Background color"
          setting_name="[options][list-background]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-background"]
              ? variant.options["list-background"]
              : undefined
          }
          placeholder={
            variant.options["list-background"]
              ? variant.options["list-background"]
              : "#000"
          }
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Container Border Style"
          setting_name="[options][list-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["list-border-style"]
              ? variant.options["list-border-style"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Border color"
          setting_name="[options][list-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-border-color"]
              ? variant.options["list-border-color"]
              : undefined
          }
          placeholder={"#000"}
          condition={
            variant.options["list-border-style"] &&
            variant.options["list-border-style"] !== "" &&
            variant.options["list-border-style"] !== "none"
          }
        />

        {variant.options["list-border-style"] &&
          variant.options["list-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][list-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[`list-border-width-${side}`]
                          ) == false
                            ? variant.options[`list-border-width-${side}`]
                            : variant.options["list-border-width"]
                            ? variant.options["list-border-width"].replace(
                                "px",
                                ""
                              )
                            : undefined
                        }
                        placeholder="1px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Container Border Corner Radius"
          setting_name="[options][list-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["list-border-radius"]
              ? variant.options["list-border-radius"]
              : undefined
          }
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][list-border-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(variant.options[`list-border-radius-${side}`]) ==
                    false
                      ? variant.options[`list-border-radius-${side}`]
                      : undefined
                  }
                  condition={variant.options["list-border-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Container Shadow"
          setting_name="[options][list-container-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["list-container-shadow"]
              ? variant.options["list-container-shadow"]
              : undefined
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-container-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["list-container-shadow-horizontal-distance"]
              ? variant.options["list-container-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["list-container-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-container-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["list-container-shadow-horizontal-distance"]
              ? variant.options["list-container-shadow-horizontal-distance"]
              : undefined
          }
          condition={variant.options["list-container-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-container-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["list-container-shadow-blur-radius"]
              ? variant.options["list-container-shadow-blur-radius"]
              : undefined
          }
          condition={variant.options["list-container-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][list-container-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Spread Radius"}
          value={
            variant.options["list-container-shadow-spread-radius"]
              ? variant.options["list-container-shadow-spread-radius"]
              : undefined
          }
          condition={variant.options["list-container-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][list-container-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["list-container-shadow-color"]
              ? variant.options["list-container-shadow-color"]
              : undefined
          }
          condition={variant.options["list-container-shadow"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Inset shadow position"
          setting_name="[options][list-container-shadow-inset]"
          setting_type="checkbox"
          object_type="variants"
          value={
            variant.options["list-container-shadow-inset"]
              ? variant.options["list-container-shadow-inset"]
              : "false"
          }
          condition={variant.options["list-container-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeList;
