import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import ActionsBuilder from "../actions/ActionsBuilder";
import ConditionsBuilder from "../conditions/ConditionsBuilder";

const ActionGroupSettings = (props) => {
  const [contextData] = useContext(VariantContextData);
  const [collapsed, setCollapsed] = useState(
    props.action_group.open ? props.action_group.open : false
  );

  let cta = contextData.objects.cta;
  let website = contextData.objects.website;
  let variant = contextData.objects.variant;
  let action_group = props.action_group;
  let element = props.element;
  let step = props.step;
  const field = props.field;

  const [productHandlesDropdown, setProductHandlesDropdown] = useState([
    {
      value: "",
      text: "Select a product",
    },
  ]);

  const [productVariantsDropdown, setProductVariantsDropdown] = useState([
    {
      value: "",
      text: "Select product variant",
    },
  ]);

  useEffect(() => {
    const integration = ecommerce_integrations.find((integration) => {
      return integration.value == action_group.data["selected-store-integration"]
    })?.integration;

    if (["Shopify"].includes(action_group.data["selected-store-integration"])) {
      const cached_products = window.cf_shopify_products;
      if (cached_products) {
        loadShopifyGraphqlProducts(cached_products);
        return;
      }

      new ProductsIntegrationsService(action_group.data["selected-store-integration"], integration)
        .adapter()
        .getProducts({ sort: element.options["shopify-products-sorting"] })
        .then((response) => {
          window.cf_shopify_products = response;
          loadShopifyGraphqlProducts(response);
        })
    }
  }, [action_group.data["selected-store-integration"]]);

  const loadShopifyGraphqlProducts = (products) => {
    let tempProductsDropdown = [];
    let tempHandlesDropdown = [];
    let tempProductVariantsDropdown = [];
    let orderedProducts = products.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    const defaultSelect = { value: "", text: "Select a product", disabled: true }
    tempProductsDropdown.push(defaultSelect)
    tempHandlesDropdown.push(defaultSelect)

    orderedProducts.forEach((shopifyAdapter) => {
      tempProductsDropdown.push({
        value: shopifyAdapter.id,
        text: shopifyAdapter.title,
      });

      tempHandlesDropdown.push({
        value: shopifyAdapter.handle,
        text: shopifyAdapter.title,
      });

      shopifyAdapter.variants.forEach((productVariant) => {
        tempProductVariantsDropdown.push({
          value: productVariant.id,
          text: `${shopifyAdapter.title} ${
            productVariant.title.includes("Default Title")
              ? ""
              : " - " + productVariant.title
          }`,
        });
      });
    });

    setProductHandlesDropdown(tempHandlesDropdown);
    setProductVariantsDropdown(tempProductVariantsDropdown);
  };

  let existing_conditions = Object.values(variant.conditions).filter(
    (condition) =>
      condition.contactable_type == "ActionGroup" &&
      condition.contactable_id == action_group.id &&
      condition.toBeDeleted !== true
  );

  let warningVisible =
    (collapsed == false &&
      action_group.data["confirmation-type"] == "step" &&
      (!action_group.data["redirect-step"] ||
        (action_group.data["redirect-step"] !== "previous" &&
          Object.values(variant.steps).filter(
            (s) =>
              s.id + "" == action_group.data["redirect-step"] &&
              s.toBeDeleted !== true
          ).length == 0))) ||
    (action_group.data["confirmation-type"] == "redirect" &&
      !action_group.data["redirect-url"] &&
      action_group.data["confirmation-close"] !== "true") ||
    (action_group.data["confirmation-type"] == "cta" &&
      !action_group.data["confirmation-cta"]) ||
    (action_group.data["confirmation-type"] == "sms" &&
      !action_group.data["sms-number"]);

  let fieldsDropdown = [
    {
      value: "",
      text: "Next question",
    },
  ];

  if (action_group.field_id) {
    const field_item = contextData.objects.variant.fields[action_group.field_id];
    const is_sub_field = field_item.field_id !== null;
    if (is_sub_field) {
      fieldsDropdown.push({
        value: "next_sub_question",
        text: "Next sub-question",
      });
    }

    Object.values(contextData.objects.variant.fields)
      .filter(
        (field) =>
          field.element_id == element.id &&
          field.toBeDeleted !== true &&
          !field.field_id
      )
      .sort(function (a, b) {
        return a.position - b.position;
      })
      .forEach((field, index) => {
        if (field.id !== props.field.id) {
          fieldsDropdown.push({
            value: field.id,
            text: `Question ${index + 1} ${
              field.label ? `- ${field.label.replace(/(<([^>]+)>)/gi, "")}` : ""
            }`,
          });
        }

        Object.values(contextData.objects.variant.fields)
          .filter(
            (sub_field) =>
              sub_field.element_id == element.id &&
              sub_field.toBeDeleted !== true &&
              sub_field.field_id == field.id
          )
          .sort((a, b) => a.position - b.position)
          .forEach((sub_field, sub_index) => {
            if (sub_field.id !== props.field.id) {
              fieldsDropdown.push({
                value: sub_field.id,
                text: `Question ${index + 1}.${sub_index + 1} ${
                  sub_field.label
                    ? `- ${sub_field.label.replace(/(<([^>]+)>)/gi, "")}`
                    : ""
                }`,
              });
            }
          });
      });

    fieldsDropdown.push({
      value: "complete_quiz",
      text: "Complete the quiz",
    });
  }

  const nextQuestion = fieldsDropdown.find(
    (field) => field.value == action_group.data["jump-field-id"]
  );

  let object_label =
    action_group.element_id && !field
      ? "Conditional Action"
      : action_group.step_id
      ? "Skip Logic"
      : "Conditional Logic";

  const objectInfo = () => {
    let object_info =
      action_group.element_id && !field
        ? "Using default confirmation action"
        : field
        ? `Jump To ${
            !action_group.data["jump-field-id"]
              ? "Next Question"
              : nextQuestion
              ? nextQuestion.text
              : ""
          }`
        : "";

    if (action_group.data["confirmation-type"] == "redirect") {
      object_info = "Redirect to a URL";

      if (action_group.data["redirect-url"]) {
        object_info += " – " + action_group.data["redirect-url"];
      }
    }

    if (action_group.data["confirmation-type"] == "step") {
      object_info = "Jump to a funnel step";
      if (action_group.data["redirect-step"] == "previous") {
        object_info += " – Previous Step";
      } else {
        let step = variant.steps[action_group.data["redirect-step"]];

        if (action_group.data["redirect-step"] && step) {
          object_info +=
            " – " +
            (step.options["name"] ? step.options["name"] : step.position);
        }
      }
    }

    if (action_group.data["confirmation-type"] == "cta") {
      object_info = "Trigger a campaign";
      let cta = website.ctas[action_group.data["confirmation-cta"]];

      if (action_group.data["confirmation-cta"] && cta) {
        object_info +=
          " – " + (cta.name ? cta.name : action_group.data["confirmation-cta"]);
      }
    }

    if (action_group.data["confirmation-type"] == "message") {
      object_info = "Display a thank you message";

      if (action_group.data["confirmation"]) {
        object_info += " – " + action_group.data["confirmation"];
      }
    }

    if (action_group.data["confirmation-type"] == "sms") {
      object_info = "Subscribe via SMS code";

      if (action_group.data["sms-number"]) {
        object_info += " – " + action_group.data["sms-number"];
      }
    }

    return object_info;
  };

  const redirectSteps = () => {
    let steps;

    if (action_group.data["confirmation-type"] == "step") {
      steps = [
        {
          value: "",
          text: "Select a step",
          disabled: true,
        },
      ];

      Object.values(variant.steps)
        .filter((s) => s.id !== props.step.id && s.toBeDeleted !== true)
        .sort((a, b) => {
          return a.position - b.position;
        })
        .forEach((s) => {
          steps.push({
            value: s.id + "",
            text:
              "Step " +
              s.position +
              (s.options["name"] ? " - " + s.options["name"] : ""),
          });
        });

      if (step.position > 1) {
        steps.push({
          value: "previous",
          text: "Jump back to previous step",
        });
      }
    }

    return steps;
  };

  let ecommerce_integrations = $.map(website.integrations, function (integration) {
    let ecommerce_integration_types = ["Shopify"];

    if (ecommerce_integration_types.includes(integration.integration_type)) {
      let output = { value: integration.integration_type, text: integration.integration_type, integration: integration }

      if (integration.integration_type == "Shopify" && integration.api_data["storefront-token"]) {
        return output;
      } else if (integration.integration_type == "Shopify" && !integration.api_data["storefront-token"]) {
        return { ...output, text: 'Shopify - Reauthentication Required', disabled: true }
      } else {
        return output;
      }
    }
  });

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={action_group}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="action_groups"
        object_id={action_group.id}
        title={`${
          action_group.data["label"] ? `${action_group.data["label"]} - ` : ""
        }${objectInfo()}`}
        style={{ margin: "4px 0px" }}
        label_max_length={25}
        label_hover_max_length={14}
        panel_type="modal"
        hide_handle={true}
        warning={
          action_group.data["confirmation-type"] == "step" &&
          (!action_group.data["redirect-step"] ||
            (action_group.data["redirect-step"] !== "previous" &&
              Object.values(variant.steps).filter(
                (s) =>
                  s.id + "" == action_group.data["redirect-step"] &&
                  s.toBeDeleted !== true
              ).length == 0))
            ? "Step to jump to not yet set"
            : action_group.data["confirmation-type"] == "redirect" &&
              !action_group.data["redirect-url"] &&
              action_group.data["confirmation-close"] !== "true"
            ? "Redirect URL not yet set"
            : action_group.data["confirmation-type"] == "cta" &&
              !action_group.data["confirmation-cta"]
            ? "Campaign to display not yet set"
            : action_group.data["confirmation-type"] == "sms" &&
              !action_group.data["sms-number"]
            ? "SMS opt-in information incomplete"
            : undefined
        }
        warningBorder={false}
        label_icon={
          action_group.data["confirmation-type"] == "step" ||
          action_group.field_id
            ? "fa-solid fa-code-branch"
            : action_group.data["confirmation-type"] == "redirect"
            ? "fa-solid fa-link"
            : action_group.data["confirmation-type"] == "cta"
            ? "fa-solid fa-message"
            : action_group.data["confirmation-type"] == "sms"
            ? "fa-solid fa-mobile"
            : action_group.data["confirmation-type"] == "message"
            ? "fa-solid fa-quote-left"
            : ""
        }
        duplicate_payload={{
          field_id: action_group.field_id,
        }}
      >
        <VariantBuilderSetting
          object={action_group}
          setting_name="[data][label]"
          setting_type="text"
          object_type="action_groups"
          object_id={action_group.id}
          label={`Label for this ${object_label}`}
          value={action_group.data["label"] ? action_group.data["label"] : ""}
          placeholder="Write label here"
          update_on_input_end={true}
        />

        <div className="col-xs-12">
          <div className="flex my-5 relative">
            <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
              <div className="w-full mt-[13px]">If</div>
              <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
            </div>

            <div className="w-[560px]">
              <ConditionsBuilder
                cta={cta}
                variant={variant}
                website={website}
                contactable_type="ActionGroup"
                contactable_id={action_group.id}
                dark_mode={contextData.dark_mode}
                builder={true}
              />
            </div>
          </div>
        </div>

        {(!element || ["products", "product_hero"].includes(element.element_type) == false) && (
            <div className="col-xs-12">
              <div className="flex my-5 relative">
                <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
                  <div className="w-full mt-[13px]">Then</div>
                  <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
                </div>

                <div className="w-[560px]">
                  <VariantBuilderSetting
                    object={action_group}
                    label="Jump To"
                    setting_name="[data][jump-field-id]"
                    setting_type="dropdown"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["jump-field-id"]
                        ? action_group.data["jump-field-id"]
                        : ""
                    }
                    options={fieldsDropdown}
                    condition={
                      element !== undefined &&
                      element.element_type == "quiz" &&
                      props.field !== undefined
                    }
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Perform confirmation action"
                    setting_name="[data][confirmation-type]"
                    setting_type="dropdown"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["confirmation-type"]
                        ? action_group.data["confirmation-type"]
                        : ""
                    }
                    options={[
                      { value: "", text: "Use default confirmation action" },
                      { value: "redirect", text: "Redirect to a URL" },
                      { value: "cart", text: "Redirect to cart", disabled: ecommerce_integrations.length == 0 },
                      { value: "checkout", text: "Redirect to checkout", disabled: ecommerce_integrations.length == 0 },
                      { value: "step", text: "Jump to a funnel step" },
                      { value: "cta", text: "Display a campaign" },
                      { value: "sms", text: "Subscribe via SMS code" },
                      { value: "message", text: "Show a thank you message", condition: ["form", "survey", "quiz"].includes(element?.element_type) },
                    ]}
                    condition={
                      element !== undefined &&
                      ["products", "product_hero"].includes(element.element_type) == false &&
                      !action_group.field_id
                    }
                    tooltip="If the conditions are met, any confirmation action you set here will be performed instead of the default"
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Selected Store Integration"
                    setting_name="[data][selected-store-integration]"
                    setting_type="dropdown"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={action_group.data["selected-store-integration"]}
                    options={[
                      {
                        value: "",
                        text: "Select a store integration",
                        disabled: true
                      },
                      ...ecommerce_integrations
                    ]}
                    warning={
                      ecommerce_integrations.length == 0 || !action_group.data["selected-store-integration"]
                        ? "No ecommerce platform selected"
                        : undefined
                    }
                    condition={
                      ['cart', 'checkout'].includes(action_group.data["confirmation-type"]) &&
                      ecommerce_integrations.length > 0
                    }
                  />

                  <div className="col-xs-12 no-padding-sides">
                    <div className="flex gap-x-3 relative">
                      <div className="flex-grow min-w-[25%] max-w-[80%]">
                        <VariantBuilderSetting
                          object={action_group}
                          label="Product variants to add to cart"
                          note={action_group.data["selected-store-integration"] == "Shopify" ? "Pro tip: To add subscriptions to cart, add Shopify automations below" : ""}
                          setting_name="[data][selected-product-variant-ids]"
                          setting_type="select"
                          object_type="action_groups"
                          object_id={action_group.id}
                          value={
                            action_group.data["selected-product-variant-ids"]
                              ? action_group.data["selected-product-variant-ids"]
                              : ""
                          }
                          options={productVariantsDropdown}
                          multi_select={true}
                          warning={
                            !action_group.data["selected-product-variant-ids"]
                              ? "No product variants selected to add to cart"
                              : undefined
                          }
                          condition={
                            ['cart', 'checkout'].includes(action_group.data["confirmation-type"]) &&
                            ecommerce_integrations.length > 0 &&
                            action_group.data["selected-store-integration"] !== null
                          }
                        />
                      </div>

                      <div className="flex-grow min-w-[25%] max-w-[20%]">
                        <VariantBuilderSetting
                          object={action_group}
                          setting_name="[data][selected-products-quantity]"
                          setting_type="number"
                          label="Quantity"
                          object_type="action_groups"
                          object_id={action_group.id}
                          condition={
                            ['cart', 'checkout'].includes(action_group.data["confirmation-type"]) &&
                            ecommerce_integrations.length > 0 &&
                            action_group.data["selected-store-integration"] !== null
                          }
                          value={action_group.data["selected-products-quantity"]}
                          placeholder="1"
                          min={1}
                          allow_empty={true}
                        />
                      </div>
                    </div>
                  </div>

                  <VariantBuilderSetting
                    object={action_group}
                    label="Jump to a funnel step"
                    setting_name="[data][redirect-step]"
                    setting_type="dropdown"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["redirect-step"] &&
                      (action_group.data["redirect-step"] == "previous" ||
                        Object.values(variant.steps).filter(
                          (s) =>
                            s.id + "" == action_group.data["redirect-step"] &&
                            s.toBeDeleted !== true
                        ).length > 0)
                        ? action_group.data["redirect-step"]
                        : ""
                    }
                    options={redirectSteps()}
                    condition={
                      action_group.data["confirmation-type"] == "step" &&
                      !action_group.field_id
                    }
                    warning={
                      !action_group.data["redirect-step"]
                        ? "Step to jump to not selected"
                        : undefined
                    }
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Redirect to a URL"
                    setting_name="[data][redirect-url]"
                    setting_type="text"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["redirect-url"]
                        ? action_group.data["redirect-url"]
                        : ""
                    }
                    condition={
                      action_group.data["confirmation-type"] == "redirect" &&
                      !action_group.field_id
                    }
                    placeholder="https://"
                    warning={
                      !action_group.data["redirect-url"] &&
                      action_group.data["confirmation-close"] !== "true"
                        ? "Redirect URL not yet set"
                        : undefined
                    }
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Add field data to redirect URL?"
                    setting_name="[data][query-string]"
                    setting_type="checkbox"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["query-string"]
                        ? action_group.data["query-string"]
                        : "false"
                    }
                    condition={
                      element !== undefined &&
                      ["form", "survey"].includes(element.element_type) &&
                      action_group.data["confirmation-type"] == "redirect" &&
                      !action_group.field_id
                    }
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Open in new tab?"
                    setting_name="[data][target-blank]"
                    setting_type="checkbox"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["target-blank"]
                        ? action_group.data["target-blank"]
                        : "false"
                    }
                    condition={
                      action_group.data["confirmation-type"] == "redirect" &&
                      !action_group.field_id
                    }
                    placeholder="https://"
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Campaign to trigger"
                    setting_name="[data][confirmation-cta]"
                    setting_type="dropdown"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["confirmation-cta"]
                        ? action_group.data["confirmation-cta"]
                        : ""
                    }
                    options={[
                      {
                        value: "",
                        text: "Select a popup campaign",
                        disabled: true,
                      },
                      ...Object.values(website.ctas)
                        .filter(
                          (c) =>
                            ["inline", "page"].includes(c.cta_type) == false
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c) => {
                          return {
                            value: c.id,
                            text:
                              (c.name || "Campaign " + c.id) +
                              " – " +
                              c.cta_type.substr(0, 1).toUpperCase() +
                              c.cta_type.substr(1),
                          };
                        }),
                    ]}
                    condition={
                      action_group.data["confirmation-type"] == "cta" &&
                      !action_group.field_id
                    }
                    tooltip="Enabling this will trigger the select popup campaign"
                    warning={
                      !action_group.data["confirmation-cta"]
                        ? "Campaign to trigger not yet set"
                        : undefined
                    }
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Thank you message"
                    setting_name="[data][confirmation]"
                    setting_type="text"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["confirmation"]
                        ? action_group.data["confirmation"]
                        : ""
                    }
                    condition={
                      action_group.data["confirmation-type"] == "message" &&
                      !action_group.field_id
                    }
                    placeholder="Thanks!"
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Phone number / shortcode to text"
                    setting_name="[data][sms-number]"
                    setting_type="text"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["sms-number"]
                        ? action_group.data["sms-number"]
                        : ""
                    }
                    condition={
                      action_group.data["confirmation-type"] == "sms" &&
                      !action_group.field_id
                    }
                    placeholder="55555"
                    warning={
                      !action_group.data["sms-number"]
                        ? "SMS opt-in requires a phone number or shortcode to text"
                        : undefined
                    }
                  />

                  <VariantBuilderSetting
                    object={element}
                    label="SMS opt-in keyword"
                    setting_name="[data][sms-keyword]"
                    setting_type="text"
                    object_type="elements"
                    object_id={action_group.id}
                    value={
                      action_group.data["sms-keyword"]
                        ? action_group.data["sms-keyword"]
                        : ""
                    }
                    condition={
                      action_group.data["confirmation-type"] == "sms" &&
                      !action_group.field_id
                    }
                    placeholder="CAMPAIGN"
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Close out current campaign?"
                    setting_name="[data][confirmation-close]"
                    setting_type="checkbox"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["confirmation-close"]
                        ? action_group.data["confirmation-close"]
                        : "false"
                    }
                    condition={
                      ["redirect", "cta", "sms"].includes(
                        action_group.data["confirmation-type"]
                      ) && !action_group.field_id
                    }
                  />
                </div>
              </div>
            </div>
          )}

        {element && !action_group.field_id && (
          <React.Fragment>
            <div className="col-xs-12">
              <div className="flex my-5 relative">
                <div className="w-[50px] h-auto text-2xl font-bold text-black dark:text-white">
                  <div className="w-full mt-[13px]">Run</div>
                  <div className="absolute w-[5px] h-[93%] mt-[10px] l-[5px] border-solid border-0 border-l-[5px] border-slate-100 dark:border-slate-800 rounded"></div>
                </div>

                <div className="w-[560px]">
                  <ActionsBuilder
                    cta={cta}
                    variant={variant}
                    website={website}
                    contactable_type="ActionGroup"
                    contactable_id={action_group.id}
                    element={element}
                    dark_mode={contextData.dark_mode}
                    builder={true}
                    title="Run Conditional Automations"
                  />

                  <VariantBuilderSetting
                    object={action_group}
                    label="Fire script"
                    setting_name="[data][script]"
                    setting_type="code"
                    object_type="action_groups"
                    object_id={action_group.id}
                    value={
                      action_group.data["script"]
                        ? action_group.data["script"]
                        : ""
                    }
                    tooltip={
                      element && element.element_type == "form"
                        ? element &&
                          "Custom scripts will fire upon successful submission of form, before redirect actions are taken"
                        : element && element.element_type == "button"
                        ? "Custom scripts will fire upon clicking button, before redirect actions are taken"
                        : element && element.element_type == "survey"
                        ? "Custom scripts will fire upon selecting an option, before redirect actions are taken"
                        : ""
                    }
                    condition={element !== undefined && !action_group.field_id}
                    feature_gate_type="Custom Scripts"
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ActionGroupSettings;
