var stats = {
  initialize: function (container, variant) {
    $(container)
      .find(".range-start")
      .datetimepicker({
        defaultDate: new Date().setHours(0, 0, 0, 0),
      });

    $(container)
      .find(".range-end")
      .datetimepicker({
        defaultDate: new Date().setHours(23, 59, 59, 999),
      });

    $(container)
      .find(".timeframe")
      .on("change", function () {
        if ($(this).val() == "daterange") {
          $(container).find(".range-selector").show();
        } else {
          $(container).find(".range-selector").hide();
          variants.stats.run(container, variant);
        }
      });

    $(container)
      .find(".range-selector .date")
      .each(function () {
        $(this).on("dp.change", function (ev) {
          variants.stats.run(container, variant);
        });
      });

    $(container)
      .find('select[data-type="conversion-type"][data-id="' + variant.id + '"]')
      .on("change", function () {
        var label = $(this).find("option:selected").text() + "s";
        $(container).find(".chart-type-label").html(label);
        variants.stats.run(container, variant);
      });

    variants.stats.run(container, variant);
  },

  run: function (container, variant) {
    if ($(container).find(".timeframe").val() !== "daterange") {
      var timeframe =
        reports.rangeMap[
          $(container).find(".timeframe").find("option:selected").text()
        ];
      var range_start = $(container).find(".timeframe").val();
      var range_end = new Date();
    } else if (
      $(container).find(".range-start input").val() !== "" &&
      $(container).find(".range-end input").val() !== ""
    ) {
      var range_start = new Date(
        Date.parse($(container).find(".range-start input").val())
      );
      var range_end = new Date($(container).find(".range-end input").val());
      var timeframe = reports.groupByType(range_start, range_end);
    }

    var event_type = $(container)
      .find('select[data-type="conversion-type"][data-id="' + variant.id + '"]')
      .val();
    var query = {
      event_type: event_type,
      contactable_type: "Cta",
      contactable_id: variant.cta_id,
      variant_id: variant.id,
    };

    reports.getChart(
      window.location.origin + "/websites/" + variant.website_id + "/reports/",
      "variant-" + variant.id + "-reports-chart",
      "ColumnChart",
      {
        website_id: variant.website_id,
        table: "Event",
        range_start: range_start,
        range_end: range_end,
        result_type: "group_by_date",
        group_by: timeframe,
        query: query,
      }
    );

    reports.getReport(
      window.location.origin + "/websites/" + variant.website_id + "/reports/",
      "variant-" + variant.id + "-reports-count",
      "variant-" + variant.id + "-reports-container",
      {
        website_id: variant.website_id,
        table: "Event",
        range_start: range_start,
        range_end: range_end,
        result_type: "unique_count",
        value: "visitor_token",
        query: query,
      }
    );
  },
};

export default stats;
