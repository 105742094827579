import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingLayout = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const responsiveValue = props.valueMappings && props.valueMappings.responsive ? props.valueMappings.responsive : "responsive";
  const stackedValue = props.valueMappings && props.valueMappings.stacked ? props.valueMappings.stacked : "stacked";

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }
  
  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">

        {props.valueMappings && props.valueMappings.responsive && (
          <button
            className={`flex-1 h-[30px] text-2xl p-3 pt-2 border-none bg-transparent rounded-lg ${
              props.value == responsiveValue ? "bg-white dark:bg-slate-950" : ""
            }`}
            onClick={() => {
              updateValue(responsiveValue);
            }}
            title={`Responsive`}
          >
            <svg width="54" height="11" viewBox="0 0 54 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="14" height="9" rx="1" fill={contextData.dark_mode ? `#CBD5E1` : "#64748B"} />
              <rect x="20" y="1" width="14" height="9" rx="1" fill={contextData.dark_mode ? `#CBD5E1` : "#64748B"} />
              <rect x="39" y="1" width="14" height="9" rx="1" fill={contextData.dark_mode ? `#CBD5E1` : "#64748B"} />
            </svg>

          </button>
        )}

        <button
          className={`flex-1 h-[30px] text-2xl p-3 pt-2 border-none bg-transparent rounded-lg ${
            props.value == stackedValue
              ? "bg-white dark:bg-slate-950"
              : ""
          }`}
          onClick={() => {
            updateValue(stackedValue);
          }}
          title={`Stacked`}
        >
          <svg width="25" height="20" viewBox="0 0 25 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="23" height="9" rx="1" fill={contextData.dark_mode ? `#CBD5E1` : "#64748B"} />
            <rect x="1" y="15" width="23" height="9" rx="1" fill={contextData.dark_mode ? `#CBD5E1` : "#64748B"} />
            <rect x="1" y="29" width="23" height="9" rx="1" fill={contextData.dark_mode ? `#CBD5E1` : "#64748B"} />
          </svg>

        </button>

      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingLayout;
