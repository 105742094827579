import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingDropdown = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  const generateRandomKey = () => {
    return `variant-builder-setting-dropdown-${Math.random().toString(16).slice(2)}`;
  }

  return (
    <React.Fragment>
      <select
        setting_name={props.setting_name}
        className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
        value={props.value ? props.value : ""}
        onChange={(e) => {
          if (builder.displayFeatureGate(e, props.feature_gate_type, e.target.value)) {
            builder.update([
              {
                object_type: props.object_type,
                object_id: props.object_id,
                setting_name: props.setting_name,
                value: e.target.value,
                skip_history: props.skip_history,
              },
            ]);
            if (props.callback) {
              props.callback(e.target.value, props.value, e.target);
            }
          }
        }}
      >
        {props.option_groups &&
          props.option_groups.map((group, i) => {
            return (
              <VariantBuilderSettingDropdownOptionGroup
                key={generateRandomKey()}
                group={group}
                options={props.options}
                value={props.value}
              />
            );
          })}
        {!props.option_groups &&
          props.options &&
          props.options.map((option, i) => {
            if (Object.keys(option).length > 0) {
              return (
                <VariantBuilderSettingDropdownOption
                  key={generateRandomKey()}
                  option={option}
                  value={props.value}
                />
              );
            }
          })}
      </select>
    </React.Fragment>
  );
};

const VariantBuilderSettingDropdownOption = (props) => {
  return (
    <React.Fragment>
      {props.option.condition !== false && <option
        value={props.option.value}
        text={props.option.text}
        field_type={props.option.field_type}
        integration_type={props.option.integration_id}
        property_id={props.option.property_id}
        disabled={props.option.disabled ? true : false}
      >
        {props.option.text}
      </option>}
    </React.Fragment>
  );
};

const VariantBuilderSettingDropdownOptionGroup = (props) => {
  let options = props.options.filter((option) => option.group == props.group);
  return (
    <React.Fragment>
      <optgroup label={props.group}>
        {options.map((option, i) => {
          return (
            <VariantBuilderSettingDropdownOption
              key={"option-" + option.group + "-" + option.value + "-" + i}
              option={option}
            />
          );
        })}
      </optgroup>
    </React.Fragment>
  );
};

export default VariantBuilderSettingDropdown;
