import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSections from "../sections/useSections.js";
import useElements from "../elements/useElements.js";
import NewElementDraggable from "../elements/NewElementDraggable.js";
import NewElementTemplates from "../elements/NewElementTemplates.js";
import templateableElementTypesArray from "../../utils/templateableElementTypesArray";
import ElementTypeItem from "../elements/ElementTypeItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewElement = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { Sections } = useSections();
  const { Elements } = useElements(Sections);
  let new_object = contextData.new_object;
  let section = contextData.objects.variant.sections[new_object.section_id];
  let website_id = contextData.objects.website.id;

  const elementTypeMapping = {
    headline: "Headline",
    text: "Text",
    image: "Image",
    video: "Video",
    list: "List",
    button: "Button",
    form: "Form",
    quiz: "Quiz",
    survey: "Survey",
    products: "Products",
    product_hero: "Product Hero",
    html: "Custom HTML",
    divider: "Divider",
    icon: "Icon",
    tabs: "Tabs",
    carousel: "Carousel",
    timer: "Timer",
    progress: "Progress Bar",
    collapse: "Collapse",
    row: "Row",
  };

  const getFirstSectionAvailable = () => {
    let steps_with_sections = Object.values(
      contextData.objects.variant.sections
    )
      .filter((section) => section.toBeDeleted !== true)
      .map((s) => s.step_id)
      .filter((v, i, a) => a.indexOf(v) === i);

    if (steps_with_sections.length > 0) {
      let first_step = Object.values(contextData.objects.variant.steps)
        .filter((step) => steps_with_sections.includes(step.id))
        .reduce(function (prev, curr) {
          return prev.position < curr.position ? prev : curr;
        });

      let first_section_with_step = Object.values(
        contextData.objects.variant.sections
      )
        .filter((s) => s.step_id == first_step.id && s.element_id == null)
        .reduce((prev, curr) => (prev.position < curr.position ? prev : curr));

      return first_section_with_step;
    } else {
      alert(
        "You must have at least one section to be able to create an element!"
      );
    }
  };

  function createElement(element_type, template_id = null) {
    let focusOnCreate = false;

    if (new_object.section_id == null) {
      section = getFirstSectionAvailable();

      if (section) {
        new_object = { column: 1, section_id: section.id };
        focusOnCreate = true;
      }
    }

    if (section) {
      if (element_type !== "image") {
        Elements.create(
          section,
          element_type,
          new_object.column,
          {},
          template_id,
          null,
          null,
          focusOnCreate
        );
      }

      if (element_type == "image") {
        uploads.select("callback", {
          environment: environment,
          path: "/websites/" + website_id + "/",
          website_id: website_id,
          library: true,
          callback: function (file_url) {
            Elements.create(
              section,
              element_type,
              new_object.column,
              {
                image: file_url,
              },
              null,
              null,
              null,
              focusOnCreate
            );
          },
        });
      }
    }
  }

  useEffect(() => {
    NewElementDraggable.start(contextData, Elements);

    let createButtons = document.querySelectorAll(
      "#new-element .element-type-item"
    );

    createButtons.forEach((createButton) => {
      createButton.onclick = function () {
        let element_type = createButton.getAttribute("data-type");

        if (templateableElementTypesArray.includes(element_type)) {
          dispatchCustomEvent("newObject", {
            object_type: "Elements",
            element_type: element_type,
          });
        } else {
          createElement(element_type);
        }
      };
    });
  }, [props.element_type]);

  const elementButtonsList = (
    <React.Fragment>
      <div className="w-full text-xl text-black dark:text-white">
        Drag to create or click to see templates!
      </div>

      <div className="w-full mt-7">
        <div className="grid grid-cols-2 my-4 text-xl text-black dark:text-white">
          <div className="font-semibold">Content</div>
        </div>
        <div className="w-full new-element-scratch grid grid-cols-2 justify-center gap-6">
          <ElementTypeItem
            title={elementTypeMapping["headline"]}
            element_type="headline"
          />
          <ElementTypeItem
            title={elementTypeMapping["text"]}
            element_type="text"
          />
          <ElementTypeItem
            title={elementTypeMapping["image"]}
            element_type="image"
          />
          <ElementTypeItem
            title={elementTypeMapping["video"]}
            element_type="video"
          />
          <ElementTypeItem
            title={elementTypeMapping["list"]}
            element_type="list"
          />
          <ElementTypeItem
            title={elementTypeMapping["icon"]}
            element_type="icon"
          />
        </div>
      </div>

      <div className="w-full mt-10">
        <div className="grid grid-cols-2 my-4 text-xl text-black dark:text-white">
          <div className="font-semibold">Conversion</div>
        </div>
        <div className="w-full new-element-scratch grid grid-cols-2 justify-center gap-6">
          <ElementTypeItem
            title={elementTypeMapping["button"]}
            element_type="button"
          />
          <ElementTypeItem
            title={elementTypeMapping["form"]}
            element_type="form"
          />
          <ElementTypeItem
            title={elementTypeMapping["quiz"]}
            element_type="quiz"
          />
          <ElementTypeItem
            title={elementTypeMapping["survey"]}
            element_type="survey"
          />
          <ElementTypeItem
            title={elementTypeMapping["products"]}
            element_type="products"
          />
          <ElementTypeItem
            title={elementTypeMapping["product_hero"]}
            element_type="product_hero"
          />
        </div>
      </div>

      <div className="w-full mt-10">
        <div className="grid grid-cols-2 my-4 text-xl text-black dark:text-white">
          <div className="font-semibold">Containers</div>
        </div>
        <div className="w-full new-element-scratch grid grid-cols-2 justify-center gap-6">
          <ElementTypeItem
            title={elementTypeMapping["row"]}
            element_type="row"
          />
          <ElementTypeItem
            title={elementTypeMapping["collapse"]}
            element_type="collapse"
          />
          <ElementTypeItem
            title={elementTypeMapping["tabs"]}
            element_type="tabs"
          />
          <ElementTypeItem
            title={elementTypeMapping["carousel"]}
            element_type="carousel"
          />
        </div>
      </div>

      <div className="w-full mt-10">
        <div className="grid grid-cols-2 my-4 text-xl text-black dark:text-white">
          <div className="font-semibold">Tools</div>
        </div>
        <div className="w-full new-element-scratch grid grid-cols-2 justify-center gap-6">
          <ElementTypeItem
            title={elementTypeMapping["progress"]}
            element_type="progress"
          />
          <ElementTypeItem
            title={elementTypeMapping["timer"]}
            element_type="timer"
          />
          <ElementTypeItem
            title={elementTypeMapping["divider"]}
            element_type="divider"
          />
          <ElementTypeItem
            title={elementTypeMapping["html"]}
            element_type="html"
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div id="new-element" className="row hard-center my-4">
        {props.element_type && props.element_type !== "all" ? (
          <NewElementTemplates
            templates={props.element_templates}
            element_type={props.element_type}
            createCallback={createElement}
            title={elementTypeMapping[props.element_type]}
          />
        ) : (
          <React.Fragment>{elementButtonsList}</React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default NewElement;
