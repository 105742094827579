import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingBorderStyle = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">
        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == "none" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("none");
          }}
          title={`No Border`}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.25 13C2.00736 13 1 11.9926 1 10.75L1 3.25C1 2.00736 2.00736 1 3.25 1L10.75 1C11.9926 1 13 2.00736 13 3.25V10.75C13 11.9926 11.9926 13 10.75 13H3.25Z"
              fill={contextData.dark_mode ? `white` : "black"}
            />
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == "solid" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("solid");
          }}
          title={"Solid Border"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M3.25 13C2.00736 13 1 11.9926 1 10.75L1 3.25C1 2.00736 2.00736 1 3.25 1L10.75 1C11.9926 1 13 2.00736 13 3.25V10.75C13 11.9926 11.9926 13 10.75 13H3.25Z"
              stroke={contextData.dark_mode ? `white` : "black"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 pt-3 border-none bg-transparent rounded-lg ${
            props.value == "dashed" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("dashed");
          }}
          title={"Dashed Border"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 15 16"
            fill="none"
          >
            <path
              d="M5.36628 1.59998H2.52183C1.73636 1.59998 1.09961 2.23673 1.09961 3.0222V5.86664M5.36628 14.4H2.52183C1.73636 14.4 1.09961 13.7632 1.09961 12.9778V10.1333M9.63294 1.59998H12.4774C13.2629 1.59998 13.8996 2.23673 13.8996 3.0222V5.86664M13.8996 10.1333V12.9778C13.8996 13.7632 13.2629 14.4 12.4774 14.4H9.63294"
              stroke={contextData.dark_mode ? `white` : "black"}
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 border-none bg-transparent rounded-lg ${
            props.value == "dotted" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("dotted");
          }}
          title={"Dotted Border"}
        >
          <div className=" ml-auto mr-auto w-[13px] h-[13px] border-dotted border-black dark:border-white rounded">
            
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingBorderStyle;
