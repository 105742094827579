import React from "react";
import PropTypes from "prop-types";

const FieldOptionDropdown = (props) => {
  let field_option = props.field_option;
  let field = props.field;

  return (
    <React.Fragment>
      <option
        key={"field-option-" + field_option.id}
        data-id={field_option.id}
        data-position={field_option.position}
        data-value={
          field_option.value
            ? field_option.value
            : field_option.label
            ? field_option.label
            : "Option " + option.position
        }
        value={
          field_option.value
            ? field_option.value
            : field_option.label
            ? field_option.label
            : "Option " + field_option.position
        }
      >
        {field_option.label
          ? field_option.label
          : field_option.value
          ? field_option.value
          : "Option " + field_option.position}
      </option>
    </React.Fragment>
  );
};

export default FieldOptionDropdown;
