import React, { useEffect } from "react";
import ContentListItem from "../content_lists/ContentListItem";

const ElementList = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  let sorted_list_items = Object.values(element.content_list_items)
    .filter(
      (list_item) =>
        list_item.element_id == element.id && list_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <React.Fragment>
      <div id={`element${element.id}-content`} className={classes}>
        <ul data-font={`${element.options["content-font-family"] || ""}`}>
          {sorted_list_items.map((list_item) => {
            return (
              <React.Fragment key={list_item.id}>
                <ContentListItem list_item={list_item} {...props} />
                <div className="cf-items-divider"></div>
              </React.Fragment>
            );
          })}
        </ul>

        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-list-element ";
    let alignmentClass = "cf-outer-center";

    if (options["cf-alignment"]) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        alignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentClass = "cf-outer-right";
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    let listWidth;
    if (options["list-width"] == "cf-list-automatic") {
    } else if (options["list-width"] == "cf-list-full") {
      listWidth = "100%";
    } else if (
      options["list-width"] == "custom" &&
      element.options["list-width-value"]
    ) {
      listWidth = `${element.options["list-width-value"]}px`;
    } else {
      listWidth = "40%";
    }

    let borderRadius = "";
    if (options["list-border-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["list-border-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["list-border-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["list-border-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["list-border-radius-" + side]) == false
              ? options["list-border-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let boxShadow;

    if (options["list-container-shadow"] == "None") {
      boxShadow = "none";
    }

    if (options["list-container-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["list-container-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["list-container-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["list-container-shadow-horizontal-distance"]
          ? `${element.options["list-container-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["list-container-shadow-vertical-distance"]
          ? `${element.options["list-container-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["list-container-shadow-blur-radius"]
          ? `${element.options["list-container-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["list-container-shadow-spread-radius"]
          ? `${element.options["list-container-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["list-container-shadow-color"]
          ? element.options["list-container-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
      boxShadow +=
        element.options["list-container-shadow-inset"] == "true" ? "inset" : "";
    }

    let listBackgroundColor;
    if (options["list-background"]) {
      listBackgroundColor = options["list-background"];
    }

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id='${element.id}'] {
        margin-left: ${_.isNil(options['margin-left']) == false ? options['margin-left'] + 'px' : '0px'} !important;
        margin-right: ${_.isNil(options['margin-right']) == false ? options['margin-right'] + 'px' : '0px'} !important;
        margin-top: ${_.isNil(options['margin-top']) == false ? options['margin-top'] + 'px' : '0px'} !important;
        margin-bottom: ${_.isNil(options['margin-bottom']) == false ? options['margin-bottom'] + 'px' : '0px'} !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element ul {
        margin: initial;
        padding-left: ${_.isNil(options['padding-left']) == false ? options['padding-left'] + 'px' : '0px'} !important;
        padding-right: ${_.isNil(options['padding-right']) == false ? options['padding-right'] + 'px' : '0px'} !important;
        padding-top: ${_.isNil(options['padding-top']) == false ? options['padding-top'] + 'px' : '0px'} !important;
        padding-bottom: ${_.isNil(options['padding-bottom']) == false ? options['padding-bottom'] + 'px' : '0px'} !important;
        ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
        ${options["list-border-style"] ? 'border-style: ' + options["list-border-style"] + ' !important;' : ''}
        ${options["list-border-style"] !== "" && _.isNil(options["list-border-width"]) == false ? 'border-width: ' + options["list-border-width"] + 'px !important;' : ''}
        ${options["list-border-style"] !== "" && _.isNil(options["list-border-width-left"]) == false ? 'border-left-width: ' + options["list-border-width-left"] + 'px !important;' : _.isNil(options["list-border-width"]) == false ? 'border-left-width: ' + options["list-border-width"] + 'px !important;' : ''}
        ${options["list-border-style"] !== "" && _.isNil(options["list-border-width-right"]) == false ? 'border-right-width: ' + options["list-border-width-right"] + 'px !important;' : _.isNil(options["list-border-width"]) == false ? 'border-right-width: ' + options["list-border-width"] + 'px !important;' : ''}
        ${options["list-border-style"] !== "" && _.isNil(options["list-border-width-top"]) == false ? 'border-top-width: ' + options["list-border-width-top"] + 'px !important;' : _.isNil(options["list-border-width"]) == false ? 'border-top-width: ' + options["list-border-width"] + 'px !important;' : ''}
        ${options["list-border-style"] !== "" && _.isNil(options["list-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["list-border-width-bottom"] + 'px !important;' : _.isNil(options["list-border-width"]) == false ? 'border-bottom-width: ' + options["list-border-width"] + 'px !important;' : ''}
        ${options["list-border-style"] !== "" && options["list-border-color"] ? 'border-color: ' + options["list-border-color"] + ' !important;' : variant.options["list-border-color"] ? 'border-color: ' + variant.options["list-border-color"] + ' !important;' : ''}
        ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
        ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
        ${listBackgroundColor ? `background: ${listBackgroundColor} !important;` : ''}
        list-style: none !important;
        display: inline-block !important;
        ${listWidth ? `width: ${listWidth} !important;` : ''}
        max-width: 100% !important;
        text-align: center !important;
        box-sizing: border-box;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element .cf-items-divider:not(:last-child) {
        ${options["list-items-divider-style"] ? `border-style: ${options["list-items-divider-style"]};` : ''}
        ${options["list-items-divider-style"] !== "" && _.isNil(options["list-items-divider-border-width"]) == false ? `border-bottom-width: ${options["list-items-divider-border-width"]}px;`: ''}
        ${options["list-items-divider-style"] !== "" && options["list-items-divider-color"] ? `border-color: ${options["list-items-divider-color"]} !important;` : options["list-border-color"] ? `border-color: ${options["list-border-color"]};` : ''}
        ${["solid", "dashed", "dotted"].includes(options["list-items-divider-style"]) ? "margin-top: 15px; margin-bottom: 15px;" : ""}
        border-top-width: 0px;
        margin-right: auto;
        margin-left: auto;
        width: 90%;
      }
      `;

    let listWidthMobile;
    if (options["list-width-mobile"] == "cf-list-full") {
      listWidthMobile = "100%";
    } else if (options["list-width-mobile"] == "custom") {
      listWidthMobile = `${element.options["list-width-value-mobile"]}px !important`;
    } else {
      listWidthMobile = "unset !important";
    }

    let listBackgroundColorMobile;
    if (options["list-background-mobile"]) {
      listBackgroundColorMobile = options["list-background-mobile"];
    }

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id='${element.id}'] {
        ${_.isNil(options['margin-mobile-left']) == false ? 'margin-left: ' + options['margin-mobile-left'] + 'px !important;' : ''}
        ${_.isNil(options['margin-mobile-right']) == false ? 'margin-right: ' + options['margin-mobile-right'] + 'px !important;' : ''}
        ${_.isNil(options['margin-mobile-top']) == false ? 'margin-top: ' + options['margin-mobile-top'] + 'px !important;' : ''}
        ${_.isNil(options['margin-mobile-bottom']) == false ? 'margin-bottom: ' + options['margin-mobile-bottom'] + 'px !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element ul {
        ${_.isNil(options['padding-mobile-left']) == false ? 'padding-left: ' + options['padding-mobile-left'] + 'px !important;' : ''}
        ${_.isNil(options['padding-mobile-right']) == false ? 'padding-right: ' + options['padding-mobile-right'] + 'px !important;' : ''}
        ${_.isNil(options['padding-mobile-top']) == false ? 'padding-top: ' + options['padding-mobile-top'] + 'px !important;' : ''}
        ${_.isNil(options['padding-mobile-bottom']) == false ? 'padding-bottom: ' + options['padding-mobile-bottom'] + 'px !important;' : ''}

        ${listWidthMobile ? `width: ${listWidthMobile};` : ''}
        ${listBackgroundColorMobile ? `background: ${listBackgroundColorMobile};` : ''}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementList;
