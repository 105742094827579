/*!
 * froala_editor v2.3.0 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */

!(function (a) {
  "function" == typeof define && define.amd
    ? define(["jquery"], a)
    : "object" == typeof module && module.exports
    ? (module.exports = function (b, c) {
        return (
          void 0 === c &&
            (c =
              "undefined" != typeof window
                ? require("jquery")
                : require("jquery")(b)),
          a(c),
          c
        );
      })
    : a(jQuery);
})(function (a) {
  "use strict";
  a.extend(a.FE.DEFAULTS, {
    codeMirror: !0,
    codeMirrorOptions: {
      lineNumbers: !0,
      tabMode: "indent",
      indentWithTabs: !0,
      lineWrapping: !0,
      mode: "text/html",
      tabSize: 2,
    },
    codeBeautifierOptions: {
      end_with_newline: !0,
      indent_inner_html: !0,
      extra_liners: [
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "blockquote",
        "pre",
        "ul",
        "ol",
        "table",
        "dl",
      ],
      brace_style: "expand",
      indent_char: "	",
      indent_size: 1,
      wrap_line_length: 0,
    },
  }),
    (a.FE.PLUGINS.codeView = function (b) {
      function c() {
        return b.$box.hasClass("fr-code-view");
      }
      function d() {
        return l ? l.getValue() : k.val();
      }
      function e(a) {
        var c = d();
        b.html.set(c),
          b.$el.blur(),
          b.$tb.find(" > .fr-command").not(a).removeClass("fr-disabled"),
          a.removeClass("fr-active"),
          b.events.focus(!0),
          b.placeholder.refresh(),
          b.undo.saveStep();
      }
      function f(a) {
        k || i(),
          !l &&
            b.opts.codeMirror &&
            "undefined" != typeof CodeMirror &&
            (l = CodeMirror.fromTextArea(k.get(0), b.opts.codeMirrorOptions)),
          b.undo.saveStep(),
          b.html.cleanEmptyTags(),
          b.html.cleanWhiteTags(!0),
          b.core.hasFocus() &&
            (b.core.isEmpty() ||
              (b.selection.save(),
              b.$el
                .find('.fr-marker[data-type="true"]:first')
                .replaceWith('<span class="fr-tmp fr-sm">F</span>'),
              b.$el
                .find('.fr-marker[data-type="false"]:last')
                .replaceWith('<span class="fr-tmp fr-em">F</span>')));
        var c = b.html.get(!1, !0);
        b.$el.find("span.fr-tmp").remove(),
          b.core.hasFocus() && b.$el.blur(),
          (c = c.replace(/<span class="fr-tmp fr-sm">F<\/span>/, "FROALA-SM")),
          (c = c.replace(/<span class="fr-tmp fr-em">F<\/span>/, "FROALA-EM")),
          b.codeBeautifier &&
            (c = b.codeBeautifier.run(c, b.opts.codeBeautifierOptions));
        var d, e;
        if (l) {
          (d = c.indexOf("FROALA-SM")),
            (e = c.indexOf("FROALA-EM")),
            d > e ? (d = e) : (e -= 9),
            (c = c.replace(/FROALA-SM/g, "").replace(/FROALA-EM/g, ""));
          var f =
              c.substring(0, d).length -
              c.substring(0, d).replace(/\n/g, "").length,
            g =
              c.substring(0, e).length -
              c.substring(0, e).replace(/\n/g, "").length;
          (d =
            c.substring(0, d).length -
            c.substring(0, c.substring(0, d).lastIndexOf("\n") + 1).length),
            (e =
              c.substring(0, e).length -
              c.substring(0, c.substring(0, e).lastIndexOf("\n") + 1).length),
            l.setSize(null, b.opts.height ? b.opts.height : "auto"),
            b.opts.heightMin &&
              b.$box
                .find(".CodeMirror-scroll")
                .css("min-height", b.opts.heightMin),
            l.setValue(c),
            l.focus(),
            l.setSelection({ line: f, ch: d }, { line: g, ch: e }),
            l.refresh(),
            l.clearHistory();
        } else
          (d = c.indexOf("FROALA-SM")),
            (e = c.indexOf("FROALA-EM") - 9),
            b.opts.heightMin && k.css("min-height", b.opts.heightMin),
            (b.opts.height || b.opts.heightMax) &&
              k.css("max-height", b.opts.height || b.opts.heightMax),
            k.val(c.replace(/FROALA-SM/g, "").replace(/FROALA-EM/g, "")),
            k.focus(),
            k.get(0).setSelectionRange(d, e);
        b.$tb.find(" > .fr-command").not(a).addClass("fr-disabled"),
          a.addClass("fr-active"),
          !b.helpers.isMobile() && b.opts.toolbarInline && b.toolbar.hide();
      }
      function g(a) {
        "undefined" == typeof a && (a = !c());
        var d = b.$tb.find('.fr-command[data-cmd="html"]');
        a
          ? (b.popups.hideAll(), b.$box.toggleClass("fr-code-view", !0), f(d))
          : (b.$box.toggleClass("fr-code-view", !1), e(d));
      }
      function h() {
        c() && g(b.$tb.find('button[data-cmd="html"]')),
          l && l.toTextArea(),
          k.val("").removeData().remove(),
          (k = null),
          m && (m.remove(), (m = null));
      }
      function i() {
        (k = a('<textarea class="fr-code" tabindex="-1">')),
          b.$wp.append(k),
          k.attr("dir", b.opts.direction),
          b.opts.toolbarInline &&
            ((m = a(
              '<a data-cmd="html" title="Code View" class="fr-command fr-btn html-switch' +
                (b.helpers.isMobile() ? "" : " fr-desktop") +
                '" role="button" tabindex="-1"><i class="fa fa-code"></i></button>'
            )),
            b.$box.append(m),
            b.events.bindClick(b.$box, "a.html-switch", function () {
              g(!1);
            }));
        var e = function () {
          return !c();
        };
        b.events.on("buttons.refresh", e),
          b.events.on("copy", e, !0),
          b.events.on("cut", e, !0),
          b.events.on("paste", e, !0),
          b.events.on("destroy", h, !0),
          b.events.on("html.set", function () {
            c() && g(!0);
          }),
          b.events.on(
            "form.submit",
            function () {
              c() &&
                (b.html.set(d()), b.events.trigger("contentChanged", [], !0));
            },
            !0
          );
      }
      function j() {
        return b.$wp ? void 0 : !1;
      }
      var k, l, m;
      return { _init: j, toggle: g, isActive: c, get: d };
    }),
    a.FE.RegisterCommand("html", {
      title: "Code View",
      undo: !1,
      focus: !1,
      forcedRefresh: !0,
      callback: function () {
        this.codeView.toggle();
      },
      plugin: "codeView",
    }),
    a.FE.DefineIcon("html", { NAME: "code" });
});
