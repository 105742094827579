import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useActionGroups from "../action_groups/useActionGroups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewActionGroup = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { ActionGroups } = useActionGroups();

  const action_group_label = () => {
    return props.field
      ? "Conditional Jump"
      : props.element
      ? "Conditional Action"
      : "Skip Logic";
  };

  const onClick = (e) => {
    let confirmation_type = props.element ? "" : "step";

    function callback(action_group) {
      dispatchCustomEvent("focusItem", {
        parent_id: props.field ? props.field.id : props.element ? props.element.id : props.step ? props.step.id : null,
        item_type: "action_groups",
        item_id: action_group.id
      });
    }

    if (props.field) {
      ActionGroups.create(props.element, null, {
        field_id: props.field.id,
      }, {
        callback: callback,
      });
    } else if (builder.displayFeatureGate(e, action_group_label())) {
      ActionGroups.create(props.element, props.step, {
        data: { "confirmation-type": confirmation_type },
      }, {
        callback: callback,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 no-padding-sides mb-3">
        <button
          className="bg-white dark:bg-slate-700 text-lg text-black dark:text-white py-2 px-4 text-center rounded-lg border-solid border-[1px] border-slate-200 dark:border-slate-700"
          onClick={onClick}
        >
          <FontAwesomeIcon icon="fas fa-plus" className="mr-1" /> Add{" "}
          {action_group_label()}
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewActionGroup;
