import React from "react";
import useContentToggle from "./useContentToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewContentToggleItem = (props) => {
  const { ContentToggle } = useContentToggle();
  const element = props.element;

  const createListItem = () => {
    ContentToggle.create(element, {
      callback: (content_toggle_item) => {
        dispatchCustomEvent("focusItem", {
          parent_id: props.element.id,
          item_type: "content_toggle_items",
          item_id: content_toggle_item.id,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 my-5">
        <button
          onClick={createListItem}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add{" "}
          {`${
            element.element_type == "collapse"
              ? "Collapse"
              : element.element_type == "tabs"
              ? "Tab"
              : element.element_type == "carousel"
              ? "Slide"
              : "Item"
          }`}
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewContentToggleItem;
