import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const useConditions = (props) => {
  let contextData;
  let builder;

  if (!props || props.builder !== false) {
    [contextData, builder] = useContext(VariantContextData);
  }

  const Conditions = {
    create: async function (condition, callback) {
      builder.create("conditions", { ...condition }, { callback: callback });
    },

    update: async function (updates, callback) {
      builder.update(updates);

      if (callback) {
        callback();
      }
    },

    duplicate: async function (og_condition, payload, skip_history, callback) {
      let attributes = {
        condition_type: og_condition.condition_type,
        url: og_condition.url,
        contactable_id: og_condition.contactable_id,
        contactable_type: og_condition.contactable_type,
        tag_id: og_condition.tag_id,
        keyword: og_condition.keyword,
        count: og_condition.count,
        data_name: og_condition.data_name,
        cta_id: og_condition.cta_id,
        data: og_condition.data || {},
        operator: og_condition.operator,
        country: og_condition.country,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      return new Promise((resolve, reject) => {
        builder.create("conditions", attributes, {
          callback: async function (condition) {
            if (callback) {
              callback(condition);
            }
            resolve(condition);
          },
          skip_history: skip_history,
          skip_select: true,
        });
      });
    },

    remove: (condition, show_message = false, callback) => {
      let message = show_message
        ? "Are you sure you want to remove this condition? Once your changes are saved, it will be irreversibly deleted."
        : null;

      builder.remove("conditions", condition.id, message);
      if (callback) {
        callback();
      }
    },
  };

  const DetachedConditions = {
    create: async function (condition, callback) {
      $.ajax({
        type: "POST",
        url: "/websites/" + condition.website_id + "/conditions",
        async: true,
        data: {
          condition: {
            ...condition,
          },
          builder: true,
        },
        success: function (data) {
          if (callback) {
            callback(JSON.parse(data));
          }
        },
      });
    },

    update: async function (condition, callback) {
      $.ajax({
        type: "PUT",
        url:
          "/websites/" + condition.website_id + "/conditions/" + condition.id,
        async: true,
        data: {
          condition: {
            ...condition,
          },
          builder: true,
        },
        success: function (data) {
          if (callback) {
            callback(data);
          }
        },
      });
    },

    duplicate: async function (og_condition, payload, skip_history, callback) {
      let attributes = {
        condition_type: og_condition.condition_type,
        url: og_condition.url,
        contactable_id: og_condition.contactable_id,
        contactable_type: og_condition.contactable_type,
        tag_id: og_condition.tag_id,
        keyword: og_condition.keyword,
        count: og_condition.count,
        data_name: og_condition.data_name,
        cta_id: og_condition.cta_id,
        data: og_condition.data || {},
        operator: og_condition.operator,
        country: og_condition.country,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      $.ajax({
        type: "POST",
        url: "/websites/" + og_condition.website_id + "/conditions",
        async: true,
        data: {
          condition: {
            ...attributes,
          },
          builder: true,
        },
        success: function (data) {
          if (callback) {
            callback(JSON.parse(data));
          }
        },
      });
    },

    remove: (condition, show_message = false, callback) => {
      let message = show_message
        ? "Are you sure you want to remove this condition? Once your changes are saved, it will be irreversibly deleted."
        : null;

      if (
        confirm(message) &&
        $("body").attr("data-controller") !== "variants"
      ) {
        $.ajax({
          type: "DELETE",
          url:
            "/websites/" + condition.website_id + "/conditions/" + condition.id,
          async: true,
          success: function () {
            if (callback) {
              callback();
            }
          },
        });
      }
    },
  };

  let currentConditionsHook =
    props && props.builder == false ? DetachedConditions : Conditions;
  return { Conditions, currentConditionsHook };
};

export default useConditions;
