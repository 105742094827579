import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingCode = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const [modalVisibility, setModalVisibility] = useState(null);
  let modalId =
    "modal-" +
    props.object_type +
    "-" +
    props.object_id +
    "-" +
    props.setting_name;

  useEffect(() => {
    let modal = document.getElementById(modalId);

    let parentModal = $(modal).parents(".modal")[0];
    if (parentModal) {
      $(modal)
        .off("shown.bs.modal")
        .on("shown.bs.modal", function () {
          $(parentModal).animate({ scrollTop: 0 });
        });
    }

    if (modalVisibility == true) {
      $(modal).modal("show");
      $(modal).addClass("in").css("display", "block");
      $("body").addClass("modal-open");

      $(modal).parents('.modal').scrollTop(0);

      let codeContainer = modal.querySelector(
        '.step-script[data-name="' + props.setting_name + '"]'
      );

      let codeTextArea = document.createElement("textarea");
      codeTextArea.className = "scriptEditor";
      codeTextArea.value = props.value ? props.value : "";

      codeContainer.innerHTML = "";
      codeContainer.appendChild(codeTextArea);

      let codeEditor = CodeMirror.fromTextArea(codeTextArea, {
        mode: "htmlmixed",
        theme: "material",
        lineWrapping: true,
        tabSize: 2,
      });

      $(modal)
        .off("hidden.bs.modal")
        .on("hidden.bs.modal", function (e) {
          let value = codeEditor.getValue();
          setModalVisibility(false);
          if (value !== props.value) {
            builder.update([
              {
                object_type: props.object_type,
                object_id: props.object_id,
                setting_name: props.setting_name,
                value: value,
                skip_history: props.skip_history,
              },
            ]);
          }
        });

      modal.onclick = function (e) {
        if (e.target == modal || $(e.target).parent()[0] == modal) {
          if (
            $(modal).is(":visible") &&
            $(modal).find(".modal:visible").length == 0
          ) {
            
            let value = codeEditor.getValue();
            setModalVisibility(false);
            if (value !== props.value) {
              builder.update([
                {
                  object_type: props.object_type,
                  object_id: props.object_id,
                  setting_name: props.setting_name,
                  value: value,
                  skip_history: props.skip_history,
                },
              ]);
            }

            setModalVisibility(false);
          }
        }
      };
    } else {
      $(modal).removeClass("in").css("display", "none");

      if ($(".modal.in").size() < 1) {
        $("body").removeClass("modal-open");
      }
    }
  });

  useEffect(() => {
    if (modalVisibility) {
      setContextData({
        ...contextData,
        selected_object_editing: modalVisibility,
      });
    }
  }, [modalVisibility]);

  return (
    <React.Fragment>
      <button
        className="border-[1px] border-solid py-2 px-5 rounded-lg text-lg mr-1 cursor-pointer bg-slate-950 dark:bg-slate-700 border-slate-950 dark:border-slate-950 text-white"
        setting_name={props.setting_name}
        object_type={props.object_type}
        object_id={props.object_id}
        style={{
          display: "block",
        }}
        ref={(node) => {
          if (node) {
            node.style.setProperty("color", "#fff", "important");
          }
        }}
        onClick={(e) => {
          if (builder.displayFeatureGate(e, props.feature_gate_type)) {
            setModalVisibility(true);
          }
        }}
      >
        
        <FontAwesomeIcon icon="fas fa-code" className="mr-3" />
        Code Editor
      </button>
      <div
        className="modal"
        id={modalId}
        data-name={props.setting_name}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="false"
        style={{
          background: "rgb(0, 0, 0, 0.21)",
          overflow: "visible",
        }}
      >
        <div className="modal-dialog !mt-44 !w-[100%] !h-[100%] ml-auto mr-auto" role="document">
          <div className="modal-content hard-center !w-[900px] bg-white dark:bg-slate-950 !shadow-2xl">
            <div className="modal-header border-slate-200 dark:border-slate-800">
              <button
                type="button"
                className="close dark:text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M12.2996 4.1001L4.09961 12.3001M12.2996 12.3001L4.09961 4.1001" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </button>
              <h4
                className="text-2xl font-bold text-black dark:text-white"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {props.label}
              </h4>
            </div>
            <div className="modal-body">
              <div
                className="row hard-center"
                style={{
                  maxWidth: "900px",
                }}
              >
                <div
                  data-name={props.setting_name}
                  className="step-script"
                ></div>
                <p className="script-editor-note text-lg text-black dark:text-white smooth mt-5">
                  Javascript must have &lt;script&gt; tags. CSS must have
                  &lt;style&gt; tags. Added scripts will run as asynchronous.
                </p>
              </div>
            </div>
            <div className="modal-footer !border-slate-200 dark:!border-slate-800">
              <button
                type="button"
                className="btn bg-white border-solid border-[1px] rounded-md !border-slate-200 text-black text-xl hover:bg-blue-100 hover:!border-blue-100 hover:text-blue-600"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingCode;
