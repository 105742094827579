import React, { useContext, useEffect, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import useContentLists from "./useContentLists";
import htmlToLabel from "../../utils/htmlToLabel";

const ListSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const list_item = props.list_item;
  const element = contextData.objects.variant.elements[list_item.element_id];
  const website = contextData.objects.website;
  const variant = contextData.objects.variant;

  const { ContentLists } = useContentLists();

  useEffect(() => {
    ContentLists.sortableStart(list_item);
  });

  // Make sure the content has a wrapped tag when calling `text`
  let list_item_content = htmlToLabel(list_item.content);

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={list_item}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="content_list_items"
        object_id={list_item.id}
        title={list_item_content}
        label_max_length={25}
        label_hover_max_length={14}
        condition={element.element_type == "list"}
        style={{
          marginBottom: "0px",
        }}
        position={list_item.position ? list_item.position : ""}
        panel_type="modal"
        duplicate_payload={{
          element_id: element.id,
          position: list_item.position + 1,
        }}
        label_icon={
          list_item.visual_type == "bullet"
            ? "fas fa-circle"
            : list_item.visual_type == "numbered"
            ? "fas fa-list-ol"
            : list_item.visual_type == "empty"
            ? "fas fa-circle"
            : list_item.visual_type == "letter"
            ? "fas fa-font"
            : list_item.visual_type == "emoji"
            ? "fas fa-smile"
            : list_item.visual_type == "icon"
            ? list_item.icon
            : list_item.visual_type == "image"
            ? "fas fa-image"
            : element.options["list_visual_type"] == "bullet"
            ? "fas fa-circle"
            : element.options["list_visual_type"] == "numbered"
            ? "fas fa-list-ol"
            : element.options["list_visual_type"] == "empty"
            ? "fas fa-circle"
            : element.options["list_visual_type"] == "letter"
            ? "fas fa-font"
            : element.options["list_visual_type"] == "emoji"
            ? "fas fa-smile"
            : element.options["list_visual_type"] == "icon"
            ? element.options["list_icon"]
            : element.options["list_visual_type"] == "image"
            ? "fas fa-image"
            : "fas fa-circle"
        }
      >
        <VariantBuilderSetting
          object={list_item}
          setting_name="[visual_type]"
          label="Item Icon Type"
          setting_type="dropdown"
          options={[
            { value: "", text: "Default" },
            { value: "bullet", text: "Bullet" },
            { value: "icon", text: "Icon" },
            { value: "emoji", text: "Emoji" },
            { value: "image", text: "Image" },
            { value: "letter", text: "Letter" },
            { value: "numbered", text: "Numbered" },
            { value: "empty", text: "Empty" },
          ]}
          object_type="content_list_items"
          object_id={list_item.id}
          value={list_item.visual_type ? list_item.visual_type : ""}
          condition={element.element_type == "list"}
        />

        <VariantBuilderSetting
          object={list_item}
          label="Icon Image"
          setting_name="[image]"
          setting_type="image"
          object_type="content_list_items"
          object_id={list_item.id}
          value={list_item.image ? list_item.image : ""}
          condition={
            element.element_type == "list" && list_item.visual_type === "image"
          }
        />

        <VariantBuilderSetting
          object={list_item}
          label="Icon"
          setting_name="[icon]"
          setting_type="icon"
          object_type="content_list_items"
          default_value="fas fa-check"
          object_id={list_item.id}
          value={list_item.icon}
          condition={
            element.element_type == "list" && list_item.visual_type === "icon"
          }
        />

        <VariantBuilderSetting
          object={list_item}
          label="Letter"
          setting_name="[letter]"
          setting_type="text"
          maxLength={1}
          object_type="content_list_items"
          object_id={list_item.id}
          value={list_item.letter}
          condition={
            element.element_type == "list" && list_item.visual_type === "letter"
          }
        />

        <VariantBuilderSetting
          object={list_item}
          label="Emoji"
          setting_name="[emoji]"
          setting_type="emoji"
          object_type="content_list_items"
          object_id={list_item.id}
          value={list_item.emoji}
          condition={
            element.element_type == "list" && list_item.visual_type === "emoji"
          }
        />

        <VariantBuilderSetting
          object={list_item}
          label="Content"
          setting_name="[content]"
          setting_type="editor"
          object_type="content_list_items"
          object_id={list_item.id}
          value={list_item.content ? list_item.content : ""}
          tooltip="The item content is the text displayed to the visitor in the list"
          tooltip_position="right"
        />

        <VariantBuilderSetting
          object={list_item}
          setting_name="[content]"
          setting_type="code"
          label="HTML Editor"
          object_type="content_list_items"
          object_id={list_item.id}
          value={list_item.content ? list_item.content : ""}
          feature_gate_type="Custom HTML"
        />
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ListSettings;
