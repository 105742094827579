import React, { useState, useEffect, useRef } from "react";
import useActions from "../actions/useActions";
import ActionSettingsInput from "./ActionSettingsInput";
import CFSelect from "../shared/forms/CFSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActionForm = (props) => {
  const form = useRef(null);
  const { currentActionsHook } = useActions(props);
  const [formData, setFormData] = useState({
    contactable_type: props.contactable_type,
    contactable_id: props.contactable_id,
    element_id: props.element ? props.element.id : null,
    cta_id: props.cta ? props.cta.id : null,
    id: props.action ? props.action.id : null,
    action_type: props.action ? props.action.action_type : null,
    data: props.action ? props.action.data : {},
  });
  const Actions = currentActionsHook;
  const action = props.action;
  const website = props.website;
  const actionsList = props.actionsList;

  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedActionOption, setSelectedActionOption] = useState(
    props.action
      ? {
          value: action.action_type,
          label: action.action_type,
        }
      : undefined
  );

  useEffect(() => {
    if (action) {
      let actionObject = Object.values(actionsList).find((integration) =>
        integration.action_types.some(
          (item) => item.action_type === action.action_type
        )
      );

      setSelectedAction(
        actionObject && actionObject?.action_types
          ? actionObject.action_types.find(
              (item) => item.action_type === action.action_type
            )
          : null
      );
    }
  }, [action]);

  const actionSelectOptions = () => {
    let selectOptions = [
      { label: "Select an automation type", options: [], isDisabled: true },
    ];

    Object.values(actionsList).forEach((action) => {
      let groupOption = { label: action.integration_type, options: [] };

      Object.values(action.action_types).forEach((action_type) => {
        groupOption.options.push({
          label: action_type.action_type,
          value: action_type.action_type,
          icon: `/images/${action.icon}`
        });
      });

      selectOptions.push(groupOption);
    });

    return selectOptions;
  };

  const handleSubmit = (newFormData) => {
    if (!selectedAction) {
      return false;
    }

    const payload = {
      website_id: website.id,
      action_object: newFormData || formData,
    };

    if (action) {
      Actions.update(payload, (data) => {
        props.onUpdate(data);
      });
    } else {
      Actions.create(payload, (data) => {
        props.onCreate(data);
      });
    }
  };

  return (
    <React.Fragment>
      <div
        className={`w-full relative max-w-[648px] float-left`}
        data-attribute="action_type"
      >
        <form
          className={`flex gap-x-3 relative ${!action ? 'pb-[10px]' : ''}`}
          data-remote="true"
          ref={form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input name="builder" value="true" type="hidden" />
          <input
            name={`action_object[contactable_type]`}
            value={props.contactable_type}
            type="hidden"
          />
          <input
            name={`action_object[contactable_id]`}
            value={props.contactable_id}
            type="hidden"
          />

          {props.element && (
            <input
              name="action_object[element_id]"
              value={props.element.id}
              type="hidden"
            />
          )}

          {props.cta && (
            <input
              name="action_object[cta_id]"
              value={props.cta.id}
              type="hidden"
            />
          )}

          {action && (
            <input name="action_object[id]" value={action.id} type="hidden" />
          )}
          <div className="flex-grow min-w-[25%] max-w-[50%]">
            <CFSelect
              id="cf-select-automation_type"
              className="smart-select"
              value={selectedActionOption}
              isSearchable={true}
              name="action_object[action_type]"
              options={actionSelectOptions()}
              onChange={(e) => {
                const actionType = e.value;
                setSelectedActionOption({
                  value: actionType,
                  label: actionType,
                });

                setSelectedAction(
                  Object.values(actionsList)
                    .find((integration) =>
                      integration.action_types.some(
                        (item) => item.action_type === e.value
                      )
                    )
                    .action_types.find((item) => item.action_type === e.value)
                );

                if (props.builder && action) {
                  dispatchCustomEvent("updateBuilder", {
                    updates: [
                      {
                        object_type: "actions",
                        object_id: action.id,
                        setting_name: "[action_type]",
                        value: actionType,
                      },
                    ],
                  });
                } else {
                  let newFormData = formData;
                  newFormData.action_type = actionType;
                  setFormData(newFormData);
                }
              }}
            />
          </div>

          {selectedAction && selectedAction["settings"] && (
            <React.Fragment>
              {selectedAction["settings"].map((setting, index) => {
                let integrationType =
                  selectedAction && selectedAction.action_type.split(" -")[0];
                let integration = $.map(
                  website.integrations,
                  function (integration) {
                    if (integration.integration_type == integrationType)
                      return integration;
                  }
                )[0];

                return (
                  <ActionSettingsInput
                    action={action}
                    key={`${setting.name}-${setting.label}-${index}`}
                    type="automation"
                    setting={setting}
                    integration={integration}
                    formData={formData}
                    setFormData={setFormData}
                    onChange={(e) => {
                      let newFormData = formData;
                      _.set(
                        newFormData,
                        `${
                          !integration
                            ? setting.attribute
                            : `[data][${setting.attribute}]`
                        }`,
                        e.value || e.target.value
                      );
                      setFormData(newFormData);

                      if (
                        action &&
                        !props.builder &&
                        setting.input_type == "select"
                      ) {
                        handleSubmit(newFormData);
                      }
                    }}
                    onDone={(e) => {
                      if (action) {
                        if (props.builder) {
                          const value = e.value || e.target.value;
                          dispatchCustomEvent("updateBuilder", {
                            updates: [
                              {
                                object_type: "actions",
                                object_id: action.id,
                                setting_name: !integration
                                  ? setting.attribute
                                  : `[data][${setting.attribute}]`,
                                value: value,
                              },
                            ],
                          });
                        } else if (setting.input_type !== "select") {
                          handleSubmit();
                        }
                      }
                    }}
                    {...props}
                  />
                );
              })}
            </React.Fragment>
          )}

          {!action && (
            <button
              className={`w-[75px] border-0 border-transparent py-3 px-3 rounded-lg text-xl ml-1 mr-1 semibold bg-blue-600 text-white cursor-pointer float-right`}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Save
            </button>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default ActionForm;
