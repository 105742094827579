import React from "react";
import PropTypes from "prop-types";

const FieldSurveyNext = (props) => {
  let element = props.element;
  let field = props.field;
  let important = props.builder !== true ? " !important" : "";

  return (
    <React.Fragment>
      <div
        className={
          "cf-row cf-outer-center cf-survey-next-outer " +
          (element.options["options-width"]
            ? element.options["options-width"]
            : "")
        }
        style={{
          display:
            (props.builder == true && field.multi_select + "" == "true") ||
            (field.multi_select + "" == "true" &&
              element.options["survey-next-hide"] == "false") ||
            (field.options["confirmation_button"] == "true" &&
              element.options["survey-next-hide"] == "false") ||
            (props.builder == true &&
              field.options["confirmation_button"] == "true")
              ? "block" + important
              : "none" + important,
        }}
        data-field-id={field.id}
      >
        <div
          className="cf-survey-next-inner cf-outer-left"
          style={{ display: "block" }}
        >
          <button
            className={
              "cf-survey-next " +
              (element.options["button-size"]
                ? element.options["button-size"]
                : "")
            }
            style={{
              fontFamily: element.options["field-font"]
                ? element.options["field-font"] + important
                : undefined,
            }}
          >
            <span
              className="cf-survey-next-text"
              style={{
                fontFamily: "inherit" + important,
              }}
            >
              {element.options["survey-next-text"]
                ? element.options["survey-next-text"]
                : "OK →"}
            </span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldSurveyNext;
