import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import ElementSettingsProductFeed from "./ElementSettingsProductFeed";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import ElementSettingsProductContent from "./ElementSettingsProductContent";

import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import ProductLeftAlignmentButtonActive from "/app/assets/images/elements/product_left_alignment_button_active.svg";
import ProductLeftAlignmentButton from "/app/assets/images/elements/product_left_alignment_button.svg";
import ProductRightAlignmentButton from "/app/assets/images/elements/product_right_alignment_button.svg";
import ProductRightAlignmentButtonActive from "/app/assets/images/elements/product_right_alignment_button_active.svg";

const ElementSettingsProductHero = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let element = builder.selected().object;

  return (
    <React.Fragment>
      {element.element_type == "product_hero" && (
        <div className="col-xs-12">
          <React.Fragment>
            <VariantBuilderPanelCollapse name="Product Hero" open={true}>
              <ElementSettingsProductFeed {...props} />
            </VariantBuilderPanelCollapse>

            <VariantBuilderPanelCollapse name="Product Content" open={true}>
              <VariantBuilderSetting
                object={element}
                label="Layout"
                setting_name="[options][products-layout]"
                setting_type="options_images"
                object_type="elements"
                object_id={element.id}
                value={element.options["products-layout"]}
                options={[
                  {
                    value: "cf-left",
                    image: ProductLeftAlignmentButton,
                    image_active: ProductLeftAlignmentButtonActive,
                  },
                  {
                    value: "cf-right",
                    image: ProductRightAlignmentButton,
                    image_active: ProductRightAlignmentButtonActive,
                  },
                ]}
                device="desktop"
              />

              <VariantBuilderSetting
                object={element}
                label="Layout"
                setting_name="[options][products-layout-mobile]"
                setting_type="options_images"
                object_type="elements"
                object_id={element.id}
                value={element.options["products-layout-mobile"]}
                options={[
                  {
                    value: "cf-left",
                    image: ProductLeftAlignmentButton,
                    image_active: ProductLeftAlignmentButtonActive,
                  },
                  {
                    value: "cf-right",
                    image: ProductRightAlignmentButton,
                    image_active: ProductRightAlignmentButtonActive,
                  },
                ]}
                device="mobile"
              />
              <ElementSettingsProductContent {...props} />
            </VariantBuilderPanelCollapse>
          </React.Fragment>
        </div>
      )}
    </React.Fragment>
  );
};

export default ElementSettingsProductHero;
