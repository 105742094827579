const htmlToLabel = (htmlString) => {
  // Create a temporary DOM element
  var tempElement = document.createElement('div');
  
  // Set the innerHTML to the provided string
  tempElement.innerHTML = htmlString;
  
  // Return the innerText, which gives the human-readable version
  return tempElement.innerText;
}

export default htmlToLabel;