import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantProfile = (props) => {
  let variant = props.variant;
  let user = props.user;
  let important = props.builder !== true ? " !important" : "";

  let profile_brand = props.website.brand ? props.website.brand : "";
  let profile_avatar =
    user && user.avatar
      ? user.avatar
      : "https://img.convertflow.co/static/avatar.jpg";

  let profile_name;
  if (user && user.name) {
    profile_name = user.name + (user.lastname ? " " + user.lastname : "");
  } else {
    profile_name = "A team member";
  }

  if (props.builder == true) {
    const [contextData, builder] = useContext(VariantContextData);

    useEffect(() => {
      let profile_tag = document.querySelectorAll(".cf-profile");
      profile_tag.forEach((profile) => {
        profile.onclick = function (e) {
          $('.panel-tab[data-tab="theme"]').trigger("click");
          const collapse = $('.tab-row[data-name="Container"]');

          if ($(collapse).find(".tab-settings").is(":visible") == false) {
            $(collapse).find(".tab-toggle").trigger("click");
          }
        };
      });
    });
  }

  return (
    <React.Fragment>
      <div
        className={
          "cf-row cf-outer-center cf-profile " +
          (variant.options["profile-alignment"] !== "left"
            ? "cf-center"
            : "cf-left")
        }
        style={{
          display: ["on", "true"].includes(variant.options["show-profile"] + "")
            ? "block"
            : "none",
          boxShadow:
            ["on", "true", true].includes(variant.options["profile-shadow"]) &&
            ["inline", "page"].includes(props.cta.cta_type) == false
              ? "0 3px 7px 0 rgba(50,57,66,0.06)"
              : "none",
          background:
            variant.options["profile-theme"] == "dark"
              ? "rgba(22, 31, 49, 1)"
              : "#fff",
          position: props.cta.cta_type !== "inline" ? "absolute" : undefined,
          top: props.cta.cta_type !== "inline" ? "0px" : undefined,
          borderRadius:
            props.cta.cta_type !== "inline" ? "4px 4px 0px 0px" : undefined,
          height:
            variant.options["profile-alignment"] == "left"
              ? "60px"
              : (props.cta.cta_type == "overlay" &&
                  (props.cta.overlay_type == "splash" ||
                    variant.options["container-width"] == "100%")) ||
                props.cta.cta_type == "inline" ||
                props.cta.cta_type == "page"
              ? "100px"
              : "71px",
          borderBottom:
            ["page", "inline"].includes(props.cta.cta_type) &&
            ["on", "true", true].includes(variant.options["show-profile"]) &&
            variant.options["profile-theme"] !== "dark"
              ? "1px solid #f1f1f1"
              : undefined,
        }}
      >
        <div className="cf-row cf-outer-center cf-content-container">
          <div
            className={
              "cf-profile-img cf-col-xs-" +
              (variant.options["profile-alignment"] !== "left" ? 12 : 2)
            }
          >
            <img
              src={profile_avatar}
              style={{
                width:
                  variant.options["profile-alignment"] == "left"
                    ? "40px"
                    : "45px",
                height:
                  variant.options["profile-alignment"] == "left"
                    ? "40px"
                    : "45px",
                marginBottom:
                  variant.options["profile-alignment"] !== "left"
                    ? "-3px"
                    : undefined,
                border:
                  variant.options["profile-theme"] == "dark"
                    ? "2px solid #151c2c"
                    : "2px solid #fff",
                marginTop:
                  variant.options["profile-alignment"] == "left"
                    ? "0px"
                    : (props.cta.cta_type == "overlay" &&
                        (props.cta.overlay_type == "splash" ||
                          variant.options["container-width"] == "100%")) ||
                      props.cta.cta_type == "inline" ||
                      props.cta.cta_type == "page"
                    ? "0px"
                    : "-20px",
              }}
            ></img>
          </div>
          <div
            className={
              "cf-col-xs-" +
              (variant.options["profile-alignment"] !== "left" ? 12 : 9) +
              " cf-profile-content"
            }
            style={{
              padding: "5px 8px 8px",
            }}
          >
            {variant.options["profile-alignment"] !== "left" && (
              <style>
                {"\
                  #cta_" +
                  props.cta.id +
                  " .cf-profile-name {\
                    padding-right: 3px !important;\
                  }\
                "}
              </style>
            )}
            <p>
              <span
                data-user-id={user ? user.id : ""}
                user-id={user ? user.id : ""}
                className="cf-profile-name"
                style={{
                  color:
                    variant.options["profile-theme"] == "dark"
                      ? "#fff"
                      : "rgb(60, 73, 83)",
                  display:
                    variant.options["profile-alignment"] == "left"
                      ? "block" + important
                      : undefined,
                }}
              >
                {profile_name}
              </span>
              <span
                className={"cf-profile-title"}
                id={"cta" + props.cta.id + "-brand"}
              >
                {profile_brand}
              </span>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantProfile;
