import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingCheckbox = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const checked = props.value + "" == "true" || props.value == "on";

  return (
    <React.Fragment>
      <label
        style={{
          cursor: "pointer",
        }}
      >
        <input
          type="checkbox"
          setting_name={props.setting_name}
          className="ios-switch green"
          style={{ display: "none" }}
          checked={
            props.value + "" == "true" || props.value == "on" ? true : false
          }
          disabled={props.disabled}
          onChange={(e) => {
            if (
              e.target.checked !== true ||
              builder.displayFeatureGate(e, props.feature_gate_type)
            ) {
              builder.update([
                {
                  object_type: props.object_type,
                  object_id: props.object_id,
                  setting_name: props.setting_name,
                  value: e.target.checked + "",
                  skip_history: props.skip_history,
                },
              ]);

              if (props.callback) {
                props.callback(e.target.checked + "");
              }
            }
          }}
        />
        <article>
          <article></article>
        </article>
      </label>
    </React.Fragment>
  );
};

export default VariantBuilderSettingCheckbox;
