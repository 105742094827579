import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderFontWeight = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">
        <button
          className={`flex-1 h-[30px] text-2xl p-2 border-none bg-transparent rounded-lg ${
            props.value == "400" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("400");
          }}
          title={`Regular`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
            <path d="M1.05859 5.11765V9.19608H5.188C6.28608 9.19608 7.17624 8.28309 7.17624 7.15686C7.17624 6.03063 6.28608 5.11765 5.188 5.11765H1.05859ZM1.05859 5.11765H4.57624C5.67431 5.11765 6.56448 4.20466 6.56448 3.07843C6.56448 1.9522 5.67431 1.03922 4.57624 1.03922H1.05859V5.11765Z" stroke={contextData.dark_mode ? `white` : "black"} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 border-none bg-transparent rounded-lg ${
            props.value == "700" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("700");
          }}
          title={`Bold`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
            <path d="M1.05859 5.11765V9.19608H5.188C6.28608 9.19608 7.17624 8.28309 7.17624 7.15686C7.17624 6.03063 6.28608 5.11765 5.188 5.11765H1.05859ZM1.05859 5.11765H4.57624C5.67431 5.11765 6.56448 4.20466 6.56448 3.07843C6.56448 1.9522 5.67431 1.03922 4.57624 1.03922H1.05859V5.11765Z" stroke={contextData.dark_mode ? `white` : "black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

        <button
          className={`flex-1 h-[30px] text-2xl p-2 border-none bg-transparent rounded-lg ${
            props.value == "900" ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue("900");
          }}
          title={`Extra Bold`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
            <path d="M1.05859 5.11765V9.19608H5.188C6.28608 9.19608 7.17624 8.28309 7.17624 7.15686C7.17624 6.03063 6.28608 5.11765 5.188 5.11765H1.05859ZM1.05859 5.11765H4.57624C5.67431 5.11765 6.56448 4.20466 6.56448 3.07843C6.56448 1.9522 5.67431 1.03922 4.57624 1.03922H1.05859V5.11765Z" stroke={contextData.dark_mode ? `white` : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

      </div>
    </React.Fragment>
  );
};

export default VariantBuilderFontWeight;
