import React from "react";
import PropTypes from "prop-types";
import BroadcastsListItemOptions from "../broadcasts/BroadcastsListItemOptions";
import BroadcastsCampaignToggle from "../broadcasts/BroadcastsCampaignToggle";
import BroadcastsGroupsSmallList from "../broadcasts_groups/BroadcastsGroupsSmallList";

const BroadcastsListItem = (props) => {
  const websiteLink = "/websites/" + props.broadcast.website_id;
  const broadcastLink =
    websiteLink + "/broadcasts/" + props.broadcast.id + "/edit";
  return (
    <React.Fragment>
      <div data-id={props.broadcast.id} className="BroadcastsListItem row">
        <div className="row hard-center">
          <div className="col-xs-12">
            <div className="col-xs-3">
              <div className="col-xs-8 no-padding-sides">
                <a href={broadcastLink}>
                  <div className="row hard-center">
                    <p
                      className="light ellipsis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={props.broadcast.name}
                      style={{ textTransform: "capitalize", margin: "0" }}
                    >
                      {props.broadcast.name}
                    </p>
                  </div>
                </a>
                <div className="row hard-center">
                  <BroadcastsGroupsSmallList {...props} />
                </div>
              </div>
            </div>
            <div className="col-xs-9 float-right">
              <a href={broadcastLink}>
                <div
                  className="col-xs-1 no-padding-sides broadcasts-list-row-column"
                  style={{ minWidth: "85px" }}
                >
                  <p className="light ellipsis">
                    {props.broadcast.ctas.length}
                  </p>
                </div>
                <div className="col-xs-2 broadcasts-list-row-column">
                  <p className="light ellipsis">
                    {numberWithCommas(props.broadcast.views)}
                  </p>
                </div>
                <div className="col-xs-2 no-padding-sides broadcasts-list-row-column">
                  <p className="light ellipsis">
                    {numberWithCommas(props.broadcast.conversions)} (
                    {parseFloat(props.broadcast.conversion_rate).toFixed(0)}%)
                  </p>
                </div>
                <div className="col-xs-2 no-padding-sides broadcasts-list-row-column">
                  <p className="light ellipsis">
                    {props.currency}
                    {numberWithCommas(props.broadcast.revenue)}
                  </p>
                </div>
              </a>
              <div className="col-xs-2 no-padding-sides broadcasts-list-row-column">
                <BroadcastsCampaignToggle {...props} />
              </div>
              <div className="col-xs-2 no-padding-sides broadcasts-list-row-column right !float-right text-right">
                <BroadcastsListItemOptions {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BroadcastsListItem;
