import React, { useState } from "react";
import NewFieldModal from "../fields/NewFieldModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewQuizField = (props) => {
  const buttonLabel = props.sub_question ? "Add Sub-Question" : "Add Question";

  const [newFieldModalVisibility, setNewFieldModalVisibility] = useState(false);

  const addQuestionButton = () => {
    return (
      <div className="col-xs-12 my-3">
        <button
          onClick={() => {
            dispatchCustomEvent("newObject", {
              ...(props.payload || {}),
              object_type: "Fields",
              element_id: props.element.id
            });
          }}
          className={`w-full p-2 !pl-0 text-left bg-transparent border-none ${
            !props.sub_question
              ? "text-xl text-blue-600 dark:text-blue-200"
              : "text-lg text-slate-500 dark:text-slate-500"
          } dark:hover:text-white`}
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" />
          {buttonLabel}
        </button>
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.payload.position <= 1 && !props.sub_question ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid #C0392B",
            borderRadius: "4px",
            boxShadow: "0px 0px 4px 0px #C0392B",
            color: "#C0392B",
            fontSize: "13px",
            fontWeight: "600",
            maxWidth: "90%",
            margin: "0px",
          }}
        >
          <div style={{ marginLeft: "8px", fontSize: "13px", padding: "8px" }}>
            <span
              className="glyphicon glyphicon-exclamation-sign"
              aria-hidden="true"
              style={{ fontSize: "13px", marginRight: "10px" }}
            ></span>
            Add your first question
          </div>

          <div style={{ borderTop: "1px solid #C0392B", width: "100%" }}>
            {addQuestionButton()}
          </div>
        </div>
      ) : (
        addQuestionButton()
      )}
    </React.Fragment>
  );
};

export default NewQuizField;
