import React, { useState, useContext, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useFields from "./useFields.js";
import FieldTemplateItem from "./FieldTemplateItem";

const NewFieldPanel = (props) => {
  const [contextData] = useContext(VariantContextData);
  const [searchText, setSearchText] = useState("");
  const { Fields } = useFields();
  const element = Object.values(contextData.objects.variant.elements).find(
    (element) => element.id == contextData.new_object.element_id
  );

  const createField = function (template_id = 0) {
    const template = template_id > 0 ? template_id : undefined;
    Fields.create(element, contextData.new_object, template, (field) => {

      if (element.element_type == 'quiz') {
        dispatchCustomEvent("selectObject", {
          object_type: "elements",
          object_id: element.id,
          tab: 'Content'
        });

        setTimeout(() => {
          dispatchCustomEvent("focusItem", {
            parent_id: element.id,
            item_type: "fields",
            item_id: field.id,
          });
        }, 500);
      }
    });
  };

  const searchedTemplates =
    props.field_templates &&
    props.field_templates.filter(function (template) {
      return template.name.toLowerCase().includes(searchText.toLowerCase());
    });

  return (
    <React.Fragment>
      <div id="new-field" className="w-full px-3 py-7">
        <div id="new-field-templates" className="w-full new-field-templates">
          <div className="w-full text-xl font-semibold text-black dark:text-white">
            Quiz Question Templates
          </div>

          <div className="w-full mt-4 relative">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400">
              <i className="fas fa-search text-2xl"></i>
            </span>
            <input
              type="text"
              name="name"
              className="w-full px-2 py-4 pl-8 text-xl rounded-lg text-slate-500 dark:focus:color-white bg-white dark:bg-slate-950 border-none focus:border-none focus:outline-none"
              placeholder="Search..."
              defaultValue={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div id="fields-create-from-scratch" className="w-full">
            <div
              className="cursor-pointer w-full h-[50px] mt-4 mb-9 text-center leading-[50px] text-lg rounded-lg text-slate-500 hover:text-blue-600 dark:hover:text-white bg-white hover:bg-white dark:bg-slate-950 dark:hover:bg-slate-700 border-dashed border-[1px] border-slate-300 hover:border-blue-600 dark:border-slate-500 dark:hover:border-white"
              onClick={createField}
            >
              Create From Scratch
            </div>
          </div>

          {searchedTemplates.map((item) => (
            <FieldTemplateItem
              key={item.id}
              item={item}
              selectTemplate={(e) => createField(e)}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewFieldPanel;
