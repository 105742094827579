var remove = function (template_id, account_id) {
  if (
    confirm(
      "WARNING: Are you sure you want to remove this template from this account?"
    )
  ) {
    $.ajax({
      url:
        window.location.origin +
        "/templates/" +
        template_id +
        "/remove?account_id=" +
        account_id,
      dataType: "JSON",
      type: "GET",
      complete: function (data) {
        $("#template-" + template_id).modal("hide");
        $("#template-" + template_id + "-thumbnail").remove();
      },
    });
  }
};

export default remove;
