import React from "react";

const VariantBuilderToolbarBreadcrumbs = (props) => {
  const cta = props.cta;

  return (
    <div className="inline-flex w-auto whitespace-nowrap max-w-[225px]">
      <div className="flex-grow text-xl">
        <a
          className="text-slate-500 hover:text-black dark:text-gray-400 dark:hover:text-white"
          href={`/websites/${props.website.id}/cta/?cta_type=${cta.cta_type}`}
        >
          {cta.cta_type == "page"
            ? "Landing Pages"
            : ["overlay", "hook"].includes(cta.cta_type)
            ? "Popups"
            : cta.cta_type == "bar"
            ? "Sticky Bars"
            : cta.cta_type == "inline"
            ? "Embeds"
            : "Campaigns"}
          <span className="mx-1 p-1"><span className="glyphicon glyphicon-menu-right !text-base" aria-hidden="true"></span></span>
        </a>
      </div>
      <div className="flex-grow overflow-hidden overflow-ellipsis text-xl">
        <a
          className="text-black semibold dark:white hover:text-black dark:text-white dark:hover:text-white"
          href={`/websites/${props.website.id}/cta/${cta.id}`}
        >
          {cta.name ? cta.name : `Campaign ${cta.id}`}
        </a>
      </div>
    </div>
  );
};

export default VariantBuilderToolbarBreadcrumbs;
