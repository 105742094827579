import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { trimEnd } from "lodash";

const VariantBuilderSettingDate = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [defaultValue, setDefaultValue] = useState(
    props.value ? props.value : ""
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const input = useRef(null);

  function updateSetting(value) {
    if (props.skip_update !== true) {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          skip_history: props.skip_history,
        },
      ]);
    }

    if (props.callback) {
      let old_value = props.value;
      props.callback(value, old_value);
    }
  }

  useEffect(() => {
    setDefaultValue(props.value);

    $(input.current).datetimepicker({
      defaultDate: new Date().setHours(23, 59, 59, 999),
      widgetPositioning: { vertical: "bottom", horizontal: "left" },
      format: "YYYY-MM-DD h:mm:ss a",
      showTodayButton: true,
      toolbarPlacement: "bottom",
      widgetParent: `.setting[data-setting_name="${props.setting_name}"]`,
      icons: {
        time: "glyphicon glyphicon-time builder-link",
        date: "glyphicon glyphicon-calendar",
        up: "glyphicon glyphicon-chevron-up",
        down: "glyphicon glyphicon-chevron-down",
        previous: "glyphicon glyphicon-chevron-left",
        next: "glyphicon glyphicon-chevron-right",
        today: "glyphicon glyphicon-trash builder-link",
        clear: "glyphicon glyphicon-trash",
        close: "glyphicon glyphicon-remove",
      },
      keyBinds: {
        up: function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().subtract(7, "d"));
          } else {
            this.date(this.date().clone().add(1, "m"));
          }
        },
        down: function (widget) {
          if (!widget) {
            this.show();
          } else if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().add(7, "d"));
          } else {
            this.date(this.date().clone().subtract(1, "m"));
          }
        },
        "control up": function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().subtract(1, "y"));
          } else {
            this.date(this.date().clone().add(1, "h"));
          }
        },
        "control down": function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().add(1, "y"));
          } else {
            this.date(this.date().clone().subtract(1, "h"));
          }
        },
        left: function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().subtract(1, "d"));
          }
        },
        right: function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().add(1, "d"));
          }
        },
        pageUp: function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().subtract(1, "M"));
          }
        },
        pageDown: function (widget) {
          if (widget.find(".datepicker").is(":visible")) {
            this.date(this.date().clone().add(1, "M"));
          }
        },
        enter: function () {
          this.hide();
        },
        escape: function () {
          this.hide();
        },
        "control space": function (widget) {
          if (widget.find(".timepicker").is(":visible")) {
            widget.find('.btn[data-action="togglePeriod"]').click();
          }
        },
        t: function () {
          this.date(moment());
        },
        delete: function () {
          this.clear();
        },
      },
    });

    $(input.current).on("dp.change", function (event) {
      const value = $(input.current).val();
      if (value) {
        updateSetting(value);
      }
    });
  }, [props.object_id]);

  return (
    <React.Fragment>
      <div className="input-group date">
        <input
          type="text"
          setting_name={props.setting_name}
          name={props.setting_name}
          className="form-control text-field input-lg"
          defaultValue={defaultValue}
          ref={input}
          onBlur={(e) => {
            const value = input.current.value;
            if (value) {
              updateSetting(value);
            }
          }}
          placeholder={props.placeholder}
        />
        <span
          onClick={() => {
            $(input.current).data("DateTimePicker").toggle();
          }}
          className="input-group-addon date-toggle"
        >
          <span className="glyphicon glyphicon-calendar"></span>
        </span>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingDate;
