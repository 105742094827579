import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import Section from "../sections/Section";
import useSections from "../sections/useSections.js";

const Step = (props) => {
  let cta = props.cta;
  let variant = props.variant;
  let step = props.step;
  let sorted_sections = Object.values({ ...variant.sections, ...step.sections })
    .filter(
      (section) =>
        section.step_id == step.id &&
        !section.element_id &&
        section.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  const classes = style.classes(cta, variant, step);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    cta,
    variant,
    step
  );

  let important = props.builder !== true ? " !important" : "";

  let styling = {};

  if (step.position == 1) {
    styling["display"] = "block" + important;
    styling["visibility"] = "initial" + important;
  }

  function variantWithoutChildren(variant) {
    let response = {};
    Object.keys(variant)
      .filter(
        (k) =>
          [
            "steps",
            "sections",
            "elements",
            "fields",
            "content_list_items",
            "field_options",
            "actions",
            "action_groups",
            "content_toggle_items",
            "conditions",
            "products",
            "product_variants",
            "product_recommendations",
          ].includes(k) == false
      )
      .forEach((key) => (response[key] = variant[key]));
    return response;
  }

  function sectionWithChildren(section) {
    if (section) {
      let response = {
        ...section,
        element: Object.values(variant.elements).find(
          (element) => element.id == section.element_id
        ),
      };
      response.elements = {};
  
      Object.values(variant.elements)
        .filter((element) => element.section_id == section.id)
        .forEach((element) => {
          let elementReponse = {
            ...element,
            sections: {},
            fields: {},
            content_list_items: {},
            products: {},
            product_recommendations: {},
            content_toggle_items: {},
          };
  
          Object.values(variant.sections)
            .filter((section) => section.element_id == element.id)
            .forEach(
              (row) =>
                (elementReponse.sections[row.id] = sectionWithChildren(row))
            );
  
          Object.values(variant.fields)
            .filter((field) => field.element_id == element.id)
            .forEach((field) => {
              let fieldResponse = { ...field, field_options: {} };
  
              Object.values(variant.field_options)
                .filter((field_option) => field_option.field_id == field.id)
                .forEach(
                  (field_option) =>
                    (fieldResponse.field_options[field_option.id] = {
                      ...field_option,
                    })
                );
              elementReponse.fields[field.id] = { ...fieldResponse };
            });
  
          Object.values(variant.content_toggle_items)
            .filter((list_item) => list_item.element_id == element.id)
            .forEach((list_item) => {
              elementReponse.content_toggle_items[list_item.id] = {
                ...list_item,
              };
            });
  
          Object.values(variant.content_list_items)
            .filter((list_item) => list_item.element_id == element.id)
            .forEach((list_item) => {
              elementReponse.content_list_items[list_item.id] = { ...list_item };
            });
  
          Object.values(variant.products)
            .filter((product) => product.element_id == element.id)
            .forEach((product) => {
              let productResponse = { ...product, product_variants: {} };
  
              Object.values(variant.product_variants)
                .filter(
                  (product_variant) => product_variant.product_id == product.id
                )
                .forEach(
                  (product_variant) =>
                    (productResponse.product_variants[product_variant.id] = {
                      ...product_variant,
                    })
                );
              elementReponse.products[product.id] = { ...productResponse };
            });
  
          Object.values(variant.product_recommendations)
            .filter(
              (product_recommendation) =>
                product_recommendation.element_id == element.id
            )
            .forEach((product_recommendation) => {
              let productRecommendationResponse = {
                ...product_recommendation,
                products: {},
              };
  
              Object.values(variant.products)
                .filter(
                  (product) =>
                    product.product_recommendation_id == product_recommendation.id
                )
                .forEach((product) => {
                  let productResponse = { ...product, product_variants: {} };
  
                  Object.values(variant.product_variants)
                    .filter(
                      (product_variant) =>
                        product_variant.product_id == product.id
                    )
                    .forEach(
                      (product_variant) =>
                        (productResponse.product_variants[product_variant.id] = {
                          ...product_variant,
                        })
                    );
  
                  productRecommendationResponse.products[product.id] = {
                    ...productResponse,
                  };
                  elementReponse.products[product.id] = { ...productResponse };
                });
  
              elementReponse.product_recommendations[
                product_recommendation.id
              ] = {
                ...productRecommendationResponse,
              };
            });
  
          response.elements[element.id] = elementReponse;
        });
  
      return response;
    }
  }

  function getSectionProps(section) {
    return {
      builder: props.builder,
      device: props.device,
      user: props.user,
      website: props.website,
      cta: props.cta,
      variant: variantWithoutChildren(variant),
      step: step,
      section: sectionWithChildren(section),
      preview: props.preview,
    };
  }

  let Sections;
  if (props.builder == true) {
    const [contextData, builder] = useContext(VariantContextData);
    const { currentHook } = useSections(step);
    Sections = currentHook;

    useEffect(() => {
      Sections.sortableStart();
    });
  }

  return (
    <React.Fragment>
      <div
        id={"step_" + step.id}
        className={classes}
        data-variant-id={variant.id}
        data-progress-bar-count={
          step.options["progress-bar-count"] != "false" ? true : false
        }
        step-id={step.id}
        data-step-id={step.id}
        data-step-position={step.position}
        data-object-type="steps"
        data-object-id={step.id}
        style={styling}
      >
        <div className="cf-step-inner">
          <div
            className="cf-cta-container cf-row cf-outer-center"
            data-variant-id={variant.id}
            data-object-id={step.id}
            style={{
              boxShadow:
                ["overlay", "hook"].includes(cta.cta_type) &&
                variant.options["show-shadow"] !== "false"
                  ? "0 1px 23px 0 rgba(50,57,66,0.09)"
                  : undefined,
            }}
          >
            <div
              className="cf-cta-container-overlay cf-background-overlay"
              data-variant-id={variant.id}
            ></div>
            {sorted_sections.map((section, i) => {
              return (
                <Section
                  key={"section-" + section.id}
                  {...getSectionProps(section)}
                />
              );
            })}
          </div>
          {props.builder == true && (
            <div
              className="section-add-container text-blue-600 dark:text-white"
              onClick={() => {
                dispatchCustomEvent("newObject", {
                  object_type: "Sections", 
                  step_id: step.id
                });
              }}
              style={{
                display: "block",
              }}
            >
              <span
                className="glyphicon glyphicon-plus-sign"
                aria-hidden="true"
              ></span>{" "}
              Add Section
            </div>
          )}
        </div>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (cta, variant, step) => {
    let classes = "cf-step cf-cta-container-background";

    if (step.options && step.options["custom-class"]) {
      classes += " " + step.options["custom-class"];
    }

    if (
      ["hook", "overlay"].includes(cta.cta_type) &&
      variant.options["show-shadow"] !== "false"
    ) {
      classes += " cf-container-shadow";
    }

    classes += variant.options["background-position"]
      ? " cf-background-position-" + variant.options["background-position"]
      : " cf-background-position-top-center";

    classes += variant.options["background-size"]
      ? " cf-background-size-" + variant.options["background-size"]
      : " cf-background-size-cover";

    return classes;
  },

  stylesheet: (builder, device, cta, variant, step) => {
    let background_image;
    if (
      variant.options["background-image"] &&
      variant.options["background-image"] !== "none"
    ) {
      background_image = variant.options["background-image"]
        .replace("url(", "")
        .replace(")", "");
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-cta-container-background[data-variant-id="${variant.id}"] {
  ${background_image ? "background-image: url(" + background_image + ") !important;" : ''}
}

#cta_${cta.id} .cf-cta-container-overlay[data-variant-id="${variant.id}"] {
  ${variant.options["background-color"] ? 'background: ' + variant.options["background-color"] + '!important;' : ''}
  ${_.isNil(variant.options["background-opacity"]) == false ? 'opacity: ' + variant.options["background-opacity"] + '!important;' : ''}
}
`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-cta-container-background[data-variant-id="${variant.id}"] {
  ${_.isNil(variant.options['background-position-mobile']) == false ? 'background-position: ' + variant.options['background-position-mobile'].replace('-', ' ') + ' !important' : ''};
  ${_.isNil(variant.options['background-size-mobile']) == false ? 'background-size: ' + (variant.options['background-size-mobile'] !== 'expand-height' ? variant.options['background-size-mobile'] : 'auto 100%') + ' !important' : ''};
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default Step;
