import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import _, { xor } from "lodash";

const VariantClose = (props) => {
  const cta = props.cta;
  const variant = props.variant;
  const step = props.step;

  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    variant
  );

  let iconThickness = variant.options["close-icon-thickness"]
    ? variant.options["close-icon-thickness"]
    : "thick";

  let iconColor = variant.options["close-icon-color"]
    ? variant.options["close-icon-color"]
    : "#676f84";

  const openSettings = () => {
    dispatchCustomEvent("panelView", {
      view: "theme",
      collapse: "Close Icon",
    });
  };

  return (
    <React.Fragment>
      <div
        className="cf-close"
        data-cta-id={cta.id}
        onClick={openSettings}
      >
        {iconThickness == "thick" && (
          <svg
            fill={iconColor}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
          >
            {" "}
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
          </svg>
        )}
        {iconThickness == "thin" && (
          <svg
            fill={iconColor}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
          </svg>
        )}
      </div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, variant) => {
    let options = { ...variant.options };

    let iconDisplay = "block";

    if (options["close-icon-show"] == "false") {
      iconDisplay = "none";
    }

    let backgroundColor = options["close-icon-background"]
      ? options["close-icon-background"]
      : "#f2f5f9";

    let iconSize = `${
      variant.options["close-icon-size"]
        ? variant.options["close-icon-size"]
        : 12
    }px`;

    let iconSizeMobile = `${
      variant.options["close-icon-size-mobile"]
        ? variant.options["close-icon-size-mobile"]
        : iconSize
    }px`;

    // prettier-ignore
    let desktopStyles = 
`#cta_${cta.id} .cf-close[data-cta-id="${cta.id}"] {
  all: initial !important;
  background: ${backgroundColor} !important;
  display: ${iconDisplay} !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  padding: 5px !important;
  line-height: 0 !important;
  border-radius: 100% !important;
  cursor: pointer !important;
  z-index: ${builder ? '21' : '9'} !important;
}

#cta_${cta.id} .cf-close[data-cta-id="${cta.id}"] svg {
  width: ${iconSize} !important;
  height: ${iconSize} !important;
}
`;

    if (builder !== true) {
      desktopStyles += `
#cta_${cta.id} .cf-close[data-cta-id="${cta.id}"] {
  z-index: 99999 !important;
}`;
    }

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-close[data-cta-id="${cta.id}"] svg {
  width: ${iconSizeMobile} !important;
  height: ${iconSizeMobile} !important;
}
`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default VariantClose;
