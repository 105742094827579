import React from "react";
import PropTypes from "prop-types";

const ElementDivider = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );

  return (
    <React.Fragment>
      <div className={classes}>
        <hr className="cf-divider"></hr>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-divider-outer ";

    let alignmentClass = "cf-outer-center";

    if (options["cf-alignment"]) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        alignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentClass = "cf-outer-right";
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element) => {
    let options = { ...element.options };

    let alignmentMargin;
    if (
      options["divider-orientation"] == "vertical" &&
      options["cf-alignment"]
    ) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentMargin = "margin-left: 0px !important;";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentMargin = "margin-right: 0px !important;";
      }
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-divider-outer {
  ${options["divider-orientation"] !== 'vertical' && options["divider-length"] == 'custom' && options["divider-length-value"] ? 'max-width: ' + options["divider-length-value"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-divider {
  border: initial !important;
  border-${options["divider-orientation"] !== 'vertical' ? 'top' : 'left'}: ${_.isNil(options["divider-border-width"]) == false ? options["divider-border-width"] : 1}px ${options["divider-border-style"] && ['empty', 'none'].includes(options["divider-border-style"]) == false ? options["divider-border-style"] : 'solid'} ${options["divider-border-color"] ? options["divider-border-color"] : '#ccc'} !important;
  opacity: ${['empty', 'none'].includes(options["divider-border-style"]) ? '0' : '1'} !important;
  ${options["divider-orientation"] == 'vertical' ? 'height: ' + (options["divider-length-value"] ? options["divider-length-value"] : 300) + 'px !important;' : ''}
  ${options["divider-orientation"] == 'vertical' ? 'width: ' + (_.isNil(options["divider-border-width"]) == false ? options["divider-border-width"] : 1) + 'px !important;' : ''}
  ${alignmentMargin}
}
`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] {
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementDivider;
