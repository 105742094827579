import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingMessage = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  return (
    <React.Fragment>
      <div
        className="select-all w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
      >
        {props.value ? props.value : ""}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingMessage;
