import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import ReportCount from "../reports/ReportCount";
import ReportChart from "../reports/ReportChart";
import ReportConversionRateChart from "../reports/ReportConversionRateChart";
import ReportFunnelChart from "../reports/ReportFunnelChart";
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";
import Tooltip from "../shared/Tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);
import { ColumnChart } from "react-chartkick";
import "chartkick/chart.js";

const WebsiteConversionReport = (props) => {
  const website = props.website;
  const [metric, setMetric] = useState("conversions");
  const [analysisType, setAnalysisType] = useState("funnel");
  const [timeframeType, setTimeframeType] = useState("This Month");
  const [rangeStartValue, setRangeStartValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_start
  );
  const [rangeEndValue, setRangeEndValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_end
  );
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);
  const [revenueType, setRevenueType] = useState("attributed");

  let group_by = ReportsHelpers.getGroupBy(timeframeType);
  let range_start = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_start;
  let range_end = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_end;

  if (timeframeType == "Date Range") {
    range_start = rangeStartValue;
    range_end = rangeEndValue;
    group_by = ReportsHelpers.getGroupBy(null, range_start, range_end);
  }

  const viewsReport = (
    <div className="col-xs-2 center no-padding-sides">
      <ReportCount
        label="Total Views"
        tooltip="Total count of all views of all campaigns. Viewing a campaign 2 times counts as 2 views. Viewing 2 funnel steps count as 2 views."
        path={`/websites/${props.website.id}/events_reports`}
        payload={{
          report_type: "count",
          report: {
            website_id: props.website.id,
            event_type: "View",
            range_start: range_start,
            range_end: range_end,
            variant_id: null,
            broadcast_id: null,
          },
        }}
      />
    </div>
  );

  const uniqueViewsReport = (
    <div className="col-xs-2 center no-padding-sides">
      <ReportCount
        id={`website-${website.id}-views`}
        label="Unique Viewers"
        tooltip="Number of unique visitors who viewed a campaign"
        path={`/websites/${props.website.id}/events_reports`}
        payload={{
          report_type: "distinct_count",
          report: {
            website_id: props.website.id,
            event_type: "View",
            range_start: range_start,
            range_end: range_end,
            variant_id: null,
            broadcast_id: null,
            field: null,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#website-${website.id}-views`,
            `#website-${website.id}-conversions`,
            `#website-${website.id}-conversion-rate`
          );
          updateConversionRate(
            `#website-${website.id}-views`,
            `#website-${website.id}-contacts`,
            `#website-${website.id}-subscribe-rate`
          );

          if (metric == 'add_to_cart') {
            updateConversionRate(
              `#website-${website.id}-views`,
              `#website-${website.id}-added-to-cart`,
              `#website-${website.id}-add-to-cart-rate`
            );
          }

          if (['add_to_cart', 'orders', 'revenue'].includes(metric)) {
            updateConversionRate(
              `#website-${website.id}-views`,
              `#website-${website.id}-orders`,
              `#website-${website.id}-order-rate`
            );
          }
        }}
      />
    </div>
  );

  const conversionsReport = (
    <div className="col-xs-2 center no-padding-sides">
      <ReportCount
        id={`website-${website.id}-conversions`}
        label="Unique Convs."
        tooltip="Number of unique viewers who clicked a campaign"
        path={`/websites/${props.website.id}/events_reports`}
        payload={{
          report_type: "distinct_count",
          report: {
            website_id: props.website.id,
            event_type: "Complete",
            variant_id: null,
            broadcast_id: null,
            range_start: range_start,
            range_end: range_end,
            field: null,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#website-${website.id}-views`,
            `#website-${website.id}-conversions`,
            `#website-${website.id}-conversion-rate`
          );
        }}
      />
    </div>
  );

  const conversionRateReport = (
    <div className="col-xs-2 center no-padding-sides">
      <div className="row hard-center">
        <div className="row hard-center">
          <span className="field-title">Conv. Rate</span>
          <Tooltip tooltip={"Unique viewers compared to unique conversions"} inline={true} />
        </div>
        <div className="row hard-center">
          <h4
            id={`website-${website.id}-conversion-rate`}
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            0.00%
          </h4>
        </div>
      </div>
    </div>
  );

  const completionsReport = (
    <div className="col-xs-2 center no-padding-sides">
      <ReportCount
        id={`website-${website.id}-completions`}
        label="Unique Comps."
        tooltip="Number of unique viewers who reached completion"
        path={`/websites/${props.website.id}/events_reports`}
        payload={{
          report_type: "distinct_count",
          report: {
            website_id: props.website.id,
            event_type: "Completion",
            variant_id: null,
            broadcast_id: null,
            range_start: range_start,
            range_end: range_end,
            field: null,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#website-${website.id}-views`,
            `#website-${website.id}-completions`,
            `#website-${website.id}-completion-rate`
          );
        }}
      />
    </div>
  );

  const completionRateReport = (
    <div className="col-xs-2 center no-padding-sides">
      <div className="row hard-center">
        <div className="row hard-center">
          <span className="field-title">Comp. Rate</span>
          <Tooltip tooltip={"Unique viewers compared to unique completions"} inline={true} />
        </div>
        <div className="row hard-center">
          <h4
            id={`website-${website.id}-completion-rate`}
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            0.00%
          </h4>
        </div>
      </div>
    </div>
  );

  const ordersReport = (
    <div className="col-xs-2 center no-padding-sides">
      <ReportCount
        id={`website-${website.id}-orders`}
        label="Orders"
        tooltip="Total orders that converted and then purchased within 7 days"
        path={`/websites/${props.website.id}/events_reports`}
        payload={{
          report_type: "count",
          report: {
            website_id: props.website.id,
            event_type: "Order",
            contactable_type: 'Cta',
            variant_id: null,
            step_id: null,
            element_id: null,
            range_start: range_start,
            range_end: range_end,
            field: null,
            broadcast_id: null,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#website-${website.id}-views`,
            `#website-${website.id}-orders`,
            `#website-${website.id}-order-rate`
          );

          updateAOV(
            `#website-${website.id}-orders`,
            `#website-${website.id}-revenue`,
            `#website-${website.id}-aov`
          );
        }}
      />
    </div>
  )

  const ordersRateReport = (
    <div className="col-xs-2 center no-padding-sides">
      <div className="row hard-center">
        <div className="row hard-center">
          <span className="field-title">Purchase Rate</span>
          <Tooltip tooltip={"Percentage of viewers who converted and then purchased within 7 days"} inline={true} />
        </div>
        <div className="row hard-center">
          <h4
            id={`website-${website.id}-order-rate`}
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            0.00%
          </h4>
        </div>
      </div>
    </div>
  )

  const revenueReport = (
    <div className="col-xs-2 center no-padding-sides">
      <ReportCount
        id={`website-${website.id}-revenue`}
        label="Revenue"
        tooltip="Revenue from orders that converted with a campaign and then purchased within 7 days"
        path={`/websites/${props.website.id}/events_reports`}
        payload={{
          report_type: "revenue",
          report: {
            website_id: props.website.id,
            event_type: "Order",
            contactable_type: 'Cta',
            variant_id: null,
            step_id: null,
            element_id: null,
            range_start: range_start,
            range_end: range_end,
            field: null,
            broadcast_id: null
          },
        }}
        callback={() => {
          updateAOV(
            `#website-${website.id}-orders`,
            `#website-${website.id}-revenue`,
            `#website-${website.id}-aov`
          );
        }}
        currency={props.currency}
      />
    </div>
  )

  const aovReport = (
    <div className="col-xs-2 center no-padding-sides">
      <div className="row hard-center">
        <div 
          data-toggle="tooltip"
          data-placement="left"
          title={"Average revenue of those who converted with a campaign and then purchased within 7 days"}
          className="row hard-center">
          <span className="field-title">AOV</span>
          <Tooltip tooltip={"Average revenue of those who converted with a campaign and then purchased within 7 days"} inline={true} />
        </div>
        <div className="row hard-center">
          <h4
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            {props.currency}<span id={`website-${website.id}-aov`}>0.00</span>
          </h4>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    $(rangeStartInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(0, 0, 0, 0),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date($(e.currentTarget).find("input").val()).setUTCHours(0, 0, 0, 0);
        const value = new Date(timestamp).toISOString();
        setRangeStartValue(value);
      });
      
    $(rangeEndInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(23, 59, 59, 999),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date($(e.currentTarget).find("input").val()).setUTCHours(23, 59, 59, 999);
        const value = new Date(timestamp).toISOString();
        setRangeEndValue(value);
      });
  }, [metric, timeframeType, rangeStartValue, rangeEndValue]);

  return (
    <React.Fragment>
      <div className="row hard-center smooth WebsiteConversionReport">
        <div className="row hard-center some-vertical-space">
          <div className="col-xs-3 no-padding-right">
            <h4 className="bold">Performance</h4>
          </div>
          <div className="col-xs-9 no-padding-left">
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-end"
                ref={rangeEndInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="End"
                  value={rangeEndValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeEndValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-start"
                ref={rangeStartInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="Start"
                  value={rangeStartValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeStartValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-xs-3 timeframe-date-selector">
              {false == (metric == "revenue" && revenueType == 'total') && (
                <select
                  className="text-field"
                  style={{ float: "right" }}
                  onChange={(event) => setTimeframeType(event.target.value)}
                  name="timeframe"
                  defaultValue={
                    props.timeframeTypes.filter(
                      (option) => option.selected == true
                    )[0].value
                  }
                >
                  {props.timeframeTypes.map((option) => (
                    <option
                      value={option.value}
                      key={`timeframe-option-${option.value}`}
                    >
                      {option.value}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div
              className="col-xs-3 no-padding-left"
              style={{ float: "right", paddingTop: "10px", width: "170px" }}
            >
              {metric !== "revenue" && (
                <div
                  className="btn-group smooth"
                  style={{ marginLeft: "auto" }}
                  role="group"
                >
                  <button
                    type="button"
                    className={`bg-white !pb-2 text-slate-500 rounded-md border-solid border-[1px] border-white ${
                      analysisType == "funnel" && metric !== "viewers"
                        ? "!border-slate-200"
                        : metric == "viewers"
                        ? "disabled"
                        : ""
                    }`}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={`Funnel report`}
                    onClick={() => {
                      if (metric !== "viewers") {
                        setAnalysisType("funnel");
                      }
                    }}
                    style={{
                      display: "inline-block",
                      padding: "10px 0px",
                      width: "50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M4.84615 9H13.1538M3 5.25H15M7.61538 12.75H10.3846"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    className={`bg-white !pb-2 text-slate-500 rounded-md border-solid border-[1px] border-white ${
                      analysisType == "counts" || metric == "viewers"
                        ? "!border-slate-200"
                        : ""
                    }`}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={`Counts over time`}
                    onClick={() => {
                      setAnalysisType("counts");
                    }}
                    style={{
                      display: "inline-block",
                      padding: "10px 0px",
                      width: "50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M14.4004 14.3996V12.5996M9.00039 14.3996V8.09961M3.60039 14.3996V3.59961"
                        stroke="#7F8493"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    className={`bg-white !pb-2 text-slate-500 rounded-md border-solid border-[1px] border-white  ${
                      analysisType == "performance" && metric !== "viewers"
                        ? "!border-slate-200"
                        : metric == "viewers"
                        ? "disabled"
                        : ""
                    }`}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={`Rates over time`}
                    onClick={() => {
                      if (metric !== "viewers") {
                        setAnalysisType("performance");
                      }
                    }}
                    style={{
                      display: "inline-block",
                      padding: "10px 0px",
                      width: "50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M12.6002 7.1998L9.9002 9.8998L7.6502 7.6498L4.9502 10.3498M14.4002 16.1998C15.3943 16.1998 16.2002 15.3939 16.2002 14.3998V3.5998C16.2002 2.60569 15.3943 1.7998 14.4002 1.7998H3.6002C2.60608 1.7998 1.8002 2.60569 1.8002 3.5998V14.3998C1.8002 15.3939 2.60608 16.1998 3.6002 16.1998H14.4002Z"
                        stroke="#7F8493"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              )}
              {metric == "revenue" && (
                <select
                  className="text-field"
                  style={{ float: "right" }}
                  onChange={(event) => setRevenueType(event.target.value)}
                  value={revenueType}
                >
                  <option value={'attributed'}>Attributed</option>
                  <option value={'total'}>Total</option>
                </select>
              )}
            </div>
          </div>
        </div>
        <div className="row hard-center">
          <ul
            className="nav nav-tabs !flex reports-tabs border-solid border-0 !border-slate-200 border-b-[1px]"
            role="tab"
            style={{
              borderBottom: "1px solid #E9EBf1",
            }}
          >
            <li
              role="presentation"
              className={`flex-1 text-center ${
                metric == "viewers" ? "active" : ""
              }`}
            >
              <a
                className="text-xl semibold metric-tab"
                data-metric="viewers"
                onClick={() => setMetric("viewers")}
              >
                Viewers
              </a>
            </li>
            <li className={`flex-1 text-center ${
                metric == "conversions" ? "active" : ""
              }`}>
              <a
                className="text-xl semibold metric-tab"
                data-metric="conversions"
                onClick={() => setMetric("conversions")}
              >
                Conversions
              </a>
            </li>
            <li className={`flex-1 text-center ${
                metric == "completions" ? "active" : ""
              }`}>
              <a
                className="text-xl semibold metric-tab"
                data-metric="completions"
                onClick={() => setMetric("completions")}
              >
                Completions
              </a>
            </li>
            <li className={`flex-1 text-center ${
                metric == "subscribes" ? "active" : ""
              }`}>
              <a
                className="text-xl semibold metric-tab"
                data-metric="subscribes"
                onClick={() => setMetric("subscribes")}
              >
                Contacts
              </a>
            </li>
            <li className={`flex-1 text-center ${
                metric == "add_to_cart" ? "active" : ""
              }`}>
              <a
                className="text-xl semibold metric-tab"
                data-metric="add_to_cart"
                onClick={() => setMetric("add_to_cart")}
              >
                Add To Cart
              </a>
            </li>
            <li className={`flex-1 text-center ${
                metric == "orders" ? "active" : ""
              }`}>
              <a
                className="text-xl semibold metric-tab"
                data-metric="orders"
                onClick={() => setMetric("orders")}
              >
                Orders
              </a>
            </li>
            <li className={`flex-1 text-center ${
                metric == "revenue" ? "active" : ""
              }`}>
              <a
                className="text-xl semibold metric-tab"
                data-metric="add_to_cart"
                onClick={() => setMetric("revenue")}
              >
                Revenue
              </a>
            </li>
          </ul>
        </div>

        {metric == "viewers" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  See how many people have viewed your campaigns.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                <ReportChart
                  path={`/websites/${props.website.id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    group_by: group_by,
                    report: {
                      website_id: props.website.id,
                      event_type: "View",
                      range_start: range_start,
                      range_end: range_end,
                      variant_id: null,
                      field: null,
                      broadcast_id: null,
                      start_at_first:
                        timeframeType == "All Time" ? true : false,
                    },
                  }}
                  height="200px"
                />
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {viewsReport}
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
            </div>
          </React.Fragment>
        )}
        {metric == "conversions" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  See how many viewers have clicked a button, survey or form in
                  a campaign.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${props.website.id}/events_reports`}
                    payload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Complete",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "View",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Complete",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${props.website.id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "View",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${props.website.id}/events_reports`}
                    conversionPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Complete",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
            </div>
          </React.Fragment>
        )}
        {metric == "subscribes" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  See how many new contacts were created.{" "}
                  <a
                    href="https://help.convertflow.com/article/190-form-elements-how-to-build-forms-and-collect-contact-info"
                    target="_blank"
                  >
                    How to configure →
                  </a>{" "}
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "View",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Complete",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "New Contacts",
                        path: `/websites/${props.website.id}/reports`,
                        payload: {
                          table: "Contact",
                          result_type: "count",
                          range_start: range_start,
                          range_end: range_end,
                          range_type: "created_at",
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${props.website.id}/reports`}
                    payload={{
                      table: "Contact",
                      result_type: "group_by_date",
                      range_start: range_start,
                      range_end: range_end,
                      group_by: `group_by_${group_by}`,
                      range_type: "created_at",
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${props.website.id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "View",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${props.website.id}/reports`}
                    conversionPayload={{
                      table: "Contact",
                      result_type: "group_by_date",
                      range_start: range_start,
                      range_end: range_end,
                      group_by: `group_by_${group_by}`,
                      range_type: "created_at",
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              <div className="col-xs-2 center no-padding-sides">
                <ReportCount
                  id={`website-${website.id}-subscribes`}
                  label="New Contacts"
                  tooltip="New contacts created during the selected timeframe"
                  path={`/websites/${props.website.id}/reports`}
                  payload={{
                    table: "Contact",
                    result_type: "count",
                    range_start: range_start,
                    range_end: range_end,
                    range_type: "created_at",
                  }}
                  callback={() => {
                    updateConversionRate(
                      `#website-${website.id}-views`,
                      `#website-${website.id}-subscribes`,
                      `#website-${website.id}-subscribe-rate`
                    );
                  }}
                />
              </div>
              <div className="col-xs-2 center no-padding-sides">
                <div className="row hard-center">
                  <div className="row hard-center">
                    <span className="field-title">Subscribe Rate</span>
                    <Tooltip tooltip={"Unique viewers compared to new contacts"} inline={true} />
                  </div>
                  <div className="row hard-center">
                    <h4
                      id={`website-${website.id}-subscribe-rate`}
                      className="report-count bold"
                      style={{ margin: "5px 0px 0px" }}
                    >
                      0.00%
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {metric == "completions" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  See how many viewers have been tracked as having completed
                  your campaigns.{" "}
                  <a
                    href="https://help.convertflow.com/article/203-reporting-on-how-your-cta-is-converting#completion"
                    target="_blank"
                  >
                    How to configure →
                  </a>{" "}
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${props.website.id}/events_reports`}
                    payload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Completion",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "View",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Complete",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Completions",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Completion",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${props.website.id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "View",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${props.website.id}/events_reports`}
                    conversionPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Completion",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {completionsReport}
              {completionRateReport}
            </div>
          </React.Fragment>
        )}
        {metric == "add_to_cart" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  See how many viewers have added a product to cart from
                  ConvertFlow campaigns.{" "}
                  <a
                    href="https://help.convertflow.com/article/347-products-elements-how-to-build-product-recommendations"
                    target="_blank"
                  >
                    How to configure →
                  </a>{" "}
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "View",
                            variant_id: null,
                            broadcast_id: null,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Complete",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Added To Cart",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Add To Cart",
                            range_start: range_start,
                            range_end: range_end,
                            variant_id: null,
                            broadcast_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Orders",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Order",
                            contactable_type: 'Cta',
                            variant_id: null,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                            broadcast_id: null,
                          },
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${props.website.id}/events_reports`}
                    payload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Add To Cart",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${props.website.id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "View",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        broadcast_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${props.website.id}/events_reports`}
                    conversionPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Add To Cart",
                        range_start: range_start,
                        range_end: range_end,
                        variant_id: null,
                        field: null,
                        broadcast_id: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              <div className="col-xs-2 center no-padding-sides">
                <ReportCount
                  id={`website-${website.id}-added-to-cart`}
                  label="Added To Cart"
                  tooltip="Unique viewers who added a product to cart from a ConvertFlow campaign"
                  path={`/websites/${props.website.id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    report: {
                      website_id: props.website.id,
                      event_type: "Add To Cart",
                      range_start: range_start,
                      range_end: range_end,
                      variant_id: null,
                      field: null,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    updateConversionRate(
                      `#website-${website.id}-views`,
                      `#website-${website.id}-added-to-cart`,
                      `#website-${website.id}-add-to-cart-rate`
                    );
                  }}
                />
              </div>
              <div className="col-xs-2 center no-padding-sides">
                <div className="row hard-center">
                  <div className="row hard-center">
                    <span className="field-title">Cart Add Rate</span>
                    <Tooltip tooltip={"Unique viewers compared to unique adds to cart"} inline={true} />
                  </div>
                  <div className="row hard-center">
                    <h4
                      id={`website-${website.id}-add-to-cart-rate`}
                      className="report-count bold"
                      style={{ margin: "5px 0px 0px" }}
                    >
                      0.00%
                    </h4>
                  </div>
                </div>
              </div>
              {ordersReport}
              {ordersRateReport}
            </div>
          </React.Fragment>
        )}
        {metric == "orders" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  Orders that converted with a campaign within 7 days before purchasing.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "View",
                            variant_id: null,
                            range_start: range_start,
                            range_end: range_end,
                            step_id: null,
                            field: null,
                            broadcast_id: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Complete",
                            variant_id: null,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                            broadcast_id: null,
                          },
                        },
                      },
                      {
                        label: "Orders",
                        path: `/websites/${props.website.id}/events_reports`,
                        payload: {
                          report_type: "count",
                          report: {
                            website_id: props.website.id,
                            event_type: "Order",
                            contactable_type: 'Cta',
                            variant_id: null,
                            step_id: null,
                            element_id: null,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                            broadcast_id: null
                          },
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${props.website.id}/events_reports`}
                    payload={{
                      report_type: "count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Order",
                        contactable_type: 'Cta',
                        variant_id: null,
                        step_id: null,
                        element_id: null,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        broadcast_id: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${props.website.id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        contactable_type: 'Cta',
                        event_type: "View",
                        variant_id: null,
                        step_id: null,
                        element_id: null,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        broadcast_id: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${props.website.id}/events_reports`}
                    conversionPayload={{
                      report_type: "count",
                      group_by: group_by,
                      report: {
                        website_id: props.website.id,
                        event_type: "Order",
                        contactable_type: 'Cta',
                        variant_id: null,
                        step_id: null,
                        element_id: null,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        broadcast_id: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {ordersReport}
              {ordersRateReport}
              {revenueReport}
              {aovReport}
            </div>
          </React.Fragment>
        )}
        {metric == "revenue" && revenueType == 'total' && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  See how much revenue your store has generated
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                <ColumnChart
                  data={props.shopify_revenue}
                  library={{
                    scales: {
                      y: {
                        ticks: {
                          callback: function (value) {
                            return `${props.currency}${value.toLocaleString()}`;
                          },
                        },
                      },
                    },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return `${
                              props.currency
                            }${context.formattedValue.toLocaleString()}`;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              <div className="col-xs-4 center no-padding-sides">
                <ReportCount
                  id={`website-${website.id}-revenue`}
                  label="Total Revenue This Month"
                  tooltip="How much revenue your Shopify store generated this month"
                  currency={props.currency}
                  count={
                    props.shopify_revenue &&
                    typeof props.shopify_revenue == "object" &&
                    props.shopify_revenue[props.this_month]
                      ? props.shopify_revenue[props.this_month]
                      : 0
                  }
                />
              </div>
              <div className="col-xs-4 center no-padding-sides">
                <ReportCount
                  id={`website-${website.id}-orders`}
                  label="Total Orders This Month"
                  tooltip="How many orders your Shopify store generated this month"
                  count={
                    props.shopify_orders &&
                    typeof props.shopify_orders == "object" &&
                    props.shopify_orders[props.this_month]
                      ? props.shopify_orders[props.this_month]
                      : 0
                  }
                />
              </div>
              <div className="col-xs-4 center no-padding-sides">
                <ReportCount
                  id={`website-${website.id}-aov`}
                  label="AOV This Month"
                  tooltip="The average order value of your Shopify store this month"
                  currency={props.currency}
                  count={
                    props.shopify_revenue &&
                    typeof props.shopify_revenue == "object" &&
                    props.shopify_revenue[props.this_month] &&
                    props.shopify_orders &&
                    typeof props.shopify_orders == "object" &&
                    props.shopify_orders[props.this_month]
                      ? props.shopify_revenue[props.this_month] /
                        props.shopify_orders[props.this_month]
                      : 0
                  }
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {metric == "revenue" && revenueType == 'attributed' && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="light text-xl ellipsis">
                  Revenue attributed to orders that converted with a campaign within 7 days before checkout.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                <ReportChart
                  path={`/websites/${props.website.id}/events_reports`}
                  payload={{
                    report_type: "revenue",
                    group_by: group_by,
                    report: {
                      website_id: props.website.id,
                      event_type: "Order",
                      contactable_type: 'Cta',
                      variant_id: null,
                      step_id: null,
                      element_id: null,
                      range_start: range_start,
                      range_end: range_end,
                      step_id: null,
                      field: null,
                      broadcast_id: null,
                      start_at_first:
                        timeframeType == "All Time" ? true : false,
                    },
                  }}
                  currency={props.currency}
                  height="200px"
                />
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {ordersReport}
              {ordersRateReport}
              {revenueReport}
              {aovReport}
            </div>
          </React.Fragment>
        )}  
      </div>
    </React.Fragment>
  );
};

export default WebsiteConversionReport;
