import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import { VariantContextData } from "../variants/VariantBuilderContext";
import SectionLayer from "../sections/SectionLayer.js";

const ContentToggleLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const element = props.element;
  const content_toggle_item = props.content_toggle_item;
  const row_section = Object.values(
    contextData.objects.variant.sections
  ).filter(
    (section) => section.content_toggle_item_id == content_toggle_item.id
  )[0];

  return (
    <React.Fragment>
      <VariantBuilderPanelLayersItem
        object_type="content_toggle_items"
        object_id={content_toggle_item.id}
        object={element}
        title={`${
          element.element_type == "collapse"
            ? "Collapse"
            : element.element_type == "tabs"
            ? "Tab"
            : element.element_type == "carousel"
            ? "Slide"
            : ""
        } ${content_toggle_item.position}`}
        element={element}
        handleSelect={() => {
          dispatchCustomEvent("selectObject", {
            object_type: "elements",
            object_id: content_toggle_item.element_id,
          });
        }}
        canvasSelector={`.cf-collapse-box[data-collapse-item-id="${content_toggle_item.id}"], .cf-carousel-item[data-toggle-item-id="${content_toggle_item.id}"], .cf-tab-item-cf-section-box[data-tab-id="${content_toggle_item.id}"]`}
      >
        <SectionLayer
          section={row_section}
          {...props}
          element={element}
          title="Row"
        />
      </VariantBuilderPanelLayersItem>
    </React.Fragment>
  );
};

export default ContentToggleLayer;
