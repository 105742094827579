import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import htmlToLabel from "../../utils/htmlToLabel";

const FieldSettingsStepMapping = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  let variant = props.variant;
  let step = props.step;
  let element = props.element;
  let field = props.field;

  let field_options = Object.values(contextData.objects.variant.field_options)
    .filter(
      (field_option) =>
        field_option.field_id == field.id && field_option.toBeDeleted !== true
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  useEffect(() => {});

  return (
    <React.Fragment>
      {field_options.map((field_option) => {
        return (
          <React.Fragment key={`field-option-mapping-${field_option.id}`}>
            <div className="row hard-center" style={{ marginBottom: "10px" }}>
              <div
                className="col-xs-6 no-padding-sides"
                style={{ width: "190px" }}
              >
                <div className="col-xs-12 bg-slate-100 dark:bg-slate-800 text-black dark:text-white text-xl p-3 rounded-md">
                    {field_option.label
                      ? htmlToLabel(field_option.label)
                      : field_option.value}
                </div>
              </div>
              <div
                className="col-xs-1 center no-padding-sides"
                style={{ paddingTop: "5px", width: "50px" }}
              >
                <p className="light smooth semibold">→</p>
              </div>
              <div className="col-xs-5" style={{ paddingLeft: "0px" }}>
                <div
                  className="row"
                  style={{
                    marginLeft: "-18px",
                    marginRight: "-30px",
                    marginTop: "-11px",
                  }}
                >
                  <VariantBuilderSetting
                    object={field_option}
                    setting_name="[step_id]"
                    setting_type="dropdown"
                    object_type="field_options"
                    object_id={field_option.id}
                    value={field_option.step_id ? field_option.step_id : ""}
                    options={[
                      {
                        value: "",
                        text: "Default funnel step",
                      },
                      ...Object.values(variant.steps)
                        .filter(
                          (s) => s.id !== step.id && s.toBeDeleted !== true
                        )
                        .sort((a, b) => {
                          return a.position - b.position;
                        })
                        .map((s) => {
                          return {
                            value: s.id + "",
                            text:
                              "Step " +
                              s.position +
                              (s.options["name"]
                                ? " - " + s.options["name"]
                                : ""),
                          };
                        }),
                    ]}
                    condition={element.options["confirmation-type"] == "step"}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default FieldSettingsStepMapping;
