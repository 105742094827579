import React, { useState, useEffect, useRef } from "react";
import Picker from "emoji-picker-react";

const EmojiSelectorField = (props) => {
  const [chosenEmoji, setChosenEmoji] = useState(
    props.value ? props.value : ""
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPickerLoaded, setIsPickerLoaded] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setIsPopoverOpen(false);

    if (props.onSelect !== null) {
      props.onSelect(emojiObject.emoji);
    }
  };

  const selectedicon = () => {
    return chosenEmoji.emoji || props.selectedIcon || "";
  };

  const useOutsideClick = (callback) => {
    const ref = React.useRef();

    React.useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("click", handleClick, true);

      return () => {
        document.removeEventListener("click", handleClick, true);
      };
    }, [ref]);

    return ref;
  };

  const handleClickOutside = () => {
    setIsPopoverOpen(false);
  };

  const handleFirstLoad = () => {
    setIsPickerLoaded(true);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <React.Fragment>
      {isPickerLoaded ? (
        <div
          ref={ref}
          style={{
            position: "absolute",
            paddingTop: "55px",
            visibility: isPopoverOpen ? "visible" : "hidden",
            zIndex: "99999",
          }}
        >
          <Picker onEmojiClick={onEmojiClick} disableSkinTonePicker={true} />
        </div>
      ) : (
        ""
      )}

      <input
        setting_name={props.setting_name}
        name={props.setting_name || props.fieldName}
        type="text"
        className="w-full text-field p-3 bg-white dark:bg-slate-950 text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
        style={{
          fontSize:
            selectedicon() === "" || props.keepFontSize === true
              ? "12px"
              : "16px",
          cursor: "pointer",
        }}
        readOnly="readonly"
        placeholder="Select"
        value={selectedicon()}
        onClick={() => handleFirstLoad()}
      />
    </React.Fragment>
  );
};

export default EmojiSelectorField;
