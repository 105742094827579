import React, { useContext, useState, useEffect, useRef } from "react";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import { VariantContextData } from "../variants/VariantBuilderContext";
import QuizAnswerSettings from "../field_options/QuizAnswerSettings";
import ActionGroupSettings from "../action_groups/ActionGroupSettings";
import NewActionGroup from "../action_groups/NewActionGroup";
import NewQuizFieldOption from "../field_options/NewQuizFieldOption";
import FieldSettingsSlugMapping from "../fields/FieldSettingsSlugMapping";
import FieldSettingsQuestionMapping from "../fields/FieldSettingsQuestionMapping";
import NewFieldOption from "../field_options/NewFieldOption";
import arePropsEqual from "../../utils/arePropsEqual";
import TabGroup from "../shared/TabGroup";
import VariantBuilderPanelBreadcrumb from "../variants/VariantBuilderPanelBreadcrumb";
import useFieldOptions from "../field_options/useFieldOptions";
import Tooltip from "../shared/Tooltip";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const QuizQuestionSettings = (props) => {
  const { FieldOptions } = useFieldOptions(false);
  const [contextData, builder] = useContext(VariantContextData);
  const [tab, setTab] = useState("Content");

  const field_item = builder.selected().object;
  const element = contextData.objects.variant.elements[field_item.element_id];
  const variant = contextData.objects.variant;
  const section = variant.sections[element.section_id];
  const step = variant.steps[section.step_id];

  const website = contextData.objects.website;
  const nested_field_options = Object.values(
    contextData.objects.variant.field_options
  )
    .filter(
      (field_option) =>
        field_option.field_id == field_item.id &&
        field_option.toBeDeleted !== true
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  let fieldsDropdown = [
    {
      value: "",
      text: "Next Question",
    },
  ];

  const is_sub_field = field_item.field_id !== null;
  const field_item_sub_fields = Object.values(contextData.objects.variant.fields).filter((field) =>
    field.element_id == element.id &&
    field.toBeDeleted !== true &&
    field.field_id == field_item.id
  );

  if (is_sub_field) {
    fieldsDropdown.push({
      value: "next_sub_question",
      text: "Next sub-question",
    });
  }

  Object.values(contextData.objects.variant.fields)
    .filter(
      (field) =>
        field.element_id == element.id &&
        field.toBeDeleted !== true &&
        !field.field_id
    )
    .sort(function (a, b) {
      return a.position - b.position;
    })
    .forEach((field, index) => {
      if (field.id !== field_item.id) {
        fieldsDropdown.push({
          value: field.id,
          text: `Question ${index + 1} ${
            field.label ? `- ${field.label.replace(/(<([^>]+)>)/gi, "")}` : ""
          }`,
        });
      }

      Object.values(contextData.objects.variant.fields)
        .filter(
          (sub_field) =>
            sub_field.element_id == element.id &&
            sub_field.toBeDeleted !== true &&
            sub_field.field_id == field.id
        )
        .sort((a, b) => a.position - b.position)
        .forEach((sub_field, sub_index) => {
          if (sub_field.id !== field_item.id) {
            fieldsDropdown.push({
              value: sub_field.id,
              text: `Question ${index + 1}.${sub_index + 1} ${
                sub_field.label
                  ? `- ${sub_field.label.replace(/(<([^>]+)>)/gi, "")}`
                  : ""
              }`,
            });
          }
        });
    });

  fieldsDropdown.push({
    value: "complete_quiz",
    text: "End Of Quiz",
  });

  const selectSettingsTab = (tab) => {
    props.setActiveTab(tab);

    document
      .querySelectorAll(
        `#cf-slide-out-panel-${field_item.id} .cf-slideout-tabs .panel-tab`
      )
      .forEach((tab) => {
        tab.classList.remove("panel-active");
      });

    document
      .querySelector(
        `#cf-slide-out-panel-${field_item.id} .cf-slideout-tabs > .panel-tab[data-tab="${tab}"]`
      )
      .classList.add("panel-active");
  };

  const nextQuestion = fieldsDropdown.find(
    (field) => field.value == field_item.options["jump-field-id"]
  );

  useEffect(() => {
    FieldOptions.sortableStart(field_item);
  }, [props.object_type, props.object_id]);

  return (
    <React.Fragment>
      <div className="row hard-center">
        <VariantBuilderPanelTitle
          title={"Question"}
          object_type="fields"
          object_id={field_item.id}
          object={field_item}
          tooltip={`Each quiz question has a title, description, image, and selectable answers. Configure jump logic to skip questions based on answers.`}
          tooltipLabel="Learn"
          parent_object={{
            type: "elements",
            id: element.id,
            label: "Back To Questions",
          }}
        />

        <TabGroup
          tabs={["Content", "Logic", "Design"]}
          tab={tab}
          setActiveTab={(tab) => {
            setTab(tab);
          }}
          className="mb-6"
        >
          {tab == "Content" && (
            <React.Fragment>
              <div className="row">
                <FieldSettingsSlugMapping field={field_item} />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[field_type]"
                  label="Type"
                  setting_type="dropdown"
                  options={[
                    { value: "survey", text: "Survey" },
                    { value: "image", text: "Image Survey" },
                    { value: "color", text: "Color Survey" },
                    { value: "emoji", text: "Emoji Survey" },
                    { value: "icon", text: "Icon Survey" },
                    { value: "letter", text: "Letter Survey" },
                    { value: "text", text: "Text Field" },
                    { value: "radio", text: "Single-Select Radio" },
                    { value: "checkbox", text: "Multi-Select Checkbox" },
                    { value: "dropdown", text: "Dropdown Field" },
                    { value: "paragraph", text: "Paragraph Field" },
                    { value: "number", text: "Number Field" },
                    { value: "range", text: "Range Field" },
                    { value: "empty", text: "Show a Message" },
                  ]}
                  object_type="fields"
                  object_id={field_item.id}
                  value={field_item.field_type ? field_item.field_type : ""}
                  condition={element.element_type == "quiz"}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[label]"
                  label="Question"
                  setting_type="editor"
                  object_type="fields"
                  object_id={field_item.id}
                  update_on_input_end={true}
                  value={field_item.label ? field_item.label : ""}
                  condition={element.element_type == "quiz"}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][description]"
                  label="Description"
                  setting_type="editor"
                  update_on_input_end={true}
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["description"]
                      ? field_item.options["description"]
                      : ""
                  }
                  condition={element.element_type == "quiz"}
                  allow_empty={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Image"
                  setting_name="[options][label-image]"
                  setting_type="image"
                  padding="0px"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["label-image"]
                      ? field_item.options["label-image"]
                      : undefined
                  }
                  condition={element.element_type == "quiz"}
                />

                {field_item.options["label-image"] && (
                  <div>
                    <VariantBuilderSetting
                      object={field_item}
                      setting_name="[options][label-image-placement]"
                      label="Image Placement"
                      setting_type="dropdown"
                      options={[
                        { value: "", text: "Theme Default" },
                        { value: "below", text: "After Description" },
                        { value: "left", text: "Left of Question" },
                        { value: "right", text: "Right of Question" },
                      ]}
                      object_type="fields"
                      object_id={field_item.id}
                      value={
                        field_item.options["label-image-placement"]
                          ? field_item.options["label-image-placement"]
                          : undefined
                      }
                      condition={element.element_type == "quiz"}
                      device="desktop"
                    />

                    <VariantBuilderSetting
                      object={field_item}
                      setting_name="[options][label-image-placement-mobile]"
                      label="Image Placement"
                      setting_type="dropdown"
                      options={[
                        { value: "", text: "Theme Default" },
                        { value: "below", text: "Below Description" },
                        { value: "above", text: "Above Description" },
                      ]}
                      object_type="fields"
                      object_id={field_item.id}
                      value={
                        field_item.options["label-image-placement-mobile"]
                          ? field_item.options["label-image-placement-mobile"]
                          : undefined
                      }
                      condition={element.element_type == "quiz"}
                      device="mobile"
                    />

                    <VariantBuilderSetting
                      object={field_item}
                      label="Image Width"
                      setting_name="[options][label-image-width]"
                      setting_type="range"
                      object_type="fields"
                      object_id={field_item.id}
                      value={
                        field_item.options["label-image-width"]
                          ? field_item.options["label-image-width"]
                          : undefined
                      }
                      placeholder={
                        field_item.options["label-image-width"]
                          ? field_item.options["label-image-width"]
                          : "Auto"
                      }
                      min={0}
                      max={750}
                      allow_empty={true}
                      range_step={1}
                      condition={element.element_type == "quiz"}
                      device="desktop"
                    />

                    <VariantBuilderSetting
                      object={field_item}
                      label="Image Width"
                      setting_name="[options][label-image-width-mobile]"
                      setting_type="range"
                      object_type="fields"
                      object_id={field_item.id}
                      value={
                        field_item.options["label-image-width-mobile"]
                          ? field_item.options["label-image-width-mobile"]
                          : undefined
                      }
                      placeholder={
                        field_item.options["label-image-width-mobile"]
                          ? field_item.options["label-image-width-mobile"]
                          : field_item.options["label-image-width"]
                          ? field_item.options["label-image-width"]
                          : "Auto"
                      }
                      min={0}
                      max={750}
                      allow_empty={true}
                      range_step={1}
                      condition={element.element_type == "quiz"}
                      device="mobile"
                    />
                  </div>
                )}

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[value]"
                  label="Value"
                  setting_type="text"
                  object_type="fields"
                  object_id={field_item.id}
                  update_on_input_end={true}
                  value={field_item.value ? field_item.value : ""}
                  condition={
                    element.element_type == "quiz" &&
                    field_item.field_type == "hidden"
                  }
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[placeholder]"
                  label="Placeholder"
                  setting_type="text"
                  object_type="fields"
                  object_id={field_item.id}
                  update_on_input_end={true}
                  value={field_item.placeholder ? field_item.placeholder : ""}
                  condition={
                    element.element_type == "quiz" &&
                    ["text", "paragraph", "dropdown", "number"].includes(
                      field_item.field_type
                    )
                  }
                />

                {[
                  "survey",
                  "image",
                  "color",
                  "emoji",
                  "dropdown",
                  "radio",
                  "checkbox",
                  "icon",
                  "letter",
                ].includes(field_item.field_type) && (
                  <React.Fragment>
                    <div className="col-xs-12 w-full relative mt-5 text-black dark:text-white">
                      <span className="text-xl">Answers:</span>
                    </div>

                    <div
                      className="sortable-container col-xs-12"
                      data-sortable-name="field-options"
                      data-object_type="fields"
                      data-object_id={field_item.id}
                    >
                      {nested_field_options.map((field_option, index) => {
                        return (
                          <QuizAnswerSettings
                            key={`quiz-answer-settings-${field_option.id}`}
                            field_option={field_option}
                            element={element}
                            quizLabelIcon={props.quizLabelIcon}
                            index={index}
                          />
                        );
                      })}
                      <NewQuizFieldOption
                        field={field_item}
                        options_length={nested_field_options.length}
                      />
                    </div>

                    <VariantBuilderSetting
                      object={field_item}
                      label="Multi-Select"
                      setting_name="[multi_select]"
                      setting_type="checkbox"
                      object_type="fields"
                      object_id={field_item.id}
                      value={
                        field_item.multi_select
                          ? field_item.multi_select
                          : false
                      }
                      condition={[
                        "survey",
                        "image",
                        "color",
                        "emoji",
                        "icon",
                        "letter",
                      ].includes(field_item.field_type)}
                    />

                    <VariantBuilderSetting
                      object={field_item}
                      object_id={field_item.id}
                      label="Limit Number of Selections"
                      setting_name="[options][multi-select-limit]"
                      setting_type="range"
                      object_type="fields"
                      value={
                        field_item.options["multi-select-limit"]
                          ? field_item.options["multi-select-limit"]
                          : undefined
                      }
                      placeholder="N/A"
                      min={0}
                      max={nested_field_options.length}
                      range_step={1}
                      allow_empty={true}
                      condition={
                        [
                          "image",
                          "color",
                          "emoji",
                          "icon",
                          "letter",
                          "survey",
                        ].includes(field_item.field_type) &&
                        field_item.multi_select != "false"
                      }
                    />
                  </React.Fragment>
                )}

                <VariantBuilderSetting
                  object={field_item}
                  label="Required"
                  setting_name="[required]"
                  setting_type="checkbox"
                  object_type="fields"
                  object_id={field_item.id}
                  value={field_item.required ? field_item.required : false}
                  condition={field_item.field_type !== "empty"}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Numerical value type"
                  setting_name="[options][range-value-type]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["range-value-type"]
                      ? field_item.options["range-value-type"]
                      : "integer"
                  }
                  condition={
                    (field_item.slug && field_item.field_type == "range") ==
                    true
                  }
                  options={[
                    { value: "integer", text: "Integer" },
                    { value: "percentage", text: "Percentage" },
                    { value: "currency", text: "Currency" },
                  ]}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[currency-message]"
                  setting_type="html"
                  object_type="fields"
                  object_id={field_item.id}
                  condition={
                    (field_item.slug &&
                      field_item.field_type == "range" &&
                      field_item.options["range-value-type"] == "currency") ==
                    true
                  }
                >
                  <div
                    className="row hard-center"
                    style={{ marginTop: "-15px" }}
                  >
                    <p
                      className="light smooth"
                      style={{ margin: "0", fontSize: "15px" }}
                    >
                      <strong>Currency:</strong> {website.currency_character}
                      <a
                        style={{ float: "right" }}
                        href={`/websites/${website.id}/edit`}
                        target="_blank"
                      >
                        Edit →
                      </a>
                    </p>
                  </div>
                </VariantBuilderSetting>

                <VariantBuilderSetting
                  object={field_item}
                  label="Numerical range type"
                  setting_name="[options][range-type]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["range-type"]
                      ? field_item.options["range-type"]
                      : "minimum/maximum"
                  }
                  condition={
                    (field_item.slug && field_item.field_type == "range") ==
                    true
                  }
                  options={[
                    { value: "minimum/maximum", text: "Minimum/Maximum" },
                    { value: "increments", text: "Increments" },
                  ]}
                />

                {field_item.field_type == "range" &&
                  field_item.options["range-type"] == "increments" && (
                    <React.Fragment>
                      <div
                        className="col-xs-12 no-padding-sides sortable-container mt-5"
                        data-sortable-name="field-options"
                        data-object_type="fields"
                        data-object_id={field_item.id}
                      >
                        <div className="col-xs-12">
                          <p className="field-title">Increments</p>
                        </div>
                        <div className="col-xs-12">
                        {nested_field_options
                          .filter((option) => isNumeric(option.value))
                          .map((field_option, index) => {
                            return (
                              <React.Fragment
                                key={"field-option-" + field_option.id}
                              >
                                <VariantBuilderSetting
                                  object={field_option}
                                  setting_name="[modal_trigger]"
                                  setting_type="item_v2"
                                  object_type="field_options"
                                  object_id={field_option.id}
                                  title={field_option.value ? `${field_option.value}` : `Option ${index + 1}`}
                                  style={{ margin: "0px" }}
                                  position={field_option.position}
                                  label_prepend={(index + 10)
                                    .toString(36)
                                    .toUpperCase()}
                                  hide_duplicate={true}
                                  draggable={'false'}
                                  {...props}
                                >
                                  <VariantBuilderSetting
                                    object={field_option}
                                    setting_name="[value]"
                                    label="Data Value"
                                    setting_type="number"
                                    placeholder={1}
                                    update_on_input_end={true}
                                    object_type="field_options"
                                    object_id={field_option.id}
                                    value={
                                      field_option.value
                                        ? field_option.value
                                        : ""
                                    }
                                    condition={element.element_type == "quiz"}
                                  />
                                </VariantBuilderSetting>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                      <NewFieldOption field={field_item} default_value={nested_field_options.filter((option) => isNumeric(option.value)).length + 1} />
                    </React.Fragment>
                  )}

                <VariantBuilderSetting
                  object={field_item}
                  label="Limit selection to increments"
                  setting_name="[options][limit-to-increments]"
                  setting_type="checkbox"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["limit-to-increments"]
                      ? field_item.options["limit-to-increments"]
                      : "true"
                  }
                  condition={
                    (field_item.slug &&
                      field_item.field_type == "range" &&
                      field_item.options["range-type"] == "increments") == true
                  }
                  tooltip="Toggle this off to allow for inputting numbers between the pre-defined increments"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Increment"
                  setting_name="[options][increment]"
                  setting_type="number"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["increment"]
                      ? field_item.options["increment"]
                      : "1"
                  }
                  condition={
                    (field_item.slug &&
                      field_item.field_type == "range" &&
                      field_item.options["range-type"] !== "increments") == true
                  }
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Minimum value"
                  setting_name="[options][minimum]"
                  setting_type="number"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["minimum"]
                      ? field_item.options["minimum"]
                      : "0"
                  }
                  condition={
                    (field_item.slug &&
                      ["range", "number"].includes(field_item.field_type) &&
                      (field_item.field_type == "number" ||
                        field_item.options["range-type"] !== "increments")) ==
                    true
                  }
                  col="6"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Maximum value"
                  setting_name="[options][maximum]"
                  setting_type="number"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["maximum"]
                      ? field_item.options["maximum"]
                      : "1000"
                  }
                  condition={
                    (field_item.slug &&
                      ["range", "number"].includes(field_item.field_type) &&
                      (field_item.field_type == "number" ||
                        field_item.options["range-type"] !== "increments")) ==
                    true
                  }
                  col="6"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Display minimum/maximum labels"
                  setting_name="[options][range-labels]"
                  setting_type="checkbox"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["range-labels"]
                      ? field_item.options["range-labels"]
                      : "false"
                  }
                  condition={
                    (field_item.slug &&
                      field_item.field_type == "range" &&
                      field_item.options["range-type"] !== "increments") == true
                  }
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Minimum text"
                  setting_name="[options][range-label-minimum]"
                  setting_type="text"
                  object_type="fields"
                  object_id={field_item.id}
                  placeholder={
                    field_item.options["minimum"]
                      ? field_item.options["minimum"]
                      : "0"
                  }
                  value={
                    field_item.options["range-label-minimum"]
                      ? field_item.options["range-label-minimum"]
                      : undefined
                  }
                  condition={
                    (field_item.slug &&
                      field_item.field_type == "range" &&
                      field_item.options["range-type"] !== "increments" &&
                      field_item.options["range-labels"] == "true") == true
                  }
                  col="6"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Maximum text"
                  setting_name="[options][range-label-maximum]"
                  setting_type="text"
                  object_type="fields"
                  object_id={field_item.id}
                  placeholder={
                    field_item.options["maximum"]
                      ? field_item.options["maximum"]
                      : "1000"
                  }
                  value={
                    field_item.options["range-label-maximum"]
                      ? field_item.options["range-label-maximum"]
                      : undefined
                  }
                  condition={
                    (field_item.slug &&
                      field_item.field_type == "range" &&
                      field_item.options["range-type"] !== "increments" &&
                      field_item.options["range-labels"] == "true") == true
                  }
                  col="6"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Display minimum/maximum handles"
                  setting_name="[options][multiple-handles]"
                  setting_type="checkbox"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["multiple-handles"]
                      ? field_item.options["multiple-handles"]
                      : "false"
                  }
                  condition={
                    (field_item.slug && field_item.field_type == "range") ==
                    true
                  }
                  tooltip="This will allow the visitor to input the range minimum and range maximum"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Lines for writing"
                  setting_name="[rows]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  value={field_item.rows ? field_item.rows : "4"}
                  condition={
                    (field_item.slug && field_item.field_type == "paragraph") ==
                    true
                  }
                  options={[
                    { value: "1", text: "1 lines" },
                    { value: "2", text: "2 lines" },
                    { value: "3", text: "3 lines" },
                    { value: "4", text: "4 lines" },
                    { value: "5", text: "5 lines" },
                    { value: "6", text: "6 lines" },
                    { value: "7", text: "7 lines" },
                    { value: "8", text: "8 lines" },
                    { value: "9", text: "9 lines" },
                    { value: "10", text: "10 lines" },
                  ]}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][show-loading-spinner]"
                  setting_type="checkbox"
                  label="Show Loading"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["show-loading-spinner"]
                      ? field_item.options["show-loading-spinner"]
                      : "false"
                  }
                  condition={field_item.field_type == "empty"}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Loading Style"
                  setting_name="[options][loading-style]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["loading-style"]
                      ? field_item.options["loading-style"]
                      : undefined
                  }
                  condition={
                    field_item.field_type == "empty" &&
                    field_item.options["show-loading-spinner"] == "true"
                  }
                  options={[
                    { value: "", text: "Spinner" },
                    { value: "bar", text: "Bar" },
                  ]}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][loading-spacing]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Loader Spacing"}
                  value={
                    field_item.options["loading-spacing"]
                      ? field_item.options["loading-spacing"]
                      : undefined
                  }
                  min={0}
                  max={1300}
                  range_step={"1"}
                  placeholder={"30"}
                  device="desktop"
                  allow_empty={true}
                  condition={
                    field_item.field_type == "empty" &&
                    field_item.options["show-loading-spinner"] == "true"
                  }
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][loading-spacing-mobile]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Loader Spacing"}
                  value={
                    field_item.options["loading-spacing-mobile"]
                      ? field_item.options["loading-spacing-mobile"]
                      : undefined
                  }
                  min={0}
                  max={1300}
                  range_step={"1"}
                  placeholder={"30"}
                  device="mobile"
                  allow_empty={true}
                  condition={
                    field_item.field_type == "empty" &&
                    field_item.options["show-loading-spinner"] == "true"
                  }
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][auto-continue-delay]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Auto Continue After ( seconds )"}
                  value={
                    field_item.options["auto-continue-delay"]
                      ? field_item.options["auto-continue-delay"]
                      : ""
                  }
                  condition={field_item.field_type == "empty"}
                  min={1}
                  max={60}
                  range_step={"1"}
                  placeholder={"5"}
                  allow_empty={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Prefilled Value"
                  setting_name="[value]"
                  setting_type="text"
                  object_type="fields"
                  object_id={field_item.id}
                  value={field_item.value ? field_item.value : ""}
                  condition={
                    field_item.slug &&
                    field_item.field_type &&
                    ["text", "paragraph", "number"].includes(
                      field_item.field_type
                    )
                  }
                  placeholder="Value to prefill field with"
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Prefill with URL parameter data"
                  setting_name="[url_params]"
                  setting_type="text"
                  object_type="fields"
                  object_id={field_item.id}
                  value={field_item.url_params ? field_item.url_params : ""}
                  condition={
                    field_item.slug &&
                    field_item.field_type &&
                    ["text", "paragraph", "number"].includes(
                      field_item.field_type
                    )
                  }
                  placeholder="Comma separated parameter names"
                  tooltip="Map data from URL parameters to prefill this field. Field will always map data from parameter named as custom field data name"
                />
              </div>
            </React.Fragment>
          )}

          {tab == "Design" && (
            <React.Fragment>
              <VariantBuilderPanelCollapse name={`Question`} open={false}>
                <div className="col-xs-12">
                  <div className="w-full mb-3 relative text-lg text-black dark:text-white">
                    Style the question content, progress bar and next/back buttons for all of your quiz's questions in the
                    quiz element's{" "}
                    <span
                      onClick={() => {
                        dispatchCustomEvent("selectObject", {
                          object_type: "elements",
                          object_id: element.id,
                          tab: "Design",
                        });
                      }}
                      className="text-blue-600 dark:text-blue-200 cursor-pointer"
                    >
                      design settings →
                    </span>
                  </div>
                </div>
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse name={`Answers`} open={true}>
                <VariantBuilderSetting
                  object={field_item}
                  label="Loading Border Color"
                  setting_name="[options][loading-border-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["loading-border-color"]
                      ? field_item.options["loading-border-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["loading-border-color"]
                      ? element.options["loading-border-color"]
                      : "#000"
                  }
                  condition={field_item.field_type == "empty"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Loading Background Color"
                  setting_name="[options][loading-background-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["loading-background-color"]
                      ? field_item.options["loading-background-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["loading-background-color"] || "#8d8d8c"
                  }
                  condition={
                    field_item.field_type == "empty" &&
                    field_item.options["loading-style"] == "bar"
                  }
                  show_reset={true}
                  gradient={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-content-placement]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Content Placement"}
                  value={
                    field_item.options["quiz-content-placement"]
                      ? field_item.options["quiz-content-placement"]
                      : undefined
                  }
                  options={[
                    { value: "", text: "Theme Default" },
                    { value: "above", text: "Above Answers" },
                    { value: "below", text: "Below Answers" },
                    { value: "left", text: "Left of Answers" },
                    { value: "right", text: "Right of Answers" },
                  ]}
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-content-placement-mobile]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Content Placement"}
                  value={
                    field_item.options["quiz-content-placement-mobile"]
                      ? field_item.options["quiz-content-placement-mobile"]
                      : undefined
                  }
                  options={[
                    { value: "", text: "Theme Default" },
                    { value: "above", text: "Above Answers" },
                    { value: "below", text: "Below Answers" },
                  ]}
                  device="mobile"
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-content-width]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label="Content Max Width"
                  value={
                    field_item.options["quiz-content-width"]
                      ? field_item.options["quiz-content-width"]
                      : undefined
                  }
                  min={0}
                  max={2560}
                  range_step={"1"}
                  device="desktop"
                  placeholder={
                    element.options["quiz-content-width"]
                      ? element.options["quiz-content-width"]
                      : "100%"
                  }
                  allow_empty={true}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-content-width-mobile]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label="Content Max Width"
                  value={
                    field_item.options["quiz-content-width-mobile"]
                      ? field_item.options["quiz-content-width-mobile"]
                      : undefined
                  }
                  min={0}
                  max={2560}
                  range_step={"1"}
                  device="mobile"
                  placeholder={
                    element.options["quiz-content-width-mobile"]
                      ? element.options["quiz-content-width-mobile"]
                      : "100%"
                  }
                  allow_empty={true}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-options-layout]"
                  setting_type="layout"
                  object_type="fields"
                  label={"Options Layout"}
                  value={
                    field_item.options["quiz-options-layout"]
                      ? field_item.options["quiz-options-layout"]
                      : undefined
                  }
                  valueMappings={{
                    responsive: "responsive",
                    stacked: "stacked",
                  }}
                  device="desktop"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type) && !["radio", "checkbox"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-options-layout-mobile]"
                  setting_type="layout"
                  object_type="fields"
                  label={"Options Layout"}
                  value={
                    field_item.options["quiz-options-layout-mobile"]
                      ? field_item.options["quiz-options-layout-mobile"]
                      : undefined
                  }
                  valueMappings={{
                    responsive: "responsive",
                    stacked: "stacked",
                  }}
                  device="mobile"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type) && !["radio", "checkbox"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-options-vertical-spacing]"
                  setting_type="range"
                  object_type="fields"
                  label={"Vertical Spacing"}
                  value={
                    field_item.options["quiz-options-vertical-spacing"]
                      ? field_item.options["quiz-options-vertical-spacing"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-options-vertical-spacing"]
                      ? element.options["quiz-options-vertical-spacing"]
                      : variant.options["quiz-options-vertical-spacing"]
                        ? variant.options["quiz-options-vertical-spacing"]
                        : 10
                  }
                  allow_empty={true}
                  min={0}
                  max={200}
                  range_step={"1"}
                  device="desktop"
                  condition={field_item.field_type !== "empty"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-options-vertical-spacing-mobile]"
                  setting_type="range"
                  object_type="fields"
                  label={"Vertical Spacing"}
                  value={
                    field_item.options["quiz-options-vertical-spacing-mobile"]
                      ? field_item.options[
                          "quiz-options-vertical-spacing-mobile"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-options-vertical-spacing-mobile"]
                      ? element.options["quiz-options-vertical-spacing-mobile"]
                      : variant.options["quiz-options-vertical-spacing-mobile"] ||
                        field_item.options["quiz-options-vertical-spacing"] ||
                        variant.options["quiz-options-vertical-spacing"] ||
                        10
                  }
                  allow_empty={true}
                  min={0}
                  max={200}
                  range_step={"1"}
                  device="mobile"
                  condition={field_item.field_type !== "empty"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-options-horizontal-spacing]"
                  setting_type="range"
                  object_type="fields"
                  label={"Horizontal Spacing"}
                  value={
                    field_item.options["quiz-options-horizontal-spacing"]
                      ? field_item.options["quiz-options-horizontal-spacing"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-options-horizontal-spacing"]
                      ? element.options["quiz-options-horizontal-spacing"]
                      : variant.options["quiz-options-horizontal-spacing"]
                        ? variant.options["quiz-options-horizontal-spacing"]
                        : 10
                  }
                  allow_empty={true}
                  min={0}
                  max={200}
                  range_step={"1"}
                  device="desktop"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-options-horizontal-spacing-mobile]"
                  setting_type="range"
                  object_type="fields"
                  label={"Horizontal Spacing"}
                  value={
                    field_item.options["quiz-options-horizontal-spacing-mobile"]
                      ? field_item.options[
                          "quiz-options-horizontal-spacing-mobile"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-options-horizontal-spacing-mobile"]
                      ? element.options["quiz-options-horizontal-spacing-mobile"]
                      : variant.options["quiz-options-horizontal-spacing-mobile"] ||
                        field_item.options["quiz-options-horizontal-spacing"] ||
                        variant.options["quiz-options-horizontal-spacing"] ||
                        10
                  }
                  allow_empty={true}
                  min={0}
                  max={200}
                  range_step={"1"}
                  device="mobile"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-image-placement]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Options Image Placement"}
                  value={
                    field_item.options["quiz-image-placement"]
                      ? field_item.options["quiz-image-placement"]
                      : undefined
                  }
                  options={[
                    { value: "", text: "Theme Default" },
                    { value: "centered", text: "Centered" },
                    { value: "left", text: "Left" },
                  ]}
                  device="desktop"
                  condition={["image"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-image-placement-mobile]"
                  setting_type="dropdown"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Options Image Placement"}
                  value={
                    field_item.options["quiz-image-placement-mobile"]
                      ? field_item.options["quiz-image-placement-mobile"]
                      : undefined
                  }
                  options={[
                    { value: "", text: "Theme Default" },
                    { value: "centered", text: "Centered" },
                    { value: "left", text: "Left" },
                  ]}
                  device="mobile"
                  condition={["image"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Option Width"
                  setting_name="[options][quiz-option-width-style]"
                  setting_type="box_width"
                  show_reset={true}
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-option-width-style"]
                      ? field_item.options["quiz-option-width-style"]
                      : undefined
                  }
                  device="desktop"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-option-width-value]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label="Option Custom Width"
                  value={
                    field_item.options["quiz-option-width-value"]
                      ? field_item.options["quiz-option-width-value"]
                      : undefined
                  }
                  condition={
                    [
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-option-width-style"] == "custom"
                  }
                  min={0}
                  max={2560}
                  range_step={"1"}
                  device="desktop"
                  placeholder={
                    element.options["quiz-option-width-value"]
                      ? element.options["quiz-option-width-value"]
                      : "100%"
                  }
                  allow_empty={true}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Option Custom Width"
                  setting_name="[options][quiz-option-width-style-mobile]"
                  setting_type="box_width"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-option-width-style-mobile"]
                      ? field_item.options["quiz-option-width-style-mobile"]
                      : undefined
                  }
                  device="mobile"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-option-width-value-mobile]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Option Width"}
                  value={
                    field_item.options["quiz-option-width-value-mobile"]
                      ? field_item.options["quiz-option-width-value-mobile"]
                      : undefined
                  }
                  condition={
                    [
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-option-width-style-mobile"] ==
                      "custom"
                  }
                  min={0}
                  max={280}
                  range_step={"1"}
                  allow_empty={true}
                  placeholder={
                    element.options["quiz-option-width-value-mobile"]
                      ? element.options["quiz-option-width-value-mobile"]
                      : "100%"
                  }
                  device="mobile"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-option-image-width]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Option Image Width"}
                  value={
                    field_item.options["quiz-option-image-width"]
                      ? field_item.options["quiz-option-image-width"]
                      : undefined
                  }
                  min={10}
                  max={1300}
                  range_step={"1"}
                  placeholder={
                    element.options["quiz-option-image-width"]
                      ? element.options["quiz-option-image-width"]
                      : "110"
                  }
                  device="desktop"
                  allow_empty={true}
                  condition={["image"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-option-image-width-mobile]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Option Image Width"}
                  value={
                    field_item.options["quiz-option-image-width-mobile"]
                      ? field_item.options["quiz-option-image-width-mobile"]
                      : undefined
                  }
                  min={10}
                  max={1300}
                  device="mobile"
                  range_step={"1"}
                  placeholder={
                    element.options["quiz-option-image-width-mobile"]
                      ? element.options["quiz-option-image-width-mobile"]
                      : field_item.options["quiz-option-image-width"] || 110
                  }
                  allow_empty={true}
                  condition={["image"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-option-image-height]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Option Image Height"}
                  value={
                    field_item.options["quiz-option-image-height"]
                      ? field_item.options["quiz-option-image-height"]
                      : undefined
                  }
                  min={0}
                  max={1300}
                  range_step={"1"}
                  placeholder={
                    element.options["quiz-option-image-height"]
                      ? element.options["quiz-option-image-height"]
                      : "100%"
                  }
                  device="desktop"
                  allow_empty={true}
                  condition={["image"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-option-image-height-mobile]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Option Image Height"}
                  value={
                    field_item.options["quiz-option-image-height-mobile"]
                      ? field_item.options["quiz-option-image-height-mobile"]
                      : undefined
                  }
                  min={0}
                  max={1300}
                  device="mobile"
                  range_step={"1"}
                  placeholder={
                    element.options["quiz-option-image-height-mobile"]
                      ? element.options["quiz-option-image-height-mobile"]
                      : field_item.options["quiz-option-image-height"] || 100
                  }
                  allow_empty={true}
                  condition={["image"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Option Text Alignment"
                  setting_name="[options][quiz-option-text-alignment]"
                  setting_type="alignment"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-option-text-alignment"]
                      ? field_item.options["quiz-option-text-alignment"]
                      : null
                  }
                  valueMappings={{
                    left: "left",
                    center: "center",
                    right: "right",
                  }}
                  device="desktop"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Option Text Alignment"
                  setting_name="[options][quiz-option-text-alignment-mobile]"
                  setting_type="alignment"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-option-text-alignment-mobile"]
                      ? field_item.options["quiz-option-text-alignment-mobile"]
                      : null
                  }
                  valueMappings={{
                    left: "left",
                    center: "center",
                    right: "right",
                  }}
                  device="mobile"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Font family"
                  setting_name="[options][quiz-option-font-family]"
                  setting_type="select"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-font-family"]
                      ? field_item.options["quiz-option-font-family"]
                      : undefined
                  }
                  option_groups={["System fonts", "Google fonts"]}
                  options={[
                    {
                      value: "",
                      text: "Theme font",
                      group: "System fonts",
                    },
                    ...window.builder_fonts_dropdown,
                  ]}
                  callback={(new_value, old_value) => {
                    ctas.builder.fonts.load_font_family(new_value);
                  }}
                  col="8"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-option-font-weight]"
                  setting_type="font_weight"
                  show_reset={true}
                  label="Weight"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-font-weight"]
                      ? field_item.options["quiz-option-font-weight"]
                      : undefined
                  }
                  col="4"
                  classNames="no-padding-left"
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Font size"
                  setting_name="[options][quiz-option-font-size]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-font-size"]
                      ? field_item.options["quiz-option-font-size"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-font-size"]
                      ? element.options["quiz-option-font-size"]
                      : variant.options["quiz-option-font-size"] || 32
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="desktop"
                  allow_empty={true}
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Font size"
                  setting_name="[options][quiz-option-font-size-mobile]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-font-size-mobile"]
                      ? field_item.options["quiz-option-font-size-mobile"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-font-size-mobile"]
                      ? element.options["quiz-option-font-size-mobile"]
                      : variant.options["quiz-option-font-size-mobile"] || 14
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="mobile"
                  allow_empty={true}
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Font line height"
                  setting_name="[options][quiz-option-font-line-height]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-font-line-height"]
                      ? field_item.options["quiz-option-font-line-height"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-font-line-height"]
                      ? element.options["quiz-option-font-line-height"]
                      : variant.options["quiz-option-font-line-height"] || 50
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="desktop"
                  allow_empty={true}
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Font line height"
                  setting_name="[options][quiz-option-font-line-height-mobile]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-font-line-height-mobile"]
                      ? field_item.options[
                          "quiz-option-font-line-height-mobile"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-font-line-height-mobile"]
                      ? element.options["quiz-option-font-line-height-mobile"]
                      : variant.options["quiz-option-font-line-height-mobile"] || 25
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="mobile"
                  allow_empty={true}
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <div className="row hard-center">
                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Font color"
                    setting_name="[options][quiz-option-font-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-option-font-color"]
                        ? field_item.options["quiz-option-font-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-option-font-color"]
                        ? element.options["quiz-option-font-color"]
                        : variant.options["quiz-option-font-color"] || "#000"
                    }
                    condition={[
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                      "radio",
                      "checkbox",
                    ].includes(field_item.field_type)}
                    col="6"
                    classNames="some-padding-right"
                    show_reset={true}
                  />

                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Hover"
                    setting_name="[options][quiz-hover-option-font-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-hover-option-font-color"]
                        ? field_item.options["quiz-hover-option-font-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-hover-option-font-color"]
                        ? element.options["quiz-hover-option-font-color"]
                        : variant.options["quiz-hover-option-font-color"] ||
                          field_item.options["quiz-option-font-color"] ||
                          "#000"
                    }
                    condition={[
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                      "radio",
                      "checkbox",
                    ].includes(field_item.field_type)}
                    col="6"
                    classNames="some-padding-left"
                    show_reset={true}
                    offset_left={true}
                  />
                </div>

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-visual-placement]"
                  setting_type="alignment"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Options Visual Placement"}
                  value={
                    field_item.options["quiz-visual-placement"]
                      ? field_item.options["quiz-visual-placement"]
                      : undefined
                  }
                  valueMappings={{
                    left: 'left',
                    center: 'centered',
                  }}
                  device="desktop"
                  condition={["color", "emoji", "icon", "letter"].includes(
                    field_item.field_type
                  )}
                  show_reset={true}
                />
                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-visual-placement-mobile]"
                  setting_type="alignment"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Options Visual Placement"}
                  value={
                    field_item.options["quiz-visual-placement-mobile"]
                      ? field_item.options["quiz-visual-placement-mobile"]
                      : undefined
                  }
                  valueMappings={{
                    left: 'left',
                    center: 'centered',
                  }}
                  device="mobile"
                  condition={["color", "emoji", "icon", "letter"].includes(
                    field_item.field_type
                  )}
                  show_reset={true}
                />

                <div className="row hard-center">
                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Icon Visual"
                    setting_name="[options][quiz-option-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-option-color"]
                        ? field_item.options["quiz-option-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-option-color"]
                        ? element.options["quiz-option-color"]
                        : variant.options["quiz-option-color"] || "#000"
                    }
                    condition={["emoji", "icon", "letter"].includes(
                      field_item.field_type
                    )}
                    col="6"
                    classNames="some-padding-right"
                    show_reset={true}
                  />

                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Hover"
                    setting_name="[options][quiz-hover-option-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-hover-option-color"]
                        ? field_item.options["quiz-hover-option-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-hover-option-color"]
                        ? element.options["quiz-hover-option-color"]
                        : field_item.options["quiz-option-color"] ||
                          variant.options["quiz-hover-option-color"] ||
                          variant.options["quiz-option-color"] ||
                          "#000"
                    }
                    condition={["emoji", "icon", "letter"].includes(
                      field_item.field_type
                    )}
                    col="6"
                    classNames="some-padding-left"
                    show_reset={true}
                    offset_left={true}
                  />
                </div>

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Icon Visual Size"
                  setting_name="[options][quiz-option-visual-font-size]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-visual-font-size"]
                      ? field_item.options["quiz-option-visual-font-size"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-visual-font-size"]
                      ? element.options["quiz-option-visual-font-size"]
                      : variant.options["quiz-option-visual-font-size"] || 16
                  }
                  min={0}
                  max={500}
                  range_step={1}
                  device="desktop"
                  allow_empty={true}
                  condition={["emoji", "icon", "letter"].includes(
                    field_item.field_type
                  )}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Icon Visual Size"
                  setting_name="[options][quiz-option-visual-font-size-mobile]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-visual-font-size-mobile"]
                      ? field_item.options[
                          "quiz-option-visual-font-size-mobile"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-visual-font-size-mobile"]
                      ? element.options["quiz-option-visual-font-size-mobile"]
                      : variant.options["quiz-option-visual-font-size-mobile"] ||
                        field_item.options["quiz-option-visual-font-size"] ||
                        variant.options["quiz-option-visual-font-size"] ||
                        16
                  }
                  min={0}
                  max={500}
                  range_step={1}
                  device="mobile"
                  allow_empty={true}
                  condition={["emoji", "icon", "letter"].includes(
                    field_item.field_type
                  )}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Visual Spacing"
                  setting_name="[options][quiz-option-visual-spacing]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-visual-spacing"]
                      ? field_item.options["quiz-option-visual-spacing"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-visual-spacing"]
                      ? element.options["quiz-option-visual-spacing"]
                      : variant.options["quiz-option-visual-spacing"] || 15
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="desktop"
                  allow_empty={true}
                  condition={["emoji", "icon", "letter", "color"].includes(
                    field_item.field_type
                  )}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Visual Spacing"
                  setting_name="[options][quiz-option-visual-spacing-mobile]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-visual-spacing-mobile"]
                      ? field_item.options["quiz-option-visual-spacing-mobile"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-visual-spacing-mobile"]
                      ? element.options["quiz-option-visual-spacing-mobile"]
                      : variant.options["quiz-option-visual-spacing-mobile"] || 15
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="mobile"
                  allow_empty={true}
                  condition={["emoji", "icon", "letter", "color"].includes(
                    field_item.field_type
                  )}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Color Visual Size"
                  setting_name="[options][quiz-option-color-size]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-color-size"]
                      ? field_item.options["quiz-option-color-size"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-color-size"]
                      ? element.options["quiz-option-color-size"]
                      : variant.options["quiz-option-color-size"] || 50
                  }
                  min={0}
                  max={200}
                  range_step={1}
                  device="desktop"
                  allow_empty={true}
                  condition={["color"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Color Visual Size"
                  setting_name="[options][quiz-option-color-size-mobile]"
                  setting_type="range"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-color-size-mobile"]
                      ? field_item.options["quiz-option-color-size-mobile"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-color-size-mobile"]
                      ? element.options["quiz-option-color-size-mobile"]
                      : variant.options["quiz-option-color-size-mobile"] || 50
                  }
                  min={0}
                  max={200}
                  range_step={1}
                  device="mobile"
                  allow_empty={true}
                  condition={["color"].includes(field_item.field_type)}
                  show_reset={true}
                />

                <div className="row hard-center">
                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Background"
                    setting_name="[options][quiz-option-background-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-option-background-color"]
                        ? field_item.options["quiz-option-background-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-option-background-color"]
                        ? element.options["quiz-option-background-color"]
                        : variant.options["quiz-option-background-color"] || "#fff"
                    }
                    condition={[
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type)}
                    col="6"
                    classNames="some-padding-right"
                    show_reset={true}
                    gradient={true}
                  />

                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Hover"
                    setting_name="[options][quiz-hover-option-background-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-hover-option-background-color"]
                        ? field_item.options[
                            "quiz-hover-option-background-color"
                          ]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-hover-option-background-color"]
                        ? element.options["quiz-hover-option-background-color"]
                        : variant.options["quiz-hover-option-background-color"] ||
                          field_item.options["quiz-option-background-color"]
                    }
                    condition={[
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type)}
                    col="6"
                    classNames="some-padding-left"
                    show_reset={true}
                    gradient={true}
                    offset_left={true}
                  />
                </div>

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Border Placement"
                  setting_name="[options][quiz-option-border-placement]"
                  setting_type="dropdown"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-border-placement"]
                      ? field_item.options["quiz-option-border-placement"]
                      : undefined
                  }
                  tooltip="Applicable to emoji/icon/color/letter field types"
                  options={[
                    { value: "", text: "Theme Default" },
                    { value: "on_option", text: "On Option" },
                    { value: "on_visual", text: "On Visual" },
                  ]}
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "survey",
                    "letter",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Border Style"
                  setting_name="[options][quiz-option-border-style]"
                  setting_type="border_style"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-border-style"]
                      ? field_item.options["quiz-option-border-style"]
                      : undefined
                  }
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <div className="row hard-center">
                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Border Color"
                    setting_name="[options][quiz-option-border-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-option-border-color"]
                        ? field_item.options["quiz-option-border-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-option-border-color"]
                        ? element.options["quiz-option-border-color"]
                        : variant.options["quiz-option-border-color"] || "#000"
                    }
                    condition={
                      [
                        "image",
                        "color",
                        "emoji",
                        "icon",
                        "letter",
                        "survey",
                      ].includes(field_item.field_type) &&
                      field_item.options["quiz-option-border-style"] !== "none"
                    }
                    col="6"
                    classNames="some-padding-right"
                    show_reset={true}
                  />

                  <VariantBuilderSetting
                    object={field_item}
                    object_id={field_item.id}
                    label="Hover"
                    setting_name="[options][quiz-hover-option-border-color]"
                    setting_type="color_v2"
                    object_type="fields"
                    value={
                      field_item.options["quiz-hover-option-border-color"]
                        ? field_item.options["quiz-hover-option-border-color"]
                        : undefined
                    }
                    placeholder={
                      element.options["quiz-hover-option-border-color"]
                        ? element.options["quiz-hover-option-border-color"]
                        : variant.options["quiz-hover-option-border-color"] ||
                          field_item.options["quiz-option-border-color"] ||
                          "#000"
                    }
                    condition={
                      [
                        "image",
                        "color",
                        "emoji",
                        "icon",
                        "letter",
                        "survey",
                      ].includes(field_item.field_type) &&
                      field_item.options["quiz-option-border-style"] !== "none"
                    }
                    allow_empty={true}
                    col="6"
                    classNames="some-padding-left"
                    show_reset={true}
                    offset_left={true}
                  />
                </div>

                {[
                  "image",
                  "color",
                  "emoji",
                  "icon",
                  "letter",
                  "survey",
                ].includes(field_item.field_type) &&
                  field_item.options["quiz-option-border-style"] !== "none" && (
                    <div className="col-xs-12" style={{ paddingTop: "15px" }}>
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Option Border Width
                      </div>

                      {["left", "top", "bottom", "right"].map((side) => {
                        const settingName = `[options][quiz-option-border-width-${side}]`;
                        return (
                          <VariantBuilderSetting
                            key={settingName}
                            object={field_item}
                            object_id={field_item.id}
                            setting_name={settingName}
                            setting_type="number"
                            object_type="fields"
                            value={
                              _.isNil(
                                field_item.options[
                                  `quiz-option-border-width-${side}`
                                ]
                              ) == false
                                ? field_item.options[
                                    `quiz-option-border-width-${side}`
                                  ]
                                : undefined
                            }
                            placeholder={
                              element.options[`quiz-option-border-width-${side}`]
                                ? element.options[`quiz-option-border-width-${side}`]
                                : "2px"
                            }
                            col="3"
                            allow_empty={true}
                            note={window.capitalizeFirstLetter(side)}
                            classNames="spacing-inputs"
                            min={0}
                          />
                        );
                      })}
                    </div>
                  )}

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Border Radius"
                  setting_name="[options][quiz-option-border-radius]"
                  setting_type="border_radius"
                  valueMappings={{
                    none: 'cf-not-rounded',
                    some: 'cf-slightly-rounded',
                    full: 'cf-fully-rounded',
                    custom: 'custom',
                  }}
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-border-radius"]
                      ? field_item.options["quiz-option-border-radius"]
                      : undefined
                  }
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                {[
                  "image",
                  "color",
                  "emoji",
                  "icon",
                  "letter",
                  "survey",
                ].includes(field_item.field_type) &&
                  field_item.options["quiz-option-border-radius"] ==
                    "custom" && (
                    <React.Fragment>
                      <div className="col-xs-12">
                        {[
                          "top-left",
                          "top-right",
                          "bottom-right",
                          "bottom-left",
                        ].map((side) => {
                          const settingName = `[options][quiz-option-border-radius-${side}]`;
                          return (
                            <VariantBuilderSetting
                              key={settingName}
                              object={field_item}
                              object_id={field_item.id}
                              setting_name={settingName}
                              setting_type="number"
                              object_type="fields"
                              value={
                                _.isNil(
                                  field_item.options[
                                    `quiz-option-border-radius-${side}`
                                  ]
                                ) == false
                                  ? field_item.options[
                                      `quiz-option-border-radius-${side}`
                                    ]
                                  : undefined
                              }
                              min={0}
                              note={capitalizeFirstLetter(side).replace(
                                "-",
                                " "
                              )}
                              col="3"
                              placeholder={
                                element.options[`quiz-option-border-radius-${side}`]
                                  ? element.options[`quiz-option-border-radius-${side}`]
                                  : "0px"
                              }
                              classNames="spacing-inputs"
                            />
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Selected Option Style"
                  setting_name="[options][quiz-selected-option-style]"
                  setting_type="dropdown"
                  object_type="fields"
                  value={
                    field_item.options["quiz-selected-option-style"]
                      ? field_item.options["quiz-selected-option-style"]
                      : undefined
                  }
                  options={[
                    { value: "", text: "Theme Default" },
                    { value: "transparent", text: "Transparent" },
                    { value: "custom", text: "Custom" },
                  ]}
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Selected Option Border Style"
                  setting_name="[options][quiz-selected-option-border-style]"
                  setting_type="border_style"
                  object_type="fields"
                  value={
                    field_item.options["quiz-selected-option-border-style"]
                      ? field_item.options["quiz-selected-option-border-style"]
                      : undefined
                  }
                  condition={
                    [
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-selected-option-style"] == "custom"
                  }
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Selected Option Border Color"
                  setting_name="[options][quiz-selected-option-border-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  value={
                    field_item.options["quiz-selected-option-border-color"]
                      ? field_item.options["quiz-selected-option-border-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-selected-option-border-color"]
                      ? element.options["quiz-selected-option-border-color"]
                      : variant.options["quiz-selected-option-border-color"] || "#bdc3c7"
                  }
                  condition={
                    [
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-selected-option-border-style"] !==
                      "none" &&
                    field_item.options["quiz-selected-option-style"] == "custom"
                  }
                  show_reset={true}
                />

                {[
                  "image",
                  "color",
                  "emoji",
                  "icon",
                  "letter",
                  "survey",
                ].includes(field_item.field_type) &&
                  field_item.options["quiz-selected-option-border-style"] !==
                    "none" &&
                  field_item.options["quiz-selected-option-style"] ==
                    "custom" && (
                    <div className="row">
                      <div className="col-xs-12" style={{ paddingTop: "15px" }}>
                        <div className="mb-[5px] text-xl text-black dark:text-white">
                          Selected Option Border Width
                        </div>

                        {["left", "top", "bottom", "right"].map((side) => {
                          const settingName = `[options][quiz-selected-option-border-width-${side}]`;
                          return (
                            <VariantBuilderSetting
                              key={settingName}
                              object={field_item}
                              object_id={field_item.id}
                              setting_name={settingName}
                              setting_type="number"
                              object_type="fields"
                              value={
                                _.isNil(
                                  field_item.options[
                                    `quiz-selected-option-border-width-${side}`
                                  ]
                                ) == false
                                  ? field_item.options[
                                      `quiz-selected-option-border-width-${side}`
                                    ]
                                  : "1"
                              }
                              placeholder={
                                element.options[`quiz-selected-option-border-width-${side}`]
                                  ? element.options[`quiz-selected-option-border-width-${side}`]
                                  : "0px"
                              }
                              col="3"
                              allow_empty={true}
                              note={window.capitalizeFirstLetter(side)}
                              classNames="spacing-inputs"
                              min={0}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Selected Option Font color"
                  setting_name="[options][quiz-selected-option-font-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  value={
                    field_item.options["quiz-selected-option-font-color"]
                      ? field_item.options["quiz-selected-option-font-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-selected-option-font-color"]
                      ? element.options["quiz-selected-option-font-color"]
                      : variant.options["quiz-selected-option-font-color"] || "#000"
                  }
                  condition={
                    [
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-selected-option-style"] == "custom"
                  }
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Selected Option Background"
                  setting_name="[options][quiz-selected-option-background-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  value={
                    field_item.options["quiz-selected-option-background-color"]
                      ? field_item.options[
                          "quiz-selected-option-background-color"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-selected-option-background-color"]
                      ? element.options["quiz-selected-option-background-color"]
                      : field_item.options["quiz-option-background-color"] ||
                        variant.options["quiz-selected-option-background-color"]
                  }
                  condition={
                    [
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "survey",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-selected-option-style"] == "custom"
                  }
                  show_reset={true}
                  gradient={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Option Drop Shadow"
                  setting_name="[options][quiz-option-box-shadow]"
                  setting_type="shadow"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-box-shadow"]
                      ? field_item.options["quiz-option-box-shadow"]
                      : undefined
                  }
                  condition={[
                    "image",
                    "color",
                    "emoji",
                    "icon",
                    "letter",
                    "survey",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-option-box-shadow-horizontal-distance]"
                  setting_type="range"
                  object_type="fields"
                  label={"Shadow Horizontal Distance"}
                  value={
                    field_item.options[
                      "quiz-option-box-shadow-horizontal-distance"
                    ]
                      ? field_item.options[
                          "quiz-option-box-shadow-horizontal-distance"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-box-shadow-horizontal-distance"]
                      ? element.options["quiz-option-box-shadow-horizontal-distance"]
                      : variant.options["quiz-option-box-shadow-horizontal-distance"]
                  }
                  condition={
                    field_item.options["quiz-option-box-shadow"] == "custom"
                  }
                  min={-100}
                  max={100}
                  range_step={"1"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-option-box-shadow-vertical-distance]"
                  setting_type="range"
                  object_type="fields"
                  label={"Shadow Vertical Distance"}
                  value={
                    field_item.options[
                      "quiz-option-box-shadow-vertical-distance"
                    ]
                      ? field_item.options[
                          "quiz-option-box-shadow-vertical-distance"
                        ]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-box-shadow-vertical-distance"]
                      ? element.options["quiz-option-box-shadow-vertical-distance"]
                      : variant.options["quiz-option-box-shadow-vertical-distance"]
                  }
                  condition={
                    field_item.options["quiz-option-box-shadow"] == "custom"
                  }
                  min={-100}
                  max={100}
                  range_step={"1"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  setting_name="[options][quiz-option-box-shadow-blur-radius]"
                  setting_type="range"
                  object_type="fields"
                  label={"Shadow Blur Radius"}
                  value={
                    field_item.options["quiz-option-box-shadow-blur-radius"]
                      ? field_item.options["quiz-option-box-shadow-blur-radius"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-option-box-shadow-blur-radius"]
                      ? element.options["quiz-option-box-shadow-blur-radius"]
                      : variant.options["quiz-option-box-shadow-blur-radius"]
                  }
                  condition={
                    field_item.options["quiz-option-box-shadow"] == "custom"
                  }
                  min={0}
                  max={100}
                  range_step={"1"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  object_id={field_item.id}
                  label="Shadow Color"
                  setting_name="[options][quiz-option-box-shadow-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  value={
                    field_item.options["quiz-option-box-shadow-color"]
                      ? field_item.options["quiz-option-box-shadow-color"]
                      : undefined
                  }
                  placeholder={element.options["quiz-option-box-shadow-color"]}
                  condition={
                    field_item.options["quiz-option-box-shadow"] == "custom"
                  }
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Width"
                  setting_name="[options][quiz-answer-width]"
                  setting_type="box_width"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-width"]
                      ? field_item.options["quiz-answer-width"]
                      : undefined
                  }
                  valueMappings={{
                    slim: 'cf-form-small',
                    regular: 'cf-form-mid',
                    full: 'cf-form-large',
                    custom: 'custom',
                  }}
                  show_reset={true}
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type)
                  }
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-width-value]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Field Width"}
                  value={
                    field_item.options["quiz-answer-width-value"]
                      ? field_item.options["quiz-answer-width-value"]
                      : undefined
                  }
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-width"] == "custom"
                  }
                  min={0}
                  max={2000}
                  placeholder={
                    element.options["quiz-answer-width-value"]
                      ? element.options["quiz-answer-width-value"]
                      : variant.options["field-width-value"]
                  }
                  show_reset={true}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Size"
                  setting_name="[options][quiz-answer-field-size]"
                  setting_type="box_height"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-size"]
                      ? field_item.options["quiz-answer-field-size"]
                      : undefined
                  }
                  valueMappings={{
                    regular: 'cf-field-regular',
                    large: 'cf-field-large',
                    custom: 'custom',
                  }}
                  show_reset={true}
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-field-size-value]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Field Padding value"}
                  value={
                    field_item.options["form-width-value"]
                      ? field_item.options["form-width-value"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-size-value"]
                      ? element.options["quiz-answer-field-size-value"]
                      : variant.options["field-height-value"]
                  }
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-size"] == "custom"
                  }
                  show_reset={true}
                  min={0}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Font"
                  setting_name="[options][quiz-answer-field-font]"
                  setting_type="select"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-font"]
                      ? field_item.options["quiz-answer-field-font"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-font"]
                      ? element.options["quiz-answer-field-font"]
                      : variant.options["field-font"]
                        ? variant.options["field-font"]
                        : undefined
                  }
                  option_groups={["System fonts", "Google fonts"]}
                  options={[
                    {
                      value: "",
                      text: "Theme font",
                      group: "System fonts",
                    },
                    ...window.builder_fonts_dropdown,
                  ]}
                  callback={ctas.builder.fonts.load_font_family}
                  col="8"
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-field-font-weight]"
                  setting_type="font_weight"
                  label="Weight"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-font-weight"]
                      ? field_item.options["quiz-answer-field-font-weight"]
                      : undefined
                  }
                  col="4"
                  classNames="no-padding-left"
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Font Size"
                  setting_name="[options][quiz-answer-field-font-size]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-font-size"]
                      ? field_item.options["quiz-answer-field-font-size"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-font-size"]
                      ? element.options["quiz-answer-field-font-size"]
                      : variant.options["field-font-size"]
                        ? variant.options["field-font-size"]
                        : 16
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="desktop"
                  allow_empty={true}
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Font Size"
                  setting_name="[options][quiz-answer-field-font-size-mobile]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-font-size-mobile"]
                      ? field_item.options["quiz-answer-field-font-size-mobile"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-font-size-mobile"]
                      ? element.options["quiz-answer-field-font-size-mobile"]
                      : (variant.options["field-font-size-mobile"]
                        ? variant.options["field-font-size-mobile"]
                        : variant.options["field-font-size"]) || 16
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="mobile"
                  allow_empty={true}
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Text Color"
                  setting_name="[options][quiz-answer-field-text-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-text-color"]
                      ? field_item.options["quiz-answer-field-text-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-text-color"]
                      ? element.options["quiz-answer-field-text-color"]
                      : variant.options["field-text-color"]
                        ? variant.options["field-text-color"]
                        : "#000"
                  }
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Rounding"
                  setting_name="[options][quiz-answer-field-rounded]"
                  setting_type="border_radius"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-rounded"]
                      ? field_item.options["quiz-answer-field-rounded"]
                      : undefined
                  }
                  valueMappings={{
                    none: 'cf-field-not-rounded',
                    some: 'cf-field-slightly-rounded',
                    full: 'cf-field-fully-rounded',
                    custom: 'custom',
                  }}
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                  (side) => {
                    const settingName = `[options][quiz-answer-field-radius-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={field_item}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="fields"
                        object_id={field_item.id}
                        value={
                          _.isNil(
                            field_item.options[
                              `quiz-answer-field-radius-${side}`
                            ]
                          ) == false
                            ? field_item.options[
                                `quiz-answer-field-radius-${side}`
                              ]
                            : "3"
                        }
                        condition={
                          [
                            "text",
                            "dropdown",
                            "paragraph",
                            "number",
                            "range",
                          ].includes(field_item.field_type) &&
                          field_item.options["quiz-answer-field-rounded"] ==
                            "custom"
                        }
                        min={0}
                        note={capitalizeFirstLetter(side).replace("-", " ")}
                        col="3"
                        placeholder="0px"
                        classNames="spacing-inputs"
                      />
                    );
                  }
                )}

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Background Color"
                  setting_name="[options][quiz-answer-field-background-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-background-color"]
                      ? field_item.options["quiz-answer-field-background-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-background-color"]
                      ? element.options["quiz-answer-field-background-color"]
                      : variant.options["field-background-color"]
                        ? variant.options["field-background-color"]
                        : "#fff"
                  }
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                  gradient={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Border Style"
                  setting_name="[options][quiz-answer-field-border-style]"
                  setting_type="border_style"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-border-style"]
                      ? field_item.options["quiz-answer-field-border-style"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-border-style"]
                      ? element.options["quiz-answer-field-border-style"]
                      : variant.options["field-border-style"]
                        ? variant.options["field-border-style"]
                        : undefined
                  }
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field border color"
                  setting_name="[options][quiz-answer-field-border-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-border-color"]
                      ? field_item.options["quiz-answer-field-border-color"]
                      : undefined
                  }
                  placeholder={
                    element.options["quiz-answer-field-border-color"]
                      ? element.options["quiz-answer-field-border-color"]
                      : variant.options["field-border-color"]
                        ? variant.options["field-border-color"]
                        : "#DBDBDB"
                  }
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-border-style"] !==
                      "none"
                  }
                  show_reset={true}
                />

                {["text", "dropdown", "paragraph", "number", "range"].includes(
                  field_item.field_type
                ) &&
                  field_item.options["quiz-answer-field-border-style"] !==
                    "none" && (
                    <div className="col-xs-12 mt-[25px]">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Field Border Width
                      </div>

                      {["left", "top", "bottom", "right"].map((side) => {
                        const settingName = `[options][quiz-answer-field-border-width-${side}]`;
                        return (
                          <VariantBuilderSetting
                            key={settingName}
                            object={field_item}
                            setting_name={settingName}
                            setting_type="number"
                            object_type="fields"
                            object_id={field_item.id}
                            value={
                              _.isNil(
                                field_item.options[
                                  `quiz-answer-field-border-width-${side}`
                                ]
                              ) == false
                                ? field_item.options[
                                    `quiz-answer-field-border-width-${side}`
                                  ]
                                : "1"
                            }
                            placeholder="0px"
                            allow_empty={true}
                            col="3"
                            note={window.capitalizeFirstLetter(side)}
                            classNames="spacing-inputs"
                            min={0}
                          />
                        );
                      })}
                    </div>
                  )}

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Drop Shadow"
                  setting_name="[options][quiz-answer-field-box-shadow]"
                  setting_type="shadow"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-box-shadow"]
                      ? field_item.options["quiz-answer-field-box-shadow"]
                      : "none"
                  }
                  show_reset={true}
                  condition={[
                    "text",
                    "dropdown",
                    "paragraph",
                    "number",
                    "range",
                  ].includes(field_item.field_type)}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-field-box-shadow-horizontal-distance]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Shadow Horizontal Distance"}
                  value={
                    field_item.options[
                      "quiz-answer-field-box-shadow-horizontal-distance"
                    ]
                      ? field_item.options[
                          "quiz-answer-field-box-shadow-horizontal-distance"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options["field-box-shadow-horizontal-distance"]
                  }
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-box-shadow"] ==
                      "custom"
                  }
                  min={-100}
                  max={100}
                  range_step={"1"}
                  allow_empty={true}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-field-box-shadow-vertical-distance]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Shadow Vertical Distance"}
                  value={
                    field_item.options[
                      "quiz-answer-field-box-shadow-vertical-distance"
                    ]
                      ? field_item.options[
                          "quiz-answer-field-box-shadow-vertical-distance"
                        ]
                      : undefined
                  }
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-box-shadow"] ==
                      "custom"
                  }
                  placeholder={
                    variant.options["field-box-shadow-vertical-distance"]
                  }
                  min={-100}
                  max={100}
                  range_step={"1"}
                  allow_empty={true}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-field-box-shadow-blur-radius]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Shadow Blur Radius"}
                  value={
                    field_item.options[
                      "quiz-answer-field-box-shadow-blur-radius"
                    ]
                      ? field_item.options[
                          "quiz-answer-field-box-shadow-blur-radius"
                        ]
                      : undefined
                  }
                  placeholder={variant.options["field-box-shadow-blur-radius"]}
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-box-shadow"] ==
                      "custom"
                  }
                  min={0}
                  max={100}
                  range_step={"1"}
                  allow_empty={true}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  setting_name="[options][quiz-answer-field-box-shadow-spread-radius]"
                  setting_type="range"
                  object_type="fields"
                  object_id={field_item.id}
                  label={"Shadow Blur Radius"}
                  value={
                    field_item.options[
                      "quiz-answer-field-box-shadow-spread-radius"
                    ]
                      ? field_item.options[
                          "quiz-answer-field-box-shadow-spread-radius"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options["field-box-shadow-spread-radius"]
                  }
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-box-shadow"] ==
                      "custom"
                  }
                  min={0}
                  max={100}
                  range_step={"1"}
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={field_item}
                  label="Field Shadow Color"
                  setting_name="[options][quiz-answer-field-box-shadow-color]"
                  setting_type="color_v2"
                  object_type="fields"
                  object_id={field_item.id}
                  value={
                    field_item.options["quiz-answer-field-box-shadow-color"]
                      ? field_item.options["quiz-answer-field-box-shadow-color"]
                      : undefined
                  }
                  placeholder={variant.options["field-box-shadow-color"]}
                  condition={
                    [
                      "text",
                      "dropdown",
                      "paragraph",
                      "number",
                      "range",
                    ].includes(field_item.field_type) &&
                    field_item.options["quiz-answer-field-box-shadow"] ==
                      "custom"
                  }
                  show_reset={true}
                />
              </VariantBuilderPanelCollapse>
            </React.Fragment>
          )}

          {tab == "Logic" && (
            <div className="row hard-center">
              <div className="col-xs-12 no-padding-sides">
                <div className="row hard-center w-full relative text-black dark:text-white">
                  <span className="font-semibold text-xl">Default Jump:</span>

                  <Tooltip
                    label="Learn"
                    tooltip={`Control the default question jump behavior when answering this question.`}
                  />
                </div>

                <div className="row hard-center !-mx-[21px]">
                  <VariantBuilderSetting
                    object={field_item}
                    setting_name="[modal_trigger]"
                    setting_type="item_v2"
                    object_type="fields"
                    object_id={field_item.id}
                    title={`Jump To ${
                      !field_item.options["jump-field-id"]
                        ? "Next Question"
                        : nextQuestion
                        ? nextQuestion.text
                        : (field_item_sub_fields.length > 0 || is_sub_field) && field_item.options["jump-field-id"] ==
                          "next_sub_question"
                        ? "Next Sub Question"
                        : ""
                    }`}
                    label_max_length={28}
                    label_hover_max_length={24}
                    panel_type="modal"
                    warningBorder={false}
                    label_icon={`fa-solid fa-code-branch`}
                    hide_handle={true}
                    hide_duplicate={true}
                    hide_remove={true}
                  >
                    <VariantBuilderSetting
                      object={field_item}
                      label={`By Default, Jump To:`}
                      setting_name="[options][jump-field-id]"
                      setting_type="dropdown"
                      object_type="fields"
                      object_id={field_item.id}
                      value={
                        field_item.options["jump-field-id"]
                          ? field_item.options["jump-field-id"]
                          : ""
                      }
                      options={fieldsDropdown}
                    />

                    {[
                      "survey",
                      "image",
                      "color",
                      "emoji",
                      "icon",
                      "letter",
                      "dropdown",
                    ].includes(field_item.field_type) &&
                      (field_item.multi_select + "" !== "true" ||
                        ["dropdown"].includes(field_item.field_type)) && (
                        <React.Fragment>
                          <div className="col-xs-12 some-vertical-space">
                            <div className="row hard-center">
                              <div className="col-xs-5 !w-[230px] no-padding-sides relative mt-5 mb-5 text-black dark:text-white">
                                <span className="text-xl">
                                  If Answer Selected:
                                </span>
                              </div>
                              <div className="col-xs-1 w-[50px]"></div>
                              <div className="col-xs-5 no-padding-sides relative mt-5 mb-5 text-black dark:text-white">
                                <span className="text-xl">Jump To:</span>
                              </div>
                            </div>
                            <div className="row hard-center">
                              <FieldSettingsQuestionMapping
                                variant={variant}
                                step={step}
                                element={element}
                                field={field_item}
                                fieldsDropdown={fieldsDropdown}
                                label_max_length={30}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                  </VariantBuilderSetting>
                </div>
              </div>

              <div className="col-xs-12 no-padding-sides">
                <div className="w-full relative mt-5 mb-5 text-black dark:text-white">
                  <span className="font-semibold text-xl">
                    Conditional Jumps:
                  </span>

                  <Tooltip
                    label="Learn"
                    tooltip={`Add conditional jumps to jump quiz takers to different questions based on multiple conditions.`}
                  />
                </div>
              </div>

              {Object.values(variant.action_groups)
                .filter(
                  (action_group) =>
                    action_group.field_id == field_item.id &&
                    action_group.toBeDeleted !== true
                )
                .map((action_group) => {
                  return (
                    <ActionGroupSettings
                      key={"action-group-" + action_group.id}
                      step={step}
                      section={section}
                      element={element}
                      action_group={action_group}
                      field={field_item}
                    />
                  );
                })}
              <NewActionGroup element={element} field={field_item} />
            </div>
          )}
        </TabGroup>
      </div>
    </React.Fragment>
  );
};

export default React.memo(QuizQuestionSettings, arePropsEqual);
