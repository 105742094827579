var sync = function (job_id, website_id, integration_id, button) {
  var connect_button =
    '.integration-container[data-integration="' +
    integration_id +
    '"] .integration-connect';
  var auth_button =
    "#integration-" + integration_id + " .integration-authenticate";
  var sync_button = "#integration-" + integration_id + " .integration-resync";

  $(connect_button + ", " + auth_button)
    .attr("disabled", "disabled")
    .removeClass("blue-button")
    .addClass("btn-default");
  var spinning_icon =
    '<span style="font-size: 12px !important" class="glyphicon glyphicon-refresh spinning"></span> ';
  $(connect_button + ", " + auth_button).html(spinning_icon + "Syncing...");

  function connectionFailed(data) {
    window[sync_variable] = true;
    clearInterval(sync_check);
    window.checks = 61;

    $(connect_button + ", " + auth_button).html("Oh no! Connection failed");
    $(connect_button + ", " + auth_button)
      .removeClass("btn-default blue-button")
      .addClass("btn-danger");

    if (
      data &&
      data.integration &&
      data.integration.api_data &&
      data.integration.api_data["recent_error"]
    ) {
      $(connect_button + ", " + auth_button)
        .parent()
        .find(".error-message")
        .text("Error: " + data.integration.api_data["recent_error"]);
    }

    setTimeout(function () {
      $(connect_button + ", " + auth_button)
        .removeAttr("disabled")
        .removeClass("btn-danger");
      $(connect_button + ", " + auth_button)
        .addClass("blue-button")
        .html("Connect again →");

      if (data && data.integration) {
        if (button) {
          $(button).removeAttr("onclick");
        }
        $(connect_button).removeAttr("data-toggle").removeAttr("data-modal");
        $(connect_button)
          .unbind("click.reconnect")
          .on("click.reconnect", function () {
            $("#integration-" + integration_id + "-reconnect-button").trigger(
              "click"
            );
          });
      }

      $(sync_button)
        .removeClass("btn-default btn-danger")
        .addClass("blue-button");
    }, 2000);
  }

  var job_status = false;
  window.checks = 0;
  var sync_variable = "integration_" + integration_id + "_synced";
  window[sync_variable] = undefined;

  window.sync_check = setInterval(function () {
    window.checks = checks + 1;

    if (!window[sync_variable] && checks <= 300) {
      var payload = {};
      if (job_id) {
        payload.job_id = job_id;
        payload["time"] = Date.now();
      }

      $.ajax({
        type: "GET",
        url:
          window.location.origin +
          "/websites/" +
          website_id +
          "/integrations/" +
          integration_id,
        contentType: "application/json; charset=utf-8",
        data: payload,
        async: true,
        dataType: "JSON",
        success: function (data, status, xhr) {
          if (data) {
            if (
              data.integration &&
              data.integration.api_data &&
              data.integration.api_data.authenticating == "false"
            ) {
              integrations.oauth.close(data.integration.id);
            }

            if (!data.synced) {
              if (data.failed) {
                connectionFailed(data);
              } else {
                $(connect_button + ", " + auth_button)
                  .parent()
                  .find(".error-message")
                  .text("")
                  .removeAttr("onclick");
                if (data.message) {
                  $(connect_button + ", " + auth_button)
                    .html(spinning_icon + data.message + "...")
                    .removeClass("btn-danger")
                    .addClass("btn-default");
                }
                if (
                  data.integration &&
                  data.integration.api_data.authenticating == "true"
                ) {
                  $(connect_button + ", " + auth_button)
                    .html(spinning_icon + "Authenticating...")
                    .removeClass("btn-danger")
                    .addClass("btn-default");
                }
              }
            }

            if (data.synced == true) {
              window[sync_variable] = true;
              clearInterval(sync_check);

              $.each(["options_manager", "field_manager"], function (
                i,
                manager_type
              ) {
                $.ajax({
                  url:
                    window.location.origin +
                    "/websites/" +
                    website_id +
                    "/integrations/" +
                    integration_id +
                    "/" +
                    manager_type,
                  contentType: "application/json; charset=utf-8",
                  async: true,
                  dataType: "JSON",
                  success: function (data, status, xhr) {
                    if (data && data.rendered) {
                      $(
                        '.integration-manager[data-type="' +
                          manager_type +
                          '"][data-id="' +
                          integration_id +
                          '"]'
                      ).html(data.rendered);
                      subscriptions.feature_gates();
                    }
                  },
                });
              });

              if (
                $("body").attr("data-controller") == "variants" &&
                $("body").attr("data-action") == "edit"
              ) {
                document.dispatchEvent(
                  new CustomEvent("integrationAdded", {
                    detail: { integration: data.integration },
                  })
                );
              }

              if (
                $("body").attr("data-controller") == "integrations" ||
                $("body").attr("data-action") == "launch"
              ) {
                $("#integrations").modal("hide");
                $("#integration-" + integration_id).modal("show");
              }

              $(connect_button + ", " + auth_button)
                .html("Success!")
                .removeAttr("onclick");
              $(connect_button + ", " + auth_button)
                .parent()
                .find(".error-message")
                .text("");
              $(
                '.integration-container[data-integration="' +
                  integration_id +
                  '"] .integration-alert'
              ).hide();

              setTimeout(function () {
                $(
                  '.integration-container[data-integration="' +
                    integration_id +
                    '"] .glyphicon-exclamation-sign'
                ).remove();
                $(connect_button)
                  .html("Manage →")
                  .removeAttr("disabled")
                  .removeClass("btn-danger")
                  .addClass("blue-button")
                  .unbind("click.reconnect")
                  .attr("data-toggle", "modal")
                  .attr("data-target", "#integration-" + integration_id);
                $(auth_button)
                  .html("Update authentication")
                  .removeAttr("disabled")
                  .removeClass("btn-default btn-danger")
                  .addClass("blue-button");
                $(sync_button)
                  .html("Resync")
                  .removeAttr("disabled")
                  .removeClass("btn-danger btn-default")
                  .addClass("blue-button");
              }, 2000);
            }
          }
        },
        error: function () {
          connectionFailed();
        },
      });
    }
  }, 1000);
};

export default sync;
