import React, { useState } from "react";
import VariantBuilderSlideOutPanel from "../variants/VariantBuilderSlideOutPanel";

const VariantBuilderSettingSlideOut = (props) => {
  const [panelVisibility, setPanelVisibility] = useState(false);

  return (
    <React.Fragment>
      <button
        className="btn btn-primary field-title btn-block btn-lg"
        style={{
          display: "block",
        }}
        ref={(node) => {
          if (node) {
            node.style.setProperty("color", "#fff", "important");
          }
        }}
        onClick={() => {
          setPanelVisibility(true);
        }}
      >
        <span
          className={"glyphicon " + props.button_glyphicon}
          style={{
            marginRight: "10px",
          }}
          ref={(node) => {
            if (node) {
              node.style.setProperty("font-size", "12px", "important");
            }
          }}
          attr-hidden="true"
        ></span>
        {props.value}
      </button>

      <VariantBuilderSlideOutPanel
        show={panelVisibility}
        setShow={setPanelVisibility}
        title={props.title || props.value}
        object_id={props.object_id}
        {...props}
      >
        {props.children}
      </VariantBuilderSlideOutPanel>
    </React.Fragment>
  );
};

export default VariantBuilderSettingSlideOut;
