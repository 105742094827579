const NewSectionDraggable = {
  start: (contextData, Sections) => {
    let forceFallback = environment == "test" ? false : true;

    let createFromScratchContainer = document.querySelector(
      "#sections-create-from-scratch"
    );
    if (createFromScratchContainer) {
      let scratchSortable = new Sortable(createFromScratchContainer, {
        sort: false,
        group: { name: "step-editors", pull: "clone", put: false },
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewSectionDraggable.handleDragging(event);
        },
        onEnd: function (event) {
          NewSectionDraggable.handleCreation(event, contextData, Sections);
        },
      });

      window.sortables.indexOf(scratchSortable) === -1
        ? window.sortables.push(scratchSortable)
        : "";
    }

    let templatesContainer = document.querySelectorAll(
      ".new-section-templates"
    );

    templatesContainer.forEach((templatesContainer) => {
      let templatesSortable = new Sortable(templatesContainer, {
        sort: false,
        group: { name: "step-editors", pull: "clone", put: false },
        draggable: ".cf-draggable-section-template",
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewSectionDraggable.handleDragging(event);
        },
        onEnd: function (event) {
          NewSectionDraggable.handleCreation(event, contextData, Sections);
        },
      });

      window.sortables.indexOf(templatesSortable) === -1
        ? window.sortables.push(templatesSortable)
        : "";
    });
  },

  handleDragging: (event) => {
    var sectionContainers = $(".cf-cta-container").filter(function () {
      return !$(this).find(".cf-section").length;
    });

    $(sectionContainers).append(
      `<p class='cf-draggable-creation-hover' data-object-type="Sections">Drag here to create</p>`
    );
    $(sectionContainers).find(`.cf-draggable-creation-hover[data-object-type="Sections"]`).show();
  },

  handleCreation: (event, contextData, Sections) => {
    $(`.cf-draggable-creation-hover[data-object-type="Sections"]`).remove();

    if (event.from !== event.to) event.item.remove();
    if (event.from == event.to) return;

    const position = event.newIndex;
    const template_id = parseInt(event.clone.dataset.templateId) || 0;
    const step_id = parseInt(event.to.getAttribute("data-object-id"));
    const step = contextData.objects.variant.steps[step_id];
    const positionUpdates = NewSectionDraggable.draggableContainerItemsReorderUpdates(event.to, position);

    Sections.create(step, null, template_id, false, position, positionUpdates, true);
  },

  draggableContainerItemsReorderUpdates: (
    container,
    new_section_dragged_index
  ) => {
    let updates = [];
    let newPositionIndexStart;
    let draggedIndex = parseInt(new_section_dragged_index);

    Array.from(container.children)
      .filter((section_div) => section_div.classList.contains("cf-section"))
      .forEach((section_div, i) => {
        let section_id = parseInt(section_div.getAttribute("data-object-id"));
        let old_position = parseInt(section_div.getAttribute("data-pos"));
        let new_position = i + 1;

        if (new_position < draggedIndex) return;
        if (new_position == draggedIndex) {
          newPositionIndexStart = new_position + 1;
          new_position = newPositionIndexStart;
        } else if (new_position > draggedIndex) {
          new_position = newPositionIndexStart + 1;
          newPositionIndexStart += 1;
        }

        updates.push({
          object_type: "sections",
          object_id: section_id,
          setting_name: "[position]",
          value: new_position,
          old_value: old_position,
        });
      });

    return updates;
  },
};

export default NewSectionDraggable;
