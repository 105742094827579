import React, { useContext, useState, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingFontAwesome = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [defaultValue, setDefaultValue] = useState(
    props.value === null || props.value.length === 0
      ? props.default_value
        ? props.default_value
        : "fas fa-archive"
      : props.value
  );

  function updateSetting(value) {
    if (props.skip_update !== true) {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          skip_history: props.skip_history,
        },
      ]);
    }

    if (props.callback) {
      let old_value = props.value;
      props.callback(value, old_value);
    }
  }

  const settingFontAwesome = useRef(null);

  useEffect(() => {
    updateSetting(defaultValue);

    $(settingFontAwesome.current)
      .iconpicker({ hideOnSelect: false })
      .on("iconpickerSelected", function (event) {
        updateSetting(event.iconpickerValue);
      });
  }, [settingFontAwesome]);

  return (
    <React.Fragment>
      <div className="input-group iconpicker-container">
        <input
          ref={settingFontAwesome}
          setting_name={props.setting_name}
          name={props.setting_name}
          data-placement="bottomRight"
          data-object-id={props.object_id}
          className="form-control text-field input-lg iconpicker-element"
          defaultValue={defaultValue}
          type="text"
          style={{ display: "none" }}
        />
        <div
          className="input-group-addon w-full p-3 py-5 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl !border-solid !border-[1px] !border-slate-200 dark:!border-slate-700 focus:!border-slate-200 "
          style={{
            borderLeft: '1px'
          }}
        >
          <i className={defaultValue}></i>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingFontAwesome;
