import FroalaEditor from "froala-editor";
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/align.min.js';
import "froala-editor/js/plugins/image.min.js";

var froalaExtend = function (website) {
  if (FroalaEditor && !FroalaEditor.extended) {
    FroalaEditor.extended = true;

    // merge tags plugin
    FroalaEditor.DefineIcon("personalize", { NAME: "person" });
    var merge_tags = {
      "({CURRENT_SCORE})": "Current score",
      "({person.firstname} OR {fallback=friend})": "First Name",
      "({person.lastname} OR {fallback=})": "Last Name",
      "({person.email} OR {fallback=})": "Email",
      "({person.phone} OR {fallback=})": "Phone",
      "({person.website} OR {fallback=})": "Website",
      "({person.company_name} OR {fallback=})": "Company Name",
      "({person.company_title} OR {fallback=})": "Company Title",
      "({person.city} OR {fallback=})": "City",
      "({person.country} OR {fallback=})": "Country",
      "({person.address} OR {fallback=})": "Address",
      "({person.state} OR {fallback=})": "State",
      "({person.zipcode} OR {fallback=})": "Zip Code",
    };
    
    $.each(website.fields, function (i, field) {
      var field_name = "({person.fields." + field.slug + "} OR {fallback=})";
      merge_tags[field_name] = "Custom - " + field.slug;
    });

    merge_tags = {...merge_tags,
      "({cart.value})": "Cart - Value",
      "({cart.value_threshold})": "Cart - Offer Threshold",
      "({cart.value_threshold_gap})": "Cart - Offer Threshold Gap",
      "({cart.count} OR {fallback=0})": "Cart - Item Count",
    };

    const proMergeTags = {...merge_tags};

    merge_tags = {...merge_tags, 
      "({product.title} OR {fallback=Product Title})": "Product - Title",
      "({product.type} OR {fallback=Product Type})": "Product - Type",
      "({product.price} OR {fallback=0})": "Product - Price",
      "({product.compare_at_price} OR {fallback=0})":
        "Product - Compare At Price",
      "({product.description} OR {fallback=Description})":
        "Product - Description",
      "({collection.title} OR {fallback=Collection Title})":
        "Collection - Title",
      "({parameter=PARAMETER} OR {fallback=})": "URL Parameter",
      "({window.variable} OR {fallback=})": "Global Variable",
    };

    $.each(website.integrations, function (i, integration) {
      if (
        integration.api_data !== undefined &&
        integration.api_data["properties"] !== undefined
      ) {
        $.each(integration.api_data["properties"], function (i, property) {
          var property_name =
            "({person." +
            integration.integration_type.toLowerCase() +
            "." +
            property.id +
            "} OR {fallback=})";
          merge_tags[property_name] =
            integration.integration_type + " - " + property["name"];
        });
      }
    });

    FroalaEditor.RegisterCommand("personalize", {
      title: "Merge tags",
      type: "dropdown",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      icon:
        '<span style="width: 13px; margin-top: 7px; font-size: 12px !important; float: initial !important; margin-left: 0px !important; margin-right: 0px !important;" class="glyphicon glyphicon-user center" aria-hidden="true"></span>',
      options: merge_tags,
      callback: function (cmd, val) {

        const planType = window.plan_type || $("#upgrade").attr("data-plan-type");
        const premiumSite =
          $("#upgrade").attr("data-website-premium") == "true";

        const isProMergeTag = proMergeTags[val] !== undefined;
        const isAdvancedMergeTag = !isProMergeTag;

        if (isProMergeTag && (["free", "2024_pages", "2024_core"].includes(planType) || !premiumSite)) {
          subscriptions.upgrade_modal(
            event,
            false,
            "add merge tags to your funnels",
            "2024_pro",
            "Triggered feature CTA – Merge Tags"
          );
        } else if (isAdvancedMergeTag && (["free", "2024_pages", "2024_core", "2024_funnels", "2024_pro", "2024_build", "2023_launch"].includes(planType) || !premiumSite)) {
          subscriptions.upgrade_modal(
            event,
            false,
            "personalize using dynamic text replacement",
            "2024_plus",
            "Triggered feature CTA – Merge Tags"
          );
        } else {
          this.html.insert(val);
        }
      },
    });

    // Register the line height command.
    FroalaEditor.RegisterCommand('lineHeight', {
      type: 'dropdown',
      title: 'Line Height',
      options: {
        'inherit': 'inherit',
        '10px': '10px',
        '20px': '20px',
        '30px': '30px',
        '40px': '40px',
        '50px': '50px',
        '60px': '60px',
        '70px': '70px',
        '80px': '80px',
        '90px': '90px',
        '100px': '100px',
        '110px': '110px',
        '120px': '120px',
        '130px': '130px',
        '140px': '140px',
        '150px': '150px',
      },
      plugin: 'lineHeight',
      callback: function (cmd, val) {
        this.lineHeight.apply(val);
      }
    });

    // Define an icon.
    FroalaEditor.DefineIcon("customHTML", { NAME: "star" });

    // Define a button.
    FroalaEditor.RegisterCommand("customHTML", {
      // Button title.
      title: "Custom HTML",

      // Specify the icon for the button.
      // If this option is not specified, the button name will be used.
      icon: '<div style="white-space: nowrap;"><span style="width: 8px; margin: 3px 0px 0px 0px !important; font-size: 8px !important; padding: 0px !important; min-width: 0;" class="glyphicon glyphicon-menu-left" aria-hidden="true"></span><span style="width: 8px; margin: 3px 0px 0px 0px !important; font-size: 8px !important; min-width: 0; padding: 0px !important; min-width: 0" class="glyphicon glyphicon-menu-right" aria-hidden="true"></span></div>',

      // Save the button action into undo stack.
      undo: true,

      // Focus inside the editor before the callback.
      focus: true,

      // Show the button on mobile or not.
      showOnMobile: true,

      // Refresh the buttons state after the callback.
      refreshAfterCallback: true,

      // Called when the button is hit.
      callback: function () {},

      // Called when the button state might have changed.
      refresh: function ($btn) {
        // The current context is the editor instance.
        // console.log (this.selection.element());
      },
    });

    // Define an icon.
    FroalaEditor.DefineIcon("filestack", { NAME: "image", SVG_KEY: 'image'  });

    // Define a button.
    FroalaEditor.RegisterCommand("filestack", {
      title: "Insert Image",
      icon: '<span style="margin-top: 3px; font-size: 12px !important; float: initial !important;" class="glyphicon glyphicon-picture center" aria-hidden="true"></span>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: true,
      callback: function () {
        this.selection.save();

        uploads.select("froala", {
          froala: this,
          environment: environment,
          path: "/websites/" + website.id + "/",
          cursor: this.selection.element(),
          website_id: website.id,
          library: true,
        });
      },

      // Called when the button state might have changed.
      refresh: function ($btn) {
        // The current context is the editor instance.
        // console.log (this.selection.element());
      },
    });

    FroalaEditor.DefineIcon("filestackReplace", { NAME: "image" });
    FroalaEditor.RegisterCommand("filestackReplace", {
      title: "Replace Image",
      icon: '<span style="margin-top: 3px; font-size: 12px !important; float: initial !important;" class="glyphicon glyphicon-picture center" aria-hidden="true"></span>',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.events.disableBlur();

        uploads.select("froala_replace", {
          froala: this,
          image: this.image.get(),
          environment: environment,
          path: "/websites/" + website.id + "/",
          website_id: website.id,
          library: true,
        });
      },
    });
    
  }
  return FroalaEditor;
};

export default froalaExtend;
