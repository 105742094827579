import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderToolbarLogo = (props) => {
  const [contextData] = useContext(VariantContextData);

  return (
    <div className="inline-flex w-24 cursor-pointer">
      <div className="flex items-center ">
        <div className="p-2" onClick={props.onClick}>
          <img src={contextData.dark_mode ? props.logo_white : props.logo} className="w-10" />
        </div>
        <div className={`p-2`} onClick={props.onClick}>
          <i className={`fas fa-chevron-down w-4 text-gray-500`}></i>
        </div>
      </div>
    </div>
  );
};
export default VariantBuilderToolbarLogo;