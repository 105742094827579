import React, { useState, useEffect, useRef } from "react";
import AreaItemInput from "./AreaItemInput";
import useAreas from "./useAreas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Area = (props) => {
  const { Areas } = useAreas();
  const [toggleOptions, setToggleOptions] = useState(false);

  const area = props.area;
  const website = props.website;
  const broadcast = props.broadcast;
  const cta = props.cta;
  const areaName = useRef(null);

  let is_toggled = false;
  let backgroundImage = "";

  const area_condition = Object.values(website.conditions)
    .filter(
      (cond) =>
        cond.contactable_id == broadcast?.id &&
        cond.contactable_type == "Broadcast" &&
        cond.cta_id == props.cta?.id
    )
    .find((condition) => condition.area_id == area.id);

  if (broadcast && cta && area_condition !== undefined) {
    is_toggled = true;
  }

  const [isToggled, setIsToggled] = useState(is_toggled);

  if (area.name.indexOf("Sidebar") > -1) {
    backgroundImage =
      "url(https://assets.convertflow.com/images/area-sidebar.png)";
  } else if (area.name.indexOf("Content") > -1) {
    backgroundImage =
      "url(https://assets.convertflow.com/images/area-bumper.png)";
  } else if (area.name.indexOf("Section") > -1) {
    backgroundImage =
      "url(https://assets.convertflow.com/images/area-section.png)";
  } else if (area.name.indexOf("Home") > -1) {
    backgroundImage =
      "url(https://assets.convertflow.com/images/area-section.png)";
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    Areas.update(
      props.website,
      {
        area: {
          id: area.id,
          name: areaName.current.value,
        },
      },
      (area) => {
        let newWebsiteAreas = { ...props.websiteAreas };
        newWebsiteAreas[area.id] = area;
        props.setWebsiteAreas(newWebsiteAreas);

        $(`#area-${area.id}-modal`).modal("hide");
      }
    );
  };

  const handleRemove = () => {
    Areas.remove(props.website, area, () => {
      let newWebsiteAreas = { ...props.websiteAreas };
      delete newWebsiteAreas[area.id];
      props.setWebsiteAreas(newWebsiteAreas);
    });
  };

  useEffect(() => {
    $(`#area-${area.id}-modal`)
      .off("shown.bs.modal")
      .on("shown.bs.modal", function () {
        areaName.current.focus();
      });
  }, []);

  return (
    <div
      key={area.id}
      className={`area ${broadcast?.quick == true ? "col-xs-6" : "col-xs-4"}`}
      data-id={area.id}
      data-name={area.name}
      condition-id={area_condition && area_condition.id}
      onMouseOver={() => {
        $(`.area[data-id=${area.id}]`).find(".area-options").show();
      }}
      onMouseLeave={() => {
        if (!toggleOptions) {
          $(`.area[data-id=${area.id}]`).find(".area-options").hide();
        }
      }}
    >
      <div className="row hard-center">
        <div className="flex">
          <div className="flex-grow">
            <span
              className="text-xl font-semibold text-black ellipsis cursor-pointer"
              data-name={`${area.name} area`}
              data-toggle="modal"
              data-target={`#area-${area.id}-modal`}
            >
              {area.name}
            </span>
          </div>
          <div className="">
            <div className="absolute right-0">
              <div
                className="area-options w-full text-black -mt-[2px] cursor-pointer absolute right-11"
                style={{
                  display: "none",
                }}
                onClick={() => {
                  setToggleOptions(!toggleOptions);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M11.2004 8.00039C11.2004 7.11674 11.9167 6.40039 12.8004 6.40039C13.684 6.40039 14.4004 7.11674 14.4004 8.00039C14.4004 8.88405 13.684 9.60039 12.8004 9.60039C11.9167 9.60039 11.2004 8.88405 11.2004 8.00039Z"
                    fill="black"
                  />
                  <path
                    d="M6.40039 8.00039C6.40039 7.11674 7.11673 6.40039 8.00039 6.40039C8.88405 6.40039 9.60039 7.11674 9.60039 8.00039C9.60039 8.88405 8.88405 9.60039 8.00039 9.60039C7.11673 9.60039 6.40039 8.88405 6.40039 8.00039Z"
                    fill="black"
                  />
                  <path
                    d="M1.60039 8.00039C1.60039 7.11674 2.31674 6.40039 3.20039 6.40039C4.08405 6.40039 4.80039 7.11674 4.80039 8.00039C4.80039 8.88405 4.08405 9.60039 3.20039 9.60039C2.31674 9.60039 1.60039 8.88405 1.60039 8.00039Z"
                    fill="black"
                  />
                </svg>
              </div>
              {toggleOptions && (
                <div className="absolute btn-group">
                  <ul className="!rounded-lg !border-none !shadow-lg dropdown-menu" style={{display: 'block', right: '0', left: 'initial'}}>
                    <li className="cursor-pointer" data-toggle="modal" data-target={`#area-${area.id}-modal`} style={{padding: '3px 20px'}}>
                      Edit
                    </li>
                    <li className="cursor-pointer" onClick={handleRemove} style={{padding: '3px 20px'}}>
                      Remove
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="row hard-center"
        style={{ height: "130px", background: "#2e344f" }}
      >
        <div
          className="row hard-center area-background"
          style={{ backgroundImage: backgroundImage }}
        ></div>
        <div className="row hard-center area-content">
          <div className="highlight area-code">
            <pre className="prettyprint">
              <code
                className="language-html"
                data-lang="html"
              >{`<div class="cf-${website.id}-area-${area.id}"></div>`}</code>
            </pre>
          </div>
        </div>
      </div>

      {broadcast && (
        <div className="row hard-center my-5">
          <div className="flex w-full relative">
            <AreaItemInput
              area={area}
              cta={cta}
              website={website}
              isToggled={isToggled}
              setIsToggled={setIsToggled}
              area_condition={area_condition}
              {...props}
            />
          </div>
        </div>
      )}
      
      <div
        className="modal"
        id={`area-${area.id}-modal`}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                dataDismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title font-bold px-5">Update Area</h4>
            </div>
            <div className="modal-body">
              <div className="row hard-center some-vertical-space smooth mt-0">
                <form onSubmit={handleSubmit} remote="true">
                  <input type="hidden" name="page" value="cta"></input>

                  <div
                    className="col-xs-12 some-vertical-space"
                    style={{ marginTop: "0px" }}
                  >
                    <input
                      ref={areaName}
                      type="text"
                      name="area[name]"
                      id="area_name"
                      placeholder="Area name"
                      required="required"
                      defaultValue={area.name}
                      className="w-full text-2xl border-solid border-[1px] border-slate-200 rounded-md text-black p-4"
                    />
                  </div>

                  <div className="col-xs-12">
                    <button
                      type="submit"
                      className="new-cta w-full bg-blue-600 border-none text-white text-2xl font-semibold rounded-md p-4"
                    >
                      Update Area +
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Area;
