import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingColor = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [originalValue, setOriginalValue] = useState(props.value ? props.value : null);
  const [defaultValue, setDefaultValue] = useState(
    props.value ? props.value : null
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const [pickerVisible, setPickerVisible] = useState(false);

  let brand_color_light = "#ffffff";
  if (contextData.objects.website.data["brand_color_light"]) {
    brand_color_light = contextData.objects.website.data["brand_color_light"];
  }

  let brand_color_vibrant = "#0D42E4";
  if (contextData.objects.website.data["brand_color_vibrant"]) {
    brand_color_vibrant =
      contextData.objects.website.data["brand_color_vibrant"];
  }

  let brand_color_dark = "#000000";
  if (contextData.objects.website.data["brand_color_dark"]) {
    brand_color_dark = contextData.objects.website.data["brand_color_dark"];
  }

  function updateColor(value, timeout) {
    let timeoutIdTemp;
    let delay = timeout !== undefined ? timeout : 1000;

    setDefaultValue(value);
    clearTimeout(timeoutId);

    if (!value || value !== originalValue) {
      timeoutIdTemp = setTimeout(function () {
        builder.update([
          {
            object_type: props.object_type,
            object_id: props.object_id,
            setting_name: props.setting_name,
            value: value,
            old_value: originalValue,
          },
        ]);
        setOriginalValue(value);

        if (props.callback) {
          props.callback(value, originalValue);
        }
      }, delay);

      setTimeoutId(timeoutIdTemp);
    }
  }

  useEffect(() => {
    let colorpickerInput = `.color-picker[data-setting_name="${props.setting_name}"]`;
    if (props.object_id) {
      colorpickerInput += `[data-object_id="${props.object_id}"]`;
    }

    let colorpicker = document.querySelector(colorpickerInput);

    if (props.object_id) {
      colorpickerInput += `[data-object_id="${props.object_id}"]`;
    }

    let colorInput = document.querySelector(
      'input[name="' + props.setting_name + '"]'
    );

    if (colorpicker.getAttribute("data-visible") + "" == "true") {
      if (!colorpicker.querySelector(".color-picker-input")) {
        var minicolors = document.createElement("input");
        minicolors.className = "color-picker-input";
        minicolors.setAttribute("data-defaultvalue", props.value);
        minicolors.setAttribute("data-color", "hue");
        minicolors.setAttribute("data-inline", "true");
        minicolors.setAttribute("data-lettercase", "lowercase");
        minicolors.setAttribute("data-position", "bottom left");
        minicolors.setAttribute("placeholder", "#696969");
        minicolors.type = "hidden";
        colorpicker
          .querySelector(".colorpicker-button")
          .appendChild(minicolors);

        $(minicolors).minicolors({
          color: colorpicker.getAttribute("data-control") || "hue",
          defaultValue: colorpicker.getAttribute("data-defaultvalue") || "",
          inline: colorpicker.getAttribute("data-inline") == "true",
          letterCase:
            colorpicker.getAttribute("data-lettercase") || "lowercase",
          opacity: true,
          position: colorpicker.getAttribute("data-position") || "bottom left",
          theme: "bootstrap",
          changeDelay: 0,
          change: function (hex, opacity) {
            var hexToRgb = function (hex, opacity) {
              var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
              hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
              });
              var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                hex
              );
              var r = parseInt(result[1], 16);
              var g = parseInt(result[2], 16);
              var b = parseInt(result[3], 16);
              return "rgba(" + [r, g, b].join() + ", " + opacity + ")";
            };

            var hex = hex ? hex : colorInput.value;

            var value;
            if (hex.indexOf("#") > -1) {
              value = hexToRgb(hex, opacity || 1);
            } else {
              value = hex;

              if (value.indexOf("rgba") > -1) {
                value = value.replace("rgba", "");
              } else if (value.indexOf("rgb") > -1) {
                value = value.replace("rgb", "");
              }

              var a = value.replace("(", "").replace(")", "").split(",");
              value =
                "rgba(" +
                a[0] +
                "," +
                parseInt(a[1]) +
                "," +
                parseInt(a[2]) +
                "," +
                opacity +
                ")";
            }
            if (!value) return;
            setDefaultValue(value);

            builder.update([
              {
                object_type: props.object_type,
                object_id: props.object_id,
                setting_name: props.setting_name,
                value: value,
                skip_history: true,
              },
            ]);
          },
        });
      }

      colorpicker
        .querySelectorAll(".fr-select-color")
        .forEach(function (color_button, i) {
          color_button.onclick = function () {
            let colorInput = document.querySelector(
              'input[name="' + props.setting_name + '"]'
            );
            var hexcode = color_button.getAttribute("data-color");
            updateColor(hexcode, 0);
          };
        });
    }
  });

  useEffect(() => {
    var colorpicker = document.querySelector(
      '.color-picker[data-setting_name="' + props.setting_name + '"]'
    );
    let colorInput = colorpicker.querySelector(".color-picker-input");

    if (colorInput) {
      colorpicker.querySelector(".color-picker-input").remove();
    }
    setOriginalValue(props.value ? props.value : '');
    setDefaultValue(props.value ? props.value : '');
  }, [props.object_id, props.last_reset, props.value]);

  return (
    <React.Fragment>
      <input
        type="text"
        name={props.setting_name}
        data-object_id={props.object_id}
        value={defaultValue}
        className="w-full p-3 pl-[40px] bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0"
        placeholder={props.placeholder}
        onChange={(e) => {
          let value = e.target.value;
          if (
            [
              "brand_color_dark",
              "brand_color_light",
              "brand_color_vibrant",
              "BLACK",
              "MEDIUMBLUE",
              "WHITE",
            ].indexOf(value) == -1
          ) {
            if (value.indexOf("#") > -1) {
              value = value.replace("#", "");
            }
            if (value && value.indexOf("#") == -1 && value.indexOf("(") == -1) {
              value = "#" + value;
            }
          }
          updateColor(value);
        }}
      />
      <div
        data-setting_name={props.setting_name}
        className="position-relative w-[30px] input-color-picker-toggle !border-slate-200 dark:!border-slate-700"
        data-object_id={props.object_id}
        style={{
          background: props.value
            ? props.value
            : props.placeholder
            ? props.placeholder
            : undefined,
        }}
        onClick={(e) => {
          let colorpickerToggleInput = `.input-color-picker-toggle[data-setting_name="${props.setting_name}"]`;
          if (props.object_id) {
            colorpickerToggleInput += `[data-object_id="${props.object_id}"]`;
          }

          let colorpickerToggle = document.querySelector(
            colorpickerToggleInput
          );

          if (e.target == colorpickerToggle) {
            setPickerVisible(!pickerVisible);
          }
        }}
      >
        <div
          className="popover top color-picker"
          style={{
            display: pickerVisible ? "block" : "none",
          }}
          data-setting_name={props.setting_name}
          data-object_type={props.object_type}
          data-object_id={props.object_id}
          onMouseLeave={() => {
            let colorPickerInput = `input[data-object_type="${props.object_type}"][name="${props.setting_name}"]`;
            let colorPickerSelector = `.color-picker[data-object_type="${props.object_type}"][data-setting_name="${props.setting_name}"]`;

            if (props.object_id) {
              colorPickerSelector += `[data-object_id="${props.object_id}"]`;
              colorPickerInput += `[data-object_id="${props.object_id}"]`;
            }
            let colorpicker = document.querySelector(colorPickerSelector);
            colorpicker.setAttribute("mouseIsOver", "false");

            setTimeout(function () {
              let colorInput = document.querySelector(colorPickerInput);
              if (colorpicker.getAttribute("mouseIsOver") == "false") {
                setPickerVisible(false);
                if (colorInput && colorInput.value !== originalValue) {
                  updateColor(colorInput.value);
                }
              }
            }, 2000);
          }}
          onMouseEnter={() => {
            let colorpicker = document.querySelector(
              '.color-picker[data-setting_name="' + props.setting_name + '"]'
            );
            colorpicker.setAttribute("mouseIsOver", "true");
          }}
          data-visible={pickerVisible}
        >
          <div className="arrow"></div>
          <div className="row">
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#FFFFFF",
                }}
                data-color="#FFFFFF"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#bdc3c7",
                }}
                data-color="#bdc3c7"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#6C7A89",
                }}
                data-color="#6C7A89"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#2f2f2f",
                }}
                data-color="#2f2f2f"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#000000",
                }}
                data-color="#000000"
              ></span>
            </div>

            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#2C82C9",
                }}
                data-color="#2C82C9"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#0072f7",
                }}
                data-color="#0072f7"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#34495e",
                }}
                data-color="#34495e"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#28324E",
                }}
                data-color="#28324E"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#1a2133",
                }}
                data-color="#1a2133"
              ></span>
            </div>

            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#1abc9c",
                }}
                data-color="#1abc9c"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#2ecc71",
                }}
                data-color="#2ecc71"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#27ae60",
                }}
                data-color="#27ae60"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#019875",
                }}
                data-color="#019875"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#1E824C",
                }}
                data-color="#1E824C"
              ></span>
            </div>

            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#FAC51C",
                }}
                data-color="#FAC51C"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#FBA026",
                }}
                data-color="#FBA026"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#F37934",
                }}
                data-color="#F37934"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#E25041",
                }}
                data-color="#E25041"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#96281B",
                }}
                data-color="#96281B"
              ></span>
            </div>

            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#f624a2",
                }}
                data-color="#f624a2"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                style={{
                  background: "#9A12B3",
                }}
                data-color="#9A12B3"
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                data-type="brand_color_light"
                style={{
                  background: brand_color_light,
                }}
                data-color={brand_color_light}
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                data-type="brand_color_vibrant"
                style={{
                  background: brand_color_vibrant,
                }}
                data-color={brand_color_vibrant}
              ></span>
            </div>
            <div className="col-xs-2">
              <span
                className="fr-select-color"
                data-type="brand_color_dark"
                style={{
                  background: brand_color_dark,
                }}
                data-color={brand_color_dark}
              ></span>
            </div>

            <div className="col-xs-2 colorpicker-button"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingColor;
