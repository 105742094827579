var builder = function (payload) {
  var modal = $("#upgrade");
  var plan_type = $(modal).attr("data-plan-type");
  var website_premium = $(modal).attr("data-website-premium");

  if (payload.broadcast.quick == false) {
    // timeframe
    var timeframe_div = $('.campaign-step [data-type="timeframe"]');
    $(timeframe_div)
      .find('select[name="broadcast[range_start(5i)]"]')
      .val("00")
      .hide();
    $(timeframe_div)
      .find('select[name="broadcast[range_end(5i)]"]')
      .val("00")
      .hide();

    if (payload.broadcast.timeframe == true) {
      $(timeframe_div).find(".broadcast-range-select").show();
    }

    $(timeframe_div)
      .find("select")
      .on("change", function () {
        var attribute = $(this).attr("name");

        if (attribute == "broadcast[timeframe]" && $(this).val() == "false") {
          $(timeframe_div).find(".time-selection").hide();
        } else {
          $(timeframe_div).find(".time-selection").show();
        }

        $(timeframe_div).find(".timeframe-submit").show();
      });

    // urls
    var urls_div = $('.campaign-step [data-type="urls"]');
    ctas.broadcast_page_urls(payload.broadcast, urls_div);
  }

  $(payload.container)
    .find(".campaign-toggle-checkbox")
    .on("click", function () {
      var live = $(this).is(":checked");
      var request = {};
      request[payload.campaign_type] = {
        live: live,
      };

      if (
        live == true &&
        payload.broadcast.quick == true && (
        (["free", "2023_build"].includes(plan_type) ||
          website_premium == "false"))
      ) {
        subscriptions.upgrade_modal(
          event,
          false,
          plan_type == '2023_build' ? "launch popup, sticky bar & embed campaigns" : "go live on your own website and try the full funnel builder.",
          plan_type == '2023_build' ? "2023_launch" : "2024_pro",
          "Triggered feature CTA – Campaigns"
        );
      } else if (
        live == true && payload.broadcast.quick == true && payload.cta.cta_type == 'inline' &&
        (["2024_pages", "2024_core", "2024_funnels", "2024_pro"].includes(plan_type) ||
          website_premium == "false")
      ) {
        subscriptions.upgrade_modal(
          event,
          false,
          "launch targeted embed campaigns",
          "2024_plus",
          "Triggered feature CTA – Targeted Embeds"
        );
      } else {
        $.ajax({
          type: "PUT",
          url:
            website.path +
            payload.campaign_type +
            "s/" +
            payload.contactable_id,
          data: request,
        });

        if (
          live == true &&
          window.website &&
          (window.website.activated !== true || window.free_plan == true)
        ) {
          $("#quickLaunchModal").modal("show");
        }

        if (live == true) {
          $("#broadcast-status").text("active");
        } else {
          $("#broadcast-status").text("inactive");
        }
      }
    });

  // number campaign steps
  $(payload.container)
    .find(".campaign-step:visible")
    .each(function (i, campaign_step) {
      if (
        i !==
        $(payload.container).find(".campaign-step:visible").length - 1
      ) {
        $(campaign_step).css("padding-bottom", "30px");
      }
      $(campaign_step)
        .find(".campaign-number")
        .text(i + 1);
    });

  broadcasts_ctas.initialize();
};

export default builder;
