import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const VariantBuilderSettingModalTrigger = (props) => {
  return (
    <React.Fragment>
      <button
        className="btn blue-button field-title btn-block btn-lg"
        style={{
          display: "block",
        }}
        ref={(node) => {
          if (node) {
            node.style.setProperty("color", "#fff", "important");
          }
        }}
        data-toggle="modal"
        data-target={props.selector}
      >
        <span
          className={"glyphicon " + props.button_glyphicon}
          style={{
            marginRight: "10px",
          }}
          ref={(node) => {
            if (node) {
              node.style.setProperty("font-size", "12px", "important");
            }
          }}
          attr-hidden="true"
        ></span>
        {props.value}
      </button>
    </React.Fragment>
  );
};

export default VariantBuilderSettingModalTrigger;
