import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsCarouselDesign = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Carousel`} open={true}>
        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][carousel-max-width-style]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-max-width-style"]
              ? element.options["carousel-max-width-style"]
              : "full"
          }
          options={[
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-max-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Carousel Maximum Width"}
          value={
            element.options["carousel-max-width-value"]
              ? element.options["carousel-max-width-value"]
              : undefined
          }
          condition={element.options["carousel-max-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          placeholder={"100%"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Max Width"
          setting_name="[options][carousel-max-width-style-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-max-width-style-mobile"]
              ? element.options["carousel-max-width-style-mobile"]
              : undefined
          }
          options={[
            { value: "full", text: "100%" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-max-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Carousel Maximum Width"}
          value={
            element.options["carousel-max-width-value-mobile"]
              ? element.options["carousel-max-width-value-mobile"]
              : undefined
          }
          condition={
            element.options["carousel-max-width-style-mobile"] == "custom"
          }
          min={0}
          max={280}
          range_step={"1"}
          allow_empty={true}
          placeholder={"100%"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Carousel Height"
          setting_name="[options][carousel-height-style]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-height-style"]
              ? element.options["carousel-height-style"]
              : undefined
          }
          options={[
            { value: "", text: "Auto" },
            { value: "custom", text: "Custom" },
          ]}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-height-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Carousel Height"}
          value={
            element.options["carousel-height-value"]
              ? element.options["carousel-height-value"]
              : undefined
          }
          condition={element.options["carousel-height-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          placeholder={"100%"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Height"
          setting_name="[options][carousel-height-style-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-height-style-mobile"]
              ? element.options["carousel-height-style-mobile"]
              : undefined
          }
          options={[
            { value: "", text: "Auto" },
            { value: "custom", text: "Custom" },
          ]}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-height-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Carousel Height"}
          value={
            element.options["carousel-height-value-mobile"]
              ? element.options["carousel-height-value-mobile"]
              : undefined
          }
          condition={
            element.options["carousel-height-style-mobile"] == "custom"
          }
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          placeholder={"100%"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Controls Placement"
          setting_name="[options][carousel-controls-style]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-controls-style"]
              ? element.options["carousel-controls-style"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "outside", text: "Outside" },
            { value: "inside", text: "Inside" },
          ]}
          tooltip="Not available if width custom is selected"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Carousel Background Color"
          setting_name="[options][carousel-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-background-color"]
              ? element.options["carousel-background-color"]
              : undefined
          }
          placeholder={
            variant.options["carousel-background-color"]
              ? variant.options["carousel-background-color"]
              : "f1f1f1"
          }
          show_reset={true}
          gradient={true}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Arrows`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Hide Arrow Controls"
          setting_name="[options][carousel-hide-arrow-controls]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-hide-arrow-controls"]
              ? element.options["carousel-hide-arrow-controls"]
              : "false"
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-arrow-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Arrow Size"}
          value={
            element.options["carousel-arrow-size"]
              ? element.options["carousel-arrow-size"]
              : undefined
          }
          placeholder={variant.options["carousel-arrow-size"] || "12"}
          min={1}
          max={80}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          condition={element.options["carousel-hide-arrow-controls"] !== "true"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-arrow-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Arrow Size"}
          value={
            element.options["carousel-arrow-size-mobile"]
              ? element.options["carousel-arrow-size-mobile"]
              : undefined
          }
          placeholder={variant.options["carousel-arrow-size-mobile"] || "12"}
          min={1}
          max={80}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          condition={element.options["carousel-hide-arrow-controls"] !== "true"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Arrow Controls Color"
          setting_name="[options][carousel-arrow-controls-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-arrow-controls-color"]
              ? element.options["carousel-arrow-controls-color"]
              : undefined
          }
          placeholder={variant.options["carousel-arrow-controls-color"]}
          condition={element.options["carousel-hide-arrow-controls"] !== "true"}
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Indicators`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Hide Indicator Controls"
          setting_name="[options][carousel-hide-indicator-controls]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-hide-indicator-controls"]
              ? element.options["carousel-hide-indicator-controls"]
              : "false"
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-indicator-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Indicator Size"}
          value={
            element.options["carousel-indicator-size"]
              ? element.options["carousel-indicator-size"]
              : undefined
          }
          placeholder={variant.options["carousel-indicator-size"] || "10"}
          min={1}
          max={256}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          condition={
            element.options["carousel-hide-indicator-controls"] !== "true"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][carousel-indicator-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Indicator Size"}
          value={
            element.options["carousel-indicator-size-mobile"]
              ? element.options["carousel-indicator-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["carousel-indicator-size-mobile"] || "10"
          }
          min={1}
          max={256}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          condition={
            element.options["carousel-hide-indicator-controls"] !== "true"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Indicator Controls Color"
          setting_name="[options][carousel-indicator-controls-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          allow_empty={true}
          value={
            element.options["carousel-indicator-controls-color"]
              ? element.options["carousel-indicator-controls-color"]
              : undefined
          }
          placeholder={
            variant.options["carousel-indicator-controls-color"] || "#bdc3c7"
          }
          condition={
            element.options["carousel-hide-indicator-controls"] !== "true"
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Indicator Active Border Style"
          setting_name="[options][carousel-indicator-active-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-indicator-active-border-style"]
              ? element.options["carousel-indicator-active-border-style"]
              : undefined
          }
          condition={
            element.options["carousel-hide-indicator-controls"] !== "true"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Indicator Active Border color"
          setting_name="[options][carousel-indicator-active-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["carousel-indicator-active-border-color"]
              ? element.options["carousel-indicator-active-border-color"]
              : undefined
          }
          placeholder={
            variant.options["carousel-indicator-active-border-color"] || "#000"
          }
          condition={
            element.options["carousel-hide-indicator-controls"] !== "true" &&
            element.options["carousel-indicator-active-border-style"] &&
            element.options["carousel-indicator-active-border-style"] !== "none"
          }
          show_reset={true}
          gradient={true}
        />

        {element.options["carousel-hide-indicator-controls"] !== "true" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Indicator Active Border Width
                </div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][carousel-indicator-active-border-width-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[
                            `carousel-indicator-active-border-width-${side}`
                          ]
                        ) == false
                          ? element.options[
                              `carousel-indicator-active-border-width-${side}`
                            ]
                          : element.options[
                              "carousel-indicator-active-border-width"
                            ]
                          ? element.options[
                              "carousel-indicator-active-border-width"
                            ].replace("px", "")
                          : "1"
                      }
                      placeholder="1px"
                      col="3"
                      allow_empty={true}
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Indicator Border Radius"
          setting_name="[options][carousel-indicator-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="elements"
          value={
            element.options["carousel-indicator-border-radius"]
              ? element.options["carousel-indicator-border-radius"]
              : undefined
          }
          condition={
            element.options["carousel-hide-indicator-controls"] !== "true"
          }
          show_reset={true}
        />

        {element.options["carousel-hide-indicator-controls"] !== "true" &&
          element.options["carousel-indicator-border-radius"] == "custom" && (
            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][carousel-indicator-border-radius-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      object_id={element.id}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      value={
                        _.isNil(
                          element.options[
                            `carousel-indicator-border-radius-${side}`
                          ]
                        ) == false
                          ? element.options[
                              `carousel-indicator-border-radius-${side}`
                            ]
                          : undefined
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder={
                        variant.options[
                          `carousel-indicator-border-radius-${side}`
                        ]
                          ? variant.options[
                              `carousel-indicator-border-radius-${side}`
                            ]
                          : "0px"
                      }
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>
          )}
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsCarouselDesign;
