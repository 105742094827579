import React from "react";
import PropTypes from "prop-types";

const FieldText = (props) => {
  let field = props.field;
  let element = props.element;
  let options = element.options;
  return (
    <React.Fragment>
      <div
        data-id={field.id}
        data-field={field.slug}
        data-pos={field.position ? field.position : ""}
        className="cf-form-field cf-custom-field"
        data-visible={field.field_type !== "hidden" ? "true" : ""}
        data-multi-select=""
        style={{
          order: field.position,
        }}
      >
        <div
          id={"customfield" + field.id}
          data-required={
            field.required == true && field.field_type !== "hidden"
              ? "true"
              : "false"
          }
          className={
            "contact-custom-field cf-" + field.field_type + " cf-col-xs-12"
          }
          style={{
            display: field.field_type == "hidden" ? "block" : undefined,
          }}
        >
          {field.label && props.hide_label !== true && (
            <p
              className="cf-label"
              style={{
                marginBottom: "4px",
                color: element.options["label-color"]
                  ? element.options["label-color"]
                  : "",
              }}
            >
              {field.label}
            </p>
          )}
          <input
            name={"contact[extra][" + field.slug + "]"}
            id={"contact_extra_" + field.slug}
            type={field.field_type ? field.field_type : "text"}
            defaultValue={field.value ? field.value : ""}
            placeholder={field.placeholder ? field.placeholder : ""}
            style={{ marginBottom: "0px" }}
            required={
              field.required == true && field.field_type !== "hidden"
                ? true
                : false
            }
            minLength={
              field.field_type == "text" && field.options["minimum-characters"]
                ? field.options["minimum-characters"]
                : field.field_type == "text" &&
                  !field.options["minimum-characters"]
                ? undefined
                : undefined
            }
            maxLength={
              field.field_type == "text" && field.options["maximum-characters"]
                ? field.options["maximum-characters"]
                : field.field_type == "text" &&
                  !field.options["maximum-characters"]
                ? undefined
                : undefined
            }
            min={
              field.field_type == "number" && field.options["minimum"]
                ? field.options["minimum"]
                : field.field_type == "number" && !field.options["minimum"]
                ? 0
                : undefined
            }
            max={
              field.field_type == "number" && field.options["maximum"]
                ? field.options["maximum"]
                : field.field_type == "number" && !field.options["maximum"]
                ? 1000
                : undefined
            }
          ></input>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldText;
