var areas = {
  initialize: function () {
    $(".area").each(function (i, area_div) {
      $(area_div)
        .on("mouseover", function () {
          $(area_div).find(".highlight").css("opacity", "1");
        })
        .on("mouseleave", function () {
          $(area_div).find(".highlight").css("opacity", "0.2");
        });

      $(area_div)
        .find(".object-remove")
        .unbind("click")
        .on("click", function () {
          if (
            confirm(
              "Are you sure you want to delete this area? This will remove any embedded CTAs using this area snippet ID"
            )
          ) {
            $.ajax({
              type: "DELETE",
              url:
                window.location.origin +
                "/websites/" +
                $(area_div).attr("data-website-id") +
                "/areas/" +
                $(area_div).attr("data-id"),
              success: function (data) {
                $(area_div).remove();
              },
            });
          }
        });
    });
  },

  builder: function (payload) {
    var container = payload.container;
    var conditions = payload.area_conditions;

    $(container).html("");

    if (website.areas !== undefined) {
      $.each(website.areas, function (i, area) {
        var area_condition = $.map(conditions, function (condition) {
          if (condition.area_id == area.id) return condition;
        })[0];
        var is_toggled = "";
        if (area_condition !== undefined) {
          var is_toggled = "checked";
        }

        var area_div = document.createElement("div");
        $(area_div).addClass("area");
        if (payload.broadcast.quick == true) $(area_div).addClass("col-xs-6");
        if (payload.broadcast.quick == false) $(area_div).addClass("col-xs-4");
        $(area_div).attr("data-id", area.id);
        $(area_div).attr("data-name", area.name);

        if (area_condition !== undefined) {
          $(area_div).attr("condition-id", area_condition.id);
        }

        $(area_div).append(
          '<div class="row hard-center" style="height: 130px; background: #2e344f;">' +
            '<div class="row hard-center area-background">' +
            "</div>" +
            '<div class="row hard-center area-content">' +
            '<div class="col-xs-9 no-padding-sides">' +
            '<p class="field-title ellipsis" data-name="' +
            area.name +
            ' area" style="color: #fff !important;">' +
            area.name +
            "</p>" +
            "</div>" +
            '<div class="col-xs-3 no-padding-sides right">' +
            '<input type="checkbox" class="ios-switch green area-toggle-checkbox" ' +
            is_toggled +
            " />" +
            '<article class="area-toggle">' +
            "<article></article>" +
            "</article>" +
            "</div>" +
            '<div class="highlight area-code">' +
            '<pre class="prettyprint"><code class="language-html" data-lang="html">&lt;div class=&quot;cf-' +
            website.id +
            "-area-" +
            area.id +
            "&quot;&gt;&lt;/div&gt;</code></pre>" +
            "</div>" +
            "</div>" +
            "</div>"
        );

        if (area.name.indexOf("Sidebar") > -1) {
          $(area_div)
            .find(".area-background")
            .css(
              "background-image",
              "url(https://assets.convertflow.com/images/area-sidebar.png)"
            );
        }

        if (area.name.indexOf("Content") > -1) {
          $(area_div)
            .find(".area-background")
            .css(
              "background-image",
              "url(https://assets.convertflow.com/images/area-bumper.png)"
            );
        }

        if (area.name.indexOf("Section") > -1) {
          $(area_div)
            .find(".area-background")
            .css(
              "background-image",
              "url(https://assets.convertflow.com/images/area-section.png)"
            );
        }

        if (area.name.indexOf("Home") > -1) {
          $(area_div)
            .find(".area-background")
            .css(
              "background-image",
              "url(https://assets.convertflow.com/images/area-section.png)"
            );
        }

        $(area_div)
          .find(".area-toggle-checkbox")
          .on("click", function () {
            if ($(this).is(":checked")) {
              $.ajax({
                type: "POST",
                url: "/websites/" + website.id + "/conditions",
                data: {
                  condition: {
                    website_id: website.id,
                    contactable_type: payload.contactable_type,
                    contactable_id: payload.contactable_id,
                    condition_type: "If area is",
                    area_id: area.id,
                    cta_id: payload.cta.id,
                    operator: "and",
                  },
                  builder: true,
                },
                async: true,
                success: function (data) {
                  website.conditions[data.id] = data;
                  $(area_div).attr("condition-id", data.id);
                },
              });
            } else if ($(area_div).attr("condition-id") !== undefined) {
              $.ajax({
                type: "DELETE",
                url:
                  "/websites/" +
                  website.id +
                  "/conditions/" +
                  $(area_div).attr("condition-id"),
                async: true,
                success: function (data) {
                  delete website.conditions[
                    parseInt($(area_div).attr("condition-id"))
                  ];
                },
              });
            }
          });

        $(container).append(area_div);
      });

      $(container).append(
        '<div class="' +
          (payload.broadcast.quick == false ? "col-xs-4" : "col-xs-6") +
          ' center area">' +
          '<div class="row hard-center card-new area-new" data-toggle="modal" data-target="#areaModal">' +
          "New Area +" +
          "</div>" +
          "</div>"
      );

      if (payload.broadcast.quick == false) {
        $(container).append(
          '<div class="row hard-center center areas-show-more">' +
            '<button class="btn smooth semibold btn-primary">' +
            "Show Areas →" +
            "</button>" +
            "</div>"
        );

        $(container)
          .find(".areas-show-more")
          .on("click", function () {
            $(this).hide();
            $(this).parent().removeClass("areas-preview");
          });
      }
    }
  },
};

document.addEventListener("turbolinks:load", function () {
  areas.initialize();
});

export default areas;
