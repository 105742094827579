import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingBoxWidth = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const autoWidthValue = props.valueMappings && props.valueMappings.auto ? props.valueMappings.auto : "auto";
  const regularWidthValue = props.valueMappings && props.valueMappings.regular ? props.valueMappings.regular : "regular";
  const fullWidthValue = props.valueMappings && props.valueMappings.full ? props.valueMappings.full : "full";
  const customWidthValue = props.valueMappings && props.valueMappings.custom ? props.valueMappings.custom : "custom";

  function updateValue(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">
        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == autoWidthValue ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue(autoWidthValue);
          }}
          title={`Auto Width`}
        >
          <div className=" ml-auto mr-auto w-[15px] h-[11px] bg-slate-500 dark:bg-slate-300 rounded">
          </div>
        </button>

        {props.valueMappings && props.valueMappings.regular && (
          <button
            className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
              props.value == regularWidthValue
                ? "bg-white dark:bg-slate-950"
                : ""
            }`}
            onClick={() => {
              updateValue(regularWidthValue);
            }}
            title={`Regular Width`}
          >
            <div className=" ml-auto mr-auto w-[25px] h-[11px] bg-slate-500 dark:bg-slate-300 rounded">
            </div>
          </button>
        )}

        {props.valueMappings && props.valueMappings.full && (
          <button
            className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
              props.value == fullWidthValue
                ? "bg-white dark:bg-slate-950"
                : ""
            }`}
            onClick={() => {
              updateValue(fullWidthValue);
            }}
            title={`Full Width`}
          >
            <div className=" ml-auto mr-auto w-[100%] h-[11px] bg-slate-500 dark:bg-slate-300 rounded">
            </div>
          </button>
        )}

        <button
          className={`flex-1 h-[30px] text-2xl p-3 border-none bg-transparent rounded-lg ${
            props.value == customWidthValue ? "bg-white dark:bg-slate-950" : ""
          }`}
          onClick={() => {
            updateValue(customWidthValue);
          }}
          title={"Custom Width"}
        >
          <div className=" ml-auto mr-auto w-[25px] h-[11px] border-dashed border-[1px] border-black dark:border-white rounded">
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingBoxWidth;
