var callback = function (upload_type, payload, file, data) {
  var file_url = "https://uploads.convertflow.co/" + data.key;

  if (upload_type == "input") {
    var input = document.querySelector(payload.selector);
    if (input) {
      input.value = file_url;
      var inputEvent = new Event("input", { bubbles: true });
      input.dispatchEvent(inputEvent);
    }
  }

  // new image element
  if (upload_type == "element" && payload.new == true) {
    var options = {
      image: file_url,
    };
    elements.create(
      payload.element_type,
      payload.step,
      payload.section,
      payload.column,
      undefined,
      options
    );
  }

  // updating image element
  if (upload_type == "element" && !payload.new) {
    var element_div = $('.cf-element[data-id="' + payload.element_id + '"]');
    var editor = $(element_div).find(".element-content");

    if ($(editor).find("img").length > 0) {
      var width = $(editor).find("img").css("width");
      var classes = $(editor).find("img").attr("class");
    }

    var html =
      '<p><img src="' +
      file_url +
      '" class="' +
      classes +
      '" style="width: ' +
      width +
      '" /></p>';
    $(editor).html(html);
    ctas.builder.data.update(
      payload.attribute,
      undefined,
      undefined,
      cta.elements[payload.element_id],
      html
    );
    $(editor).removeAttr("data-editor").froalaEditor("destroy");
    $(editor).trigger("click");
  }

  if (upload_type == "section") {
    if (payload.column == undefined) {
      var section_background =
        '.cf-section[section-id="' + payload.section_id + '"]';
    } else {
      var section_background =
        ".cf-column-" +
        payload.column +
        '[section-id="' +
        payload.section_id +
        '"]';
    }

    $(section_background).css("background-image", "url(" + file_url + ")");
    $('.setting[data-attribute="' + payload.attribute + '"] input').val(
      file_url
    );
    ctas.builder.data.update(
      payload.attribute,
      undefined,
      undefined,
      cta.sections[payload.section_id],
      file_url
    );
  }

  if (upload_type == "field_option") {
    var option_div = '.cf-survey-choice[data-id="' + payload.option_id + '"]';
    $(option_div).find(".cf-survey-choice-image-container").show();
    $(option_div).find("img").attr("src", file_url);
    $("#settingsModal").modal("hide");
    $('.setting[data-attribute="' + payload.attribute + '"] input')
      .val(file_url)
      .trigger("change");
    ctas.builder.data.update(
      payload.attribute,
      undefined,
      undefined,
      cta.field_options[payload.option_id],
      file_url
    );
  }

  if (upload_type == "froala") {
    payload.froala.selection.setAtEnd(payload.cursor);
    payload.froala.image.insert(file_url, true, {});
    payload.froala.undo.saveStep();
    payload.froala.filestackUploading = false;

    setTimeout(() => {
      payload.froala.events.trigger("blur", {}, true);
    }, 350);
  }

  if (upload_type == "froala_replace") {
    payload.froala.image.insert(file_url, true, {}, $(payload.image));
    payload.froala.undo.saveStep();
    payload.froala.filestackUploading = false;

    setTimeout(() => {
      payload.froala.events.trigger("blur", {}, true);
    }, 350);
  }

  if (upload_type == "form") {
    $(payload.input).val(file_url);
    $(payload.image).attr("src", file_url);
  }

  if (upload_type == "callback") {
    payload.callback(file_url);
  }

  // create image compression request
  $.ajax({
    type: "POST",
    url: window.location.origin + "/users/process_image?process_type=compress",
    dataType: "json",
    data: {
      file_path: data.key,
      file_type: file.mimetype,
      file_url: file_url,
      file_handle: data.handle,
      preview_handle: file.handle,
      user_id: payload.user_id,
      website_id: payload.website_id,
      account_id: payload.account_id,
    },
  });
};

export default callback;
