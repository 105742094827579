import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import useProducts from "../products/useProducts";
import NewActionGroup from "../action_groups/NewActionGroup";
import ActionGroupSettings from "../action_groups/ActionGroupSettings";
import ActionsBuilder from "../actions/ActionsBuilder";

const ElementSettingsProductContent = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { Products } = useProducts();

  let element = builder.selected().object;
  let variant = contextData.objects.variant;
  let cta = contextData.objects.cta;
  let website = contextData.objects.website;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  let actionAfterAddToCart = "cart";

  if (element.options["products-button-action-after-add-to-cart"]) {
    actionAfterAddToCart =
      element.options["products-button-action-after-add-to-cart"];
  } else if (element.options["products-cart-redirect"] == "true") {
    actionAfterAddToCart = "cart";
  } else if (element.options["products-cart-redirect"] == "false") {
    actionAfterAddToCart = "stay";
  }

  useEffect(() => {
    // Make sure the list is ordered by position when the page loads
    let wrapper = $("#options-panel .sortable-container.products-content");
    wrapper
      .find(".setting[data-setting_type='item_v2'][data-draggable='true']")
      .sort(function (a, b) {
        return (
          +a.getAttribute("data-position") - +b.getAttribute("data-position")
        );
      })
      .appendTo(wrapper);

    Products.sortableContentStart(element);
  }, [element]);

  const offerBadgeSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[offer-badge-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Offer Badge"
        label_icon={"fa-certificate"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["offer-badge-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][offer-badge-show]"
        toggle_value={
          element.options["offer-badge-show"] ? element.options["offer-badge-show"] : "true"
        }
        condition={element.element_type == "product_hero"}
      >
        <div className="col-xs-12">

          <VariantBuilderSetting
              object={element}
              label="Show Sold Out Badge"
              setting_name="[options][products-show-image-sold-out-badge]"
              setting_type="checkbox"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-show-image-sold-out-badge"]
                  ? element.options["products-show-image-sold-out-badge"]
                  : "false"
              }
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-image-sold-out-badge-text]"
              setting_type="text"
              label="Sold Out Label"
              object_type="elements"
              object_id={element.id}
              placeholder="SOLD OUT"
              condition={element.options["products-show-image-sold-out-badge"] == "true"}
              value={
                element.options["products-image-sold-out-badge-text"]
                  ? element.options["products-image-sold-out-badge-text"]
                  : undefined
              }
              allow_empty={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Badge"
              setting_name="[options][products-image-badge-style]"
              setting_type="dropdown"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-image-badge-style"]
                  ? element.options["products-image-badge-style"]
                  : "show-discount"
              }
              options={[
                {
                  value: "show-discount",
                  text: "Show Discount",
                },
                {
                  value: "custom",
                  text: "Custom Message",
                },
              ]}
            />

            <VariantBuilderSetting
              object={element}
              label="Image Badge Custom Message"
              setting_name="[options][products-image-badge-custom-message]"
              setting_type="text"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-image-badge-custom-message"]
                  ? element.options["products-image-badge-custom-message"]
                  : undefined
              }
              placeholder="10% Off"
              condition={
                element.options["products-reviews-show-label"] !== "false" &&
                element.options["products-image-badge-style"] == "custom"
              }
            />

        </div>
      </VariantBuilderSetting>
    );
  }

  const imageSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[image-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Image"
        label_icon={"fa-images"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        draggable={element.element_type == "product_hero" ? "false" : "true"}
        position={element.options["image-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][image-show]"
        toggle_value={
          element.options["image-show"] ? element.options["image-show"] : "true"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Link To Product"
            setting_name="[options][products-image-link-to-product]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-link-to-product"]
                ? element.options["products-image-link-to-product"]
                : "false"
            }
            tooltip={"Automatically links to product when button is hidden"}
            tooltip_position="bottom"
            classNames={`no-padding-right`}
            col="12"
          />

          <VariantBuilderSetting
            object={element}
            label="Images to Show"
            setting_name="[options][products-images-to-show]"
            setting_type="dropdown"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-images-to-show"]
                ? element.options["products-images-to-show"]
                : "featured-image"
            }
            condition={element.options["products-source"] == "ShopifyGraphql"}
            options={[
              {
                value: "featured-image",
                text: "Just Featured Image",
              },
              {
                value: "carousel",
                text: "Carousel",
              },
            ]}
          />


          <VariantBuilderSetting
            object={element}
            label="Show Image Badge"
            setting_name="[options][products-show-image-badge]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-show-image-badge"]
                ? element.options["products-show-image-badge"]
                : "false"
            }
            col="6"
            classNames="no-padding-right"
            condition={element.element_type !== "product_hero"}
          />

          <VariantBuilderSetting
            object={element}
            label="Show Sold Out Badge"
            setting_name="[options][products-show-image-sold-out-badge]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-show-image-sold-out-badge"]
                ? element.options["products-show-image-sold-out-badge"]
                : "false"
            }
            col="6"
            classNames="no-padding-left"
            condition={element.element_type !== "product_hero"}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-image-sold-out-badge-text]"
            setting_type="text"
            label="Sold Out Label"
            object_type="elements"
            object_id={element.id}
            placeholder="SOLD OUT"
            condition={element.options["products-show-image-sold-out-badge"] == "true" && element.element_type !== "product_hero"}
            value={
              element.options["products-image-sold-out-badge-text"]
                ? element.options["products-image-sold-out-badge-text"]
                : undefined
            }
            allow_empty={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge"
            setting_name="[options][products-image-badge-style]"
            setting_type="dropdown"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-style"]
                ? element.options["products-image-badge-style"]
                : "show-discount"
            }
            condition={element.options["products-show-image-badge"] == "true" && element.element_type !== "product_hero"}
            options={[
              {
                value: "show-discount",
                text: "Show Discount",
              },
              {
                value: "custom",
                text: "Custom Message",
              },
            ]}
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge Custom Message"
            setting_name="[options][products-image-badge-custom-message]"
            setting_type="text"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-custom-message"]
                ? element.options["products-image-badge-custom-message"]
                : undefined
            }
            placeholder="10% Off"
            condition={
              element.element_type !== "product_hero" &&
              element.options["products-reviews-show-label"] !== "false" &&
              element.options["products-image-badge-style"] == "custom" &&
              element.options["products-show-image-badge"] == "true"
            }
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge Placement"
            setting_name="[options][products-image-badge-placement]"
            setting_type="dropdown"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-placement"]
                ? element.options["products-image-badge-placement"]
                : "top-left"
            }
            condition={element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"}
            options={[
              {
                value: "top-left",
                text: "Top Left",
              },
              {
                value: "top-right",
                text: "Top Right",
              },
              {
                value: "bottom-right",
                text: "Bottom Right",
              },
              {
                value: "bottom-left",
                text: "Bottom Left",
              },
            ]}
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  const reviewIntegrationTypes = ["Yotpo", "Okendo"];
  const integrationsList = Object.values(website.integrations).filter(
    (i) =>
      reviewIntegrationTypes.includes(i.integration_type) && i.status == true
  );

  let reviewsSourceOptions = [
    {
      value: "none",
      text: "None",
    },
  ];

  integrationsList.forEach((integration) => {
    reviewsSourceOptions.push({
      value: integration.integration_type,
      text: integration.integration_type,
    });
  });

  const isConvertflowSource = !element.options["products-source"] ||
    element.options["products-source"]?.toLowerCase() == "convertflow";

  const reviewSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[reviews-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Reviews"
        label_icon={"fa-star"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["reviews-position"]}
        hide_duplicate={true}
        hide_remove={true}
        condition={element.options["products-source"] == "ShopifyGraphql"}
        toggle_setting_name="[options][reviews-show]"
        toggle_value={
          element.options["reviews-show"]
            ? element.options["reviews-show"]
            : "false"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Reviews Source"
            setting_name="[options][products-reviews-source]"
            setting_type="dropdown"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-reviews-source"]
                ? element.options["products-reviews-source"]
                : "none"
            }
            callback={(value) => {
              window.cf_product_reviews_source = value;
            }}
            options={reviewsSourceOptions}
          />

          <VariantBuilderSetting
            object={element}
            label="Show Count"
            setting_name="[options][products-reviews-show-count]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-reviews-show-count"]
                ? element.options["products-reviews-show-count"]
                : "true"
            }
            col="6"
          />

          <VariantBuilderSetting
            object={element}
            label="Show Label"
            setting_name="[options][products-reviews-show-label]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-reviews-show-label"]
                ? element.options["products-reviews-show-label"]
                : "true"
            }
            col="6"
          />

          <VariantBuilderSetting
            object={element}
            label="Label"
            setting_name="[options][products-reviews-label]"
            setting_type="text"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-reviews-label"]
                ? element.options["products-reviews-label"]
                : undefined
            }
            placeholder="Reviews"
            condition={
              element.options["products-reviews-show-label"] !== "false"
            }
          />

          <VariantBuilderSetting
            object={element}
            label="Link To Product"
            setting_name="[options][products-reviews-link-to-product]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-reviews-link-to-product"]
                ? element.options["products-reviews-link-to-product"]
                : "false"
            }
            tooltip={"Automatically links to product when button is hidden"}
            tooltip_position="bottom"
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  const titleSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[title-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Title"
        label_icon={"fa-a"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["title-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][title-show]"
        toggle_value={
          element.options["title-show"] ? element.options["title-show"] : "true"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Max Number of Lines"
            setting_name="[options][products-title-max-number-of-lines]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-title-max-number-of-lines"]
                ? element.options["products-title-max-number-of-lines"]
                : undefined
            }
            allow_empty={true}
            placeholder={
              variant.options["products-title-max-number-of-lines"]
                ? variant.options["products-title-max-number-of-lines"]
                : "auto"
            }
            min={1}
            max={100}
            device="desktop"
            range_step={1}
          />

          <VariantBuilderSetting
            object={element}
            label="Max Number of Lines"
            setting_name="[options][products-title-max-number-of-lines-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-title-max-number-of-lines-mobile"]
                ? element.options["products-title-max-number-of-lines-mobile"]
                : undefined
            }
            allow_empty={true}
            placeholder={
              variant.options["products-title-max-number-of-lines-mobile"]
                ? variant.options["products-title-max-number-of-lines-mobile"]
                : "auto"
            }
            min={1}
            max={100}
            device="mobile"
            range_step={1}
          />

          <VariantBuilderSetting
            object={element}
            label="Link To Product"
            setting_name="[options][products-title-link-to-product]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-title-link-to-product"]
                ? element.options["products-title-link-to-product"]
                : "false"
            }
            tooltip={"Automatically links to product when button is hidden"}
            tooltip_position="bottom"
            classNames={`no-padding-right`}
          />

          <VariantBuilderSetting
            object={element}
            label="Override Title Text"
            setting_name="[options][products-title-override-text]"
            setting_type="text"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-title-override-text"]
                ? element.options["products-title-override-text"]
                : undefined
            }
            condition={element.element_type == "product_hero"}
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  const descriptionSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[description-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Description"
        label_icon={"fa-align-left"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["description-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][description-show]"
        toggle_value={
          // handle legacy values
          element.options["description-show"]
            ? element.options["description-show"]
            : element.options["products-descriptions"]
            ? element.options["products-descriptions"]
            : "true"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Formatting"
            setting_name="[options][products-description-formatting]"
            setting_type="dropdown"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-description-formatting"]
                ? element.options["products-description-formatting"]
                : undefined
            }
            options={[
              { value: "", text: "Default" },
              { value: "cf-unformatted-text", text: "Unformatted Text" },
            ]}
          />

          <VariantBuilderSetting
            object={element}
            label="Max Number of Lines"
            setting_name="[options][products-description-max-number-of-lines]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-description-max-number-of-lines"]
                ? element.options["products-description-max-number-of-lines"]
                : undefined
            }
            allow_empty={true}
            placeholder={
              variant.options["products-description-max-number-of-lines"]
                ? variant.options["products-description-max-number-of-lines"]
                : "auto"
            }
            min={1}
            max={100}
            device="desktop"
            range_step={1}
          />

          <VariantBuilderSetting
            object={element}
            label="Max Number of Lines"
            setting_name="[options][products-description-max-number-of-lines-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-description-max-number-of-lines-mobile"]
                ? element.options[
                    "products-description-max-number-of-lines-mobile"
                  ]
                : undefined
            }
            allow_empty={true}
            placeholder={
              variant.options["products-description-max-number-of-lines-mobile"]
                ? variant.options[
                    "products-description-max-number-of-lines-mobile"
                  ]
                : "auto"
            }
            min={1}
            max={100}
            device="mobile"
            range_step={1}
          />

          <VariantBuilderSetting
            object={element}
            label="Link To Product"
            setting_name="[options][products-description-link-to-product]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-description-link-to-product"]
                ? element.options["products-description-link-to-product"]
                : "false"
            }
            tooltip={"Automatically links to product when button is hidden"}
            tooltip_position="bottom"
            classNames={`no-padding-right`}
          />

          <VariantBuilderSetting
            object={element}
            label="Override Description Text"
            setting_name="[options][products-description-override-text]"
            setting_type="editor"
            object_type="elements"
            object_id={element.id}
            editor_live_preview={false}
            allow_empty={true}
            value={
              element.options["products-description-override-text"]
                ? element.options["products-description-override-text"]
                : undefined
            }
            condition={element.element_type == "product_hero"}
          />

          
        </div>
      </VariantBuilderSetting>
    );
  };

  const priceSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[price-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Price"
        label_icon={"fa-dollar-sign"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["price-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][price-show]"
        toggle_value={
          element.options["price-show"] ? element.options["price-show"] : "true"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-discount-type]"
            setting_type="dropdown"
            label="Discount To Show"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-discount-type"]
                ? element.options["products-discount-type"]
                : ""
            }
            options={[
              { value: "", text: "No discount" },
              { value: "amount", text: "Amount" },
              { value: "percentage", text: "Percentage" },
              {
                value: "detect",
                text: "Difference with product sale price",
              },
            ]}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-discount-amount]"
            setting_type="number"
            label="Discount Amount"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-discount-amount"]
                ? element.options["products-discount-amount"]
                : "10"
            }
            allow_empty={true}
            min={1}
            condition={element.options["products-discount-type"] == "amount"}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-discount-percentage]"
            setting_type="range"
            label="Discount Percentage"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-discount-percentage"]
                ? element.options["products-discount-percentage"]
                : "10"
            }
            min={1}
            max={100}
            condition={
              element.options["products-discount-type"] == "percentage"
            }
          />

          <VariantBuilderSetting
            object={element}
            label="Link To Product"
            setting_name="[options][products-price-link-to-product]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-price-link-to-product"]
                ? element.options["products-price-link-to-product"]
                : "false"
            }
            tooltip={"Automatically links to product when button is hidden"}
            tooltip_position="bottom"
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  const variantPickerSettings = () => {
    let styleOptions = [{ value: "dropdown", text: "Dropdown" }];

    if (element.options["products-source"] == "ShopifyGraphql") {
      styleOptions.push({
        value: "cf-options-buttons",
        text: "Options Buttons",
      });
    }

    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[variant-picker-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Variant Picker"
        label_icon={"fa-ellipsis"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["variant-picker-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][variant-picker-show]"
        toggle_disabled={element.options["products-source"] == "Shopify"}
        toggle_value={
          element.options["products-source"] == "Shopify"
            ? "true"
            : element.options["variant-picker-show"]
            ? element.options["variant-picker-show"]
            : "true"
        }
        condition={
          element.options["confirmation-type"] == "cart" ||
          isConvertflowSource
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Show Label"
            setting_name="[options][products-variant-picker-show-label]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-variant-picker-show-label"]
                ? element.options["products-variant-picker-show-label"]
                : "false"
            }
            col="12"
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-variant-picker-style]"
            setting_type="dropdown"
            label="Variant Picker Style"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-variant-picker-style"]
                ? element.options["products-variant-picker-style"]
                : "dropdown"
            }
            options={styleOptions}
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  const quantityPickerSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[quantity-picker-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Quantity"
        label_icon={"fa-square-plus"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["quantity-picker-position"]}
        hide_duplicate={true}
        hide_remove={true}
        condition={
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) && element.options["confirmation-type"] == "cart"
        }
        toggle_setting_name="[options][quantity-picker-show]"
        toggle_value={
          // handle legacy values
          element.options["quantity-picker-show"]
            ? element.options["quantity-picker-show"]
            : element.options["products-quantity"]
            ? element.options["products-quantity"]
            : "false"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Show Label"
            setting_name="[options][products-quantity-picker-show-label]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-quantity-picker-show-label"]
                ? element.options["products-quantity-picker-show-label"]
                : "false"
            }
            col="12"
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-quantity-label-text]"
            setting_type="text"
            label="Label Text"
            object_type="elements"
            object_id={element.id}
            placeholder="Quantity"
            value={
              element.options["products-quantity-label-text"]
                ? element.options["products-quantity-label-text"]
                : undefined
            }
            allow_empty={true}
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  const subscriptionSettings = () => {
    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[subscription-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Subscriptions"
        label_icon={"fa-arrow-rotate-right"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["subscription-position"]}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][subscription-show]"
        condition={
          element.options["products-source"] == "ShopifyGraphql" &&
          element.options["confirmation-type"] == "cart"
        }
        toggle_value={
          element.options["subscription-show"]
            ? element.options["subscription-show"]
            : "false"
        }
      >
        <div className="col-xs-12">
          <VariantBuilderSetting
            object={element}
            label="Show Label"
            setting_name="[options][products-subscription-show-label]"
            setting_type="checkbox"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-subscription-show-label"]
                ? element.options["products-subscription-show-label"]
                : "true"
            }
            col="12"
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-subscription-label-text]"
            setting_type="text"
            label="Label Text"
            object_type="elements"
            object_id={element.id}
            placeholder="Frequency"
            value={
              element.options["products-subscription-label-text"]
                ? element.options["products-subscription-label-text"]
                : undefined
            }
            allow_empty={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Default Plan Text"
            setting_name="[options][products-subscription-default-plan-text]"
            setting_type="text"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-subscription-default-plan-text"]
                ? element.options["products-subscription-default-plan-text"]
                : undefined
            }
            placeholder="One-time purchase"
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-subscription-choices-type]"
            setting_type="dropdown"
            label="Plan Selector Type"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-subscription-choices-type"]
                ? element.options["products-subscription-choices-type"]
                : undefined
            }
            options={[
              { value: "dropdown", text: "Dropdown" },
              { value: "radios", text: "Radios" },
            ]}
          />
        </div>
      </VariantBuilderSetting>
    );
  };

  let productsButtonActionAfterAddToCartOptions = [
    {
      value: "cart",
      text: "Redirect to cart",
    },
  ];

  if (
    element.options["products-source"] == "ShopifyGraphql" ||
    element.options["products-source"] == "Shopify"
  ) {
    productsButtonActionAfterAddToCartOptions.push(
      { value: "checkout", text: "Redirect to checkout" },
      { value: "redirect", text: "Redirect to a URL" },
      { value: "step", text: "Jump to a funnel step" },
      { value: "cta", text: "Display a popup campaign" }
    );
  }

  let redirectSteps;
  if (element.options["products-button-action-after-add-to-cart"] == "step") {
    redirectSteps = [
      {
        value: "",
        text: "Select a step",
        disabled: true,
      },
    ];

    Object.values(variant.steps)
      .filter((s) => s.id !== step.id && s.toBeDeleted !== true)
      .sort((a, b) => {
        return a.position - b.position;
      })
      .forEach((s) => {
        redirectSteps.push({
          value: s.id + "",
          text:
            "Step " +
            s.position +
            (s.options["name"] ? " - " + s.options["name"] : ""),
        });
      });

    if (step.position > 1) {
      redirectSteps.push({
        value: "previous",
        text: "Jump back to previous step",
      });
    }
  }

  const buttonSettings = () => {
    const action_groups = Object.values(variant.action_groups).filter(
      (action_group) =>
        action_group.element_id == element.id &&
        action_group.toBeDeleted !== true
    );

    return (
      <VariantBuilderSetting
        object={element}
        setting_name="[button-position]"
        setting_type="item_v2"
        object_type="elements"
        object_id={element.id}
        title="Button"
        label_icon={"fa-arrow-pointer"}
        label_max_length={40}
        label_hover_max_length={30}
        style={{ margin: "4px 0px" }}
        position={element.options["button-position"]}
        onEdit={() => {
          dispatchCustomEvent("selectObject", {
            object_type: "elements",
            object_id: element.id,
            tab: "Actions",
          });

          setTimeout(() => {
            const button = $(
              '.setting[data-setting_name="[default-action]"] .cf-item-controls button[title="Edit"]'
            )[0];

            var event = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });

            button.dispatchEvent(event);
          }, 500);
        }}
        hide_duplicate={true}
        hide_remove={true}
        toggle_setting_name="[options][button-show]"
        toggle_disabled={
          element.options["products-source"] == "Shopify" &&
          element.options["confirmation-type"] == "cart"
        }
        toggle_value={
          // handle legacy values
          element.options["products-source"] == "Shopify" &&
          element.options["confirmation-type"] == "cart"
            ? "true"
            : element.options["button-show"]
            ? element.options["button-show"]
            : element.options["products-button"]
            ? element.options["products-button"]
            : "true"
        }
      >
        <VariantBuilderSetting
          object={element}
          label="Upon button click"
          setting_name="[options][confirmation-type]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["confirmation-type"]
              ? element.options["confirmation-type"]
              : ""
          }
          options={[
            {
              value: "product",
              text: "Redirect to product",
            },
            ...(["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            )
              ? [
                  {
                    value: "cart",
                    text: "Add product to cart",
                  },
                ]
              : []),
          ]}
          note={
            ["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            ) && element.options["confirmation-type"] == "cart"
              ? "The product selected will be added to cart automatically"
              : isConvertflowSource ||
                element.options["confirmation-type"] == "product"
              ? "Visitors will be automatically redirected to the selected product"
              : ""
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[html]"
          setting_type="editor"
          label="Button Text"
          object_type="elements"
          object_id={element.id}
          value={
            element.html
              ? element.html
              : element.options["confirmation-type"] == "cart" && element.options["products-source"]?.toLowerCase() !== "convertflow"
              ? "<p>Add To Cart</p>"
              : "<p>Go To Product</p>"
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Sold Out Product Button Text"
          setting_name="[options][products-sold-out-button-text]"
          setting_type="text"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-sold-out-button-text"]
              ? element.options["products-sold-out-button-text"]
              : undefined
          }
          placeholder="Sold Out"
          condition={
            ["ShopifyGraphql"].includes(element.options["products-source"]) &&
            element.options["confirmation-type"] !== "product"
          }
        />

        <VariantBuilderSetting
          object={element}
          label={"Track Conversion When"}
          setting_name="[options][track-conversion-when]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["track-conversion-when"]
              ? element.options["track-conversion-when"]
              : "cf-added-to-cart"
          }
          condition={element.options["confirmation-type"] !== "product" && ["Shopify", "ShopifyGraphql"].includes(element.options["products-source"])}
          options={[
            { value: "cf-added-to-cart", text: "Product added to cart" },
            {
              value: "cf-link-clicked-or-added-to-cart",
              text: "Product link clicked or added to cart",
            },
            {
              value: "cf-clicking-through-to-cart",
              text: "Clicking through after adding to cart",
            },
          ]}
        />

        <ActionsBuilder
          cta={cta}
          variant={variant}
          website={website}
          contactable_type="Element"
          contactable_id={element.id}
          element={element}
          title={"Trigger These Automations"}
        />

        <VariantBuilderSetting
          object={element}
          label="Fire a script"
          setting_name="[options][script]"
          setting_type="code"
          object_type="elements"
          object_id={element.id}
          value={element.options["script"] ? element.options["script"] : ""}
          tooltip={
            element.element_type == "form"
              ? "Custom scripts will fire upon successful submission of form, before redirect actions are taken"
              : element.element_type == "button"
              ? "Custom scripts will fire upon clicking button, before redirect actions are taken"
              : element.element_type == "survey"
              ? "Custom scripts will fire upon selecting an option, before redirect actions are taken"
              : ""
          }
          feature_gate_type={"Custom Scripts"}
        />

        <React.Fragment>
          <div
            className="field-title col-xs-12"
            style={{ margin: "15px 0px 0px" }}
          >
            Run These Conditional Actions
          </div>

          {action_groups.map((action_group) => {
            return (
              <ActionGroupSettings
                key={"action-group-" + action_group.id}
                step={step}
                element={element}
                action_group={action_group}
              />
            );
          })}
          <NewActionGroup element={element} />
        </React.Fragment>

        <VariantBuilderSetting
          object={element}
          label="Redirect to cart upon add to cart"
          setting_name="[options][products-cart-redirect]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-cart-redirect"]
              ? element.options["products-cart-redirect"]
              : "false"
          }
          condition={
            element.element_type == "products" &&
            ["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            ) &&
            element.options["confirmation-type"] == "cart"
          }
          note="Leave this untoggled if you want shoppers to be able to add multiple products to cart"
        />

        {element.options["confirmation-type"] !== "product" &&
          element.options["products-cart-redirect"] !== "true" && (
            <div className="field-headline col-xs-12 some-vertical-space">
              After adding to cart, have the button:
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Button Text After Add To Cart"
          setting_name="[options][products-cart-button]"
          setting_type="editor"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-cart-button"]
              ? element.options["products-cart-button"]
              : "<p>View Cart</p>"
          }
          condition={
            element.options["products-cart-redirect"] !== "true" &&
            ["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            ) &&
            element.options["confirmation-type"] !== "product"
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Upon button click"
          setting_name="[options][products-button-action-after-add-to-cart]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={actionAfterAddToCart}
          condition={
            element.options["products-cart-redirect"] !== "true" &&
            ["ShopifyGraphql", "Shopify"].includes(
              element.options["products-source"]
            ) &&
            element.options["confirmation-type"] == "cart"
          }
          options={productsButtonActionAfterAddToCartOptions}
        />

        <VariantBuilderSetting
          object={element}
          label="Default funnel step to jump to"
          setting_name="[options][products-button-redirect-step]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-button-redirect-step"] &&
            (element.options["products-button-redirect-step"] == "previous" ||
              Object.values(variant.steps).filter(
                (s) =>
                  s.id + "" ==
                    element.options["products-button-redirect-step"] &&
                  s.toBeDeleted !== true
              ).length > 0)
              ? element.options["products-button-redirect-step"]
              : ""
          }
          options={redirectSteps}
          condition={
            element.options["confirmation-type"] == "cart" &&
            element.options["products-cart-redirect"] !== "true" &&
            element.options["products-button-action-after-add-to-cart"] ==
              "step"
          }
          warning={
            !element.options["products-button-redirect-step"]
              ? "Step to jump to not yet set"
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Redirect to a URL"
          setting_name="[options][products-button-redirect-url]"
          setting_type="text"
          object_type="elements"
          object_id={element.id}
          value={element.options["products-button-redirect-url"]}
          condition={
            element.options["confirmation-type"] == "cart" &&
            element.options["products-cart-redirect"] !== "true" &&
            element.options["products-button-action-after-add-to-cart"] ==
              "redirect"
          }
          placeholder="https://"
          warning={
            !element.options["products-button-redirect-url"]
              ? "Redirect URL not yet set"
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Campaign to trigger"
          setting_name="[options][products-button-confirmation-cta]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          value={element.options["products-button-confirmation-cta"]}
          options={[
            {
              value: "",
              text: "Select a popup campaign",
              disabled: true,
            },
            ...Object.values(website.ctas)
              .filter(
                (c) =>
                  ["inline", "page"].includes(c.cta_type) == false &&
                  c.id !== cta.id
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => {
                return {
                  value: c.id,
                  text:
                    (c.name || "Campaign " + c.id) +
                    " – " +
                    c.cta_type.substr(0, 1).toUpperCase() +
                    c.cta_type.substr(1),
                };
              }),
          ]}
          condition={
            element.options["confirmation-type"] == "cart" &&
            element.options["products-cart-redirect"] !== "true" &&
            element.options["products-button-action-after-add-to-cart"] ==
              "cta"
          }
          tooltip="Enabling this will trigger the select popup campaign"
          warning={
            !element.options["products-button-confirmation-cta"]
              ? "Campaign to display not yet set"
              : undefined
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Close current popup"
          setting_name="[options][products-button-close-popup]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-button-close-popup"]
              ? element.options["products-button-close-popup"]
              : "false"
          }
          condition={
            element.options["products-button-action-after-add-to-cart"] ==
              "cta" &&
            ["overlay", "hook", "bar"].includes(cta.cta_type) &&
            ["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            ) &&
            element.options["confirmation-type"] == "cart"
          }
        />

        <VariantBuilderSetting
          object={element}
          label="Open in new tab?"
          setting_name="[options][target-blank]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["target-blank"]
              ? element.options["target-blank"]
              : "false"
          }
          condition={
            ["redirect", "product", "cart"].includes(
              element.options["confirmation-type"]
            ) &&
            (element.options["confirmation-type"] !== "cart" ||
              (actionAfterAddToCart == "cart" &&
                element.options["products-cart-redirect"] !== "false"))
          }
          placeholder="https://"
        />

        <VariantBuilderSetting
          object={element}
          label="Apply discount code upon redirect"
          setting_name="[options][products-cart-redirect-discount]"
          setting_type="text"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-cart-redirect-discount"]
              ? element.options["products-cart-redirect-discount"]
              : ""
          }
          placeholder="BFCMSALE"
          condition={
            ["Shopify", "ShopifyGraphql"].includes(
              element.options["products-source"]
            ) &&
            ["product", "cart"].includes(
              element.options["confirmation-type"]
            ) &&
            element.options["products-button-action-after-add-to-cart"] !==
              "step" &&
            element.options["products-button-action-after-add-to-cart"] !==
              "cta"
          }
        />
      </VariantBuilderSetting>
    );
  };

  return (
    <React.Fragment>
      <div
        className="col-xs-12 sortable-container products-content"
        data-object_type="elements"
        data-object_id={element.id}
      >
        {imageSettings()}

        {element.element_type == "product_hero" && <div className="col-xs-12 no-padding-sides" style={{ margin: '5px 0px' }}>
          <div className="cf-divider-line border-solid border-[0px] border-t-[1px] border-slate-200 dark:border-slate-800" />
        </div>}

        {offerBadgeSettings()}
        {reviewSettings()}
        {titleSettings()}
        {priceSettings()}
        {descriptionSettings()}
        {variantPickerSettings()}
        {quantityPickerSettings()}
        {subscriptionSettings()}
        {buttonSettings()}
      </div>
    </React.Fragment>
  );
};

export default ElementSettingsProductContent;
