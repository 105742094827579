import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const ContentListItem = (props) => {
  let list_item = props.list_item;
  let element = props.element;

  function numberToLetter(n) {
    let result = "";
    while (n > 0) {
      let remainder = (n - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      n = Math.floor((n - remainder) / 26);
    }
    return result;
  }

  const visualType =
    list_item.visual_type ||
    element.options.list_visual_type ||
    props.variant.options.list_visual_type ||
    "bullet";

  const classes = style.classes(list_item);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    list_item,
    element,
    props.variant,
    visualType
  );

  const visualTypeValue = () => {
    if (visualType === "image") {
      return (
        list_item.image ||
        element.options.list_image ||
        props.variant.options.list_image
      );
    } else if (visualType === "letter") {
      return list_item.letter || numberToLetter(parseInt(list_item.position));
    } else if (visualType === "icon") {
      return (
        list_item.icon ||
        element.options.list_icon ||
        props.variant.options.list_icon
      );
    } else if (visualType === "emoji") {
      return (
        list_item.emoji ||
        element.options.list_emoji ||
        props.variant.options.list_emoji
      );
    }
  };

  const isBulletVisualType = () => {
    return visualType === "bullet";
  };

  const isImageVisualType = () => {
    return visualType === "image" && visualTypeValue();
  };

  const isLetterVisualType = () => {
    return visualType === "letter" && visualTypeValue();
  };

  const isIconVisualType = () => {
    return visualType === "icon" && visualTypeValue();
  };

  const isEmojiVisualType = () => {
    return visualType === "emoji" && visualTypeValue();
  };

  const isNumberedVisualType = () => {
    return visualType === "numbered";
  };

  const visualTypeBlock = () => {
    return (
      <React.Fragment>
        {isImageVisualType() ? (
          <span
            className={"cf-image-visual-type"}
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            <img src={visualTypeValue()}></img>
          </span>
        ) : (
          <div className="cf-visual-type">
            {isBulletVisualType() ? (
              <span className={"cf-visual-type-bullet"}>•</span>
            ) : (
              ""
            )}
            {isIconVisualType() ? (
              <span className={"cf-visual-type-icon"}>
                <FontAwesomeIcon icon={visualTypeValue()} />
              </span>
            ) : (
              ""
            )}

            {isEmojiVisualType() ? (
              <span className={"cf-visual-type-emoji"}>
                {visualTypeValue()}
              </span>
            ) : (
              ""
            )}

            {isLetterVisualType() ? (
              <span className={"cf-visual-type-letter"}>
                {visualTypeValue()}
              </span>
            ) : (
              ""
            )}

            {isNumberedVisualType() ? (
              <span className={"cf-visual-type-numbered"}>
                {list_item.position}
              </span>
            ) : (
              ""
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    let element_tag = document.querySelector(
      '#builder .cf-element[data-element-id="' + element.id + '"]'
    );
    let list_item_froala = element_tag.querySelector(
      `#list-item-${list_item.id} .cf-content`
    );

    $(list_item_froala)
      .unbind("click")
      .on("click", function (e) {
        let item_list_trigger = document.querySelector(
          `.item-trigger[data-object_type="content_list_items"][data-object_id="${list_item.id}"]`
        );

        if (item_list_trigger) {
          item_list_trigger.click();
        }
      });
  }, []);

  return (
    <React.Fragment>
      <li
        id={`list-item-${list_item.id}`}
        className={classes}
        key={"item-" + list_item.id}
        data-emoji={isEmojiVisualType() ? list_item.emoji : ""}
      >
        {visualTypeBlock()}
        <div
          className="cf-content"
          style={{ display: "inline-block" }}
          dangerouslySetInnerHTML={{
            __html: list_item.content,
          }}
        ></div>
      </li>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  classes: (list_item) => {
    let options = list_item.options;
    let classes = "element-content cf-list-element-item ";

    return classes;
  },
  stylesheet: (
    builder,
    device,
    cta,
    list_item,
    element,
    variant,
    visualType
  ) => {
    let options = { ...element.options };

    let contentFontFamily;
    if (
      options["content-font-family"] &&
      options["content-font-family"] !== "theme"
    ) {
      contentFontFamily = options["content-font-family"];
    }

    let contentFontWeight;
    if (
      options["content-font-weight"] &&
      options["content-font-weight"] !== "theme"
    ) {
      contentFontWeight = options["content-font-weight"];
    }

    let contentFontSize;
    if (options["content-font-size"]) {
      contentFontSize = options["content-font-size"];
    }

    let contentFontColor;
    if (
      options["content-font-color"] &&
      options["content-font-color"] !== "theme"
    ) {
      contentFontColor = options["content-font-color"];
    }

    let contentTextShadow;
    if (options["content-box-shadow"] == "none") {
      contentTextShadow = "none";
    }
    if (options["content-box-shadow"] == "soft") {
      contentTextShadow = "0 3px 5px rgb(179 179 179 / 78%)";
    }
    if (options["content-box-shadow"] == "hard") {
      contentTextShadow = "-3px 3px 0 rgb(0 0 0 / 10%)";
    }
    if (options["content-box-shadow"] == "custom") {
      contentTextShadow = "";
      contentTextShadow +=
        (element.options["content-box-shadow-horizontal-distance"]
          ? `${element.options["content-box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      contentTextShadow +=
        (element.options["content-box-shadow-vertical-distance"]
          ? `${element.options["content-box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      contentTextShadow +=
        (element.options["content-box-shadow-blur-radius"]
          ? `${element.options["content-box-shadow-blur-radius"]}px`
          : "0px") + " ";

      contentTextShadow +=
        (element.options["content-box-shadow-color"]
          ? element.options["content-box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    let contentAlignment;
    if (element.options["content-alignment"]) {
      contentAlignment = element.options["content-alignment"];
    }

    let visualTypeImageWidth;
    if (element.options["visual-type-image-width"]) {
      visualTypeImageWidth = element.options["visual-type-image-width"];
    } else if (variant.options["list-visual-type-image-width"]) {
      visualTypeImageWidth = variant.options["list-visual-type-image-width"];
    } else if (contentFontSize) {
      visualTypeImageWidth = contentFontSize * 2.5;
    } else {
      visualTypeImageWidth = "134";
    }

    let visualTypeFontSize;
    if (options["visual-type-font-size"]) {
      visualTypeFontSize = options["visual-type-font-size"];
    }

    let visualTypeFontColor;
    if (options["visual-type-font-color"]) {
      visualTypeFontColor = options["visual-type-font-color"];
    }

    let visualTypeLetterColor;
    if (visualTypeFontColor) {
      visualTypeLetterColor = visualTypeFontColor;
    } else if (variant.options["list-visual-type-font-color"]) {
      visualTypeLetterColor = variant.options["list-visual-type-font-color"];
    } else {
      visualTypeLetterColor = "#000";
    }

    let contentLineHeight;
    if (options["content-font-line-height"]) {
      contentLineHeight = options["content-font-line-height"];
    } else if (variant.options["list-content-font-line-height"]) {
      contentLineHeight = variant.options["list-content-font-line-height"];
    }

    let elementDividerPresentWithoutLineHeight =
      options["list-items-divider-style"] !== undefined &&
      options["list-items-divider-style"] !== "none" &&
      !options["content-font-line-height"];

    let variantDividerPresentWithoutLineHeight =
      variant.options["list-items-divider-style"] !== undefined &&
      variant.options["list-items-divider-style"] !== "none" &&
      !variant.options["list-content-font-line-height"];

    let visualTypeWidth = "";
    let visualFontSize =
      visualTypeFontSize || variant.options["list-visual-type-font-size"] || 16;
    visualTypeWidth = `${parseInt(visualFontSize) * 2.8}`;

    let listVisualTypeSpacing =
      options["list-visual-type-spacing"] ||
      variant.options["list-visual-type-spacing"] ||
      undefined;

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} {
        display: flex;
        align-items: ${visualType === "image" ? 'baseline' : visualType === "letter" ? 'baseline' : 'flex-start'} !important;
        ${contentLineHeight ? `line-height: ${contentLineHeight}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type {
        ${visualTypeFontSize ? `font-size: ${visualTypeFontSize}px !important;` : ''}
        ${visualTypeFontColor ? `color: ${visualTypeFontColor} !important;` : ''}
        ${listVisualTypeSpacing ? `margin-right: ${listVisualTypeSpacing}px !important;` : `width: ${visualTypeWidth}px !important;` }
        text-align: center !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type .svg-inline--fa {
        display: var(--fa-display, inline-block) !important;
        height: 1em !important;
        overflow: visible !important;
        ${visualTypeFontSize ? `font-size: ${visualTypeFontSize}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element .cf-list-element .cf-items-divider:not(:last-child) {
        display: inline-block !important;
        ${elementDividerPresentWithoutLineHeight && variantDividerPresentWithoutLineHeight ? "margin-bottom: 10px !important; margin-top: 10px !important;" : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content {
        width: 100% !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content div,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content p,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h1,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h2,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h3,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h4 {
        ${contentFontFamily ? `font-family: ${contentFontFamily}, sans-serif !important;` : ''}
        ${contentFontWeight ? `font-weight: ${contentFontWeight} !important;` : ''}
        ${contentFontSize ? `font-size: ${contentFontSize}px !important;` : ""}
        ${contentFontColor ? `color: ${contentFontColor} !important;` : ''}
        ${contentTextShadow ? `text-shadow: ${contentTextShadow} !important;` : ''}
        ${contentAlignment ? `text-align: ${contentAlignment} !important;` : 'text-align: left !important;'}
        ${contentLineHeight ? `line-height: ${contentLineHeight}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type-letter {
        ${visualTypeLetterColor ? `border: 2px solid ${visualTypeLetterColor} !important;` : ''}
        ${visualTypeLetterColor ? `background-color: ${visualTypeLetterColor}14 !important;` : ''}
        border-radius: 50% !important;
        ${visualFontSize ? `font-size: ${visualFontSize * 0.7}px !important;` : ""}
        width: ${visualFontSize * 1.2}px !important;
        height: ${visualFontSize * 1.2}px !important;
        line-height: ${visualFontSize * 1.05}px !important;
        font-family: Arial, sans-serif !important;
        display: block !important;
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
        box-sizing: border-box !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type-numbered {
        font-family: Arial, sans-serif !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-image-visual-type {
        line-height: initial !important;
        box-sizing: border-box !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-image-visual-type img {
        ${visualTypeImageWidth ? `width: ${visualTypeImageWidth}px !important;` : ""}
        max-width: 100% !important;
      }
      `;

    let contentFontSizeMobile;
    if (options["content-font-size-mobile"]) {
      contentFontSizeMobile = options["content-font-size-mobile"];
    }

    let visualTypeFontSizeMobile;
    if (options["visual-type-font-size-mobile"]) {
      visualTypeFontSizeMobile = options["visual-type-font-size-mobile"];
    } else if (variant.options["list-visual-type-font-size-mobile"]) {
      visualTypeFontSizeMobile =
        variant.options["list-visual-type-font-size-mobile"];
    }

    let contentLineHeightMobile;
    if (options["content-font-line-height-mobile"]) {
      contentLineHeightMobile = options["content-font-line-height-mobile"];
    } else if (variant.options["list-content-font-line-height-mobile"]) {
      contentLineHeightMobile =
        variant.options["list-content-font-line-height-mobile"];
    }

    let visualTypeImageWidthMobile;
    if (element.options["visual-type-image-width-mobile"]) {
      visualTypeImageWidthMobile =
        element.options["visual-type-image-width-mobile"];
    } else if (variant.options["list-visual-type-image-width-mobile"]) {
      visualTypeImageWidthMobile =
        variant.options["list-visual-type-image-width-mobile"];
    } else if (contentFontSizeMobile) {
      visualTypeImageWidthMobile = contentFontSizeMobile * 2.5;
    } else {
      visualTypeImageWidthMobile = "134";
    }

    let visualTypeWidthMobile = "";
    let visualFontSizeMobile =
      visualTypeFontSizeMobile ||
      variant.options["list-visual-type-font-size-mobile"] ||
      visualFontSize;
    visualTypeWidthMobile = `${parseInt(visualFontSizeMobile) * 2.8}`;

    let listVisualTypeSpacingMobile =
      options["list-visual-type-spacing-mobile"] ||
      options["list-visual-type-spacing"] ||
      variant.options["list-visual-type-spacing-mobile"] ||
      variant.options["list-visual-type-spacing"] ||
      undefined;

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} {
        ${contentLineHeightMobile ? `line-height: ${contentLineHeightMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content div,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content p,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h1,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h2,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h3,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-list-element-item .cf-content h4 {
        ${contentFontSizeMobile ? `font-size: ${contentFontSizeMobile}px !important;` : ""}
        ${contentLineHeightMobile ? `line-height: ${contentLineHeightMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type {
        ${visualTypeFontSizeMobile ? `font-size: ${visualTypeFontSizeMobile}px !important;` : ''}
        ${listVisualTypeSpacingMobile ? `margin-right: ${listVisualTypeSpacingMobile}px !important;` : `width: ${visualTypeWidthMobile}px !important;` }
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type-letter {
        ${visualTypeFontSizeMobile ? `font-size: ${parseInt(visualTypeFontSizeMobile) * 0.6}px !important;` : ''}
        width: ${visualFontSizeMobile}px !important;
        height: ${visualFontSizeMobile}px !important;
        line-height: ${visualFontSizeMobile * 0.8}px !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-image-visual-type img {
        ${visualTypeImageWidthMobile ? `width: ${visualTypeImageWidthMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] #list-item-${list_item.id} .cf-visual-type .svg-inline--fa {
        ${visualTypeFontSizeMobile ? `font-size: ${visualTypeFontSizeMobile * 0.7}px !important;` : ""}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ContentListItem;
