import React, { useContext } from "react";
import PropTypes from "prop-types";
import VariantBuilderCanvasSelectPopover from "./VariantBuilderCanvasSelectPopover.js";

const VariantBuilderCanvasSelect = (props) => {
  let label = capitalizeFirstLetter(props.label).replace('_', ' ');

  let selectObject = () => {
    if (
      props.object_type == "elements" &&
      ["row", "survey"].includes(props.object.element_type) == false
    ) {
      let element_tag = $(
        '#builder .cf-element[data-element-id="' +
          props.object_id +
          '"] .froala-editor'
      )
        .children()
        .last();
      $(element_tag).dblclick();
    } else {
      document.dispatchEvent(
        new CustomEvent("selectObject", {
          detail: {
            object_type: props.object_type,
            object_id: props.object_id,
            collapse: props.column,
            editing: false,
          },
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div
        className="select-label smooth left"
        data-object_type={props.object_type}
        data-object_id={props.object_id}
        data-column={props.column}
      >
        <span
          onClick={selectObject}
          style={{
            marginRight: "5px",
          }}
          className="select-label-span"
          data-object_id={props.object_id}
        >
          {label}
        </span>
      </div>
      {!props.object_type == "sections" ||
      (!props.column && props.object && !props.element_id) ? (
        <VariantBuilderCanvasSelectPopover {...props} label={label} />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default VariantBuilderCanvasSelect;
