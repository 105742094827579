class YotpoClient {
  constructor(props) {
    this.endpoint = `https://api.yotpo.com/`;
    this.app_key = props.app_key;

    this.clientOptions = {
      headers: { "Content-Type": "application/json" },
    };
  }

  getProductReviews = async (productId) => {
    return new Promise((resolve, reject) => fetch(
      `${this.endpoint}v1/widget/${this.app_key}/products/${productId}/reviews.json`, this.clientOptions
    ).then(response => {
      response.json().then(resp => {
        resolve({...resp.response.bottomline, product_id: productId})
      }).catch(reject);
    }).catch(reject));
  }
}

if (typeof window !== "undefined" && typeof window.convertflow == "undefined") {
  window.YotpoClient = YotpoClient;
}
