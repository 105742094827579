import React, { useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";
import { VariantContextData } from "../variants/VariantBuilderContext";

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * 35;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={35}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const VariantBuilderSettingSelect = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  let options = props.options;

  if (options[0] && options[0].text) {
    options = props.options.filter(e => e.condition !== false).map((option) => {
      return { value: option.value, label: option.text };
    });
  }

  const selectedOptions =
    props.multi_select !== true && options && options.length > 0
      ? options[0].options
        ? options
            .filter((option) => option.options)
            .flatMap((option) => option.options)
            .filter((option) => option.value === props.value)
        : options.find((option) => option.value === props.value)
      : props.multi_select !== true
      ? ""
      : undefined;

  const multiSelectedOptions =
    props.multi_select == true && options && options.length > 0
      ? options[0].options
        ? options
            .filter((option) => option.options)
            .flatMap((option) => option.options)
            .filter(
              (option) =>
                option.value && props.value?.split(",")?.includes(option.value)
            )
        : options.filter(
            (option) =>
              option.value && props.value?.split(",")?.includes(option.value)
          )
      : undefined;

  let icon;

  const formatOptionLabel = ({ value, label }) => {
    const isFontDropdown = props.setting_name.indexOf('font') > -1;

    props.options.forEach((option) => {
      if (option.options) {
        const selectedOption = option.options.filter(
          (option) => option.value == value
        )[0];

        if (selectedOption && selectedOption.icon) {
          icon = selectedOption.icon;
        }
      }
    });

    return (
      <div data-value={value} style={{fontFamily: isFontDropdown ? `${value}, Inter, san-serif` : null}} onMouseOver={() => {
        if (isFontDropdown) {
          ctas.builder.fonts.load_font_family(value);
        }
      }}>
        {icon && <img className="w-[13px] h-auto mr-5" src={icon} />}
        {label}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Select
        classNames={{
          control: () =>
            "setting-select w-full !min-h-[34px] px-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white !rounded-xl border-solid border-[1px] !border-slate-200 dark:!border-slate-700 focus:!border-slate-200 !outline-none !shadow-none",
          dropdownIndicator: () => "!p-[0px]",
          indicatorSeparator: () => "!w-[0px]",
          input: () =>
            "text-black dark:text-white outline-none focus:outline-none focus:ring-0 appearance-none shadow-none",
          menu: () =>
            "w-full !z-50 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700",
          option: ({ isFocused, isSelected, isDisabled }) =>
            `!cursor-pointer !text-black dark:!text-white ${
              isSelected
                ? "!bg-blue-600 !text-white"
                : isFocused
                ? "!bg-slate-100 dark:!bg-slate-800"
                : isDisabled
                ? "!opacity-60" // Apply opacity 0.6 to disabled options
                : ""
            } hover:!text-black active:!text-black focus:!text-black hover:!bg-slate-100 active:!bg-slate-100 focus:!bg-slate-800 dark:hover:!text-white dark:active:!text-white dark:focus:!text-white dark:hover:!bg-slate-800 dark:active:!bg-slate-800 dark:focus:!bg-slate-800`,
          singleValue: () => "text-black dark:text-white",
          multiValue: () => "select-multi-value !bg-white dark:!bg-slate-950 border-solid border-[1px] !border-slate-200 dark:!border-slate-700",
          valueContainer: () => "!p-[0px]",
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        options={options}
        value={selectedOptions || multiSelectedOptions}
        defaultValue={multiSelectedOptions}
        placeholder={props.placeholder}
        isMulti={props.multi_select == true ? true : null}
        formatOptionLabel={formatOptionLabel}
        onChange={(selectedOption) => {
          let old_value = _.get(
            contextData,
            "[objects][variant][" +
              props.object_type +
              "][" +
              props.object_id +
              "]" +
              props.setting_name
          );

          let value;

          if (selectedOption == null) {
            value = null;
          } else if (props.multi_select !== true) {
            value = selectedOption.value;
          } else {
            value = selectedOption.map((option) => option.value).join(",");
          }

          builder.update([
            {
              object_type: props.object_type,
              object_id: props.object_id,
              setting_name: props.setting_name,
              value: value,
              skip_history: props.skip_history,
            },
          ]);

          if (props.callback) {
            props.callback(value, old_value);
          }
        }}
      ></Select>
    </React.Fragment>
  );
};

export default VariantBuilderSettingSelect;
