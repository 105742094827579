import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";

const BroadcastsListToolbar = (props) => {
  const filterForm = useRef(null);
  const groupsInput = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [selectedSort, setSelectedSortby] = useState(null);
  const [filterToggled, setFilterToggled] = useState(false);
  const hasFilter =
    (props.sort_by && props.sort_by !== "created_at_latest") ||
    props.status ||
    props.group_ids;

  function searchInput(e) {
    let timeoutIdTemp;
    clearTimeout(timeoutId);

    timeoutIdTemp = setTimeout(function () {
      filterForm.current.submit();
    }, 1000);
    setTimeoutId(timeoutIdTemp);
  }

  function toggleFilter() {
    setFilterToggled(!filterToggled);
  }

  const sortByOptions = [
    { value: "created_at_latest", label: "Created at (Latest)" },
    { value: "created_at_oldest", label: "Created at (Oldest)" },
    { value: "conversion_score", label: "Conversion Score" },
    { value: "views", label: "Views" },
    { value: "conversions", label: "Conversions" },
    { value: "completions", label: "Completions" },
    { value: "conversion_rate", label: "Conversion Rate" },
    { value: "completion_rate", label: "Completion Rate" },
    { value: "revenue", label: "Revenue" },
  ];

  const selectedSortByOptions = sortByOptions.map((option) => {
    if (
      (!props.sort_by && option.value == "created_at_latest") ||
      props.sort_by == option.value
    ) {
      return option;
    }
  });

  const statusOptions = [
    { value: "", label: "All broadcasts" },
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" },
  ];

  const selectedStatusOption = statusOptions.find((option) => {
    if ((!props.status && option.value == "") || props.status == option.value) {
      return option;
    }
  });

  const [selectedStatus, setSelectedStatus] = useState(selectedStatusOption);

  const groupOptions = props.groups
    ? props.groups.map((group) => {
        return { value: group.id, label: group.name };
      })
    : [];

  const selectedGroupOptions = groupOptions.map((option) => {
    if (
      props.group_ids &&
      props.group_ids.split(",").filter((id) => id == option.value)[0]
    ) {
      return option;
    }
  });

  useEffect(() => {
    $('[data-toggle="popover"]').popover();
  }, [selectedSort]);

  return (
    <React.Fragment>
      <div className="BroadcastsListToolbar row hard-center">
        <div className="col-xs-12">
          <form ref={filterForm}>
            <div className="col-xs-1 no-padding-sides" style={{ width: "375px" }}>
              <div className="col-xs-4 no-padding-sides" style={{ width: "auto", minWidth: "100px" }}>
                <h4 className="bold smooth">
                  <a style={{ color: "inherit" }} href="./broadcasts">
                    Broadcasts
                  </a>
                </h4>
              </div>
              <div className="col-xs-8 pt-3">
                <button
                  className={
                    `btn !py-2 bg-white text-black rounded-lg mr-2 cursor-pointer border-none ${!selectedStatus.value ? "!bg-slate-100" : ""}`
                  }
                  data-type="card"
                  title="Active"
                  onClick={() => {
                    setSelectedStatus({ value: "", label: "All broadcasts" });
                    filterForm.current.submit();
                  }}
                >
                  Recent
                </button>
                <button
                  className={
                    `btn !py-2 bg-white text-black rounded-lg mr-2 cursor-pointer border-none ${selectedStatus.value == 'true' ? "!bg-slate-100" : ""}`
                  }
                  data-type="card"
                  title="Active"
                  onClick={() => {
                    if (selectedStatus.value != 'true') {
                      setSelectedStatus({ value: "true", label: "Active" });
                    } else {
                      setSelectedStatus({ value: "", label: "All broadcasts" });
                    }
                    filterForm.current.submit();
                  }}
                >
                  Active
                </button>
                <button
                  className={
                    `btn !py-2 bg-white text-black rounded-lg mr-2 cursor-pointer border-none ${selectedStatus.value == 'false' ? "!bg-slate-100" : ""}`
                  }
                  data-type="card"
                  title="Active"
                  onClick={() => {
                    if (selectedStatus.value != 'archived') {
                      setSelectedStatus({ value: "false", label: "Inactive" });
                    } else {
                      setSelectedStatus({ value: "", label: "All broadcasts" });
                    }
                    filterForm.current.submit();
                  }}
                >
                  Inactive
                </button>
              </div>
            </div>
            <div className="col-xs-7 no-padding-sides !float-right">
              <div
                className="broadcasts-list-toolbar-column"
                style={{ marginRight: "0px" }}
              >
                <button
                  type="button"
                  className="btn blue-button broadcast-create"
                  data-toggle="modal"
                  data-target="#newBroadcastModal"
                >
                  New +
                </button>
              </div>
              <div className="broadcasts-list-toolbar-column">
                <div className="btn-group">
                  <button
                    type="button"
                    id="broadcast-filter-toggle"
                    className={
                      "btn border-none bg-transparent text-black " +
                      (hasFilter ? "text-blue-600" : undefined)
                    }
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={toggleFilter}
                  >
                    {hasFilter ? "Filtered" : "Filters"}{" "}
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu broadcasts-list-toolbar-filter-dropdown"
                    style={{ display: filterToggled ? "block" : "none" }}
                  >
                    <div className="col-xs-12 some-vertical-space">
                      <li>
                        <span className="field-title">
                          Sort by{" "}
                          {((!selectedSort &&
                            props.sort_by == "conversion_score") ||
                            selectedSort == "conversion_score") && (
                            <span
                              className="glyphicon glyphicon-question-sign"
                              aria-hidden="true"
                              data-container="body"
                              data-toggle="popover"
                              data-placement="top"
                              data-content="Unique viewers multiplied by conversion rate = Conversion score"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "#0089f3",
                              }}
                            ></span>
                          )}
                        </span>
                        <Select
                          name="sort_by"
                          filterOption={createFilter({ ignoreAccents: false })}
                          defaultValue={selectedSortByOptions}
                          options={sortByOptions}
                          className="smart-select"
                          onChange={(option) => {
                            if (option && option.value) {
                              setSelectedSortby(option.value);
                            }
                          }}
                        ></Select>
                      </li>
                      <li>
                        <span className="field-title">Status</span>
                        <Select
                          name="status"
                          filterOption={createFilter({ ignoreAccents: false })}
                          value={selectedStatus}
                          options={statusOptions}
                          className="smart-select"
                          onChange={(option) => {
                            setSelectedStatus(option);
                          }}
                        ></Select>
                      </li>
                      <li>
                        <span className="field-title">Groups</span>
                        <a
                          data-toggle="modal"
                          data-target="#groupsModal"
                          className="smooth semibold text-xl"
                          style={{ float: "right", cursor: "pointer" }}
                        >
                          Manage →
                        </a>
                        <input
                          ref={groupsInput}
                          type="hidden"
                          name="group_ids"
                          defaultValue={props.group_ids}
                        />
                        <Select
                          isMulti
                          defaultValue={selectedGroupOptions}
                          filterOption={createFilter({ ignoreAccents: false })}
                          options={groupOptions}
                          onChange={(selectedOptions) => {
                            if (selectedOptions) {
                              groupsInput.current.value = selectedOptions
                                .map((option) => option.value)
                                .join();
                            }
                          }}
                          className="smart-select"
                        ></Select>
                      </li>
                      <li
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <div className="row hard-center">
                          <div className="col-xs-6 no-padding-sides">
                            <button className="btn btn-clean semibold">
                              <span
                                className="glyphicon glyphicon-search"
                                style={{ fontSize: "13px", marginRight: "5px" }}
                              ></span>{" "}
                              Apply Filters
                            </button>
                          </div>
                          <div className="col-xs-6 no-padding-sides right">
                            <a href="./broadcasts">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-clean semibold"
                              >
                                <span
                                  className="glyphicon glyphicon-remove"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                ></span>{" "}
                                Clear
                              </button>
                            </a>
                          </div>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="broadcasts-list-toolbar-column">
                <input
                  type="text"
                  name="name"
                  className="form-control ml-5 !text-xl !text-slate-500 border-solid !border-b-[1px] !border-slate-200 !rounded-md !bg-transparent !shadow-none"
                  placeholder="Search..."
                  defaultValue={props.name}
                  onChange={searchInput}
                />
                <span className="glyphicon glyphicon-search search"></span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BroadcastsListToolbar;
