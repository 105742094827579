import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import NewContentToggleItem from "../content_toggles/NewContentToggleItem";
import ContentToggleCarouselSettings from "../content_toggles/ContentToggleCarouselSettings";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";

const ElementSettingsCarousel = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  let sorted_list_items = Object.values(variant.content_toggle_items)
    .filter(
      (list_item) =>
        list_item.element_id == element.id && list_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <React.Fragment>
      <div className="col-xs-12">
        <div className="w-full relative text-black dark:text-white">
          <span className="text-xl">Slides:</span>
        </div>
      </div>
      <div
        className="sortable-container col-xs-12"
        data-sortable-name="content-toggle-list-items"
        data-object_type="content_toggle_items"
      >
        {sorted_list_items.map((list_item, i) => {
          return (
            <ContentToggleCarouselSettings
              key={"content_toggle_item-" + list_item.id}
              toggle_item={list_item}
              {...props}
            />
          );
        })}
      </div>

      {element.element_type == "carousel" && (
        <NewContentToggleItem element={element} />
      )}

      <VariantBuilderSetting
        object={element}
        setting_name="[options][carousel-slides-per-view]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Slides per View"}
        value={
          element.options["carousel-slides-per-view"]
            ? element.options["carousel-slides-per-view"]
            : ""
        }
        placeholder="1"
        min={1}
        max={5}
        range_step={"1"}
        allow_empty={true}
        device="desktop"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][carousel-slides-per-view-mobile]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Slides per View"}
        value={
          element.options["carousel-slides-per-view-mobile"]
            ? element.options["carousel-slides-per-view-mobile"]
            : ""
        }
        placeholder="1"
        min={1}
        max={5}
        range_step={"1"}
        allow_empty={true}
        device="mobile"
      />

      <VariantBuilderSetting
        object={element}
        label="Hide Arrow Controls"
        setting_name="[options][carousel-hide-arrow-controls]"
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["carousel-hide-arrow-controls"]
            ? element.options["carousel-hide-arrow-controls"]
            : "false"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Hide Indicator Controls"
        setting_name="[options][carousel-hide-indicator-controls]"
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["carousel-hide-indicator-controls"]
            ? element.options["carousel-hide-indicator-controls"]
            : "false"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Rotation"
        setting_name="[options][carousel-rotation-style]"
        setting_type="dropdown"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["carousel-rotation-style"]
            ? element.options["carousel-rotation-style"]
            : ""
        }
        options={[
          { value: "manual", text: "Manual" },
          { value: "automatic", text: "Automatic" },
        ]}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][carousel-rotation-delay]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Rotation Delay ( seconds )"}
        value={
          element.options["carousel-rotation-delay"]
            ? element.options["carousel-rotation-delay"]
            : ""
        }
        condition={element.options["carousel-rotation-style"] == "automatic"}
        min={1}
        max={60}
        range_step={"1"}
        placeholder={variant.options["carousel-rotation-delay"] || "5"}
        allow_empty={true}
      />
    </React.Fragment>
  );
};

export default ElementSettingsCarousel;
