import React from "react";
import PropTypes from "prop-types";
import BroadcastsGroupsSmallListItem from "../broadcasts_groups/BroadcastsGroupsSmallListItem";

const BroadcastsGroupsSmallList = (props) => {
  let groups = props.broadcast.groups.slice(0, props.trim ? 3 : 6);
  
  return (
    <React.Fragment>
      <div
        data-toggle="modal"
        data-target={"#Broadcast" + props.broadcast.id + "GroupsModal"}
        data-broadcast-id={props.broadcast.id}
        className={"BroadcastsGroupsSmallList row hard-center flex"}
      >
        {groups.map((group) => {
          return (
            <BroadcastsGroupsSmallListItem
              key={"broadcast-" + props.broadcast.id + "-group-" + group.id}
              group={group}
              {...props}
            />
          );
        })}
        <div
          className={"broadcast-group-small-add smooth bold"}
          title="Manage Groups"
        >
          +
        </div>
      </div>
    </React.Fragment>
  );
};

export default BroadcastsGroupsSmallList;
