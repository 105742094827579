import React, { useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import QuizSettings from "../fields/QuizSettings";
import NewQuizField from "../fields/NewQuizField";
import useFields from "../fields/useFields";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import ElementSettingsConfirmation from "./ElementSettingsConfirmation";

const ElementSettingsQuiz = (props) => {
  const wistia = useRef(null);
  const [contextData, builder] = useContext(VariantContextData);
  const website = contextData.objects.website;
  const subscription = contextData.objects.subscription;

  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  const { Fields } = useFields(false);

  let sorted_fields = Object.values(variant.fields)
    .filter(
      (field) =>
        field?.element_id == element.id &&
        field?.toBeDeleted !== true &&
        !field?.field_id
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  useEffect(() => {
    Fields.sortableStart(element);

    // Uses window instead of ContextData for checking because the ContextData might not reflect the most recent state
    if (window.selected_object_tab == "Content" && sorted_fields[0]) {
      dispatchCustomEvent("focusItem", {
        parent_id: element.id,
        item_type: "fields",
        item_id: sorted_fields[0].id
      });
    }
  }, [props.object_id]);

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        setting_name="[options][show-progress-bar]"
        setting_type="checkbox"
        label="Progress Bar"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["show-progress-bar"]
            ? element.options["show-progress-bar"]
            : "true"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Progress Label"
        setting_name="[options][quiz-progress-label]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["quiz-progress-label"]
            ? element.options["quiz-progress-label"]
            : undefined
        }
        condition={element.options["show-progress-bar"] !== "false"}
        placeholder={"Question"}
      />

      <div
        className="col-xs-12 text-xl text-black dark:text-white"
        style={{
          margin: "15px 0px",
          marginBottom: sorted_fields.length == 0 ? "15px" : "0px",
        }}
      >
        Questions
      </div>

      <div className="quiz-fields-container col-xs-12 w-full">
        <div
          className="col-xs-12 setting disabled-field"
          data-setting_type="item_v2"
          data-object_type="fields"
        ></div>

        {sorted_fields.map((field_item, index) => {
          return (
            <React.Fragment key={"quiz-field-item-" + field_item.id}>
              <QuizSettings
                field_item={field_item}
                field_item_position={index + 1}
                {...props}
              />
            </React.Fragment>
          );
        })}

        {element.element_type == "quiz" && (
          <NewQuizField
            element={element}
            payload={{
              field_id: undefined,
              position: sorted_fields.length + 1,
            }}
            {...props}
          />
        )}
      </div>

      <VariantBuilderSetting
        object={element}
        label="Next Button"
        setting_name="[options][next-button-label]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["next-button-label"]
            ? element.options["next-button-label"]
            : undefined
        }
        col={8}
        placeholder={"Next →"}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][hide-next-button]"
        setting_type="checkbox"
        label="Hide"
        object_type="elements"
        object_id={element.id}
        col={4}
        value={
          element.options["hide-next-button"]
            ? element.options["hide-next-button"]
            : "false"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Back Button"
        setting_name="[options][back-button-label]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        col={8}
        value={
          element.options["back-button-label"]
            ? element.options["back-button-label"]
            : undefined
        }
        placeholder={"← Back"}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][hide-back-button]"
        setting_type="checkbox"
        label="Hide"
        object_type="elements"
        col={4}
        object_id={element.id}
        value={
          element.options["hide-back-button"]
            ? element.options["hide-back-button"]
            : "false"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Prefill fields"
        setting_name={"[options][prefill-fields]"}
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["prefill-fields"]
            ? element.options["prefill-fields"]
            : "true"
        }
        tooltip="Prefill fields with data already collected from the visitor and from URL parameters"
        col="6"
        classNames="some-padding-right"
      />


      {(['free', '2024_pages', '2024_core'].includes(subscription.plan_type) || ['2024_pages', '2024_core'].includes(website.plan_type) || website.premium !== true) && (
        <div className="row absolute top-0 bottom-0 right-0 left-0 w-auto h-full p-5" style={{
          backdropFilter: 'blur(4px)'
        }}>
          <div className="row hard-center mt-5 mb-3">
            <div className="col-xs-12 !float-right" style={{ marginTop: "0px", maxWidth: '250px' }}>
              <div className="row hard-center bg-white border-solid border-slate-300 rounded-lg px-7">
                <div className="row hard-center some-vertical-space">
                  <div
                    className="wistia_responsive_padding"
                    data-id="v2oqzh0rhq"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <div
                      className="wistia_responsive_wrapper"
                      style={{
                        height: "100%",
                        left: "0",
                        position: "absolute",
                        top: "0",
                        width: "100%",
                      }}
                      onLoad={() => {
                        $.getScript("https://fast.wistia.com/assets/external/E-v1.js");
                        $.getScript("https://fast.wistia.com/embed/medias/v2oqzh0rhq.jsonp");
                      }}
                    >
                      <div
                        className="wistia_embed wistia_async_v2oqzh0rhq popover=true videoFoam=true"
                        style={{
                          display: "inline-block",
                          height: "100%",
                          position: "relative",
                          width: "100%",
                        }}
                        ref={wistia}
                      >
                        <img
                          src="https://fast.wistia.com/embed/medias/v2oqzh0rhq/swatch"
                          style={{
                            filter: "blur(5px)",
                            height: "100%",
                            objectFit: "contain",
                            width: "100%",
                          }}
                          alt=""
                          aria-hidden="true"
                          onLoad={() => {
                            wistia.current.style.opacity = 1;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row hard-center">
                  <p className="bold">Build quiz funnels</p>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Funnel logic & scores
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Product recommendations
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M3.5981 10.6577L8.62006 2.88562C9.02581 2.25767 10 2.54503 10 3.29266V7.23333C10 7.28856 10.0448 7.33333 10.1 7.33333H13.842C14.3285 7.33333 14.6128 7.8819 14.3322 8.27934L9.36273 15.3195C8.94073 15.9173 8 15.6187 8 14.887V11.6833C8 11.6281 7.95523 11.5833 7.9 11.5833H4.10205C3.62672 11.5833 3.34013 11.0569 3.5981 10.6577Z" fill="#003FFF"/>
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Sync answers to CRMs
                  </div>
                </div>
                <div className="row mb-5 mt-2">
                  <div className="col-xs-12">
                    <button
                      className="btn bg-black !text-white font-bold py-2 px-4"
                      onClick={(event) => {
                        subscriptions.upgrade_modal(
                          event,
                          false,
                          "build quiz funnels.",
                          "2024_pro",
                          "Triggered feature CTA – Quiz"
                        );
                      }}
                    >
                      <svg
                        className="mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M3.30039 4.40019V3.94305C3.30039 2.4236 4.50468 1.2002 6.00039 1.2002C7.49611 1.2002 8.70039 2.4236 8.70039 3.94305V4.40019M3.30039 4.40019C2.80539 4.40019 2.40039 4.81162 2.40039 5.31448V9.88591C2.40039 10.3888 2.80539 10.8002 3.30039 10.8002H8.70039C9.19539 10.8002 9.60039 10.3888 9.60039 9.88591V5.31448C9.60039 4.81162 9.19539 4.40019 8.70039 4.40019M3.30039 4.40019H8.70039"
                          stroke="white"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                        />
                      </svg>
                      Upgrade To Unlock
                    </button>
                    <div className="text-xl mt-3">
                      <a
                        href="https://www.convertflow.com/quizzes"
                        target="_blank"
                      >
                        Learn more →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ElementSettingsQuiz;
