import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";

const CtasListToolbar = (props) => {
  const filterForm = useRef(null);
  const listTypeInput = useRef(null);
  const ctaTypeInput = useRef(null);
  const groupsInput = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [filterToggled, setFilterToggled] = useState(false);
  const hasFilter =
    props.cta_type ||
    (props.sort_by && props.sort_by !== "created_at_latest") ||
    props.status ||
    props.group_ids;

  const statusOptions = [
    { value: "", label: "All campaigns" },
    { value: "active", label: "Active" },
    { value: "archived", label: "Archived" },
  ];

  const selectedStatusOption = statusOptions.find((option) => {
    if ((!props.status && option.value == "") || props.status == option.value) {
      return option;
    }
  });

  const [selectedStatus, setSelectedStatus] = useState(selectedStatusOption);

  function changeListType(listType) {
    listTypeInput.current.value = listType;
    filterForm.current.submit();
  }

  function searchInput(e) {
    let timeoutIdTemp;
    clearTimeout(timeoutId);

    timeoutIdTemp = setTimeout(function () {
      filterForm.current.submit();
    }, 1000);
    setTimeoutId(timeoutIdTemp);
  }

  function toggleFilter() {
    setFilterToggled(!filterToggled);
  }

  const sortByOptions = [
    { value: "created_at_latest", label: "Created at (Latest)" },
    { value: "created_at_oldest", label: "Created at (Oldest)" },
    { value: "published_at_latest", label: "Published at (Latest)" },
    { value: "published_at_oldest", label: "Published at (Oldest)" },
    { value: "conversion_score", label: "Conversion Score" },
    { value: "views", label: "Views" },
    { value: "conversions", label: "Conversions" },
    { value: "completions", label: "Completions" },
    { value: "conversion_rate", label: "Conversion Rate" },
    { value: "completion_rate", label: "Completion Rate" },
    { value: "revenue", label: "Revenue" },
    { value: "aov", label: "AOV" },
  ];

  const selectedSortByOption = sortByOptions.find((option) => {
    if (
      (!props.sort_by && option.value == "created_at_latest") ||
      props.sort_by == option.value
    ) {
      return option;
    }
  });

  const [selectedSort, setSelectedSortby] = useState(selectedSortByOption);
  console.log("selectedSort", selectedSort);

  const ctaTypeOptions = [
    { value: "inline", label: "Embeds" },
    { value: "hook", label: "Hook Popups" },
    { value: "overlay", label: "Overlay Popups" },
    { value: "bar", label: "Sticky Bars" },
    { value: "page", label: "Landing Pages" },
  ];

  const selectedCtaTypeOptions = ctaTypeOptions.map((option) => {
    if (props.cta_type && props.cta_type.indexOf(option.value) > -1) {
      return option;
    }
  });

  const groupOptions = props.groups
    ? props.groups.map((group) => {
        return { value: group.id, label: group.name };
      })
    : [];

  const selectedGroupOptions = groupOptions.map((option) => {
    if (
      props.group_ids &&
      props.group_ids.split(",").filter((id) => id == option.value)[0]
    ) {
      return option;
    }
  });

  useEffect(() => {
    $('[data-toggle="popover"]').popover();
  }, [selectedSort]);

  return (
    <React.Fragment>
      <div className="CtasListToolbar row hard-center">
        <div
          className={`col-xs-12 ${
            props.list_type == "card" ? "no-padding-right" : ""
          }`}
        >
          <form ref={filterForm}>
            <div className="col-xs-6 no-padding-sides">
              <div
                className="col-xs-1 no-padding-sides"
                style={{ width: "auto", minWidth: "100px" }}
              >
                <h4 className="bold smooth">
                  <a style={{ color: "inherit" }} href="./cta">
                    {(props.cta_type + "").indexOf("overlay") > -1
                      ? "Overlay Popup"
                      : (props.cta_type + "").indexOf("hook") > -1
                      ? "Hook Popup"
                      : props.cta_type == "page"
                      ? "Landing Page"
                      : props.cta_type == "inline"
                      ? "Embedded"
                      : props.cta_type == "bar"
                      ? "Sticky Bar"
                      : ""}{" "}
                    Campaigns
                  </a>
                </h4>
              </div>
              <div className="col-xs-6 pt-3">
                {props.website && (
                  <React.Fragment>
                    <button
                      className={`btn !py-2 bg-white text-black rounded-lg mr-2 cursor-pointer border-none ${
                        selectedSortByOption.value == "published_at_latest"
                          ? "!bg-slate-100"
                          : ""
                      }`}
                      data-type="card"
                      title="Recent"
                      onClick={() => {
                        if (
                          selectedSortByOption.value != "published_at_latest"
                        ) {
                          setSelectedSortby({
                            value: "published_at_latest",
                            label: "Published at (Latest)",
                          });
                        } else {
                          setSelectedSortby({
                            value: "created_at_latest",
                            label: "Created at (Latest)",
                          });
                        }
                        filterForm.current.submit();
                      }}
                    >
                      Recent
                    </button>
                    <button
                      className={`btn !py-2 bg-white text-black rounded-lg mr-2 cursor-pointer border-none ${
                        selectedStatus.value == "active" ? "!bg-slate-100" : ""
                      }`}
                      data-type="card"
                      title="Active"
                      onClick={() => {
                        if (selectedStatus.value != "active") {
                          setSelectedStatus({
                            value: "active",
                            label: "Active",
                          });
                        } else {
                          setSelectedStatus({
                            value: "",
                            label: "All campaigns",
                          });
                        }
                        filterForm.current.submit();
                      }}
                    >
                      Active
                    </button>
                    <button
                      className={`btn !py-2 !px-1 bg-white text-black rounded-lg mr-2 cursor-pointer border-none ${
                        selectedStatus.value == "archived"
                          ? "!bg-slate-100"
                          : ""
                      }`}
                      data-type="card"
                      title="Active"
                      onClick={() => {
                        if (selectedStatus.value != "archived") {
                          setSelectedStatus({
                            value: "archived",
                            label: "Archived",
                          });
                        } else {
                          setSelectedStatus({
                            value: "",
                            label: "All campaigns",
                          });
                        }
                        filterForm.current.submit();
                      }}
                    >
                      Archived
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="col-xs-6 no-padding-sides !float-right">
              <div className="ctas-list-toolbar-column !mr-0">
                {props.website && (
                  <a href={`/websites/${props.website?.id}/cta/new`}>
                    <button
                      type="button"
                      className="btn blue-button cta-create"
                    >
                      New +
                    </button>
                  </a>
                )}
              </div>
              <div className="ctas-list-toolbar-column">
                <div className="btn-group">
                  <button
                    type="button"
                    id="cta-filter-toggle"
                    className={`btn border-none bg-transparent text-black ${
                      hasFilter ? "text-blue-600" : ""
                    }`}
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={toggleFilter}
                  >
                    {hasFilter ? "Filtered" : "Filters"}{" "}
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu ctas-list-toolbar-filter-dropdown"
                    style={{ display: filterToggled ? "block" : "none" }}
                  >
                    <div className="col-xs-12 some-vertical-space">
                      <li>
                        <span className="field-title">
                          Sort by{" "}
                          {((!selectedSort &&
                            props.sort_by == "conversion_score") ||
                            selectedSort == "conversion_score") && (
                            <span
                              className="glyphicon glyphicon-question-sign"
                              aria-hidden="true"
                              data-container="body"
                              data-toggle="popover"
                              data-placement="top"
                              data-content="Unique viewers multiplied by conversion rate = Conversion score"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "#0089f3",
                              }}
                            ></span>
                          )}
                        </span>
                        <Select
                          name="sort_by"
                          filterOption={createFilter({ ignoreAccents: false })}
                          value={selectedSort}
                          options={sortByOptions}
                          className="smart-select"
                          onChange={(option) => {
                            if (option && option.value) {
                              setSelectedSortby(option);
                            }
                          }}
                        ></Select>
                      </li>
                      <li>
                        <span className="field-title">Campaign Format</span>
                        <input
                          ref={ctaTypeInput}
                          type="hidden"
                          name="cta_type"
                          defaultValue={props.cta_type}
                        />
                        <Select
                          isMulti
                          defaultValue={selectedCtaTypeOptions}
                          filterOption={createFilter({ ignoreAccents: false })}
                          options={ctaTypeOptions}
                          onChange={(selectedOptions) => {
                            ctaTypeInput.current.value = selectedOptions
                              .map((option) => option.value)
                              .join();
                          }}
                          className="smart-select"
                        ></Select>
                      </li>
                      {props.hide_filters &&
                      props.hide_filters.includes("status") ? (
                        ""
                      ) : (
                        <li>
                          <span className="field-title">Status</span>
                          <Select
                            name="status"
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            value={selectedStatus}
                            options={statusOptions}
                            className="smart-select"
                            onChange={(option) => {
                              setSelectedStatus(option);
                            }}
                          ></Select>
                        </li>
                      )}

                      {props.hide_filters &&
                      props.hide_filters.includes("groups") ? (
                        ""
                      ) : (
                        <li>
                          <span className="field-title">Groups</span>
                          <a
                            data-toggle="modal"
                            data-target="#groupsModal"
                            className="text-xl"
                            style={{ float: "right", cursor: "pointer" }}
                          >
                            Manage →
                          </a>
                          <input
                            ref={groupsInput}
                            type="hidden"
                            name="group_ids"
                            defaultValue={props.group_ids}
                          />
                          <Select
                            isMulti
                            defaultValue={selectedGroupOptions}
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            options={groupOptions}
                            onChange={(selectedOptions) => {
                              if (selectedOptions) {
                                groupsInput.current.value = selectedOptions
                                  .map((option) => option.value)
                                  .join();
                              }
                            }}
                            className="smart-select"
                          ></Select>
                        </li>
                      )}

                      <li
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <div className="row hard-center">
                          <div className="col-xs-6 no-padding-sides">
                            <button className="btn black-button">
                              <span
                                className="glyphicon glyphicon-search"
                                style={{ fontSize: "13px", marginRight: "5px" }}
                              ></span>{" "}
                              Apply Filters
                            </button>
                          </div>
                          <div className="col-xs-6 no-padding-sides right">
                            <a href="./cta">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-clean"
                              >
                                <span
                                  className="glyphicon glyphicon-remove"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                ></span>{" "}
                                Clear
                              </button>
                            </a>
                          </div>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="ctas-list-toolbar-column">
                <input
                  type="text"
                  name="name"
                  className="form-control ml-5 !text-xl !text-slate-500 border-solid !border-b-[1px] !border-slate-200 !rounded-md !bg-transparent !shadow-none"
                  placeholder="Search..."
                  defaultValue={props.name}
                  onChange={searchInput}
                  style={{
                    width: "150px",
                  }}
                />
                <span className="glyphicon glyphicon-search search"></span>
              </div>
              <div className="ctas-list-toolbar-column !mr-0">
                <input
                  ref={listTypeInput}
                  type="hidden"
                  name="list_type"
                  defaultValue={props.list_type}
                />
                <button
                  className={`btn !pt-3 !pb-1 !px-4 bg-white text-black rounded-lg mr-0 cursor-pointer border-none ${
                    !props.list_type || props.list_type == "card"
                      ? "!bg-slate-100"
                      : ""
                  }`}
                  data-type="card"
                  title="Card view"
                  onClick={() => {
                    changeListType("card");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12.6667 2C13.403 2 14 2.58878 14 3.31507L14 5.55995C14 6.28624 13.403 6.87501 12.6667 6.87501H10.6667C9.93029 6.87501 9.33334 6.28624 9.33334 5.55995L9.33333 3.31507C9.33333 2.58877 9.93029 2 10.6667 2L12.6667 2Z"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33333 2C2.59695 2 2 2.58878 2 3.31507L2.00001 5.55995C2.00001 6.28624 2.59696 6.87501 3.33334 6.87501H5.33334C6.06972 6.87501 6.66667 6.28624 6.66667 5.55995L6.66666 3.31507C6.66666 2.58877 6.06971 2 5.33333 2L3.33333 2Z"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.6667 9.12501C13.4031 9.12501 14 9.71378 14 10.4401V12.6849C14 13.4112 13.4031 14 12.6667 14H10.6667C9.93029 14 9.33334 13.4112 9.33334 12.6849L9.33334 10.4401C9.33334 9.71378 9.93029 9.12501 10.6667 9.12501H12.6667Z"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33334 9.12501C2.59696 9.12501 2 9.71378 2.00001 10.4401L2.00001 12.6849C2.00001 13.4112 2.59696 14 3.33334 14H5.33334C6.06972 14 6.66667 13.4112 6.66667 12.6849L6.66667 10.4401C6.66667 9.71378 6.06972 9.12501 5.33334 9.12501H3.33334Z"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  className={`btn !pt-3 !pb-1 !px-4 bg-white text-black rounded-lg mr-0 cursor-pointer border-none ${
                    props.list_type == "row" ? "!bg-slate-100" : ""
                  }`}
                  data-type="list"
                  title="List view"
                  onClick={() => {
                    changeListType("row");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M13.3346 12H2.66797M13.3346 8H2.66797M13.3346 4H2.66797"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CtasListToolbar;
