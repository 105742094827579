var conditions_groups = {};

import initialize from "packs/conditions_groups/initialize.js";
conditions_groups.initialize = initialize;

document.addEventListener("turbolinks:load", function () {
  if ($("body").attr("data-controller") == "conditions_groups") {
    $.cachedScript(
      window.location.origin +
        "/websites/" +
        $("body").attr("data-website-id") +
        "/json.js",
      function () {
        conditions_groups.initialize();
      }
    );
  }
});

export default conditions_groups;
