import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import ReportCount from "../reports/ReportCount";
import ReportChart from "../reports/ReportChart";
import ReportConversionRateChart from "../reports/ReportConversionRateChart";
import ReportFunnelChart from "../reports/ReportFunnelChart";
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";
import Tooltip from "../shared/Tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const BroadcastsReport = (props) => {
  const broadcast = props.broadcast;
  const [metric, setMetric] = useState("conversions");
  const [analysisType, setAnalysisType] = useState("funnel");
  const [timeframeType, setTimeframeType] = useState("All Time");
  const [rangeStartValue, setRangeStartValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_start
  );
  const [rangeEndValue, setRangeEndValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_end
  );
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);
  const [ctaId, setCtaId] = useState(null);

  let group_by = ReportsHelpers.getGroupBy(timeframeType);
  let range_start = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_start;
  let range_end = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_end;

  if (timeframeType == "Date Range") {
    range_start = rangeStartValue;
    range_end = rangeEndValue;
    group_by = ReportsHelpers.getGroupBy(null, range_start, range_end);
  }

  const uniqueViewsReport = (
    <div className="col-xs-2 no-padding-sides">
      <ReportCount
        id={`broadcast-${broadcast.id}-views`}
        label="Viewers"
        tooltip="Number of unique visitors who viewed this campaign"
        path={`/websites/${broadcast.website_id}/events_reports`}
        payload={{
          report_type: "distinct_count",
          report: {
            website_id: props.broadcast.website_id,
            event_type: "View",
            contactable_type: ctaId ? "Cta" : null,
            contactable_id: ctaId ? ctaId : null,
            broadcast_id: broadcast.id,
            range_start: range_start,
            range_end: range_end,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#broadcast-${broadcast.id}-views`,
            `#broadcast-${broadcast.id}-conversions`,
            `#broadcast-${broadcast.id}-conversion-rate`
          );
          updateConversionRate(
            `#broadcast-${broadcast.id}-views`,
            `#broadcast-${broadcast.id}-completions`,
            `#broadcast-${broadcast.id}-completion-rate`
          );

          if (metric == 'add_to_cart') {
            updateConversionRate(
              `#broadcast-${broadcast.id}-views`,
              `#broadcast-${broadcast.id}-added-to-cart`,
              `#broadcast-${broadcast.id}-add-to-cart-rate`
            );
          }

          if (['add_to_cart', 'orders', 'revenue'].includes(metric)) {
            updateConversionRate(
              `#broadcast-${broadcast.id}-views`,
              `#broadcast-${broadcast.id}-orders`,
              `#broadcast-${broadcast.id}-order-rate`
            );
          }
        }}
      />
    </div>
  );

  const conversionsReport = (
    <div className="col-xs-2 no-padding-sides">
      <ReportCount
        id={`broadcast-${broadcast.id}-conversions`}
        label="Conversions"
        tooltip="Number of unique viewers who clicked this campaign"
        path={`/websites/${broadcast.website_id}/events_reports`}
        payload={{
          report_type: "distinct_count",
          report: {
            website_id: broadcast.website_id,
            event_type: "Complete",
            contactable_type: ctaId ? "Cta" : null,
            contactable_id: ctaId ? ctaId : null,
            broadcast_id: broadcast.id,
            range_start: range_start,
            range_end: range_end,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#broadcast-${broadcast.id}-views`,
            `#broadcast-${broadcast.id}-conversions`,
            `#broadcast-${broadcast.id}-conversion-rate`
          );
        }}
      />
    </div>
  );

  const conversionRateReport = (
    <div className="col-xs-2 no-padding-sides">
      <div className="row hard-center">
        <div className="row hard-center">
          <span className="field-title">Conv. Rate</span>
          <Tooltip inline={true} tooltip="Unique viewers compared to unique conversions" />
        </div>
        <div className="row hard-center">
          <h4
            id={`broadcast-${broadcast.id}-conversion-rate`}
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            0.00%
          </h4>
        </div>
      </div>
    </div>
  );

  const completionsReport = (
    <div className="col-xs-2 no-padding-sides">
      <ReportCount
        id={`broadcast-${broadcast.id}-completions`}
        label="Completions"
        tooltip="Number of unique viewers who reached completion"
        path={`/websites/${broadcast.website_id}/events_reports`}
        payload={{
          report_type: "distinct_count",
          report: {
            website_id: broadcast.website_id,
            event_type: "Completion",
            contactable_type: ctaId ? "Cta" : null,
            contactable_id: ctaId ? ctaId : null,
            broadcast_id: broadcast.id,
            range_start: range_start,
            range_end: range_end,
          },
        }}
        callback={() => {
          updateConversionRate(
            `#broadcast-${broadcast.id}-views`,
            `#broadcast-${broadcast.id}-completions`,
            `#broadcast-${broadcast.id}-completion-rate`
          );
        }}
      />
    </div>
  );

  const completionRateReport = (
    <div className="col-xs-2 no-padding-sides">
      <div className="row hard-center">
        <div className="row hard-center">
          <span className="field-title">Comp. Rate</span>
          <Tooltip inline={true} tooltip="Unique viewers compared to unique completions" />
        </div>
        <div className="row hard-center">
          <h4
            id={`broadcast-${broadcast.id}-completion-rate`}
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            0.00%
          </h4>
        </div>
      </div>
    </div>
  );

  const ordersReport = (
    <div className="col-xs-2 no-padding-sides">
      <ReportCount
        id={`broadcast-${broadcast.id}-orders`}
        label="Orders"
        tooltip="Total orders that converted with the campaign and then purchased within 7 days"
        path={`/websites/${props.broadcast.website_id}/events_reports`}
        payload={{
          report_type: "count",
          report: {
            website_id: props.broadcast.website_id,
            range_start: range_start,
            range_end: range_end,
            event_type: "Order",
            contactable_type: ctaId ? "Cta" : null,
            contactable_id: ctaId ? ctaId : null,
            broadcast_id: broadcast.id,
            variant_id: null,
            step_id: null,
            element_id: null,
            product_id: null,
            field: null,
            value: null
          },
        }}
        callback={() => {
          updateConversionRate(
            `#broadcast-${broadcast.id}-views`,
            `#broadcast-${broadcast.id}-orders`,
            `#broadcast-${broadcast.id}-order-rate`
          );

          updateAOV(
            `#broadcast-${broadcast.id}-orders`,
            `#broadcast-${broadcast.id}-revenue`,
            `#broadcast-${broadcast.id}-aov`
          );
        }}
      />
    </div>
  )

  const ordersRateReport = (
    <div className="col-xs-2 no-padding-sides">
      <div className="row hard-center">
        <div className="row hard-center">
          <span className="field-title">Purchase Rate</span>
          <Tooltip inline={true} tooltip="Percentage of viewers who converted and then purchased within 7 days" />
        </div>
        <div className="row hard-center">
          <h4
            id={`broadcast-${broadcast.id}-order-rate`}
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            0.00%
          </h4>
        </div>
      </div>
    </div>
  )

  const revenueReport = (
    <div className="col-xs-2 no-padding-sides">
      <ReportCount
        id={`broadcast-${broadcast.id}-revenue`}
        label="Revenue"
        tooltip="Revenue from orders that converted with the campaign and then purchased within 7 days"
        path={`/websites/${props.broadcast.website_id}/events_reports`}
        payload={{
          report_type: "revenue",
          report: {
            website_id: props.broadcast.website_id,
            range_start: range_start,
            range_end: range_end,
            event_type: "Order",
            contactable_type: ctaId ? "Cta" : null,
            contactable_id: ctaId ? ctaId : null,
            broadcast_id: broadcast.id,
            variant_id: null,
            step_id: null,
            element_id: null,
            product_id: null,
            field: null,
            value: null
          },
        }}
        callback={() => {
          updateAOV(
            `#broadcast-${broadcast.id}-orders`,
            `#broadcast-${broadcast.id}-revenue`,
            `#broadcast-${broadcast.id}-aov`
          );
        }}
        currency={props.currency}
      />
    </div>
  )

  const aovReport = (
    <div className="col-xs-2 no-padding-sides">
      <div className="row hard-center">
        <div
          className="row hard-center">
          <span className="field-title">AOV</span>
          <Tooltip inline={true} tooltip="Average revenue of those who converted with the campaign and then checked out" />
        </div>
        <div className="row hard-center">
          <h4
            className="report-count bold"
            style={{ margin: "5px 0px 0px" }}
          >
            {props.currency}<span id={`broadcast-${broadcast.id}-aov`}>0.00</span>
          </h4>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    $(rangeStartInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(0, 0, 0, 0),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date($(e.currentTarget).find("input").val()).setUTCHours(0, 0, 0, 0);
        const value = new Date(timestamp).toISOString();
        setRangeStartValue(value);
      });

    $(rangeEndInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(23, 59, 59, 999),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date($(e.currentTarget).find("input").val()).setUTCHours(23, 59, 59, 999);
        const value = new Date(timestamp).toISOString();
        setRangeEndValue(value);
      });
  }, [metric, timeframeType, ctaId, rangeStartValue, rangeEndValue]);

  return (
    <React.Fragment>
      <div className="row hard-center smooth BroadcastsReport">
        {props.legacyReports == true &&
          (timeframeType !== "All Time" ||
            analysisType !== "funnel" ||
            metric == "viewers" ||
            metric == "add_to_cart") && (
            <div className="row hard-center" style={{ marginTop: "15px" }}>
              <div className="col-xs-12">
                <div className="col-xs-12 dark alert-blue">
                  <p className="smooth semibold">
                    This broadcast was created before the 2023 analytics update
                    was released. Timeframed reports are limited to 2023 onward.
                  </p>
                </div>
              </div>
            </div>
          )}
        <div className="row hard-center some-vertical-space">
          <div
            className={`col-xs-3 no-padding-right`}
          >
            <h4 className="bold ellipsis">
              Campaign Performance
            </h4>
          </div>
          <div
            className={`col-xs-9`}
          >
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-end"
                ref={rangeEndInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="End"
                  value={rangeEndValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeEndValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-start"
                ref={rangeStartInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="Start"
                  value={rangeStartValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeStartValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-xs-3 timeframe-date-selector">
              <select
                className="text-field"
                style={{ float: "right" }}
                onChange={(event) => setTimeframeType(event.target.value)}
                name="timeframe"
                defaultValue={
                  props.timeframeTypes.filter(
                    (option) => option.selected == true
                  )[0].value
                }
              >
                {props.timeframeTypes.map((option) => (
                  <option
                    value={option.value}
                    key={`timeframe-option-${option.value}`}
                  >
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-xs-3"
              style={{ float: "right", paddingTop: "10px", width: "150px" }}
            >
              <select
                className="text-field"
                name="broadcast_cta"
                onChange={(event) => {
                  setCtaId(event.target.value);
                }}
              >
                {" "}
                <option value="">All campaigns</option>
                {props.broadcast.ctas.map((cta) => (
                  <option key={`cta-${cta.id}`} value={cta.id}>{`${
                    cta.name ? cta.name : `Campaign ${cta.id}`
                  } (${
                    cta.cta_type !== "inline" ? cta.cta_type : "embed"
                  })`}</option>
                ))}
              </select>
            </div>
            <div
              className="col-xs-3 no-padding-left"
              style={{ float: "right", paddingTop: "10px", width: "170px" }}
            >
              <div
                className="btn-group smooth"
                style={{ marginLeft: "auto" }}
                role="group"
              >
                <button
                  type="button"
                  className={`bg-white !pb-2 text-slate-500 rounded-md border-solid border-[1px] border-white ${
                    analysisType == "funnel" && ["viewers", "revenue"].includes(metric) == false
                      ? "!border-slate-200"
                      : ["viewers", "revenue"].includes(metric)
                      ? "disabled"
                      : ""
                  }`}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={`Funnel report`}
                  onClick={() => {
                    if (["viewers", "revenue"].includes(metric) == false) {
                      setAnalysisType("funnel");
                    }
                  }}
                  style={{
                    display: "inline-block",
                    padding: "10px 0px",
                    width: "50px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M4.84615 9H13.1538M3 5.25H15M7.61538 12.75H10.3846"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className={`bg-white !pb-2 text-slate-500 rounded-md border-solid border-[1px] border-white  ${
                    analysisType == "counts" || ["viewers", "revenue"].includes(metric)
                      ? "!border-slate-200"
                      : ""
                  }`}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={`Counts over time`}
                  onClick={() => {
                    setAnalysisType("counts");
                  }}
                  style={{
                    display: "inline-block",
                    padding: "10px 0px",
                    width: "50px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M14.4004 14.3996V12.5996M9.00039 14.3996V8.09961M3.60039 14.3996V3.59961"
                      stroke="#7F8493"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  className={`bg-white !pb-2 text-slate-500 rounded-md border-solid border-[1px] border-white ${
                    analysisType == "performance" && ["viewers", "revenue"].includes(metric) == false
                      ? "!border-slate-200"
                      : ["viewers", "revenue"].includes(metric)
                      ? "disabled"
                      : ""
                  }`}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={`Rates over time`}
                  onClick={() => {
                    if (["viewers", "revenue"].includes(metric) == false) {
                      setAnalysisType("performance");
                    }
                  }}
                  style={{
                    display: "inline-block",
                    padding: "10px 0px",
                    width: "50px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M12.6002 7.1998L9.9002 9.8998L7.6502 7.6498L4.9502 10.3498M14.4002 16.1998C15.3943 16.1998 16.2002 15.3939 16.2002 14.3998V3.5998C16.2002 2.60569 15.3943 1.7998 14.4002 1.7998H3.6002C2.60608 1.7998 1.8002 2.60569 1.8002 3.5998V14.3998C1.8002 15.3939 2.60608 16.1998 3.6002 16.1998H14.4002Z"
                      stroke="#7F8493"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row hard-center">
          <ul
            className="nav nav-tabs !flex reports-tabs border-solid border-0 !border-slate-200 border-b-[1px]"
            role="tab"
          >
            <li
              role="presentation"
              className={`flex-1 text-center ${
                metric == "viewers" ? "active" : ""
              }`}
            >
              <a
                className="text-xl font-semibold metric-tab"
                data-metric="viewers"
                onClick={() => setMetric("viewers")}
              >
                Viewers
              </a>
            </li>
            <li 
              className={`flex-1 text-center ${
                metric == "conversions" ? "active" : ""
              }`}
            >
              <a
                className="text-xl font-semibold metric-tab"
                data-metric="conversions"
                onClick={() => setMetric("conversions")}
              >
                Conversions
              </a>
            </li>
            <li 
              className={`flex-1 text-center ${
                metric == "completions" ? "active" : ""
              }`}
            >
              <a
                className="text-xl font-semibold metric-tab"
                data-metric="completions"
                onClick={() => setMetric("completions")}
              >
                Completions
              </a>
            </li>
            <li
              className={`flex-1 text-center ${
                metric == "add_to_cart" ? "active" : ""
              }`}
            >
              <a
                className="text-xl font-semibold metric-tab"
                data-metric="add_to_cart"
                onClick={() => setMetric("add_to_cart")}
              >
                Added To Cart
              </a>
            </li>
            <li 
              className={`flex-1 text-center ${
                metric == "orders" ? "active" : ""
              }`}
            >
              <a
                className="text-xl font-semibold metric-tab"
                data-metric="orders"
                onClick={() => setMetric("orders")}
              >
                Orders
              </a>
            </li>
            <li 
              className={`flex-1 text-center ${
                metric == "revenue" ? "active" : ""
              }`}
            >
              <a
                className="text-xl font-semibold metric-tab"
                data-metric="revenue"
                onClick={() => setMetric("revenue")}
              >
                Revenue
              </a>
            </li>
          </ul>
        </div>

        {metric == "viewers" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="text-xl text-slate-500 ellipsis">
                  See how many people have viewed your campaign.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                <ReportChart
                  path={`/websites/${broadcast.website_id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    group_by: group_by,
                    report: {
                      website_id: broadcast.website_id,
                      event_type: "View",
                      contactable_type: ctaId ? "Cta" : null,
                      contactable_id: ctaId,
                      broadcast_id: broadcast.id,
                      range_start: range_start,
                      range_end: range_end,
                      variant_id: null,
                      field: null,
                      start_at_first:
                        timeframeType == "All Time" ? true : false,
                    },
                  }}
                  height="200px"
                />
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {completionsReport}
              {completionRateReport}
            </div>
          </React.Fragment>
        )}
        {metric == "conversions" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="text-xl text-slate-500 ellipsis">
                  See how many viewers have clicked a button, survey or form in
                  your campaign.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${broadcast.website_id}/events_reports`}
                    payload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        event_type: "Complete",
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" &&
                          broadcast.conversions != 0
                            ? true
                            : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "View",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            step_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Complete",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${broadcast.website_id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "View",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${broadcast.website_id}/events_reports`}
                    conversionPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "Complete",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {completionsReport}
              {completionRateReport}
            </div>
          </React.Fragment>
        )}

        {metric == "completions" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="text-xl text-slate-500 ellipsis">
                  See how many viewers have been tracked as having completed
                  your broadcast.{" "}
                  <a
                    href="https://help.convertflow.com/article/203-reporting-on-how-your-cta-is-converting#completion"
                    target="_blank"
                  >
                    How to configure →
                  </a>{" "}
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "View",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            step_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Complete",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Completions",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Completion",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${broadcast.website_id}/events_reports`}
                    payload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        event_type: "Completion",
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${broadcast.website_id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "View",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${broadcast.website_id}/events_reports`}
                    conversionPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "Completion",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {completionsReport}
              {completionRateReport}
            </div>
          </React.Fragment>
        )}

        {metric == "add_to_cart" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="text-xl text-slate-500 ellipsis">
                  See how many viewers have added a product to cart from
                  ConvertFlow.{" "}
                  <a
                    href="https://help.convertflow.com/article/347-products-elements-how-to-build-product-recommendations"
                    target="_blank"
                  >
                    How to configure →
                  </a>{" "}
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "View",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            step_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Complete",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Added To Cart",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Add To Cart",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Orders",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Order",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                          }
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${broadcast.website_id}/events_reports`}
                    payload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        event_type: "Add To Cart",
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${broadcast.website_id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "View",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${broadcast.website_id}/events_reports`}
                    conversionPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "Add To Cart",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        step_id: null,
                        field: null,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              <div className="col-xs-2 no-padding-sides">
                <ReportCount
                  id={`broadcast-${broadcast.id}-added-to-cart`}
                  label="Added To Cart"
                  tooltip="Unique viewers who added a product to cart using ConvertFlow"
                  path={`/websites/${broadcast.website_id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    report: {
                      website_id: broadcast.website_id,
                      event_type: "Add To Cart",
                      contactable_type: ctaId ? "Cta" : null,
                      contactable_id: ctaId,
                      broadcast_id: broadcast.id,
                      range_start: range_start,
                      range_end: range_end,
                      field: null,
                    },
                  }}
                  callback={() => {
                    updateConversionRate(
                      `#broadcast-${broadcast.id}-views`,
                      `#broadcast-${broadcast.id}-added-to-cart`,
                      `#broadcast-${broadcast.id}-add-to-cart-rate`
                    );
                  }}
                />
              </div>
              <div className="col-xs-2 no-padding-sides">
                <div className="row hard-center">
                  <div className="row hard-center">
                    <span className="field-title">Add Cart Rate</span>
                    <Tooltip inline={true} tooltip="Unique viewers compared to unique adds to cart" />
                  </div>
                  <div className="row hard-center">
                    <h4
                      id={`broadcast-${broadcast.id}-add-to-cart-rate`}
                      className="report-count bold"
                      style={{ margin: "5px 0px 0px" }}
                    >
                      0.00%
                    </h4>
                  </div>
                </div>
              </div>
              {ordersReport}
              {ordersRateReport}
            </div>
          </React.Fragment>
        )}

        {metric == "orders" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="text-xl text-slate-500 ellipsis">
                  Customers who converted with this broadcast within 7 days before purchasing.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                {analysisType == "funnel" && (
                  <ReportFunnelChart
                    reports={[
                      {
                        label: "Unique Viewers",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "View",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            step_id: null,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Unique Conversions",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "distinct_count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Complete",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                            field: null,
                          },
                        },
                      },
                      {
                        label: "Orders",
                        path: `/websites/${broadcast.website_id}/events_reports`,
                        payload: {
                          report_type: "count",
                          report: {
                            website_id: broadcast.website_id,
                            event_type: "Order",
                            contactable_type: ctaId ? "Cta" : null,
                            contactable_id: ctaId,
                            broadcast_id: broadcast.id,
                            range_start: range_start,
                            range_end: range_end,
                          }
                        },
                      },
                    ]}
                    height="200px"
                  />
                )}
                {analysisType == "counts" && (
                  <ReportChart
                    path={`/websites/${broadcast.website_id}/events_reports`}
                    payload={{
                      report_type: "count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        event_type: "Order",
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
                {analysisType == "performance" && (
                  <ReportConversionRateChart
                    viewPath={`/websites/${broadcast.website_id}/events_reports`}
                    viewPayload={{
                      report_type: "distinct_count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "View",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    conversionPath={`/websites/${props.website_id}/events_reports`}
                    conversionPayload={{
                      report_type: "count",
                      group_by: group_by,
                      report: {
                        website_id: broadcast.website_id,
                        contactable_type: ctaId ? "Cta" : null,
                        contactable_id: ctaId,
                        event_type: "Order",
                        broadcast_id: broadcast.id,
                        range_start: range_start,
                        range_end: range_end,
                        start_at_first:
                          timeframeType == "All Time" ? true : false,
                      },
                    }}
                    height="200px"
                  />
                )}
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {ordersReport}
              {ordersRateReport}
              {revenueReport}
              {aovReport}
            </div>
          </React.Fragment>
        )}

        {metric == "revenue" && (
          <React.Fragment>
            <div
              className="row hard-center"
              style={{ borderBottom: "1px solid rgb(233, 235, 241)" }}
            >
              <div
                className="col-xs-12 vertical-space center"
                style={{ marginBottom: "0px" }}
              >
                <span className="text-xl text-slate-500 ellipsis">
                  Revenue attributed to orders that converted with this broadcast within 7 days before checkout.
                </span>
              </div>
              <div className="col-xs-12 some-vertical-space">
                <ReportChart
                  path={`/websites/${broadcast.website_id}/events_reports`}
                  payload={{
                    report_type: "revenue",
                    group_by: group_by,
                    report: {
                      website_id: broadcast.website_id,
                      event_type: "Order",
                      contactable_type: ctaId ? "Cta" : null,
                      contactable_id: ctaId,
                      broadcast_id: broadcast.id,
                      range_start: range_start,
                      range_end: range_end,
                      start_at_first:
                        timeframeType == "All Time" ? true : false,
                    },
                  }}
                  currency={props.currency}
                  height="200px"
                />
              </div>
            </div>
            <div className="row hard-center vertical-space report-column-wrapper">
              {uniqueViewsReport}
              {conversionsReport}
              {conversionRateReport}
              {ordersReport}
              {ordersRateReport}
              {revenueReport}
              {aovReport}
            </div>
          </React.Fragment>
        )}  
      </div>
    </React.Fragment>
  );
};

export default BroadcastsReport;
