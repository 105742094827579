import React, { useContext } from "react";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import { VariantContextData } from "../variants/VariantBuilderContext";

const QuizAnswerSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  const element = props.element;
  const field_option = props.field_option;
  const field_option_item = field_option.field_id
    ? Object.values(contextData.objects.variant.fields).find(
        (s) => field_option.field_id == s.id
      )
    : field_item;

  return (
    <React.Fragment
      key={`quiz-field-${field_option_item.id}-option-${field_option.id}`}
    >
      <VariantBuilderSetting
        object={field_option}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="field_options"
        object_id={field_option.id}
        title={field_option.label || field_option.value}
        condition={element.element_type == "quiz"}
        style={{ margin: "0px" }}
        position={field_option.position}
        label_prepend={(props.index + 10).toString(36).toUpperCase()}
        label_icon={
          field_option_item.field_type == "icon"
            ? field_option.icon
            : props.quizLabelIcon
            ? props.quizLabelIcon(field_option_item.field_type)
            : ""
        }
        panel_type="modal"
        label_max_length={20}
        label_hover_max_length={16}
        duplicate_payload={{
          field_id: `${field_option_item.id}`,
          label: `${field_option.label} (copy)`,
        }}
      >
        <VariantBuilderSetting
          object={field_option}
          setting_name="[label]"
          label="Answer Label"
          setting_type="text"
          update_on_input_end={true}
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.label ? field_option.label : ""}
          condition={element.element_type == "quiz"}
        />

        <VariantBuilderSetting
          object={field_option}
          setting_name="[value]"
          label="Data Value"
          setting_type="text"
          placeholder={field_option.label}
          update_on_input_end={true}
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.value ? field_option.value : ""}
          condition={element.element_type == "quiz"}
          no_commas={true}
          callback={(new_value, old_value) => {
            Object.values(contextData.objects.variant.conditions)
              .filter(
                (c) =>
                  c.data && c.data["field_option_id"] == field_option.id + ""
              )
              .forEach((condition) => {
                builder.update([
                  {
                    object_type: "field_options",
                    object_id: field_option.id,
                    setting_name: "[value]",
                    value: new_value,
                    old_value: old_value,
                  },
                  {
                    object_type: "conditions",
                    object_id: condition.id,
                    setting_name: "[keyword]",
                    value: new_value,
                    old_value: old_value,
                  },
                ]);
              });
          }}
        />

        <VariantBuilderSetting
          object={field_option}
          label="Image"
          setting_name="[image]"
          setting_type="image"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.image ? field_option.image : ""}
          padding="0px"
          condition={
            element.element_type == "quiz" &&
            field_option_item.field_type === "image"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Icon"
          setting_name="[icon]"
          setting_type="icon"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.icon}
          condition={
            element.element_type == "quiz" &&
            field_option_item.field_type === "icon"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Letter"
          setting_name="[letter]"
          setting_type="text"
          maxLength={1}
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.letter}
          condition={
            element.element_type == "quiz" &&
            field_option_item.field_type === "letter"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Color"
          setting_name="[color]"
          setting_type="color_v2"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.color}
          placeholder="#000"
          condition={
            element.element_type == "quiz" &&
            field_option_item.field_type === "color"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Emoji"
          setting_name="[emoji]"
          setting_type="emoji"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.emoji}
          condition={
            element.element_type == "quiz" &&
            field_option_item.field_type === "emoji"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Score"
          setting_name="[score]"
          setting_type="range"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.score + "" ? field_option.score : ""}
          min={-1000}
          max={1000}
          step={1}
          tooltip={
            "Assigning a score to this field option will increment the visitor's current score for the CTA"
          }
          tooltip_position="right"
          condition={field_option_item.field_type !== "range"}
        />
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default QuizAnswerSettings;
