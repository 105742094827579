import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const SaveAsTemplateButton = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const targetController = () => {
    switch (props.object_type) {
      case "Cta":
        return "templates";
      case "Step":
        return "step_templates";
      case "Section":
        return "section_templates";
      case "Element":
        return "element_templates";
      case "Field":
        return "field_templates";
      default:
    }
  };

  const redirectToNewTemplate = function (e) {
    if (builder.displayFeatureGate(e, props.feature_gate_type)) {
      if (props.unsaved == true) {
        alert(
          "You have unsaved changes. Save your changes to enable saving templates."
        );
      } else {
        const baseRoute = `${window.location.origin}/accounts/${
          props.website.account_id
        }/${targetController()}/new`;
        const routeParams = `?object_type=${props.object_type}&object_id=${props.object.id}`;

        window.open(baseRoute + routeParams, "_blank");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 some-vertical-space setting">
        <button
          onClick={redirectToNewTemplate}
          className={`btn blue-button field-title btn-block btn-lg ${
            props.unsaved == true ? "disabled" : ""
          }`}
          style={{
            color: "#fff",
            border: "1px solid #00b592",
            background: "#00b592",
          }}
        >
          <span
            style={{
              fontSize: "12px",
              marginRight: "10px",
            }}
            className="glyphicon glyphicon-share"
            aria-hidden="true"
          ></span>
          Save as Template
        </button>
      </div>
    </React.Fragment>
  );
};

export default SaveAsTemplateButton;
