import React, { useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderToolbarInvite = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);

  return (
    <React.Fragment>
      <div className="grid grid-flow-col auto-cols-auto pt-2 justify-center mr-5 ">
        <div className="pt-1 order-first z-20">
          <a
            onClick={() => {
              $('#new-user').modal('show');
            }}
            target="_blank"
            title="Invite team members"
            className="cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                fill={!contextData.dark_mode ? "#EFEFEF" : "#1D1F2D"}
              />
              <rect
                x="1"
                y="1"
                width="26"
                height="26"
                rx="13"
                stroke={!contextData.dark_mode ? "white" : "black"}
                strokeLinecap="round"
              />
              <path
                d="M14 9.125L14 18.875M18.875 14L9.125 14"
                stroke={!contextData.dark_mode ? "#959595" : "#A1A8C1"}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </a>
        </div>
        <div className="pt-1 -ml-3 order-1 z-10">
          <a
            href={`${window.location.origin}/accounts/${props.website.account_id}/users`}
            target="_blank"
          >
            <div className="!w-[25px] !h-[25px] mt-[1px] overflow-hidden rounded-full relative">
              <img src={props.current_user.avatar} className="w-full h-auto" />
            </div>
          </a>
        </div>
        {props.users.filter((u) => u.id !== props.current_user.id).slice(0, 1).map((user) => {
          return (
            <div className="pt-1 -ml-3 order-last">
              <a
                href={`${window.location.origin}/accounts/${props.website.account_id}/users`}
                target="_blank"
              >
                <div className="!w-[25px] !h-[25px] mt-[1px] overflow-hidden rounded-full relative">
                  <img src={user.avatar} className="w-full h-auto" />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderToolbarInvite;
