var copy_link = function(button) {

  /* Get the text field */
  var templateLink = $('#template-link')[0];
  templateLink.select();
  document.execCommand("copy");

  $(button).find('span').text('Copied!')

  setTimeout(function() {
    $(button).find('span').text('Link')
  }, 3000);

};

export default copy_link;