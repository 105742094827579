import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import FieldSettings from "../fields/FieldSettings";
import NewField from "../fields/NewField";
import useFields from "../fields/useFields";
import useActions from "../actions/useActions";
import useElements from "../elements/useElements";
import NewActionGroup from "../action_groups/NewActionGroup";
import VariantBuilderPanelBreadcrumb from "../variants/VariantBuilderPanelBreadcrumb";
import ElementSettingsText from "../elements/ElementSettingsText";
import ElementSettingsConfirmation from "../elements/ElementSettingsConfirmation";
import ElementSettingsFields from "../elements/ElementSettingsFields";
import ElementSettingsFormDesign from "../elements/ElementSettingsFormDesign";
import ElementSettingsVideo from "../elements/ElementSettingsVideo";
import ElementSettingsVideoDesign from "../elements/ElementSettingsVideoDesign";
import ElementSettingsImage from "../elements/ElementSettingsImage";
import ElementSettingsImageDesign from "../elements/ElementSettingsImageDesign";
import ElementSettingsDivider from "../elements/ElementSettingsDivider";
import ElementSettingsDividerDesign from "../elements/ElementSettingsDividerDesign";
import ElementSettingsIconDesign from "../elements/ElementSettingsIconDesign";
import ElementSettingsTimer from "../elements/ElementSettingsTimer";
import ElementSettingsTimerDesign from "../elements/ElementSettingsTimerDesign";
import ElementSettingsButtonDesign from "../elements/ElementSettingsButtonDesign";
import ElementSettingsProgress from "../elements/ElementSettingsProgress";
import ElementSettingsProgressDesign from "../elements/ElementSettingsProgressDesign";
import ElementSettingsProducts from "../elements/ElementSettingsProducts";
import ElementSettingsProductsDesign from "../elements/ElementSettingsProductsDesign";
import ElementSettingsProductHeroDesign from "../elements/ElementSettingsProductHeroDesign";
import ElementSettingsProductHero from "../elements/ElementSettingsProductHero";
import ElementSettingsCollapse from "../elements/ElementSettingsCollapse";
import ElementSettingsCollapseDesign from "../elements/ElementSettingsCollapseDesign";
import ElementSettingsCarousel from "../elements/ElementSettingsCarousel";
import ElementSettingsCarouselDesign from "../elements/ElementSettingsCarouselDesign";
import ElementSettingsQuiz from "../elements/ElementSettingsQuiz";
import ElementSettingsQuizDesign from "../elements/ElementSettingsQuizDesign";
import ElementSettingsQuizLayout from "../elements/ElementSettingsQuizLayout";
import ElementSettingsList from "../elements/ElementSettingsList";
import ElementSettingsListDesign from "../elements/ElementSettingsListDesign";
import ElementSettingsTabs from "../elements/ElementSettingsTabs";
import ElementSettingsTabsDesign from "../elements/ElementSettingsTabsDesign";
import ElementSettingsSurveyDesign from "../elements/ElementSettingsSurveyDesign";
import TabGroup from "../shared/TabGroup";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";
import ElementSettingsSurveyOptionsDesign from "./ElementSettingsSurveyDesign";

const ElementSettings = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const tab = contextData.selected_object_tab || "Content";

  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  const elements_with_content = ["collapse", "tabs", "carousel", "quiz"];

  return (
    <React.Fragment>
      <div className="row hard-center">
        <VariantBuilderPanelTitle
          title={`${
            element.element_type == "headline"
              ? "Headline"
              : element.element_type == "text"
              ? "Text"
              : element.element_type == "image"
              ? "Image"
              : element.element_type == "button"
              ? "Button"
              : element.element_type == "html"
              ? "HTML Code"
              : element.element_type == "form"
              ? "Form"
              : element.element_type == "survey"
              ? "Survey"
              : element.element_type == "quiz"
              ? "Quiz"
              : element.element_type == "video"
              ? "Video"
              : element.element_type == "divider"
              ? "Divider"
              : element.element_type == "icon"
              ? "Icon"
              : element.element_type == "list"
              ? "List"
              : element.element_type == "tabs"
              ? "Tabs"
              : element.element_type == "carousel"
              ? "Carousel"
              : element.element_type == "timer"
              ? "Timer"
              : element.element_type == "progress"
              ? "Progress Bar"
              : element.element_type == "products"
              ? "Products Feed"
              : element.element_type == "product_hero"
              ? "Product Hero"
              : element.element_type == "collapse"
              ? "Collapse"
              : element.element_type == "row"
              ? "Row"
              : "Element"
          }`}
          tooltip={`${
            element.element_type == "headline"
              ? "Grab attention with a headline element. Adjust content, styling and layout."
              : element.element_type == "text"
              ? "Deliver your persuasive message with a text element. Adjust content, styling and layout."
              : element.element_type == "image"
              ? "Upload an image, or search Unsplash's library of royalty-free stock photos."
              : element.element_type == "button"
              ? "Display a call-to-action button. Adjust the message, style, and the action that occurs when the button is clicked."
              : element.element_type == "html"
              ? "Add custom HTML code to your page (at your own risk)."
              : element.element_type == "form"
              ? "Add a form to your funnel. Enable contact fields, add custom fields, adjust the style, and the action that occurs when the form is submitted."
              : element.element_type == "survey"
              ? "Survey your visitors and get statistics. Map to a contact field, add answers, adjust the style and the action that occurs when the survey is submitted."
              : element.element_type == "quiz"
              ? "Ask multiple questions with a quiz. Add questions, adjust the style, jump logic, and the action that occurs when the quiz is submitted."
              : element.element_type == "video"
              ? "Embed a video from YouTube, Wistia, Vimeo, or any iframe source."
              : element.element_type == "divider"
              ? "Add a divider to your content. Adjust the style and layout."
              : element.element_type == "icon"
              ? "Display an icon. Adjust the style and layout."
              : element.element_type == "list"
              ? "Display a list of content items. Adjust the style and layout."
              : element.element_type == "tabs"
              ? "Display tabbed content. Add your tabs and drag elements inside of them. Adjust the style and layout."
              : element.element_type == "carousel"
              ? "Display a carousel of content slides. Add your slides and drag elements inside of them. Adjust the style and layout."
              : element.element_type == "timer"
              ? "Display a countdown timer. Adjust the style, layout, and what happens when the timer is finished."
              : element.element_type == "progress"
              ? "Display a bar showing funnel progress, or any custom progress message."
              : element.element_type == "products"
              ? "Display a feed of products from your Shopify store, or add custom products. Adjust the style and layout."
              : element.element_type == "collapse"
              ? "Display collapsible content. Add your collapses and drag elements inside of them. Adjust the style and layout."
              : "Element"
          }`}
          tooltipLabel="Learn"
          object_type="elements"
          object_id={element.id}
          object={element}
        />

        <TabGroup
          tabs={
            ["button", "form", "survey", "quiz", "products", "product_hero"].includes(
              element.element_type
            )
              ? ["Content", "Actions", "Design"]
              : ["Content", "Design"]
          }
          tab={tab}
          setActiveTab={(tab) => {
            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
              tab: tab,
            });
          }}
          className="mb-6"
        >
          {tab == "Content" && (
            <React.Fragment>
              <div className="row">
                <VariantBuilderSetting
                  object={element}
                  label="Text"
                  setting_name="[html]"
                  setting_type="editor"
                  object_type="elements"
                  object_id={element.id}
                  value={element.html}
                  condition={["headline", "text", "button"].includes(
                    element.element_type
                  )}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Icon"
                  setting_name="[options][fa-icon]"
                  setting_type="icon"
                  object_type="elements"
                  object_id={element.id}
                  value={element.options["fa-icon"]}
                  condition={["icon"].includes(element.element_type)}
                />

                <VariantBuilderSetting
                  object={element}
                  setting_name="[html]"
                  setting_type="image"
                  object_type="elements"
                  object_id={element.id}
                  label="Image URL"
                  value={
                    element.html && element.html.indexOf("img") > -1
                      ? $(element.html).find("img").attr("src")
                        ? $(element.html).find("img").attr("src")
                        : $(element.html).attr("src")
                      : ""
                  }
                  condition={element.element_type == "image"}
                  inline_image={true}
                  inline_image_selector={
                    '.cf-element[data-element-id="' + element.id + '"] img'
                  }
                />

                {element.element_type == "video" && (
                  <ElementSettingsVideo {...props} />
                )}
                {element.element_type == "timer" && (
                  <ElementSettingsTimer {...props} />
                )}
                {element.element_type == "divider" && (
                  <ElementSettingsDivider {...props} />
                )}
                {["form", "survey"].includes(element.element_type) && (
                  <ElementSettingsFields {...props} />
                )}
                {["list"].includes(element.element_type) && (
                  <ElementSettingsList {...props} />
                )}
                {element.element_type == "progress" && (
                  <ElementSettingsProgress {...props} />
                )}

                {element.element_type == "products" && (
                  <ElementSettingsProducts {...props} />
                )}

                {element.element_type == "product_hero" && (
                  <ElementSettingsProductHero {...props} />
                )}

                {element.element_type == "collapse" && (
                  <ElementSettingsCollapse {...props} />
                )}

                {element.element_type == "tabs" && (
                  <ElementSettingsTabs {...props} />
                )}
                {element.element_type == "carousel" && (
                  <ElementSettingsCarousel {...props} />
                )}

                {element.element_type == "quiz" && (
                  <ElementSettingsQuiz {...props} />
                )}

                {element.element_type == "image" && (
                  <ElementSettingsImage {...props} />
                )}

                <VariantBuilderSetting
                  object={element}
                  label="Outer Alignment"
                  setting_name="[options][cf-alignment]"
                  setting_type="alignment"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["cf-alignment"]
                      ? element.options["cf-alignment"]
                      : "cf-center"
                  }
                  valueMappings={{
                    left:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-left"
                        : "cf-outer-left",
                    center:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-center"
                        : "cf-outer-center",
                    right:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-right"
                        : "cf-outer-right",
                  }}
                  condition={
                    ["html", "quiz"].includes(element.element_type) == false
                  }
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Outer Alignment"
                  setting_name="[options][cf-alignment-mobile]"
                  setting_type="alignment"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["cf-alignment-mobile"]
                      ? element.options["cf-alignment-mobile"]
                      : ""
                  }
                  valueMappings={{
                    left:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-mobile-left"
                        : "cf-mobile-outer-left",
                    center:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-mobile-center"
                        : "cf-mobile-outer-center",
                    right:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-mobile-right"
                        : "cf-mobile-outer-right",
                  }}
                  condition={
                    ["html", "quiz"].includes(element.element_type) == false
                  }
                  device="mobile"
                />

                <VariantBuilderSetting
                  object={step}
                  setting_name="[html]"
                  setting_type="code"
                  label="HTML Editor"
                  object_type="elements"
                  object_id={element.id}
                  value={element.html ? element.html : ""}
                  condition={[
                    "text",
                    "headline",
                    "button",
                    "form",
                    "html",
                    "progress",
                  ].includes(element.element_type)}
                  feature_gate_type="Custom HTML"
                />
              </div>
            </React.Fragment>
          )}

          {tab == "Actions" && <ElementSettingsConfirmation {...props} />}

          {tab == "Design" && (
            <React.Fragment>
              <VariantBuilderPanelCollapse name={`Layout`} open={true}>
                <VariantBuilderSetting
                  object={element}
                  setting_name="[options][SPACING_TYPE-"
                  setting_type="spacing"
                  object_type="elements"
                  object_id={element.id}
                  label="Spacing"
                  note="Click to increase and reduce spacing"
                  device="desktop"
                >
                  <div
                    className="row hard-center"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Outer margin
                      </div>

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-left]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-left"]}
                        placeholder="0px"
                        col="3"
                        note="Left"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-top]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-top"]}
                        placeholder="0px"
                        col="3"
                        note="Top"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-bottom]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-bottom"]}
                        placeholder="0px"
                        col="3"
                        note="Bottom"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-right]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-right"]}
                        placeholder="0px"
                        col="3"
                        note="Right"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />
                    </div>
                  </div>

                  <div
                    className="row hard-center"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Inner padding
                      </div>

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-left]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={builder.helpers.convertLegacyPadding(
                          element.options["padding-left"]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Left"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-top]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={builder.helpers.convertLegacyPadding(
                          element.options["padding-top"]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Top"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-bottom]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={builder.helpers.convertLegacyPadding(
                          element.options["padding-bottom"]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Bottom"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-right]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={builder.helpers.convertLegacyPadding(
                          element.options["padding-right"]
                        )}
                        placeholder="0px"
                        col="3"
                        note="Right"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />
                    </div>
                  </div>
                </VariantBuilderSetting>

                <VariantBuilderSetting
                  object={element}
                  setting_name="[options][SPACING_TYPE-mobile-"
                  setting_type="spacing"
                  object_type="elements"
                  object_id={element.id}
                  label="Outer Margin & Inner Padding"
                  note="Click to increase and reduce spacing"
                  device="mobile"
                >
                  <div
                    className="row hard-center"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Outer margin{" "}
                        <span
                          className="glyphicon glyphicon-phone tooltip-icon"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Mobile only"
                          aria-hidden="true"
                          style={{
                            color: "#00b593",
                            fontSize: "14px",
                          }}
                        ></span>
                      </div>

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-mobile-left]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-mobile-left"]}
                        placeholder="0px"
                        col="3"
                        note="Left"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-mobile-top]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-mobile-top"]}
                        placeholder="0px"
                        col="3"
                        note="Top"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-mobile-bottom]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-mobile-bottom"]}
                        placeholder="0px"
                        col="3"
                        note="Bottom"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][margin-mobile-right]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={element.options["margin-mobile-right"]}
                        placeholder="0px"
                        col="3"
                        note="Right"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />
                    </div>
                  </div>

                  <div
                    className="row hard-center"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Inner padding{" "}
                        <span
                          className="glyphicon glyphicon-phone tooltip-icon"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Mobile only"
                          aria-hidden="true"
                          style={{
                            color: "#00b593",
                            fontSize: "14px",
                          }}
                        ></span>
                      </div>

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-mobile-left]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-mobile-left"]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-left"]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Left"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-mobile-top]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-mobile-top"]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-top"]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Top"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-mobile-bottom]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-mobile-bottom"]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-bottom"]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Bottom"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />

                      <VariantBuilderSetting
                        object={element}
                        setting_name="[options][padding-mobile-right]"
                        setting_type="text"
                        numeric_with_percentage={true}
                        object_type="elements"
                        object_id={element.id}
                        value={
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-mobile-right"]
                          ) ||
                          builder.helpers.convertLegacyPadding(
                            element.options["padding-right"]
                          )
                        }
                        placeholder="0px"
                        col="3"
                        note="Right"
                        classNames="spacing-inputs"
                        allow_empty={true}
                      />
                    </div>
                  </div>
                </VariantBuilderSetting>

                <VariantBuilderSetting
                  object={element}
                  label="Hide on desktop"
                  setting_name="[options][hide-desktop]"
                  setting_type="checkbox"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["hide-desktop"]
                      ? element.options["hide-desktop"]
                      : "false"
                  }
                  condition={
                    element.options["hide-mobile"] !== "true" ||
                    (element.options["hide-desktop"] == "true" &&
                      element.options["hide-mobile"] == "true")
                  }
                />

                <VariantBuilderSetting
                  object={element}
                  label="Hide on mobile"
                  setting_name="[options][hide-mobile]"
                  setting_type="checkbox"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["hide-mobile"]
                      ? element.options["hide-mobile"]
                      : "false"
                  }
                  condition={element.options["hide-desktop"] !== "true"}
                />

                <VariantBuilderSetting
                  object={element}
                  object_type="elements"
                  object_id={element.id}
                  setting_name="[options][max-width]"
                  setting_type="range"
                  value={
                    element.options["max-width"]
                      ? element.options["max-width"]
                      : undefined
                  }
                  placeholder={"2560"}
                  label="Max Width"
                  min={1}
                  max={2560}
                  step={1}
                  allow_empty={true}
                  device="desktop"
                  show_reset={true}
                  condition={["headline", "text"].includes(
                    element.element_type
                  )}
                />

                <VariantBuilderSetting
                  object={element}
                  object_type="elements"
                  object_id={element.id}
                  setting_name="[options][max-width-mobile]"
                  setting_type="range"
                  value={
                    element.options["max-width-mobile"]
                      ? element.options["max-width-mobile"]
                      : undefined
                  }
                  placeholder={"2560"}
                  label="Max Width"
                  min={1}
                  max={2560}
                  step={1}
                  allow_empty={true}
                  device="mobile"
                  show_reset={true}
                  condition={["headline", "text"].includes(
                    element.element_type
                  )}
                />

                {element.element_type == "quiz" && (
                  <ElementSettingsQuizLayout {...props} />
                )}

                <VariantBuilderSetting
                  object={element}
                  label="Outer Alignment"
                  setting_name="[options][cf-alignment]"
                  setting_type="alignment"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["cf-alignment"]
                      ? element.options["cf-alignment"]
                      : "cf-center"
                  }
                  valueMappings={{
                    left:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-left"
                        : "cf-outer-left",
                    center:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-center"
                        : "cf-outer-center",
                    right:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-right"
                        : "cf-outer-right",
                  }}
                  condition={
                    contextData.device == "desktop" &&
                    ["html", "quiz"].includes(element.element_type) == false
                  }
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Outer Alignment"
                  setting_name="[options][cf-alignment-mobile]"
                  setting_type="alignment"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["cf-alignment-mobile"]
                      ? element.options["cf-alignment-mobile"]
                      : ""
                  }
                  valueMappings={{
                    left:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-mobile-left"
                        : "cf-mobile-outer-left",
                    center:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-mobile-center"
                        : "cf-mobile-outer-center",
                    right:
                      element.element_type !== "form" &&
                      !elements_with_content.includes(element.element_type)
                        ? "cf-mobile-right"
                        : "cf-mobile-outer-right",
                  }}
                  condition={
                    contextData.device == "mobile" &&
                    ["html", "quiz"].includes(element.element_type) == false
                  }
                  device="mobile"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Inner Alignment"
                  setting_name="[options][cf-inner-alignment]"
                  setting_type="alignment"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["cf-inner-alignment"]
                      ? element.options["cf-inner-alignment"]
                      : "cf-center"
                  }
                  valueMappings={{
                    left: "cf-left",
                    center: "cf-center",
                    right: "cf-right"
                  }}
                  condition={element.element_type == "product_hero"}
                  device="desktop"
                />

                <VariantBuilderSetting
                  object={element}
                  label="Inner Alignment"
                  setting_name="[options][cf-inner-alignment-mobile]"
                  setting_type="alignment"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["cf-inner-alignment-mobile"]
                      ? element.options["cf-inner-alignment-mobile"]
                      : ""
                  }
                  valueMappings={{
                    left: "cf-left",
                    center: "cf-center",
                    right: "cf-right"
                  }}
                  condition={element.element_type == "product_hero"}
                  device="mobile"
                />
              </VariantBuilderPanelCollapse>

              {["headline", "text"].includes(element.element_type) && (
                <ElementSettingsText {...props} />
              )}

              {element.element_type == "form" && (
                <ElementSettingsFormDesign {...props} />
              )}

              {element.element_type == "survey" && (
                <ElementSettingsSurveyDesign {...props} />
              )}

              {element.element_type == "video" && (
                <ElementSettingsVideoDesign {...props} />
              )}
              {element.element_type == "image" && (
                <ElementSettingsImageDesign {...props} />
              )}
              {element.element_type == "progress" && (
                <ElementSettingsProgressDesign {...props} />
              )}

              {element.element_type == "icon" && (
                <ElementSettingsIconDesign {...props} />
              )}

              {element.element_type == "collapse" && (
                <ElementSettingsCollapseDesign {...props} />
              )}

              {element.element_type == "carousel" && (
                <ElementSettingsCarouselDesign {...props} />
              )}

              {element.element_type == "quiz" && (
                <ElementSettingsQuizDesign {...props} />
              )}

              {element.element_type == "tabs" && (
                <ElementSettingsTabsDesign {...props} />
              )}

              {element.element_type == "list" && (
                <ElementSettingsListDesign {...props} />
              )}

              {element.element_type == "products" && (
                <ElementSettingsProductsDesign {...props} />
              )}

              {element.element_type == "product_hero" && (
                <ElementSettingsProductHeroDesign {...props} />
              )}

              {element.element_type == "button" && (
                <ElementSettingsButtonDesign {...props} />
              )}

              {element.element_type == "divider" && (
                <ElementSettingsDividerDesign {...props} />
              )}

              {element.element_type == "timer" && (
                <ElementSettingsTimerDesign {...props} />
              )}

              <VariantBuilderPanelCollapse
                name={`Shadow`}
                open={false}
                condition={[
                  "form",
                  "text",
                  "headline",
                  "image",
                  "video",
                  "progress",
                ].includes(element.element_type)}
              >
                <VariantBuilderSetting
                  object={element}
                  label="Drop Shadow"
                  setting_name="[options][box-shadow]"
                  setting_type="shadow"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["box-shadow"]
                      ? element.options["box-shadow"]
                      : "none"
                  }
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][box-shadow-horizontal-distance]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  label={"Shadow Horizontal Distance"}
                  value={
                    element.options["box-shadow-horizontal-distance"]
                      ? element.options["box-shadow-horizontal-distance"]
                      : "0"
                  }
                  condition={element.options["box-shadow"] == "custom"}
                  min={-100}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][box-shadow-vertical-distance]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  label={"Shadow Vertical Distance"}
                  value={
                    element.options["box-shadow-vertical-distance"]
                      ? element.options["box-shadow-vertical-distance"]
                      : "0"
                  }
                  condition={element.options["box-shadow"] == "custom"}
                  min={-100}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][box-shadow-blur-radius]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  label={"Shadow Blur Radius"}
                  value={
                    element.options["box-shadow-blur-radius"]
                      ? element.options["box-shadow-blur-radius"]
                      : "0"
                  }
                  condition={element.options["box-shadow"] == "custom"}
                  min={0}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={section}
                  setting_name="[options][box-shadow-spread-radius]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  label={"Shadow Spread Radius"}
                  value={
                    element.options["box-shadow-spread-radius"]
                      ? element.options["box-shadow-spread-radius"]
                      : "0"
                  }
                  condition={
                    element.options["box-shadow"] == "custom" &&
                    ["text", "headline"].includes(element.element_type) == false
                  }
                  min={0}
                  max={100}
                  range_step={"1"}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Shadow Color"
                  setting_name="[options][box-shadow-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["box-shadow-color"]
                      ? element.options["box-shadow-color"]
                      : "rgba(0,0,0, 0.35)"
                  }
                  condition={element.options["box-shadow"] == "custom"}
                />
              </VariantBuilderPanelCollapse>

              <VariantBuilderPanelCollapse name={`Custom Code`} open={false}>
                <VariantBuilderSetting
                  object={element}
                  setting_name="[options][custom-class]"
                  label="Custom Classes"
                  placeholder="Custom class names here"
                  setting_type="text"
                  object_type="elements"
                  object_id={element.id}
                  value={element.options["custom-class"]}
                  alphanumeric={true}
                  update_on_input_end={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="HTML/Anchor ID"
                  setting_type="message"
                  object_type="elements"
                  object_id={element.id}
                  value={"#element" + element.id}
                  condition={element.element_type !== "row"}
                />

                <VariantBuilderSetting
                  object={element}
                  label="CSS Selector"
                  setting_type="message"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    "#element" +
                    element.id +
                    " ." +
                    (element.element_type == "survey"
                      ? "cf-survey"
                      : element.element_type == "button"
                      ? "cf-button"
                      : element.element_type == "form"
                      ? "cf-form"
                      : ["headline", "text"].includes(element.element_type)
                      ? "cf-text-element"
                      : element.element_type == "image"
                      ? "cf-image"
                      : element.element_type == "html"
                      ? "cf-html"
                      : element.element_type == "timer"
                      ? "cf-timer"
                      : element.element_type == "video"
                      ? "cf-video"
                      : ["products", "product_hero"].includes(element.element_type)
                      ? "cf-products-outer"
                      : element.element_type == "list"
                      ? "cf-list"
                      : element.element_type == "collapse"
                      ? "cf-collapse-element"
                      : element.element_type == "progress"
                      ? "cf-progress"
                      : element.element_type == "icon"
                      ? "cf-icon-element"
                      : element.element_type == "tabs"
                      ? "cf-tabs-element"
                      : element.element_type == "carousel"
                      ? "cf-carousel-element"
                      : element.element_type == "quiz"
                      ? "cf-quiz-element"
                      : element.element_type == "divider"
                      ? "cf-divider"
                      : "")
                  }
                  condition={element.element_type !== "row"}
                  tooltip="Using custom CSS? Make sure to add !important to the end of each of your CSS properties."
                />
              </VariantBuilderPanelCollapse>
            </React.Fragment>
          )}
        </TabGroup>
      </div>
    </React.Fragment>
  );
};

export default ElementSettings;
