var contacts = {
  initialize: function () {
    var website_id = $("#contacts").attr("data-website-id");
    var website_path = window.location.origin + "/websites/" + website_id;

    contacts.displayVariantOptions();

    $("#contactable_id").on("change", function () {
      if ($(this).val() == "") {
        $("#event_type").val("");
        $("#event_type").parent().hide();
      } else {
        $("#event_type").val("Complete");
        $("#event_type").parent().show();
      }
      contacts.displayVariantOptions();
    });

    $("#contact-filter").on("submit", function (e) {
      e.preventDefault();
      var cta_id = $("#contactable_id").val();
      var variant_id = $("#variant_id").val();

      if ($("#contactable_id").val() == "") {
        var event_type = "";
      } else {
        var event_type = $("#event_type").val();
      }
      if (cta_id != 0 && event_type.length > 0) {
        var url = `${website_path}/contacts/?contactable_type=Cta&contactable_id=${cta_id}&variant_id=${variant_id ? variant_id : ''}&event_type=${event_type ? event_type : ''}`;
      } else {
        var url = website_path + "/contacts/?";
      }

      $.each(
        [
          "timeframe", 
          "name",
          "last_name",
          "email",
          "phone",
          "city",
          "country",
          "company_name",
          "company_title",
          "url",
          "utm_source",
          "utm_medium",
          "utm_campaign",
          "utm_term",
          "utm_content",
          "referral_source",
          "first_visit",
          "source_url",
          "marketing_consent",
          "sms_consent",
          "privacy_consent",
          "ip_address",
        ],
        function (i, utm) {
          if (
            $('#contact-filter input[name="' + utm + '"]').attr("type") !==
            "checkbox"
          ) {
            var value = $('#contact-filter [name="' + utm + '"]').val();
            value = value.replace("+", "%2B");
          } else {
            if ($('#contact-filter input[name="' + utm + '"]').is(":checked")) {
              var value = true;
            } else {
              var value = "";
            }
          }
          url = url + "&" + utm + "=" + value;
        }
      );
      window.location = url;
    });

    var export_link = website_path + "/contacts";
    var contacts_count = parseInt($("#contacts").attr("data-count"));

    if (contacts_count == 1) {
      var export_link =
        export_link +
        ".csv" +
        window.location.search.replace("&delayed_export=true", "");
    } else {
      var export_link = export_link + location.search;
      if (export_link.indexOf("?") == -1) {
        var export_link = export_link + "?";
      }
      var export_link = export_link + "&delayed_export=true";
    }
    $("#export-link").attr("href", export_link);

    $("#contact-filter-button").on("click", function () {
      $("#contact-filter-submit").trigger("click");
    });

    if (window.location.search.indexOf("delayed_export=true") > -1) {
      window.history.replaceState(
        {},
        document.title,
        "contacts" + window.location.search.replace("&delayed_export=true", "")
      );
    }
  },

  displayVariantOptions: function() {
    const contactable_id = $("#contactable_id").val();
    const variantFilter = $('.contacts-filter[data-column="variant_id"]');

    $(variantFilter).hide();
    $('#variant_id').find('option[value!=""]').hide();

    if (contactable_id) {
      $(variantFilter).show();
      $('#variant_id').find('option[data-cta-id="' + contactable_id + '"]').show();
    }
  },

  makeFilterFullHeight: function () {
    var navHeight = 65;

    $("#contact-filter-container").css(
      "height",
      $(window).height() - navHeight - 60 - 150 + "px"
    );
    $(".contact-search").css(
      "height",
      $(window).height() - (navHeight + 1) + "px"
    );

    if ($(window).scrollTop() > navHeight) {
      $(".contact-search").css("position", "fixed");
      $(".contact-search").css("height", "101%");
    } else {
      var difference = navHeight - $(window).scrollTop();
      $(".contact-search").css("position", "fixed");
      $(".contact-search").css(
        "height",
        $(window).height() - difference + "px"
      );
    }

    $(window).on("scroll", function () {
      if ($(window).scrollTop() > navHeight) {
        $(".contact-search").css("position", "fixed");
        $(".contact-search").css("height", "101%");
      } else {
        var difference = navHeight - $(window).scrollTop();
        $(".contact-search").css("position", "fixed");
        $(".contact-search").css(
          "height",
          $(window).height() - difference + "px"
        );
      }
    });
  },
};

document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "contacts" &&
    $("body").attr("data-action") == "index"
  ) {
    contacts.initialize();
    contacts.makeFilterFullHeight();
  }
});

export default contacts;
