var edit = function (cta) {
  var cta_path =
    window.location.origin + "/websites/" + cta.website_id + "/cta/" + cta.id;
  $('[data-toggle="tooltip"]').tooltip();

  $(".create-variant").on("click", function () {
    $("#new-variant").modal("show");
  });

  $("#name-edit").on("submit", function (e) {
    e.preventDefault();

    $.ajax({
      type: "POST",
      url: cta_path + "/update",
      data: {
        cta: {
          name: $("#name-edit-field").val(),
        },
      },
    });

    return false;
  });

  $("#name-edit input").on("change", function () {
    $("#name-edit").submit();
  });

  $("#testing-type").on("submit", function (e) {
    e.preventDefault();
    var variants = {};

    $(".percentage").each(function (index, input) {
      var variant_id = $(input).attr("data-id");
      var percentage = $(input).val();
      variants[variant_id] = percentage;
    });

    $.ajax({
      type: "POST",
      url: cta_path + "/update",
      data: {
        cta: {
          test_type: $("#split-testing-type").val(),
        },
      },
    });
  });

  $("#split-testing-type").on("change", function () {
    $("#testing-type").submit();
  });

  $("#reset").on("click", function (e) {
    e.preventDefault();

    if (
      confirm(
        "Are you sure you want to reset this CTA's analytics? All of your CTA and variant stats will be set to 0"
      )
    ) {
      window.location = $(this).attr("href");
    }
  });

  $("#page-url").on("submit", function (e) {
    e.preventDefault();
    $.ajax({
      type: "PUT",
      url: cta_path,
      data: $("#page-url").serialize(),
      dataType: "JSONP",
      complete: function (data) {
        $("#path-save").hide();
        if (data !== undefined && data.responseText) {
          var data = $.parseJSON(data.responseText);
          if (data !== undefined && data.message == "Page path already taken") {
            $("#page-path-taken").show();
            if (data.cta_in_use) {
              $("#page-path-taken p").html(
                "This page path has already been taken by " +
                  '<a style="color: inherit !important; text-decoration: underline !important;" href="' +
                  window.location.origin +
                  "/websites/" +
                  data.cta_in_use.website_id +
                  "/cta/" +
                  data.cta_in_use.id +
                  '/launch" target="_blank">' +
                  (data.cta_in_use.name
                    ? data.cta_in_use.name
                    : "CTA " + data.cta_in_use.id) +
                  " →</a>"
              );
            }
          }
        }
      },
    });

    return false;
  });

  const pageUrlInput = document.getElementById("page-url");

  if (pageUrlInput) {
    const externalIntegrationType = pageUrlInput.getAttribute(
      "data-external_integration_type"
    );
    const pageUrlSelect = $('#page-url select[name="cta[domain_id]"]');
    const startValue = $(pageUrlSelect).val();

    $(pageUrlSelect).on("change", function (event) {
      var link =
        "https://" +
        $(this).find("option:selected").text() +
        "/" +
        $('#page-url input[name="cta[page_path]"]').val();

      $(".preview-link").each(function (i, preview_link) {
        var broadcast_id = $(this).attr("data-broadcast-id");

        if (broadcast_id) {
          link = link + "/?broadcast_id=" + broadcast_id;
        }

        $(preview_link).text(link);
      });

      $("#page-path-taken").hide();
      $("#path-save").show();
    });

    $("#page-url").on("submit", function (event) {
      event.preventDefault();
      const value = $(pageUrlSelect).val();

      if (
        !externalIntegrationType ||
        (value !== externalIntegrationType &&
          confirm(
            `Are you sure you want to unsync and remove this page from ${externalIntegrationType}?`
          ))
      ) {
        if (value == "Shopify") {
          $(
            ".cta-page-settings[data-group='open-graph'], .cta-page-settings[data-group='index']"
          ).hide();
          $(".cta-page-settings[data-group='shopify']").show();
        } else {
          $(
            ".cta-page-settings[data-group='open-graph'], .cta-page-settings[data-group='index']"
          ).show();
          $(".cta-page-settings[data-group='shopify']").hide();
        }
        return true;
      } else {
        $(pageUrlSelect).val(startValue);
      }
    });

    $('#page-url input[name="cta[page_path]"]').on("keyup", function () {
      $(this).val(function (_, v) {
        return v.replace(/[^a-zA-Z0-9-]/, "").toLowerCase();
      });

      var link =
        "https://" +
        $('#page-url select[name="cta[domain_id]"]')
          .find("option:selected")
          .text() +
        "/" +
        $('#page-url input[name="cta[page_path]"]').val();

      $(".preview-link").each(function (i, preview_link) {
        var broadcast_id = $(this).attr("data-broadcast-id");

        if (broadcast_id) {
          link = link + "/?broadcast_id=" + broadcast_id;
        }
        $(preview_link).text(link);
      });

      $("#page-path-taken").hide();
      $("#path-save").show();
    });

    $(".preview-link").on("click", function () {
      window.open($(this).text(), "_blank");
    });
  }

  $("#seo_index").on("click", function () {
    $("#cta_seo_index").click();
  });

  $("#shopify_header_switch").click(function () {
    $("#shopify_header").click();
  });

  $("#shopify_header").on("change", function () {
    if ($(this).is(":checked")) {
      $("[name='cta[options][shopify-header]']").val("true");
    } else {
      $("[name='cta[options][shopify-header]']").val("false");
    }
  });

  $("#shopify_footer_switch").click(function () {
    $("#shopify_footer").click();
  });

  $("#shopify_footer").on("change", function () {
    if ($(this).is(":checked")) {
      $("[name='cta[options][shopify-footer]']").val("true");
    } else {
      $("[name='cta[options][shopify-footer]']").val("false");
    }
  });

  $("#changeCTAType form").on("submit", function (e) {
    e.preventDefault();

    if (
      confirm(
        "Are you sure you want to change this CTA's type? This will effect how it displays on your website"
      )
    ) {
      $(this)
        .find('input[type="submit"]')
        .attr("value", "Processing...")
        .addClass("disabled");

      setTimeout(function () {
        location.reload();
      }, 3000);

      return true;
    }
  });
};

export default edit;
