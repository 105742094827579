import React from "react";
import VariantBuilderPanelBreadcrumb from "../variants/VariantBuilderPanelBreadcrumb";
import Tooltip from "../shared/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderPanelTitle = (props) => {
  return (
    <React.Fragment>
      <div
        className="row pt-[20px] sticky z-20 bg-white dark:bg-slate-950 top-0 text-2xl py-3 text-black dark:text-white border-solid border-0 border-b-[1px] border-b-slate-100 dark:border-b-slate-800"
        style={{ borderRadius: "5px 5px 0px 0px" }}
      >
        {props.parent_object && (
          <div
            className="col-xs-12 text-black dark:text-white text-lg text-left mb-5 cursor-pointer"
            onClick={() => {
              if (props.parent_object.type) {
                dispatchCustomEvent("selectObject", {
                  object_type: props.parent_object.type,
                  object_id: props.parent_object.id,
                });
              }
              if (props.parent_object.callback) {
                props.parent_object.callback();
              }
            }}
          >
            <FontAwesomeIcon icon="fas fa-angle-left" /> {props.parent_object.label}
          </div>
        )}
        <div className="col-xs-12">
          <span className="font-semibold">{props.title}</span>
          {props.tooltip ? (
            <Tooltip
              className="right-7"
              label={props.tooltipLabel}
              tooltip={props.tooltip}
            />
          ) : (
            ""
          )}
        </div>
        {props.object_type && (
          <div className="col-xs-12">
            <VariantBuilderPanelBreadcrumb
              object_type={props.object_type}
              object_id={props.object_id}
              object={props.object}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderPanelTitle;
