var uploads = {};

import select, {} from 'packs/uploads/select.js';
uploads.select = select;

import transform, {} from 'packs/uploads/transform.js';
uploads.transform = transform;

import callback, {} from 'packs/uploads/callback.js';
uploads.callback = callback;

export default uploads;