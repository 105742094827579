class ConvertflowAdapter {
  constructor(source_product) {
    this.source = "Convertflow";
    this.source_product = source_product;

    this.id = this.productId();
    this.handle = this.productHandle();
    this.available = this.productAvailable();
    this.title = this.productTitle();
    this.description = this.productDescription();
    this.image = this.productImage();
    this.price = this.productPrice();
    this.options = this.productOptions();
    this.variants = this.productVariants() || [];
    this.compare_at_price = this.productCompareAtPrice();
    this.redirect_url = this.productRedirectUrl();
    this.subscriptions = this.productSubscriptions();
  }

  productId() {
    return this.source_product?.id;
  }

  productHandle() {
    return undefined;
  }

  productAvailable() {
    return true;
  }

  productTitle() {
    return this.source_product?.title;
  }

  productDescription() {
    return this.source_product?.description;
  }

  productImage() {
    return this.source_product?.image;
  }

  productPrice() {
    return (this.source_product?.discounted_price || this.source_product?.price);
  }

  productCompareAtPrice() {
    if (!this.source_product?.discounted_price) return;

    return this.source_product?.price * 100;
  }

  productOptions() {
    return undefined;
  }

  productStockQuantity() {
    return undefined;
  }

  productRedirectUrl() {
    return this.source_product?.redirect_url;
  }

  productSubscriptions() {
    return undefined;
  }

  productVariants() {
    if (!this.source_product?.product_variants) return undefined;

    let variantsOutput = [this];

    Object.values(this.source_product?.product_variants).filter(
      (productVariant) =>
        productVariant.product_id == this.source_product?.id &&
        productVariant.toBeDeleted !== true
    ).forEach((productVariant) => {
      variantsOutput.push(new ConvertflowAdapter(productVariant));
    });

    return variantsOutput.sort((a, b) => {
      return a.position - b.position;
    });
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ConvertflowAdapter = ConvertflowAdapter;
}
