import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingNumber = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [defaultValue, setDefaultValue] = useState(
    props.value ? props.value : ""
  );

  const [timeoutId, setTimeoutId] = useState(null);

  function updateSetting(value) {
    let timeoutIdTemp;

    setDefaultValue(value);
    clearTimeout(timeoutId);

    timeoutIdTemp = setTimeout(function () {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: props.setting_name,
          value: value,
          skip_history: props.skip_history,
        },
      ]);
    }, 1000);

    setTimeoutId(timeoutIdTemp);
  }

  useEffect(() => {
    setDefaultValue(props.value);
  }, [props.object_id]);

  return (
    <React.Fragment>
      <input
        type="number"
        setting_name={props.setting_name}
        className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
        value={defaultValue}
        placeholder={props.placeholder}
        onChange={(e) => {
          if (e.target.value) {
            let value = parseFloat(e.target.value);
            if (props.min && value < props.min) {
              value = props.min;
            } else if (props.max && value > props.max) {
              value = props.max;
            }
            e.target.value = value;
            if (
              (!props.min || value >= props.min) &&
              (!props.max || value <= props.max)
            ) {
              updateSetting(value);
            }
          } else {
            if (props.allow_empty == true) {
              updateSetting("");
            } else {
              setDefaultValue("");
            }
          }
        }}
        min={props.min}
        max={props.max}
      />
    </React.Fragment>
  );
};

export default VariantBuilderSettingNumber;
