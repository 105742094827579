import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import VariantBuilderSettingFontAwesome from "../variants/VariantBuilderSettingFontAwesome";
import htmlToLabel from "../../utils/htmlToLabel";

const FieldOptionSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  let field = props.field;
  let field_option = props.field_option;
  let element = contextData.objects.variant.elements[field.element_id];
  let website = contextData.objects.website;
  const isRangeIncrement = field.field_type == "range" && field.options["range-type"] == "increments" ;

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={field_option}
        setting_name={"field-option-modal-trigger-" + field_option.id}
        setting_type="item_v2"
        object_type="field_options"
        object_id={field_option.id}
        title={htmlToLabel(field_option.label && !isRangeIncrement ? field_option.label : `${field_option.value}`)}
        style={{
          marginBottom: "0px",
        }}
        position={field_option.position ? field_option.position : ""}
        panel_type={"modal"}
        duplicate_payload={{
          field_id: field.id
        }}
        draggable={isRangeIncrement ? 'false' : null}
        hide_duplicate={isRangeIncrement}
      >
        <VariantBuilderSetting
          object={field_option}
          label="Label"
          setting_name="[label]"
          setting_type={field.field_type == "survey" ? "editor" : "text"}
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.label ? field_option.label : ""}
          tooltip="The field option label is the text displayed to the visitor in the field option"
          tooltip_position="right"
          condition={field.field_type !== "range"}
        />

        <VariantBuilderSetting
          object={field_option}
          label="Data value"
          setting_name="[value]"
          setting_type={field.field_type !== "range" ? "text" : "number"}
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.value ? field_option.value : ""}
          tooltip="The field option value is the data value that is stored when the field option is selected"
          tooltip_position="right"
          no_commas={true}
          skip_history={true}
          callback={(new_value, old_value) => {
            Object.values(contextData.objects.variant.conditions)
              .filter(
                (c) =>
                  c.data && c.data["field_option_id"] == field_option.id + ""
              )
              .forEach((condition) => {
                builder.update([
                  {
                    object_type: "field_options",
                    object_id: field_option.id,
                    setting_name: "[value]",
                    value: new_value,
                    old_value: old_value,
                  },
                  {
                    object_type: "conditions",
                    object_id: condition.id,
                    setting_name: "[keyword]",
                    value: new_value,
                    old_value: old_value,
                  },
                ]);
              });
          }}
        />

        <VariantBuilderSetting
          object={field_option}
          setting_name="[visual_type]"
          label="Visual Type"
          setting_type="dropdown"
          options={[
            { value: "empty", text: "None" },
            { value: "image", text: "Image" },
            { value: "letter", text: "Letter" },
            { value: "icon", text: "Icon" },
            { value: "emoji", text: "Emoji" },
            { value: "color", text: "Color" },
          ]}
          object_type="field_options"
          object_id={field_option.id}
          value={
            field_option.visual_type
              ? field_option.visual_type
              : field_option.visual_type === null &&
                field_option.image?.length > 0
              ? "image"
              : "empty"
          }
          condition={element.element_type == "survey"}
        />

        <VariantBuilderSetting
          object={field_option}
          label="Option image"
          setting_name="[image]"
          setting_type="image"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.image ? field_option.image : ""}
          condition={
            element.element_type == "survey" &&
            (field_option.visual_type === "image" ||
              (field_option.visual_type === null &&
                field_option.image?.length > 0))
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Icon"
          setting_name="[icon]"
          setting_type="icon"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.icon}
          condition={
            element.element_type == "survey" &&
            field_option.visual_type === "icon"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Letter"
          setting_name="[letter]"
          setting_type="text"
          maxLength={1}
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.letter}
          condition={
            element.element_type == "survey" &&
            field_option.visual_type === "letter"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Color"
          setting_name="[color]"
          setting_type="color_v2"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.color}
          placeholder="#000"
          condition={
            element.element_type == "survey" &&
            field_option.visual_type === "color"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Emoji"
          setting_name="[emoji]"
          setting_type="emoji"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.emoji}
          condition={
            element.element_type == "survey" &&
            field_option.visual_type === "emoji"
          }
        />

        <VariantBuilderSetting
          object={field_option}
          label="Score"
          setting_name="[score]"
          setting_type="range"
          object_type="field_options"
          object_id={field_option.id}
          value={field_option.score + "" ? field_option.score : ""}
          min={-1000}
          max={1000}
          step={1}
          tooltip={
            "Assigning a score to this field option will increment the visitor's current score for the CTA"
          }
          tooltip_position="right"
          condition={field.field_type !== "range"}
        />
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default FieldOptionSettings;
