class OkendoClient {
  constructor(props) {
    this.endpoint = `https://api.okendo.io/v1/stores/${props.app_key}`;

    this.clientOptions = {
      headers: { "Content-Type": "application/json" },
    };
  }

  getProductReviews = async (productId) => {
    return new Promise((resolve, reject) => fetch(
      `${this.endpoint}/products/shopify-${productId}/review_aggregate`, this.clientOptions
    ).then(response => {
      response.json().then(resp => {
        resolve(resp.reviewAggregate)
      }).catch(reject);
    }).catch(reject));
  }
}

if (typeof window !== "undefined" && typeof window.convertflow == "undefined") {
  window.OkendoClient = OkendoClient;
}
