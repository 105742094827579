const variantCustomFieldsArray = (website, variant, contextData, only_fields_in_use) => {
  let customFields = [];
  let cfFields = {
    label: "Saved Custom Fields",
    options: [],
  };

  Object.values(variant.fields).forEach(function (field) {
    if (
      field.slug !== "New Field" &&
      field.slug !== "" &&
      cfFields.options.filter((option) => option.value == field.slug)[0] ==
        undefined &&
      (!field.service ||
        field.service == "ConvertFlow" ||
        !website.integrations ||
        Object.values(website.integrations).map(function (integration) {
          if (integration.integration_type == field.service) return integration;
        }).length == 0)
    ) {
      cfFields.options.push({
        value: field.slug,
        label: field.slug,
        icon: contextData.logo,
      });
    }
  });

  customFields.push(cfFields);

  Object.values(website.integrations)
    .filter((integration) => integration.features.includes("contact"))
    .sort((a, b) => a.integration_type.localeCompare(b.integration_type))
    .forEach(function (integration) {
      let serviceFields = {
        label: integration.integration_type,
        options: [],
      };

      if (integration.api_data["properties"]) {
        Object.values(integration.api_data["properties"]).forEach(function (
          property
        ) {
          let property_option = {
            value: property.id,
            label: property.name,
            plan_tier: integration.plan_tier,
            icon: `/images/integrations/${
              integration.icon ? integration.icon.split("/").pop() : null
            }`,
          };

          property_option.integration_id = integration.id;
          property_option.property_id = property.id;
          if (property.options !== undefined) {
            property_option.field_type = "select";
          } else {
            property_option.field_type = "text";
          }

          if (!only_fields_in_use || Object.values(variant.fields).filter((field) => field.slug == property.id)[0]) {
            serviceFields.options.push(property_option);
          }
        });
      } else if (!only_fields_in_use) {
        serviceFields.options.push({
          label: `${integration.integration_type} automatically receives ConvertFlow's saved custom fields. Save fields above!`,
          isDisabled: true,
          icon: `/images/integrations/${
            integration.icon ? integration.icon.split("/").pop() : null
          }`,
        });
      }
      customFields.push(serviceFields);
    });


  return customFields;
};

export default variantCustomFieldsArray;
