import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderPanelCollapse = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [collapseStatus, setCollapseStatus] = useState(null);
  let collapseOpen = collapseStatus !== null ? collapseStatus : props.open;

  useEffect(() => {
    if (props.open !== undefined) {
      setCollapseStatus(props.open);
    }
  }, [builder.selected().object_id, contextData.selected_object_collapse]);

  return (
    <React.Fragment>
      {(props.condition == undefined || props.condition == true) && (
        <div
          data-name={props.name}
          className={`panel-collapse col-xs-12 no-padding-sides w-full tab-row mt-4 pb-3 border-solid border-0 !border-b-[1px] border-slate-100 dark:border-slate-800`}
        >
          <div
            className={`relative tab-toggle w-full py-3 cursor-pointer`}
            onClick={() =>
              setCollapseStatus(collapseStatus == true ? false : true)
            }
          >
            <div className={`text-xl font-semibold text-black dark:text-white`}>
              {props.name}
              <div className="float-right">
                <FontAwesomeIcon
                  icon={collapseOpen ? "angle-down" : "angle-right"}
                />
              </div>
            </div>
          </div>
          <div
            className={`tab-settings row pt-2 pb-5`}
            style={{ display: collapseOpen ? "block" : "none" }}
          >
            {props.children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default VariantBuilderPanelCollapse;
