import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import FieldOptionSettings from "../field_options/FieldOptionSettings";
import NewFieldOptions from "../field_options/NewFieldOption";
import useFieldOptions from "../field_options/useFieldOptions";
import useElements from "../elements/useElements";
import FieldSettingsSlugMapping from "../fields/FieldSettingsSlugMapping";

const FieldSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { Elements } = useElements();
  const { FieldOptions } = useFieldOptions();

  const field = props.field;
  const element = contextData.objects.variant.elements[field.element_id];
  const website = contextData.objects.website;
  const cta = contextData.objects.cta;
  const variant = contextData.objects.variant;

  let field_options = Object.values(contextData.objects.variant.field_options)
    .filter(
      (field_option) =>
        field_option.field_id == field.id && field_option.toBeDeleted !== true
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  useEffect(() => {
    FieldOptions.sortableStart(field);
  });

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={field}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="fields"
        object_id={field.id}
        title={
          field.label && field.label !== "Empty Field"
            ? field.label
            : field.slug
            ? field.slug
            : `field_${field.id}`
        }
        condition={element.element_type == "form"}
        style={{
          marginBottom: "0px",
        }}
        position={field.position ? field.position : ""}
        panel_type={"modal"}
        callbackOnRemove={(field) => {
          if (element && element.element_type == "form") {
            Elements.updateFieldCount(element);
          }
        }}
        classNames={element.element_type == "form" ? "mt-[15px] ml-[15px] !pr-[96px]" : ""}
      >
        <FieldSettingsSlugMapping field={field} />

        <VariantBuilderSetting
          object={field}
          label="Field Type"
          setting_name="[field_type]"
          setting_type="dropdown"
          object_type="fields"
          object_id={field.id}
          value={field.field_type ? field.field_type : ""}
          options={[
            { value: "", text: "Select a field type", disabled: true },
            { value: "text", text: "Text field" },
            { value: "dropdown", text: "Dropdown" },
            { value: "select", text: "Radio/Checkbox Select" },
            { value: "paragraph", text: "Paragraph" },
            { value: "number", text: "Number" },
            { value: "range", text: "Range" },
            { value: "hidden", text: "Hidden" },
          ]}
          callback={(new_value) => {
            Elements.updateFieldCount(element);
          }}
          condition={field.slug !== null}
        />
        <VariantBuilderSetting
          key={`field-${field.id}-label`}
          object={field}
          label="Field Label"
          setting_name="[label]"
          setting_type="text"
          object_type="fields"
          object_id={field.id}
          value={field.label ? field.label : ""}
          placeholder="Field Label"
          condition={
            (field.slug && field.field_type && field.field_type !== "hidden") ==
            true
          }
        />
        <VariantBuilderSetting
          object={field}
          label="Field Placeholder"
          setting_name="[placeholder]"
          setting_type="text"
          object_type="fields"
          object_id={field.id}
          value={field.placeholder ? field.placeholder : ""}
          placeholder="Placeholder text"
          condition={
            (field.slug &&
              field.field_type &&
              ["text", "paragraph", "dropdown", "number"].includes(
                field.field_type
              )) == true
          }
        />

        <VariantBuilderSetting
          object={field}
          label="Minimum Characters"
          setting_name="[options][minimum-characters]"
          setting_type="number"
          object_type="fields"
          object_id={field.id}
          col="6"
          value={
            field.options["minimum-characters"]
              ? field.options["minimum-characters"]
              : ""
          }
          placeholder="0"
          allow_empty={true}
          condition={
            (field.slug &&
              field.field_type &&
              ["text", "paragraph"].includes(field.field_type)) == true
          }
        />

        <VariantBuilderSetting
          object={field}
          label="Maximum Characters"
          setting_name="[options][maximum-characters]"
          setting_type="number"
          object_type="fields"
          object_id={field.id}
          col="6"
          value={
            field.options["maximum-characters"]
              ? field.options["maximum-characters"]
              : ""
          }
          placeholder="255"
          allow_empty={true}
          condition={
            (field.slug &&
              field.field_type &&
              ["text", "paragraph"].includes(field.field_type)) == true
          }
        />

        <VariantBuilderSetting
          object={field}
          label="Numerical value type"
          setting_name="[options][range-value-type]"
          setting_type="dropdown"
          object_type="fields"
          object_id={field.id}
          value={
            field.options["range-value-type"]
              ? field.options["range-value-type"]
              : "integer"
          }
          condition={(field.slug && field.field_type == "range") == true}
          options={[
            { value: "integer", text: "Integer" },
            { value: "percentage", text: "Percentage" },
            { value: "currency", text: "Currency" },
          ]}
        />

        <VariantBuilderSetting
          object={field}
          setting_name="[currency-message]"
          setting_type="html"
          object_type="fields"
          object_id={field.id}
          condition={
            (field.slug &&
              field.field_type == "range" &&
              field.options["range-value-type"] == "currency") == true
          }
        >
          <div className="row hard-center" style={{ marginTop: "-15px" }}>
            <p
              className="light smooth"
              style={{ margin: "0", fontSize: "15px" }}
            >
              <strong>Currency:</strong> {website.currency_character}
              <a
                style={{ float: "right" }}
                href={`/websites/${website.id}/edit`}
                target="_blank"
              >
                Edit →
              </a>
            </p>
          </div>
        </VariantBuilderSetting>

        <VariantBuilderSetting
          object={field}
          label="Numerical range type"
          setting_name="[options][range-type]"
          setting_type="dropdown"
          object_type="fields"
          object_id={field.id}
          value={
            field.options["range-type"]
              ? field.options["range-type"]
              : "minimum/maximum"
          }
          condition={(field.slug && field.field_type == "range") == true}
          options={[
            { value: "minimum/maximum", text: "Minimum/Maximum" },
            { value: "increments", text: "Increments" },
          ]}
        />

        {["dropdown", "select"].includes(field.field_type) && (
          <React.Fragment>
            <div className="col-xs-12 w-full relative mt-5 text-black dark:text-white">
              <span className="text-xl">Answers:</span>
            </div>
            <div
              className="col-xs-12 sortable-container"
              data-sortable-name="field-options"
              data-object_type="fields"
              data-object_id={field.id}
            >
              {field_options.map((field_option, i) => {
                return (
                  <React.Fragment key={"field-option-" + field_option.id}>
                    <FieldOptionSettings
                      field={field}
                      field_option={field_option}
                      {...props}
                    />
                  </React.Fragment>
                );
              })}
            </div>
            <NewFieldOptions field={field} />
          </React.Fragment>
        )}

        {field.field_type == "range" &&
          field.options["range-type"] == "increments" && (
            <React.Fragment>
              <div
                className="col-xs-12 sortable-container mt-5"
                data-sortable-name="field-options"
                data-object_type="fields"
                data-object_id={field.id}
                style={{
                  padding: "0",
                }}
              >
                <div className="col-xs-12">
                  <p className="field-title">Increments</p>
                </div>
                <div className="col-xs-12">
                {field_options
                  .filter((option) => isNumeric(option.value))
                  .map((field_option, i) => {
                    return (
                      <React.Fragment key={"field-option-" + field_option.id}>
                        <FieldOptionSettings
                          field={field}
                          field_option={field_option}
                          {...props}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <NewFieldOptions field={field} default_value={field_options.filter((option) => isNumeric(option.value)).length + 1} />
            </React.Fragment>
          )}

        <VariantBuilderSetting
          object={field}
          label="Limit selection to increments"
          setting_name="[options][limit-to-increments]"
          setting_type="checkbox"
          object_type="fields"
          object_id={field.id}
          value={
            field.options["limit-to-increments"]
              ? field.options["limit-to-increments"]
              : "true"
          }
          condition={
            (field.slug &&
              field.field_type == "range" &&
              field.options["range-type"] == "increments") == true
          }
          tooltip="Toggle this off to allow for inputting numbers between the pre-defined increments"
        />

        <VariantBuilderSetting
          object={field}
          label="Increment"
          setting_name="[options][increment]"
          setting_type="number"
          object_type="fields"
          object_id={field.id}
          value={field.options["increment"] ? field.options["increment"] : "1"}
          condition={
            (field.slug &&
              field.field_type == "range" &&
              field.options["range-type"] !== "increments") == true
          }
        />

        <VariantBuilderSetting
          object={field}
          label="Minimum value"
          setting_name="[options][minimum]"
          setting_type="number"
          object_type="fields"
          object_id={field.id}
          value={field.options["minimum"] ? field.options["minimum"] : "0"}
          condition={
            (field.slug &&
              ["range", "number"].includes(field.field_type) &&
              (field.field_type == "number" ||
                field.options["range-type"] !== "increments")) == true
          }
          col="6"
        />

        <VariantBuilderSetting
          object={field}
          label="Maximum value"
          setting_name="[options][maximum]"
          setting_type="number"
          object_type="fields"
          object_id={field.id}
          value={field.options["maximum"] ? field.options["maximum"] : "1000"}
          condition={
            (field.slug &&
              ["range", "number"].includes(field.field_type) &&
              (field.field_type == "number" ||
                field.options["range-type"] !== "increments")) == true
          }
          col="6"
        />

        <VariantBuilderSetting
          object={field}
          label="Display minimum/maximum labels"
          setting_name="[options][range-labels]"
          setting_type="checkbox"
          object_type="fields"
          object_id={field.id}
          value={
            field.options["range-labels"]
              ? field.options["range-labels"]
              : "false"
          }
          condition={
            (field.slug &&
              field.field_type == "range" &&
              field.options["range-type"] !== "increments") == true
          }
        />

        <VariantBuilderSetting
          object={field}
          label="Minimum text"
          setting_name="[options][range-label-minimum]"
          setting_type="text"
          object_type="fields"
          object_id={field.id}
          placeholder={
            field.options["minimum"] ? field.options["minimum"] : "0"
          }
          value={
            field.options["range-label-minimum"]
              ? field.options["range-label-minimum"]
              : undefined
          }
          condition={
            (field.slug &&
              field.field_type == "range" &&
              field.options["range-type"] !== "increments" &&
              field.options["range-labels"] == "true") == true
          }
          col="6"
        />

        <VariantBuilderSetting
          object={field}
          label="Maximum text"
          setting_name="[options][range-label-maximum]"
          setting_type="text"
          object_type="fields"
          object_id={field.id}
          placeholder={
            field.options["maximum"] ? field.options["maximum"] : "1000"
          }
          value={
            field.options["range-label-maximum"]
              ? field.options["range-label-maximum"]
              : undefined
          }
          condition={
            (field.slug &&
              field.field_type == "range" &&
              field.options["range-type"] !== "increments" &&
              field.options["range-labels"] == "true") == true
          }
          col="6"
        />

        <VariantBuilderSetting
          object={field}
          label="Display minimum/maximum handles"
          setting_name="[options][multiple-handles]"
          setting_type="checkbox"
          object_type="fields"
          object_id={field.id}
          value={
            field.options["multiple-handles"]
              ? field.options["multiple-handles"]
              : "false"
          }
          condition={(field.slug && field.field_type == "range") == true}
          tooltip="This will allow the visitor to input the range minimum and range maximum"
        />

        <VariantBuilderSetting
          object={field}
          label="Multi-Select"
          setting_name="[multi_select]"
          setting_type="checkbox"
          object_type="fields"
          object_id={field.id}
          value={field.multi_select ? field.multi_select : false}
          condition={
            (field.slug &&
              field.field_type == "select" &&
              field.required + "" !== "true") == true
          }
          tooltip="Toggle this setting to enable the ability to select multiple options. Values will be comma separated."
        />
        <VariantBuilderSetting
          object={field}
          label="Prefilled Value"
          setting_name="[value]"
          setting_type="text"
          object_type="fields"
          object_id={field.id}
          value={field.value ? field.value : ""}
          condition={
            (field.slug &&
              field.field_type &&
              field.field_type !== "dropdown" &&
              field.field_type !== "range") == true
          }
          placeholder="Value to prefill field with"
        />
        <VariantBuilderSetting
          object={field}
          label="Prefill with URL parameter data"
          setting_name="[url_params]"
          setting_type="text"
          object_type="fields"
          object_id={field.id}
          value={field.url_params ? field.url_params : ""}
          condition={
            (field.slug &&
              field.field_type &&
              field.field_type !== "dropdown" &&
              field.field_type !== "range") == true
          }
          placeholder="Comma separated parameter names"
          tooltip="Map data from URL parameters to prefill this field. Field will always map data from parameter named as custom field data name"
        />
        <VariantBuilderSetting
          object={field}
          label="Lines for writing"
          setting_name="[rows]"
          setting_type="dropdown"
          object_type="fields"
          object_id={field.id}
          value={field.rows ? field.rows : "4"}
          condition={(field.slug && field.field_type == "paragraph") == true}
          options={[
            { value: "1", text: "1 lines" },
            { value: "2", text: "2 lines" },
            { value: "3", text: "3 lines" },
            { value: "4", text: "4 lines" },
            { value: "5", text: "5 lines" },
            { value: "6", text: "6 lines" },
            { value: "7", text: "7 lines" },
            { value: "8", text: "8 lines" },
            { value: "9", text: "9 lines" },
            { value: "10", text: "10 lines" },
          ]}
        />
        <VariantBuilderSetting
          object={field}
          label="Field Required"
          setting_name="[required]"
          setting_type="checkbox"
          object_type="fields"
          object_id={field.id}
          value={field.required ? field.required : ""}
          condition={
            (field.slug &&
              [true, "true"].includes(field.multi_select) == false &&
              field.field_type &&
              field.field_type !== "hidden" &&
              field.field_type !== "range") == true
          }
        />
      </VariantBuilderSetting>

      {element.element_type == "survey" && (
        <React.Fragment>
          <FieldSettingsSlugMapping field={field} />
          
          <div className="col-xs-12 mt-5">
            <div className="w-full relative text-black dark:text-white">
              <span className="text-xl">Survey Answers:</span>
            </div>
          </div>
          <div
            className="col-xs-12 sortable-container"
            data-sortable-name="field-options"
            data-object_type="fields"
            data-object_id={field.id}
          >
            {field_options.map((field_option, i) => {
              return (
                <React.Fragment key={"field-option-" + field_option.id}>
                  <FieldOptionSettings
                    field={field}
                    field_option={field_option}
                    {...props}
                  />
                </React.Fragment>
              );
            })}
          </div>
          <NewFieldOptions
            element={element}
            field={field}
            options_length={field_options.length}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FieldSettings;
