import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsDividerDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Divider`} open={true}>
        <VariantBuilderSetting
          kobject={section}
          label="Divider Border Width"
          setting_name="[options][divider-border-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            _.isNil(element.options[`divider-border-width`]) == false
              ? element.options[`divider-border-width`]
              : "1"
          }
          placeholder="1px"
          min={1}
          max={100}
          condition={["divider"].includes(element.element_type)}
        />

        <VariantBuilderSetting
          object={element}
          label="Border Style"
          setting_name="[options][divider-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["divider-border-style"]
              ? element.options["divider-border-style"]
              : "solid"
          }
          condition={["divider"].includes(element.element_type)}
        />

        <VariantBuilderSetting
          object={element}
          label="Button border color"
          setting_name="[options][divider-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["divider-border-color"]
              ? element.options["divider-border-color"]
              : "#ccc"
          }
          condition={
            ["divider"].includes(element.element_type) &&
            element.options["divider-border-style"] !== "empty"
          }
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsDividerDesign;
