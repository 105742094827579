var domains = {
  initialize: function () {
    $("#domain_sub_domain")
      .unbind("keyup")
      .on("keyup", function () {
        $("#custom-domain-error-message").hide();
        $(this).val(function (_, v) {
          return v.replace(/[^a-zA-Z0-9-]/, "").toLowerCase();
        });
      });

    $("#domain_root_domain")
      .unbind("keyup")
      .on("keyup", function () {
        $("#custom-domain-error-message").hide();
        $(this).val(function (_, v) {
          return v.toLowerCase();
        });
      });

    $("#new_domain")
      .unbind("submit")
      .on("submit", function (e) {
        e.preventDefault();
        $("#new_domain submit").addClass("disabled");

        $.ajax({
          type: "POST",
          url: "/websites/" + $(this).attr("data-website-id") + "/domains",
          data: $("#new_domain").serialize(),
          dataType: "JSONP",
          complete: function (data) {
            $("#new_domain submit").removeClass("disabled");

            if (data.responseText) {
              var data = $.parseJSON(data.responseText);

              if (data !== undefined) {
                $("#custom-domain-error-message")
                  .show()
                  .find("p")
                  .text(data.message);
              }
            }
          },
        });

        return false;
      });

    $(".domain").each(function (i, domain_div) {
      var domain_id = $(domain_div).attr("data-id");
      var root_domain = $(domain_div).attr("data-root-domain");
      var sub_domain = $(domain_div).attr("data-sub-domain");

      $(domain_div)
        .find(".edit_domain")
        .unbind("submit")
        .on("submit", function (e) {
          e.preventDefault();
          $(this).find(".error-message").hide();

          var current_value = $(this)
            .find("input[name='domain[redirect]']")
            .val()
            .replace("http://", "")
            .replace("https://", "");

          if (current_value !== sub_domain + "." + root_domain) {
            $(this)
              .find("input[type='submit']")
              .attr("value", "Saving...")
              .css("opacity", "0.7");
            return true;
          } else {
            $(this).find(".error-message").show();
            return false;
          }
        });
    });
  },
};

document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "domains" &&
    $("body").attr("data-action") == "index"
  ) {
    domains.initialize();
  }
});

export default domains;
