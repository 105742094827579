class ProductsReviewsAdapterService {
  constructor(client, source) {
    this.client = client;
    this.source = source
  }

  adaptReview(review) {
    switch (this.source) {
      case 'Yotpo':
        return new YotpoAdapter(review);
      case 'Okendo':
        return new OkendoAdapter(review);
      default:
        break;
    }
  }

  getReviews(productIds) {
    return new Promise(async (resolve, reject) => {
      let reviews = [];

      for (const productId of productIds) {
        try {
          const productReviews = await this.client.getProductReviews(productId);
          reviews = reviews.concat(this.adaptReview(productReviews));
        } catch (error) {
          reject(error);
          return;
        }
      }

      resolve(reviews);
    });
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ProductsReviewsAdapterService = ProductsReviewsAdapterService;
}
