var highlight = function (service) {
  $("#integrations")
    .off("shown.bs.modal")
    .on("shown.bs.modal", function (event) {
      var integration_col = $(
        '.integration-container[data-service="' + service + '"]'
      );

      var scroll_position = $(integration_col).offset().top - 300;
      $("#integrations").animate(
        {
          scrollTop: scroll_position + "px",
        },
        "fast"
      );
    });

  $("#integrations").modal("show");
  $("#quickLaunchModal").modal("hide");
  $('.integrations-install[data-service="' + service + '"]')
    .removeAttr("onclick")
    .css({
      opacity: "0.8",
      cursor: "initial",
    });
};

export default highlight;
