var request_public = function(account_id, template_id, button) {

  $(button).text('Requesting...').attr('disabled', 'disabled');

  $.ajax({
    url: window.location.origin + '/accounts/' + account_id + '/templates/' + template_id + '/request_public',
    dataType: "JSON",
    type: "POST",
    complete: function(data) {
      $(button).text('Requested!')
      $('.template-public-notification[template-id="' + template_id + '"]').show();
    }
  });

};

export default request_public;