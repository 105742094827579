var variants = {};

import stats from "packs/variants/stats.js";
variants.stats = stats;

import edit from "packs/variants/edit.js";
variants.edit = edit;

import froalaExtend from "packs/ctas/froala_extend.js";
window.froalaExtend = froalaExtend;

document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "cta" &&
    $("body").attr("data-action") == "show"
  ) {
    $.getJSON(
      window.location.origin +
        "/websites/" +
        $(".cta-show").attr("data-website-id") +
        "/cta/" +
        $(".cta-show").attr("data-id") +
        "/variants",
      function (variants_list) {
        $.each(variants_list, function (i, variant) {
          variants.edit(variant);

          $("#variant-" + variant.id + "-report")
            .unbind("shown.bs.modal")
            .on("shown.bs.modal", function () {
              if ($("#variant-" + variant.id + "-reports-chart").is(":empty")) {
                var container = $(
                  "#variant-" + variant.id + "-reports-container"
                )[0];
                variants.stats.initialize(container, variant);
              }
            });
        });
      }
    );
  }
  if (
    $("body").attr("data-controller") == "variants" &&
    $("body").attr("data-action") == "edit"
  ) {
    dispatchEvent(new Event("load"));
    ctas.builder.fonts.initialize();

    setTimeout(function () {
      ctas.builder.fonts.load_used_fonts();
    }, 1000);
  }
});

export default variants;
