import React from "react";
import PropTypes from "prop-types";
import Field from "../fields/Field";

const ElementFormCustomFields = (props) => {
  let element = props.element;
  let fields = Object.values(element.fields).filter(
    (field) =>
      field.element_id == element.id &&
      field.id &&
      field.field_type &&
      field.slug &&
      field.position &&
      field.toBeDeleted !== true
  );
  let sorted_fields = fields.sort(function (a, b) {
    return a.position - b.position;
  });
  return (
    <React.Fragment>
      {sorted_fields.map((field) => {
        return <Field key={"field-" + field.id} field={field} {...props} />;
      })}
    </React.Fragment>
  );
};

export default ElementFormCustomFields;
