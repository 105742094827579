import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderPresetField = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    let modal = document.querySelector(
      '.modal[data-name="' + props.setting_name + '"]'
    );
    if (modalVisibility == true) {
      $(modal).modal("show");

      $(modal)
        .unbind("hidden.bs.modal")
        .bind("hidden.bs.modal", function (e) {
          setModalVisibility(false);
        });

      modal.onclick = function (e) {
        if (e.target == modal) {
          if (
            $(modal).is(":visible") &&
            $(modal).find(".modal:visible").length == 0
          ) {
            setModalVisibility(false);
          }
        }
      };
    } else {
      $(modal).modal("hide");
    }
  });

  return (
    <React.Fragment>
      <div
        className="col-xs-10 !w-[190px] mr-[10px] field-trigger cf-item-box cf-preset-field-trigger my-2 mb-0 text-xl py-4 pl-3 pr-0 rounded-lg bg-white dark:bg-slate-950 border-solid border-[1px] text-slate-500 dark:text-slate-200 border-slate-200 dark:border-slate-700"
        onClick={() => {
          setModalVisibility(true);
        }}
      >
        <div
          className="cf-item-handler"
          style={{ position: "absolute", left: "-16px", display: 'none' }}
        >
          <span
            className="glyphicon glyphicon-option-vertical"
            aria-hidden="true"
          ></span>
        </div>
        <div className="cf-item-title">
          {props.object.options[props.preset_field.data_name + "-placeholder"]
            ? props.object.options[
                props.preset_field.data_name + "-placeholder"
              ]
            : props.preset_field.data_name}
        </div>

        <div
          className="cf-item-controls absolute right-2 top-[6px]"
          style={{
            display: "none",
          }}
        >
          <button
            title="Edit"
            className={`w-[25px] h-[25px] p-0 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                stroke={contextData.dark_mode ? "white" : "black"}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-xs-2 pl-0 field-checkbox-container right">
        <label
          style={{
            cursor: "pointer",
            marginTop: '4px'
          }}
        >
          <input
            type="checkbox"
            setting_name={props.setting_name}
            className="ios-switch green"
            style={{ display: "none" }}
            checked={
              props.value + "" == "true" || props.value == "on" ? true : false
            }
            onChange={(e) => {
              builder.update([
                {
                  object_type: props.object_type,
                  object_id: props.object_id,
                  setting_name: props.setting_name,
                  value: e.target.checked + "",
                  skip_history: props.skip_history,
                },
              ]);

              if (props.callback) {
                props.callback(e.target.checked + "");
              }
            }}
          />
          <article>
            <article></article>
          </article>
        </label>
      </div>
      <div
        className="modal"
        id={"modal-" + props.setting_name}
        data-name={props.setting_name}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="false"
        style={{
          background: "rgb(0, 0, 0, 0.21)",
        }}
      >
        <div className="modal-dialog !mt-44 !w-[690px]" role="document">
          <div className="modal-content bg-white dark:bg-slate-950">
            <div className="modal-header !border-slate-200 dark:!border-slate-800">
              <button
                type="button"
                className="close dark:text-white"
                data-dismiss={"modal-" + props.setting_name}
                aria-label="Close"
                onClick={() => {
                  setModalVisibility(false);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M12.2996 4.1001L4.09961 12.3001M12.2996 12.3001L4.09961 4.1001" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </button>
              <h4 className="text-2xl semibold text-black dark:text-white">
                {props.preset_field.label}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row hard-center">{props.children}</div>
            </div>
            <div className="modal-footer !border-slate-200 dark:!border-slate-800">
              <button
                type="button"
                className="text-xl semibold py-2 px-4 bg-white dark:bg-slate-950 text-black dark:text-white border-solid border-[1px] border-black dark:border-white rounded-lg"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderPresetField;
