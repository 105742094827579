import React, { useState, useEffect, useRef } from "react";
import ReportCount from "../reports/ReportCount";
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";
import FieldOptionReportIcon from "./FieldOptionReportIcon";

const FieldOptionReport = (props) => {
  const field_option = props.field_option;
  const field = props.field;
  const element = props.element;
  const lifetimeCount =
    props.timeframeType == "All Time" &&
    element.data &&
    element.data["fields"] &&
    element.data["fields"][field.slug] &&
    element.data["fields"][field.slug][field_option.value]
      ? element.data["fields"][field.slug][field_option.value]
      : undefined;
  const defaultConversionRate =
    lifetimeCount > 0
      ? ((lifetimeCount / element.conversions) * 100).toFixed(2)
      : 0;

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report text-xl"
        data-element-id={element.id}
        style={{ width: "100%", order: lifetimeCount }}
      >
        <div
          className="element-selection-report-bar"
          style={{
            width:
              props.timeframeType == "All Time"
                ? `${defaultConversionRate}%`
                : undefined,
          }}
        ></div>
        <div className="col-xs-4">
          <div className="ellipsis" style={{ marginBottom: "5px" }}>
            <FieldOptionReportIcon
              field_type={field_option.visual_type}
              field_option={field_option}
            />
            {field_option.value}
          </div>
        </div>
        <div className="col-xs-8 right">
          <p className="light text-xl" style={{ marginBottom: "10px" }}>
            <strong>
              <ReportCount
                inline={true}
                count={lifetimeCount}
                id={`field-option-${field_option.id}-selections`}
                path={`/websites/${props.cta.website_id}/events_reports`}
                payload={{
                  report_type: "distinct_count",
                  report: {
                    website_id: props.cta.website_id,
                    event_type: "Complete",
                    contactable_type: "Cta",
                    contactable_id: props.cta.id,
                    variant_id: props.step.variant_id,
                    step_id: props.step.id,
                    element_id: element.id,
                    field: field.slug,
                    value: field_option.value,
                    range_start: props.range_start,
                    range_end: props.range_end,
                    broadcast_id: null,
                  },
                }}
                callback={() => {
                  props.calculationConversionRates();

                  updateConversionRate(
                    `#field-option-${field_option.id}-selections`,
                    `#field_option-${field_option.id}-orders`,
                    `#field_option-${field_option.id}-conversion-rate`
                  );
                }}
              />
            </strong>{" "}
            selected (
            <span className="conversion-rate">
              {props.timeframeType == "All Time"
                ? defaultConversionRate
                : "0.00"}
            </span>
            %)
            {!props.gateReports && (
              <React.Fragment>
                {" - "}
                <strong>
                  <ReportCount
                    id={`field_option-${field_option.id}-orders`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "count",
                      report: {
                        website_id: props.cta.website_id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        field: field.slug,
                        value: field_option.value,
                        broadcast_id: null,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `#field_option-${field_option.id}-orders`,
                        `#field_option-${field_option.id}-revenue`,
                        `#field_option-${field_option.id}-aov`
                      );
                      updateConversionRate(
                        `#field-option-${field_option.id}-selections`,
                        `#field_option-${field_option.id}-orders`,
                        `#field_option-${field_option.id}-order-rate`
                      );
                    }}
                  />
                </strong>{" "}
                orders (
                <span id={`field_option-${field_option.id}-order-rate`}>
                  0.00
                </span>
                ){" - "}
                <strong>
                  <ReportCount
                    id={`field_option-${field_option.id}-revenue`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "revenue",
                      report: {
                        website_id: props.cta.website_id,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        field: field.slug,
                        value: field_option.value,
                        broadcast_id: null,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `#field_option-${field_option.id}-orders`,
                        `#field_option-${field_option.id}-revenue`,
                        `#field_option-${field_option.id}-aov`
                      );
                    }}
                    currency={props.currency}
                  />
                </strong>{" "}
                revenue
                {" - "}
                <strong>
                  {props.currency}
                  <span id={`field_option-${field_option.id}-aov`}>0.00</span>
                </strong>{" "}
                AOV
              </React.Fragment>
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FieldOptionReport;
