import React, { useState, useEffect, useRef } from "react";
import ReportCount from "../reports/ReportCount";
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";

const ProductValueReport = (props) => {
  const element = props.element;

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report"
        data-element-id={element.id}
        data-product-value={props.value}
        style={{ width: "100%" }}
      >
        <div
          className="element-selection-report-bar"
          style={{
            width: "100%",
          }}
        ></div>
        <div className="col-xs-4">
          <div className="text-xl ellipsis" style={{ marginBottom: "5px" }}>
            {props.label}
          </div>
        </div>
        <div className="col-xs-8 right">
          <p className="light" style={{ marginBottom: "10px" }}>
            <strong>
              <ReportCount
                inline={true}
                id={`product-value-${props.value}`}
                path={`/websites/${props.cta.website_id}/events_reports`}
                payload={{
                  report_type: "distinct_count",
                  report: {
                    website_id: props.cta.website_id,
                    event_type: ["Complete", "Add To Cart"],
                    contactable_type: "Cta",
                    contactable_id: props.cta.id,
                    variant_id: props.step.variant_id,
                    step_id: props.step.id,
                    element_id: element.id,
                    product_id: null,
                    range_start: props.range_start,
                    range_end: props.range_end,
                    field: null,
                    value: props.value,
                  },
                }}
                callback={() => {
                  props.calculationConversionRates();

                  updateConversionRate(
                    `[id="product-value-${props.value}"]`,
                    `[id="product-value-${props.value}-orders"]`,
                    `[id="product-value-${props.value}-order-rate"]`
                  );
                }}
              />
            </strong>{" "}
            selected (<span className="conversion-rate">0.00</span>
            %)
            {!props.gateReports && (
              <React.Fragment>
                {" - "}
                <strong>
                  <ReportCount
                    id={`product-value-${props.value}-orders`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "count",
                      report: {
                        website_id: props.cta.website_id,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        field: null,
                        value: props.value,
                        product_id: null,
                        product_variant_id: null,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `[id="product-value-${props.value}-orders"]`,
                        `[id="product-value-${props.value}-revenue"]`,
                        `[id="product-value-${props.value}-aov"]`
                      );
                      updateConversionRate(
                        `[id="product-value-${props.value}"]`,
                        `[id="product-value-${props.value}-orders"]`,
                        `[id="product-value-${props.value}-order-rate"]`
                      );
                    }}
                  />
                </strong>{" "}
                orders (
                <span id={`product-value-${props.value}-order-rate`}>0.00</span>
                ){" - "}
                <strong>
                  <ReportCount
                    id={`product-value-${props.value}-revenue`}
                    inline={true}
                    path={`/websites/${props.cta.website_id}/events_reports`}
                    payload={{
                      report_type: "revenue",
                      report: {
                        website_id: props.cta.website_id,
                        event_type: "Order",
                        contactable_type: "Cta",
                        contactable_id: props.cta.id,
                        variant_id: props.step.variant_id,
                        step_id: props.step.id,
                        element_id: element.id,
                        range_start: props.range_start,
                        range_end: props.range_end,
                        field: null,
                        value: props.value,
                        product_id: null,
                        product_variant_id: null,
                      },
                    }}
                    callback={() => {
                      updateAOV(
                        `[id="product-value-${props.value}-orders"]`,
                        `[id="product-value-${props.value}-revenue"]`,
                        `[id="product-value-${props.value}-aov"]`
                      );
                    }}
                    currency={props.currency}
                  />
                </strong>{" "}
                revenue
                {" - "}
                <strong>
                  {props.currency}
                  <span id={`product-value-${props.value}-aov`}>0.00</span>
                </strong>{" "}
                AOV
              </React.Fragment>
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductValueReport;
