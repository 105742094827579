import React, { useState } from "react";

const IncrementQuantityPicker = (props) => {
  const stylesheet = style.stylesheet();

  const [currentValue, setCurrentValue] = useState(
    props.value ? props.value : 1
  );

  const decrementCurrentValue = () => {
    if (currentValue > 1) {
      setCurrentValue(currentValue - 1);
    }
  };

  const incrementCurrentValue = () => {
    setCurrentValue(currentValue + 1);
  };

  return (
    <React.Fragment>
      <div className="cf-quantity-picker-wrapper">
        <p className="cf-quantity-picker-label" style={{ fontSize: "13px" }}>
          {props.label ? props.label : "Quantity"}
        </p>

        <div className="cf-increment-quantity-picker">
          <div className="cf-decrement" onClick={() => decrementCurrentValue()}>
            -
          </div>
          <div className="cf-products-quantity">{currentValue}</div>
          <div className="cf-increment" onClick={() => incrementCurrentValue()}>
            +
          </div>

          <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        </div>
      </div>
    </React.Fragment>
  );
};

const style = {
  stylesheet: () => {
    // prettier-ignore
    let desktopStyles =
      `.cf-quantity-picker-wrapper {
        display: inline-block;
        width: 109px;
      }

      .cf-products-item--shopify-graphql .cf-quantity-picker-wrapper {
        margin-top: 0px;
      }

      .cf-products-item--shopify .cf-quantity-picker-wrapper {
        margin-top: 15px;
      }

      .cf-products-item--shopify .cf-increment-quantity-picker {
        margin-bottom: 10px;
      }

      .cf-increment-quantity-picker {
        gap: 20px;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 5px;
        box-sizing: border-box;
        font-size: 17px;
        background-color: #fff;
        display: flex;
        justify-content: space-around;
      }

      .cf-increment-quantity-picker .cf-decrement,
      .cf-increment-quantity-picker .cf-increment,
      .cf-increment-quantity-picker .cf-products-quantity {
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
      }
      `;

    return desktopStyles;
  },
};

export default IncrementQuantityPicker;
