var conditions = {};

import form_site_wide from "packs/conditions/form_site_wide.js";
conditions.form_site_wide = form_site_wide;

document.addEventListener("turbolinks:load", function () {
  if (
    $("body").attr("data-controller") == "websites" &&
    $("body").attr("data-action") == "edit"
  ) {
    conditions.form_site_wide();
  }
});

export default conditions;
