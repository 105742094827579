var account_plan_modal = function (e, limit, message, plan, event_type) {
  if (e !== undefined && e !== "event") {
    e.preventDefault();
  }

  $(".go-annual").on("click", function (e) {
    e.preventDefault();
    $('a[aria-controls="annual"]').click();
  });

  $("#upgrade-headline-limit").hide();
  $("#upgrade-subheadline").hide();
  $("#upgrade-headline-feature").hide();
  $("#upgrade-subheadline-feature").hide();
  $("#upgrade-headline-default").hide();
  $("#upgrade-subheadline-default").hide();

  if (limit == true) {
    $("#upgrade-headline-limit").show();
    $("#upgrade-subheadline").show();
  }

  if (limit == false && message && message !== "trial") {
    $("#upgrade-headline-feature").show();
    $("#upgrade-subheadline-feature").show();
    $(".upgrade-feature").html(message);

    if (plan !== undefined && plan.length) {
      $("#upgrade-plan").text(plan);
    } else {
      $("#upgrade-plan").text("Pro");
    }
  } else if (limit == false && (!message || message == "trial")) {
    $("#upgrade-headline-default").show();
    $("#upgrade-subheadline-default").show();
  }

  if (e !== undefined && e.target.nodeType !== undefined) {
    $("#upgrade").modal("show");
  } else if (
    getCookie("free_limit_cta") !== "true" &&
    (limit == true || message == "trial")
  ) {
    $("#upgrade").modal("show");
    $("#upgrade").on("hidden.bs.modal", function () {
      setCookie("free_limit_cta", "true", 1);
    });
  }

  $(".modal-backdrop").css("z-index", "1");
  subscriptions.pricing_table();
};

export default account_plan_modal;
