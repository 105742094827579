import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useConditions from "../conditions/useConditions";
import useProducts from "../products/useProducts";

const useProductRecommendations = () => {
  const [contextData, builder] = useContext(VariantContextData);
  const { Conditions } = useConditions();
  const { Products } = useProducts();

  let variant = contextData.objects.variant;

  const ProductRecommendations = {
    create: async function (element, payload, options) {
      builder.create(
        "product_recommendations",
        {
          ...(payload || {}),
          element_id: element ? element.id : undefined,
        },
        {
          transform: function (product_recommendation) {
            product_recommendation.open = true;
            return product_recommendation;
          },
          callback: options ? options.callback : undefined,
        }
      );
    },

    duplicate: async function (
      og_product_recommendation,
      payload,
      skip_history
    ) {
      let attributes = {
        options: og_product_recommendation.options,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      builder.create("product_recommendations", attributes, {
        callback: async function (product_recommendation) {
          // duplicate conditions
          let conditions = Object.values(variant.conditions).filter(
            (condition) =>
              condition.contactable_type == "ProductRecommendation" &&
              condition.contactable_id == og_product_recommendation.id &&
              condition.toBeDeleted !== true
          );

          conditions.forEach((condition) => {
            Conditions.duplicate(
              condition,
              {
                contactable_id: product_recommendation.id,
              },
              true
            );
          });

          // duplicate products
          let products = Object.values(variant.products).filter(
            (product) =>
              product.product_recommendation_id ==
                og_product_recommendation.id && product.toBeDeleted !== true
          );

          products.forEach((product) => {
            Products.duplicate(
              product,
              {
                product_recommendation_id: product_recommendation.id,
              },
              true
            );
          });
        },
        skip_history: skip_history,
        skip_select: true,
      });
    },

    remove: (product_recommendation) => {
      let message =
        "Are you sure you want to remove this product recommendation? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove(
        "product_recommendations",
        product_recommendation.id,
        message
      );
    },
  };

  let currentHook = ProductRecommendations;
  return { ProductRecommendations, currentHook };
};

export default useProductRecommendations;
