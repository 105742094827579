import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSections from "../sections/useSections.js";
import useElements from "../elements/useElements.js";
import NewElementDraggable from "../elements/NewElementDraggable.js";
import NewElement from "../elements/NewElement.js";
import ElementTypeItem from "../elements/ElementTypeItem";
import ElementTemplateItemSmall from "../elements/ElementTemplateItemSmall";
import TabGroup from "../shared/TabGroup";
import NewSection from "../sections/NewSection.js";
import NewStep from "../steps/NewStep.js";
import NewFieldPanel from "../fields/NewFieldPanel.js";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";

const VariantBuilderPanelNew = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { Sections } = useSections();
  const { Elements } = useElements(Sections);

  let new_object = contextData.new_object;
  let section = contextData.objects.variant.sections[new_object.section_id];
  let website_id = contextData.objects.website.id;

  const elementTypeMapping = {
    headline: "Headline",
    text: "Text",
    image: "Image",
    video: "Video",
    list: "List",
    button: "Button",
    form: "Form",
    quiz: "Quiz",
    survey: "Survey",
    products: "Products",
    row: "Row",
    collapse: "Collapse",
    tabs: "Tabs",
    carousel: "Carousel",
    progress: "Progress Bar",
    timer: "Timer",
    icon: "Icon",
    divider: "Divider",
    html: "HTML",
  };
  const elementTypesCount = Object.keys(elementTypeMapping).length;

  const getFirstSectionAvailable = () => {
    let steps_with_sections = Object.values(
      contextData.objects.variant.sections
    )
      .filter((section) => section.toBeDeleted !== true)
      .map((s) => s.step_id)
      .filter((v, i, a) => a.indexOf(v) === i);

    if (steps_with_sections.length > 0) {
      let first_step = Object.values(contextData.objects.variant.steps)
        .filter((step) => steps_with_sections.includes(step.id))
        .reduce(function (prev, curr) {
          return prev.position < curr.position ? prev : curr;
        });

      let first_section_with_step = Object.values(
        contextData.objects.variant.sections
      )
        .filter((s) => s.step_id == first_step.id && s.element_id == null)
        .reduce((prev, curr) => (prev.position < curr.position ? prev : curr));

      return first_section_with_step;
    } else {
      alert(
        "You must have at least one section to be able to create an element!"
      );
    }
  };

  function createElement(element_type, template_id = null) {
    let focusOnCreate = false;
    let elementPayload = {};

    if (!new_object.section_id) {
      section = getFirstSectionAvailable();

      if (section) {
        elementPayload = { column: 1, section_id: section.id };
        focusOnCreate = true;
      }
    }

    if (section) {
      if (element_type !== "image") {
        Elements.create(
          section,
          element_type,
          elementPayload.column,
          {},
          template_id,
          null,
          null,
          focusOnCreate
        );
      }

      if (element_type == "image") {
        uploads.select("callback", {
          environment: environment,
          path: "/websites/" + website_id + "/",
          website_id: website_id,
          library: true,
          callback: function (file_url) {
            Elements.create(
              section,
              element_type,
              elementPayload.column,
              {
                image: file_url,
              },
              null,
              null,
              null,
              focusOnCreate
            );
          },
        });
      }
    }
  }

  useEffect(() => {
    NewElementDraggable.start(contextData, Elements);
  }, [contextData.new_object]);

  return (
    <React.Fragment>
      <div className="row hard-center">
        <VariantBuilderPanelTitle
          title={`${
            new_object.object_type == "Fields"
              ? "Add Quiz Question"
              : `Create ${
                  new_object.object_type == "Steps"
                    ? "Funnel Step"
                    : new_object.object_type == "Sections"
                    ? "Section"
                    : new_object.object_type == "Elements"
                    ? "Element"
                    : ""
                }`
          }`}
          tooltip="Drag-and-drop our pre-built templates. Or, create from scratch!"
          tooltipLabel="Learn"
          parent_object={
            new_object.object_type == "Fields"
              ? {
                  type: "elements",
                  id: new_object.element_id,
                  label: "Back To Questions",
                }
              : undefined
          }
        />

        {[null, "Steps", "Sections", "Elements"].includes(
          new_object.object_type
        ) && (
          <TabGroup
            tabs={["Steps", "Sections", "Elements"]}
            tab={
              new_object && new_object.object_type
                ? new_object.object_type
                : null
            }
            setActiveTab={(tab) => {
              builder.new_object({ object_type: tab, element_type: "all" });
            }}
            className="mb-6"
          >
            {new_object.object_type == "Steps" && <NewStep {...props} />}

            {new_object.object_type == "Sections" && <NewSection {...props} />}

            {new_object.object_type == "Elements" && (
              <NewElement {...props} element_type={new_object.element_type} />
            )}
          </TabGroup>
        )}

        {new_object.object_type == "Fields" && <NewFieldPanel {...props} />}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderPanelNew;
