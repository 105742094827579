import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeContainer = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;

  let cta_width_options = [
    { value: "310px", text: "310px" },
    { value: "500px", text: "500px" },
  ];

  if (cta.cta_type == "inline" || cta.cta_type == "overlay") {
    cta_width_options.push(
      { value: "700px", text: "700px" },
      { value: "900px", text: "900px" },
      { value: "1200px", text: "1200px" },
      { value: "100%", text: "100%" }
    );
  }

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse
        name="Container"
        open={contextData.selected_object_collapse == "Container"}
      >
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][splash-type]"
          setting_type="dropdown"
          object_type="variants"
          label="Splash overlay sizing"
          options={[
            { value: "full", text: "Full height" },
            { value: "scrollable", text: "Height of content" },
          ]}
          value={
            variant.options["splash-type"]
              ? variant.options["splash-type"]
              : "full"
          }
          condition={cta.cta_type == "overlay" && cta.overlay_type == "splash"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][container-width]"
          setting_type="range"
          object_type="variants"
          label="Max container width"
          options={cta_width_options}
          value={
            variant.options["container-width"] &&
            variant.options["container-width"].indexOf("px") > -1
              ? variant.options["container-width"].replace("px", "")
              : variant.options["container-width"] &&
                variant.options["container-width"].indexOf("%") > -1
              ? null
              : cta.cta_type == "hook" && cta.hook_type == "vertical"
              ? "310"
              : null
          }
          placeholder={cta.cta_type == "hook" && cta.hook_type == "vertical" ? "310" : "100%"}
          condition={
            (cta.cta_type == "overlay" && cta.overlay_type == "boxed") ||
            cta.cta_type == "inline" ||
            cta.cta_type == "bar" ||
            (cta.cta_type == "hook" && cta.hook_type == "vertical")
          }
          skip_update={true}
          callback={(new_value) => {
            builder.update([
              {
                object_type: "variants",
                setting_name: "[options][container-width]",
                value: `${new_value}px`,
              },
            ]);
          }}
          min={50}
          max={2560}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][cta-position]"
          setting_type="dropdown"
          object_type="variants"
          label={
            (cta.cta_type == "hook"
              ? "Popup"
              : cta.cta_type == "inline"
              ? "Embed"
              : "") + " Position"
          }
          options={[
            { value: "left", text: "Left" },
            { value: "center", text: "Center" },
            { value: "right", text: "Right" },
          ]}
          value={
            variant.options["cta-position"]
              ? variant.options["cta-position"]
              : cta.cta_type == "inline" || cta.cta_type == "bar"
              ? "center"
              : "right"
          }
          condition={
            cta.cta_type == "inline" ||
            cta.cta_type == "hook" ||
            cta.cta_type == "bar"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][bar-position]"
          setting_type="dropdown"
          object_type="variants"
          label="Sticky Bar Position"
          options={[
            { value: "top", text: "Appear from top" },
            { value: "bottom", text: "Appear from bottom" },
          ]}
          value={
            variant.options["bar-position"]
              ? variant.options["bar-position"]
              : "top"
          }
          condition={cta.cta_type == "bar"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][bar-fixed]"
          setting_type="checkbox"
          object_type="variants"
          label="Scroll with page"
          value={
            variant.options["bar-fixed"] ? variant.options["bar-fixed"] : "true"
          }
          condition={
            cta.cta_type == "bar" &&
            (!variant.options["bar-position"] ||
              variant.options["bar-position"] == "top")
          }
          tooltip="Disable this to have the sticky bar appear at the top of the page without scrolling with the visitor"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-color]"
          setting_type="color_v2"
          object_type="variants"
          label="Background Color"
          value={
            variant.options["background-color"]
              ? variant.options["background-color"]
              : ""
          }
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-opacity]"
          setting_type="range"
          object_type="variants"
          label={
            "Background Color Opacity " +
            (variant.options["background-opacity"]
              ? variant.options["background-opacity"]
              : 100)
          }
          value={
            variant.options["background-opacity"]
              ? variant.options["background-opacity"]
              : 100
          }
          min={0}
          max={1}
          range_step={"0.01"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-image]"
          setting_type="image"
          object_type="variants"
          object_id={variant.id}
          label="Background Image"
          value={
            variant.options["background-image"] &&
            variant.options["background-image"] !== "none"
              ? variant.options["background-image"]
              : ""
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-position]"
          setting_type="dropdown"
          object_type="variants"
          label="Background Image Position"
          value={
            variant.options["background-position"]
              ? variant.options["background-position"]
              : "top-center"
          }
          options={[
            { value: "top-left", text: "Top left" },
            { value: "top-center", text: "Top center" },
            { value: "top-right", text: "Top right" },
            { value: "center-left", text: "Middle left" },
            { value: "center-center", text: "Middle center" },
            { value: "center-right", text: "Middle right" },
            { value: "bottom-left", text: "Bottom left" },
            { value: "bottom-center", text: "Bottom center" },
            { value: "bottom-right", text: "Bottom right" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-position-mobile]"
          setting_type="dropdown"
          object_type="variants"
          label="Background Image Position"
          value={
            variant.options["background-position-mobile"]
              ? variant.options["background-position-mobile"]
              : variant.options["background-position"]
              ? variant.options["background-position"]
              : "top-center"
          }
          options={[
            { value: "top-left", text: "Top left" },
            { value: "top-center", text: "Top center" },
            { value: "top-right", text: "Top right" },
            { value: "center-left", text: "Middle left" },
            { value: "center-center", text: "Middle center" },
            { value: "center-right", text: "Middle right" },
            { value: "bottom-left", text: "Bottom left" },
            { value: "bottom-center", text: "Bottom center" },
            { value: "bottom-right", text: "Bottom right" },
          ]}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-size]"
          setting_type="dropdown"
          object_type="variants"
          label="Background Image Size"
          value={
            variant.options["background-size"]
              ? variant.options["background-size"]
              : "cover"
          }
          options={[
            { value: "auto", text: "Image width" },
            { value: "cover", text: "Cover container" },
            { value: "expand-width", text: "Expand to width" },
            { value: "expand-height", text: "Expand to height" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][background-size-mobile]"
          setting_type="dropdown"
          object_type="variants"
          label="Background Image Size"
          value={
            variant.options["background-size-mobile"]
              ? variant.options["background-size-mobile"]
              : variant.options["background-size"]
              ? variant.options["background-size"]
              : "cover"
          }
          options={[
            { value: "auto", text: "Image width" },
            { value: "cover", text: "Cover container" },
            { value: "expand-width", text: "Expand to width" },
            { value: "expand-height", text: "Expand to height" },
          ]}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][container-background]"
          setting_type="dropdown"
          object_type="variants"
          label="Popup Backdrop Theme"
          options={[
            { value: "light", text: "Light" },
            { value: "dark", text: "Dark" },
            { value: "custom", text: "Custom" },
          ]}
          value={
            variant.options["container-background"]
              ? variant.options["container-background"]
              : "light"
          }
          condition={
            cta.cta_type == "overlay" ||
            (cta.cta_type == "hook" && cta.hook_type == "full")
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][container-background-color]"
          setting_type="color_v2"
          object_type="variants"
          label="Popup Backdrop Color"
          value={
            variant.options["container-background-color"]
              ? variant.options["container-background-color"]
              : ""
          }
          condition={
            cta.cta_type == "overlay" &&
            variant.options["container-background"] == "custom"
          }
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][container-rounding]"
          setting_type="dropdown"
          object_type="variants"
          label="Popup Container Rounding"
          options={[
            { value: "0", text: "None" },
            { value: "4", text: "Slightly rounded" },
            { value: "20", text: "More rounded" },
          ]}
          value={
            variant.options["container-rounding"]
              ? variant.options["container-rounding"]
              : cta.cta_type == "hook"
              ? "4"
              : "0"
          }
          condition={cta.cta_type == "overlay" || cta.cta_type == "hook"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][show-shadow]"
          setting_type="checkbox"
          object_type="variants"
          label="Show container shadow"
          value={
            variant.options["show-shadow"]
              ? variant.options["show-shadow"]
              : "true"
          }
          condition={cta.cta_type == "overlay" || cta.cta_type == "hook"}
          tooltip="Toggling this off will disable the subtle shadow shown around the popup"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][show-profile]"
          setting_type="checkbox"
          object_type="variants"
          label="Show user profile"
          value={variant.options["show-profile"]}
          condition={
            cta.cta_type == "overlay" ||
            cta.cta_type == "hook" ||
            cta.cta_type == "inline"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[user_id]"
          setting_type="dropdown"
          object_type="variants"
          label="Profile User"
          options={[
            {
              value: "",
              text: "Select a user profile",
            },
            ...contextData.objects.users
              .filter((user) => {
                return user.name || user.id == props.current_user.id;
              })
              .map((user) => {
                return {
                  value: user.id,
                  text: user.name ? user.name : "Your profile",
                };
              }),
          ]}
          value={variant.user_id ? variant.user_id : undefined}
          condition={
            (cta.cta_type == "overlay" ||
              cta.cta_type == "hook" ||
              cta.cta_type == "inline") &&
            variant.options["show-profile"] + "" == "true"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][profile-edit]"
          setting_type="modal_trigger"
          object_type="variants"
          value="Edit Your Profile"
          button_glyphicon="glyphicon-random"
          selector="#userEdit"
          condition={
            (cta.cta_type == "overlay" ||
              cta.cta_type == "hook" ||
              cta.cta_type == "inline") &&
            variant.options["show-profile"] + "" == "true" &&
            parseInt(variant.user_id) == props.current_user.id
          }
          onload={function () {
            let firstname = $('#profile-form input[name="user[name]"]').val();
            let lastname = $(
              '#profile-form input[name="user[lastname]"]'
            ).val();
            let avatar;
            let brandname;

            $(
              '#profile-form input[name="user[name]"], #profile-form input[name="user[lastname]"]'
            ).on("keyup", function () {
              firstname = $('#profile-form input[name="user[name]"]').val();
              lastname = $('#profile-form input[name="user[lastname]"]').val();
              $("#user-name").text(firstname + " " + lastname);
            });
            $('#profile-form input[name="user[company]"]').on(
              "keyup",
              function () {
                $("#user-company").text($(this).val());
              }
            );

            $("#profile-form form")
              .off("submit")
              .on("submit", function (e) {
                e.preventDefault();

                avatar = $('#profile-form input[name="user[avatar]"]').val();
                brandname = $(
                  '#profile-form input[name="user[company]"]'
                ).val();

                $('#profile-form input[type="submit"]').attr(
                  "disabled",
                  "true"
                );
                let userToUpdate = contextData.objects.users.filter(
                  (user) => user.id == props.current_user.id
                )[0];
                if (userToUpdate) {
                  userToUpdate.name = firstname;
                  userToUpdate.lastname = lastname;
                  userToUpdate.avatar = avatar;

                  let newContextData = { ...contextData };
                  newContextData.objects.users =
                    contextData.objects.users.filter(
                      (user) => user.id !== props.current_user.id
                    );
                  newContextData.objects.users.push(userToUpdate);
                  newContextData.objects.website.brand = brandname;
                  setContextData((originalData) => newContextData);
                }

                $.ajax({
                  type: "POST",
                  url:
                    window.location.origin +
                    "/signup?website_id=" +
                    contextData.objects.website.id,
                  data: new FormData($(this)[0]),
                  processData: false,
                  contentType: false,
                  async: true,
                  success: function (current_user) {
                    $('#profile-form input[type="submit"]').removeAttr(
                      "disabled"
                    );
                    $("#userEdit").modal("hide");
                  },
                });
              });
          }}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][profile-theme]"
          setting_type="dropdown"
          object_type="variants"
          label="Profile Theme"
          options={[
            { value: "light", text: "Light" },
            { value: "dark", text: "Dark" },
          ]}
          value={
            variant.options["profile-theme"]
              ? variant.options["profile-theme"]
              : "light"
          }
          condition={
            (cta.cta_type == "overlay" ||
              cta.cta_type == "hook" ||
              cta.cta_type == "inline") &&
            variant.options["show-profile"] + "" == "true"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][profile-alignment]"
          setting_type="dropdown"
          object_type="variants"
          label="Profile Picture Alignment"
          options={[
            { value: "left", text: "Aligned Left" },
            { value: "center", text: "Aligned Center" },
          ]}
          value={
            variant.options["profile-alignment"]
              ? variant.options["profile-alignment"]
              : "center"
          }
          condition={
            (cta.cta_type == "overlay" ||
              cta.cta_type == "hook" ||
              cta.cta_type == "inline") &&
            variant.options["show-profile"] + "" == "true"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][profile-shadow]"
          setting_type="checkbox"
          object_type="variants"
          label="User profile shadow"
          value={variant.options["profile-shadow"]}
          condition={
            (cta.cta_type == "overlay" ||
              cta.cta_type == "hook" ||
              cta.cta_type == "inline") &&
            variant.options["show-profile"] + "" == "true"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][custom-class]"
          label="Custom Classes"
          placeholder="Custom class names here"
          setting_type="text"
          object_type="variants"
          value={variant.options["custom-class"]}
          alphanumeric={true}
          update_on_input_end={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_type="message"
          label="HTML/Anchor ID"
          value={"#cta_" + cta.id}
        />

        <VariantBuilderSetting
          object={variant}
          setting_type="message"
          label="CSS Selector"
          value={"#cta_" + cta.id + '[data-variant-id="' + variant.id + '"]'}
          tooltip="Using custom CSS? Make sure to add !important to the end of each of your CSS properties."
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeContainer;
