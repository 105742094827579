import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const useBroadcasts = (props) => {
  let contextData;
  let builder;

  if (!props || props.builder !== false) {
    [contextData, builder] = useContext(VariantContextData);
  }

  const Broadcasts = {};

  const DetachedBroadcasts = {
    remove: async function (broadcast, show_message = false) {
      let message = show_message
        ? "Are you sure you want to remove this condition? Once your changes are saved, it will be irreversibly deleted."
        : null;

      if (confirm(message)) {
        $.ajax({
          type: "DELETE",
          url:
            "/websites/" + broadcast.website_id + "/broadcasts/" + broadcast.id,
          async: true,
          success: function () {
            if (callback) {
              callback();
            }
          },
        });
      }
    },
  };

  let currentBroadcastsHook =
    props && props.builder == false ? DetachedBroadcasts : Broadcasts;
  return { Broadcasts, currentBroadcastsHook };
};

export default useBroadcasts;
