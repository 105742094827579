import React, { useState } from "react";
import PropTypes from "prop-types";

const BroadcastsCampaignToggle = (props) => {
  const [live, setLive] = useState(props.broadcast.live);
  const websiteLink = "/websites/" + props.website.id;
  const broadcastEndpoint =
    window.location.origin + websiteLink + "/broadcasts/" + props.broadcast.id;

  async function saveStatus(status) {
    await fetch(broadcastEndpoint, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": csrfToken(),
      },
      method: "PUT",
      body: JSON.stringify({ broadcast: { live: status } }),
    });
  }

  function toggleLive(e) {
    let status = e.target.checked;
    saveStatus(status);
    setLive(status);
  }

  return (
    <React.Fragment>
      <div
        className="broadcast-campaign-toggle"
        data-toggle="tooltip"
        data-placement="top"
        title="Campaign status"
      >
        <input
          type="checkbox"
          className="ios-switch green campaign-toggle-checkbox"
          checked={live}
          onChange={toggleLive}
        />
        <article className="campaign-toggle">
          <article></article>
        </article>
        <span className="campaign-status">
          <a
            className="light"
            href={websiteLink + "/broadcasts/" + props.broadcast.id + "/edit"}
          >
            {live ? "Active" : "Inactive"}
          </a>
        </span>
      </div>
    </React.Fragment>
  );
};

export default BroadcastsCampaignToggle;
