var broadcasts_ctas = {
  initialize: function () {
    $(".broadcasts-cta, .broadcasts-cta-list").each(function (
      i,
      broadcasts_cta_div
    ) {
      $(broadcasts_cta_div)
        .find(".object-remove, .broadcast-remove")
        .unbind("click")
        .on("click", function (i, delete_icon) {
          if (
            confirm(
              "Are you sure you want to remove this campaign from this broadcast? Doing so will clear any stats this campaign has associated with this broadcast"
            )
          ) {
            $.ajax({
              type: "DELETE",
              async: true,
              url:
                window.location.origin +
                "/websites/" +
                $(broadcasts_cta_div).attr("data-website-id") +
                "/broadcasts/" +
                $(broadcasts_cta_div).attr("data-broadcast-id") +
                "/broadcasts_cta/" +
                $(broadcasts_cta_div).attr("data-id"),
            });
          }
        });
    });
  },
};

export default broadcasts_ctas;
