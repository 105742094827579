import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingOptionsImages = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  function updateOption(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="row hard-center VariantBuilderSettingOptionsImages">
        <div className="btn-img-group">
          {props.options.map((option) => {
            let isActive = props.value == option.value;

            return (
              <img
                key={`cf-option-img-${props.object.id}-${option.value}`}
                className={isActive && !option.image_active ? "active" : ""}
                src={isActive && option.image_active ? option.image_active : option.image}
                onClick={() => {
                  updateOption(option.value);
                }}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingOptionsImages;
