import React from "react";
import PropTypes from "prop-types";

const FieldOptionRadio = (props) => {
  let field_option = props.field_option;
  let element = props.element;
  let field = props.field;
  let important = props.builder !== true ? " !important" : "";
  const fieldOptionId = `contact_extra_${field.slug}_${
    field_option.value
      ? field_option.value.toLowerCase().replace(" ", "_")
      : field_option.label
      ? field_option.label.toLowerCase().replace(" ", "_")
      : ""
  }_${field_option.position}`;

  const isRequired = field.required == true;
  const isMultiSelect = field.field_type !== "radio" && (field.multi_select == true || field.field_type == "checkbox");
  const hasOneOption = Object.values(field.field_options).length == 1;
  const isCheckbox = hasOneOption == true || isMultiSelect == true;

  return (
    <React.Fragment>
      <p
        key={"field-option-" + field_option.id}
        id={"option" + field_option.id + "Option"}
        data-id={field_option.id}
        data-position={field_option.position}
        className="cf-radio-option"
        style={{
          marginBottom: "0px",
          color: element.options["label-color"]
            ? element.options["label-color"] + important
            : undefined,
        }}
      >
        <input
          name={
            isMultiSelect == false
              ? "contact[extra][" + field.slug + "]"
              : undefined
          }
          data-slug={"contact[extra][" + field.slug + "]"}
          data-id={field_option.id}
          data-position={field_option.position}
          data-value={
            field_option.value
              ? field_option.value
              : field_option.label
              ? field_option.label
              : "Option " + field_option.position
          }
          id={fieldOptionId}
          style={{ marginRight: "10px" }}
          defaultValue={
            field_option.value
              ? field_option.value
              : field_option.label
              ? field_option.label
              : "Option " + field_option.position
          }
          type={isCheckbox == true ? "checkbox" : "radio"}
          required={isRequired == true && isMultiSelect == false ? true : false}
        ></input>
        <label
          className="cf-label"
          htmlFor={fieldOptionId}
          style={{
            fontWeight: "400",
            color: element.options["label-color"]
              ? element.options["label-color"] + important
              : undefined,
          }}
        >
          {field_option.label
            ? field_option.label
            : field_option.value
            ? field_option.value
            : "Option " + field_option.position}
        </label>
      </p>
    </React.Fragment>
  );
};

export default FieldOptionRadio;
