import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeCarousel = (props) => {
  const [contextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Carousels" open={false}>
        <VariantBuilderSetting
          object={variant}
          label="Controls Style"
          setting_name="[options][carousel-controls-style]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["carousel-controls-style"]
              ? variant.options["carousel-controls-style"]
              : "inside"
          }
          options={[
            { value: "outside", text: "Outside" },
            { value: "inside", text: "Inside" },
          ]}
          tooltip="Not available if width custom is selected at the element level"
        />

        <VariantBuilderSetting
          object={variant}
          label="Carousel Background Color"
          setting_name="[options][carousel-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["carousel-background-color"]
              ? variant.options["carousel-background-color"]
              : undefined
          }
          placeholder="#f1f1f1"
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][carousel-arrow-size]"
          setting_type="range"
          object_type="variants"
          label={"Arrow Size"}
          value={
            variant.options["carousel-arrow-size"]
              ? variant.options["carousel-arrow-size"]
              : undefined
          }
          placeholder={"12"}
          min={1}
          max={80}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][carousel-arrow-size-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Arrow Size"}
          value={
            variant.options["carousel-arrow-size-mobile"]
              ? variant.options["carousel-arrow-size-mobile"]
              : undefined
          }
          placeholder={variant.options["carousel-arrow-size"] || "12"}
          min={1}
          max={80}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Arrow Controls Color"
          setting_name="[options][carousel-arrow-controls-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["carousel-arrow-controls-color"]
              ? variant.options["carousel-arrow-controls-color"]
              : null
          }
          placeholder="#000"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][carousel-indicator-size]"
          setting_type="range"
          object_type="variants"
          label={"Indicator Size"}
          value={
            variant.options["carousel-indicator-size"]
              ? variant.options["carousel-indicator-size"]
              : undefined
          }
          placeholder={"10"}
          min={1}
          max={256}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][carousel-indicator-size-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Indicator Size"}
          value={
            variant.options["carousel-indicator-size-mobile"]
              ? variant.options["carousel-indicator-size-mobile"]
              : undefined
          }
          placeholder={variant.options["carousel-indicator-size"] || "10"}
          min={1}
          max={256}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Indicator Controls Color"
          setting_name="[options][carousel-indicator-controls-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["carousel-indicator-controls-color"]
              ? variant.options["carousel-indicator-controls-color"]
              : undefined
          }
          placeholder="#bdc3c7"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Indicator Active Border Style"
          setting_name="[options][carousel-indicator-active-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["carousel-indicator-active-border-style"]
              ? variant.options["carousel-indicator-active-border-style"]
              : "solid"
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Indicator Active Border color"
          setting_name="[options][carousel-indicator-active-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["carousel-indicator-active-border-color"]
              ? variant.options["carousel-indicator-active-border-color"]
              : undefined
          }
          placeholder="#000"
          condition={
            variant.options["carousel-indicator-active-border-style"] &&
            variant.options["carousel-indicator-active-border-style"] !== "none"
          }
          show_reset={true}
        />

        <div
          className="col-xs-12 some-vertical-space setting"
          style={{ marginBottom: "5px" }}
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="mb-[5px] text-xl text-black dark:text-white">
                Indicator Active Border Width
              </div>

              {["left", "top", "bottom", "right"].map((side) => {
                const settingName = `[options][carousel-indicator-active-border-width-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[
                          `carousel-indicator-active-border-width-${side}`
                        ]
                      ) == false
                        ? variant.options[
                            `carousel-indicator-active-border-width-${side}`
                          ]
                        : variant.options[
                            "carousel-indicator-active-border-width"
                          ]
                        ? variant.options[
                            "carousel-indicator-active-border-width"
                          ].replace("px", "")
                        : "1"
                    }
                    placeholder="1px"
                    col="3"
                    allow_empty={true}
                    note={window.capitalizeFirstLetter(side)}
                    classNames="spacing-inputs"
                    min={0}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Indicator Border Radius"
          setting_name="[options][carousel-indicator-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="variants"
          value={
            variant.options["carousel-indicator-border-radius"]
              ? variant.options["carousel-indicator-border-radius"]
              : undefined
          }
          show_reset={true}
        />

        {variant.options["carousel-indicator-border-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][carousel-indicator-border-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[
                          `carousel-indicator-border-radius-${side}`
                        ]
                      ) == false
                        ? variant.options[
                            `carousel-indicator-border-radius-${side}`
                          ]
                        : undefined
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder={"0px"}
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeCarousel;
