import React, { useContext, useState, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderToolbarLogo from "./VariantBuilderToolbarLogo.js";
import VariantBuilderToolbarBreadcrumbs from "./VariantBuilderToolbarBreadcrumbs.js";
import VariantBuilderToolbarVariants from "./VariantBuilderToolbarVariants.js";
import VariantBuilderToolbarDevices from "./VariantBuilderToolbarDevices.js";
import VariantBuilderToolbarButtons from "./VariantBuilderToolbarButtons.js";
import VariantBuilderToolbarZoom from "./VariantBuilderToolbarZoom.js";
import VariantBuilderToolbarInvite from "./VariantBuilderToolbarInvite.js";

const VariantBuilderToolbar = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);

  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <React.Fragment>
      <div
        className="fixed h-[49px] top-0 left-0 w-full bg-white dark:bg-slate-950"
        style={{
          boxShadow: "0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)",
        }}
      >
        <div className="w-full grid grid-flow-col grid-cols-3 auto-cols-max px-2 pb-1 pr-[7px]">
          <div className="pt-2">
            <VariantBuilderToolbarLogo
              onClick={() => {
                $('#website-nav').show();
              }}
              {...props}
            />
            <VariantBuilderToolbarBreadcrumbs {...props} />
            <VariantBuilderToolbarVariants {...props} />
          </div>
          <div className="flex justify-center">
            <VariantBuilderToolbarDevices {...props} />
            {props.page == "builder" && (
              <VariantBuilderToolbarZoom {...props} />
            )}
          </div>
          <div>
            <div className="grid grid-flow-col auto-cols-auto pt-2 justify-end">
              <VariantBuilderToolbarInvite {...props} />
              <VariantBuilderToolbarButtons {...props} />
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default VariantBuilderToolbar;
