var integrations = {};

import oauth from "packs/integrations/oauth.js";
integrations.oauth = oauth;

import sync from "packs/integrations/sync.js";
integrations.sync = sync;

import form from "packs/integrations/form.js";
integrations.form = form;

import highlight from "packs/integrations/highlight.js";
integrations.highlight = highlight;

document.addEventListener("turbolinks:load", function () {
  $('[data-toggle="popover"]').popover();

  $(".coming-soon")
    .unbind("click")
    .on("click", function () {
      $.ajax({
        url: window.location.origin + "/users/intercom-tag",
        type: "POST",
        data: {
          id: $(this).attr("data-user"),
          tag: $(this).attr("data-value"),
        },
      });
      $(this)
        .removeClass("btn-primary")
        .addClass("btn-default")
        .html("Subscribed!");
    });

  $("#google-analytics-track-toggle")
    .unbind("click")
    .on("click", function () {
      $("#google-analytics-track").click();
    });

  $("#google-analytics-track")
    .unbind("change")
    .on("change", function () {
      if ($(this).is(":checked")) {
        $("#website-google-analytics-track").val("true");
      } else {
        $("#website-google-analytics-track").val("false");
      }
    });

  $("#google-analytics-data-layer-toggle")
    .unbind("click")
    .on("click", function () {
      $("#google-analytics-data-layer").click();
    });

  $("#google-analytics-data-layer")
    .unbind("change")
    .on("change", function () {
      if ($(this).is(":checked")) {
        $("#website-google-analytics-data-layer").val("true");
      } else {
        $("#website-google-analytics-data-layer").val("false");
      }
    });

  $("#google-analytics-cross-domain-toggle")
    .unbind("click")
    .on("click", function () {
      $("#google-analytics-cross-domain").click();
    });

  $("#google-analytics-cross-domain")
    .unbind("change")
    .on("change", function () {
      if ($(this).is(":checked")) {
        $("#website-google-analytics-cross-domain").val("true");
      } else {
        $("#website-google-analytics-cross-domain").val("false");
      }
    });

  $("#googleanalytics-submit")
    .unbind("click")
    .on("click", function () {
      $("#googleAnalyticsModal").modal("hide");
    });

  $(".integration-resync")
    .unbind("click")
    .on("click", function () {
      var button = $(this);

      $.ajax({
        type: "GET",
        url:
          window.location.origin +
          "/websites/" +
          $(button).attr("data-website") +
          "/integrations/" +
          $(button).attr("data-integration") +
          "/refresh",
        dataType: "JSON",
        success: function (data, status, xhr) {
          if (data && data.job_id) {
            integrations.sync(
              data.job_id,
              $(button).attr("data-website"),
              $(button).attr("data-integration"),
              button
            );
          }
        },
      });
    });

  // inject zapier templates
  $("#apikey").on("shown.bs.modal", function () {
    var iframe = $("#zapier-templates")[0];

    const script = document.createElement("script");
    script.type = "module";
    script.src =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    document.head.appendChild(script);

    // Load CSS
    const stylesheet = document.createElement("link");
    stylesheet.rel = "stylesheet";
    stylesheet.href =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    document.head.appendChild(stylesheet);

    // Create and display zapier-full-experience
    const element = document.createElement("zapier-full-experience");
    element.clientId = "k9bz1Dn6tCfF3fUHF4CZXxxbarcXoFDlqRcipen0";
    element.theme = "light";
    element.appSearchBarDisplay = "show";
    element.appExclusions = "convertflow";
    element.appCategories = "ecommerce,email-newsletters,phone,-forms";
    element.zapLimit = 10;
    element.zapStyle = "card";
    element.zapCreateFromScratchDisplay = "hide";

    const container = document.querySelector("#zapier-templates");
    if ($(container).children().length == 0) {
      container.appendChild(element);
    }
  });

  if (
    $("body").attr("data-controller") == "integrations" &&
    $("body").attr("data-action") == "index"
  ) {
    var integration_id = getUrlParameter("integration_id");

    if (integration_id) {
      const integrationDiv = $(".integration-item[data-integration='" + integration_id + "']")[0];

      if (integrationDiv) {
        integrationDiv.scrollIntoView({
          behavior: 'smooth',
        });
      }

      if (getUrlParameter("error") !== 'true') {
        $("#integration-" + integration_id).modal("show");
      }
    }

    var service = getUrlParameter("service");

    if (service) {
      var integrationItem = $(`.integration-item[data-service="${service}"]`);
      var button = $('.integration-connect[data-service="' + service + '"]');
      var auth_type = $(button).attr("data-auth-type");
      var website_id = $(button).attr("data-website-id");
      var target = $(button).attr("data-target");
      var auth_keys = $(button).attr("data-auth-keys");

      if ($(integrationItem).attr('data-status') == 'true') {
        $(`.integration-stacked-modal[data-service="${service}"]`).modal("show");
      } else {
        if (auth_type == "oauth" && !auth_keys) {
          window.integrations.oauth.create(website_id, service);
        }
  
        if (auth_type == "key" || (auth_type == "oauth" && auth_keys)) {
          $(target).modal("show");
          window.integrations.form(button);
        }
      }

      if ($(integrationItem)[0]) {
        $(integrationItem)[0].scrollIntoView();
      }
    }
  }
});

export default integrations;
