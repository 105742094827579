var initialize = function (cta_group_div) {
  $(cta_group_div)
    .find(".object-remove")
    .on("click", function () {
      if (
        confirm("Are you sure you want to remove this campaign from this group?")
      ) {
        $.ajax({
          type: "DELETE",
          url:
            window.location.origin +
            "/websites/" +
            $(cta_group_div).attr("data-website-id") +
            "/cta_groups/" +
            $(cta_group_div).attr("data-id"),
          success: function () {
            var id = $(cta_group_div).attr("data-id");
            var cta_id = $(cta_group_div).attr("data-cta-id");
            var group_id = $(cta_group_div).attr("data-group-id");
            $(
              '.cta-group[data-id="' +
                id +
                '"], .cta-group-item[data-cta-id="' +
                cta_id +
                '"][data-group-id="' +
                group_id +
                '"]'
            ).remove();
          },
        });
      }
    });
};

export default initialize;
