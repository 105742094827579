class OkendoAdapter {
  constructor(source_review) {
    this.source = "Okendo";
    this.source_review = source_review;

    this.product_id = this.productId();
    this.product_score = this.productScore();
    this.product_total_reviews = this.productTotalReviews();
  }

  productId() {
    return this.source_review?.productId.replace('shopify-', '');
  }

  productScore() {
    if (this.source_review?.ratingAndReviewCount === 0) return 5;

    return this.source_review?.ratingAndReviewValuesTotal / this.source_review?.ratingAndReviewCount;
  }

  productTotalReviews() {
    if (this.source_review?.ratingAndReviewCount === 0) return undefined;

    return this.source_review?.ratingAndReviewCount;
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.OkendoAdapter = OkendoAdapter;
}