var preview_device = function(device_type) {

  var frame = $('#template-iframe')
  var container = $('.template-preview-container')
  var current_device = $(container).attr('device-type');
  $(container).attr('device-type', device_type);
  var current_source = $(frame).attr('src');
  $(frame).attr('src', current_source.replace('device_type=' + current_device, 'device_type=' + device_type));

  $('.template-preview-devices').attr('device-type', device_type);

  if (false == ($('body').attr('data-controller') == 'templates' && $('body').attr('data-action') == 'show')) {

    $(frame).css({
      'transform': 'initial',
      'margin-left': 'auto',
      'margin-top': 'auto'
    })

    if (device_type == 'desktop' && $(frame).attr('cta-type') == 'page') {
      $(frame).attr('height', '672px')
      $(frame).css({
        'transform': 'scale(0.9)',
        'margin-left': '-67px',
        'margin-top': '-38px'
      })
    }

    if (device_type == 'mobile' && $(frame).attr('cta-type') == 'page') {
      $(frame).attr('height', '600px')
    }
  }

};

export default preview_device;