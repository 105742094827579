import React, { useState, useEffect, useRef } from "react";
import useConditions from "../conditions/useConditions";

const AreaItemInput = (props) => {
  const toggleRef = useRef();

  const { currentConditionsHook } = useConditions({ ...props, builder: false });
  const Conditions = currentConditionsHook;

  const website = props.website;

  useEffect(() => {
    toggleRef.current.checked = props.isToggled;
  }, []);

  const [conditionId, setConditionId] = useState(props.area_condition?.id);

  const toggleArea = () => {
    const isChecked = toggleRef.current.checked;

    if (isChecked) {
      const attributes = {
        website_id: website.id,
        contactable_type: props.contactable_type,
        contactable_id: props.broadcast?.id,
        condition_type: "If area is",
        area_id: props.area.id,
        cta_id: props.cta.id,
        operator: "and",
      };

      Conditions.create(attributes, (e) => {
        setConditionId(e.id);
      });
    } else if (props.area_condition && props.area_condition.id !== undefined) {
      Conditions.remove(
        { website_id: website.id, id: conditionId },
        true,
        () => {}
      );
    }

    props.setIsToggled(toggleRef.current.checked);
  };

  return (
    <React.Fragment>
      <div>
        <input
          type="checkbox"
          className="ios-switch area-toggle-checkbox cursor-pointer absolute left-0 w-full"
          data-cta-id={props.cta.id}
          onChange={toggleArea}
          ref={toggleRef}
        />
        <article className="area-toggle cursor-pointer">
          <article></article>
        </article>
      </div>
      <div
        onClick={() => {
          toggleRef.current.checked = !toggleRef.current.checked;
          toggleArea();
        }}
        className="cursor-pointer pl-3 text-black text-xl"
      >
        {`${props.isToggled ? "Targeted" : "Not selected"}`}
      </div>
    </React.Fragment>
  );
};

export default AreaItemInput;
