import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import VariantSettingsThemeContainer from "../variants/VariantSettingsThemeContainer";
import VariantSettingsThemeHeadings from "./VariantSettingsThemeHeadings";
import VariantSettingsThemeTimer from "./VariantSettingsThemeTimer";
import VariantSettingsThemeForms from "./VariantSettingsThemeForms";
import VariantSettingsThemeButtons from "./VariantSettingsThemeButtons";
import VariantSettingsThemeProgress from "./VariantSettingsThemeProgress";
import VariantSettingsThemeProducts from "./VariantSettingsThemeProducts";
import VariantSettingsThemeProductHero from "./VariantSettingsThemeProductHero";
import VariantSettingsThemeIcon from "./VariantSettingsThemeIcon";
import VariantSettingsThemeList from "./VariantSettingsThemeList";
import VariantSettingsThemeSurveys from "./VariantSettingsThemeSurveys";
import VariantSettingsThemeCollapses from "./VariantSettingsThemeCollapses";
import VariantSettingsThemeTabs from "./VariantSettingsThemeTabs";
import VariantSettingsThemeCarousel from "./VariantSettingsThemeCarousel";
import VariantSettingsThemeQuiz from "./VariantSettingsThemeQuiz";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";
import VariantSettingsThemeClose from "../variants/VariantSettingsThemeClose";

const VariantSettingsTheme = (props) => {
  return (
    <React.Fragment>
      <div className="row hard-center">
        <VariantBuilderPanelTitle
          title={`Theme Styles`}
          tooltip="Make your funnel on-brand by adjusting your theme styles. Override theme styles by styling elements you add."
          tooltipLabel="Learn"
        
        />
        <VariantSettingsThemeContainer {...props} />
        <VariantSettingsThemeClose {...props} />
        <VariantSettingsThemeHeadings {...props} />
        <VariantSettingsThemeForms {...props} />
        <VariantSettingsThemeButtons {...props} />
        <VariantSettingsThemeProducts {...props} />
        <VariantSettingsThemeProductHero {...props} />
        <VariantSettingsThemeSurveys {...props} />
        <VariantSettingsThemeQuiz {...props} />
        <VariantSettingsThemeProgress {...props} />
        <VariantSettingsThemeTimer {...props} />
        <VariantSettingsThemeIcon {...props} />
        <VariantSettingsThemeCollapses {...props} />
        <VariantSettingsThemeList {...props} />
        <VariantSettingsThemeTabs {...props} />
        <VariantSettingsThemeCarousel {...props} />
      </div>
    </React.Fragment>
  );
};

export default VariantSettingsTheme;
