import React from "react";
import useFieldOptions from "../field_options/useFieldOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewQuizFieldOption = (props) => {
  const { FieldOptions } = useFieldOptions();

  const createFieldOption = () => {
    let position_letter = String.fromCharCode(65 + props.options_length);
    const default_value = `Option ${position_letter}`;

    FieldOptions.create(
      props.field,
      { label: default_value, value: default_value, emoji: "😎", letter: position_letter },
      {
        callback: (field_option) => {
          dispatchCustomEvent("focusItem", {
            parent_id: field_option.field_id,
            item_type: "field_options",
            item_id: field_option.id
          });
        }
      }
    );
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 no-padding-sides my-3">
        <button
          onClick={createFieldOption}
          className={`w-full p-2 !pl-0 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white`}
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" />
          Add Answer
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewQuizFieldOption;
