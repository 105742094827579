const NewElementDraggable = {
  start: (contextData, Elements) => {
    let forceFallback = environment == "test" ? false : true;

    let tinyElementsContainers = document.querySelectorAll(".new-element-scratch-tiny");
    tinyElementsContainers.forEach((newElementsContainer) => {
      let elementsSortable = new Sortable(newElementsContainer, {
        sort: false,
        group: { name: "elements", pull: "clone", put: false },
        draggable: ".cf-draggable-element",
        forceFallback: false,
        delay: 10,
        direction: 'horizontal',
        animation: 0,
        ghostClass: "drop-zone",
        scroll: false,
        forceFallback: true,
        fallbackTolerance: 5,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        swapThreshold: 0.8,
        onStart: function (event) {
          NewElementDraggable.handleDragging(event);
          $('#builder').addClass('dragging');
        },
        onEnd: function (event) {
          NewElementDraggable.handleCreation(event, contextData, Elements);
          $('#builder').removeClass('dragging');
        },
      });

      window.sortables.indexOf(elementsSortable) === -1
        ? window.sortables.push(elementsSortable)
        : "";
    });

    let newElementsContainers = document.querySelectorAll(".new-element-scratch");
    newElementsContainers.forEach((newElementsContainer) => {
      let elementsSortable = new Sortable(newElementsContainer, {
        sort: false,
        group: { name: "elements", pull: "clone", put: false },
        draggable: ".cf-draggable-element",
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewElementDraggable.handleDragging(event);
          $('#builder').addClass('dragging');
        },
        onEnd: function (event) {
          NewElementDraggable.handleCreation(event, contextData, Elements);
          $('#builder').removeClass('dragging');
        },
      });

      window.sortables.indexOf(elementsSortable) === -1
        ? window.sortables.push(elementsSortable)
        : "";
    });

    let createFromScratchContainer = document.querySelector(
      "#elements-create-from-scratch"
    );
    if (createFromScratchContainer) {
      let scratchSortable = new Sortable(createFromScratchContainer, {
        sort: false,
        group: { name: "elements", pull: "clone", put: false },
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewElementDraggable.handleDragging(event);
          $('#builder').addClass('dragging');
        },
        onEnd: function (event) {
          NewElementDraggable.handleCreation(event, contextData, Elements);
          $('#builder').removeClass('dragging');
        },
      });

      window.sortables.indexOf(scratchSortable) === -1
        ? window.sortables.push(scratchSortable)
        : "";
    }

    let templatesTinyContainer = document.querySelectorAll(".new-element-templates-tiny");

    templatesTinyContainer.forEach((templatesContainer) => {
      let templatesSortable = new Sortable(templatesContainer, {
        sort: false,
        group: { name: "elements", pull: "clone", put: false },
        draggable: ".cf-draggable-element-template",
        forceFallback: false,
        delay: 10,
        direction: 'horizontal',
        animation: 0,
        ghostClass: "drop-zone",
        scroll: false,
        forceFallback: true,
        fallbackTolerance: 5,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        swapThreshold: 0.8,
        onStart: function (event) {
          NewElementDraggable.handleDragging(event);
          $('#builder').addClass('dragging');
        },
        onEnd: function (event) {
          NewElementDraggable.handleCreation(event, contextData, Elements);
          $('#builder').removeClass('dragging');
        },
      });

      window.sortables.indexOf(templatesSortable) === -1
        ? window.sortables.push(templatesSortable)
        : "";
    })

    let templatesContainer = document.querySelectorAll(".new-element-templates");

    templatesContainer.forEach((templatesContainer) => {
      let templatesSortable = new Sortable(templatesContainer, {
        sort: false,
        group: { name: "elements", pull: "clone", put: false },
        draggable: ".cf-draggable-element-template",
        forceFallback: forceFallback,
        delay: 10,
        onStart: function (event) {
          NewElementDraggable.handleDragging(event);
          $('#builder').addClass('dragging');
        },
        onEnd: function (event) {
          NewElementDraggable.handleCreation(event, contextData, Elements);
          $('#builder').removeClass('dragging');
        },
      });

      window.sortables.indexOf(templatesSortable) === -1
        ? window.sortables.push(templatesSortable)
        : "";
    })
  },

  handleDragging: (event) => {
    $(event.to).find(`.cf-cta-item-container .drop-zone`).hide();
    $(`.section-add-element`).hide();
  },

  handleCreation: (event, contextData, Elements) => {
    if (event.from !== event.to) event.item.remove();
    if (event.from == event.to) return;

    let section =
      contextData.objects.variant.sections[event.to.dataset.sectionId];
    let position = event.newIndex;
    let element_type = event.clone.children[0].dataset.type;
    let column = event.to.dataset.sortableId;
    let website_id = contextData.objects.website.id;
    let template_id = parseInt(event.clone.dataset.templateId) || 0;
    let positionUpdates = NewElementDraggable.draggableContainerItemsReorderUpdates(
      event.to,
      position
    );
    let focusOnCreate = false;
    let forceRender = true;

    if (element_type !== "image") {
      Elements.create(
        section,
        element_type,
        column,
        {},
        template_id,
        position,
        positionUpdates,
        focusOnCreate,
        forceRender
      );
    }

    if (element_type == "image") {
      uploads.select("callback", {
        environment: environment,
        path: "/websites/" + website_id + "/",
        website_id: website_id,
        library: true,
        callback: function (file_url) {
          Elements.create(
            section,
            element_type,
            column,
            {
              image: file_url
            },
            null,
            position,
            positionUpdates,
            focusOnCreate,
            forceRender
          );
        },
      });
    }
  },

  draggableContainerItemsReorderUpdates: (
    container,
    new_element_dragged_index
  ) => {
    let updatesForHistory = [];
    let newPositionIndexStart;
    let draggedIndex = parseInt(new_element_dragged_index);

    Array.from(container.children)
      .filter((div) => div.className.indexOf("disabled-item") == -1)
      .forEach((element_div, i) => {
        let element_id = parseInt(element_div.getAttribute("data-element-id"));
        let old_position = parseInt(element_div.getAttribute("data-pos"));
        let new_position = i + 1;

        if (new_position < draggedIndex) return;
        if (new_position == draggedIndex) {
          newPositionIndexStart = new_position + 1;
          new_position = newPositionIndexStart;
        } else if (new_position > draggedIndex) {
          new_position = newPositionIndexStart + 1;
          newPositionIndexStart += 1;
        }

        updatesForHistory.push({
          object_type: "elements",
          object_id: element_id,
          setting_name: "[position]",
          value: new_position,
          old_value: old_position,
        });
      });

    return updatesForHistory;
  },
};

export default NewElementDraggable;
