var templates = {};

import preview from "packs/templates/preview.js";
templates.preview = preview;

import preview_device from "packs/templates/preview_device.js";
templates.preview_device = preview_device;

import copy_link from "packs/templates/copy_link.js";
templates.copy_link = copy_link;

import request_public from "packs/templates/request_public.js";
templates.request_public = request_public;

import remove from "packs/templates/remove.js";
templates.remove = remove;

document.addEventListener("turbolinks:load", function () {
  if ($("body").attr("data-controller").includes("templates")) {
    if ($("body").attr("data-action") == "show") {
      // make iframe full height
      var iframe_height = $(window).height();

      if ($(window).width() > 600) {
        var iframe_height = iframe_height * 1.1;
      }

      $("#template-iframe").attr("height", iframe_height + "px");
      $(".template-preview-container-frame").css(
        "height",
        $(".template-preview-container-frame").outerHeight() * 0.85 + "px"
      );
    }

    if ($("body").attr("data-action") == "index") {
      $("#template-search select").change(function () {
        $("#template-search").submit();
      });
      $('#template-search input[name="name"]').keyup(function () {
        if ($(this).val().length > 0) {
          $(this).parent().find(".search").css("color", "#1892f5");
        } else {
          $(this).parent().find(".search").css("color", "#696969");
        }
      });
      $("#template-search .search").click(function () {
        $("#template-search").submit();
      });
    }

    if (
      $("body").attr("data-action") == "new" ||
      $("body").attr("data-action") == "replace"
    ) {
      $("#template_object_id").on("change", function () {
        $("#variant_id option").hide();
        var options = $('#variant_id option[cta-id="' + $(this).val() + '"]');
        $(options).show();
        $("#variant_id").val($(options).first().attr("value"));
      });

      if (getUrlParameter("object_id")) {
        $("#template_object_id")
          .val(getUrlParameter("object_id"))
          .trigger("change");

        if (getUrlParameter("variant_id")) {
          $("#variant_id").val(getUrlParameter("variant_id"));
        }
      }
    }

    if (
      $("body").attr("data-controller") == "templates" &&
      $("body").attr("data-action") == "edit"
    ) {
      $(".template-form").each(function (i, template_form_div) {
        $(template_form_div)
          .find("textarea")
          .froalaEditor({
            enter: $.FroalaEditor.ENTER_P,
            paragraphFormatDefaultSelection: "Paragraph",
            toolbarButtons: [
              "undo",
              "redo",
              "insertLink",
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "align",
              "formatUL",
              "insertImage",
            ],
            key: "kKC1KXDF1INBh1KPe2TK==",
          });
      });
      $(".template-header").on("click", function () {
        $(this).find(".glyphicon").toggleClass("glyphicon-chevron-right");
        $(this).find(".glyphicon").toggleClass("glyphicon-chevron-down");
        $(this).parent().find(".template-body").toggle();
      });
    }
  }
});

export default templates;
