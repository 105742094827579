import React, { useEffect, useState } from "react";

import IncrementQuantityPicker from "../shared/IncrementQuantityPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ElementProductHero = (props) => {
  const website = props.website;
  const element = props.element;

  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  const placeholderProduct = {
    source: 'Convertflow',
    id: 1,
    title: "Placeholder 1",
    price: 1,
    description: "Example Description for Product 1",
    variants: [
      {
        id: 1,
        title: "Placeholder 1",
      },
    ],
    options: [
      {
        name: "1",
        values: ["1", "2", "3"],
      },
    ],
  }

  const [product, setProduct] = useState(placeholderProduct);
  const [reviews, setReviews] = useState(null);

  const generateRandomKey = () => { return `product-hero-${Math.random().toString(16).slice(2)}` }

  const imagePlaceholderClass = ["ShopifyGraphql"].includes(element.options["products-source"])
    ? "cf-shopify-product-placeholder-svg"
    : "cf-generic-product-placeholder-svg"

  const setupProductsFeed = () => {
    const integration = Object.values(props.website?.integrations).filter(
      (i) => i.integration_type == "Shopify" && i.status == true
    )[0]

    if (element.options["products-source"] == "ShopifyGraphql" && integration) {
      const parsed_store_name = (integration.api_data?.api_url || integration.api_data?.website)
        ?.replace("http://", "")
        ?.replace("https://", "")
        ?.replace(".myshopify.com", "")
        ?.replace("/", "")
        ?.replace(" ", "");

      const shopifyClient = new ShopifyGraphqlClient({
        store_name: parsed_store_name,
        token: integration.api_data["storefront-token"],
      });

      new ProductsAdapterService(shopifyClient, "ShopifyGraphql")
        .getProduct(element.options["shopify-products-selected"])
        .then((response) => {
          const selectedVariant = response.variants.find(
            (variant) =>
              variant.id ==
              element.options["shopify-products-selected-variants"]
          );

          if (selectedVariant) {
            setProduct(selectedVariant);
          } else {
            setProduct(response);
          }
        });
    } else {
      let product = Object.values(element.products)
        .find((product) => product.element_id == element.id && product.toBeDeleted !== true);

      const adapterService = new ProductsAdapterService(undefined, "Convertflow");

      if (product) {
        setProduct(adapterService.adaptProduct(product))
      } else {
        setProduct(placeholderProduct);
      }
    }
  }

  if (props.builder == true) {
    useEffect(() => {
      if (element.options["products-reviews-source"] && product?.id) {
        const integration = Object.values(props.website.integrations).find(
          (i) =>
            i.integration_type == element.options["products-reviews-source"] &&
            i.status == true
        );

        if (integration) {
          const client = new window[integration.integration_type + "Client"]({
            app_key: integration.api_data["app_key"],
          });

          new ProductsReviewsAdapterService(client, integration.integration_type)
            .getReviews([product.id])
            .then((response) => {
              setReviews(response[0])
            });
        } else {
          setReviews(null);
        }
      }
    }, [product?.id, element.options["products-reviews-source"]]);

    useEffect(() => {
      setupProductsFeed();
    }, [
      element.options["shopify-products-selected"],
      element.options["products-source"],
      element.products
    ]);
  }

  const ProductOfferBadge = (props) => {
    if (props.condition == false) {
      return null;
    }

    let productDiscount;
    if (
      element.options["products-discount-type"] == "amount" &&
      element.options["products-discount-amount"]
    ) {
      productDiscount = `${website.currency_character} ${element.options["products-discount-amount"]}`;
    } else if (
      element.options["products-discount-type"] == "percentage" &&
      element.options["products-discount-percentage"]
    ) {
      productDiscount = `${element.options["products-discount-percentage"]}%`;
    } else {
      productDiscount = "10%";
    }

    let productImageBadgeText;
    if (element.options["products-show-image-sold-out-badge"] == "true" && product.available === false) {
      productImageBadgeText = element.options["products-image-sold-out-badge-text"] || "SOLD OUT";
    } else if (element.options["products-image-badge-style"] == "custom") {
      productImageBadgeText = element.options["products-image-badge-custom-message"] || "10% OFF"
    } else if (element.options["products-discount-type"] == "detect") {
      const productAdapter = selectedProductVariant || product;

      if (productAdapter?.compare_at_price) {
        productImageBadgeText = `${website.currency_character} ${((productAdapter?.compare_at_price / 100) - productAdapter?.price).toFixed(2)} OFF`
      }
    } else {
      productImageBadgeText = productDiscount + " OFF";
    }

    return (
      <React.Fragment>
        <div className="cf-products-item-badge" data-font={element.options["products-image-badge-font-family"]}>
          {productImageBadgeText}
        </div>
      </React.Fragment>
    );
  };

  const ProductImage = (props) => {
    if (props.condition == false) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="cf-prod-hero-inner-box">
          <div className="cf-prod-hero-image-box">
            <div className="cf-product-item-image-block" key={generateRandomKey()}>
              <div className="cf-products-item-image-wrapper">
                <div className="cf-row cf-outer-center cf-products-item-image">
                  {product?.image ? <img loading="lazy" src={product.image} /> : <img className={`cf-products-placeholder-svg ${imagePlaceholderClass}`} /> }
                </div>
              </div>

              {element.options["products-images-to-show"] == "carousel" &&
                element.options["products-source"] == "ShopifyGraphql" && (
                  <div className="cf-product__slider-wrap">
                    <div className="cf-product__slider">
                      {product?.imageList?.length > 0 && (
                        Object.values(product.imageList).map((image, index) => {
                          return (
                            <img
                              key={`product-image-${generateRandomKey()}-${image.id}`}
                              className={`cf-product__image cf-product__image--${
                                index == 0 ? "active" : ""
                              }`}
                              src={image.transformedSrc}
                            />
                          );
                        })
                      )}

                      {!product?.imageList && (
                        <React.Fragment>
                          <img key={generateRandomKey()} className={`cf-product__image cf-product__image--active ${imagePlaceholderClass}`} style={{ backgroundColor: 'rgba(105, 114, 123, 0.5)' }} />
                          <img key={generateRandomKey()} className={`cf-product__image ${imagePlaceholderClass}`} style={{ backgroundColor: 'rgba(105, 114, 123, 0.5)' }} />
                          <img key={generateRandomKey()} className={`cf-product__image ${imagePlaceholderClass}`} style={{ backgroundColor: 'rgba(105, 114, 123, 0.5)' }} />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  };

  const ProductReviews = (props) => {
    if (props.condition == false) {
      return null;
    }

    const renderStarIcon = (score) => {
      const fullStars = Math.floor(score);
      const hasHalfStar = score % 1 !== 0;

      const starIcons = [];

      for (let i = 0; i < fullStars; i++) {
        starIcons.push(<FontAwesomeIcon key={generateRandomKey()} icon="fa-star" />);
      }

      if (hasHalfStar) {
        starIcons.push(<FontAwesomeIcon key={generateRandomKey()} icon="fa-star-half-stroke" />);
      }

      const remainingStars = 5 - starIcons.length;
      for (let i = 0; i < remainingStars; i++) {
        starIcons.push(<FontAwesomeIcon key={generateRandomKey()} icon="fa-regular fa-star" color="white" />);
      }

      return starIcons;
    };

    return (
      <React.Fragment>
        <div key={generateRandomKey()} className="cf-products-reviews-wrapper">
          <div className="cf-products-reviews" data-font={element.options["products-reviews-font-family"]}>
            <div className="cf-products-reviews-stars-inner">
              {reviews ? (
                renderStarIcon(reviews.product_score)
              ) :
                <React.Fragment>
                  <FontAwesomeIcon icon="fa-star" />
                  <FontAwesomeIcon icon="fa-star" />
                  <FontAwesomeIcon icon="fa-star" />
                  <FontAwesomeIcon icon="fa-star" />
                  <FontAwesomeIcon icon="fa-star" />
                </React.Fragment>
              }
            </div>

            { reviews && reviews.product_total_reviews > 0 &&
              <div className="cf-products-reviews-count"> {reviews.product_total_reviews}</div>}

            { reviews && reviews.product_total_reviews > 0 &&
              <div className="cf-products-reviews-label">{element.options["products-reviews-label"] ? element.options["products-reviews-label"] : "Reviews" }</div>}
          </div>
        </div>
      </React.Fragment>
    )
  }

  let productTitleText;
  let selectedProductVariant = product?.variants && product?.variants[0];

  if (product?.source?.toLowerCase() == "convertflow" && product?.title && selectedProductVariant) {
    productTitleText = `${selectedProductVariant?.title?.includes('Default Title') ? product?.title : selectedProductVariant.title}`
  } else if (product?.title && selectedProductVariant) {
    productTitleText = `${product?.title} ${selectedProductVariant.title.includes('Default Title') ? '' : ' - ' + selectedProductVariant.title}`
  } else if (product?.title) {
    productTitleText = product?.title;
  } else {
    productTitleText = `Product ${product?.id}`;
  }

  const ProductTitle = (props) => {
    if (props.condition == false) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          key={generateRandomKey()}
          className={`cf-products-item-title`}
          data-font={element.options["products-item-title-font"]}
        >
          <p>{element.options["products-title-override-text"] ? element.options["products-title-override-text"] : productTitleText}</p>
        </div>
      </React.Fragment>
    )
  }

  const ProductPrice = (props) => {
    if (props.condition == false) {
      return null;
    }

    function addZeroes(num) {
      const dec = (num + "").split(".")[1];
      const len = dec && dec.length > 2 ? dec.length : 2;
      return Number(num).toFixed(len);
    }

    const productAdapter = selectedProductVariant || product;

    let priceFormatted = "0.00";
    if (productAdapter?.price) {
      priceFormatted = addZeroes(productAdapter?.price);
      if ((priceFormatted + "").split(".")[1] == "00") {
        priceFormatted = Math.trunc(priceFormatted);
      }
    }

    let discountedPriceFormatted;

    if (element.options["products-discount-type"] == "amount") {
      const discountAmount = element.options["products-discount-amount"] || 10;

      discountedPriceFormatted = (productAdapter?.price - discountAmount).toFixed(2);
    }

    if (element.options["products-discount-type"] == "percentage") {
      const discountAmount = element.options["products-discount-percentage"] || 10;

      discountedPriceFormatted = (
        productAdapter?.price -
        productAdapter?.price * (parseInt(discountAmount) * 0.01)
      ).toFixed(2);
    }

    if (element.options["products-discount-type"] == "detect") {
      if (productAdapter?.compare_at_price) {
        priceFormatted = productAdapter.compare_at_price / 100;
        discountedPriceFormatted = productAdapter.price;
      }
    }

    if (discountedPriceFormatted && discountedPriceFormatted <= 0) {
      discountedPriceFormatted = "0.00";
    }

    if (discountedPriceFormatted) {
      discountedPriceFormatted = addZeroes(discountedPriceFormatted);

      if ((discountedPriceFormatted + "").split(".")[1] == "00") {
        discountedPriceFormatted = Math.trunc(discountedPriceFormatted);
      }

      if (discountedPriceFormatted == 0) {
        discountedPriceFormatted = "0";
      }
    }

    return (
      <React.Fragment>
        <div
          key={generateRandomKey()}
          data-font={element.options["products-item-price-font"]}
          className={`cf-products-item-price`}
          data-discount={discountedPriceFormatted ? true : undefined}
        >
          <p>
            {!discountedPriceFormatted && (
              <React.Fragment>
                {website.currency_character}
                <span className="cf-products-item-price-base">
                  {priceFormatted}
                </span>
              </React.Fragment>
            )}
            {discountedPriceFormatted && (
              <React.Fragment>
                <strike>
                  {website.currency_character}
                  <span className="cf-products-item-price-base">
                    {priceFormatted}
                  </span>
                </strike>{" "}
                {website.currency_character}
                <span className="cf-products-item-price-discount">
                  {discountedPriceFormatted}
                </span>
              </React.Fragment>
            )}
          </p>
        </div>
      </React.Fragment>
    )
  }

  const ProductDescription = (props) => {
    if (props.condition == false) {
      return null;
    }

    let productDescriptionContent = element.options["products-description-override-text"]
      ? element.options["products-description-override-text"]
      : product?.description
      ? product.description
      : `<p>Example Description For ${productTitleText}</p>`;

    if (props.builder == true && element.options["products-description-formatting"] == "cf-unformatted-text") {
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = productDescriptionContent;
      productDescriptionContent = tempDiv.textContent || tempDiv.innerText || "";
    }

    return (
      <React.Fragment>
        <div
          key={generateRandomKey()}
          className={`cf-products-item-description cf-merge-tag-allowed`}
          data-font={element.options["products-item-description-font"]}
          dangerouslySetInnerHTML={{ __html: productDescriptionContent }}
        />
      </React.Fragment>
    )
  }

  const ProductQuantityPicker = (props) => {
    if (props.condition == false || element.options["products-source"] !== "ShopifyGraphql") {
      return null;
    }

    return (
      <React.Fragment>
        <div key={generateRandomKey()} style={{ display: element.options["confirmation-type"] == "cart" ? 'block' : 'none'}}>
          <IncrementQuantityPicker label={element.options["products-quantity-label-text"]}/>
        </div>
      </React.Fragment>
    )
  }

  const ProductVariantPicker = (props) => {
    if (props.condition == false) {
      return null;
    }

    const variantPickerLabel = product?.options ? product?.options.map((f) => f.name == "Title" ? "Options" : f.name).join(" / ") : "Option";
    const isConvertflowSource = !element.options["products-source"] || element.options["products-source"]?.toLowerCase() == "convertflow";

    return (
      <React.Fragment>
        <div className="cf-variant-picker-wrapper" key={generateRandomKey()} style={{ display: element.options["confirmation-type"] == "cart" || isConvertflowSource ? 'block' : 'none', width: '100%' }}>
          {element.options["products-variant-picker-style"] == "cf-options-buttons" &&
            element.options["products-source"] == "ShopifyGraphql" ? (
              <React.Fragment>
                {product?.options && product?.options.map((option) => {
                  let filteredOptions = option.values.filter(e => e !== 'Default Title')

                  return (
                    <React.Fragment key={generateRandomKey()}>
                      {option.name !== "Title" && (
                        <p className="cf-variant-picker-label" style={{ fontSize: "13px" }}>
                          {option.name}
                        </p>
                      )}

                      {filteredOptions.length > 0 && (
                        <div className="cf-products-options-wrapper" data-font={element.options["products-variant-picker-font-family"]}>
                          {filteredOptions.map((value, i) => {
                            return <div key={`${value}-${i}`} className={`cf-products-option-item ${i == 0 ? 'cf-products-item-button--selected' : ''}`}>{value}</div>;
                          })}
                        </div>
                      )}

                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="cf-form-2 cf-form-theme-default cf-field-regular cf-products-item-variants">
                <p className="cf-variant-picker-label" style={{ fontSize: "13px" }}>
                  {variantPickerLabel}
                </p>

                <select disabled={props.builder == true ? true : undefined}>
                  {product && product?.variants && product?.variants.length > 0 ? (
                    <React.Fragment>
                      {product?.variants.map((productVariant) => {
                        return (
                          <option key={generateRandomKey()} value={productVariant.id}>
                            {productVariant.title == "Default Title" ? product?.title : productVariant.title || productTitleText}
                          </option>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <option value="">{product?.title}</option>
                  )}
                </select>
              </div>
            )}
        </div>
      </React.Fragment>
    )
  }

  const ProductSubscription = (props) => {
    if (props.condition == false || element.options["products-source"] !== "ShopifyGraphql") {
      return null;
    }

    return (
      <React.Fragment>
        <div key={generateRandomKey()} className="cf-products-subscription-dropdown-wrapper" style={{ display: element.options["confirmation-type"] == "cart" ? 'block' : 'none', width: '100%'}}>
          <div className="cf-form-2 cf-form-theme-default cf-field-regular">
            <p className="cf-products-subscription-label" style={{ fontSize: "13px" }}>
              {element.options["products-subscription-label-text"] ? element.options["products-subscription-label-text"] : "Frequency"}
            </p>

            {(!element.options["products-subscription-choices-type"] || element.options["products-subscription-choices-type"] == "dropdown" && product) && (
              <select className="cf-subscription-select" disabled={props.builder == true ? true : undefined}>
                {(product.requires_selling_plan !== true || product.subscriptions?.length == 0) && (<option value="">{element.options["products-subscription-default-plan-text"] ? element.options["products-subscription-default-plan-text"] : "One-time purchase"}</option>)}

                {product.subscriptions && product.subscriptions.map((subscriptionGroup) => {
                  return Object.values(subscriptionGroup.sellingPlans).map((subscription) => {
                    return (
                      <option key={Math.random().toString(16).slice(2) + subscription.id} value={subscription.id}>
                        {subscriptionGroup.name} - {subscription.name}
                      </option>
                    );
                  })
                })}
              </select>
            )}

            {(element.options["products-subscription-choices-type"] == "radios" && product) && (
              <div className="cf-products-subscription-radio-wrapper" data-font={element.options["products-subscription-choices-font-family"]}>
                {(product.requires_selling_plan !== true || product.subscriptions?.length == 0) && (
                  <p>
                    <input type="radio" id="one-time-purchase" name="cf-subscription-type" value="" />
                    <label htmlFor="one-time-purchase">
                      {element.options["products-subscription-default-plan-text"] ? element.options["products-subscription-default-plan-text"] : "One-time purchase"}
                    </label>
                  </p>
                )}

                {product.subscriptions && product.subscriptions.map((subscriptionGroup) => {
                  return Object.values(subscriptionGroup.sellingPlans).map((subscription) => {
                    return (
                      <p key={Math.random().toString(16).slice(2) + subscription.id}>
                        <input type="radio" id={subscription.id} name="cf-subscription-type" value={subscription.id} />
                        <label htmlFor={subscription.id}>{subscriptionGroup.name} - {subscription.name}</label>
                      </p>
                    );
                  })
                })}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  const ProductButton = (props) => {
    if (props.condition == false) {
      return null;
    }

    let buttonClasses = "element-content cf-button cf-button-primary cf-text-element";

    buttonClasses += " " + (element.options["button-size"] ? element.options["button-size"] : "cf-button-small");

    let buttonLayoutClasses =
      " " +
      (element.options["products-layout"] !== "list" && element.options["button-alignment"]
        ? element.options["button-alignment"]
        : "");

    let buttonText = element.html
      ? element.html
      : element.options["confirmation-type"] == "cart" && element.options["products-source"]?.toLowerCase() !== "convertflow"
      ? "<p>Add To Cart</p>"
      : "<p>Go To Product</p>";

    return (
      <React.Fragment>
        {element.options["products-button"] !== "false" || element.options["confirmation-type"] == "cart" ?
          <div className="cf-product-hero-outer-button">
            <div
              key={generateRandomKey()}
              className={`cf-row cf-outer-center cf-products-item-button-outer ${buttonLayoutClasses}`}
              data-element-id={element.id}
              data-font={element.options["button-font"]}
            >
              <div
                className={`${buttonClasses} cf-products-item-button cf-merge-tag-allowed`}
                dangerouslySetInnerHTML={{
                  __html:
                    props.builder == true
                      ? "<div class='froala-editor'>" + buttonText + "</div>"
                      : buttonText,
                }}
              ></div>
            </div>
          </div>
        : null}
      </React.Fragment>
    )
  }

  const ProductEmptyMessage = (props) => {
    if (props.condition == false) {
      return null;
    }

    const emptyMessage = element.options["products-empty-message"]
      ? element.options["products-empty-message"]
      : "<p>Sorry, there are no products to display</p>";

    return (
      <React.Fragment>
        <div
          key={generateRandomKey()}
          className="cf-row cf-outer-center cf-products-empty-message cf-merge-tag-allowed"
          dangerouslySetInnerHTML={{ __html: emptyMessage }}
          data-element-id={element.id}
        />
      </React.Fragment>
    )
  }

  const OrderedProductContent = () => {
    const ComponentTypes = {
      "offer-badge": ProductOfferBadge,
      "reviews": ProductReviews,
      "title": ProductTitle,
      "price": ProductPrice,
      "description": ProductDescription,
      "quantity-picker": ProductQuantityPicker,
      "variant-picker": ProductVariantPicker,
      "subscription": ProductSubscription,
      "button": ProductButton,
    };

    let sorted_content_types = Object.keys(ComponentTypes)
      .map((type) => ({
        key: type,
        show: element.options[`${type}-show`],
        position: parseInt(element.options[`${type}-position`]),
      }))

    return (
      <React.Fragment>
        {sorted_content_types
          .filter((type) => {
            let consideredShowValue = type.show;

            if (type.key == "quantity-picker" && element.options["quantity-picker-show"] == undefined) {
              consideredShowValue = "false";
            }

            if (type.key == "subscription" && element.options["subscription-show"] == undefined) {
              consideredShowValue = "false";
            }

            if (type.key == "reviews") {
              if (element.options["reviews-show"] == undefined || element.options["products-source"] !== "ShopifyGraphql") {
                consideredShowValue = "false";
              }
            }

            return consideredShowValue !== "false";
          })
          .sort((a, b) => a.position - b.position)
          .map((content_type) => {
            return (
              <div className="cf-product-content-type" data-content-type={content_type.title} key={`cf-product-${element.id}-type-${content_type.key}`}>
                {React.createElement(ComponentTypes[content_type.key], {
                  condition: content_type.show,
                  ...props,
                })}
              </div>
            )
          })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div
        className={`cf-products-item--shopify-graphql cf-products-outer ${classes}`}
        data-product-id={product?.id}
        data-element-id={element.id}
      >
        <div className="cf-product-hero-inner cf-products-item" data-product-id={product.id}>
          <ProductImage condition={element.options["image-show"] !== "false"} {...props} />

          <div className="cf-prod-hero-inner-box">
            <div className="cf-prod-hero-content-box">
              <OrderedProductContent />
            </div>
          </div>

          <style dangerouslySetInnerHTML={{ __html: stylesheet }} />

        </div>
      </div>

      <ProductEmptyMessage />
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-product-hero-element ";
    let outerAlignmentClass = "cf-outer-center";
    let innerAlignmentClass = "cf-inner-left";

    if (options["cf-alignment"]) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        outerAlignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        outerAlignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        outerAlignmentClass = "cf-outer-right";
      }
    }

    classes += " " + outerAlignmentClass + " " + innerAlignmentClass;

    return classes;
  },

  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    const productLayout = options["products-layout"];

    let innerPadding;
    if (options["products-item-padding"]) {
      const paddingValue = parseInt(options["products-item-padding"]);
      innerPadding = `${paddingValue}px`;
    }

    let innerPaddingMobile;
    if (options["products-item-padding-mobile"]) {
      const paddingValueMobile = parseInt(
        options["products-item-padding-mobile"]
      );
      innerPaddingMobile = `${paddingValueMobile}px`;
    }

    let outerPadding;
    if (options["products-padding"]) {
      const paddingValue = parseInt(options["products-padding"]);
      outerPadding = `${paddingValue}px`;
    }

    let outerPaddingMobile;
    if (options["products-padding-mobile"]) {
      const paddingValueMobile = parseInt(options["products-padding-mobile"]);
      outerPaddingMobile = `${paddingValueMobile}px`;
    }

    let verticalMargin;
    if (options["products-margin-vertical"]) {
      const marginValue = parseInt(options["products-margin-vertical"]);
      verticalMargin = `${marginValue}px`;
    }

    let verticalMarginMobile;
    if (options["products-margin-vertical-mobile"]) {
      const marginValueMobile = parseInt(
        options["products-margin-vertical-mobile"]
      );
      verticalMarginMobile = `${marginValueMobile}px`;
    }

    let borderRadius = "";
    if (options["products-item-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["products-item-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["products-item-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["products-item-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["products-item-radius-" + side]) == false
              ? options["products-item-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let boxShadow;

    if (options["products-item-shadow"] == "None") {
      boxShadow = "none";
    }

    if (options["products-item-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["products-item-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["products-item-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (options["products-item-shadow-horizontal-distance"]
          ? `${options["products-item-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-vertical-distance"]
          ? `${options["products-item-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-blur-radius"]
          ? `${options["products-item-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-spread-radius"]
          ? `${options["products-item-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["products-item-shadow-color"]
          ? options["products-item-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    let buttonBorderRadius = "";
    if (options["button-radius"] == "cf-not-rounded") {
      buttonBorderRadius = "0px";
    }
    if (options["button-radius"] == "cf-slightly-rounded") {
      buttonBorderRadius = "4px";
    }
    if (options["button-radius"] == "cf-fully-rounded") {
      buttonBorderRadius = "100px";
    }
    if (options["button-radius"] == "custom") {
      buttonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          buttonBorderRadius += `${
            _.isNil(options["button-radius-" + side]) == false
              ? options["button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let buttonShadow;
    let buttonShadowHover;

    if (options["button-shadow"] == "None") {
      buttonShadow = "none";
    }

    if (options["button-shadow"] == "soft") {
      buttonShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["button-shadow"] == "hard") {
      buttonShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["button-shadow"] == "custom") {
      buttonShadow = "";
      buttonShadow +=
        (options["button-shadow-horizontal-distance"]
          ? `${options["button-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-vertical-distance"]
          ? `${options["button-shadow-vertical-distance"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-blur-radius"]
          ? `${options["button-shadow-blur-radius"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-spread-radius"]
          ? `${options["button-shadow-spread-radius"]}px`
          : "0px") + " ";

      buttonShadow +=
        (options["button-shadow-color"]
          ? options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      buttonShadowHover =
        buttonShadow +
        (options["button-hover-shadow-color"]
          ? options["button-hover-shadow-color"]
          : options["button-shadow-color"]
          ? options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      buttonShadow += options["button-shadow-inset"] == "true" ? "inset" : "";
      buttonShadowHover +=
        options["button-shadow-inset"] == "true" ? "inset" : "";
    }

    let buttonFont;
    if (options["button-font"] && options["button-font"] !== "theme") {
      buttonFont = options["button-font"];
    }

    let buttonFontWeight;
    if (
      options["button-font-weight"] &&
      options["button-font-weight"] !== "theme"
    ) {
      buttonFontWeight = options["button-font-weight"];
    }

    let buttonFontSize;
    if (options["button-font-size"]) {
      buttonFontSize = options["button-font-size"];
    }

    let imageBorderRadius = "";
    if (options["products-image-corner-rounding"] == "cf-not-rounded") {
      imageBorderRadius = "0px";
    }
    if (options["products-image-corner-rounding"] == "cf-slightly-rounded") {
      imageBorderRadius = "4px";
    }
    if (options["products-image-corner-rounding"] == "cf-fully-rounded") {
      imageBorderRadius = "100px";
    }
    if (options["products-image-corner-rounding"] == "custom") {
      imageBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          imageBorderRadius += `${
            _.isNil(options["products-image-corner-" + side]) == false
              ? options["products-image-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    let imageBadgePosition;
    if (element.options["products-image-badge-placement"] == "top-right") {
      imageBadgePosition = "top: 0; right: 0;";
    } else if (
      element.options["products-image-badge-placement"] == "top-left"
    ) {
      imageBadgePosition = "top: 0; left: 0;";
    } else if (
      element.options["products-image-badge-placement"] == "bottom-right"
    ) {
      imageBadgePosition = "bottom: 0; right: 0;";
    } else if (
      element.options["products-image-badge-placement"] == "bottom-left"
    ) {
      imageBadgePosition = "bottom: 0; left: 0;";
    }

    let imageBadgeBackground = element.options["products-image-badge-background"] || variant.options["products-hero-offer-badge-background"];

    let imageBadgeCornerRounding;
    if (options["products-image-badge-corner-rounding"] == "cf-not-rounded") {
      imageBadgeCornerRounding = "0px";
    }
    if (
      options["products-image-badge-corner-rounding"] == "cf-slightly-rounded"
    ) {
      imageBadgeCornerRounding = "4px";
    }
    if (options["products-image-badge-corner-rounding"] == "cf-fully-rounded") {
      imageBadgeCornerRounding = "100px";
    }
    if (options["products-image-badge-corner-rounding"] == "custom") {
      imageBadgeCornerRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          imageBadgeCornerRounding += `${
            _.isNil(options["products-image-badge-corner-" + side]) == false
              ? options["products-image-badge-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    let themeImageBadgeCornerRounding;
    if (variant.options["products-hero-offer-badge-corner-rounding"] == "cf-not-rounded") {
      themeImageBadgeCornerRounding = "0px";
    }
    if (
      variant.options["products-hero-offer-badge-corner-rounding"] == "cf-slightly-rounded"
    ) {
      themeImageBadgeCornerRounding = "4px";
    }
    if (variant.options["products-hero-offer-badge-corner-rounding"] == "cf-fully-rounded") {
      themeImageBadgeCornerRounding = "100px";
    }
    if (variant.options["products-hero-offer-badge-corner-rounding"] == "custom") {
      themeImageBadgeCornerRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          themeImageBadgeCornerRounding += `${
            _.isNil(variant.options["products-hero-offer-badge-corner-" + side]) == false
              ? variant.options["products-hero-offer-badge-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    let imageBadgeFontFamily = element.options["products-image-badge-font-family"] || variant.options["products-hero-offer-badge-font-family"];
    let imageBadgeFontWeight = element.options["products-image-badge-font-family-weight"] || variant.options["products-hero-offer-badge-font-family-weight"];
    let imageBadgeFontSize = element.options["products-image-badge-font-size"] || variant.options["products-hero-offer-badge-font-size"];
    let imageBadgeFontColor = element.options["products-image-badge-text-color"] || variant.options["products-hero-offer-badge-text-color"];

    const quantityPickerShowLabel = element.options["products-quantity-picker-show-label"] == "true" ? true : false;
    const variantPickerShowLabel = element.options["products-variant-picker-show-label"] == "true" ? true : false;

    const quantityPickerButtonMinimumWidth = element.options["products-quantity-picker-minimum-width"]
      ? element.options["products-quantity-picker-minimum-width"]
      : undefined

    const quantityPickerFontFamily = element.options["products-quantity-picker-font-family"]
    const quantityPickerFontWeight = element.options["products-quantity-picker-font-weight"]
    const quantityPickerFontSize = element.options["products-quantity-picker-font-size"]
    const quantityPickerFontColor = element.options["products-quantity-picker-text-color"]
    const quantityPickerBackgroundColor = element.options["products-quantity-picker-background-color"]
    const quantityPickerBorderStyle = element.options["products-quantity-picker-border-style"]
    const quantityPickerBorderColor = element.options["products-quantity-picker-border-color"]
    const quantityPickerBorderHoverColor = element.options["products-quantity-picker-border-hover-color"] || variant.options["products-hero-quantity-picker-border-hover-color"]
    const quantityPickerBorderWidthLeft = element.options["products-quantity-picker-border-left"]
    const quantityPickerBorderWidthRight = element.options["products-quantity-picker-border-right"]
    const quantityPickerBorderWidthTop = element.options["products-quantity-picker-border-top"]
    const quantityPickerBorderWidthBottom = element.options["products-quantity-picker-border-bottom"]

    let pickerBorderRounding;
    if (element.options["products-quantity-picker-corner-rounding"] == "cf-not-rounded") {
      pickerBorderRounding = "0px";
    }
    if (element.options["products-quantity-picker-corner-rounding"] == "cf-slightly-rounded") {
      pickerBorderRounding = "4px";
    }
    if (element.options["products-quantity-picker-corner-rounding"] == "cf-fully-rounded") {
      pickerBorderRounding = "100px";
    }
    if (element.options["products-quantity-picker-corner-rounding"] == "custom") {
      pickerBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          pickerBorderRounding += `${
            _.isNil(element.options["products-quantity-picker-corner-" + side]) == false
              ? element.options["products-quantity-picker-corner-" + side]
              : "4"
          }px `;
        }
      );
    }

    const variantPickerOptionsMinimumWidth = element.options["products-variant-picker-button-minimum-width"] || variant.options["products-hero-variant-picker-button-minimum-width"];
    const variantPickerFontFamily = element.options["products-variant-picker-font-family"] || variant.options["products-hero-variant-picker-font-family"];
    const variantPickerFontWeight = element.options["products-variant-picker-font-weight"] || variant.options["products-hero-variant-picker-font-weight"];
    const variantPickerFontSize = element.options["products-variant-picker-font-size"] || variant.options["products-hero-variant-picker-font-size"];
    const variantPickerFontColor = element.options["products-variant-picker-text-color"] || variant.options["products-hero-variant-picker-text-color"];
    let variantPickerFontHoverColor;
    if (element.options["products-variant-picker-text-hover-color"]) {
      variantPickerFontHoverColor = element.options["products-variant-picker-text-hover-color"];
    } else if (variant.options["products-hero-variant-picker-text-hover-color"]) {
      variantPickerFontHoverColor = variant.options["products-hero-variant-picker-text-hover-color"];
    } else if (element.options["products-variant-picker-style"] == "cf-options-buttons") {
      variantPickerFontHoverColor = "#fff";
    } else {
      variantPickerFontHoverColor = "#000";
    }
    const variantPickerBorderStyle = element.options["products-variant-picker-border-style"] || variant.options["products-hero-variant-picker-border-style"];
    const variantPickerBorderColor = element.options["products-variant-picker-border-color"] || variant.options["products-hero-variant-picker-border-color"];
    const variantPickerBorderHoverColor = element.options["products-variant-picker-border-hover-color"] || variant.options["products-hero-variant-picker-border-hover-color"];
    const variantPickerBorderWidthLeft = element.options["products-variant-picker-border-left"] || variant.options["products-hero-variant-picker-border-left"];
    const variantPickerBorderWidthRight = element.options["products-variant-picker-border-right"] || variant.options["products-hero-variant-picker-border-right"];
    const variantPickerBorderWidthTop = element.options["products-variant-picker-border-top"] || variant.options["products-hero-variant-picker-border-top"];
    const variantPickerBorderWidthBottom = element.options["products-variant-picker-border-bottom"] || variant.options["products-hero-variant-picker-border-bottom"];
    const variantPickerBackgroundColor = element.options["products-variant-picker-background-color"] || variant.options["products-hero-variant-picker-background-color"];
    const variantPickerBackgroundHoverColor = element.options["products-variant-picker-background-hover-color"] || variant.options["products-hero-variant-picker-background-hover-color"] || "#000";

    let variantPickerBorderRounding;
    if (element.options["products-variant-picker-corner-rounding"] == "cf-not-rounded") {
      variantPickerBorderRounding = "0px";
    }
    if (element.options["products-variant-picker-corner-rounding"] == "cf-slightly-rounded") {
      variantPickerBorderRounding = "4px";
    }
    if (element.options["products-variant-picker-corner-rounding"] == "cf-fully-rounded") {
      variantPickerBorderRounding = "100px";
    }
    if (element.options["products-variant-picker-corner-rounding"] == "custom") {
      variantPickerBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          variantPickerBorderRounding += `${
            _.isNil(element.options["products-variant-picker-corner-" + side]) == false
              ? element.options["products-variant-picker-corner-" + side]
              : "1"
          }px `;
        }
      );
    }

    let themeVariantPickerBorderRounding;
    if (variant.options["products-hero-variant-picker-corner-rounding"] == "cf-not-rounded") {
      themeVariantPickerBorderRounding = "0px";
    }
    if (variant.options["products-hero-variant-picker-corner-rounding"] == "cf-slightly-rounded") {
      themeVariantPickerBorderRounding = "4px";
    }
    if (variant.options["products-hero-variant-picker-corner-rounding"] == "cf-fully-rounded") {
      themeVariantPickerBorderRounding = "100px";
    }
    if (variant.options["products-hero-variant-picker-corner-rounding"] == "custom") {
      themeVariantPickerBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          themeVariantPickerBorderRounding += `${
            _.isNil(variant.options["products-hero-variant-picker-corner-" + side]) == false
              ? variant.options["products-hero-variant-picker-corner-" + side]
              : "1"
          }px `;
        }
      );
    }

    const subscriptionChoicesFontFamily = element.options["products-subscription-choices-font-family"] || variant.options["products-hero-subscription-choices-font-family"];
    const subscriptionChoicesFontWeight = element.options["products-subscription-choices-font-weight"] || variant.options["products-hero-subscription-choices-font-weight"];
    const subscriptionChoicesFontSize = element.options["products-subscription-choices-font-size"] || variant.options["products-hero-subscription-choices-font-size"];
    const subscriptionChoicesFontColor = element.options["products-subscription-choices-text-color"] || variant.options["products-hero-subscription-choices-text-color"];
    const subscriptionChoicesFontHoverColor = element.options["products-subscription-choices-text-hover-color"] || variant.options["products-hero-subscription-choices-text-hover-color"];
    const subscriptionChoicesBackgroundColor = element.options["products-subscription-choices-background-color"] || variant.options["products-hero-subscription-choices-background-color"];
    const subscriptionChoicesRadioColor = element.options["products-subscription-radio-color"] || variant.options["products-hero-subscription-radio-color"];
    const subscriptionChoicesSelectedRadioColor = element.options["products-subscription-radio-selected-color"] || variant.options["products-hero-subscription-radio-selected-color"];

    let subscriptionChoicesBorderRounding;
    if (element.options["products-subscription-corner-rounding"] == "cf-not-rounded") {
      subscriptionChoicesBorderRounding = "0px";
    }
    if (element.options["products-subscription-corner-rounding"] == "cf-slightly-rounded") {
      subscriptionChoicesBorderRounding = "4px";
    }
    if (element.options["products-subscription-corner-rounding"] == "cf-fully-rounded") {
      subscriptionChoicesBorderRounding = "100px";
    }
    if (element.options["products-subscription-corner-rounding"] == "custom") {
      subscriptionChoicesBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          subscriptionChoicesBorderRounding += `${
            _.isNil(element.options["products-subscription-corner-" + side]) == false
              ? element.options["products-subscription-corner-" + side]
              : "1"
          }px `;
        }
      );
    }

    let variantSubscriptionChoicesBorderRounding;
    if (variant.options["products-hero-subscription-corner-rounding"] == "cf-not-rounded") {
      variantSubscriptionChoicesBorderRounding = "0px";
    }
    if (variant.options["products-hero-subscription-corner-rounding"] == "cf-slightly-rounded") {
      variantSubscriptionChoicesBorderRounding = "4px";
    }
    if (variant.options["products-hero-subscription-corner-rounding"] == "cf-fully-rounded") {
      variantSubscriptionChoicesBorderRounding = "100px";
    }
    if (variant.options["products-hero-subscription-corner-rounding"] == "custom") {
      variantSubscriptionChoicesBorderRounding = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          variantSubscriptionChoicesBorderRounding += `${
            _.isNil(variant.options["products-hero-subscription-corner-" + side]) == false
              ? variant.options["products-hero-subscription-corner-" + side]
              : "1"
          }px `;
        }
      );
    }

    const subscriptionChoicesDropdownBorderStyle = element.options["products-subscription-border-style"] || variant.options["products-hero-subscription-border-style"];
    const subscriptionChoicesDropdownBorderColor = element.options["products-subscription-border-color"] || variant.options["products-hero-subscription-border-color"];
    const subscriptionChoicesDropdownBorderHoverColor = element.options["products-subscription-border-hover-color"] || variant.options["products-hero-subscription-border-hover-color"];
    const subscriptionChoicesDropdownBorderWidthLeft = element.options["products-subscription-border-left"] || variant.options["products-hero-subscription-border-left"];
    const subscriptionChoicesDropdownBorderWidthRight = element.options["products-subscription-border-right"] || variant.options["products-hero-subscription-border-right"];
    const subscriptionChoicesDropdownBorderWidthTop = element.options["products-subscription-border-top"] || variant.options["products-hero-subscription-border-top"];
    const subscriptionChoicesDropdownBorderWidthBottom = element.options["products-subscription-border-bottom"] || variant.options["products-hero-subscription-border-bottom"];
    const subscriptionChoicesShowLabel = element.options["products-subscription-show-label"] == "false" ? false : true;

    const contentTypeSpacing = element.options["products-content-type-spacing"];
    const productImageCarouselHeight = element.options["products-carousel-image-height"];

    let addAllButtonBorderRadius = "";
    if (options["products-add-all-btn-radius"] == "cf-not-rounded") {
      addAllButtonBorderRadius = "0px";
    }
    if (options["products-add-all-btn-radius"] == "cf-slightly-rounded") {
      addAllButtonBorderRadius = "4px";
    }
    if (options["products-add-all-btn-radius"] == "cf-fully-rounded") {
      addAllButtonBorderRadius = "100px";
    }
    if (options["products-add-all-btn-radius"] == "custom") {
      addAllButtonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          addAllButtonBorderRadius += `${
            _.isNil(options["products-add-all-btn-radius-" + side]) == false
              ? options["products-add-all-btn-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let addAllButtonShadow;
    let addAllButtonShadowHover;

    if (options["products-add-all-btn-shadow"] == "None") {
      addAllButtonShadow = "none";
    }

    if (options["products-add-all-btn-shadow"] == "soft") {
      addAllButtonShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["products-add-all-btn-shadow"] == "hard") {
      addAllButtonShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["products-add-all-btn-shadow"] == "custom") {
      addAllButtonShadow = "";
      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-horizontal-distance"]
          ? `${options["products-add-all-btn-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-vertical-distance"]
          ? `${options["products-add-all-btn-shadow-vertical-distance"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-blur-radius"]
          ? `${options["products-add-all-btn-shadow-blur-radius"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-spread-radius"]
          ? `${options["products-add-all-btn-shadow-spread-radius"]}px`
          : "0px") + " ";

      addAllButtonShadow +=
        (options["products-add-all-btn-shadow-color"]
          ? options["products-add-all-btn-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      addAllButtonShadowHover =
        addAllButtonShadow +
        (options["button-hover-shadow-color"]
          ? options["button-hover-shadow-color"]
          : options["products-add-all-btn-shadow-color"]
          ? options["products-add-all-btn-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      addAllButtonShadow += options["products-add-all-btn-shadow-inset"] == "true" ? "inset" : "";
      addAllButtonShadowHover +=
        options["products-add-all-btn-shadow-inset"] == "true" ? "inset" : "";
    }

    const reviewsShowCount = element.options["products-reviews-show-count"] !== "false";
    const reviewsShowLabel = element.options["products-reviews-show-label"] !== "false";
    const reviewsStarColor = element.options["products-reviews-star-color"];
    const reviewsStarSize = element.options["products-reviews-star-size"];
    const reviewsFontFamily = element.options["products-reviews-font-family"];
    const reviewsFontWeight = element.options["products-reviews-font-weight"];
    const reviewsFontSize = element.options["products-reviews-font-size"];
    const reviewsFontColor = element.options["products-reviews-text-color"];

    const productsContentLabelFontSize = options["products-content-label-font-size"];
    const productsContentLabelFontWeight = options["products-content-label-font-weight"];
    const productsContentLabelFontFamily = options["products-content-label-font-family"];
    const productsContentLabelLineHeight = options["products-content-label-line-height"];
    const productsContentLabelFontColor = options["products-content-label-font-color"];

    let productButtonWidth;
    if (options["button-width"] == "cf-button-slim") {
      productButtonWidth = "min-width: fit-content !important;"
    } else if (options["button-width"] == "cf-button-mid") {
      productButtonWidth = "min-width: 150px !important;"
    } else if (options["button-width"] == "cf-full-width") {
      productButtonWidth = "min-width: 100% !important;"
    } else if (options["button-width"] == "custom") {
      productButtonWidth = "min-width: " + options["button-width-value"] + "px !important;"
    }

    let productsInnerAlignment;
    if (options["cf-inner-alignment"] == "cf-left") {
      productsInnerAlignment = "justify-content: flex-start !important; text-align: left !important;"
    } else if (options["cf-inner-alignment"] == "cf-center") {
      productsInnerAlignment = "justify-content: center !important; text-align: center !important;"
    } else if (options["cf-inner-alignment"] == "cf-right") {
      productsInnerAlignment = "justify-content: flex-end !important; text-align: right !important;"
    }

    // prettier-ignore
    let desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-prod-hero-image-box {
        ${productLayout == "cf-left" ? "justify-content: start !important;" : "justify-content: end !important;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-subscription-radio-wrapper {
        ${options["cf-inner-alignment"] == "center" ? "margin-left: auto; margin-right: auto;" : ""}
        ${options["cf-inner-alignment"] == "right" ? "margin-left: auto;" : ""}
        ${options["cf-inner-alignment"] == "left" ? "margin-right: auto;" : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-item-title,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-item-description,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-prod-hero-content-box,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-options-wrapper,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quantity-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-variant-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-subscription-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-content-type {
        ${productsInnerAlignment ? productsInnerAlignment : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-quantity-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-variant-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-label {
        ${productsContentLabelFontSize ? `font-size: ${productsContentLabelFontSize}px !important;` : ""}
        ${productsContentLabelFontWeight ? `font-weight: ${productsContentLabelFontWeight} !important;` : ""}
        ${productsContentLabelFontFamily ? `font-family: ${productsContentLabelFontFamily} !important;` : ""}
        ${productsContentLabelLineHeight ? `line-height: ${productsContentLabelLineHeight}px !important;` : ""}
        ${productsContentLabelFontColor ? `color: ${productsContentLabelFontColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper select {
        ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
        ${variantPickerFontFamily ? `font-family: ${variantPickerFontFamily} !important;` : ''}
        ${variantPickerFontWeight ? `font-weight: ${variantPickerFontWeight} !important;` : ""}
        ${variantPickerFontSize ? `font-size: ${variantPickerFontSize}px !important;` : ""}
        ${variantPickerFontColor ? `color: ${variantPickerFontColor} !important;` : ""}
        ${variantPickerBorderStyle ? `border-style: ${variantPickerBorderStyle} !important;` : ""}
        ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
        ${variantPickerBackgroundColor ? `background: ${variantPickerBackgroundColor} !important;` : ""}
        ${variantPickerBorderWidthLeft ? `border-left-width: ${variantPickerBorderWidthLeft}px !important;` : ""}
        ${variantPickerBorderWidthRight ? `border-right-width: ${variantPickerBorderWidthRight}px !important;` : ""}
        ${variantPickerBorderWidthTop ? `border-top-width: ${variantPickerBorderWidthTop}px !important;` : ""}
        ${variantPickerBorderWidthBottom ? `border-bottom-width: ${variantPickerBorderWidthBottom}px !important;` : ""}
        ${variantPickerBorderRounding ? `border-radius: ${variantPickerBorderRounding} !important;` : themeVariantPickerBorderRounding ? `border-radius: ${themeVariantPickerBorderRounding} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-products-subscription-dropdown-wrapper select {
        ${subscriptionChoicesBackgroundColor ? `background: ${subscriptionChoicesBackgroundColor} !important;` : ""}
        ${subscriptionChoicesDropdownBorderStyle ? `border-style: ${subscriptionChoicesDropdownBorderStyle} !important;` : ""}
        ${subscriptionChoicesDropdownBorderColor ? `border-color: ${subscriptionChoicesDropdownBorderColor} !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthLeft ? `border-left-width: ${subscriptionChoicesDropdownBorderWidthLeft}px !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthRight ? `border-right-width: ${subscriptionChoicesDropdownBorderWidthRight}px !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthTop ? `border-top-width: ${subscriptionChoicesDropdownBorderWidthTop}px !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthBottom ? `border-bottom-width: ${subscriptionChoicesDropdownBorderWidthBottom}px !important;` : ""}
        ${subscriptionChoicesBorderRounding ? `border-radius: ${subscriptionChoicesBorderRounding} !important;` : variantSubscriptionChoicesBorderRounding ? `border-radius: ${variantSubscriptionChoicesBorderRounding} !important;` : ""}
        ${subscriptionChoicesDropdownBorderHoverColor ? `&:hover { border-color: ${subscriptionChoicesDropdownBorderHoverColor} !important; }` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews-count {
        display: ${reviewsShowCount ? "block" : "none"};
        ${reviewsFontFamily ? `font-family: ${reviewsFontFamily} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews-label {
        display: ${reviewsShowLabel ? "block" : "none"};
        ${reviewsFontFamily ? `font-family: ${reviewsFontFamily} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews {
        ${reviewsFontWeight ? `font-weight: ${reviewsFontWeight} !important;` : ""}
        ${reviewsFontSize ? `font-size: ${reviewsFontSize}px !important;` : ""}
        ${reviewsFontColor ? `color: ${reviewsFontColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews-stars-inner svg {
        ${reviewsStarColor ? `color: ${reviewsStarColor};` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews-stars-inner .svg-inline--fa {
        ${reviewsStarSize ? `font-size: ${reviewsStarSize}px;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-quantity-picker-label {
        display: ${quantityPickerShowLabel ? "block" : "none"};
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product__image {
        ${productImageCarouselHeight ? `height: ${productImageCarouselHeight}px;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-variant-picker-label {
        display: ${variantPickerShowLabel ? "block" : "none"};
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-label {
        display: ${subscriptionChoicesShowLabel ? "block" : "none"} !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product-content-type {
        ${contentTypeSpacing ? `margin-bottom: ${contentTypeSpacing}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-products-subscription-dropdown-wrapper select,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-radio-wrapper label {
        ${subscriptionChoicesFontFamily ? `font-family: ${subscriptionChoicesFontFamily} !important;` : ""}
        ${subscriptionChoicesFontWeight ? `font-weight: ${subscriptionChoicesFontWeight} !important;` : ""}
        ${subscriptionChoicesFontSize ? `font-size: ${subscriptionChoicesFontSize}px !important;` : ""}
        ${subscriptionChoicesFontColor ? `color: ${subscriptionChoicesFontColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-products-subscription-dropdown-wrapper select {
        ${subscriptionChoicesFontHoverColor ? `&:hover { color: ${subscriptionChoicesFontHoverColor} !important; }` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-radio-wrapper label {
        flex: 1;
        text-align: left;
        width: 100%;
        ${subscriptionChoicesFontHoverColor ? `&:hover { color: ${subscriptionChoicesFontHoverColor} !important; }` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-products-subscription-dropdown-wrapper select {
        ${subscriptionChoicesBackgroundColor ? `background: ${subscriptionChoicesBackgroundColor} !important;` : ""}
        ${subscriptionChoicesDropdownBorderStyle ? `border-style: ${subscriptionChoicesDropdownBorderStyle} !important;` : ""}
        ${subscriptionChoicesDropdownBorderColor ? `border-color: ${subscriptionChoicesDropdownBorderColor} !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthLeft ? `border-left-width: ${subscriptionChoicesDropdownBorderWidthLeft}px !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthRight ? `border-right-width: ${subscriptionChoicesDropdownBorderWidthRight}px !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthTop ? `border-top-width: ${subscriptionChoicesDropdownBorderWidthTop}px !important;` : ""}
        ${subscriptionChoicesDropdownBorderWidthBottom ? `border-bottom-width: ${subscriptionChoicesDropdownBorderWidthBottom}px !important;` : ""}
        ${subscriptionChoicesBorderRounding ? `border-radius: ${subscriptionChoicesBorderRounding} !important;` : ""}
        ${subscriptionChoicesDropdownBorderHoverColor ? `&:hover { border-color: ${subscriptionChoicesDropdownBorderHoverColor} !important; }` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-radio-wrapper input[type="radio"] {
        ${subscriptionChoicesRadioColor ? `background-color: ${subscriptionChoicesRadioColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-radio-wrapper input[type="radio"]:checked {
        ${subscriptionChoicesSelectedRadioColor ? `background-color: ${subscriptionChoicesSelectedRadioColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select {
        ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select {
        ${variantPickerFontFamily ? `font-family: ${variantPickerFontFamily} !important;` : ''}
        ${variantPickerFontWeight ? `font-weight: ${variantPickerFontWeight} !important;` : ""}
        ${variantPickerFontSize ? `font-size: ${variantPickerFontSize}px !important;` : ""}
        ${variantPickerFontColor ? `color: ${variantPickerFontColor} !important;` : ""}
        ${variantPickerBorderStyle ? `border-style: ${variantPickerBorderStyle} !important;` : ""}
        ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
        ${variantPickerBackgroundColor ? `background: ${variantPickerBackgroundColor} !important;` : ""}
        ${variantPickerBorderWidthLeft ? `border-left-width: ${variantPickerBorderWidthLeft}px !important;` : ""}
        ${variantPickerBorderWidthRight ? `border-right-width: ${variantPickerBorderWidthRight}px !important;` : ""}
        ${variantPickerBorderWidthTop ? `border-top-width: ${variantPickerBorderWidthTop}px !important;` : ""}
        ${variantPickerBorderWidthBottom ? `border-bottom-width: ${variantPickerBorderWidthBottom}px !important;` : ""}
        ${variantPickerBorderRounding ? `border-radius: ${variantPickerBorderRounding} !important;` : themeVariantPickerBorderRounding ? `border-radius: ${themeVariantPickerBorderRounding} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-option-item {
        ${variantPickerOptionsMinimumWidth ? `min-width: ${variantPickerOptionsMinimumWidth}px !important;` : ""}
        ${variantPickerFontFamily ? `font-family: ${variantPickerFontFamily} !important;` : ""}
        ${variantPickerFontWeight ? `font-weight: ${variantPickerFontWeight} !important;` : ""}
        ${variantPickerFontSize ? `font-size: ${variantPickerFontSize}px !important;` : ""}
        ${variantPickerFontColor ? `color: ${variantPickerFontColor} !important;` : ""}
        ${variantPickerBorderStyle ? `border-style: ${variantPickerBorderStyle} !important;` : ""}
        ${variantPickerBorderColor ? `border-color: ${variantPickerBorderColor} !important;` : ""}
        ${variantPickerBackgroundColor ? `background: ${variantPickerBackgroundColor} !important;` : ""}
        ${variantPickerBorderWidthLeft ? `border-left-width: ${variantPickerBorderWidthLeft}px !important;` : ""}
        ${variantPickerBorderWidthRight ? `border-right-width: ${variantPickerBorderWidthRight}px !important;` : ""}
        ${variantPickerBorderWidthTop ? `border-top-width: ${variantPickerBorderWidthTop}px !important;` : ""}
        ${variantPickerBorderWidthBottom ? `border-bottom-width: ${variantPickerBorderWidthBottom}px !important;` : ""}
        ${variantPickerBorderRounding ? `border-radius: ${variantPickerBorderRounding} !important;` : themeVariantPickerBorderRounding ? `border-radius: ${themeVariantPickerBorderRounding} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper select:hover,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select:hover {
        ${variantPickerFontHoverColor ? `color: ${variantPickerFontHoverColor} !important;` : "color: #fff;"}
        ${variantPickerBorderHoverColor ? `border-color: ${variantPickerBorderHoverColor} !important;` : "border-color: #fff;"}
        ${variantPickerBackgroundHoverColor ? `background-color: ${variantPickerBackgroundHoverColor} !important;` : "background-color: #000;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-option-item:hover {
        ${variantPickerFontHoverColor ? `color: ${variantPickerFontHoverColor} !important;` : "color: #fff;"}
        ${variantPickerBorderHoverColor ? `border-color: ${variantPickerBorderHoverColor} !important;` : "border-color: #000;"}
        ${variantPickerBackgroundHoverColor ? `background-color: ${variantPickerBackgroundHoverColor} !important;` : "background-color: #000;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-button--selected {
        ${variantPickerFontHoverColor ? `color: ${variantPickerFontHoverColor} !important;` : "color: #fff;"}
        ${variantPickerBorderHoverColor ? `border-color: ${variantPickerBorderHoverColor} !important;` : ""}
        ${variantPickerBackgroundHoverColor ? `background-color: ${variantPickerBackgroundHoverColor} !important;` : "background-color: #000;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-quantity-picker-wrapper {
        ${quantityPickerButtonMinimumWidth ? `width: ${quantityPickerButtonMinimumWidth}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker {
        ${quantityPickerFontWeight ? `font-weight: ${quantityPickerFontWeight} !important;` : ""}
        ${quantityPickerFontSize ? `font-size: ${quantityPickerFontSize}px !important;` : ""}
        ${quantityPickerFontColor ? `color: ${quantityPickerFontColor} !important;` : ""}
        ${quantityPickerBackgroundColor ? `background: ${quantityPickerBackgroundColor} !important;` : ""}
        ${quantityPickerBorderStyle ? `border-style: ${quantityPickerBorderStyle} !important;` : ""}
        ${quantityPickerBorderColor ? `border-color: ${quantityPickerBorderColor};` : ""}
        ${quantityPickerBorderWidthLeft ? `border-left-width: ${quantityPickerBorderWidthLeft}px !important;` : ""}
        ${quantityPickerBorderWidthRight ? `border-right-width: ${quantityPickerBorderWidthRight}px !important;` : ""}
        ${quantityPickerBorderWidthBottom ? `border-bottom-width: ${quantityPickerBorderWidthBottom}px !important;` : ""}
        ${quantityPickerBorderWidthTop ? `border-top-width: ${quantityPickerBorderWidthTop}px !important;` : ""}
        ${pickerBorderRounding ? `border-radius: ${pickerBorderRounding} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker {
        ${quantityPickerBorderColor ? `border-color: ${quantityPickerBorderColor} !important;` : "border-color: #000;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker .cf-decrement,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker .cf-products-quantity,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker .cf-increment {
        ${quantityPickerFontFamily ? `font-family: ${quantityPickerFontFamily} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker:hover {
        ${quantityPickerBorderHoverColor ? `border-color: ${quantityPickerBorderHoverColor} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-content-box {
        ${options["products-item-content-width"] ? 'width: ' + options["products-item-content-width"] + 'px !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item {
        ${outerPadding ? 'padding: ' + outerPadding + ' !important;' : ''}
        ${verticalMargin ? 'margin-bottom: ' + verticalMargin + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product-hero-inner {
        max-width: 100% !important;
        ${options["products-item-width"] ? 'width: ' + options["products-item-width"] + 'px !important;' : ''}
        ${productLayout == "cf-left" ? "flex-direction: row-reverse;" : ""}
        ${innerPadding ? 'padding: ' + innerPadding + ' !important;' : ''}
        ${options["products-item-background-color"] ? 'background: ' + options["products-item-background-color"] + ' !important;' : ''}
        ${options["products-item-border-style"] ? 'border-style: ' + options["products-item-border-style"] + '!important;' : ''}
        ${_.isNil(options["products-item-border-width"]) == false ? 'border-width: ' + options["products-item-border-width"] + 'px !important;' : ''}
        ${_.isNil(options["products-item-border-width-left"]) == false ? 'border-left-width: ' + options["products-item-border-width-left"] + 'px !important;' : ''}
        ${_.isNil(options["products-item-border-width-right"]) == false ? 'border-right-width: ' + options["products-item-border-width-right"] + 'px !important;' : ''}
        ${_.isNil(options["products-item-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["products-item-border-width-bottom"] + 'px !important;' : ''}
        ${_.isNil(options["products-item-border-width-top"]) == false ? 'border-top-width: ' + options["products-item-border-width-top"] + 'px !important;' : ''}
        ${options["products-item-border-color"] ? 'border-color: ' + options["products-item-border-color"] + ' !important;' : ''}
        ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
        ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-image img, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-image svg {
        ${options["products-item-image-height"] ? 'height: ' + options["products-item-image-height"] + 'px !important;' : ''}
        ${imageBorderRadius ? 'border-radius: ' + imageBorderRadius + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-badge {
        ${imageBadgePosition ? imageBadgePosition : ""}
        ${imageBadgeBackground ? `background: ${imageBadgeBackground} !important;` : ""}
        ${imageBadgeCornerRounding ? `border-radius: ${imageBadgeCornerRounding} !important;` : themeImageBadgeCornerRounding ? `border-radius: ${themeImageBadgeCornerRounding} !important;` : ""}
        ${imageBadgeFontFamily ? `font-family: ${imageBadgeFontFamily}, Helvetica, Arial, sans-serif !important;` : 'font-family: "Inter", Helvetica, sans-serif;'}
        ${imageBadgeFontWeight ? `font-weight: ${imageBadgeFontWeight} !important;` : ''}
        ${imageBadgeFontSize ? `font-size: ${imageBadgeFontSize}px !important;` : ''}
        ${imageBadgeFontColor ? `color: ${imageBadgeFontColor} !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-title p {
        ${options["products-item-title-size"] ? 'font-size: ' + options["products-item-title-size"] + 'px !important;' : ''}
        ${options["products-item-title-line-height"] ? 'line-height: ' + options["products-item-title-line-height"] + 'px !important;' : ''}
        ${options["products-item-title-font"] ? 'font-family: ' + options["products-item-title-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
        ${options['products-item-title-font-weight'] ? 'font-weight: ' + options['products-item-title-font-weight'] + ' !important;' : ''}
        ${options["products-item-title-color"] ? 'color: ' + options["products-item-title-color"] + ' !important;' : ''}
        ${options["products-title-max-number-of-lines"] ? `-webkit-line-clamp: ${options["products-title-max-number-of-lines"]}; display: -webkit-box !important;` : ''}
        ${options["products-title-max-number-of-lines"] ? `line-clamp: ${options["products-title-max-number-of-lines"]};` : ''}
        ${options["products-title-max-number-of-lines"] ? `white-space: normal !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-price p {
        line-height: unset !important;
        ${options["products-item-price-size"] ? 'font-size: ' + options["products-item-price-size"] + 'px !important;' : ''}
        ${options["products-item-price-font"] ? 'font-family: ' + options["products-item-price-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
        ${options['products-item-price-font-weight'] ? 'font-weight: ' + options['products-item-price-font-weight'] + ' !important;' : ''}
        ${options["products-item-price-color"] ? 'color: ' + options["products-item-price-color"] + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-price[data-discount="true"] p {
        ${options["products-item-discount-color"] ? 'color: ' + options["products-item-discount-color"] + ' !important; opacity: 1;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-price[data-discount="true"] p strike {
        ${options["products-item-price-color"] ? 'color: ' + options["products-item-price-color"] + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description {
        ${options["products-description-max-number-of-lines"] ? `-webkit-line-clamp: ${options["products-description-max-number-of-lines"]};` : ''}
        ${options["products-description-max-number-of-lines"] ? `line-clamp: ${options["products-description-max-number-of-lines"]}; display: -webkit-box !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description p,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h1,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h2,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h3,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h4,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-empty-message p {
        ${options["products-item-description-size"] ? 'font-size: ' + options["products-item-description-size"] + 'px !important;' : ''}
        ${options["products-item-description-font"] ? 'font-family: ' + options["products-item-description-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
        ${options['products-item-description-font-weight'] ? 'font-weight: ' + options['products-item-description-font-weight'] + ' !important;' : ''}
        ${options["products-item-description-color"] ? 'color: ' + options["products-item-description-color"] + ' !important;' : ''}
        ${options["products-item-description-line-height"] ? `line-height: ${options["products-item-description-line-height"]}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product-hero-outer-button {
        ${options["button-width"] == "cf-full-width" ? "width: 100% !important;" : "width: auto !important;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-button {
        ${options["button-color"] ? 'background: ' + options["button-color"] + '!important;' : ''}
        ${options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
        ${productButtonWidth ? productButtonWidth : ""}
        ${options["button-size"] == 'custom' && options["button-size-value"] ? 'padding: ' + options["button-size-value"] + 'px 20px !important;' : ''}
        ${options["button-border-style"] ? 'border-style: ' + options["button-border-style"] + ' !important;' : ''}
        ${options["button-border-style"] !== "" && _.isNil(options["button-border-width"]) == false ? 'border-width: ' + options["button-border-width"] + 'px !important;' : ''}
        ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-left"]) == false ? 'border-left-width: ' + options["button-border-width-left"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-left-width: ' + options["button-border-width"] + 'px !important;' : ''}
        ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-right"]) == false ? 'border-right-width: ' + options["button-border-width-right"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-right-width: ' + options["button-border-width"] + 'px !important;' : ''}
        ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-top"]) == false ? 'border-top-width: ' + options["button-border-width-top"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-top-width: ' + options["button-border-width"] + 'px !important;' : ''}
        ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["button-border-width-bottom"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-bottom-width: ' + options["button-border-width"] + 'px !important;' : ''}
        ${options["button-border-style"] !== "" && options["button-border-color"] ? 'border-color: ' + options["button-border-color"] + ' !important;' : options["button-color"] ? 'border-color: ' + options["button-color"] + ' !important;' : ''}
        ${buttonBorderRadius ? 'border-radius: ' + buttonBorderRadius + ' !important;' : ''}
        ${buttonShadow ? 'box-shadow: ' + buttonShadow + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-all-button-outer .cf-button {
        ${options["products-add-all-btn-color"] ? `background-color: ${options["products-add-all-btn-color"]} !important;` : ""}
        ${options["products-add-all-btn-text-color"] ? `color: ${options["products-add-all-btn-text-color"]} !important;` : ""}
        ${options["products-add-all-btn-padding"] == 'custom' && options["products-add-all-btn-padding-value"] ? `padding: ${options["products-add-all-btn-padding-value"]}px 20px !important;` : ""}
        ${options["products-add-all-btn-border-style"] ? `border-style: ${options["products-add-all-btn-border-style"]} !important;` : ""}
        ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
        ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-left"]) == false ? 'border-left-width: ' + options["products-add-all-btn-border-width-left"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-left-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
        ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-right"]) == false ? 'border-right-width: ' + options["products-add-all-btn-border-width-right"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-right-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
        ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-top"]) == false ? 'border-top-width: ' + options["products-add-all-btn-border-width-top"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-top-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
        ${options["products-add-all-btn-border-style"] !== "" && _.isNil(options["products-add-all-btn-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["products-add-all-btn-border-width-bottom"] + 'px !important;' : _.isNil(options["products-add-all-btn-border-width"]) == false ? 'border-bottom-width: ' + options["products-add-all-btn-border-width"] + 'px !important;' : ''}
        ${options["products-add-all-btn-border-style"] !== "" && options["products-add-all-btn-border-color"] ? 'border-color: ' + options["products-add-all-btn-border-color"] + ' !important;' : ''}
        ${addAllButtonBorderRadius ? `border-radius: ${addAllButtonBorderRadius} !important;` : ""}
        ${addAllButtonShadow ? `box-shadow: ${addAllButtonShadow} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-button:hover, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-button:active {
        ${options["button–hover-color"] ? 'background: ' + options["button–hover-color"] + ' !important;' : options["button-color"] ? 'background: ' + options["button-color"] + ' !important;' : ''}
        ${options["button–hover-text-color"] ? 'color: ' + options["button–hover-text-color"] + ' !important;' : options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
        ${options["button-hover-border-color"] ? 'border-color: ' + options["button-hover-border-color"] + ' !important;' : options["button-border-color"] ? 'border-color: ' +  options["button-border-color"] + ' !important;' : ''}
        ${buttonShadowHover ? 'box-shadow: ' + buttonShadowHover + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-all-button-outer .cf-button:hover, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-all-button-outer .cf-button:active {
        ${options["products-add-all-btn-hover-color"] ? `background-color: ${options["products-add-all-btn-hover-color"]} !important;` : ""}
        ${options["products-add-all-btn-hover-text-color"] ? `color: ${options["products-add-all-btn-hover-text-color"]} !important;` : ""}
        ${options["products-add-all-btn-hover-border-color"] ? `border-color: ${options["products-add-all-btn-hover-border-color"]} !important;` : ""}
        ${addAllButtonShadowHover ? `box-shadow: ${addAllButtonShadowHover} !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-button p {
        ${buttonFont ? `font-family: ${buttonFont} !important;` : ''}
        ${buttonFontWeight ? `font-weight: ${buttonFontWeight} !important;` : ''}
        ${buttonFontSize ? `font-size: ${buttonFontSize}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-all-button-outer .cf-button p {
        ${options["products-add-all-btn-font-family"] ? `font-family: ${options["products-add-all-btn-font-family"]} !important;` : ""}
        ${options["products-add-all-btn-font-weight"] ? `font-weight: ${options["products-add-all-btn-font-weight"]} !important;` : ""}
        ${options["products-add-all-btn-font-size"] ? `font-size: ${options["products-add-all-btn-font-size"]}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-inner-box {
        ${element.options["image-show"] !== "false" ? "min-width: 50%;" : "max-width: 100%;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-content-box {
        max-width: 100% !important;
      }
      `;

    const productLayoutMobile = options["products-layout-mobile"];

    let buttonFontSizeMobile;
    if (options["button-font-size-mobile"]) {
      buttonFontSizeMobile = options["button-font-size-mobile"];
    }

    let imageBadgeFontSizeMobile = element.options["products-image-badge-font-size-mobile"] || variant.options["products-hero-offer-badge-font-size-mobile"]

    const quantityPickerButtonMinimumWidthMobile = element.options["products-quantity-picker-minimum-width-mobile"]
      ? element.options["products-quantity-picker-minimum-width-mobile"]
      : undefined

    const quantityPickerFontSizeMobile = element.options["products-quantity-picker-font-size-mobile"];
    const variantPickerOptionsMinimumWidthMobile = element.options["products-variant-picker-button-minimum-width-mobile"] || variant.options["products-hero-variant-picker-button-minimum-width-mobile"];
    const variantPickerFontSizeMobile = element.options["products-variant-picker-font-size-mobile"] || variant.options["products-hero-variant-picker-font-size-mobile"];

    const contentTypeSpacingMobile = element.options["products-content-type-spacing-mobile"];

    const productImageCarouselHeightMobile = element.options["products-carousel-image-height-mobile"];

    const subscriptionChoicesFontSizeMobile = element.options["products-subscription-choices-font-size-mobile"] || variant.options["products-hero-subscription-choices-font-size-mobile"];

    const reviewsStarSizeMobile = element.options["products-reviews-star-size-mobile"];
    const reviewsFontSizeMobile = element.options["products-reviews-font-size-mobile"];

    const productsContentLabelFontSizeMobile = options["products-content-label-font-size-mobile"];
    const productsContentLabelLineHeightMobile = options["products-content-label-line-height-mobile"];

    let productButtonWidthMobile;
    if (options["button-width-mobile"] == "cf-button-slim") {
      productButtonWidthMobile = "min-width: fit-content !important;"
    } else if (options["button-width-mobile"] == "cf-button-mid") {
      productButtonWidthMobile = "min-width: 150px !important;"
    } else if (options["button-width-mobile"] == "cf-full-width") {
      productButtonWidthMobile = "min-width: 100% !important;"
    } else if (options["button-width-mobile"] == "custom" || (!options["button-width-mobile"] && options["button-width"] == "custom")) {
      productButtonWidthMobile = `min-width: ${options["button-width-value-mobile"] || options["button-width-value"]}px !important;`
    }

    let productsOuterAlignmentMobile;
    if (options["cf-alignment-mobile"] == "cf-mobile-left") {
      productsOuterAlignmentMobile = "justify-content: flex-start !important; margin-right: auto !important; margin-left: unset !important;"
    } else if (options["cf-alignment-mobile"] == "cf-mobile-center") {
      productsOuterAlignmentMobile = "justify-content: center !important; margin-left: auto !important; margin-right: auto !important;"
    } else if (options["cf-alignment-mobile"] == "cf-mobile-right") {
      productsOuterAlignmentMobile = "justify-content: flex-end !important; margin-left: auto !important; margin-right: unset !important;"
    }

    let productsInnerAlignmentMobile;
    if (options["cf-inner-alignment-mobile"] == "cf-left") {
      productsInnerAlignmentMobile = "justify-content: flex-start !important; text-align: left !important;"
    } else if (options["cf-inner-alignment-mobile"] == "cf-center") {
      productsInnerAlignmentMobile = "justify-content: center !important; text-align: center !important;"
    } else if (options["cf-inner-alignment-mobile"] == "cf-right") {
      productsInnerAlignmentMobile = "justify-content: flex-end !important; text-align: right !important;"
    }

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-content-box {
        ${options["products-item-content-width-mobile"] ? 'width: ' + options["products-item-content-width-mobile"] + 'px !important;' : ''}
        ${options["cf-inner-alignment-mobile"] == "left" ? "margin-right: auto !important; margin-left: unset !important;" : ""}
        ${options["cf-inner-alignment-mobile"] == "center" ? "margin-left: auto !important; margin-right: auto !important;" : ""}
        ${options["cf-inner-alignment-mobile"] == "right" ? "margin-left: auto !important; margin-right: unset !important;" : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-subscription-radio-wrapper {
        ${options["cf-inner-alignment-mobile"] == "center" ? "margin-left: auto !important; margin-right: auto !important;" : ""}
        ${options["cf-inner-alignment-mobile"] == "right" ? "margin-left: auto !important; margin-right: unset !important;" : ""}
        ${options["cf-inner-alignment-mobile"] == "left" ? "margin-right: auto !important; margin-left: unset !important;" : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-item-title,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-item-description,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-prod-hero-content-box,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-options-wrapper,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quantity-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-variant-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-subscription-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-content-type {
        ${productsInnerAlignmentMobile ? productsInnerAlignmentMobile : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-products-outer {
        ${productsOuterAlignmentMobile ? productsOuterAlignmentMobile : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product-hero-outer-button {
        ${options["button-width-mobile"] == "cf-full-width" ? "width: 100% !important;" : "width: auto !important;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-button {
        ${productButtonWidthMobile ? productButtonWidthMobile : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-quantity-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-variant-picker-label,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-label {
        ${productsContentLabelFontSizeMobile ? `font-size: ${productsContentLabelFontSizeMobile}px !important;` : ""}
        ${productsContentLabelLineHeightMobile ? `line-height: ${productsContentLabelLineHeightMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-quantity-picker-wrapper {
        ${quantityPickerButtonMinimumWidthMobile ? `width: ${quantityPickerButtonMinimumWidthMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews {
        ${reviewsFontSizeMobile ? `font-size: ${reviewsFontSizeMobile}px !important;` : ""}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-reviews-stars-inner .svg-inline--fa {
        ${reviewsStarSizeMobile ? `font-size: ${reviewsStarSizeMobile}px;` : ""}products-hero-subscription-corner-rounding
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-products-subscription-dropdown-wrapper select,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-subscription-radio-wrapper label {
        ${subscriptionChoicesFontSizeMobile ? `font-size: ${subscriptionChoicesFontSizeMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-title p {
        ${options["products-title-max-number-of-lines-mobile"] ? `-webkit-line-clamp: ${options["products-title-max-number-of-lines-mobile"]}; display: -webkit-box !important;` : ''}
        ${options["products-title-max-number-of-lines-mobile"] ? `line-clamp: ${options["products-title-max-number-of-lines-mobile"]};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description {
        ${options["products-description-max-number-of-lines-mobile"] ? `-webkit-line-clamp: ${options["products-description-max-number-of-lines-mobile"]};` : ''}
        ${options["products-description-max-number-of-lines-mobile"] ? `line-clamp: ${options["products-description-max-number-of-lines-mobile"]}; display: -webkit-box !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description p,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h1,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h2,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h3,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h4,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-empty-message p {
        ${options["products-item-description-line-height-mobile"] ? `line-height: ${options["products-item-description-line-height-mobile"]}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product-content-type {
        ${contentTypeSpacingMobile ? `margin-bottom: ${contentTypeSpacingMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product__image {
        ${productImageCarouselHeightMobile ? `height: ${productImageCarouselHeightMobile}px;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-increment-quantity-picker {
        ${quantityPickerFontSizeMobile ? `font-size: ${quantityPickerFontSizeMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper select,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element div.cf-variant-picker-wrapper.cf-product-content-type > div > select {
        ${variantPickerFontSizeMobile ? `font-size: ${variantPickerFontSizeMobile}px !important;` : "font-size: 12px;"}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-option-item {
        ${variantPickerOptionsMinimumWidthMobile ? `min-width: ${variantPickerOptionsMinimumWidthMobile}px !important;` : ""}
        ${variantPickerFontSizeMobile ? `font-size: ${variantPickerFontSizeMobile}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item {
        ${outerPaddingMobile ? 'padding: ' + outerPaddingMobile + ' !important;' : ''}
        ${verticalMarginMobile ? 'margin-bottom: ' + verticalMarginMobile + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-product-hero-inner {
        margin-left: auto;
        margin-right: auto;
        ${options["products-item-width-mobile"] ? 'width: ' + options["products-item-width-mobile"] + 'px !important;' : ''}
        ${productLayoutMobile == "cf-left" ? "flex-direction: column-reverse !important;" : "flex-direction: column !important;"}
        ${innerPaddingMobile ? 'padding: ' + innerPaddingMobile + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-image img, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-image svg {
        ${options["products-item-image-height-mobile"] ? 'height: ' + options["products-item-image-height-mobile"] + 'px !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-badge {
        ${imageBadgeFontSizeMobile ? `font-size: ${imageBadgeFontSizeMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-title p {
        ${options["products-item-title-size-mobile"] ? 'font-size: ' + options["products-item-title-size-mobile"] + 'px !important;' : ''}
        ${options["products-item-title-line-height-mobile"] ? 'line-height: ' + options["products-item-title-line-height-mobile"] + 'px !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-price p {
        ${options["products-item-price-size-mobile"] ? 'font-size: ' + options["products-item-price-size-mobile"] + 'px !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description p,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h1,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h2,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h3,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-item-description h4,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-empty-message p {
        ${options["products-item-description-size-mobile"] ? 'font-size: ' + options["products-item-description-size-mobile"] + 'px !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-button p,
      #cta_${cta.id} .cf-button[data-element-id="${element.id}"] p {
        ${buttonFontSizeMobile ? `font-size: ${buttonFontSizeMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-products-all-button-outer .cf-button p,
      #cta_${cta.id} .cf-products-all-button-outer .cf-button[data-element-id="${element.id}"] p {
        ${options["products-add-all-btn-font-size-mobile"] ? `font-size: ${options["products-add-all-btn-font-size-mobile"]}px !important;` : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-inner-box {
        margin-left: auto;
        margin-right: auto;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-inner-box,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-product-hero-element .cf-prod-hero-content-box {
        max-width: 100%;
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementProductHero;
