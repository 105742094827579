import React, { useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSteps from "../steps/useSteps.js";
import useSections from "../sections/useSections.js";
import useElements from "../elements/useElements.js";

const VariantBuilderToolbarButtons = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const cta = props.cta;
  const variant = props.variant;
  const saveButton = useRef(null);
  const publishButton = useRef(null);
  const canSave = contextData.history.unsaved == true;
  const canPublish = contextData.history.unpublished == true;
  const undoButton = useRef(null);
  const redoButton = useRef(null);

  function publishClick() {
    if (canPublish) {
      builder.publish();
    }
  }

  function saveClick() {
    if (canSave) {
      builder.save();
    }

    if (canPublish) {
      publishClick();
    }
  }

  return (
    <React.Fragment>
      {props.page == "builder" && (
        <React.Fragment>
          <div>
            <button
              ref={undoButton}
              className={`bg-white dark:bg-slate-950 py-3 px-1 rounded-lg text-xl ml-1 cursor-pointer border-[1px] border-solid border-white dark:border-slate-950 ${
                !contextData.history.changes ||
                contextData.history.changes.length == contextData.history.undos
                  ? "opacity-30 cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={builder.undo}
              title="Undo"
              onMouseOver={() => {
                const svgPath = undoButton.current.querySelector("svg path");
                if (
                  false ==
                    (!contextData.history.changes ||
                      contextData.history.changes.length ==
                        contextData.history.undos) &&
                  !contextData.dark_mode
                ) {
                  svgPath.setAttribute("stroke", "#003FFF");
                }
              }}
              onMouseLeave={() => {
                const svgPath = undoButton.current.querySelector("svg path");
                if (
                  svgPath.getAttribute("stroke") == "#003FFF" &&
                  !contextData.dark_mode
                ) {
                  svgPath.setAttribute("stroke", "black");
                }
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.79489 4.00001L2 7.84625M2 7.84625L5.79489 11.6925M2 7.84625L14 7.84625C16.2091 7.84625 18 9.63711 18 11.8463L18 12C18 14.2092 16.2091 16 14 16L10 16"
                  stroke={
                    !contextData.history.changes ||
                    contextData.history.changes.length ==
                      contextData.history.undos
                      ? contextData.dark_mode
                        ? `white`
                        : "black"
                      : contextData.dark_mode
                      ? `white`
                      : "black"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              ref={redoButton}
              className={`bg-white dark:bg-slate-950 py-3 px-1 rounded-lg text-xl ml-1 cursor-pointer border-[1px] border-solid border-white dark:border-slate-950 ${
                contextData.history.undos == 0
                  ? "opacity-30 cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={builder.redo}
              title="Redo"
              onMouseOver={() => {
                const svgPath = redoButton.current.querySelector("svg path");
                if (contextData.history.undos > 0 && !contextData.dark_mode) {
                  svgPath.setAttribute("stroke", "#003FFF");
                }
              }}
              onMouseLeave={() => {
                const svgPath = redoButton.current.querySelector("svg path");
                if (
                  svgPath.getAttribute("stroke") == "#003FFF" &&
                  !contextData.dark_mode
                ) {
                  svgPath.setAttribute("stroke", "black");
                }
              }}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4551 4.00001L18.25 7.84625M18.25 7.84625L14.4551 11.6925M18.25 7.84625L6.25 7.84625C4.04086 7.84625 2.25 9.63711 2.25 11.8463L2.25 12C2.25 14.2092 4.04086 16 6.25 16L10.25 16"
                  stroke={
                    contextData.history.undos == 0
                      ? contextData.dark_mode
                        ? `white`
                        : "black"
                      : contextData.dark_mode
                      ? `white`
                      : "black"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </React.Fragment>
      )}

      <div className="pt-1">
        {canSave && (
          <button
            ref={saveButton}
            id="save"
            className={`w-[73px] border-[1px] border-solid py-3 px-5 rounded-lg text-xl ml-1 mr-1 bg-slate-950 dark:bg-slate-950 border-slate-950 dark:border-white text-white dark:text-white cursor-pointer`}
            onClick={saveClick}
            style={{
              width: "72px",
            }}
          >
            Save
          </button>
        )}

        {!canSave && canPublish && (
          <button
            ref={publishButton}
            id="publish"
            className={`w-[73px] border-[1px] border-solid py-3 px-5 rounded-lg text-xl ml-1 mr-1 ${
              canPublish
                ? "bg-blue-600 border-blue-600 text-white cursor-pointer"
                : "bg-white border-slate-200 text-gray-300 cursor-not-allowed"
            }`}
            onClick={publishClick}
            style={{
              width: "72px",
            }}
          >
            Publish
          </button>
        )}
        {!canSave && !canPublish && (
          <button
            ref={saveButton}
            id="save"
            className={`w-[73px] border-[1px] border-solid py-3 px-5 rounded-lg text-xl ml-1 mr-1 bg-white dark:bg-slate-950 border-slate-200 dark:border-slate-800 text-black dark:text-gray-400 dark:hover:text-white cursor-not-allowed`}
            style={{
              width: "72px",
            }}
            title="Published"
          >
            <i className="far fa-check-circle w-7 fa-lg opacity-50"></i>
          </button>
        )}
      </div>
      <div className="pt-1">
        <button
          className={`w-[73px] border-[1px] border-solid py-3 px-5 rounded-lg text-xl ml-1 mr-1 cursor-pointer text-black hover:text-blue-600 dark:text-gray-400 dark:hover:text-white bg-white hover:bg-blue-100 dark:bg-slate-950 dark:hover:bg-slate-800 border-slate-200 hover:border-blue-100 dark:border-slate-800 ${
            canSave ? "cursor-not-allowed opacity-30" : ""
          }`}
          onClick={() => {
            if (canSave) return;
            props.setPage(props.page == "builder" ? "preview" : "builder");
          }}
        >
          {props.page == "builder" ? "Preview" : "Builder"}
        </button>
      </div>
      <div className="pt-1 mr-[1px]">
        <a
          href={`/websites/${props.website.id}/cta/${cta.id}/launch/?variant=${variant.variation}`}
        >
          <button
            className={`w-[85px] border-[1px] border-solid py-3 px-3 rounded-lg text-xl ml-1 mr-1 cursor-pointer ${
              !canSave && !canPublish
                ? "bg-slate-950 dark:bg-blue-600 border-slate-950 dark:border-blue-600 text-white cursor-pointer"
                : "bg-white hover:bg-blue-100 dark:bg-slate-950 dark:hover:bg-slate-800 text-black hover:text-blue-600 dark:text-gray-400 dark:hover:text-white border-slate-200 hover:border-blue-100 dark:border-slate-800 cursor-not-allowed"
            }`}
          >
            Launch →
          </button>
        </a>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderToolbarButtons;
