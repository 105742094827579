import React, { useContext, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderToolbarZoom = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const cta = props.cta;

  const zoomInDebounce = _.debounce((clicks) => {
    dispatchCustomEvent("zoomIn");
  }, 50);

  const zoomOutDebounce = _.debounce((clicks) => {
    dispatchCustomEvent("zoomOut");
  }, 50);

  const resetCanvas = () => {
    dispatchCustomEvent("resetCanvas");
  };

  return (
    <React.Fragment>
      <div className="grid grid-flow-col auto-cols-auto justify-center ml-3">
        <div className="flex h-[32px] mt-3 text-xl hover:bg-blue-100 dark:hover:bg-slate-800 text-black dark:text-white rounded-lg border-solid border-[1px] border-slate-200 hover:border-blue-100 dark:border-slate-700 dark:hover:border-slate-800">
          <button
            title="Zoom Out"
            className={`h-[28px] py-0 px-3 !bg-transparent rounded-lg text-2xl border-none hover:text-blue-600 dark:text-slate-200`}
            onClick={zoomOutDebounce}
          >
            -
          </button>
          <div
            title="Reset"
            id="cf-zoom-control-percentage"
            className="cf-zoom-control-percentage w-[45px] text-center py-[6px] px-[5px] cursor-pointer dark:text-white hover:text-blue-600"
            onClick={resetCanvas}
          >
            100%
          </div>
          <button
            title="Zoom In"
            className={`h-[28px] py-0 px-3 !bg-transparent rounded-lg text-2xl border-none hover:text-blue-600 dark:text-slate-200`}
            onClick={zoomInDebounce}
          >
            +
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderToolbarZoom;
