import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import VariantTwoTapPopup from "../variants/VariantTwoTapPopup";

const VariantSettingsTwoTap = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={variant}
        setting_name="[options][modal-trigger]"
        setting_type="modal"
        object_type="variants"
        object_id={variant.id}
        value="Edit Message"
        button_glyphicon="glyphicon-edit"
        condition={
          ["prehook", "two-tap"].includes(variant.options["trigger_type"]) ||
          variant.options["prehook-trigger-on-close"] == "true"
        }
      >
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][prehook-message]"
          setting_type="editor"
          simple_editor={true}
          object_type="variants"
          label="Message text"
          value={
            variant.options["prehook-message"]
              ? variant.options["prehook-message"]
              : variant.options["trigger_type"] == "prehook"
              ? "Hi ({person.firstname} OR {fallback=friend})!"
              : "<p>Your message here</p>"
          }
          condition={
            ["two-tap", "prehook"].includes(variant.options["trigger_type"]) ||
            variant.options["prehook-trigger-on-close"] == "true"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][prehook-cta]"
          setting_type="editor"
          simple_editor={true}
          object_type="variants"
          label="Message button text"
          value={
            variant.options["prehook-cta"]
              ? variant.options["prehook-cta"]
              : "See More"
          }
          condition={["prehook"].includes(variant.options["trigger_type"])}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][prehook-button-color]"
          setting_type="color_v2"
          object_type="variants"
          label="Message button color"
          value={
            variant.options["prehook-button-color"]
              ? variant.options["prehook-button-color"]
              : "#007aec"
          }
          condition={
            ["two-tap", "prehook"].includes(variant.options["trigger_type"]) ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[user_id]"
          setting_type="dropdown"
          object_type="variants"
          label="Profile User"
          options={[
            {
              value: "",
              text: "Select a user profile",
            },
            ...contextData.objects.users
              .filter((user) => {
                return user.name || user.id == props.current_user.id;
              })
              .map((user) => {
                return {
                  value: user.id,
                  text: user.name ? user.name : "Your profile",
                };
              }),
          ]}
          value={variant.user_id ? variant.user_id : undefined}
          condition={variant.options["trigger_type"] == "prehook"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Button font"
          setting_name="[options][prehook-button-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["prehook-button-font"]
              ? variant.options["prehook-button-font"]
              : "Helvetica"
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
          callback={ctas.builder.fonts.load_font_family}
          col="8"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][prehook-button-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["prehook-button-font-weight"]
              ? variant.options["prehook-button-font-weight"]
              : "700"
          }
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={variant}
          label="Button font size"
          device="desktop"
          setting_name="[options][prehook-button-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["prehook-button-size"]
              ? variant.options["prehook-button-size"]
              : "19"
          }
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
          min={0}
          max={100}
          range_step={1}
        />

        <VariantBuilderSetting
          object={variant}
          label="Button font size"
          device="mobile"
          setting_name="[options][prehook-button-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["prehook-button-size-mobile"]
              ? variant.options["prehook-button-size-mobile"]
              : "19"
          }
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
          min={0}
          max={100}
          range_step={1}
        />

        <VariantBuilderSetting
          object={variant}
          label="Button text color"
          setting_name="[options][prehook-button-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["prehook-button-font-color"]
              ? variant.options["prehook-button-font-color"]
              : "#fff"
          }
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Border style"
          setting_name="[options][prehook-button-border-style]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["prehook-button-border-style"]
              ? variant.options["prehook-button-border-style"]
              : "none"
          }
          options={[
            { value: "solid", text: "Solid" },
            { value: "dashed", text: "Dashed" },
            { value: "dotted", text: "Dotted" },
            { value: "none", text: "None" },
          ]}
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
        />

        <VariantBuilderSetting
          object={variant}
          label="Button border color"
          setting_name="[options][prehook-button-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["prehook-button-border-color"]
              ? variant.options["prehook-button-border-color"]
              : "#000"
          }
          condition={
            (variant.options["trigger_type"] == "two-tap" ||
              (variant.options["trigger_type"] !== "prehook" &&
                variant.options["prehook-trigger-on-close"] == "true")) &&
            ["dotted", "dashed", "solid"].includes(
              variant.options["prehook-button-border-style"]
            )
          }
        />

        {(variant.options["trigger_type"] == "two-tap" ||
          (variant.options["trigger_type"] !== "prehook" &&
            variant.options["prehook-trigger-on-close"] == "true")) &&
          variant.options["prehook-button-border-style"] &&
          variant.options["prehook-button-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][prehook-button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[
                              `prehook-button-border-width-${side}`
                            ]
                          ) == false
                            ? variant.options[
                                `prehook-button-border-width-${side}`
                              ]
                            : variant.options["prehook-button-border-width"]
                            ? variant.options[
                                "prehook-button-border-width"
                              ].replace("px", "")
                            : "1"
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Corner Radius"
          setting_name="[options][prehook-button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["prehook-button-radius"]
              ? variant.options["prehook-button-radius"]
              : "cf-not-rounded"
          }
          condition={
            variant.options["trigger_type"] == "two-tap" ||
            (variant.options["trigger_type"] !== "prehook" &&
              variant.options["prehook-trigger-on-close"] == "true")
          }
        />

        {(variant.options["trigger_type"] == "two-tap" ||
          (variant.options["trigger_type"] !== "prehook" &&
            variant.options["prehook-trigger-on-close"] == "true")) &&
          variant.options["prehook-button-radius"] == "custom" && (
            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][prehook-button-radius-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={variant}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="variants"
                      value={
                        _.isNil(
                          variant.options[`prehook-button-radius-${side}`]
                        ) == false
                          ? variant.options[`prehook-button-radius-${side}`]
                          : "3"
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder="0px"
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>
          )}

        <div className="col-xs-12 some-vertical-space">
          <div className="row">
            <div className="col-xs-6">
              <p className="field-title smooth">Preview</p>
            </div>
            <div className="col-xs-6"></div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div
                className={`cta${props.cta.id} ${
                  variant.options["trigger_type"] == "prehook"
                    ? "cf-prehook-popup"
                    : "cf-two-tap"
                }`}
                data-cta-id={props.cta.id}
                style={{ paddingBottom: "0px" }}
              >
                <VariantTwoTapPopup
                  {...props}
                  builder={true}
                  device={contextData.device}
                  user={
                    contextData.objects.users.filter((user) => {
                      return user.id + "" == variant.user_id + "";
                    })[0]
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </VariantBuilderSetting>

      <VariantBuilderSetting
        object={variant}
        setting_name="[options][prehook-position]"
        setting_type="dropdown"
        object_type="variants"
        label={`${
          variant.options["trigger_type"] == "two-tap" ||
          (variant.options["trigger_type"] !== "prehook" &&
            variant.options["prehook-trigger-on-close"] == "true")
            ? "Two-tap button"
            : "On-site message"
        } Position`}
        options={[
          { value: "left", text: "Left" },
          { value: "center", text: "Center" },
          { value: "right", text: "Right" },
        ]}
        value={
          variant.options["prehook-position"]
            ? variant.options["prehook-position"]
            : "right"
        }
        condition={
          variant.options["trigger_type"] == "two-tap" ||
          variant.options["prehook-trigger-on-close"] == "true"
        }
      />

      <VariantBuilderSetting
        object={variant}
        setting_name="[options][prehook-trigger-type]"
        setting_type="dropdown"
        object_type="variants"
        label={`${
          variant.options["trigger_type"] == "two-tap"
            ? "Two-tap button"
            : "On-site message"
        } Trigger Type`}
        options={[
          { value: "", text: "Select trigger type", disabled: true },
          { value: "immediate", text: "Immediate" },
          { value: "scroll", text: "Scroll point" },
          { value: "delayed", text: "Time delayed" },
        ]}
        value={
          variant.options["prehook-trigger-type"]
            ? variant.options["prehook-trigger-type"]
            : "immediate"
        }
        condition={
          (cta.cta_type == "overlay" ||
            cta.cta_type == "hook" ||
            cta.cta_type == "bar") &&
          ["two-tap", "prehook"].includes(variant.options["trigger_type"])
        }
      />
    </React.Fragment>
  );
};

export default VariantSettingsTwoTap;
