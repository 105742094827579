var broadcast_page_urls = function(broadcast, container) {

  $('.campaign-step[data-step="urls"]').hide();
  $(container).html('')

  $(broadcast.ctas).each(function(i, cta_id) {
    var cta = website.ctas[cta_id]

    if (cta && cta.cta_type == 'page') {
      var root_domain = 'https://';

      if (cta.domain_id && website.domains[cta.domain_id]) {
        var domain = website.domains[cta.domain_id]
        root_domain = root_domain + domain.sub_domain + '.' + domain.root_domain;
      } else {
        root_domain = root_domain + website.sub_domain + '.convertflowpages.com';;
      }
      
      var link = root_domain + '/' + cta.page_path + '/?broadcast_id=' + broadcast.id; 

      $(container).append(
        '<div class="row hard-center some-vertical-space" style="margin-bottom: 0">' +
          '<p class="field-title">' +
            '<a target="_blank" href="/websites/' + website.id + '/cta/' + cta_id + '/launch" style="color: inherit; text-decoration: capitalize;">' +
              (cta.name || 'Landing page ' + cta.id) + `. <span class="underline">Edit →</span>` +
            '</a>' +
          '</p>' +
          '<p class="preview-link text-blue-600" data-broadcast-id="<%= broadcast.id %>" style="cursor: pointer;">' +
            '<a style="color: inherit;" href="' + link + '" target="_blank">' +
              link +
            '</a>' +
          '</p>' +
        '</div>'
      )

    }
  })

  if ($(container).children().length > 0) {
    $('.campaign-step[data-step="urls"]').show();
  }
}

export default broadcast_page_urls;