import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeQuiz = (props) => {
  const [contextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Quizzes" open={false}>
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-question-distance-from-progress]"
          setting_type="range"
          object_type="variants"
          label={"Distance From Progress Bar"}
          value={
            variant.options["quiz-question-distance-from-progress"]
              ? variant.options["quiz-question-distance-from-progress"]
              : 20
          }
          placeholder="20"
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-question-distance-from-progress-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Distance From Progress Bar"}
          value={
            variant.options["quiz-question-distance-from-progress-mobile"]
              ? variant.options["quiz-question-distance-from-progress-mobile"]
              : undefined
          }
          placeholder={variant.options["quiz-question-distance-from-progress"] ? variant.options["quiz-question-distance-from-progress"] : 20}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-question-spacing]"
          setting_type="range"
          object_type="variants"
          label={"Question Content Spacing"}
          value={
            variant.options["quiz-question-spacing"]
              ? variant.options["quiz-question-spacing"]
              : undefined
          }
          placeholder={0}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-question-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Question Content Spacing"}
          value={
            variant.options["quiz-question-spacing-mobile"]
              ? variant.options["quiz-question-spacing-mobile"]
              : undefined
          }
          placeholder={variant.options["quiz-question-spacing"] || 0}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][label-image-placement]"
          label="Image Placement"
          setting_type="dropdown"
          options={[
            { value: "", text: "Below Description" },
            { value: "left", text: "Left of Question" },
            { value: "right", text: "Right of Question" },
          ]}
          object_type="variants"
          value={
            variant.options["label-image-placement"]
              ? variant.options["label-image-placement"]
              : undefined
          }
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][label-image-placement-mobile]"
          label="Image Placement"
          setting_type="dropdown"
          options={[
            { value: "", text: "Below Description" },
            { value: "above", text: "Above Description" },
          ]}
          object_type="variants"
          value={
            variant.options["label-image-placement-mobile"]
              ? variant.options["label-image-placement-mobile"]
              : undefined
          }
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-options-layout]"
          setting_type="layout"
          object_type="variants"
          label={"Options Layout"}
          value={
            variant.options["quiz-options-layout"]
              ? variant.options["quiz-options-layout"]
              : "responsive"
          }
          valueMappings={{
            stacked: "stacked",
            responsive: "responsive",
          }}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-options-layout-mobile]"
          setting_type="layout"
          object_type="variants"
          label={"Options Layout"}
          value={
            variant.options["quiz-options-layout-mobile"]
              ? variant.options["quiz-options-layout-mobile"]
              : undefined
          }
          valueMappings={{
            stacked: "stacked",
            responsive: "responsive",
          }}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Width"
          setting_name="[options][quiz-option-width-style]"
          setting_type="box_width"
          object_type="variants"
          value={
            variant.options["quiz-option-width-style"]
              ? variant.options["quiz-option-width-style"]
              : undefined
          }
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          object_type="variants"
          setting_name="[options][quiz-option-width-value]"
          setting_type="range"
          label="Option Width"
          value={
            variant.options["quiz-option-width-value"]
              ? variant.options["quiz-option-width-value"]
              : "auto"
          }
          condition={variant.options["quiz-option-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          placeholder={"100%"}
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          object_type="variants"
          label="Option Width"
          setting_name="[options][quiz-option-width-style-mobile]"
          setting_type="box_width"
          value={
            variant.options["quiz-option-width-style-mobile"]
              ? variant.options["quiz-option-width-style-mobile"]
              : variant.options["quiz-option-width-value"]
              ? variant.options["quiz-option-width-value"]
              : "auto"
          }
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          object_type="variants"
          setting_name="[options][quiz-option-width-value-mobile]"
          setting_type="range"
          label={"Option Width"}
          value={
            variant.options["quiz-option-width-value-mobile"]
              ? variant.options["quiz-option-width-value-mobile"]
              : undefined
          }
          condition={
            variant.options["quiz-option-width-style-mobile"] == "custom"
          }
          min={0}
          max={480}
          range_step={"1"}
          allow_empty={true}
          placeholder={"100%"}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-options-vertical-spacing]"
          setting_type="range"
          object_type="variants"
          label={"Options Vertical Spacing"}
          value={
            variant.options["quiz-options-vertical-spacing"]
              ? variant.options["quiz-options-vertical-spacing"]
              : 10
          }
          placeholder={10}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-options-vertical-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Options Vertical Spacing"}
          value={
            variant.options["quiz-options-vertical-spacing-mobile"]
              ? variant.options["quiz-options-vertical-spacing-mobile"]
              : variant.options["quiz-options-vertical-spacing"]
              ? variant.options["quiz-options-vertical-spacing"]
              : 10
          }
          placeholder={variant.options["quiz-options-vertical-spacing"] || 10}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-options-horizontal-spacing]"
          setting_type="range"
          object_type="variants"
          label={"Options Horizontal Spacing"}
          value={
            variant.options["quiz-options-horizontal-spacing"]
              ? variant.options["quiz-options-horizontal-spacing"]
              : 10
          }
          placeholder={10}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-options-horizontal-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          label={"Options Horizontal Spacing"}
          value={
            variant.options["quiz-options-horizontal-spacing-mobile"]
              ? variant.options["quiz-options-horizontal-spacing-mobile"]
              : variant.options["quiz-options-horizontal-spacing"]
              ? variant.options["quiz-options-horizontal-spacing"]
              : 10
          }
          placeholder={variant.options["quiz-options-horizontal-spacing"] || 10}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-image-placement]"
          setting_type="alignment"
          object_type="variants"
          label={"Options Image Placement"}
          value={
            variant.options["quiz-image-placement"]
              ? variant.options["quiz-image-placement"]
              : "centered"
          }
          valueMappings={{
            left: "left",
            center: "centered",
          }}
          device="desktop"
        />
        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-image-placement-mobile]"
          setting_type="alignment"
          object_type="variants"
          label={"Options Image Placement"}
          value={
            variant.options["quiz-image-placement-mobile"]
              ? variant.options["quiz-image-placement-mobile"]
              : variant.options["quiz-image-placement"]
              ? variant.options["quiz-image-placement"]
              : undefined
          }
          valueMappings={{
            left: "left",
            center: "centered",
          }}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Text Alignment"
          setting_name="[options][quiz-option-text-alignment]"
          setting_type="alignment"
          object_type="variants"
          value={
            variant.options["quiz-option-text-alignment"]
              ? variant.options["quiz-option-text-alignment"]
              : "center"
          }
          valueMappings={{
            left: "left",
            center: "center",
            right: "right",
          }}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Text Alignment"
          setting_name="[options][quiz-option-text-alignment-mobile]"
          setting_type="alignment"
          object_type="variants"
          value={
            variant.options["quiz-option-text-alignment-mobile"]
              ? variant.options["quiz-option-text-alignment-mobile"]
              : variant.options["quiz-option-text-alignment"]
              ? variant.options["quiz-option-text-alignment"]
              : "center"
          }
          valueMappings={{
            left: "left",
            center: "center",
            right: "right",
          }}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Font family"
          setting_name="[options][quiz-option-font-family]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["quiz-option-font-family"]
              ? variant.options["quiz-option-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-option-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["quiz-option-font-weight"]
              ? variant.options["quiz-option-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Font size"
          setting_name="[options][quiz-option-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-font-size"]
              ? variant.options["quiz-option-font-size"]
              : undefined
          }
          placeholder={16}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Font size"
          setting_name="[options][quiz-option-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-font-size-mobile"]
              ? variant.options["quiz-option-font-size-mobile"]
              : undefined
          }
          placeholder={14}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Font line height"
          setting_name="[options][quiz-option-font-line-height]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-font-line-height"]
              ? variant.options["quiz-option-font-line-height"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Font line height"
          setting_name="[options][quiz-option-font-line-height-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-font-line-height-mobile"]
              ? variant.options["quiz-option-font-line-height-mobile"]
              : undefined
          }
          placeholder={30}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Opt. Font color"
            setting_name="[options][quiz-option-font-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-option-font-color"]
                ? variant.options["quiz-option-font-color"]
                : "#000"
            }
            placeholder={"#000"}
            col="6"
            classNames="some-padding-right"
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-hover-option-font-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-hover-option-font-color"]
                ? variant.options["quiz-hover-option-font-color"]
                : variant.options["quiz-option-font-color"]
                ? variant.options["quiz-option-font-color"]
                : undefined
            }
            placeholder={variant.options["quiz-option-font-color"] || "#000"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-visual-placement]"
          setting_type="alignment"
          object_type="variants"
          label={"Options Visual Placement"}
          value={
            variant.options["quiz-visual-placement"]
              ? variant.options["quiz-visual-placement"]
              : "left"
          }
          valueMappings={{
            left: "left",
            center: "centered"
          }}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-visual-placement-mobile]"
          setting_type="alignment"
          object_type="variants"
          label={"Options Visual Placement"}
          value={
            variant.options["quiz-visual-placement-mobile"]
              ? variant.options["quiz-visual-placement-mobile"]
              : variant.options["quiz-visual-placement"]
              ? variant.options["quiz-visual-placement"]
              : "left"
          }
          valueMappings={{
            left: "left",
            center: "centered"
          }}
          device="mobile"
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Visual Color"
            setting_name="[options][quiz-option-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-option-color"]
                ? variant.options["quiz-option-color"]
                : "#000"
            }
            placeholder={"#000"}
            col="6"
            classNames="some-padding-right"
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-hover-option-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-hover-option-color"]
                ? variant.options["quiz-hover-option-color"]
                : variant.options["quiz-option-color"]
                ? variant.options["quiz-option-color"]
                : undefined
            }
            placeholder={variant.options["quiz-option-color"] || "#000"}
            col="6"
            classNames="some-padding-left"
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Option Icon Visual Size"
          setting_name="[options][quiz-option-visual-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-visual-font-size"]
              ? variant.options["quiz-option-visual-font-size"]
              : undefined
          }
          placeholder={16}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Icon Visual Size"
          setting_name="[options][quiz-option-visual-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-visual-font-size-mobile"]
              ? variant.options["quiz-option-visual-font-size-mobile"]
              : undefined
          }
          placeholder={variant.options["quiz-option-visual-font-size"] || 16}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Visual Spacing"
          setting_name="[options][quiz-option-visual-spacing]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-visual-spacing"]
              ? variant.options["quiz-option-visual-spacing"]
              : undefined
          }
          placeholder={15}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Visual Spacing"
          setting_name="[options][quiz-option-visual-spacing-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-visual-spacing-mobile"]
              ? variant.options["quiz-option-visual-spacing-mobile"]
              : undefined
          }
          placeholder={variant.options["quiz-option-visual-spacing"] || 15}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Color Visual Size"
          setting_name="[options][quiz-option-color-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-color-size"]
              ? variant.options["quiz-option-color-size"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={200}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Color Visual Size"
          setting_name="[options][quiz-option-color-size-mobile]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["quiz-option-color-size-mobile"]
              ? variant.options["quiz-option-color-size-mobile"]
              : undefined
          }
          placeholder={50}
          min={0}
          max={200}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Opt. Background"
            setting_name="[options][quiz-option-background-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-option-background-color"]
                ? variant.options["quiz-option-background-color"]
                : "#fff"
            }
            placeholder={"#fff"}
            col="6"
            classNames="some-padding-right"
            gradient={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-hover-option-background-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-hover-option-background-color"]
                ? variant.options["quiz-hover-option-background-color"]
                : variant.options["quiz-option-background-color"]
                ? variant.options["quiz-option-background-color"]
                : "#fff"
            }
            placeholder={variant.options["quiz-option-background-color"]}
            col="6"
            classNames="some-padding-left"
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Option Border Placement"
          setting_name="[options][quiz-option-border-placement]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["quiz-option-border-placement"]
              ? variant.options["quiz-option-border-placement"]
              : "on_option"
          }
          tooltip="Appliable to emoji/icon/color/letter field types"
          options={[
            { value: "on_option", text: "On Option" },
            { value: "on_visual", text: "On Visual" },
          ]}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Border Style"
          setting_name="[options][quiz-option-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["quiz-option-border-style"]
              ? variant.options["quiz-option-border-style"]
              : "solid"
          }
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Opt. Border"
            setting_name="[options][quiz-option-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-option-border-color"]
                ? variant.options["quiz-option-border-color"]
                : "#000"
            }
            condition={variant.options["quiz-option-border-style"] !== "none"}
            col="6"
            classNames="some-padding-right"
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-hover-option-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-hover-option-border-color"]
                ? variant.options["quiz-hover-option-border-color"]
                : variant.options["quiz-option-border-color"]
                ? variant.options["quiz-option-border-color"]
                : undefined
            }
            placeholder={variant.options["quiz-option-border-color"] || "#000"}
            allow_empty={true}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {variant.options["quiz-option-border-style"] !== "none" && (
          <div
            className="col-xs-12 some-vertical-space setting"
            style={{ marginBottom: "5px" }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="mb-[5px] text-xl text-black dark:text-white">Option Border Width</div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][quiz-option-border-width-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={variant}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="variants"
                      value={
                        _.isNil(
                          variant.options[`quiz-option-border-width-${side}`]
                        ) == false
                          ? variant.options[`quiz-option-border-width-${side}`]
                          : undefined
                      }
                      placeholder="2px"
                      col="3"
                      allow_empty={true}
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Option Border Radius"
          setting_name="[options][quiz-option-border-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["quiz-option-border-radius"]
              ? variant.options["quiz-option-border-radius"]
              : "cf-slightly-rounded"
          }
        />

        {variant.options["quiz-option-border-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][quiz-option-border-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(
                        variant.options[`quiz-option-border-radius-${side}`]
                      ) == false
                        ? variant.options[`quiz-option-border-radius-${side}`]
                        : undefined
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder={"0px"}
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={variant}
          label="Selected Option Style"
          setting_name="[options][quiz-selected-option-style]"
          setting_type="dropdown"
          object_type="variants"
          value={
            variant.options["quiz-selected-option-style"]
              ? variant.options["quiz-selected-option-style"]
              : undefined
          }
          options={[
            { value: "", text: "Transparent" },
            { value: "custom", text: "Custom" },
          ]}
        />

        <VariantBuilderSetting
          object={variant}
          label="Selected Option Border Style"
          setting_name="[options][quiz-selected-option-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["quiz-selected-option-border-style"]
              ? variant.options["quiz-selected-option-border-style"]
              : "solid"
          }
          condition={variant.options["quiz-selected-option-style"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Selected Option Border Color"
          setting_name="[options][quiz-selected-option-border-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["quiz-selected-option-border-color"]
              ? variant.options["quiz-selected-option-border-color"]
              : "#bdc3c7"
          }
          placeholder="#bdc3c7"
          condition={
            variant.options["quiz-selected-option-border-style"] !== "none" &&
            variant.options["quiz-selected-option-style"] == "custom"
          }
          allow_empty={true}
        />

        {variant.options["quiz-selected-option-border-style"] !== "none" &&
          variant.options["quiz-selected-option-style"] == "custom" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Selected Option Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][quiz-selected-option-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[
                              `quiz-selected-option-border-width-${side}`
                            ]
                          ) == false
                            ? variant.options[
                                `quiz-selected-option-border-width-${side}`
                              ]
                            : "1"
                        }
                        placeholder="0px"
                        col="3"
                        allow_empty={true}
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Selected Option Font color"
          setting_name="[options][quiz-selected-option-font-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["quiz-selected-option-font-color"]
              ? variant.options["quiz-selected-option-font-color"]
              : "#000"
          }
          placeholder={"#000"}
          condition={variant.options["quiz-selected-option-style"] == "custom"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Selected Option Background"
          setting_name="[options][quiz-selected-option-background-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["quiz-selected-option-background-color"]
              ? variant.options["quiz-selected-option-background-color"]
              : undefined
          }
          placeholder={variant.options["quiz-option-background-color"]}
          condition={variant.options["quiz-selected-option-style"] == "custom"}
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Option Drop Shadow"
          setting_name="[options][quiz-option-box-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["quiz-option-box-shadow"]
              ? variant.options["quiz-option-box-shadow"]
              : "none"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-option-box-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["quiz-option-box-shadow-horizontal-distance"]
              ? variant.options["quiz-option-box-shadow-horizontal-distance"]
              : "0"
          }
          condition={variant.options["quiz-option-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-option-box-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["quiz-option-box-shadow-vertical-distance"]
              ? variant.options["quiz-option-box-shadow-vertical-distance"]
              : "0"
          }
          condition={variant.options["quiz-option-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][quiz-option-box-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["quiz-option-box-shadow-blur-radius"]
              ? variant.options["quiz-option-box-shadow-blur-radius"]
              : "0"
          }
          condition={variant.options["quiz-option-box-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][quiz-option-box-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["quiz-option-box-shadow-color"]
              ? variant.options["quiz-option-box-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={variant.options["quiz-option-box-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeQuiz;
