import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingAlignment = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const leftValue = props.valueMappings && props.valueMappings.left ? props.valueMappings.left : "left";
  const centerValue = props.valueMappings && props.valueMappings.center ? props.valueMappings.center : "center";
  const rightValue = props.valueMappings && props.valueMappings.right ? props.valueMappings.right : "right";

  function updateAlignment(value) {
    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">

        {props.valueMappings && props.valueMappings.left && (
          <button
            className={`flex-1 text-2xl p-2 border-none bg-transparent rounded-lg ${
              props.value == leftValue ? "bg-white dark:bg-slate-950" : ""
            }`}
            onClick={() => {
              updateAlignment(leftValue);
            }}
            title={`Align Left`}
          >
            <FontAwesomeIcon icon={`fas fa-align-left`} />
          </button>
        )}

        {props.valueMappings && props.valueMappings.center && (
          <button
            className={`flex-1 text-2xl p-2 border-none bg-transparent rounded-lg ${
              props.value == centerValue ? "bg-white dark:bg-slate-950" : ""
            }`}
            onClick={() => {
              updateAlignment(centerValue);
            }}
            title={`Align Center`}
          >
            <FontAwesomeIcon icon={`fas fa-align-center`} />
          </button>
        )}

        {props.valueMappings && props.valueMappings.right && (
          <button
            className={`flex-1 text-2xl p-2 border-none bg-transparent rounded-lg ${
              props.value == rightValue ? "bg-white dark:bg-slate-950" : ""
            }`}
            onClick={() => {
              updateAlignment(rightValue);
            }}
            title={`Align Right`}
          >
            <FontAwesomeIcon icon={`fas fa-align-right`} />
          </button>
        )}
        
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingAlignment;
