import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsQuizDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name={`Progress Bar`} open={false}>
        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-label-format]"
          setting_type="dropdown"
          value={
            element.options["quiz-progress-label-format"]
              ? element.options["quiz-progress-label-format"]
              : "box"
          }
          label="Progress Label Placement"
          condition={element.options["show-progress-bar"] !== "false"}
          options={[
            { value: "none", text: "None" },
            { value: "box", text: "Inside" },
            { value: "line", text: "Outside" },
          ]}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-outer-width]"
          setting_type="range"
          value={
            element.options["quiz-progress-outer-width"]
              ? element.options["quiz-progress-outer-width"]
              : undefined
          }
          label="Progress Bar container max width"
          placeholder="400"
          allow_empty={true}
          condition={element.options["show-progress-bar"] !== "false"}
          min={100}
          max={2560}
          step={1}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-height]"
          setting_type="range"
          value={
            element.options["quiz-progress-height"]
              ? element.options["quiz-progress-height"]
              : element.options["quiz-progress-label-format"] == "box"
              ? "40"
              : "10"
          }
          label="Progress Bar container height"
          allow_empty={true}
          placeholder="10"
          min={5}
          max={200}
          condition={
            element.options["show-progress-bar"] !== "false" &&
            element.options["quiz-progress-label-format"] !== "box"
          }
          step={1}
          device={"desktop"}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-height-mobile]"
          setting_type="range"
          value={
            element.options["quiz-progress-height-mobile"]
              ? element.options["quiz-progress-height-mobile"]
              : element.options["quiz-progress-height"]
              ? element.options["quiz-progress-height"]
              : element.options["quiz-progress-label-format"] == "box"
              ? "40"
              : "10"
          }
          allow_empty={true}
          placeholder={element.options["quiz-progress-height"] || "10"}
          label="Progress Bar container height"
          condition={
            element.options["quiz-progress-label-format"] !== "box" &&
            element.options["show-progress-bar"] !== "false"
          }
          min={5}
          max={200}
          step={1}
          device={"mobile"}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-question-distance-from-progress]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Distance From Progress Bar"}
          value={
            element.options["quiz-question-distance-from-progress"]
              ? element.options["quiz-question-distance-from-progress"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-distance-from-progress"]
              ? variant.options["quiz-question-distance-from-progress"]
              : 20
          }
          condition={element.options["show-progress-bar"] !== "false"}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-question-distance-from-progress-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Distance From Progress Bar"}
          value={
            element.options["quiz-question-distance-from-progress-mobile"]
              ? element.options["quiz-question-distance-from-progress-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-distance-from-progress-mobile"]
              ? variant.options["quiz-question-distance-from-progress-mobile"]
              : 20
          }
          condition={element.options["show-progress-bar"] !== "false"}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-inner-padding]"
          setting_type="range"
          value={
            element.options["quiz-progress-inner-padding"]
              ? element.options["quiz-progress-inner-padding"]
              : undefined
          }
          allow_empty={true}
          placeholder="15"
          label="Progress Bar message padding"
          condition={
            element.options["quiz-progress-label-format"] == "box" &&
            element.options["show-progress-bar"] !== "false"
          }
          min={5}
          max={100}
          step={1}
          device={"desktop"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-inner-padding-mobile]"
          setting_type="range"
          value={
            element.options["quiz-progress-inner-padding-mobile"]
              ? element.options["quiz-progress-inner-padding-mobile"]
              : undefined
          }
          allow_empty={true}
          placeholder={element.options["quiz-progress-inner-padding"] || "15"}
          label="Progress Bar message padding"
          condition={
            element.options["quiz-progress-label-format"] == "box" &&
            element.options["show-progress-bar"] !== "false"
          }
          min={5}
          max={100}
          step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Progress Msg. font"
          setting_name="[options][quiz-progress-text-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-text-font"]
              ? element.options["quiz-progress-text-font"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-font"]
              ? variant.options["progress-text-font"]
              : ""
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          condition={
            element.options["quiz-progress-label-format"] !== "none" &&
            element.options["show-progress-bar"] !== "false"
          }
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-progress-text-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-text-font-weight"]
              ? element.options["quiz-progress-text-font-weight"]
              : undefined
          }
          condition={
            element.options["quiz-progress-label-format"] !== "none" &&
            element.options["show-progress-bar"] !== "false"
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Progress Message font size"
          setting_name="[options][quiz-progress-text-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-text-size"]
              ? element.options["quiz-progress-text-size"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-size"]
              ? variant.options["progress-text-size"]
              : 16
          }
          condition={
            element.options["quiz-progress-label-format"] !== "none" &&
            element.options["show-progress-bar"] !== "false"
          }
          min={0}
          max={100}
          range_step={1}
          device={"desktop"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Message font size"
          setting_name="[options][quiz-progress-text-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-text-size-mobile"]
              ? element.options["quiz-progress-text-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["progress-text-size-mobile"]
              ? variant.options["progress-text-size-mobile"]
              : variant.options["progress-text-size"]) || 16
          }
          allow_empty={true}
          condition={
            element.options["quiz-progress-label-format"] !== "none" &&
            element.options["show-progress-bar"] !== "false"
          }
          min={0}
          max={100}
          range_step={1}
          device={"mobile"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-text-color]"
          setting_type="color_v2"
          value={
            element.options["quiz-progress-text-color"]
              ? element.options["quiz-progress-text-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-text-color"]
              ? variant.options["progress-text-color"]
              : "#000"
          }
          label="Progress Message text color"
          condition={
            element.options["quiz-progress-label-format"] !== "none" &&
            element.options["show-progress-bar"] !== "false"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-outer-background-color]"
          setting_type="color_v2"
          value={
            element.options["quiz-progress-outer-background-color"]
              ? element.options["quiz-progress-outer-background-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-outer-background-color"]
              ? variant.options["progress-outer-background-color"]
              : "#ecf0f6"
          }
          condition={element.options["show-progress-bar"] !== "false"}
          label="Progress Bar background color"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          object_type="elements"
          object_id={element.id}
          setting_name="[options][quiz-progress-inner-background-color]"
          setting_type="color_v2"
          value={
            element.options["quiz-progress-inner-background-color"]
              ? element.options["quiz-progress-inner-background-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-inner-background-color"]
              ? variant.options["progress-inner-background-color"]
              : "#0D42E4"
          }
          condition={element.options["show-progress-bar"] !== "false"}
          label="Progress Bar meter color"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Progress Border Style"
          setting_name="[options][quiz-progress-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-border-style"]
              ? element.options["quiz-progress-border-style"]
              : undefined
          }
          condition={element.options["show-progress-bar"] !== "false"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Progress Border color"
          setting_name="[options][quiz-progress-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-border-color"]
              ? element.options["quiz-progress-border-color"]
              : undefined
          }
          placeholder={
            variant.options["progress-border-color"]
              ? variant.options["progress-border-color"]
              : "#000"
          }
          condition={
            element.options["quiz-progress-border-style"] !== "none" &&
            element.options["show-progress-bar"] !== "false"
          }
          show_reset={true}
        />

        {element.options["quiz-progress-border-style"] !== "none" &&
          element.options["show-progress-bar"] !== "false" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Progress Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][quiz-progress-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[
                              `quiz-progress-border-width-${side}`
                            ]
                          ) == false
                            ? element.options[
                                `quiz-progress-border-width-${side}`
                              ]
                            : element.options["quiz-progress-border-width"]
                            ? element.options[
                                "quiz-progress-border-width"
                              ].replace("px", "")
                            : "2"
                        }
                        placeholder="0px"
                        col="3"
                        allow_empty={true}
                        note={window.capitalizeFirstLetter(side)}
                        condition={
                          element.options["show-progress-bar"] !== "false"
                        }
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Progress Corner Radius"
          setting_name="[options][quiz-progress-outer-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-progress-outer-radius"]
              ? element.options["quiz-progress-outer-radius"]
              : undefined
          }
          condition={element.options["show-progress-bar"] !== "false"}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][quiz-progress-outer-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(
                      element.options[`quiz-progress-outer-radius-${side}`]
                    ) == false
                      ? element.options[`quiz-progress-outer-radius-${side}`]
                      : "3"
                  }
                  condition={
                    element.options["quiz-progress-outer-radius"] == "custom" &&
                    element.options["show-progress-bar"] !== "false"
                  }
                  allow_empty={true}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Questions`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Question Font family"
          setting_name="[options][quiz-question-font-family]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-family"]
              ? element.options["quiz-question-font-family"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-font-family"]
              ? variant.options["quiz-question-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-question-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-weight"]
              ? element.options["quiz-question-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
        />

        <VariantBuilderSetting
          object={element}
          label="Question Font size"
          setting_name="[options][quiz-question-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-size"]
              ? element.options["quiz-question-font-size"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-font-size"]
              ? variant.options["quiz-question-font-size"]
              : 32
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Question Font size"
          setting_name="[options][quiz-question-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-size-mobile"]
              ? element.options["quiz-question-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-font-size-mobile"]
              ? variant.options["quiz-question-font-size-mobile"]
              : variant.options["quiz-question-font-size"]
              ? variant.options["quiz-question-font-size"]
              : 32
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Question Font line height"
          setting_name="[options][quiz-question-font-line-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-line-height"]
              ? element.options["quiz-question-font-line-height"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-font-line-height"]
              ? variant.options["quiz-question-font-line-height"]
              : 50
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Question Font line height"
          setting_name="[options][quiz-question-font-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-line-height-mobile"]
              ? element.options["quiz-question-font-line-height-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-font-line-height-mobile"]
              ? variant.options["quiz-question-font-line-height-mobile"]
              : variant.options["quiz-question-font-line-height"]
              ? variant.options["quiz-question-font-line-height"]
              : 30
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Question Font color"
          setting_name="[options][quiz-question-font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-question-font-color"]
              ? element.options["quiz-question-font-color"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-font-color"]
              ? variant.options["quiz-question-font-color"]
              : "#000"
          }
          show_reset={true}
        />

        {/* END QUESTIONS SETTINGS */}

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-question-spacing]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Question Content Spacing"}
          value={
            element.options["quiz-question-spacing"]
              ? element.options["quiz-question-spacing"]
              : undefined
          }
          placeholder={variant.options["quiz-question-spacing"] || 0}
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-question-spacing-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Question Content Spacing"}
          value={
            element.options["quiz-question-spacing-mobile"]
              ? element.options["quiz-question-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-question-spacing-mobile"] ||
            variant.options["quiz-question-spacing"] ||
            0
          }
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          show_reset={true}
        />

        {/* START DESCRIPTION SETTINGS */}
        <VariantBuilderSetting
          object={element}
          label="Description Font"
          setting_name="[options][quiz-description-font-family]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-family"]
              ? element.options["quiz-description-font-family"]
              : undefined
          }
          placeholder={
            variant.options["quiz-description-font-family"]
              ? variant.options["quiz-description-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-description-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-weight"]
              ? element.options["quiz-description-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Description Font size"
          setting_name="[options][quiz-description-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-size"]
              ? element.options["quiz-description-font-size"]
              : undefined
          }
          placeholder={
            variant.options["quiz-description-font-size"]
              ? variant.options["quiz-description-font-size"]
              : 32
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Description Font size"
          setting_name="[options][quiz-description-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-size-mobile"]
              ? element.options["quiz-description-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-description-font-size-mobile"]
              ? variant.options["quiz-description-font-size-mobile"]
              : variant.options["quiz-description-font-size"]
              ? variant.options["quiz-description-font-size"]
              : 32
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Description Font line height"
          setting_name="[options][quiz-description-font-line-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-line-height"]
              ? element.options["quiz-description-font-line-height"]
              : undefined
          }
          placeholder={
            variant.options["quiz-description-font-line-height"]
              ? variant.options["quiz-description-font-line-height"]
              : 50
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Description Font line height"
          setting_name="[options][quiz-description-font-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-line-height-mobile"]
              ? element.options["quiz-description-font-line-height-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-description-font-line-height-mobile"]
              ? variant.options["quiz-description-font-line-height-mobile"]
              : variant.options["quiz-description-font-line-height"]
              ? variant.options["quiz-description-font-line-height"]
              : 50
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Description Font color"
          setting_name="[options][quiz-description-font-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-description-font-color"]
              ? element.options["quiz-description-font-color"]
              : undefined
          }
          placeholder={
            variant.options["quiz-description-font-color"]
              ? variant.options["quiz-description-font-color"]
              : "#000"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Loading Border Color"
          setting_name="[options][loading-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["loading-border-color"]
              ? element.options["loading-border-color"]
              : undefined
          }
          placeholder={"#000"}
          tooltip="Applicable for 'Show a Message' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Loading Background Color"
          setting_name="[options][loading-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["loading-background-color"]
              ? element.options["loading-background-color"]
              : undefined
          }
          placeholder={"#8d8d8c"}
          tooltip="Applicable for 'Show a Message' field type with loading bar only"
          show_reset={true}
          gradient={true}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Surveys`} open={false}>
        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-content-placement]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          label={"Content Placement"}
          value={
            element.options["quiz-content-placement"]
              ? element.options["quiz-content-placement"]
              : undefined
          }
          options={[
            { value: "", text: "Above Answers" },
            { value: "below", text: "Below Answers" },
            { value: "left", text: "Left of Answers" },
            { value: "right", text: "Right of Answers" },
          ]}
          device="desktop"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-content-placement-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          label={"Content Placement"}
          value={
            element.options["quiz-content-placement-mobile"]
              ? element.options["quiz-content-placement-mobile"]
              : undefined
          }
          options={[
            { value: "", text: "Above Answers" },
            { value: "below", text: "Below Answers" },
          ]}
          device="mobile"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-content-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label="Content Max Width"
          value={
            element.options["quiz-content-width"]
              ? element.options["quiz-content-width"]
              : undefined
          }
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          placeholder={"100%"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-content-width-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label="Content Max Width"
          value={
            element.options["quiz-content-width-mobile"]
              ? element.options["quiz-content-width-mobile"]
              : undefined
          }
          min={0}
          max={2560}
          range_step={"1"}
          device="mobile"
          placeholder={"100%"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-options-layout]"
          setting_type="layout"
          object_type="elements"
          label={"Options Layout"}
          value={
            element.options["quiz-options-layout"]
              ? element.options["quiz-options-layout"]
              : undefined
          }
          valueMappings={{
            responsive: "responsive",
            stacked: "stacked",
          }}
          device="desktop"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-options-layout-mobile]"
          setting_type="layout"
          object_type="elements"
          label={"Options Layout"}
          value={
            element.options["quiz-options-layout-mobile"]
              ? element.options["quiz-options-layout-mobile"]
              : undefined
          }
          valueMappings={{
            responsive: "responsive",
            stacked: "stacked",
          }}
          device="mobile"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-options-vertical-spacing]"
          setting_type="range"
          object_type="elements"
          label={"Vertical Spacing"}
          value={
            element.options["quiz-options-vertical-spacing"]
              ? element.options["quiz-options-vertical-spacing"]
              : undefined
          }
          placeholder={
            variant.options["quiz-options-vertical-spacing"]
              ? variant.options["quiz-options-vertical-spacing"]
              : 10
          }
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          tooltip="Applicable for all field types but 'Show a Message'"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-options-vertical-spacing-mobile]"
          setting_type="range"
          object_type="elements"
          label={"Vertical Spacing"}
          value={
            element.options["quiz-options-vertical-spacing-mobile"]
              ? element.options["quiz-options-vertical-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-options-vertical-spacing-mobile"] ||
            element.options["quiz-options-vertical-spacing"] ||
            variant.options["quiz-options-vertical-spacing"] ||
            10
          }
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          tooltip="Applicable for all field types but 'Show a Message'"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-options-horizontal-spacing]"
          setting_type="range"
          object_type="elements"
          label={"Horizontal Spacing"}
          value={
            element.options["quiz-options-horizontal-spacing"]
              ? element.options["quiz-options-horizontal-spacing"]
              : undefined
          }
          placeholder={
            variant.options["quiz-options-horizontal-spacing"]
              ? variant.options["quiz-options-horizontal-spacing"]
              : 10
          }
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="desktop"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-options-horizontal-spacing-mobile]"
          setting_type="range"
          object_type="elements"
          label={"Horizontal Spacing"}
          value={
            element.options["quiz-options-horizontal-spacing-mobile"]
              ? element.options["quiz-options-horizontal-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-options-horizontal-spacing-mobile"] ||
            element.options["quiz-options-horizontal-spacing"] ||
            variant.options["quiz-options-horizontal-spacing"] ||
            10
          }
          allow_empty={true}
          min={0}
          max={200}
          range_step={"1"}
          device="mobile"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-image-placement]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          label={"Options Image Placement"}
          value={
            element.options["quiz-image-placement"]
              ? element.options["quiz-image-placement"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "centered", text: "Centered" },
            { value: "left", text: "Left" },
          ]}
          device="desktop"
          tooltip="Applicable for 'image' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-image-placement-mobile]"
          setting_type="dropdown"
          object_type="elements"
          object_id={element.id}
          label={"Options Image Placement"}
          value={
            element.options["quiz-image-placement-mobile"]
              ? element.options["quiz-image-placement-mobile"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "centered", text: "Centered" },
            { value: "left", text: "Left" },
          ]}
          device="mobile"
          tooltip="Applicable for 'image' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Option Width"
          setting_name="[options][quiz-option-width-style]"
          setting_type="box_width"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-option-width-style"]
              ? element.options["quiz-option-width-style"]
              : undefined
          }
          device="desktop"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-option-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label="Option Custom Width"
          value={
            element.options["quiz-option-width-value"]
              ? element.options["quiz-option-width-value"]
              : undefined
          }
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) with custom width only"
          condition={element.options["quiz-option-width-style"] == "custom"}
          min={0}
          max={2560}
          range_step={"1"}
          device="desktop"
          placeholder={"100%"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Option Custom Width"
          setting_name="[options][quiz-option-width-style-mobile]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-option-width-style-mobile"]
              ? element.options["quiz-option-width-style-mobile"]
              : undefined
          }
          device="mobile"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-option-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Option Width"}
          value={
            element.options["quiz-option-width-value-mobile"]
              ? element.options["quiz-option-width-value-mobile"]
              : undefined
          }
          condition={
            element.options["quiz-option-width-style-mobile"] == "custom"
          }
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) with custom width only"
          min={0}
          max={280}
          range_step={"1"}
          allow_empty={true}
          placeholder={"100%"}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-option-image-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Option Image Width"}
          value={
            element.options["quiz-option-image-width"]
              ? element.options["quiz-option-image-width"]
              : undefined
          }
          min={10}
          max={1300}
          range_step={"1"}
          placeholder={"110"}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'image' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-option-image-width-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Option Image Width"}
          value={
            element.options["quiz-option-image-width-mobile"]
              ? element.options["quiz-option-image-width-mobile"]
              : undefined
          }
          min={10}
          max={1300}
          device="mobile"
          range_step={"1"}
          placeholder={element.options["quiz-option-image-width"] || 110}
          allow_empty={true}
          tooltip="Applicable for 'image' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-option-image-height]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Option Image Height"}
          value={
            element.options["quiz-option-image-height"]
              ? element.options["quiz-option-image-height"]
              : undefined
          }
          min={0}
          max={1300}
          range_step={"1"}
          placeholder={"100%"}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'image' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-option-image-height-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Option Image Height"}
          value={
            element.options["quiz-option-image-height-mobile"]
              ? element.options["quiz-option-image-height-mobile"]
              : undefined
          }
          min={0}
          max={1300}
          device="mobile"
          range_step={"1"}
          placeholder={"100%"}
          allow_empty={true}
          tooltip="Applicable for 'image' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Option Text Alignment"
          setting_name="[options][quiz-option-text-alignment]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-option-text-alignment"]
              ? element.options["quiz-option-text-alignment"]
              : null
          }
          valueMappings={{
            left: "left",
            center: "center",
            right: "right",
          }}
          device="desktop"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Option Text Alignment"
          setting_name="[options][quiz-option-text-alignment-mobile]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-option-text-alignment-mobile"]
              ? element.options["quiz-option-text-alignment-mobile"]
              : null
          }
          valueMappings={{
            left: "left",
            center: "center",
            right: "right",
          }}
          device="mobile"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Font family"
          setting_name="[options][quiz-option-font-family]"
          setting_type="select"
          object_type="elements"
          value={
            element.options["quiz-option-font-family"]
              ? element.options["quiz-option-font-family"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          col="8"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-option-font-weight]"
          setting_type="font_weight"
          show_reset={true}
          label="Weight"
          object_type="elements"
          value={
            element.options["quiz-option-font-weight"]
              ? element.options["quiz-option-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Font size"
          setting_name="[options][quiz-option-font-size]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-font-size"]
              ? element.options["quiz-option-font-size"]
              : undefined
          }
          placeholder={variant.options["quiz-option-font-size"] || 32}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Font size"
          setting_name="[options][quiz-option-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-font-size-mobile"]
              ? element.options["quiz-option-font-size-mobile"]
              : undefined
          }
          placeholder={variant.options["quiz-option-font-size-mobile"] || 14}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Font line height"
          setting_name="[options][quiz-option-font-line-height]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-font-line-height"]
              ? element.options["quiz-option-font-line-height"]
              : undefined
          }
          placeholder={variant.options["quiz-option-font-line-height"] || 50}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Font line height"
          setting_name="[options][quiz-option-font-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-font-line-height-mobile"]
              ? element.options["quiz-option-font-line-height-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-option-font-line-height-mobile"] || 25
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Font color"
            setting_name="[options][quiz-option-font-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-option-font-color"]
                ? element.options["quiz-option-font-color"]
                : undefined
            }
            placeholder={variant.options["quiz-option-font-color"] || "#000"}
            tooltip="Applicable for 'image, color, emoji, icon, letter, survey, radio, checkbox' field type(s) only"
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Hover"
            setting_name="[options][quiz-hover-option-font-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-hover-option-font-color"]
                ? element.options["quiz-hover-option-font-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-hover-option-font-color"] ||
              element.options["quiz-option-font-color"] ||
              "#000"
            }
            tooltip="Applicable for 'image, color, emoji, icon, letter, survey, radio, checkbox' field type(s) only"
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-visual-placement]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          label={"Options Visual Placement"}
          value={
            element.options["quiz-visual-placement"]
              ? element.options["quiz-visual-placement"]
              : undefined
          }
          valueMappings={{
            left: "left",
            center: "centered",
          }}
          device="desktop"
          tooltip="Applicable for 'color, emoji, icon, letter' field type(s) only"
          show_reset={true}
        />
        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-visual-placement-mobile]"
          setting_type="alignment"
          object_type="elements"
          object_id={element.id}
          label={"Options Visual Placement"}
          value={
            element.options["quiz-visual-placement-mobile"]
              ? element.options["quiz-visual-placement-mobile"]
              : undefined
          }
          valueMappings={{
            left: "left",
            center: "centered",
          }}
          device="mobile"
          tooltip="Applicable for 'color, emoji, icon, letter' field type(s) only"
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Icon Visual"
            setting_name="[options][quiz-option-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-option-color"]
                ? element.options["quiz-option-color"]
                : undefined
            }
            placeholder={variant.options["quiz-option-color"] || "#000"}
            tooltip="Applicable for 'emoji, icon, letter' field type(s) only"
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Hover"
            setting_name="[options][quiz-hover-option-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-hover-option-color"]
                ? element.options["quiz-hover-option-color"]
                : undefined
            }
            placeholder={
              element.options["quiz-option-color"] ||
              variant.options["quiz-hover-option-color"] ||
              variant.options["quiz-option-color"] ||
              "#000"
            }
            tooltip="Applicable for 'emoji, icon, letter' field type(s) only"
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Icon Visual Size"
          setting_name="[options][quiz-option-visual-font-size]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-visual-font-size"]
              ? element.options["quiz-option-visual-font-size"]
              : undefined
          }
          placeholder={variant.options["quiz-option-visual-font-size"] || 16}
          min={0}
          max={500}
          range_step={1}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'emoji, icon, letter' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Icon Visual Size"
          setting_name="[options][quiz-option-visual-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-visual-font-size-mobile"]
              ? element.options["quiz-option-visual-font-size-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-option-visual-font-size-mobile"] ||
            element.options["quiz-option-visual-font-size"] ||
            variant.options["quiz-option-visual-font-size"] ||
            16
          }
          min={0}
          max={500}
          range_step={1}
          device="mobile"
          allow_empty={true}
          tooltip="Applicable for 'emoji, icon, letter' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Visual Spacing"
          setting_name="[options][quiz-option-visual-spacing]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-visual-spacing"]
              ? element.options["quiz-option-visual-spacing"]
              : undefined
          }
          placeholder={variant.options["quiz-option-visual-spacing"] || 15}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'emoji, icon, letter, color' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Visual Spacing"
          setting_name="[options][quiz-option-visual-spacing-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-visual-spacing-mobile"]
              ? element.options["quiz-option-visual-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["quiz-option-visual-spacing-mobile"] || 15
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          tooltip="Applicable for 'emoji, icon, letter, color' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Color Visual Size"
          setting_name="[options][quiz-option-color-size]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-color-size"]
              ? element.options["quiz-option-color-size"]
              : undefined
          }
          placeholder={variant.options["quiz-option-color-size"] || 50}
          min={0}
          max={200}
          range_step={1}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'color' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Color Visual Size"
          setting_name="[options][quiz-option-color-size-mobile]"
          setting_type="range"
          object_type="elements"
          value={
            element.options["quiz-option-color-size-mobile"]
              ? element.options["quiz-option-color-size-mobile"]
              : undefined
          }
          placeholder={variant.options["quiz-option-color-size-mobile"] || 50}
          min={0}
          max={200}
          range_step={1}
          device="mobile"
          allow_empty={true}
          tooltip="Applicable for 'color' field type(s) only"
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Background"
            setting_name="[options][quiz-option-background-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-option-background-color"]
                ? element.options["quiz-option-background-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-option-background-color"] || "#fff"
            }
            tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Hover"
            setting_name="[options][quiz-hover-option-background-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-hover-option-background-color"]
                ? element.options["quiz-hover-option-background-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-hover-option-background-color"] ||
              element.options["quiz-option-background-color"]
            }
            tooltip="Applicable for 'image, color, emoji, icon, letter, survey' field type(s) only"
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Border Placement"
          setting_name="[options][quiz-option-border-placement]"
          setting_type="dropdown"
          object_type="elements"
          value={
            element.options["quiz-option-border-placement"]
              ? element.options["quiz-option-border-placement"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "on_option", text: "On Option" },
            { value: "on_visual", text: "On Visual" },
          ]}
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Border Style"
          setting_name="[options][quiz-option-border-style]"
          setting_type="border_style"
          object_type="elements"
          value={
            element.options["quiz-option-border-style"]
              ? element.options["quiz-option-border-style"]
              : undefined
          }
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Border Color"
            setting_name="[options][quiz-option-border-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-option-border-color"]
                ? element.options["quiz-option-border-color"]
                : undefined
            }
            placeholder={variant.options["quiz-option-border-color"] || "#000"}
            condition={element.options["quiz-option-border-style"] !== "none"}
            tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            object_id={element.id}
            label="Hover"
            setting_name="[options][quiz-hover-option-border-color]"
            setting_type="color_v2"
            object_type="elements"
            value={
              element.options["quiz-hover-option-border-color"]
                ? element.options["quiz-hover-option-border-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-hover-option-border-color"] ||
              element.options["quiz-option-border-color"] ||
              "#000"
            }
            condition={element.options["quiz-option-border-style"] !== "none"}
            tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
            allow_empty={true}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Selected Option Style"
          setting_name="[options][quiz-selected-option-style]"
          setting_type="dropdown"
          object_type="elements"
          value={
            element.options["quiz-selected-option-style"]
              ? element.options["quiz-selected-option-style"]
              : undefined
          }
          options={[
            { value: "", text: "Theme Default" },
            { value: "transparent", text: "Transparent" },
            { value: "custom", text: "Custom" },
          ]}
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Selected Option Border Style"
          setting_name="[options][quiz-selected-option-border-style]"
          setting_type="border_style"
          object_type="elements"
          value={
            element.options["quiz-selected-option-border-style"]
              ? element.options["quiz-selected-option-border-style"]
              : undefined
          }
          condition={element.options["quiz-selected-option-style"] == "custom"}
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Selected Option Border Color"
          setting_name="[options][quiz-selected-option-border-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["quiz-selected-option-border-color"]
              ? element.options["quiz-selected-option-border-color"]
              : undefined
          }
          placeholder={
            variant.options["quiz-selected-option-border-color"] || "#bdc3c7"
          }
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          condition={
            element.options["quiz-selected-option-border-style"] !== "none" &&
            element.options["quiz-selected-option-style"] == "custom"
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Selected Option Font color"
          setting_name="[options][quiz-selected-option-font-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["quiz-selected-option-font-color"]
              ? element.options["quiz-selected-option-font-color"]
              : undefined
          }
          placeholder={
            variant.options["quiz-selected-option-font-color"] || "#000"
          }
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          condition={element.options["quiz-selected-option-style"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Selected Option Background"
          setting_name="[options][quiz-selected-option-background-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["quiz-selected-option-background-color"]
              ? element.options["quiz-selected-option-background-color"]
              : undefined
          }
          placeholder={
            element.options["quiz-option-background-color"] ||
            variant.options["quiz-selected-option-background-color"]
          }
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          condition={element.options["quiz-selected-option-style"] == "custom"}
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Option Drop Shadow"
          setting_name="[options][quiz-option-box-shadow]"
          setting_type="shadow"
          object_type="elements"
          value={
            element.options["quiz-option-box-shadow"]
              ? element.options["quiz-option-box-shadow"]
              : undefined
          }
          tooltip="Applicable for 'image, color, emoji, icon, survey, letter' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-option-box-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          label={"Shadow Horizontal Distance"}
          value={
            element.options["quiz-option-box-shadow-horizontal-distance"]
              ? element.options["quiz-option-box-shadow-horizontal-distance"]
              : undefined
          }
          placeholder={
            element.options["quiz-option-box-shadow-horizontal-distance"]
              ? element.options["quiz-option-box-shadow-horizontal-distance"]
              : variant.options["quiz-option-box-shadow-horizontal-distance"]
          }
          condition={element.options["quiz-option-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-option-box-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          label={"Shadow Vertical Distance"}
          value={
            element.options["quiz-option-box-shadow-vertical-distance"]
              ? element.options["quiz-option-box-shadow-vertical-distance"]
              : undefined
          }
          placeholder={
            element.options["quiz-option-box-shadow-vertical-distance"]
              ? element.options["quiz-option-box-shadow-vertical-distance"]
              : variant.options["quiz-option-box-shadow-vertical-distance"]
          }
          condition={element.options["quiz-option-box-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][quiz-option-box-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          label={"Shadow Blur Radius"}
          value={
            element.options["quiz-option-box-shadow-blur-radius"]
              ? element.options["quiz-option-box-shadow-blur-radius"]
              : undefined
          }
          placeholder={
            element.options["quiz-option-box-shadow-blur-radius"]
              ? element.options["quiz-option-box-shadow-blur-radius"]
              : variant.options["quiz-option-box-shadow-blur-radius"]
          }
          condition={element.options["quiz-option-box-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label="Shadow Color"
          setting_name="[options][quiz-option-box-shadow-color]"
          setting_type="color_v2"
          object_type="elements"
          value={
            element.options["quiz-option-box-shadow-color"]
              ? element.options["quiz-option-box-shadow-color"]
              : undefined
          }
          placeholder={variant.options["quiz-option-box-shadow-color"]}
          condition={element.options["quiz-option-box-shadow"] == "custom"}
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Fields`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Field Width"
          setting_name="[options][quiz-answer-width]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-width"]
              ? element.options["quiz-answer-width"]
              : undefined
          }
          valueMappings={{
            slim: "cf-form-small",
            regular: "cf-form-mid",
            full: "cf-form-large",
            custom: "custom",
          }}
          show_reset={true}
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-answer-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Field Width"}
          value={
            element.options["quiz-answer-width-value"]
              ? element.options["quiz-answer-width-value"]
              : undefined
          }
          placeholder={"Auto"}
          show_reset={true}
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          condition={element.options["quiz-answer-width"] == "custom"}
          min={0}
          max={2000}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Size"
          setting_name="[options][quiz-answer-field-size]"
          setting_type="box_height"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-size"]
              ? element.options["quiz-answer-field-size"]
              : undefined
          }
          show_reset={true}
          valueMappings={{
            regular: "cf-field-regular",
            large: "cf-field-large",
            custom: "custom",
          }}
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-answer-field-size-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Field Padding value"}
          value={
            element.options["form-width-value"]
              ? element.options["form-width-value"]
              : undefined
          }
          placeholder={"10"}
          show_reset={true}
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          condition={element.options["quiz-answer-field-size"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Font"
          setting_name="[options][quiz-answer-field-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-font"]
              ? element.options["quiz-answer-field-font"]
              : undefined
          }
          placeholder={
            variant.options["field-font"]
              ? variant.options["field-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={ctas.builder.fonts.load_font_family}
          col="8"
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-answer-field-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-font-weight"]
              ? element.options["quiz-answer-field-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Font Size"
          setting_name="[options][quiz-answer-field-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-font-size"]
              ? element.options["quiz-answer-field-font-size"]
              : undefined
          }
          placeholder={
            variant.options["field-font-size"]
              ? variant.options["field-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Font Size"
          setting_name="[options][quiz-answer-field-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-font-size-mobile"]
              ? element.options["quiz-answer-field-font-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["field-font-size-mobile"]
              ? variant.options["field-font-size-mobile"]
              : variant.options["field-font-size"]) || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Text Color"
          setting_name="[options][quiz-answer-field-text-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-text-color"]
              ? element.options["quiz-answer-field-text-color"]
              : undefined
          }
          placeholder={
            variant.options["field-text-color"]
              ? variant.options["field-text-color"]
              : "#000"
          }
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Background Color"
          setting_name="[options][quiz-answer-field-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-background-color"]
              ? element.options["quiz-answer-field-background-color"]
              : undefined
          }
          placeholder={
            variant.options["field-background-color"]
              ? variant.options["field-background-color"]
              : "#fff"
          }
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field Border Style"
          setting_name="[options][quiz-answer-field-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-border-style"]
              ? element.options["quiz-answer-field-border-style"]
              : undefined
          }
          placeholder={
            variant.options["field-border-style"]
              ? variant.options["field-border-style"]
              : undefined
          }
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Field border color"
          setting_name="[options][quiz-answer-field-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-answer-field-border-color"]
              ? element.options["quiz-answer-field-border-color"]
              : undefined
          }
          placeholder={
            variant.options["field-border-color"]
              ? variant.options["field-border-color"]
              : "#DBDBDB"
          }
          tooltip="Applicable for 'text, dropdown, paragraph, number, range' field type(s) only"
          condition={
            element.options["quiz-answer-field-border-style"] !== "none"
          }
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name={`Next/Back Buttons`} open={false}>
        <VariantBuilderSetting
          object={element}
          label="Button Padding"
          setting_name="[options][quiz-button-size]"
          setting_type="box_height"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-size"]
              ? element.options["quiz-button-size"]
              : "cf-button-regular"
          }
          valueMappings={{
            small: "cf-button-small",
            regular: "cf-button-regular",
            large: "cf-button-large",
            custom: "custom",
          }}
          show_reset={true}
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-size-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label="Custom Button Padding"
          value={
            element.options["quiz-button-size-value"]
              ? element.options["quiz-button-size-value"]
              : undefined
          }
          condition={
            element.options["quiz-button-size"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          show_reset={true}
          min={0}
          max={200}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Width"
          setting_name="[options][quiz-button-width]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-width"]
              ? element.options["quiz-button-width"]
              : "cf-full-width"
          }
          valueMappings={{
            auto: "cf-button-slim",
            regular: "cf-button-mid",
            custom: "custom",
          }}
          condition={
            element.options["form-type"] !== "horizontal" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Button Width"}
          value={
            element.options["quiz-button-width-value"]
              ? element.options["quiz-button-width-value"]
              : undefined
          }
          condition={
            element.options["quiz-button-width"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          device="desktop"
          min={0}
          max={2000}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Width"
          setting_name="[options][quiz-button-width-mobile]"
          setting_type="box_width"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-width-mobile"]
              ? element.options["quiz-button-width-mobile"]
              : "cf-full-width"
          }
          valueMappings={{
            auto: "cf-button-slim",
            regular: "cf-button-mid",
            custom: "custom",
          }}
          condition={
            element.options["form-type"] !== "horizontal" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Button Width"}
          value={
            element.options["quiz-button-width-value-mobile"]
              ? element.options["quiz-button-width-value-mobile"]
              : undefined
          }
          condition={
            element.options["quiz-button-width-mobile"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          device="mobile"
          min={0}
          max={2000}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font"
          setting_name="[options][quiz-button-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-font"]
              ? element.options["quiz-button-font"]
              : undefined
          }
          placeholder={
            variant.options["button-font"]
              ? variant.options["button-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          callback={function (new_value, old_value) {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-font-weight"]
              ? element.options["quiz-button-font-weight"]
              : undefined
          }
          placeholder={variant.options["button-font-weight"]}
          col="4"
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button font size"
          setting_name="[options][quiz-button-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-font-size"]
              ? element.options["quiz-button-font-size"]
              : undefined
          }
          placeholder={
            variant.options["button-font-size"]
              ? variant.options["button-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="font-size"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("font-size");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font size"
          setting_name="[options][quiz-button-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-font-size-mobile"]
              ? element.options["quiz-button-font-size-mobile"]
              : undefined
          }
          placeholder={
            (variant.options["button-font-size-mobile"]
              ? variant.options["button-font-size-mobile"]
              : variant.options["button-font-size"]) || 16
          }
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="But. text color"
            setting_name="[options][quiz-button-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button-text-color"]
                ? element.options["quiz-button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button-text-color"]
                ? variant.options["button-text-color"]
                : "#fff"
            }
            col="6"
            classNames="some-padding-right"
            condition={
              element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true"
            }
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-button–hover-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button–hover-text-color"]
                ? element.options["quiz-button–hover-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button–hover-text-color"]
                ? variant.options["button–hover-text-color"]
                : variant.options["button-text-color"]
            }
            col="6"
            condition={
              element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true"
            }
            classNames="some-padding-left"
            show_reset={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Button color"
            setting_name="[options][quiz-button-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button-color"]
                ? element.options["quiz-button-color"]
                : undefined
            }
            placeholder={
              variant.options["button-color"]
                ? variant.options["button-color"]
                : "#0072f7"
            }
            condition={
              element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-button–hover-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button–hover-color"]
                ? element.options["quiz-button–hover-color"]
                : undefined
            }
            placeholder={
              element.options["quiz-button-color"]
                ? element.options["quiz-button-color"]
                : variant.options["button–hover-color"]
                ? variant.options["button–hover-color"]
                : variant.options["button-color"]
            }
            condition={
              element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label="Button Border Style"
          setting_name="[options][quiz-button-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-border-style"]
              ? element.options["quiz-button-border-style"]
              : undefined
          }
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Border Color"
            setting_name="[options][quiz-button-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button-border-color"]
                ? element.options["quiz-button-border-color"]
                : undefined
            }
            placeholder={variant.options["button-border-color"]}
            condition={
              element.options["quiz-button-border-style"] &&
              element.options["quiz-button-border-style"] !== "" &&
              element.options["quiz-button-border-style"] !== "none" &&
              (element.options["hide-back-button"] !== "true" ||
                element.options["hide-next-button"] !== "true")
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-button-hover-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button-hover-border-color"]
                ? element.options["quiz-button-hover-border-color"]
                : undefined
            }
            placeholder={
              element.options["quiz-button-border-color"]
                ? element.options["quiz-button-border-color"]
                : variant.options["button-hover-border-color"]
            }
            condition={
              element.options["quiz-button-border-style"] &&
              element.options["quiz-button-border-style"] !== "" &&
              element.options["quiz-button-border-style"] !== "none" &&
              (element.options["hide-back-button"] !== "true" ||
                element.options["hide-next-button"] !== "true")
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {element.options["quiz-button-border-style"] &&
          element.options["quiz-button-border-style"] !== "none" &&
          (element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true") && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Button Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][quiz-button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[`quiz-button-border-width-${side}`]
                          ) == false
                            ? element.options[
                                `quiz-button-border-width-${side}`
                              ]
                            : element.options["quiz-button-border-width"]
                            ? element.options[
                                "quiz-button-border-width"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder="0px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Button Corner Radius"
          setting_name="[options][quiz-button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-radius"]
              ? element.options["quiz-button-radius"]
              : undefined
          }
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][quiz-button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`quiz-button-radius-${side}`]) ==
                    false
                      ? element.options[`quiz-button-radius-${side}`]
                      : undefined
                  }
                  condition={
                    element.options["quiz-button-radius"] == "custom" &&
                    (element.options["hide-back-button"] !== "true" ||
                      element.options["hide-next-button"] !== "true")
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label="Button Shadow"
          setting_name="[options][quiz-button-shadow]"
          setting_type="shadow"
          show_reset={true}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-button-shadow"]
              ? element.options["quiz-button-shadow"]
              : undefined
          }
          condition={
            element.options["hide-back-button"] !== "true" ||
            element.options["hide-next-button"] !== "true"
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["quiz-button-shadow-horizontal-distance"]
              ? element.options["quiz-button-shadow-horizontal-distance"]
              : undefined
          }
          condition={
            element.options["quiz-button-shadow"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["quiz-button-shadow-vertical-distance"]
              ? element.options["quiz-button-shadow-vertical-distance"]
              : undefined
          }
          condition={
            element.options["quiz-button-shadow"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["quiz-button-shadow-blur-radius"]
              ? element.options["quiz-button-shadow-blur-radius"]
              : undefined
          }
          condition={
            element.options["quiz-button-shadow"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][quiz-button-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["quiz-button-shadow-spread-radius"]
              ? element.options["quiz-button-shadow-spread-radius"]
              : undefined
          }
          condition={
            element.options["quiz-button-shadow"] == "custom" &&
            (element.options["hide-back-button"] !== "true" ||
              element.options["hide-next-button"] !== "true")
          }
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Button Shadow Color"
            setting_name="[options][quiz-button-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button-shadow-color"]
                ? element.options["quiz-button-shadow-color"]
                : undefined
            }
            condition={
              element.options["quiz-button-shadow"] == "custom" &&
              (element.options["hide-back-button"] !== "true" ||
                element.options["hide-next-button"] !== "true")
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-button-hover-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-button-hover-shadow-color"]
                ? element.options["quiz-button-hover-shadow-color"]
                : undefined
            }
            placeholder={
              element.options["quiz-button-shadow-color"]
                ? element.options["quiz-button-shadow-color"]
                : variant.options["quiz-button-hover-shadow-color"]
                ? variant.options["quiz-button-hover-shadow-color"]
                : variant.options["quiz-button-shadow-color"]
            }
            condition={
              element.options["quiz-button-shadow"] == "custom" &&
              (element.options["hide-back-button"] !== "true" ||
                element.options["hide-next-button"] !== "true")
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Back But. Text"
            setting_name="[options][quiz-back-button-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-back-button-text-color"]
                ? element.options["quiz-back-button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button-text-color"] ||
              element.options["quiz-button-text-color"] ||
              variant.options["button-text-color"] ||
              "#fff"
            }
            allow_empty={true}
            col="6"
            classNames="some-padding-right"
            condition={element.options["hide-back-button"] !== "true"}
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-back-button–hover-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-back-button–hover-text-color"]
                ? element.options["quiz-back-button–hover-text-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button–hover-text-color"] ||
              element.options["quiz-button–hover-text-color"] ||
              variant.options["button–hover-text-color"] ||
              variant.options["button-text-color"]
            }
            allow_empty={true}
            col="6"
            condition={element.options["hide-back-button"] !== "true"}
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Back But. color"
            setting_name="[options][quiz-back-button-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-back-button-color"]
                ? element.options["quiz-back-button-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button-color"] ||
              element.options["quiz-button-color"] ||
              variant.options["button-color"] ||
              "#0072f7"
            }
            allow_empty={true}
            condition={element.options["hide-back-button"] !== "true"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-back-button–hover-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-back-button–hover-color"]
                ? element.options["quiz-back-button–hover-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button–hover-color"] ||
              element.options["quiz-button–hover-color"] ||
              variant.options["button–hover-color"] ||
              variant.options["button-color"]
            }
            allow_empty={true}
            condition={element.options["hide-back-button"] !== "true"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label="Back Button Border Style"
          setting_name="[options][quiz-back-button-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-back-button-border-style"]
              ? element.options["quiz-back-button-border-style"]
              : undefined
          }
          condition={element.options["hide-back-button"] !== "true"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Back Bord. Color"
            setting_name="[options][quiz-back-button-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-back-button-border-color"]
                ? element.options["quiz-back-button-border-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button-border-color"] ||
              element.options["quiz-button-border-color"] ||
              variant.options["button-border-color"]
            }
            condition={
              element.options["hide-back-button"] !== "true" &&
              element.options["quiz-back-button-border-style"] &&
              element.options["quiz-back-button-border-style"] !== "" &&
              element.options["quiz-back-button-border-style"] !== "none"
            }
            allow_empty={true}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][quiz-back-button-hover-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["quiz-back-button-hover-border-color"]
                ? element.options["quiz-back-button-hover-border-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button-hover-border-color"] ||
              element.options["quiz-button-hover-border-color"] ||
              variant.options["button-hover-border-color"]
            }
            condition={
              element.options["hide-back-button"] !== "true" &&
              element.options["quiz-back-button-border-style"] &&
              element.options["quiz-back-button-border-style"] !== "" &&
              element.options["quiz-back-button-border-style"] !== "none"
            }
            allow_empty={true}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        {element.options["quiz-back-button-border-style"] &&
          element.options["quiz-back-button-border-style"] !== "none" &&
          element.options["hide-back-button"] !== "true" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Back Button Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][quiz-back-button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[
                              `quiz-back-button-border-width-${side}`
                            ]
                          ) == false
                            ? element.options[
                                `quiz-back-button-border-width-${side}`
                              ]
                            : element.options["quiz-back-button-border-width"]
                            ? element.options[
                                "quiz-button-border-width"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder={
                          element.options["quiz-button-border-style"] || "1px"
                        }
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label="Back Button Corner Radius"
          setting_name="[options][quiz-back-button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["quiz-back-button-radius"]
              ? element.options["quiz-back-button-radius"]
              : undefined
          }
          condition={element.options["hide-back-button"] !== "true"}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][quiz-back-button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(
                      element.options[`quiz-back-button-radius-${side}`]
                    ) == false
                      ? element.options[`quiz-back-button-radius-${side}`]
                      : undefined
                  }
                  condition={
                    element.options["quiz-back-button-radius"] == "custom" &&
                    element.options["hide-back-button"] !== "true"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  allow_empty={true}
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default ElementSettingsQuizDesign;
