import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ElementTypeItem = (props) => {
  const [contextData] = useContext(VariantContextData);

  return (
    <div className="cf-draggable-element center">
      <div
        className={`element-type-item ${
          props.small ? "w-[90px] inline-block float-left mr-[7px]" : "w-full"
        } h-[96px] p-4 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-slate-700 text-black dark:text-slate-300 dark:hover:text-white rounded-lg cursor-pointer`}
        data-type={props.element_type}
        onClick={(e) => {
          if (props.callback) {
            props.callback();
          }
        }}
      >
        <div className="w-full pt-6 h-[53px] bg-white dark:bg-slate-950 text-blue-600 dark:text-white rounded-lg text-center text-4xl">
          {props.element_type == "headline" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M4.63038 18.2155V9.90779M4.63038 18.2155H1.86115M4.63038 18.2155H7.39961M4.63038 9.90779V1.6001M4.63038 9.90779H17.0919M4.63038 1.6001H1.86115M4.63038 1.6001H7.39961M17.0919 9.90779V18.2155M17.0919 9.90779V1.6001M17.0919 18.2155H14.3227M17.0919 18.2155H19.8611M17.0919 1.6001H14.3227M17.0919 1.6001H19.8611" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "text" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.08571 19.2002H12.3529M12.3529 19.2002H15.7714M12.3529 19.2002V4.80017M12.3529 4.80017H7.02857C6.46051 4.80017 6 5.26068 6 5.82874V7.34135M12.3529 4.80017H17.2538C17.8218 4.80017 18.2823 5.26068 18.2823 5.82874V7.76488" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "image" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3.41789 18.6054C3.02737 18.9959 3.02737 19.6291 3.41789 20.0196C3.80842 20.4101 4.44158 20.4101 4.83211 20.0196L3.41789 18.6054ZM8.625 14.8125L9.33211 14.1054C8.94158 13.7149 8.30842 13.7149 7.91789 14.1054L8.625 14.8125ZM10.875 17.0625L10.1679 17.7696C10.5584 18.1601 11.1916 18.1601 11.5821 17.7696L10.875 17.0625ZM15.9375 12L16.6446 11.2929C16.2541 10.9024 15.6209 10.9024 15.2304 11.2929L15.9375 12ZM19.7304 17.2071C20.1209 17.5976 20.7541 17.5976 21.1446 17.2071C21.5351 16.8166 21.5351 16.1834 21.1446 15.7929L19.7304 17.2071ZM4.83211 20.0196L9.33211 15.5196L7.91789 14.1054L3.41789 18.6054L4.83211 20.0196ZM7.91789 15.5196L10.1679 17.7696L11.5821 16.3554L9.33211 14.1054L7.91789 15.5196ZM11.5821 17.7696L16.6446 12.7071L15.2304 11.2929L10.1679 16.3554L11.5821 17.7696ZM15.2304 12.7071L19.7304 17.2071L21.1446 15.7929L16.6446 11.2929L15.2304 12.7071ZM6.375 4H17.625V2H6.375V4ZM20 6.375V17.625H22V6.375H20ZM17.625 20H6.375V22H17.625V20ZM4 17.625V6.375H2V17.625H4ZM6.375 20C5.06332 20 4 18.9367 4 17.625H2C2 20.0412 3.95875 22 6.375 22V20ZM20 17.625C20 18.9367 18.9367 20 17.625 20V22C20.0412 22 22 20.0412 22 17.625H20ZM17.625 4C18.9367 4 20 5.06332 20 6.375H22C22 3.95875 20.0412 2 17.625 2V4ZM6.375 2C3.95875 2 2 3.95875 2 6.375H4C4 5.06332 5.06332 4 6.375 4V2ZM8.75 8.0625C8.75 8.4422 8.4422 8.75 8.0625 8.75V10.75C9.54677 10.75 10.75 9.54677 10.75 8.0625H8.75ZM8.0625 8.75C7.6828 8.75 7.375 8.4422 7.375 8.0625H5.375C5.375 9.54677 6.57823 10.75 8.0625 10.75V8.75ZM7.375 8.0625C7.375 7.6828 7.6828 7.375 8.0625 7.375V5.375C6.57823 5.375 5.375 6.57823 5.375 8.0625H7.375ZM8.0625 7.375C8.4422 7.375 8.75 7.6828 8.75 8.0625H10.75C10.75 6.57823 9.54677 5.375 8.0625 5.375V7.375Z" fill={contextData.dark_mode ? '#fff' : '#003FFF'} />
            </svg>
          )}
          {props.element_type == "video" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M17.9733 10.9373C19.3397 11.6988 19.3447 12.6568 17.9733 13.5177L7.37627 20.6645C6.04478 21.3751 5.14046 20.9556 5.04553 19.418L5.00057 4.45982C4.97059 3.04355 6.13721 2.6434 7.24887 3.32244L17.9733 10.9373Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2"/>
            </svg>
          )}
          {props.element_type == "list" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M8.71961 6H21.5996M8.71961 12.48H21.5996M8.71961 18.96H21.5996M3.59961 6V6.0128M3.59961 12.48V12.4928M3.59961 18.96V18.9728" stroke={contextData.dark_mode ? '#fff' : "#003FFF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "icon" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M11.4956 2.71393C11.7021 2.29539 12.299 2.29539 12.5055 2.71393L15.1795 8.13206C15.2616 8.29826 15.4201 8.41346 15.6036 8.44012L21.5828 9.30895C22.0447 9.37607 22.2291 9.94369 21.8949 10.2695L17.5683 14.4869C17.4355 14.6163 17.375 14.8027 17.4063 14.9854L18.4277 20.9404C18.5066 21.4005 18.0237 21.7513 17.6106 21.5341L12.2626 18.7225C12.0986 18.6362 11.9026 18.6362 11.7385 18.7225L6.3905 21.5341C5.97737 21.7513 5.49453 21.4005 5.57343 20.9404L6.59481 14.9854C6.62614 14.8027 6.56557 14.6163 6.43285 14.4869L2.10622 10.2695C1.77199 9.94369 1.95642 9.37607 2.41832 9.30895L8.39757 8.44012C8.58099 8.41346 8.73955 8.29826 8.82158 8.13206L11.4956 2.71393Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "form" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
              <path d="M26 8.83333V4.16667C26 3.52233 25.3564 3 24.5625 3H4.4375C3.64359 3 3 3.52233 3 4.16667V8.83333C3 9.47767 3.64359 10 4.4375 10H24.5625C25.3564 10 26 9.47767 26 8.83333Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M26 19.8333V15.1667C26 14.5223 25.3564 14 24.5625 14H4.4375C3.64359 14 3 14.5223 3 15.1667V19.8333C3 20.4777 3.64359 21 4.4375 21H24.5625C25.3564 21 26 20.4777 26 19.8333Z" fill={contextData.dark_mode ? '#fff' : '#003FFF'}/>
              <path d="M8.03125 18.0833V16.9167M26 15.1667V19.8333C26 20.4777 25.3564 21 24.5625 21H4.4375C3.64359 21 3 20.4777 3 19.8333V15.1667C3 14.5223 3.64359 14 4.4375 14H24.5625C25.3564 14 26 14.5223 26 15.1667Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "quiz" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" viewBox="0 0 29 29" fill="none">
              <path d="M14.5 19.9374V19.9852M11.7824 11.1529C11.7824 9.62299 12.9997 8.38279 14.5012 8.38279C16.0027 8.38279 17.2199 9.62299 17.2199 11.1529C17.2199 12.6827 16.0027 13.9229 14.5012 13.9229C14.5012 13.9229 14.5 14.7498 14.5 15.7697M25.375 7.7031L25.375 21.2969C25.375 23.5492 23.5492 25.375 21.2969 25.375H7.70312C5.45084 25.375 3.625 23.5492 3.625 21.2969V7.7031C3.625 5.45083 5.45084 3.625 7.70312 3.625H21.2969C23.5492 3.625 25.375 5.45083 25.375 7.7031Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "survey" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" viewBox="0 0 29 29" fill="none">
              <path d="M9.6 18.4H15.6M9.6 13.6L19.2 13.6M9.6 8.8L12 8.8M4.8 6.4C4.8 5.07452 5.87452 4 7.2 4L21.6 4C22.9255 4 24 5.07452 24 6.4L24 20.8C24 22.1255 22.9255 23.2 21.6 23.2L7.2 23.2C5.87452 23.2 4.8 22.1255 4.8 20.8L4.8 6.4Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "button" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="24" viewBox="0 0 42 24" fill="none">
              <path d="M27.3352 21.3815H4.31474C3.03634 21.3815 2 20.3528 2 19.0838V5.29769C2 4.02871 3.03634 3 4.31474 3H35.8583C37.1367 3 38.1731 4.02871 38.1731 5.29769V9.4M35.6027 17.4821L39.564 16.1418C40.3065 15.8906 40.3313 14.8719 39.6019 14.5863L29.7807 10.7414C29.0975 10.474 28.414 11.1263 28.6702 11.8013L32.4115 21.6588C32.6849 22.3792 33.7255 22.3808 34.0012 21.6613L35.6027 17.4821Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "products" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="24" viewBox="0 0 54 24" fill="none">
              <rect x="1" y="1" width="14" height="22" rx="1" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2"/>
              <rect x="20" y="1" width="14" height="22" rx="1" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2"/>
              <rect x="39" y="1" width="14" height="22" rx="1" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2"/>
              <rect x="4" y="17" width="8" height="3" rx="1.5" fill={contextData.dark_mode ? '#fff' : '#003FFF'}/>
              <rect x="23" y="17" width="8" height="3" rx="1.5" fill={contextData.dark_mode ? '#fff' : '#003FFF'}/>
              <rect x="42" y="17" width="8" height="3" rx="1.5" fill={contextData.dark_mode ? '#fff' : '#003FFF'}/>
            </svg>
          )}
          {props.element_type == "product_hero" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="24" viewBox="0 0 43 24" fill="none">
              <rect x="1" y="1" width="14" height="22" rx="1" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2"/>
              <path d="M21.9992 2L41.1992 2M21.9992 7.2L36.1234 7.2M21.9992 12.4L41.1992 12.4" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="21" y="17" width="12" height="4" rx="2" fill={contextData.dark_mode ? '#fff' : '#003FFF'}/>
            </svg>
          )}
          {props.element_type == "row" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 3L19.0274 3C20.1168 3 21 3.88316 21 4.9726V19.0274C21 20.1168 20.1168 21 19.0274 21H12M12 3L4.9726 3C3.88316 3 3 3.88316 3 4.9726L3 19.0274C3 20.1168 3.88317 21 4.9726 21H12M12 3V21" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "collapse" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M2.40039 2.40002H21.6004M2.40039 21.6H21.6004M4.80039 16.2H19.2004C20.5259 16.2 21.6004 15.1255 21.6004 13.8V10.2C21.6004 8.87454 20.5259 7.80002 19.2004 7.80002H4.80039C3.47491 7.80002 2.40039 8.87454 2.40039 10.2V13.8C2.40039 15.1255 3.47491 16.2 4.80039 16.2Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "carousel" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24" fill="none">
              <path d="M5.40078 15.6L1.80078 12L5.40078 8.40002M32.6008 8.40002L36.2008 12L32.6008 15.6" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M25.0004 2.39996C26.9886 2.39996 28.6004 4.01174 28.6004 5.99996V18.0001C28.6004 19.9883 26.9886 21.6001 25.0004 21.6001H13.0004C11.0122 21.6001 9.40039 19.9883 9.40039 18.0001L9.40039 5.99996C9.40039 4.01174 11.0122 2.39996 13.0004 2.39996L25.0004 2.39996Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "tabs" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M2.40142 8.41667L2.40133 17.941C2.40131 19.0456 3.29675 19.941 4.40132 19.941L19.6001 19.941C20.7046 19.941 21.6 19.0457 21.6001 17.9411L21.6004 8.01244C21.6004 7.46015 21.1527 7.01241 20.6004 7.01241H12.0841L9.31905 4.05878H3.40077C2.84833 4.05878 2.40056 4.50599 2.40073 5.05842C2.40103 6.00924 2.40143 7.43615 2.40142 8.41667Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "timer" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15.5588 15.1987C16.0827 15.3733 16.649 15.0902 16.8237 14.5662C16.9983 14.0423 16.7152 13.476 16.1912 13.3013L15.5588 15.1987ZM12.5 13.125H11.5C11.5 13.5554 11.7754 13.9376 12.1838 14.0737L12.5 13.125ZM13.5 8.42087C13.5 7.86858 13.0523 7.42087 12.5 7.42087C11.9477 7.42087 11.5 7.86858 11.5 8.42087H13.5ZM16.1912 13.3013L12.8162 12.1763L12.1838 14.0737L15.5588 15.1987L16.1912 13.3013ZM13.5 13.125V8.42087H11.5V13.125H13.5ZM20.5 12C20.5 16.4183 16.9183 20 12.5 20V22C18.0228 22 22.5 17.5228 22.5 12H20.5ZM12.5 20C8.08172 20 4.5 16.4183 4.5 12H2.5C2.5 17.5228 6.97715 22 12.5 22V20ZM4.5 12C4.5 7.58172 8.08172 4 12.5 4V2C6.97715 2 2.5 6.47715 2.5 12H4.5ZM12.5 4C16.9183 4 20.5 7.58172 20.5 12H22.5C22.5 6.47715 18.0228 2 12.5 2V4Z" fill={contextData.dark_mode ? '#fff' : '#003FFF'}/>
            </svg>
          )}
          {props.element_type == "progress" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="24" viewBox="0 0 31 24" fill="none">
              <path d="M8 9.6V14.4M8 9.6H6.8M8 9.6H9.2M8 14.4H6.8M8 14.4H9.2M10.4 9.6V14.4M10.4 9.6H9.2M10.4 9.6H11.6M10.4 14.4H9.2M10.4 14.4H11.6M6.8 14.4H5.6V9.6H6.8M6.8 14.4V9.6M9.2 14.4V9.6M11.6 14.4H21.8V9.6H11.6M11.6 14.4V9.6M20.5996 14.4V9.59998M19.5996 14.4V9.59998M18.5996 14.4V9.59998M17.5996 14.4V9.59998M16.5996 14.4V9.59998M15.5996 14.4V9.59998M14.5996 14.4V9.59998M13.5996 14.4V9.59998M4.4 18H26.4C27.7255 18 28.8 16.9255 28.8 15.6V8.4C28.8 7.07452 27.7255 6 26.4 6H4.4C3.07452 6 2 7.07452 2 8.4V15.6C2 16.9255 3.07452 18 4.4 18Z" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "divider" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 21V18.0811C3 17.0064 3.88316 16.1351 4.9726 16.1351L19.0274 16.1351C20.1168 16.1351 21 17.0064 21 18.0811V21M3 3V5.91892C3 6.99363 3.88316 7.86486 4.9726 7.86486H19.0274C20.1168 7.86486 21 6.99363 21 5.91892V3M3 12.0001H3.06007M7.42492 12.0001H7.48498M11.97 12.0001H12.03M16.455 12.0001H16.515M20.9399 12.0001H21" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          {props.element_type == "html" && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.8004 17.7L13.2004 6.29999M6.00039 15.9L2.40039 12.3L6.00039 8.69999M18.0004 8.69999L21.6004 12.3L18.0004 15.9" stroke={contextData.dark_mode ? '#fff' : '#003FFF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
        </div>
        <div className="w-full mt-3 text-lg">{props.title}</div>
      </div>
    </div>
  );
};

export default ElementTypeItem;
