import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import htmlToLabel from "../../utils/htmlToLabel";

const VariantBuilderSettingModal = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    let modal = document.querySelector(
      '.modal[data-name="' +
        props.setting_name +
        '"][object_type="' +
        props.object_type +
        '"][object_id="' +
        props.object_id +
        '"]'
    );
    if (modalVisibility == true) {
      $(modal).modal("show");

      $(modal)
        .unbind("hidden.bs.modal")
        .bind("hidden.bs.modal", function (e) {
          setModalVisibility(false);
        });

      modal.onclick = function (e) {
        if (e.target == modal) {
          if (
            $(modal).is(":visible") &&
            $(modal).find(".modal:visible").length == 0
          ) {
            setModalVisibility(false);
          }
        }
      };
    } else {
      $(modal).modal("hide");
    }
  });

  return (
    <React.Fragment>
      <button
        className="btn btn-primary field-title btn-block btn-lg"
        style={{
          display: "block",
        }}
        ref={(node) => {
          if (node) {
            node.style.setProperty("color", "#fff", "important");
          }
        }}
        onClick={() => {
          setModalVisibility(true);
        }}
      >
        <span
          className={"glyphicon " + props.button_glyphicon}
          style={{
            marginRight: "10px",
          }}
          ref={(node) => {
            if (node) {
              node.style.setProperty("font-size", "12px", "important");
            }
          }}
          attr-hidden="true"
        ></span>
        {props.value}
      </button>
      <div
        className="modal"
        id={"modal-" + props.setting_name}
        data-name={props.setting_name}
        object_type={props.object_type}
        object_id={props.object_id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="false"
        style={{
          background: "rgb(0, 0, 0, 0.21)",
          overflowY: "auto",
        }}
      >
        <div
          className="modal-dialog !mt-44 !w-[690px]"
          role="document"
        >
          <div className="modal-content bg-white dark:bg-slate-950">
            <div className="modal-header border-slate-200 dark:border-slate-800">
              <button
                type="button"
                className="close dark:text-white"
                data-dismiss={"modal-" + props.setting_name}
                aria-label="Close"
                onClick={() => {
                  setModalVisibility(false);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M12.2996 4.1001L4.09961 12.3001M12.2996 12.3001L4.09961 4.1001" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              </button>
              <h4
                className="text-2xl semibold text-black dark:text-white"
              >
                {htmlToLabel(props.value)}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row hard-center">
                <div className="col-xs-12">{props.children}</div>
              </div>
            </div>
            <div className="modal-footer !border-slate-200 dark:!border-slate-800">
              <button
                type="button"
                className="btn bg-white border-solid border-[1px] rounded-md !border-slate-200 text-black text-xl hover:bg-blue-100 hover:!border-blue-100 hover:text-blue-600"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingModal;
