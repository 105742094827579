window.userEvent = function (event) {
  console.log(event);
  if (window.Intercom) Intercom("trackEvent", event);
  if (window.mixpanel) mixpanel.track(event);
};

window.loadIcon = function (message, delay) {
  if (delay == undefined) {
    delay = 1000;
  }
  $("#loading-icon").delay(delay).fadeIn();
  if (message.length > 0) {
    $("#loading-icon-text").html(message);
  }
};

document.addEventListener("turbolinks:load", function () {
  $("#loading-icon").hide();
  $('[data-toggle="tooltip"]').tooltip();

  if ($("body").attr("data-controller") == "registrations") {
    $("#signup-email").on("submit", function (e) {
      e.preventDefault();
      var email = $("#signup-email-input").val();
      $("#user_email").val(email);
      $("#user_email").parent().hide();
      $(".signup-box-step[data-question='email']").hide();
      $(".signup-box-step[data-question='signup']").show();
      $("#user_website")[0].focus();
    });

    function getParameterByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    if (getParameterByName("email") != "") {
      $("#user_email, #signup-email-input").val(getParameterByName("email"));
    }

    if (getParameterByName("first_name") != "") {
      $("#user_name").val(getParameterByName("first_name"));
    }

    if (getParameterByName("last_name") != "") {
      $("#user_lastname").val(getParameterByName("last_name"));
    }

    if ($("#error_explanation").length < 1) {
      $("#message").show();
    }

    function trackSignupInGa() {
      dataLayer.push({ event: "cf_create_account" });
      console.log("cf_create_account");
    }

    var signup_form = $("#user_signup_form");
    $(signup_form).on("submit", function (e) {
      trackSignupInGa();
      return true;
    });

    $("#google_signup").on("click", function (e) {
      trackSignupInGa();
    });

    $("#user_website").on("keyup", function (event) {
      var value = $(this).val();
      var value = value
        .replace("https://", "")
        .replace("http://", "")
        .replace(/\/$/, "")
        .replace(" ", "")
        .replace(",", "");
      $(this).val(value);
    });
  }

  if (
    $("body").attr("data-controller") == "users" &&
    $("body").attr("data-action") == "oauth_signup_website"
  ) {
    $("#website-select").on("submit", function (e) {
      e.preventDefault();

      var website_id = $(this).find("select").val();
      window.location.href =
        window.location.origin +
        "/websites/" +
        website_id +
        "/integrations/create_from_oauth_signup";
    });
  }

  if (
    $("body").attr("data-controller") == "users" &&
    $("body").attr("data-action") == "index"
  ) {
    $(".edit_user").each(function (i, user_form) {
      $(user_form).on("submit", function (e) {
        if (
          $("#user_password").val().length > 0 &&
          $("#user_password_confirmation").val().length > 0
        ) {
          e.preventDefault();

          if (
            $("#user_password").val() == $("#user_password_confirmation").val()
          ) {
            $(user_form)[0].submit();
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    });

    var user_id = getUrlParameter("user_id");
    if (user_id) {
      $("#user" + user_id).modal("show");
    }

    if (getUrlParameter("invite") == "true") {
      $("#new-user").modal("show");
    }
  }

  $("#affiliate-join").on("submit", function (e) {
    e.preventDefault();
    var form = $("#affiliate-join");
    $(form).find('input[type="submit"]').attr("value", "Submitting...");

    $.ajax({
      url: window.location.origin + "/users/affiliate",
      dataType: "json",
      method: "POST",
      data: {
        paypal: $(form).find('input[name="paypal"]').val(),
      },
      complete: function (data) {
        var response = data.responseJSON;
        if (response && response.auth_token) {
          var frame_source =
            "https://convertflow.firstpromoter.com/iframe?at=" +
            response.auth_token;
          var modal = $("#affiliate-modal");
          $(modal).find(".modal-dialog").css("width", "1200px");
          $(modal).find(".modal-header").show();
          $(modal)
            .find(".affiliate-dashboard-container")
            .html(
              '<div id="affiliate-dashboard" class="row hard-center some-vertical-space" style="max-width: 100%;">' +
                '<iframe height="1200px" width="100%" frameborder="0" src="' +
                frame_source +
                '"></iframe>' +
                "</div>"
            );
        }
      },
    });
  });

  $("#affiliate-modal").on("shown.bs.modal", function () {
    var dashboard_div = $("#affiliate-dashboard");

    if ($(dashboard_div).find("iframe").length == 0) {
      $(dashboard_div).html(
        '<iframe height="1200px" width="100%" frameborder="0" src="' +
          $(dashboard_div).attr("data-iframe-src") +
          '"></iframe>'
      );
    }
  });

  if (window.innerWidth < 761) {
    $("#mobileWarning").modal("show");
  }

  const modalDiv = $("#upgrade");
  if (!$(modalDiv)[0]) return null;

  window.dataLayer = window.dataLayer || [];
  const currentPlanType = $(modalDiv).attr("data-plan-type");
  const isTrialing = $(modalDiv).attr("data-trial") == "true";
  const activeCustomer = currentPlanType && currentPlanType !== "free";

  dataLayer.push({ event: "logged_in" });

  if (isTrialing) {
    if (currentPlanType.indexOf("pro") > -1) {
      dataLayer.push({ event: "used_pro_trial" });
    }

    if (currentPlanType.indexOf("team") > -1) {
      dataLayer.push({ event: "used_teams_trial" });
    }

    if (currentPlanType.indexOf("business") > -1) {
      dataLayer.push({ event: "used_business_trial" });
    }
  }

  if (activeCustomer) {
    dataLayer.push({ event: "logged_in_customer" });
  }
});
