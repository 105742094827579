const updateAOV = (
  ordersSelector,
  revenueSelector,
  aovSelector
) => {
  let aov_check = setInterval(function () {
    const ordersSpan = $(ordersSelector);
    const revenueSpan = $(revenueSelector);

    if (ordersSpan.attr("data-loading") == "false" && revenueSpan.attr("data-loading") == "false") {
      clearInterval(aov_check);

      const orders = parseInt($(ordersSpan).text().replaceAll(",", ""));
      const revenue = parseFloat($(revenueSpan).text().replaceAll(",", ""));

      let aov = orders && revenue ? revenue / orders : 0;
      $(aovSelector).text(aov.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }
  }, 500);
};

export default updateAOV;
