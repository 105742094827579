import React, { useState, useEffect, useRef } from "react";
import ReportCount from "../reports/ReportCount";
import updateConversionRate from "../../utils/updateConversionRate";
import updateAOV from "../../utils/updateAOV";
import FieldOptionValueReport from "../field_options/FieldOptionValueReport";
import htmlToLabel from "../../utils/htmlToLabel";

const FieldReport = (props) => {
  const element = props.element;
  const field = props.field;
  const parentField = element.fields.filter(
    (parentField) => parentField.id == field.field_id
  )[0];

  const canShowFieldValues =
    [
      "survey",
      "image",
      "color",
      "emoji",
      "icon",
      "letter",
      "dropdown",
      "select",
      "radio",
      "checkbox",
    ].includes(field.field_type) ||
    (!field.field_type && element.element_type == "quiz") ||
    (field.field_type == "range" &&
      field.options["range-type"] == "increments");

  const [showFieldValues, setShowFieldValues] = useState(false);

  const calculateSelectionRates = (element) => {
    const conversionsSpan = document.getElementById("field-report-" + field.id);
    const conversions = parseInt($(conversionsSpan).text().replaceAll(",", ""));

    $(`.element-selection-report[data-field-id="${field.id}"]`).each(
      (i, elementSelectionReportDiv) => {
        const selectionsSpan = $(elementSelectionReportDiv).find(
          ".events-report-count"
        )[0];
        const conversionRateElement = $(elementSelectionReportDiv).find(
          ".conversion-rate"
        )[0];
        const conversionBarElement = $(elementSelectionReportDiv).find(
          ".element-selection-report-bar"
        )[0];

        const selections = parseInt(
          $(selectionsSpan).text().replaceAll(",", "")
        );
        let conversionRateValue =
          selections > 0 && conversions > 0
            ? (selections / conversions) * 100
            : 0;

        if (conversionRateValue > 100) {
          conversionRateValue = 100.0;
        }

        $(conversionRateElement).text(conversionRateValue.toFixed(2));

        if (conversionRateValue > 0) {
          $(conversionBarElement)
            .css("width", `${conversionRateValue}%`)
            .attr("aria-valuenow", conversionRateValue)
            .show();
        }

        $(elementSelectionReportDiv).css("order", selections);
      }
    );
  };

  return (
    <React.Fragment>
      <div
        className="row hard-center element-selection-report"
        data-element-id={element.id}
        style={{ width: "100%" }}
      >
        <div
          className="element-selection-report-bar"
          style={{ width: "100%" }}
        ></div>
        <div className="row hard-center">
          <div className="col-xs-4">
            <div
              className="text-xl ellipsis field-report-toggle"
              data-field-id={field.id}
              style={{
                marginBottom: "5px",
                cursor: canShowFieldValues ? "pointer" : undefined,
              }}
              onClick={() => {
                if (canShowFieldValues) {
                  setShowFieldValues(!showFieldValues);
                }
              }}
            >
              {element.element_type == "quiz"
                ? !parentField
                  ? `${field.position} - `
                  : `${parentField.position}.${field.position} - `
                : ""}
              {field.label ? htmlToLabel(field.label) : field.slug}{" "}
              {canShowFieldValues && (
                <span
                  className={`glyphicon glyphicon-chevron-${
                    showFieldValues ? "down" : "right"
                  } element-selection-report-toggle`}
                ></span>
              )}
            </div>
          </div>
          <div className="col-xs-8 right">
            <p className="light text-xl" style={{ marginBottom: "10px" }}>
              <strong>
                <ReportCount
                  inline={true}
                  id={`field-report-${field.id}`}
                  path={`/websites/${props.cta.website_id}/events_reports`}
                  payload={{
                    report_type: "distinct_count",
                    report: {
                      website_id: props.cta.website_id,
                      event_type: ["Complete", "Selection"],
                      contactable_type: "Cta",
                      contactable_id: props.cta.id,
                      variant_id: props.step.variant_id,
                      step_id: props.step.id,
                      element_id: element.id,
                      product_id: null,
                      product_variant_id: null,
                      field: field.slug,
                      range_start: props.range_start,
                      range_end: props.range_end,
                      broadcast_id: null,
                    },
                  }}
                  callback={() => {
                    props.calculationConversionRates();
                  }}
                />
              </strong>{" "}
              responded (<span className="conversion-rate">0.00</span>%)
              {!props.gateReports && (
                <React.Fragment>
                  {" - "}
                  <strong>
                    <ReportCount
                      id={`field-${field.id}-orders`}
                      inline={true}
                      path={`/websites/${props.cta.website_id}/events_reports`}
                      payload={{
                        report_type: "count",
                        report: {
                          website_id: props.cta.website_id,
                          event_type: "Order",
                          contactable_type: "Cta",
                          contactable_id: props.cta.id,
                          variant_id: props.step.variant_id,
                          step_id: props.step.id,
                          element_id: element.id,
                          range_start: props.range_start,
                          range_end: props.range_end,
                          field: field.slug,
                          value: null,
                          product_id: null,
                          product_variant_id: null,
                          broadcast_id: null,
                        },
                      }}
                      callback={() => {
                        updateAOV(
                          `#field-${field.id}-orders`,
                          `#field-${field.id}-revenue`,
                          `#field-${field.id}-aov`
                        );
                        updateConversionRate(
                          `#field-report-${field.id}`,
                          `#field-${field.id}-orders`,
                          `#field-${field.id}-order-rate`
                        );
                      }}
                    />
                  </strong>{" "}
                  orders (<span id={`field-${field.id}-order-rate`}>0.00</span>)
                  {" - "}
                  <strong>
                    <ReportCount
                      id={`field-${field.id}-revenue`}
                      inline={true}
                      path={`/websites/${props.cta.website_id}/events_reports`}
                      payload={{
                        report_type: "revenue",
                        report: {
                          website_id: props.cta.website_id,
                          range_start: props.range_start,
                          range_end: props.range_end,
                          event_type: "Order",
                          contactable_type: "Cta",
                          contactable_id: props.cta.id,
                          variant_id: props.step.variant_id,
                          step_id: props.step.id,
                          element_id: element.id,
                          field: field.slug,
                          value: null,
                          product_id: null,
                          product_variant_id: null,
                          broadcast_id: null,
                        },
                      }}
                      callback={() => {
                        updateAOV(
                          `#field-${field.id}-orders`,
                          `#field-${field.id}-revenue`,
                          `#field-${field.id}-aov`
                        );
                      }}
                      currency={props.currency}
                    />
                  </strong>{" "}
                  revenue
                  {" - "}
                  <strong>
                    {props.currency}
                    <span id={`field-${field.id}-aov`}>0.00</span>
                  </strong>{" "}
                  AOV
                </React.Fragment>
              )}
            </p>
          </div>
        </div>
        {canShowFieldValues && showFieldValues && (
          <div
            className="row hard-center"
            style={{
              display: "flex",
              width: "100%",
              flexBasis: "100%",
              flexWrap: "wrap",
              flexDirection: "column-reverse",
            }}
          >
            {field.field_options
              .sort((field_option) => {
                field_option.position;
              })
              .map((field_option) => {
                return (
                  <FieldOptionValueReport
                    key={`field-option-report-${field_option.id}`}
                    id={`field-option-report-${field_option.id}`}
                    field={field}
                    field_option={field_option}
                    calculateSelectionRates={() => {
                      calculateSelectionRates();
                    }}
                    {...props}
                  />
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FieldReport;
