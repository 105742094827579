import React from "react";
import PropTypes from "prop-types";
import CtaCampaignToggle from "../ctas/CtaCampaignToggle";

const CtasListItemStatus = (props) => {
  const websiteLink = "/websites/" + props.cta.website_id;
  const ctaLink = websiteLink + "/cta/" + props.cta.id;
  const launchLink = ctaLink + "/launch";
  const broadcast = props.cta.broadcasts && props.cta.broadcasts.filter(
    (broadcast) => broadcast.quick == false && broadcast.live == true
  )[0];

  const ctaCampaign = props.cta.broadcasts && props.cta.broadcasts.filter(
    (broadcast) => broadcast.quick == true
  )[0];
  return (
    <React.Fragment>
      {["hook", "overlay", "bar"].includes(props.cta.cta_type) &&
        !broadcast &&
        !ctaCampaign && (
          <a href={launchLink}>
            <span className="campaign-status">Inactive</span>
          </a>
        )}

      {["hook", "overlay", "bar", "inline"].includes(props.cta.cta_type) &&
        !broadcast &&
        ctaCampaign &&
        (props.cta.cta_type !== "inline" || ctaCampaign.live == true) && (
          <CtaCampaignToggle broadcast={ctaCampaign} labelLocation="left" {...props} />
        )}

      {props.cta.cta_type == "inline" &&
        !broadcast &&
        (!ctaCampaign || ctaCampaign.live == false) && (
          <a href={launchLink}>
            <span className="campaign-status">Embed</span>
          </a>
        )}

      {props.cta.cta_type == "page" && !broadcast && (
        <a href={launchLink}>
          <span
            className="campaign-status ellipsis"
            data-toggle="tooltip"
            data-placement="top"
            title={
              props.cta.domain
                ? props.cta.domain.sub_domain +
                  "." +
                  props.cta.domain.root_domain +
                  "/" +
                  props.cta.page_path
                : "convertflowpages.com/" + props.cta.page_path
            }
            style={{
              width: props.list_type == "row" ? "150px" : "100px",
            }}
          >
            /{props.cta.page_path}
          </span>
        </a>
      )}
      
      {broadcast && (
        <a href={websiteLink + "/broadcasts/" + broadcast.id + "/edit"}>
          <span
            className="campaign-status"
          >
            In Broadcast
          </span>
        </a>
      )}
    </React.Fragment>
  );
};

export default CtasListItemStatus;
