import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import ProductVariantSettings from "../product_variants/ProductVariantSettings";
import NewProductVariant from "../product_variants/NewProductVariant";
import useProductVariants from "../product_variants/useProductVariants";
import Tooltip from "../shared/Tooltip";

const ProductSettings = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { ProductVariants } = useProductVariants();
  const website = contextData.objects.website;
  const product = props.product;
  const productVariants = Object.values(
    contextData.objects.variant.product_variants
  )
    .filter(
      (productVariant) =>
        productVariant.toBeDeleted !== true &&
        productVariant.product_id == product.id
    )
    .sort((a, b) => {
      return a.position - b.position;
    });

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={product}
        setting_name="[modal_trigger]"
        setting_type="item_v2"
        object_type="products"
        object_id={product.id}
        title={
          product.title
            ? product.title
            : product.position
            ? `Product ${product.position}`
            : `Product ${product.id}`
        }
        label_icon={"fas fa-tshirt"}
        label_max_length={30}
        label_hover_max_length={18}
        style={{
          margin: "0px 0px 5px 0px",
          ...props.style,
        }}
        panel_type="modal"
        position={product.position ? product.position : "1"}
        warning={
          !product.title || !product.price || !product.redirect_url
            ? "Required info missing"
            : ""
        }
        warningBorder={false}
        duplicate_payload={{
          element_id: product.element_id,
          product_recommendation_id: product.product_recommendation_id
        }}
        callback={() => {
          ProductVariants.sortableStart(product);
        }}
        callbackOnRemove={props.callbackOnRemove}
        callbackOnDuplicate={props.callbackOnDuplicate}
      >
        <VariantBuilderSetting
          object={product}
          setting_name="[title]"
          setting_type="text"
          label="Title"
          object_type="products"
          object_id={product.id}
          value={product.title ? product.title : undefined}
          warning={!product.title ? "Product title required" : ""}
        />

        <VariantBuilderSetting
          object={product}
          setting_name="[price]"
          setting_type="number"
          label="Price"
          object_type="products"
          object_id={product.id}
          value={product.price}
          min={0}
          warning={!product.price ? "Product price required" : ""}
          col="6"
        />

        <VariantBuilderSetting
          object={product}
          setting_name="[discounted_price]"
          setting_type="number"
          label="Sale Price"
          object_type="products"
          object_id={product.id}
          value={product.discounted_price}
          min={0}
          col="6"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={product}
          setting_name="[currency-message]"
          setting_type="html"
          object_type="products"
          object_id={product.id}
        >
          <div className="row hard-center" style={{ marginTop: "-15px" }}>
            <div
              className="text-lg text-black dark:text-white"
            >
              <strong>Currency:</strong> {website.currency_character}
              <a
                style={{ float: "right" }}
                href={`/websites/${website.id}/edit`}
                target="_blank"
              >
                Edit →
              </a>
            </div>
          </div>
        </VariantBuilderSetting>

        <VariantBuilderSetting
          object={product}
          setting_name="[image]"
          padding="0px"
          setting_type="image"
          label="Image"
          object_type="products"
          object_id={product.id}
          value={product.image ? product.image : null}
        />

        <VariantBuilderSetting
          object={product}
          setting_name="[redirect_url]"
          setting_type="text"
          label="Product Link URL"
          object_type="products"
          object_id={product.id}
          value={product.redirect_url ? product.redirect_url : null}
          placeholder="https://"
          warning={!product.redirect_url ? "Product URL required" : ""}
        />

        <VariantBuilderSetting
          object={product}
          setting_name="[description]"
          setting_type="editor"
          label="Description"
          object_type="products"
          object_id={product.id}
          value={product.description ? product.description : undefined}
          preview_selector={`.cf-products-item[data-product-id="${product.id}"] .cf-products-item-description`}
        />

        <div className="col-xs-12">
          <div className="w-full mt-5 relative text-black dark:text-white">
            <span className="text-xl">Product Variants:</span>

            <Tooltip
              tooltip={`Add product variants to this product to allow shoppers to select different variations.`}
            />
          </div>
        </div>
        <div className="col-xs-12">
          <div
            className="col-xs-12 sortable-container"
            data-sortable-name="product-variants"
            data-object_type="products"
            data-object_id={product.id}
            style={{
              padding: "0",
            }}
          >
            {productVariants.map((productVariant, i) => {
              return (
                <React.Fragment key={"product-variant-" + productVariant.id}>
                  <ProductVariantSettings
                    product={product}
                    product_variant={productVariant}
                    {...props}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <NewProductVariant product={product} />
      </VariantBuilderSetting>
    </React.Fragment>
  );
};

export default ProductSettings;
