import React, { useEffect, useState } from "react";
import CFSelect from "../shared/forms/CFSelect";

const ConditionSettingsInput = (props) => {
  const [selectSettingValue, setSelectSettingValue] = useState(undefined);

  const setting = props.setting;
  const integration = props.integration;
  const website = props.website;

  let setting_value;
  let value_paths = setting.attribute
    ? setting.attribute
        .replace("][", ".")
        .replace("[", "")
        .replace("]", "")
        .split(".")
    : [];

  value_paths.forEach((path) => {
    if (setting_value) {
      setting_value = setting_value[path];
    } else if (props.condition && integration) {
      setting_value = props.condition["data"] && props.condition["data"][path];
    } else if (props.condition) {
      setting_value = props.condition[path];
    }
  });

  const updateInputValue = () => {
    if (setting.input_type == "select") {
      let selectOption = settingSelectOptions().find(
        (option) => option.value == setting_value
      );

      if (selectOption || setting_value) {
        setSelectSettingValue(
          selectOption || {
            label: setting_value,
            value: setting_value,
          }
        );
      }
    }

    if (setting.input_type !== "select") {
      let parsedSetting = setting.attribute.replace('[', '-').replace(']', '');
      let inputDiv = document.querySelector(`#condition-${props.condition?.id}-${parsedSetting}`);

      if (inputDiv && setting_value) inputDiv.value = setting_value;
    }
  }

  useEffect(() => {
    updateInputValue();
  }, [props.condition, setting_value]);

  const changeCallback = (e) => {
    if (setting.input_type == "select") {
      setSelectSettingValue({ label: e.label, value: e.value });
    } else {
      setSelectSettingValue(e.target.value);
    }

    if (props.onChange) {
      props.onChange(e);
    }

    if (props.onDone && setting.input_type == "select") {
      props.onDone(e);
    }

    if (setting.callback) {
      setting.callback(e);
    }
  };

  const settingSelectOptions = () => {
    let selectOptions = [
      {
        value: "",
        label: setting.placeholder,
        isDisabled: true,
      },
    ];

    {
      integration &&
        integration.api_data[setting.dropdown] &&
        Object.values(integration.api_data[setting.dropdown]).forEach(
          (option) => {
            selectOptions.push({
              value: setting.dropdown_id_key ? option[setting.dropdown_id_key] : option.id,
              label: option.name,
            });
          }
        );
    }

    {
      !integration &&
        setting.dropdown == "fields" &&
        Object.values(website.fields)
          .filter((f) => f.service == "ConvertFlow")
          .forEach((field) => {
            if (selectOptions.filter((o) => o.value == field.slug).length == 0) {
              selectOptions.push({
                value: field.slug,
                label: field.slug,
              });
            }
          });
    }

    {
      !integration &&
        setting.dropdown == "tags" &&
        Object.values(website.tags).forEach((tag) => {
          selectOptions.push({
            value: tag.id,
            label: tag.name,
          });
        });
    }

    {
      !integration &&
        setting.options &&
        setting.options
          .filter((option) => {
            return (
              option &&
              option.text &&
              (!option.parent || option.parent == props.parent_value?.value)
            );
          })
          .sort(function (a, b) {
            return a.text.localeCompare(b.text);
          })
          .forEach((dropdown_option, i) => {
            var custom_attributes = {};

            dropdown_option.custom_attributes &&
              dropdown_option.custom_attributes.forEach((custom_attribute) => {
                custom_attributes[custom_attribute.name] =
                  custom_attribute.value;
              });

            selectOptions.push({
              value: dropdown_option.value,
              label: dropdown_option.text,
              ...custom_attributes,
            });
          });
    }

    return selectOptions;
  };

  return (
    <React.Fragment>
      <div
        className={`flex-grow min-w-[100px] ${setting.note ? "pb-[10px]" : ""}`}
        style={{
          display: setting.input_type == "hidden" ? "none" : "",
        }}
      >
        {setting.input_type !== "select" && (
          <input
            id={`condition-${props.condition?.id}-${setting?.attribute?.replace('[', '-')?.replace(']', '')}`}
            className="w-full p-3 bg-white dark:bg-slate-950 text-xl text-black dark:text-white rounded-xl border-solid border-[1px] border-slate-200 dark:border-slate-700 focus:border-slate-200 outline-none focus:outline-none focus:ring-0 appearance-none"
            placeholder={setting.placeholder}
            type={setting.input_type}
            name={
              integration
                ? `condition[data][${setting.attribute}]`
                : `condition${setting.attribute}`
            }
            required="required"
            min={setting.min}
            defaultValue={setting_value || setting.value}
            data-input-type={setting.input_type}
            onChange={changeCallback}    
            onBlur={props.onDone}
          ></input>
        )}

        {setting.input_type == "select" && (
          <React.Fragment>
            <CFSelect
              isSearchable={true}
              name={
                integration
                  ? `condition[data][${setting.attribute}]`
                  : `condition${setting.attribute}`
              }
              options={settingSelectOptions()}
              value={selectSettingValue}
              onChange={changeCallback}
            />

            <input
              name={
                integration
                  ? `condition[data][${setting.attribute}]`
                  : `condition${setting.attribute}`
              }
              tabIndex={-1}
              autoComplete="off"
              style={{ display: 'none' }}
              value={selectSettingValue?.value}
              required="required"
            />
          </React.Fragment>
        )}
      </div>

      {setting.input_type == "select" && setting.child_setting && (
        <ConditionSettingsInput
          condition={props.condition}
          integration={props.integration}
          setting={setting.child_setting}
          parent_value={selectSettingValue}
          onChange={(e) => {
            let newFormData = props.formData;
            _.set(
              newFormData,
              `${
                !integration
                  ? setting.child_setting.attribute
                  : `[data][${setting.child_setting.attribute}]`
              }`,
              e.value || e.target.value
            );
            props.setFormData(newFormData);

            if (
              props.condition &&
              !props.builder &&
              setting.child_setting.input_type == "select"
            ) {
              props.handleSubmit(e, newFormData);
            }
          }}
          onDone={(e) => {
            if (props.condition) {
              if (props.builder) {
                const value = e.value || e.target.value;
                dispatchCustomEvent("updateBuilder", {
                  updates: [
                    {
                      object_type: "conditions",
                      object_id: props.condition.id,
                      setting_name: setting.child_setting.attribute,
                      value: value,
                    },
                  ],
                });
              } else if (setting.child_setting.input_type !== "select") {
                props.handleSubmit(e);
              }
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ConditionSettingsInput;
