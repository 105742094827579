import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "./VariantBuilderContext";
import VariantBuilderSetting from "./VariantBuilderSetting";
import VariantBuilderPanelCollapse from "./VariantBuilderPanelCollapse";

const VariantSettingsThemeButtons = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Buttons" open={false}>
        <VariantBuilderSetting
          object={variant}
          label="Button Font"
          setting_name="[options][button-font]"
          setting_type="select"
          object_type="variants"
          value={
            variant.options["button-font"]
              ? variant.options["button-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          skip_history={true}
          callback={function (new_value, old_value) {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][button-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="variants"
          value={
            variant.options["button-font-weight"]
              ? variant.options["button-font-weight"]
              : undefined
          }
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Button font size"
          setting_name="[options][button-font-size]"
          setting_type="range"
          object_type="variants"
          value={
            variant.options["button-font-size"]
              ? variant.options["button-font-size"]
              : 16
          }
          placeholder="16"
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Button Font size"
          setting_name="[options][button-font-size-mobile]"
          setting_type="range"
          object_type="variants"
          object_id={variant.id}
          value={
            variant.options["button-font-size-mobile"]
              ? variant.options["button-font-size-mobile"]
              : undefined
          }
          placeholder={variant.options["button-font-size"] ? variant.options["button-font-size"] : 16}
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Button color"
            setting_name="[options][button-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["button-color"]
                ? variant.options["button-color"]
                : undefined
            }
            placeholder="#0036da"
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][button–hover-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["button–hover-color"]
                ? variant.options["button–hover-color"]
                : undefined
            }
            placeholder={variant.options["button-color"] ? variant.options["button-color"] : "#0036da"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Text color"
            setting_name="[options][button-text-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["button-text-color"]
                ? variant.options["button-text-color"]
                : undefined
            }
            placeholder="#fff"
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][button–hover-text-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["button–hover-text-color"]
                ? variant.options["button–hover-text-color"]
                : undefined
            }
            placeholder={variant.options["button-text-color"]
            ? variant.options["button-text-color"]
            : '#fff'}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Button Border Style"
          setting_name="[options][button-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["button-border-style"]
              ? variant.options["button-border-style"]
              : "solid"
          }
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Border color"
            setting_name="[options][button-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["button-border-color"]
                ? variant.options["button-border-color"]
                : undefined
            }
            condition={
              variant.options["button-border-style"] &&
              variant.options["button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][button-hover-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["button-hover-border-color"]
                ? variant.options["button-hover-border-color"]
                : undefined
            }
            placeholder={variant.options["button-border-color"]}
            col="6"
            classNames="some-padding-left"
            condition={
              variant.options["button-border-style"] &&
              variant.options["button-border-style"] !== "none"
            }
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div
          className="col-xs-12 some-vertical-space setting"
          style={{ marginBottom: "5px" }}
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="mb-[5px] text-xl text-black dark:text-white">Button Border Width</div>

              {["left", "top", "bottom", "right"].map((side) => {
                const settingName = `[options][button-border-width-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={variant}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="variants"
                    value={
                      _.isNil(variant.options[`button-border-width-${side}`]) ==
                      false
                        ? variant.options[`button-border-width-${side}`]
                        : variant.options["button-border-width"]
                        ? variant.options["button-border-width"].replace(
                            "px",
                            ""
                          )
                        : "2"
                    }
                    placeholder="0px"
                    col="3"
                    note={window.capitalizeFirstLetter(side)}
                    classNames="spacing-inputs"
                    min={0}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Button Corner Radius"
          setting_name="[options][button-radius]"
          setting_type="border_radius"
          object_type="variants"
          value={
            variant.options["button-radius"]
              ? variant.options["button-radius"]
              : "cf-slightly-rounded"
          }
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(variant.options[`button-radius-${side}`]) == false
                      ? variant.options[`button-radius-${side}`]
                      : "3"
                  }
                  condition={variant.options["button-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Button Shadow"
          setting_name="[options][button-shadow]"
          setting_type="shadow"
          object_type="variants"
          value={
            variant.options["button-shadow"]
              ? variant.options["button-shadow"]
              : "None"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][button-shadow-horizontal-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Horizontal Distance"}
          value={
            variant.options["button-shadow-horizontal-distance"]
              ? variant.options["button-shadow-horizontal-distance"]
              : "0"
          }
          condition={variant.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][button-shadow-vertical-distance]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Vertical Distance"}
          value={
            variant.options["button-shadow-vertical-distance"]
              ? variant.options["button-shadow-vertical-distance"]
              : "0"
          }
          condition={variant.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][button-shadow-blur-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["button-shadow-blur-radius"]
              ? variant.options["button-shadow-blur-radius"]
              : "0"
          }
          condition={variant.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][button-shadow-spread-radius]"
          setting_type="range"
          object_type="variants"
          label={"Shadow Blur Radius"}
          value={
            variant.options["button-shadow-spread-radius"]
              ? variant.options["button-shadow-spread-radius"]
              : "0"
          }
          condition={variant.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
        />

        <VariantBuilderSetting
          object={variant}
          label="Shadow Color"
          setting_name="[options][button-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["button-shadow-color"]
              ? variant.options["button-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={variant.options["button-shadow"] == "custom"}
          col="6"
          classNames="some-padding-right"
        />

        <VariantBuilderSetting
          object={variant}
          label="Hover"
          setting_name="[options][button-hover-shadow-color]"
          setting_type="color_v2"
          object_type="variants"
          value={
            variant.options["button-hover-shadow-color"]
              ? variant.options["button-hover-shadow-color"]
              : variant.options["button-shadow-color"]
              ? variant.options["button-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={variant.options["button-shadow"] == "custom"}
          col="6"
          classNames="some-padding-left"
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          label="Inset shadow position"
          setting_name="[options][button-shadow-inset]"
          setting_type="checkbox"
          object_type="variants"
          value={
            variant.options["button-shadow-inset"]
              ? variant.options["button-shadow-inset"]
              : "false"
          }
          condition={variant.options["button-shadow"] == "custom"}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Back But. Text"
            setting_name="[options][quiz-back-button-text-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-back-button-text-color"]
                ? variant.options["quiz-back-button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-button-text-color"] ||
              variant.options["button-text-color"] ||
              "#fff"
            }
            col="6"
            classNames="some-padding-right"
            condition={variant.options["hide-back-button"] !== "true"}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-back-button–hover-text-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-back-button–hover-text-color"]
                ? variant.options["quiz-back-button–hover-text-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-button–hover-text-color"] ||
              variant.options["button–hover-text-color"] ||
              variant.options["button-text-color"]
            }
            col="6"
            condition={variant.options["hide-back-button"] !== "true"}
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Back But. color"
            setting_name="[options][quiz-back-button-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-back-button-color"]
                ? variant.options["quiz-back-button-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-button-color"] ||
              variant.options["button-color"] ||
              "#0036da"
            }
            condition={variant.options["hide-back-button"] !== "true"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-back-button–hover-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-back-button–hover-color"]
                ? variant.options["quiz-back-button–hover-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-button–hover-color"] ||
              variant.options["button–hover-color"] ||
              variant.options["button-color"]
            }
            condition={variant.options["hide-back-button"] !== "true"}
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            gradient={true}
            offset_left={true}
          />
        </div>

        <VariantBuilderSetting
          object={variant}
          label="Back Button Border Style"
          setting_name="[options][quiz-back-button-border-style]"
          setting_type="border_style"
          object_type="variants"
          value={
            variant.options["quiz-back-button-border-style"]
              ? variant.options["quiz-back-button-border-style"]
              : 'solid'
          }
          condition={variant.options["hide-back-button"] !== "true"}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={variant}
            label="Back Bord. Color"
            setting_name="[options][quiz-back-button-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-back-button-border-color"]
                ? variant.options["quiz-back-button-border-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-back-button-color"] || 
              variant.options["quiz-button-border-color"] ||
              variant.options["button-border-color"]
            }
            condition={
              variant.options["quiz-back-button-border-style"] &&
              variant.options["quiz-back-button-border-style"] !== "" &&
              variant.options["quiz-back-button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={variant}
            label="Hover"
            setting_name="[options][quiz-back-button-hover-border-color]"
            setting_type="color_v2"
            object_type="variants"
            value={
              variant.options["quiz-back-button-hover-border-color"]
                ? variant.options["quiz-back-button-hover-border-color"]
                : undefined
            }
            placeholder={
              variant.options["quiz-button-hover-border-color"] ||
              variant.options["button-hover-border-color"]
            }
            condition={
              variant.options["quiz-back-button-border-style"] &&
              variant.options["quiz-back-button-border-style"] !== "" &&
              variant.options["quiz-back-button-border-style"] !== "none" &&
              variant.options["hide-back-button"] !== "true"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {variant.options["quiz-back-button-border-style"] &&
          variant.options["quiz-back-button-border-style"] !== "none" &&
          variant.options["hide-back-button"] !== "true" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="mb-[5px] text-xl text-black dark:text-white">Back Button Border Width</div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][quiz-back-button-border-width-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={variant}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="variants"
                        value={
                          _.isNil(
                            variant.options[
                              `quiz-back-button-border-width-${side}`
                            ]
                          ) == false
                            ? variant.options[
                                `quiz-back-button-border-width-${side}`
                              ]
                            : variant.options["quiz-back-button-border-width"]
                            ? variant.options[
                                "quiz-button-border-width"
                              ].replace("px", "")
                            : undefined
                        }
                        placeholder={
                          variant.options["quiz-button-border-style"] || "1px"
                        }
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                        allow_empty={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={variant}
          label="Back Button Corner Radius"
          setting_name="[options][quiz-back-button-radius]"
          setting_type="border_radius"
          valueMappings={{
            none: 'cf-not-rounded',
            some: 'cf-slightly-rounded',
            full: 'cf-fully-rounded',
            custom: 'custom',
          }}
          object_type="variants"
          value={
            variant.options["quiz-back-button-radius"]
              ? variant.options["quiz-back-button-radius"]
              : 'cf-slightly-rounded'
          }
          condition={variant.options["hide-back-button"] !== "true"}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][quiz-back-button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={variant}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="variants"
                  value={
                    _.isNil(
                      variant.options[`quiz-back-button-radius-${side}`]
                    ) == false
                      ? variant.options[`quiz-back-button-radius-${side}`]
                      : undefined
                  }
                  condition={
                    variant.options["quiz-back-button-radius"] == "custom" &&
                    variant.options["hide-back-button"] !== "true"
                  }
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeButtons;
