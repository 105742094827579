import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import NewContentListItem from "../content_lists/NewContentListItem";
import ListSettings from "../content_lists/ListSettings";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";

const ElementSettingsList = (props) => {
  const [contextData, builder] = useContext(VariantContextData);

  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  let sorted_list_items = Object.values(variant.content_list_items)
    .filter(
      (list_item) =>
        list_item.element_id == element.id && list_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <React.Fragment>

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        setting_name="[options][list_visual_type]"
        label="Default Icon Type"
        setting_type="dropdown"
        options={[
          { value: "bullet", text: "Bullet" },
          { value: "icon", text: "Icon" },
          { value: "emoji", text: "Emoji" },
          { value: "image", text: "Image" },
          { value: "letter", text: "Letter" },
          { value: "numbered", text: "Numbered" },
          { value: "empty", text: "Empty" },
        ]}
        object_type="elements"
        value={
          element.options["list_visual_type"]
            ? element.options["list_visual_type"]
            : ""
        }
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        label="Default Icon"
        setting_name="[options][list_icon]"
        setting_type="icon"
        object_type="elements"
        default_value="fas fa-check"
        value={
          element.options["list_icon"] ? element.options["list_icon"] : null
        }
        condition={element.options["list_visual_type"] === "icon"}
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        label="Default Emoji"
        setting_name="[options][list_emoji]"
        setting_type="emoji"
        object_type="elements"
        value={
          element.options["list_emoji"] ? element.options["list_emoji"] : null
        }
        condition={element.options["list_visual_type"] === "emoji"}
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        label="Default Icon Image"
        setting_name="[options][list_image]"
        setting_type="image"
        object_type="elements"
        value={
          element.options["list_image"] ? element.options["list_image"] : ""
        }
        condition={element.options["list_visual_type"] === "image"}
        show_reset={true}
      />

      <div className="col-xs-12 mt-5">
        <div className="w-full relative text-black dark:text-white">
          <span className="font-semibold text-xl">List Items:</span>
        </div>
      </div>
      <div
        className="sortable-container col-xs-12"
        data-sortable-name="content-list-items"
        data-object_type="content_list_items"
      >
        {sorted_list_items.map((list_item, i) => {
          return (
            <ListSettings
              key={"content_list_item-" + list_item.id}
              list_item={list_item}
              {...props}
            />
          );
        })}
      </div>

      {element.element_type == "list" && (
        <NewContentListItem element={element} />
      )}
    </React.Fragment>
  );
};

export default ElementSettingsList;
