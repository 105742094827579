import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingAdvanced = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <React.Fragment>
      <Popover
        isOpen={isPopoverOpen}
        positions={["left"]}
        onClickOutside={({}) => setIsPopoverOpen(false)}
        containerClassName={`${contextData.dark_mode ? 'dark' : ''} advanced-settings-popover smooth`}
        reposition={false}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={{
              width: 227,
              height: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            popoverRect={popoverRect}
            arrowColor={"white"}
            arrowSize={10}
            arrowStyle={{
              opacity: 1,
              top: "90px",
              left: "auto",
              right: "0px",
              borderLeft: `10px solid ${contextData.dark_mode ? '#000' : '#fff'}`,
            }}
            className={`popover-arrow-container text-black bg-white ${contextData.dark_mode ? '!bg-slate-950' : ''} rounded-lg shadow-lg`}
            arrowClassName="popover-arrow"
          >
            <div className="row">{props.children}</div>
          </ArrowContainer>
        )}
      >
        <div className="float-right">
          <FontAwesomeIcon
            icon={"cog"}
            className="text-xl text-black dark:text-white cursor-pointer"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            title="Advanced settings"
          />
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default VariantBuilderSettingAdvanced;
