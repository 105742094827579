import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const VariantSettingsThemeClose = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse
        name="Close Icon"
        open={contextData.selected_object_collapse == "Close Icon"}
        condition={["overlay", "hook", "bar"].includes(cta.cta_type)}
      >

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][close-icon-show]"
          setting_type="checkbox"
          object_type="variants"
          label="Show Close Icon"
          value={
            variant.options["close-icon-show"]
              ? variant.options["close-icon-show"]
              : "true"
          }
          condition={["overlay", "hook", "bar"].includes(cta.cta_type)}
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][close-icon-thickness]"
          setting_type="dropdown"
          object_type="variants"
          label="Close Icon Thickness"
          options={[
            { value: "thin", text: "Thin" },
            { value: "thick", text: "Thick" },
          ]}
          value={
            variant.options["close-icon-thickness"]
              ? variant.options["close-icon-thickness"]
              : "thick"
          }
          condition={
            ["overlay", "hook", "bar"].includes(cta.cta_type) &&
            variant.options["close-icon-show"] !== "false"
          }
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][close-icon-size]"
          setting_type="range"
          object_type="variants"
          label="Close Icon Size"
          value={
            variant.options["close-icon-size"]
              ? variant.options["close-icon-size"]
              : "12"
          }
          condition={
            ["overlay", "hook", "bar"].includes(cta.cta_type) &&
            variant.options["close-icon-show"] !== "false"
          }
          min={10}
          max={50}
          device="desktop"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][close-icon-size-mobile]"
          setting_type="range"
          object_type="variants"
          label="Close Icon Size"
          value={
            variant.options["close-icon-size-mobile"]
              ? variant.options["close-icon-size-mobile"]
              : variant.options["close-icon-size"]
              ? variant.options["close-icon-size"]
              : "12"
          }
          condition={
            ["overlay", "hook", "bar"].includes(cta.cta_type) &&
            variant.options["close-icon-show"] !== "false"
          }
          min={10}
          max={50}
          device="mobile"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][close-icon-color]"
          setting_type="color_v2"
          object_type="variants"
          label="Close Color"
          value={
            variant.options["close-icon-color"]
              ? variant.options["close-icon-color"]
              : "#676f84"
          }
          condition={
            ["overlay", "hook", "bar"].includes(cta.cta_type) &&
            variant.options["close-icon-show"] !== "false"
          }
          col="6"
          classNames="some-padding-right"
        />

        <VariantBuilderSetting
          object={variant}
          setting_name="[options][close-icon-background]"
          setting_type="color_v2"
          object_type="variants"
          label="Background"
          value={
            variant.options["close-icon-background"]
              ? variant.options["close-icon-background"]
              : "#f2f5f9"
          }
          condition={
            ["overlay", "hook", "bar"].includes(cta.cta_type) &&
            variant.options["close-icon-show"] !== "false"
          }
          col="6"
          classNames="some-padding-left"
          gradient={true}
          offset_left={true}
        />

        <VariantBuilderSetting
          object={variant}
          setting_type="message"
          label="CSS Selector"
          value={`#cta_${cta.id} .cf-close[data-cta-id="${cta.id}"]`}
          tooltip="Using custom CSS? Make sure to add !important to the end of each of your CSS properties."
        />
      </VariantBuilderPanelCollapse>
    </React.Fragment>
  );
};

export default VariantSettingsThemeClose;
