import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useFields from "../fields/useFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewField = (props) => {
  const { Fields } = useFields();

  const createField = () => {
    Fields.create(props.element, null, null, (field) => {
      dispatchCustomEvent("focusItem", {
        parent_id: props.element.id,
        item_type: "fields",
        item_id: field.id,
      });
    });
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 my-5">
        <button
          onClick={createField}
          className="field-add-container w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add
          Field
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewField;
