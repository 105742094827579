import React from "react";
import useProducts from "../products/useProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewProduct = (props) => {
  const { Products } = useProducts();

  const createProduct = _.debounce((clicks) => {
    Products.create(props.element, props.product_recommendation, (product) => {
      dispatchCustomEvent("focusItem", {
        parent_id: props.element ? props.element.id : props.product_recommendation ? props.product_recommendation.id : null,
        item_type: "products",
        item_id: product.id
      });
    });
  }, 200);

  return (
    <React.Fragment>
      <div className="row hard-center mb-5">
        <button
          onClick={createProduct}
          className="w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
          title="Add Product"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add Product
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewProduct;
