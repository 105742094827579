import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _, { xor } from "lodash";

const VariantTwoTapPopup = (props) => {
  const variant = props.variant;
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    variant
  );

  if (props.builder == true) {
    useEffect(() => {
      ctas.builder.fonts.load_used_fonts(".cf-two-tap");
    }, [variant.options["prehook-button-font"]]);
  }

  return (
    <React.Fragment>
      {variant.options["trigger_type"] !== "prehook" && (
        <VariantTwoTapPopupButton {...props} />
      )}
      {variant.options["trigger_type"] == "prehook" && (
        // prehook is what we called two-tap popups back in the day
        <VariantTwoTapPopupProfile {...props} />
      )}
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const VariantTwoTapPopupButton = (props) => {
  const variant = props.variant;

  return (
    <React.Fragment>
      <div className="cf-two-tap-close">
        <svg
          fill={variant.options['prehook-button-font-color'] ? variant.options['prehook-button-font-color'] : '#fff'}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
        </svg>
      </div>
      <div
        className="cf-two-tap-message"
        data-font={variant.options["prehook-button-font"]}
        style={{
          fontFamily: variant.options["prehook-button-font"],
        }}
        dangerouslySetInnerHTML={{
          __html: variant.options["prehook-message"]
            ? variant.options["prehook-message"]
            : "<p>Your message here</p>",
        }}
      ></div>
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, variant) => {
    let options = { ...variant.options };

    let prehookPosition;

    if (
      builder !== true &&
      (!options["prehook-position"] || options["prehook-position"] == "right")
    ) {
      prehookPosition = "right: 20px !important;";
    }

    if (builder !== true && options["prehook-position"] == "left") {
      prehookPosition = "left: 20px !important;";
    }

    if (builder !== true && options["prehook-position"] == "center") {
      prehookPosition = `
        right: 50% !important; 
        left: 50% !important; 
        transform: translate(-50%, 0%) !important;
        animation: initial !important;
        transition: initial !important;
`;
    }

    let borderRadius = "0px";
    if (options["prehook-button-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["prehook-button-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["prehook-button-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["prehook-button-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["prehook-button-radius-" + side]) == false
              ? options["prehook-button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    // prettier-ignore
    let desktopStyles = 
`.cf-two-tap[data-cta-id="${cta.id}"] {
  cursor: pointer;
  background: ${options['prehook-button-color'] ? options['prehook-button-color'] : '#007aec'} !important;
  min-height: 40px;
  position: ${builder == true ? 'relative' : 'fixed'};
  display: ${builder == true ? 'inline-block' : 'none'};
  width: fit-content;
  min-width: 100px;
  bottom: ${builder == true ? 'initial' : '20px'};
  z-index: 100002;
  overflow: hidden;
  user-select: none;
  border: initial !important;
  border-style: ${options["prehook-button-border-style"] ? options["prehook-button-border-style"] : 'none'} !important;
  border-width: ${_.isNil(options["prehook-button-border-width"]) == false ? options["prehook-button-border-width"] : 2}px !important;
  border-left-width: ${_.isNil(options["prehook-button-border-width-left"]) == false ? options["prehook-button-border-width-left"] : _.isNil(options["prehook-button-border-width"]) == false ? options["prehook-button-border-width"] : 2}px !important;
  border-right-width: ${_.isNil(options["prehook-button-border-width-right"]) == false ? options["prehook-button-border-width-right"] : _.isNil(options["prehook-button-border-width"]) == false ? options["prehook-button-border-width"] : 2}px !important;
  border-top-width: ${_.isNil(options["prehook-button-border-width-top"]) == false ? options["prehook-button-border-width-top"] : _.isNil(options["prehook-button-border-width"]) == false ? options["prehook-button-border-width"] : 2}px !important;
  border-bottom-width: ${_.isNil(options["prehook-button-border-width-bottom"]) == false ? options["prehook-button-border-width-bottom"] : _.isNil(options["prehook-button-border-width"]) == false ? options["prehook-button-border-width"] : 2}px !important;
  border-color: ${options["prehook-button-border-color"] ? options["prehook-button-border-color"] : '#000'} !important;
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
  ${prehookPosition}
}

.cf-two-tap[data-cta-id="${cta.id}"] .cf-two-tap-close {
  position: absolute;
  display: flex;
  width: 21px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 5px;
  font-family: Verdana, Arial, sans-serif;
  font-size: 19px;
  text-align: left;
  color: ${options["prehook-button-font-color"] ? options["prehook-button-font-color"] : '#fff'} !important;
  z-index: 100003;
  padding-top: 4px;
}

.cf-two-tap[data-cta-id="${cta.id}"] .cf-two-tap-message {
  margin-right: 20px;
  padding: 0px 15px;
}

.cf-two-tap[data-cta-id="${cta.id}"] .cf-two-tap-message p {
  all: initial !important;
  cursor: pointer !important;
  text-align: center !important;
  font-family: Helvetica, Arial, sans-serif !important;
  margin: 0 !important;
  line-height: 40px !important;
  color: ${options['prehook-text-color'] ? options['prehook-text-color'] : '#fff'} !important;
  width: 100% !important;
  display: inline-block !important;
  user-select: none !important;
  font-size: ${options["prehook-button-size"] ? options["prehook-button-size"] : '16'}px !important;
  font-family: ${options["prehook-button-font"] ? options["prehook-button-font"] + ', ' : ''}Helvetica, Arial, sans-serif !important;
  font-weight: ${options['prehook-button-font-weight'] ? options['prehook-button-font-weight'] : '700'} !important;
  color: ${options["prehook-button-font-color"] ? options["prehook-button-font-color"] : '#fff'} !important;
  white-space: nowrap !important;
}
`;

    if (builder == true && variant.options["trigger_type"] == "prehook") {
      desktopStyles += `
    .cta${cta.id}.cf-prehook-popup {
      position: relative !important;
      bottom: initial !important;
      right: initial !important;
    }

    .cta${cta.id}.cf-prehook-popup .prehook-message p {
      margin: 0 !important; 
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; 
      font-size: 16px !important; 
      color: #6f818e; 
      line-height: 1.4 !important; 
      padding: 0px; 
      -webkit-font-smoothing: subpixel-antialiased; 
      -moz-osx-font-smoothing: initial; 
      font-smoothing: subpixel-antialiased;
    }
`;
    }

    let buttonFontSizeMobile;
    if (options["prehook-button-size-mobile"]) {
      buttonFontSizeMobile = options["prehook-button-size-mobile"];
    }

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */

.cf-two-tap[data-cta-id="${cta.id}"] .cf-two-tap-message p {
  ${buttonFontSizeMobile ? `font-size: ${buttonFontSizeMobile}px !important;` : ''}
}

`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

const VariantTwoTapPopupProfile = (props) => {
  const variant = props.variant;
  const user = props.user;

  let profile_brand = props.website.brand ? props.website.brand : "";
  let profile_avatar =
    user && user.avatar
      ? user.avatar
      : "https://img.convertflow.co/static/avatar.jpg";

  let profile_name;
  if (user && user.name) {
    profile_name = user.name + (user.lastname ? " " + user.lastname : "");
  } else {
    profile_name = "A team member";
  }
  return (
    <React.Fragment>
      <div
        id="trigger-prehook"
        className="cf-col-xs-12 prehook-message"
        style={{ padding: "25px 20px 15px" }}
        dangerouslySetInnerHTML={{
          __html: variant.options["prehook-message"]
            ? variant.options["prehook-message"]
            : "<p>Your message here</p>",
        }}
      ></div>
      <div
        className="cf-col-xs-2 cf-center"
        style={{ paddingBottom: props.builder == true ? "10px" : undefined }}
      >
        <div className="prehook-profile cf-profile-img" id="prehook-profile">
          <img src={profile_avatar} />
        </div>
      </div>
      <div className="cf-col-xs-6" style={{ padding: "10px 0px 0px 10px" }}>
        <div className="prehook-name">
          <p className="cf-profile-name">{profile_name}</p>
        </div>
        <div className="prehook-title">
          <p>
            <span className="cf-profile-title">{profile_brand}</span>
          </p>
        </div>
      </div>
      <div
        id="trigger-cta"
        className="cf-prehook-button cf-button-primary cf-center"
        style={{
          background: variant.options["prehook-button-color"],
        }}
        dangerouslySetInnerHTML={{
          __html: variant.options["prehook-cta"]
            ? variant.options["prehook-cta"]
            : "<p>See More</p>",
        }}
      ></div>
      <div className="prehook-close">x</div>
    </React.Fragment>
  );
};

export default VariantTwoTapPopup;
