var oauth = {
  create: function (website_id, service, payload) {
    var api_data = {
      authenticating: true,
    };

    if (payload) {
      var api_data = $.extend({}, api_data, payload);
    }

    $.ajax({
      type: "POST",
      url: window.location.origin + "/websites/" + website_id + "/integrations",
      async: true,
      data: {
        integration: {
          website_id: website_id,
          integration_type: service,
          api_data: api_data,
        },
      },
      success: function (data, status, xhr) {
        // integrations/create.js.erb
        $("#integrations").modal("show");
        console.log("Starting " + service + " oauth...");
        window["created_" + service] = true;
      },
    });

    var integration_check = setInterval(function () {
      if (window["created_" + service] == true) {
        clearInterval(integration_check);
        $(
          '.integration-stacked-modal[data-service="' +
            service +
            '"] .integration-authenticate'
        ).click();
      }
    }, 1000);
  },

  create_with_form: function (website_id, service, button) {
    window.integrations.form(button);
    var form = $($(button).attr("data-form"));

    $(form).on("submit", function (e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      var fields = {};
      $(form)
        .find('[name*="api_data"]')
        .each(function (i, field) {
          fields[$(field).attr("data-auth-key")] = $(field).val();
        });

      window.integrations.oauth.create(website_id, service, fields);

      return false;
    });
  },

  open: function (website_id, integration_id, service, button) {
    var link =
      window.location.origin +
      "/websites/" +
      website_id +
      "/integrations/" +
      integration_id +
      "/authenticate?service=" +
      service;
    window["oauth_" + integration_id] = window.open(
      link,
      "_blank"
    );

    if (window["oauth_" + integration_id]) {
      if (!button) {
        var button = $('.integration-connect[data-service="' + service + '"]');
      }

      setTimeout(function () {
        integrations.sync(undefined, website_id, integration_id, button);
      }, 2000);
    } else {
      alert(
        "Integration connection popup blocked. Allow popups for this website and try again."
      );
    }
  },

  close: function (integration_id) {
    if (window["oauth_" + integration_id]) {
      window["oauth_" + integration_id].close();
    }
  },
};

export default oauth;
