import React, { useState } from "react";
import updateAOV from "../../utils/updateAOV";
import updateConversionRate from "../../utils/updateConversionRate";
import ReportCount from "../reports/ReportCount";

const VariantListReport = (props) => {
  const variant = props.variant;
  const cta = props.cta;
  const website = props.website;

  const [range_start, setRangeStart] = useState(cta.created_at);
  const [range_end, setRangeEnd] = useState(new Date().toISOString());

  return (
    <React.Fragment>
      <div
        className="col-xs-1"
        style={{ paddingTop: "25px", maxWidth: "125px" }}
      >
        <p
          id={`variant${variant.id}-viewers`}
          className="text-slate-500 variant-stat"
          data-name="viewers"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Unique people who triggered the campaign and saw this variant"
          style={{ marginTop: "10px" }}
        >
          <ReportCount
            id={`cta-${cta.id}-variant-${variant.id}-views`}
            inline={true}
            unstyled={true}
            path={`/websites/${website.id}/events_reports`}
            payload={{
              report_type: "distinct_count",
              report: {
                website_id: website.id,
                event_type: "View",
                contactable_type: "Cta",
                contactable_id: cta.id,
                variant_id: variant.id,
                range_start: range_start,
                range_end: range_end,
                field: null,
                element_id: null,
                broadcast_id: null,
                step_id: null,
              },
            }}
          />
        </p>
      </div>

      <div
        className="col-xs-1"
        style={{ paddingTop: "25px", minWidth: "125px" }}
      >
        <p
          id={`variant${variant.id}-conversions`}
          className="text-slate-500 variant-stat"
          data-name="conversions"
          style={{ paddingTop: "10px" }}
        >
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Converted on first step button/form of this variant"
          >
            <ReportCount
              id={`cta-${cta.id}-variant-${variant.id}-conversions`}
              inline={true}
              unstyled={true}
              path={`/websites/${website.id}/events_reports`}
              payload={{
                report_type: "distinct_count",
                report: {
                  website_id: website.id,
                  event_type: "Complete",
                  contactable_type: "Cta",
                  contactable_id: cta.id,
                  variant_id: variant.id,
                  range_start: range_start,
                  range_end: range_end,
                  field: null,
                  broadcast_id: null,
                  step_id: null,
                },
              }}
              callback={() => {
                updateConversionRate(
                  `#cta-${cta.id}-variant-${variant.id}-views`,
                  `#cta-${cta.id}-variant-${variant.id}-conversions`,
                  `#cta-${cta.id}-variant-${variant.id}-conversion-rate`
                );
              }}
            />{" "}
          </span>

          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Percentage that converted on first step of this variant"
          >
            (
            <span id={`cta-${cta.id}-variant-${variant.id}-conversion-rate`}>
              0.00%
            </span>
            )
          </span>
        </p>
      </div>

      <div
        className="col-xs-1"
        style={{ paddingTop: "25px", minWidth: "125px" }}
      >
        <p
          id={`variant${variant.id}-completions`}
          className="text-slate-500 variant-stat"
          data-name="completions"
          style={{ marginTop: "10px" }}
        >
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Tracked as having completed this campaign variant"
          >
            <ReportCount
              id={`cta-${cta.id}-variant-${variant.id}-completions`}
              inline={true}
              unstyled={true}
              path={`/websites/${website.id}/events_reports`}
              payload={{
                report_type: "distinct_count",
                report: {
                  website_id: website.id,
                  event_type: "Completion",
                  contactable_type: "Cta",
                  contactable_id: cta.id,
                  variant_id: variant.id,
                  range_start: range_start,
                  range_end: range_end,
                  field: null,
                  broadcast_id: null,
                  step_id: null,
                },
              }}
              callback={() => {
                updateConversionRate(
                  `#cta-${cta.id}-variant-${variant.id}-views`,
                  `#cta-${cta.id}-variant-${variant.id}-completions`,
                  `#cta-${cta.id}-variant-${variant.id}-completion-rate`
                );
              }}
            />{" "}
          </span>

          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Percentage that completed this campaign variant"
          >
            (
            <span id={`cta-${cta.id}-variant-${variant.id}-completion-rate`}>
              0.00%
            </span>
            )
          </span>
        </p>
      </div>

      <div
        className="col-xs-1"
        style={{ paddingTop: "25px", minWidth: "100px" }}
      >
        <p
          id={`variant${variant.id}-orders`}
          className="text-slate-500 variant-stat"
          data-name="orders"
          style={{ marginTop: "10px" }}
        >
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Orders within 7 days after converting with this campaign"
          >
            <ReportCount
              id={`cta-${cta.id}-variant-${variant.id}-orders`}
              inline={true}
              unstyled={true}
              path={`/websites/${website.id}/events_reports`}
              payload={{
                report_type: "count",
                report: {
                  website_id: website.id,
                  range_start: range_start,
                  range_end: range_end,
                  event_type: "Order",
                  contactable_type: "Cta",
                  contactable_id: cta.id,
                  variant_id: variant.id,
                  step_id: null,
                  element_id: null,
                  product_id: null,
                  field: null,
                  value: null,
                  broadcast_id: null,
                },
              }}
              callback={() => {
                updateConversionRate(
                  `#cta-${cta.id}-variant-${variant.id}-views`,
                  `#cta-${cta.id}-variant-${variant.id}-orders`,
                  `#cta-${cta.id}-variant-${variant.id}-orders-rate`
                );

                updateAOV(
                  `#cta-${cta.id}-variant-${variant.id}-orders`,
                  `#cta-${cta.id}-variant-${variant.id}-revenue`,
                  `#cta-${cta.id}-variant-${variant.id}-aov`
                );
              }}
            />{" "}
          </span>

          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Percentage of orders that converted and then checked out within 7 days"
          >
            (
            <span id={`cta-${cta.id}-variant-${variant.id}-orders-rate`}>
              0.00%
            </span>
            )
          </span>
        </p>
      </div>

      <div
        className="col-xs-1"
        style={{ paddingTop: "25px", maxWidth: "125px" }}
      >
        <p
          id={`variant${variant.id}-revenue`}
          className="text-slate-500 variant-stat"
          data-name="revenue"
          style={{ marginTop: "10px" }}
        >
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Total revenue of orders that occured within 7 days of converting"
          >
            <ReportCount
              id={`cta-${cta.id}-variant-${variant.id}-revenue`}
              inline={true}
              unstyled={true}
              currency={props.currency}
              path={`/websites/${website.id}/events_reports`}
              payload={{
                report_type: "revenue",
                report: {
                  website_id: website.id,
                  range_start: range_start,
                  range_end: range_end,
                  event_type: "Order",
                  contactable_type: "Cta",
                  contactable_id: cta.id,
                  variant_id: variant.id,
                  step_id: null,
                  element_id: null,
                  product_id: null,
                  field: null,
                  value: null,
                  broadcast_id: null,
                },
              }}
            />
          </span>
        </p>
      </div>

      <div
        className="col-xs-1"
        style={{ paddingTop: "25px", maxWidth: "125px" }}
      >
        <p
          className="text-slate-500 variant-stat"
          data-name="aov"
          style={{ marginTop: "10px" }}
        >
          {props.currency}
          <span
            id={`cta-${cta.id}-variant-${variant.id}-aov`}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Average revenue of orders that occured within 7 days of converting"
          >
            0.00
          </span>
        </p>
      </div>

      <div
        className="col-xs-2 right"
        style={{
          width: "130px",
          paddingTop: "25px",
          position: "absolute",
          right: "-25px",
        }}
      >
        <div className="btn-group" style={{ float: "right" }}>
          <button
            type="button"
            className="bg-white border-solid border-[1px] py-3 px-5 mr-4 rounded-md border-slate-200 text-black text-xl hover:bg-blue-100 hover:border-blue-100 hover:text-blue-600"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Options <span className="caret"></span>
          </button>
          <ul className="!rounded-lg !border-none !shadow-lg dropdown-menu">
            <li>
              <a href={props.builder_path} data-turbolinks="false">
                Builder
              </a>
            </li>
            <li>
              <a href={props.preview_path}>Preview</a>
            </li>
            <li>
              <a
                className="variant-create"
                data-turbolinks="false"
                href={props.duplicate_path}
              >
                Duplicate
              </a>
            </li>

            {props.account_role_type != "client" && (
              <li>
                <a
                  className=" template-create"
                  target="_blank"
                  href={props.save_template_path}
                >
                  Save as template
                </a>
              </li>
            )}

            <li>
              <a href={props.contacts_path}>Contacts</a>
            </li>

            {variant.percentage == 0 && (
                <li>
                  <a
                    data-type="variant-delete"
                    data-id={variant.id}
                    id={`variant${variant.id}-delete`}
                    style={{
                      display: variant.percentage != 0 ? "display:none" : "",
                    }}
                    href="#"
                  >
                    Delete
                  </a>
                </li>
              )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantListReport;
