import React, { useContext, useState } from "react";
import useActionGroups from "../action_groups/useActionGroups";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useSections from "../sections/useSections.js";

const useSteps = () => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let cta = contextData.objects.cta;
  const { Sections } = useSections();
  const { ActionGroups } = useActionGroups();

  const Steps = {
    create: async function (
      template_id = null,
      draggablePosition,
      updates,
      forceRender = false
    ) {
      const position =
        draggablePosition ||
        Object.values(variant.steps).filter((step) => !step.toBeDeleted)
          .length + 1;

      builder.create(
        "steps",
        {
          position: position,
          variant_id: variant.id,
          template_id: template_id,
          options: {},
        },
        {
          updates: updates,
          forceRender: forceRender,
          callback: (step) => {
            Object.values(step.sections).forEach((section) => {
              Sections.addChildrenToBuilder(section, true);
            });

            dispatchCustomEvent("navigateTab", {
              tab: "layers"
            });

            $("html, body").animate(
              {
                scrollTop:
                  $('#builder .step[data-step-id="' + step.id + '"]').offset()
                    .top - 100,
              },
              500
            );
          },
        }
      );
    },

    reorderStepPositions: (newStepPosition) => {
      let updatesForHistory = [];
      let updatesForPreview = [];
      let newPositionIndexStart;
      const draggedPosition = parseInt(newStepPosition);

      Object.values(variant.steps)
        .filter((s) => s.toBeDeleted !== true)
        .sort(function (a, b) {
          return a.position - b.position;
        })
        .forEach((step, i) => {
          let old_position = step.position;
          let new_position = i + 1;

          if (new_position < draggedPosition) return;
          if (new_position == draggedPosition) {
            newPositionIndexStart = new_position + 1;
            new_position = newPositionIndexStart;
          } else if (new_position > draggedPosition) {
            new_position = newPositionIndexStart + 1;
            newPositionIndexStart += 1;
          }

          updatesForHistory.push({
            object_type: "steps",
            object_id: step.id,
            setting_name: "[position]",
            value: new_position,
            old_value: old_position,
          });

          updatesForPreview.push({
            object_type: "steps",
            object_id: step.id,
            setting_name: "[position]",
            value: new_position,
            skip_history: true,
            old_value: old_position,
          });
        });

      dispatchCustomEvent("updateBuilder", { updates: updatesForPreview });
      return updatesForHistory;
    },

    duplicate: async function (og_step) {
      let targetPosition = og_step.position + 1;
      let updates = Steps.reorderStepPositions(targetPosition);

      return new Promise((resolve, reject) => {
        builder.create(
          "steps",
          {
            position: targetPosition,
            variant_id: variant.id,
            options: og_step.options,
          },
          {
            updates: updates,
            callback: async function (step) {
              try {
                let og_sections = Object.values(variant.sections).filter(
                  (section) =>
                    section.step_id == og_step.id &&
                    !section.element_id &&
                    section.toBeDeleted !== true
                );

                let og_action_groups = Object.values(variant.action_groups).filter(
                  (action_group) =>
                    action_group.step_id == og_step.id &&
                    action_group.toBeDeleted !== true
                );

                await Promise.all([
                  ...og_sections.map((section) => {
                    return Sections.duplicate(
                      section,
                      {
                        step_id: step.id,
                      },
                      true
                    );
                  }),
                  ...og_action_groups.map((action_group) => {
                    return ActionGroups.duplicate(
                      action_group,
                      {
                        step_id: step.id,
                      },
                      true
                    );
                  })
                ]);

                $("html, body").animate(
                  {
                    scrollTop:
                      $(
                        '#builder .step[data-step-id="' + step.id + '"]'
                      ).offset().top - 100,
                  },
                  500
                );

                resolve(step);
              } catch (error) {
                console.log("error", error);
                reject(error);
              }
            },
            skip_select: true,
          },
        );
      });
    },

    remove: (removed_step) => {
      let updates = [];

      let removedStepPosition = removed_step.position;
      let nextSteps = Object.values(variant.steps).filter(
        (s) => s.toBeDeleted !== true && s.position > removed_step.position
      );

      nextSteps.forEach((step) => {
        let update = {
          object_type: "steps",
          object_id: step.id,
          setting_name: "[position]",
          value: step.position - 1,
          old_value: step.position,
        };
        updates.push(update);
        builder.update([{ ...update, skip_history: true }]);
      });

      let message =
        "Are you sure you want to remove this step? Once your changes are saved, it will be irreversibly deleted.";
      builder.remove("steps", removed_step.id, message, updates);
    },

    moveUp: (moved_step) => {
      let updates = [];
      let steps = Object.values(variant.steps)
        .filter((s) => s.toBeDeleted !== true)
        .sort((a, b) => a.position - b.position);
      let new_value = moved_step.position - 1;

      if (steps.filter((s) => s.position == new_value)[0]) {
        updates.push({
          object_type: "steps",
          object_id: moved_step.id,
          setting_name: "[position]",
          value: new_value,
          old_value: moved_step.position,
        });

        steps
          .filter((s) => s.position == moved_step.position - 1)
          .forEach((step) => {
            updates.push({
              object_type: "steps",
              object_id: step.id,
              setting_name: "[position]",
              value: step.position + 1,
              old_value: step.position,
            });
          });

        builder.update(updates);
        setTimeout(function () {
          Steps.scrollTo(moved_step);
        }, 100);
      }
    },

    moveDown: (moved_step) => {
      let updates = [];
      let steps = Object.values(variant.steps)
        .filter((s) => s.toBeDeleted !== true)
        .sort((a, b) => a.position - b.position);
      let new_value = moved_step.position + 1;

      if (steps.filter((s) => s.position == new_value)[0]) {
        updates.push({
          object_type: "steps",
          object_id: moved_step.id,
          setting_name: "[position]",
          value: new_value,
          old_value: moved_step.position,
        });

        steps
          .filter((s) => s.position == moved_step.position + 1)
          .forEach((step) => {
            updates.push({
              object_type: "steps",
              object_id: step.id,
              setting_name: "[position]",
              value: step.position - 1,
              old_value: step.position,
            });
          });

        builder.update(updates);
        setTimeout(function () {
          Steps.scrollTo(moved_step);
        }, 100);
      }
    },

    sortableStart: () => {
      let container = document.querySelector("#builder .steps-container");

      if (contextData.selected_object_editing == false) {
        let sortable = new Sortable(container, {
          sort: true,
          group: { name: "steps-position" },
          animation: 150,
          draggable: ".step",
          ghostClass: "drop-zone",
          handle: ".step-toggle",
          forceFallback: true,
          scroll: true,
          filter: ".drag-disable",
          forceFallback: true,
          fallbackOnBody: true,
          fallbackTolerance: 5,
          fallbackClass: "hide-during-drag",
          onUpdate: Steps.sortableUpdate,
        });
      }
    },

    sortableUpdate: (event) => {
      if (event.item.getAttribute("data-step-id")) {
        let updates = [];
        event.to
          .querySelectorAll(".step, .step-thumbnail")
          .forEach((step_div, i) => {
            let step_id = step_div.getAttribute("data-step-id");
            let new_position = i + 1;

            updates.push({
              object_type: "steps",
              object_id: step_id,
              setting_name: "[position]",
              value: new_position,
              old_value: step_div.getAttribute("data-position"),
            });

            step_div.setAttribute("data-position", new_position);
          });
        builder.update(updates);
      }
    },

    sortableLayersStart: () => {
      let container = document.querySelector(`#layers`);

      let sortable = new Sortable(container, {
        sort: true,
        group: { name: "layers-steps" },
        animation: 150,
        draggable: ".layers-item[data-object-type='steps']",
        fitler: ".drag-disable",
        ghostClass: "drop-zone",
        handle:
          ".layers-item-icon[data-object-type='steps'], .layers-item-label[data-object-type='steps']",
        forceFallback: true,
        fallbackOnBody: true,
        fallbackTolerance: 5,
        fallbackClass: "hide-during-drag",
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        onUpdate: Steps.sortableLayersUpdate,
      });
    },

    sortableLayersUpdate: (event) => {
      let updates = [];
      event.to
        .querySelectorAll(".layers-item[data-object-type='steps']")
        .forEach((step_div, i) => {
          let step_id = step_div.getAttribute("data-object-id");
          let new_position = i + 1;

          updates.push({
            object_type: "steps",
            object_id: step_id,
            setting_name: "[position]",
            value: new_position,
            old_value: step_div.getAttribute("data-position"),
          });

          step_div.setAttribute("data-position", new_position);
        });
      builder.update(updates);
    },

    // generates a thumbnail preview for the step manager
    generateThumbnail: (step, cta) => {
      let step_div = document.querySelector(
        '#builder .cf-step[data-step-id="' + step.id + '"]'
      );

      if (step_div) {
        let max_width;

        if (
          variant.options["container-width"] == "310px" ||
          (!variant.options["container-width"] &&
            cta.cta_type == "hook" &&
            cta.hook_type == "vertical")
        ) {
          max_width = 310;
        } else if (
          ["small", "500px"].includes(variant.options["container-width"])
        ) {
          max_width = 500;
        } else if (
          ["regular", "700px"].includes(variant.options["container-width"])
        ) {
          max_width = 700;
        } else if (
          ["large", "900px"].includes(variant.options["container-width"])
        ) {
          max_width = 900;
        } else if (variant.options["container-width"] == "1200px") {
          max_width = 1200;
        }

        if (cta.cta_type == "page") {
          max_width = 1200;
        }

        let step_preview = $(step_div).clone()[0];
        $(step_preview)
          .find("[data-html2canvas-ignore], .section-add-container")
          .remove();

        var scale = Math.min(242 / max_width);
        $(step_preview).css({
          visibility: "visible",
          "margin-top": "0",
        });

        let step_thumbnail = document.querySelector(
          '.step-thumbnail[data-step-id="' +
            step.id +
            '"] div[id="cta_' +
            cta.id +
            '"]'
        );

        if (step_thumbnail) {
          step_thumbnail.innerHTML = "";
          step_thumbnail.appendChild(step_preview);
        }
      }
    },

    // drag and drop in the steps manager
    managerSortable: (event) => {
      let container = document.querySelector("#steps-list");

      var sortable_options = {
        sort: true,
        group: { name: "step-thumbnails" },
        animation: 0,
        forceFallback: true,
        fallbackOnBody: true,
        fallbackClass: "builder-sortable-fallback",
        draggable: ".step-thumbnail",
        handle: ".step-handle",
        ghostClass: "drop-zone",
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        delay: 10,
        onUpdate: Steps.sortableUpdate,
      };

      new Sortable(container, sortable_options);
    },

    scrollTo: function (step) {
      $("html, body").animate(
        {
          scrollTop:
            $('#builder .step[data-step-id="' + step.id + '"]').offset().top -
            100,
        },
        500
      );
    },
  };
  return { Steps };
};

export default useSteps;
